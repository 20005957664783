import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler: any;
  history: any
  t:(key:string)=>string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formData: {
    newPassword: string;
    confirmPassword: string;
  };
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  isErrorFormData: any;
  resetpasswordLoader:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      formData: {
        newPassword: "",
        confirmPassword: "",
      },
      showNewPassword: false,
      showConfirmPassword: false,
      isErrorFormData: {},
      resetpasswordLoader:false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.resetPasswordApiCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            resetpasswordLoader:true
          })
          this.props.openToastHandler(this.props.t('ResetPasswordScreen.success'), "success")
          const role = localStorage.getItem("role");
          const route = role ==="EMP" ?"/Employee/Login":"/Login"
          setTimeout(() => {
            this.props.history.push(`${route}`)
            localStorage.clear()
          }, 1500)
        } else {
          //Check Error Response
          this.props.openToastHandler(responseJson.errors, "error")
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  handleShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  }

  handleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }


  handlePasswordChange = (e: any) => {
    const errors: any = {};
    const { name, value } = e.target;

    (name === 'newPassword') && this.validatePassword(value, errors, 'newPassword');
    (name === 'confirmPassword') && this.validatePassword(value, errors, "confirmPassword");
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
      isErrorFormData: errors
    });
  }
  validatePassword = (value: string, errors: any, name: any) => {
    const { atleastOneCapsRegex,
      atleastLowerCaseRegex,
      specialCharRegex,
      digitsRegex } = configJSON.passwordValidators;
      const {t}=this.props;
    if (!value) {
      errors[name] = name === "newPassword" ? t('ResetPasswordScreen.validations.newPassword') : t('ResetPasswordScreen.validations.confirmPassword')
    }
    else if (!atleastOneCapsRegex.test(value)) {
      errors[name] =  t('ResetPasswordScreen.validations.upperCase');
    }
    else if (!atleastLowerCaseRegex.test(value)) {
      errors[name] = t('ResetPasswordScreen.validations.lowerCase');
    }
    else if (!specialCharRegex.test(value)) {
      errors[name] = t('ResetPasswordScreen.validations.specialCharacter');
    }
    else if (!digitsRegex.test(value)) {
      errors[name] = t('ResetPasswordScreen.validations.number');
    }
    else if ((value).length < 8) {
      errors[name] = t('ResetPasswordScreen.validations.length');
    }
  }


  validateBothPasswords = (errors: any) => {
    let { newPassword, confirmPassword } = this.state.formData;
    const {t}=this.props;
    if (newPassword === confirmPassword) {
      if (newPassword !== '' && confirmPassword !== '') {
        this.resetPassword();
      }
    } else {
      errors.commonError = t('ResetPasswordScreen.validations.bothSame')
    }
  }


  handleResetPasswordSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors: any = {};
    let { newPassword, confirmPassword } = this.state.formData;
    this.validatePassword(newPassword, errors, 'newPassword');
    this.validatePassword(confirmPassword, errors, 'confirmPassword');
    this.validateBothPasswords(errors);
    this.setState({ isErrorFormData: errors },
      () => {
        if (Object.keys(this.state.isErrorFormData).length === 0) {
          this.resetPassword();
        }
      }
    );
  }

  resetPassword = async() => {
    const header = {
      "Content-Type": "application/json",
    };
    const role = localStorage.getItem("role");
    const data = {
      "token": localStorage.getItem('OtpToken'),
      "new_password": this.state.formData.newPassword,
      "confirm_password": this.state.formData.confirmPassword,
    };

    const httpBody = {
      "role":role,
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const endPoint="bx_block_forgot_password/passwords"
    const lang= await getStorageData('lang')

    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}?lang=${lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End