import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Container,
  TextField,
  Dialog,
  Button,
  InputProps,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Hidden
} from "@material-ui/core";
import {
  Theme,
  styled,
  withStyles
} from "@material-ui/core/styles";
import {
  productImage,
  servicesImage,
  uploadIcon,
  dropDownSelectIcon,
  deleteTaxIcon,
  editProductsServicesIcon
} from "./assets";
import "./CustomScrollbar.css";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { SettingsSideBar } from "./SettingsSideBar.web";
import { FileUpload } from "../../../components/src/FileUpload.web";
import { StyledBox, StyledCheckbox } from "./CompanyInformation.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import DeleteModal from "../../../components/src/DeleteModal";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
// Customizable Area End

import ProductAndServicesController, {
  Props,
} from "./ProductAndServicesController.web";

// Customizable Area Start
const StyledButtons = styled(Box)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down("sm")]: {
    gap: "1rem !important",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "2rem 0px 0.5rem 0rem !important",
  },
}));

const StyledTableHead = withStyles((theme: Theme) => ({
  root: {
    "& .MuiTableCell-root": {
      maxWidth: "38% !important",
      width: "100% !important",
      whiteSpace: "normal !important",
      padding: "unset !important",
      [theme.breakpoints.down("xs")]: {
        minWidth: "20% !important",
      }
    }
  }
}))(TableHead);

interface InterfaceProps {
  languageSelected: string;
}

const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    "& .MuiTableCell-root": {
      height: "103px !important",
      [theme.breakpoints.down("xs")]: {
        minWidth: "20% !important",
      }
    },
    "& .MuiTableCell-root:first-child, & .MuiTableCell-root:nth-child(2)": {
      [theme.breakpoints.down("xs")]: {
        paddingRight: (props: InterfaceProps) => props.languageSelected === "ar" ? 0 : "1rem !important",
      }
    },
    "&:hover": {
      backgroundColor: CONSTANTS.color.darkBlue,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& .MuiInputBase-root .MuiInputBase-input": {
        color: `${CONSTANTS.color.white} !important`,
      },
    },
  }
}))(TableRow);

const StyledDialog = withStyles((theme: Theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: "2rem 1.5rem",
      width: "100%",
      maxWidth: "657px",
      borderRadius: "20px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
      [theme.breakpoints.down("xs")]: {
        padding: "1.5rem 1rem !important",
      }
    }
  }
}))(Dialog);

export const StyledTextFields = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      }
    },
    "& .MuiOutlinedInput-input":{
      color: CONSTANTS.color.midGrey,
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0",
      marginRight: "0",
    }
  }
}))(TextField);

const renderDescriptionLength = (description: string, limit: number, languageSelected: string) => {
  const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
  
  const descriptionLengthArabic = String(description.length)
    .split('')
    .map(digit => arabicNumerals[parseInt(digit)])
    .join('');
  
  const limitArabic = String(limit)
    .split('')
    .map(digit => arabicNumerals[parseInt(digit)])
    .join('');
  
  const style = description.length > limit ? { color: CONSTANTS.color.red } : {};
  return (
    <span style={style}>
      {languageSelected === "ar" ? `(${descriptionLengthArabic}/${limitArabic})` : `(${description.length}/${limit})`}
    </span>
  );
}

const renderHeaderText = (isEdit: boolean, text: string, name: string, classes: ClassNameMap, t: Function) => {
  return (
    <Typography style={{ ...webStyle.headerText, ...webStyle.paddingStyle }} className={classes.addProductTitle} variant="subtitle1">
      {!isEdit ? `${t('settings.addNew')} ${text}` : `${(t('settings.edit'))} ${name}`}
    </Typography>
  );
}

const getDropdownSelectIconStyle = (condition: boolean, baseStyle: Record<string, string>) => ({
  ...baseStyle,
  transform: condition ? "rotate(-90deg)" : baseStyle.transform
});

const hasError = (errorCondition: string | undefined, lengthValue: number) => (
  Boolean(errorCondition || lengthValue > 500)
);

interface Image {
  id?: string;
  file?: File | Blob;
  url?: string;
}

interface ItemType {
  id: number;
  type: string;
  attributes: {
    name: string;
    cost: string;
    markup?: string;
    unit_price?: string;
  }
}
// Customizable Area End

export class ProductAndServices extends ProductAndServicesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start  
  renderProductsImages = (images: Image[], classes: ClassNameMap) => {
    const { isEmployee } = this.state;
    return images?.slice(0, 3)?.map((item: Image) => {
      let objectUrl;
      if (item instanceof File || item instanceof Blob) {
        if (typeof window.URL.createObjectURL === 'function') {
          objectUrl = window.URL.createObjectURL(item);
        } else if (typeof window.webkitURL?.createObjectURL === 'function') {
          objectUrl = window.webkitURL.createObjectURL(item);
        }
      }
      return (
        <Grid container spacing={2} key={item?.id}>
          <Grid item lg={4} md={4} xs={4}>
            <Box style={webStyle.deleteImageContainer}>
              <img
                style={webStyle.borderRadiusStyle}
                className={classes.productServicesImages}
                src={objectUrl || item?.url}
                width="173"
                height="187"
              />
              {!isEmployee && 
                <CancelOutlinedIcon
                  data-test-id="handleDeleteProductsImage"
                  style={webStyle.deleteImageIcon}
                  onClick={() => this.handleDeleteProductsImage(item)}
                />
              }
            </Box>
          </Grid>
        </Grid>
      );
    });
  }

  renderServicesImages = (images: Image[], classes: ClassNameMap) => {
    const { isEmployee } = this.state;
    return images?.slice(0, 3)?.map((item: Image) => {
      let objectUrl
      if (item instanceof File || item instanceof Blob) {
        if (typeof window.URL.createObjectURL === 'function') {
          objectUrl = window.URL.createObjectURL(item)
        } else if (typeof window.webkitURL?.createObjectURL === 'function') {
          objectUrl = window.webkitURL.createObjectURL(item);
        }
      }

      return (
        <Grid container spacing={2} key={item?.id}>
          <Grid item lg={4} md={4} xs={4}>
            <Box style={webStyle.deleteImageContainer}>
              <img
                style={webStyle.borderRadiusStyle}
                className={classes.productServicesImages}
                src={objectUrl || item?.url}
                width="173" height="187"
              />
              {!isEmployee && 
                <CancelOutlinedIcon
                  data-test-id="handleDeleteServicesImage"
                  style={webStyle.deleteImageIcon}
                  onClick={() => this.handleDeleteServicesImage(item)}
                />
              }
            </Box>
          </Grid>
        </Grid>
      );
    });
  }
  
  renderImagesLength = (images: Image[]) => {
    if (images.length > 3) {
      return <Typography style={webStyle.imagesLength} variant="subtitle2" component={"p"}>{`+${images.length - 3}`}</Typography>;
    }
    return null;
  }

  uploadButton = (minHeight: string, onChange: React.ChangeEventHandler<HTMLElement>, classes: ClassNameMap) => {
    const { t } = this.props;
    return (
      <Button
        style={{...webStyle.uploadFiles, minHeight}}
        className={classes.uploadFiles}
        variant="text"
        component="label"
        disableRipple
        disableElevation
        disableFocusRipple
      >
        <img
          style={webStyle.uploadIcon}
          src={uploadIcon}
          alt=""
        />&nbsp;&nbsp;&nbsp;
        <Typography style={webStyle.uploadText} variant="caption">
          <input
            data-test-id="handleSelectProductFiles"
            hidden
            multiple
            type="file"
            accept="image/*"
            onChange={onChange}
          />
          {t('settings.uploadFiles')}
        </Typography>
      </Button>
    );
  }

  renderFileUpload = (isUploaded: boolean, progress: number) => {
    return (
      <>
        {isUploaded && <FileUpload progress={progress} languageSelected={this.state.languageSelected} {...this.props} />}
      </>
    );
  }
  
  renderDeleteBoxContainer = (classes: ClassNameMap, itemId: number, selectedId: number, handleDelete: () => void, handleEdit: () => void) => {
    const { isEmployee } = this.state;
    if (itemId === selectedId && !isEmployee) {
      return (
        <Box style={webStyle.editDeleteContainer}>
          <Box
            data-test-id="handleEdit"
            style={webStyle.editBoxContainer}
            className={`${classes.deleteBoxStyle} ${classes.paddingStyle}`}
            onClick={handleEdit}
          >
            <img
              style={webStyle.deleteTaxIcon}
              className={classes.deleteTaxIcon}
              src={editProductsServicesIcon}
              alt=""
            />
          </Box>
          <Box
            data-test-id="handleDelete"
            style={webStyle.deleteBoxContainer}
            className={`${classes.deleteBoxStyle} ${classes.paddingStyle}`}
            onClick={handleDelete}
          >
            <img
              style={webStyle.deleteTaxIcon}
              className={classes.deleteTaxIcon}
              src={deleteTaxIcon}
              alt=""
            />
          </Box>
        </Box>
      );
    }
    return null;
  };
  
  viewServicesData = (classes: ClassNameMap) => {
    const { isEmployee, viewServices, selectedService, servicesData } = this.state;
    const { t } = this.props;
    return (
      <>
        {viewServices ? (
          <div style={webStyle.tableBodyContainer} className={classes.tableBodyContainer}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      style={
                        this.state.languageSelected === "ar" ?
                        {...webStyle.tableHeadText, ...webStyle.paddingRightStyle} :
                        {...webStyle.tableHeadText, ...webStyle.paddingLeftStyle}
                      }
                      className={classes.tableHeadText} variant="caption" color="textPrimary" component={"div"}>
                      {t('settings.serviceName')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableHeadText} style={webStyle.tableHeadText} variant="caption" color="textPrimary" component={"div"}>{t('settings.servicePrice')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableHeadText} style={webStyle.tableHeadText} variant="caption" color="textPrimary" component={"div"}>
                      {t('settings.markUpLabelPercent')}</Typography>
                  </TableCell>
                </TableRow>
              </StyledTableHead>
            </Table>
            <Table>
              <StyledTableHead>
                {servicesData?.map((item: ItemType) => (
                  <StyledTableRow
                    key={item.id}
                    data-test-id="handleSelectService"
                    onClick={() => this.handleSelectService(item.id as unknown as string, item.attributes.name)}
                    languageSelected={this.state.languageSelected}
                  >
                    <TableCell>
                      <Typography
                        style={
                          this.state.languageSelected !== "ar" ?
                          {...webStyle.tableBodyText, ...webStyle.fontWeightStyle} :
                          {...webStyle.tableBodyText, ...webStyle.fontWeightStyle, ...webStyle.paddingLeftRight}
                        }
                        className={classes.tableHeadText}
                        variant="caption" component={"div"}>
                        {item.attributes.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography style={webStyle.tableBodyText} className={classes.tableHeadText} variant="caption" component={"div"}>
                        {`${this.state.getCurrency} ${item.attributes.cost}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box style={webStyle.tableRowDeleteContainer}>
                        <Typography style={{...webStyle.tableBodyText, ...webStyle.tableBodyDelete}} className={classes.tableHeadText} variant="caption" component={"div"}>
                          {`${item.attributes.markup}%`}
                        </Typography>
                        {!isEmployee && this.renderDeleteBoxContainer(classes, item.id, selectedService, this.handleDeleteService, this.handleEditService.bind(this, item.attributes.name, item.id as unknown as string))}
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </StyledTableHead>
            </Table>
          </div>
        ) : null}
      </>
    );
  }

  viewProductsData = (classes: ClassNameMap) => {
    const { t } = this.props;
    const { isEmployee, viewProducts, selectedProduct, productsData, languageSelected } = this.state;
    return (
      <>
        {viewProducts ? (
          <div style={webStyle.tableBodyContainer} className={classes.tableBodyContainer}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      style={
                        languageSelected === "ar" ?
                        {...webStyle.tableHeadText, ...webStyle.paddingRightStyle} :
                        {...webStyle.tableHeadText, ...webStyle.paddingLeftStyle}
                      }
                      className={classes.tableHeadText}
                      component={"div"}
                      variant="caption"
                      color="textPrimary"
                    >
                      {t('settings.productName')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={webStyle.tableHeadText} className={classes.tableHeadText} variant="caption" color="textPrimary" component={"div"}>
                      {t('settings.productPrice')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={webStyle.tableHeadText} className={classes.tableHeadText} variant="caption" color="textPrimary" component={"div"}>
                      {t('settings.markUpLabelPercent')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </StyledTableHead>
            </Table>
            <Table>
              <StyledTableHead>
                {productsData?.map((item: ItemType) => (
                  <StyledTableRow
                    key={item.id}
                    data-test-id="handleSelectProduct"
                    onClick={()=> this.handleSelectProduct(item.id as unknown as string, item.attributes.name)}
                    languageSelected={this.state.languageSelected}
                  >
                    <TableCell>
                      <Typography
                        style={
                          languageSelected === "ar" ?
                          {...webStyle.tableBodyText, ...webStyle.fontWeightStyle, paddingLeft: 0, paddingRight: "2rem"} :
                          {...webStyle.tableBodyText, ...webStyle.fontWeightStyle}
                        }
                        className={classes.tableHeadText}
                        variant="caption"
                        component={"div"}
                      >
                        {item?.attributes?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography style={webStyle.tableBodyText} className={classes.tableHeadText} variant="caption" component={"div"}>
                        {`${this.state.getCurrency} ${item?.attributes?.unit_price}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box style={webStyle.tableRowDeleteContainer}>
                        <Typography style={{...webStyle.tableBodyText, ...webStyle.tableBodyDelete}} className={classes.tableHeadText} variant="caption" component={"div"}>
                          {`${item?.attributes?.markup}%`}
                        </Typography>
                        {!isEmployee && this.renderDeleteBoxContainer(classes, item.id, selectedProduct, this.handleDeleteProduct, this.handleEditProduct.bind(this, item.attributes.name, item.id as unknown as string))}
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </StyledTableHead>
            </Table>
          </div>
        ) : null}
      </>
    );
  }

  renderNoProductsDataUI = (classes: ClassNameMap)=>{
    const { isEmployee } = this.state
    const { t } = this.props;
    return this.state.productsData.length === 0 && (
      <Grid container style={webStyle.manageTaxGrid} spacing={2}>
        <Grid item lg={7} md={7} sm={7} xs={12}>
          <Box style={webStyle.manageTaxContainer}>
            <Typography style={webStyle.headerText} className={classes.addProductTitle} variant="subtitle1">
              {t('settings.productAndServices')}
            </Typography>
            <Typography style={webStyle.headerText} className={classes.productTitle} variant="subtitle2">
              {t('settings.productsAndServicesHeading')}
            </Typography>
            <Box style={webStyle.exemptTaxContainer}>
              <Typography style={webStyle.productServicesDesc} className={classes.productDesc} variant="body1" color="textPrimary" gutterBottom>
                {t('settings.productsAndServicesDesc')}
              </Typography>
              <Hidden smUp>
                <img
                  style={webStyle.productImage}
                  className={classes.productImage}
                  src={productImage}
                  alt=""
                />
              </Hidden>
            </Box>
            {!isEmployee && 
              <Button
                data-test-id="handleAddProducts"
                className={`${classes.buttonContainer} ${classes.buttonContainer1} ${classes.buttonCommonStyle}`}
                onClick={this.handleAddProducts}
              >
                <Typography
                  style={webStyle.buttonText}
                  className={classes.buttonTextCreate}
                  variant="subtitle2"
                >
                  {t('settings.product')}
                </Typography>
              </Button>
            }
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item lg={5} md={5} sm={5} xs={3} className={classes.gridImage}>
            <img
              style={webStyle.productImage}
              className={classes.productImage}
              src={productImage}
              alt=""
            />
          </Grid>
        </Hidden>
      </Grid>
    );
  }

  arrowButton = (languageSelected: string, viewProducts: boolean) => {
    return languageSelected === "ar" && { transform: viewProducts ? "rotate(0deg)" : "rotate(90deg)" }
  }

  renderProductsDataUI = (classes: ClassNameMap) => {
    const { isEmployee, productsData, viewProducts, languageSelected } = this.state;
    const { t } = this.props;
    return productsData.length > 0 && (
      <>
        <Box style={webStyle.productsContainer}>
          <Box
            data-test-id="handleViewAllProducts"
            style={webStyle.productsContainer}
            onClick={this.handleViewAllProducts}
          >
            <img
              style={{
                ...getDropdownSelectIconStyle(!viewProducts, webStyle.dropDownSelectIcon),
                ...(this.arrowButton(languageSelected, viewProducts))
              }}
              src={dropDownSelectIcon}
              alt=""
            />
            <Typography style={webStyle.headerText} className={classes.headerText} variant="subtitle1">
              {t('settings.products')}
            </Typography>
          </Box>
          <img
            style={webStyle.productImageSmall}
            src={productImage}
            alt=""
          />
        </Box>
        {this.viewProductsData(classes)}

        {!isEmployee && 
          <Button
            data-test-id="handleAddProducts"
            style={webStyle.buttonContainerStyle}
            className={`${classes.buttonContainer} ${classes.buttonContainer1} ${classes.buttonCommonStyle}`}
            onClick={this.handleAddProducts}
          >
            <Typography
              style={webStyle.buttonTextCreate}
              className={classes.buttonTextCreate}
            >
              {t('settings.createAProduct')}
            </Typography>
          </Button>
        }
      </>
    )
  }

  renderDeleteProductDialog = () => {
    const { t } = this.props;
    const { deleteProductsDialogOpen, deleteProductsLoading } = this.state;
    return (
      <>
        {deleteProductsDialogOpen &&
          <DeleteModal
            deleteDialogOpen={deleteProductsDialogOpen}
            handleCloseDialog={this.handleCloseProductsDeleteDialog}
            handleClickProceedDelete={this.handleClickProceedDeleteProducts}
            title={t('settings.deleteProduct')}
            description={t('settings.deleteProductConfirmation')}
            loader={<LoadingBackdrop loading={deleteProductsLoading} />}
          />
        }
      </>
    );
  }

  renderNoServiceUI = (classes: ClassNameMap) => {
    const { isEmployee, servicesData } = this.state;
    const { t } = this.props;
    return (
      <>
        {servicesData.length === 0 && (
          <Grid container style={webStyle.manageTaxGrid}>
            <Grid item lg={7} md={7} sm={7} xs={12} style={webStyle.renderNoServiceGrid}>
              <Box style={webStyle.manageTaxContainer}>
                <Typography style={webStyle.headerText} className={classes.productTitle} variant="subtitle2">
                  {t('settings.servicesHeading')}
                </Typography>
                <Box style={webStyle.exemptTaxContainer}>
                  <Typography style={webStyle.productServicesDesc} className={classes.productDesc} variant="body1" color="textPrimary" gutterBottom>
                    {t('settings.servicesDesc')}
                  </Typography>
                  <Hidden smUp>
                    <img
                      style={{...webStyle.productImage, ...webStyle.maxWidthStyle}}
                      className={classes.servicesImage}
                      src={servicesImage}
                      alt=""
                    />
                  </Hidden>
                </Box>
                {!isEmployee &&
                  <Button
                    data-test-id="handleAddServices"
                    className={`${classes.buttonContainer} ${classes.buttonContainer1} ${classes.buttonCommonStyle}`}
                    onClick={this.handleAddServices}
                  >
                    <Typography
                      style={webStyle.buttonText}
                      className={classes.buttonTextCreate}
                      variant="subtitle2"
                    >
                      {t('settings.services')}
                    </Typography>
                  </Button>
                }
              </Box>
            </Grid>
            <Hidden xsDown>
              <Grid item lg={5} md={5} sm={5} xs={3}>
                <img
                  style={{...webStyle.productImage, ...webStyle.maxWidthStyle}}
                  className={classes.servicesImage}
                  src={servicesImage}
                  alt=""
                />
              </Grid>
            </Hidden>
          </Grid>
        )}
      </>
    );
  }

  renderServicesUI = (classes: ClassNameMap) => {
    const { isEmployee, servicesData, viewServices, languageSelected } = this.state;
    const { t } = this.props;
    return servicesData.length > 0 && (
      <>
        <Box style={webStyle.productsContainer}>
          <Box
            data-test-id="handleViewAllServices"
            style={webStyle.productsContainer}
            onClick={this.handleViewAllServices}
          >
            <img
              style={{
                ...getDropdownSelectIconStyle(!viewServices, webStyle.dropDownSelectIcon),
                ...(this.arrowButton(languageSelected, viewServices))
              }}
              src={dropDownSelectIcon}
              alt=""
            />
            <Typography style={webStyle.headerText} className={classes.headerText} variant="subtitle1">
              {t('settings.services')}
            </Typography>
          </Box>
          <img
            style={webStyle.serviceImageSmall}
            src={servicesImage}
            alt=""
          />
        </Box>
        {this.viewServicesData(classes)}
        {!isEmployee &&
          <Button
            data-test-id="handleAddServices"
            style={webStyle.buttonCreateAService}
            className={`${classes.buttonContainer} ${classes.buttonContainer1} ${classes.buttonCommonStyle}`}
            onClick={this.handleAddServices}
          >
            <Typography
              style={webStyle.buttonTextCreate}
              className={classes.buttonTextCreate}
            >
              {t('settings.createAService')}
            </Typography>
          </Button>
        }
      </>
    );
  }

  renderDeleteServiceDialog = () => {
    const { t } = this.props;
    const { deleteServicesDialogOpen, deleteServicesLoading } = this.state;
    return (
      <>
        {deleteServicesDialogOpen &&
          <DeleteModal
            deleteDialogOpen={deleteServicesDialogOpen}
            handleCloseDialog={this.handleCloseServicesDeleteDialog}
            handleClickProceedDelete={this.handleClickProceedDeleteService}
            title={t('settings.deleteService')}
            description={t('settings.deleteServiceConfirmation')}
            loader={<LoadingBackdrop loading={deleteServicesLoading} />}
          />
        }
      </>
    )
  }

  renderStyledButtons = (classes: ClassNameMap) => {
    const { isEmployee, isEditProduct, createProductsLoading, updateProductLoading } = this.state;
    const { t } = this.props;
    return (
      !isEmployee &&
      <StyledButtons style={webStyle.buttonsContainer}>
        <Box style={webStyle.buttonOuterContainer}>
          <Button
            data-test-id="handleCloseProductsDialog"
            className={`${classes.deleteButtonContainer} ${classes.buttonContainerCancel} ${classes.buttonContainer} ${classes.buttonCommonStyle}`}
            onClick={this.handleCloseProductsDialog}
          >
            <Typography style={webStyle.buttonTextCancel} className={classes.buttonTextCreate} variant="subtitle2">
              {t('settings.cancel')}
            </Typography>
          </Button>
        </Box>
        {!isEditProduct ?
          <Button
            data-test-id="handleSaveProductsDialog"
            className={`${classes.deleteButtonContainer} ${classes.buttonContainerSave} ${classes.buttonContainer} ${classes.buttonCommonStyle}`}
            onClick={this.handleSaveProductsDialog}
          >
            <Typography style={webStyle.buttonTextSave} className={classes.buttonTextCreate} variant="subtitle2">
              {t('settings.save')}
              <LoadingBackdrop loading={createProductsLoading} />
            </Typography>
          </Button>
        :
          <Button
            data-test-id="handleUpdateProductsDialog"
            className={`${classes.deleteButtonContainer} ${classes.buttonContainerSave} ${classes.buttonContainer} ${classes.buttonCommonStyle}`}
            onClick={this.handleUpdateProductsDialog}
          >
            <Typography style={webStyle.buttonTextSave} className={classes.buttonTextCreate} variant="subtitle2">
              {t('settings.update')}
              <LoadingBackdrop loading={updateProductLoading} />
            </Typography>
          </Button>
        }
      </StyledButtons>
    );
  }

  renderLoadingBackdrop = () => {
    return (
      <LoadingBackdrop
        loading={this.state.getAllProductsLoading || this.state.getAllServicesLoading}
      />
    );
  }

  renderErrorMessage = (message: string | undefined, classes: ClassNameMap) => {
    return (
      <Typography variant="body1" style={webStyle.errorMessage} className={classes.productTitle}>
        {message}
      </Typography>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const {
      isEmployee,
      serviceImages,
      productsData,
      servicesData,
      productsDialogOpen,
      isEditProduct,
      productImages,
      isUploadedProducts,
      productProgress,
      viewProductLoading,
      servicesDialogOpen,
      isEditService,
      createServicesLoading,
      updateServiceLoading,
      viewServiceLoading,
      isUploadedServices,
      serviceProgress,
      serviceExemptTax,
      productExemptTax,
      languageSelected
    } = this.state;
    const { serviceName, serviceDescription } = this.state.serviceFormData;
    const { serviceCost, serviceMarkUp, serviceTotalPrice } = this.state.servicePriceFormData;
    const { productName, productDescription } = this.state.productFormData;
    const { markUp, unitPrice, totalPrice } = this.state.productPriceFormData;
    const { t } = this.props;

    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.settings')} active={false} active1={false} active2={1} />
        <SettingsSideBar location={undefined} progress={100} {...this.props} active={1} />
        <div style={webStyle.containerDiv} className={(productsData.length > 0 || servicesData.length > 0) ? "custom-scrollbar" : ""}>
          <StyledBox languageSelected={this.state.languageSelected}>
            <Box sx={webStyle.boxContainerProductServices} className={classes.boxContainerProductServices}>
              <Container maxWidth={false}>
                <Box>
                  {this.renderNoProductsDataUI(classes)}
                  
                  {this.renderProductsDataUI(classes)}
                  {this.renderDeleteProductDialog()}

                  <span style={webStyle.hr}></span>

                  {this.renderNoServiceUI(classes)}
                  {this.renderServicesUI(classes)}

                  {this.renderDeleteServiceDialog()}

                  <StyledDialog
                    open={productsDialogOpen}
                    onClose={this.handleCloseProductsDialog}
                    aria-labelledby="responsive-dialog-title"
                  >
                    {renderHeaderText(isEditProduct, t('settings.product'), this.state.productName, classes, t)}
                    <Grid container spacing={3}>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                          {t('settings.nameOfTheProduct')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                        </Typography>
                        <StyledTextFields
                          disabled = {isEmployee}
                          data-test-id="handleProductChange"
                          fullWidth
                          variant="outlined"
                          placeholder={t('settings.nameOfTheProductEg')}
                          name="productName"
                          value={productName}
                          onChange={this.handleProductChange}
                          error={Boolean(this.state.isErrorProductFormData.productName)}
                        />
                        {this.renderErrorMessage(this.state.isErrorProductFormData.productName, classes)}
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                          {t('settings.productDesc')}
                        </Typography>
                        <StyledTextFields
                          disabled = {isEmployee}
                          data-test-id="handleProductChange"
                          fullWidth
                          variant="outlined"
                          multiline
                          minRows={6}
                          InputProps={{ maxLength: 500 } as InputProps}
                          helperText={
                            <div style={webStyle.helperTextStyle}>
                              <Typography
                                variant="body1"
                                style={{...webStyle.errorMessage, ...webStyle.marginTopStyle}}
                              >
                                {this.state.isErrorProductFormData.productDescription}
                              </Typography>
                              {renderDescriptionLength(productDescription, 500, languageSelected)}
                            </div>
                          }
                          name="productDescription"
                          value={productDescription}
                          onChange={this.handleProductChange}
                          error={hasError(this.state.isErrorProductFormData.productDescription, productDescription.length)}
                        />
                      </Grid>
                      
                      <Grid item lg={12} md={12} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography
                              style={webStyle.formText}
                              className={classes.productTitle}
                              variant="body1"
                              color="textPrimary"
                              gutterBottom
                            >
                              {t('settings.price')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                            </Typography>
                            <StyledTextFields
                              disabled = {isEmployee}
                              data-test-id="handleProductPrice"
                              fullWidth
                              variant="outlined"
                              placeholder={`${t('settings.unitPrice')} (${this.state.getCurrency}):${configJSON.zeroPlaceholder}`}
                              name="unitPrice"
                              value={unitPrice}
                              onChange={this.handleProductPrice}
                              error={Boolean(this.state.isErrorProductPriceFormData.unitPrice)}
                            />
                            {this.renderErrorMessage(this.state.isErrorProductPriceFormData.unitPrice, classes)}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                              {t('settings.markUpLabel')}
                            </Typography>
                            <StyledTextFields
                              disabled = {isEmployee}
                              data-test-id="handleProductPrice"
                              fullWidth
                              variant="outlined"
                              placeholder={t('settings.markupPlaceholder')}
                              name="markUp"
                              value={markUp}
                              onChange={this.handleProductPrice}
                              error={Boolean(this.state.isErrorProductPriceFormData.markUp)}
                            />
                            {this.renderErrorMessage(this.state.isErrorProductPriceFormData.markUp, classes)}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography style={{...webStyle.formText, ...webStyle.visibilityStyle}} className={classes.visibilityStyle} variant="body1" color="textPrimary" gutterBottom>
                              {t('settings.total')}
                            </Typography>
                            <Box style={webStyle.totalPriceContainer} className={classes.totalPriceContainer}>
                              <Typography
                                variant="body1"
                                style={webStyle.totalPriceText}
                                className={classes.productTitle}
                              >
                                {`${t('settings.totalPrice')} (${this.state.getCurrency}):`}{totalPrice}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Box style={webStyle.exemptTaxContainer}>
                          <StyledCheckbox
                            disabled={isEmployee}
                            data-test-id="handleCheckProductExemptTax"
                            color="primary"
                            checked={productExemptTax}
                            onChange={this.handleCheckProductExemptTax}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary">
                            {t('settings.exemptTax')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography style={webStyle.formText} variant="body1" color="textPrimary" gutterBottom>
                          {t('settings.productImages')}
                        </Typography>
                        {productImages.length > 0 && (
                          <Box style={webStyle.uploadImageStyles}>
                            {this.renderProductsImages(productImages, classes)}
                            {this.renderImagesLength(productImages)}
                          </Box>
                        )}
                        {!isEmployee &&  productImages.length > 0 ? (
                          <Box style={webStyle.uploadButtonStyle}>
                            {this.uploadButton("55px", this.handleSelectProductFiles, classes)}
                          </Box>
                        ) : (
                          !isEmployee && this.uploadButton("130px", this.handleSelectProductFiles, classes)
                        )}
                      </Grid>
                    </Grid>
                    {this.renderFileUpload(isUploadedProducts, productProgress)}
    
                    {this.renderStyledButtons(classes)}
                    <LoadingBackdrop loading={viewProductLoading} />
                  </StyledDialog>

                  <StyledDialog
                    open={servicesDialogOpen}
                    onClose={this.handleCloseServicesDialog}
                    aria-labelledby="responsive-dialog-title"
                  >
                    {renderHeaderText(isEditService, t('settings.service'), this.state.serviceName, classes, t)}
                    <Grid container spacing={3}>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                          {t('settings.nameOfTheService')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                        </Typography>
                        <StyledTextFields
                          disabled={isEmployee}
                          data-test-id="handleServiceChange"
                          fullWidth
                          variant="outlined"
                          placeholder={t('settings.serviceNameEg')}
                          name="serviceName"
                          value={serviceName}
                          onChange={this.handleServiceChange}
                          error={Boolean(this.state.isErrorServiceFormData.serviceName)}
                        />
                        {this.renderErrorMessage(this.state.isErrorServiceFormData.serviceName, classes)}
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                          {t('settings.serviceDesc')}
                        </Typography>
                        <StyledTextFields
                          disabled={isEmployee}
                          data-test-id="handleServiceChange"
                          fullWidth
                          variant="outlined"
                          multiline
                          minRows={6}
                          InputProps={{ maxLength: 500 } as InputProps}
                          helperText={
                            <div style={webStyle.helperTextStyle}>
                              <Typography
                                variant="body1"
                                style={{...webStyle.errorMessage, ...webStyle.marginTopStyle}}
                              >
                                {this.state.isErrorServiceFormData.serviceDescription}
                              </Typography>
                              {renderDescriptionLength(serviceDescription, 500, languageSelected)}
                            </div>
                          }
                          name="serviceDescription"
                          value={serviceDescription}
                          onChange={this.handleServiceChange}
                          error={hasError(this.state.isErrorServiceFormData.serviceDescription, serviceDescription.length)}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                              {t('settings.price')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                            </Typography>
                            <StyledTextFields
                              disabled={isEmployee}
                              data-test-id="handleServicePrice"
                              fullWidth
                              variant="outlined"
                              placeholder={`${t('settings.cost')} (${this.state.getCurrency}):${configJSON.zeroPlaceholder}`}
                              name="serviceCost"
                              value={serviceCost}
                              onChange={this.handleServicePrice}
                              error={Boolean(this.state.isErrorServicePriceFormData.serviceCost)}
                            />
                            {this.renderErrorMessage(this.state.isErrorServicePriceFormData.serviceCost, classes)}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                              {t('settings.markUpLabel')}
                            </Typography>
                            <StyledTextFields
                              disabled={isEmployee}
                              data-test-id="handleServicePrice"
                              fullWidth
                              variant="outlined"
                              placeholder={t('settings.markupPlaceholder')}
                              name="serviceMarkUp"
                              value={serviceMarkUp}
                              onChange={this.handleServicePrice}
                              error={Boolean(this.state.isErrorServicePriceFormData.serviceMarkUp)}
                            />
                            {this.renderErrorMessage(this.state.isErrorServicePriceFormData.serviceMarkUp, classes)}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography style={{ ...webStyle.formText, ...webStyle.visibilityStyle }} className={classes.visibilityStyle} variant="body1" color="textPrimary" gutterBottom>
                              {t('settings.total')}
                            </Typography>
                            <Box style={webStyle.totalPriceContainer} className={classes.totalPriceContainer}>
                              <Typography variant="body1" style={webStyle.totalPriceText} className={classes.productTitle}>
                              {`${t('settings.totalPrice')} (${this.state.getCurrency}):`}{serviceTotalPrice}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Box style={webStyle.exemptTaxContainer}>
                          <StyledCheckbox
                            disabled={isEmployee}
                            data-test-id="handleCheckServiceExemptTax"
                            color="primary"
                            checked={serviceExemptTax}
                            onChange={this.handleCheckServiceExemptTax}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary">
                            {t('settings.exemptTax')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography style={webStyle.formText} className={classes.productTitle} variant="body1" color="textPrimary" gutterBottom>
                          {t('settings.serviceImages')}
                        </Typography>
                        {serviceImages.length > 0 && (
                          <Box style={webStyle.uploadImageStyles}>
                            {this.renderServicesImages(serviceImages, classes)}
                            {this.renderImagesLength(serviceImages)}
                          </Box>
                        )}
                        {!isEmployee && serviceImages.length > 0 ? (
                          <Box style={webStyle.uploadButtonStyle} data-test-id="handleSelectServicesFiles">
                            {this.uploadButton("55px", this.handleSelectServicesFiles, classes)}
                          </Box>
                        ) : (
                          !isEmployee && this.uploadButton("130px",this.handleSelectServicesFiles, classes)
                        )}
                      </Grid>
                    </Grid>
                    {this.renderFileUpload(isUploadedServices, serviceProgress)}
                    {!isEmployee &&
                      <StyledButtons style={webStyle.buttonsContainer}>
                        <Box style={webStyle.buttonOuterContainer}>
                          <Button
                            data-test-id="handleCloseServicesDialog"
                            className={`${classes.deleteButtonContainer} ${classes.buttonContainerCancel} ${classes.buttonContainer} ${classes.buttonCommonStyle}`}
                            onClick={this.handleCloseServicesDialog}
                          >
                            <Typography style={webStyle.buttonTextCancel} className={classes.buttonTextCreate} variant="subtitle2">
                              {t('settings.cancel')}
                            </Typography>
                          </Button>
                        </Box>
                        {!isEditService ?
                          <Button
                            data-test-id="handleSaveServicesDialog"
                            className={`${classes.deleteButtonContainer} ${classes.buttonContainerSave} ${classes.buttonContainer} ${classes.buttonCommonStyle}`}
                            onClick={this.handleSaveServicesDialog}
                          >
                            <Typography style={webStyle.buttonTextSave} className={classes.buttonTextCreate} variant="subtitle2">
                              {t('settings.save')}
                              <LoadingBackdrop loading={createServicesLoading} />
                            </Typography>
                          </Button>
                        :
                          <Button
                            data-test-id="handleUpdateServicesDialog"
                            className={`${classes.deleteButtonContainer} ${classes.buttonContainerSave} ${classes.buttonContainer} ${classes.buttonCommonStyle}`}
                            onClick={this.handleUpdateServicesDialog}
                          >
                            <Typography style={webStyle.buttonTextSave} className={classes.buttonTextCreate} variant="subtitle2">
                              {t('settings.update')}
                              <LoadingBackdrop loading={updateServiceLoading} />
                            </Typography>
                          </Button>
                        }
                      </StyledButtons>
                    }
                    <LoadingBackdrop loading={viewServiceLoading} />
                  </StyledDialog>
                </Box>
              </Container>
            </Box>
          </StyledBox>
        </div>
       {this.renderLoadingBackdrop()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainerProductServices: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.25rem 8rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0rem 3rem !important"
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "49px !important"
    },
    [theme.breakpoints.down(480)]: {
      maxWidth: "100% !important",
    },
  },
  buttonContainer1: {
    maxWidth: "168px",
    minHeight: "67px",
    backgroundColor: CONSTANTS.color.darkBlue,
    marginTop: "0.5rem",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        cursor: "pointer",
    },
  },
  deleteButtonContainer: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "47px !important"
    },
    [theme.breakpoints.down(480)]: {
      maxWidth: "100% !important",
    },
  },
  buttonTextCreate: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important"
    }
  },
  headerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    }
  },
  addProductTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important"
    }
  },
  productTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  productDesc: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important"
    }
  },
  tableHeadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px !important"
    }
  },
  buttonCommonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: "100%",
    borderRadius: "10px",
    textTransform: "none" as const
  },
  uploadFiles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
  },
  deleteBoxStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "40px !important"
    }
  },
  deleteTaxIcon: {
    [theme.breakpoints.down("xs")]: {
      width: "24px !important",
      height: "24px !important",
    }
  },
  tableBodyContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0px 0px 0.5rem !important"
    }
  },
  gridImage: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      justifyContent: "center !important",
    }
  },
  productServicesImages: {
    [theme.breakpoints.down(660)]: {
      width: "84px !important",
      height: "90px !important",
    }
  },
  visibilityStyle: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  totalPriceContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start !important",
    }
  },
  productImage: {
    [theme.breakpoints.down("xs")]: {
      width: "84px !important",
      height: "auto"
    },
  },
  servicesImage: {
    [theme.breakpoints.down("xs")]: {
      width: "124px !important",
      height: "auto"
    },
  },
  paddingStyle: {
    [theme.breakpoints.down("xs")]: {
      padding: "5px !important"
    }
  },
  buttonContainerSave: {
    minHeight: "56px",
    maxWidth: "156px",
    backgroundColor: CONSTANTS.color.darkBlue,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    }
  },
  buttonContainerCancel: {
    minHeight: "56px",
    maxWidth: "156px",
    border: `1px solid ${CONSTANTS.color.crimsonRed}`,
    color:CONSTANTS.color.crimsonRed,
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: '#ff5454',
      color: theme.palette.common.white,
      cursor: "pointer"
    },
  },
});

const webStyle = {
  boxContainerProductServices: {
    marginTop: "7rem",
    paddingBottom: "10rem"
  },
  containerDiv: {
    overflowY: "scroll" as const,
  },
  headerText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  paddingStyle: {
    paddingBottom: "1rem"
  },
  manageTaxGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  renderNoServiceGrid: {
    display:"flex",
    alignSelf:"flex-start"
  },
  manageTaxContainer: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "20px",
  },
  productServicesDesc: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "27px",
  },
  productImage: {
    width: "100%",
    maxWidth: "249px",
  },
  maxWidthStyle: {
    maxWidth: "392px"
  },
  buttonCreateAService: {
    maxWidth: "211px",
    minHeight: "56px",
    marginTop: "1.5rem"
  },
  buttonContainerStyle: {
    maxWidth: "211px",
    minHeight: "56px",
    marginTop: "1.5rem"
  },
  buttonText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  buttonTextCreate: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
    fontSize: "22px",
  },
  hr: {
    display: "flex",
    height: "2px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "2rem 0 2rem"
  },
  formText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  visibilityStyle: {
    visibility: "hidden" as const
  },
  uploadImageStyles: {
    display: "flex",
    position: "relative" as const
  },
  uploadButtonStyle: {
    paddingTop: "1rem"
  },
  totalPriceContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "58px",
    backgroundColor: CONSTANTS.color.softBlueGrey,
    borderRadius: "10px",
    padding: "0 10px"
  },
  totalPriceText: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  redColor: {
    color: CONSTANTS.color.red
  },
  exemptTaxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  buttonsContainer: {
    padding: "2rem 0rem 0.5rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonOuterContainer: {
    display: 'flex',
    width: "100%"
  },
  buttonTextCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  buttonTextSave: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  imagesLength: {
    position: "absolute" as const,
    color: CONSTANTS.color.white,
    top: "50%",
    left: "80%",
    transform: "translate(-50%, -50%)",
  },
  borderRadiusStyle: {
    borderRadius: "8px"
  },
  deleteImageContainer: {
    position: "relative" as const,
    display: "inline-block"
  },
  deleteImageIcon: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    cursor: "pointer",
    color: CONSTANTS.color.black
  },
  uploadFiles: {
    gap: "15px",
    minHeight: "130px",
    borderRadius: "8px",
    border: `2px dashed ${CONSTANTS.color.darkBlue}`,
    backgroundColor: CONSTANTS.color.invisibleBlue,
  },
  uploadIcon: {
    width: "21px",
    height: "20px",
  },
  uploadText: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
    textAlign: "center" as const,
    textTransform: "capitalize" as const
  },
  helperTextStyle: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: CONSTANTS.color.white
  },
  errorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  marginTopStyle: {
    marginTop: "0px"
  },
  productsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
    cursor: "pointer",
    paddingBottom: "1rem",
  },
  tableBodyContainer: {
    cursor: "pointer",
    padding: "1rem 0px 0px 0.5rem",
    width: "100%",
    borderRadius: "24px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
  },
  tableHeadText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
    textAlign: "start" as const,
  },
  paddingRightStyle: {
    paddingRight: "1.5rem"
  },
  paddingLeftStyle: {
    paddingLeft: "1.5rem"
  },
  tableBodyText: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    wordBreak: "break-all" as const,
    textAlign: "start" as const,
  },
  fontWeightStyle: {
    paddingLeft: "2rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
  },
  paddingLeftRight: {
    paddingLeft: 0,
    paddingRight: "2rem"
  },
  tableBodyDelete: {
    maxWidth: "none",
    width: "auto",
    wordBreak: "normal" as const
  },
  tableRowDeleteContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem"
  },
  editBoxContainer: {
    maxWidth: "87px",
    minHeight: "103px",
    backgroundColor: CONSTANTS.color.softBlue,
  },
  editDeleteContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: '100%'
  },
  deleteBoxContainer: {
    maxWidth: "87px",
    minHeight: "103px",
    backgroundColor: CONSTANTS.color.pastelPink,
  },
  deleteTaxIcon: {
    width: "39px",
    height: "39px",
  },
  dropDownSelectIcon: {
    width: "16px",
    height: "10px",
  },
  productImageSmall: {
    width: "89px",
    height: "81px",
  },
  serviceImageSmall: {
    width: "127px",
    height: "92px",
  },
}

const productAndServicesStyle = withStyles(styles)(ProductAndServices);
export default withTranslation()(withSnackbar(productAndServicesStyle));
// Customizable Area End