import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { View } from "react-big-calendar";
import { getStorageData } from "../../../framework/src/Utilities";
const mockDashboardCard = [
  {
    id: 1,
    title: "Jobs",
    count: "",
    percentage: "",
    description: "",
  },
  {
    id: 2,
    title: "Tasks",
    count: "",
    percentage: "",
    description: "",
  },
];
export interface JobRes{
  "data":{
      "job":{
          "number_of_jobs":string;
          "compared_to_last":string;
          "status":string
      },
      "tasks":{
        "number_of_tasks":string;
        "compared_to_last":string;
        "status":string
    }
  }
}
export interface MockDashboardCard{
  id: number,
  title: string,
  count: string,
  percentage: string,
  description: string,
}
export interface CalEvent{
  "id": string,
  "type": string,
  "start": string,
  "end": string,
  "des": string
  "attributes": {
      "id": number,
      "title": string,
      "schedule_later": boolean,
      "description": string,
      "start_date": string,
      "start_time": string,
      "end_date": string,
      "end_time": string,
      "mark_as_complete": boolean,
      "all_day": boolean,
      "employees": [
          {
              "id": number,
              "email": string,
              "name": string
              
          },
      ],
      "employee_image": [
          {
              "id": number,
              "size": number,
              "url": string,
              "name": string,
              "content_type": string
          }
      ]
  },
  "title": string,
}
export interface ConvertedEvent{
  "title": string,
  "start_date": string,
  "end_date": string,
  "start_time": string | null,
  "end_time": string | null,
  "start": string|Date,
  "end": string|Date
}
const mockCalendar = [
  { id: 1, title: "Weekly", view: "week" },
  { id: 2, title: "Monthly", view: "month" },
]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler:Function,
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mockCalendarStatus: any;
  
  today: any;
  weekdays: any;
  monthlyButton: boolean;
  dashboardCard: any;
  calendarStatus: any;
  view:View| undefined,
  selectedDate: any;
 
  dashboardLoading: boolean;
  center: {
    lat: number;
    lng: number;
  };
  jobsData: any;
  tasksData: any;
  events: CalEvent[] | undefined
  markerPlaces: any;
  startDate:string
  toggleJob:boolean;
  toggleTask:boolean;
  toggleTimesheet:boolean;
  lang:string,
  status: string;
  dateModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  datePickerRef: any;
  mapRef: any;
  getEmployeeDashboardDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      mockCalendarStatus: "Weekly",
     
      today: "",
      weekdays: "",
      monthlyButton: false,
      dashboardCard: mockDashboardCard,
      calendarStatus: mockCalendar,
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
     
      dashboardLoading: false,
      view:'week',
      center: { lat: 45.5344, lng: 23.555 },
      jobsData: [],
      tasksData: [],
      events:[],
      markerPlaces: [],
      startDate:'',
      toggleJob:false,
      toggleTask:false,
      toggleTimesheet:false,
      lang:"en",
      status: "Today",
      dateModal: false,
    };
    this.datePickerRef = React.createRef<any>();
    this.mapRef = React.createRef<any>();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const lang = await getStorageData("lang")
    this.setState({ lang });
    if(lang === "ar") {
      this.setState({ mockCalendarStatus: "أسبوعي", status: "اليوم" });
    }
    const token = localStorage.getItem("authToken")
    this.checkAuthTokenEmpDashboard(token)
   
   
    this.getEmployeeDashboardData();
    this.setState({ dashboardLoading: true });
    // Customizable Area End
  }

  // Customizable Area Start
  checkAuthTokenEmpDashboard = (token:any)=>{
    if (!token) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }
  }
  errorMessageHandler = (error: any) => {
    const { token } = error
    if(token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel)
      this.tokenExpired();
    }
  }

  errorResponseMessage = (error: string) => error && this.errorMessageHandler(error);

  tokenExpired = () => setTimeout(this.setTimeOutHandler, 2000);

  setTimeOutHandler = () => {
    localStorage.clear()
    this.props.navigation.history?.push("/Employee/Login")
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getEmployeeDashboardDataApiCallId) {
        const { t } = this.props;
        if (!responseJson.errors) {
          this.setState({
            dashboardLoading: false,
            jobsData:responseJson.jobs, 
            tasksData: responseJson.tasks,
            markerPlaces: responseJson.map
          });
          if(this.state.status === t('dashboard.custom')) {
            this.setState({
              selectedDate: {
                ...this.state.selectedDate,
                startDate: new Date(responseJson.start_date),
                endDate: new Date(responseJson.end_date)
              }
            });
          }
          responseJson.schedule.data.forEach((item: ConvertedEvent) => {
            let title = item.title
            item.title = title; 
            item.start = new Date(item.start_date);
            item.end = new Date(item.end_date);
            return item
          })
          this.setState({ events: responseJson.schedule.data || [] })
          this.markersLocations();
          this.handleCardData(responseJson);
        } else if (responseJson.errors) {
          this.setState({ dashboardLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ dashboardLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  markersLocations = () => {
    const map = this.mapRef.current;
    if (map && this.state.markerPlaces?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      this.state.markerPlaces.forEach((marker: any) => {
        bounds.extend(new window.google.maps.LatLng(marker?.latitude, marker?.longitude));
      });

      map.fitBounds(bounds);
    }
  };
  
  handleCardData = (responseJson: JobRes) => {
    const getCount = this.state.dashboardCard.map((item: MockDashboardCard) => {
      if(item.title === "Jobs") {
        item.count = responseJson.data?.job?.number_of_jobs
        item.percentage = responseJson.data?.job?.compared_to_last
        item.description = responseJson.data?.job?.status
      } else if (item.title === "Tasks") {
        item.count = responseJson.data?.tasks?.number_of_tasks
        item.percentage = responseJson.data?.tasks?.compared_to_last
        item.description = responseJson.data?.tasks?.status
      }
      return item;
    });
    this.setState({ dashboardCard: getCount });
  }
  
  handleChangeMonthlyWeekly = (item: any) => {
    this.setState({
      mockCalendarStatus: item.title,
      view: item.view
    }, () => {
      this.getEmployeeDashboardData();
      this.setState({ dashboardLoading: true });
    });
  }

  handleEmpDateModalOpen = () => {
    this.setState({ dateModal: !this.state.dateModal });
  }
  handleEmpDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    this.setState({
      selectedDate: { ...this.state.selectedDate, startDate: start, endDate: end },
    }, () => {
      if(start && end) {
        this.getEmployeeDashboardData();
        this.setState({ dashboardLoading: true });
        this.setState({ dateModal: false });
      }
    });
  };
  handleEmpStatusModalClose = () => {
    const { t } = this.props;
    if(this.state.status !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }

  handleEmpSelectStatus = (item: { title: string; }) => {
    const { t } = this.props;
    const { startDate, endDate } = this.state.selectedDate;
    this.setState({ status: item?.title }, () => {
      if(item?.title === t('dashboard.custom')) {
        if(startDate && endDate) {
          this.getEmployeeDashboardData();
          this.setState({ dashboardLoading: true });
        }
      } else {
        this.getEmployeeDashboardData();
        this.setState({ dashboardLoading: true });
      }
    });
    if(item?.title !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }


  handleNavigate = (route: string) => {
    this.props.navigation.history?.push(route);
  }
  onMapGoogleLoad = (map: google.maps.Map) => {
    this.mapRef.current = map;
    const zoomInButton = document.createElement('button');
    zoomInButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
        <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
    </svg>`;
    zoomInButton.style.backgroundColor = 'white';
    zoomInButton.style.marginTop = '10px';
    zoomInButton.style.marginBottom = '5px';
    zoomInButton.style.padding = '6px 3px 0px 5px';
    zoomInButton.style.borderRadius = '4px';
    zoomInButton.style.border = 'none';
    zoomInButton.style.paddingLeft = '5px';
    zoomInButton.style.cursor = 'pointer';
    zoomInButton.title = 'Zoom In';
    zoomInButton.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
    const zoomOutButton = document.createElement('button');
    zoomOutButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
    </svg>`;
    zoomOutButton.style.backgroundColor = 'white';
    zoomOutButton.style.marginBottom = '5px';
    zoomOutButton.style.padding = '6px 3px 0px 5px';
    zoomOutButton.style.borderRadius = '4px';
    zoomOutButton.style.paddingLeft = '5px';
    zoomOutButton.style.border = 'none';
    zoomOutButton.style.cursor = 'pointer';
    zoomOutButton.title = 'Zoom Out';
  
    zoomOutButton.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
  
    const controlDiv = document.createElement('div');
    controlDiv.style.position = 'absolute';
    controlDiv.style.marginRight = '20px';
    controlDiv.style.marginBottom = '20px';
    controlDiv.style.bottom = '100px';
    controlDiv.style.right = '10px';
    controlDiv.style.display = 'flex';
    controlDiv.style.flexDirection = 'column';
    controlDiv.style.zIndex = '1';
    controlDiv.style.justifyContent = 'space-between'; 
    controlDiv.appendChild(zoomInButton);
    controlDiv.appendChild(zoomOutButton);
  
    const controlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
    map.controls[controlPosition].push(controlDiv);
  }; 
  statusEmpAr = (status: string) => {
    let statusAr;
    if(status === "اليوم") {
      statusAr = "today";
    } else if(status === "هذا الاسبوع") {
      statusAr = "this_week";
    } else if(status === "هذا الشهر") {
      statusAr = "this_month";
    } else if(status === "هذا العام") {
      statusAr = "this_year";
    } else if(status === "مخصص") {
      statusAr = "custom";
    }
    return statusAr;
  }

  getEmployeeDashboardData = () => {
    const { mockCalendarStatus,  status } = this.state;
    const { startDate, endDate } = this.state.selectedDate;
    const lang = localStorage.getItem('lang')
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": localStorage.getItem(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmployeeDashboardDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDashboardDataAPIEndPoint}?start_date=${startDate}&end_date=${endDate}&graph=${mockCalendarStatus?.toLowerCase()}&date_filter=${lang === "ar" ? this.statusEmpAr(status) : status?.toLowerCase().replace(/ /g, "_")}&lang=${lang ? lang : "en"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDashboardDataAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  toggleJobs = (isMobile:boolean) => isMobile && this.setState({ toggleJob: !this.state.toggleJob });
  toggleTasks = (isMobile:boolean) => isMobile && this.setState({ toggleTask: !this.state.toggleTask })
  toggleTimesheet = (isMobile:boolean) => isMobile && this.setState({ toggleTimesheet: !this.state.toggleTimesheet })
}
// Customizable Area End