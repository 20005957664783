import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { docExtensionIcon, jpgExtensionIcon, pdfExtensionIcon, pngExtensionIcon, pptExtensionIcon, txtExtensionIcon, xlsExtensionIcon } from "./assets";
// Customizable Area Start
import {WithStyles} from"@material-ui/core"
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  history: any
  // Customizable Area Start
  type: string
  editedinternalAttachements: any
  internalNotes: string
  editStatus: boolean
  updateInternalNotes: Function
  openToastHandler: Function
  updateInternalAttachments: Function
  internalAttachments: any
  getConvertedFiles: Function
  convertedFiles: Function
  getEditedFiles:Function
  t?: (key: string) => string;
  languageSelected: string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  internalNotes: string,
  active: any;
  files: any;
  editedFiles: any;
  convertedFiles: any;
  progress: any;
  completed: boolean;
  count: number;
  extensionIcons: any;
  extensionAllowed: any;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ResuableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wrapperRef: any;
  allCustomersApiCallId: string = "";
  getCustomerSitesApiCallId: string = ""
  getEmployeesApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      internalNotes: "",
      active: 0,
      files: [],
      editedFiles: [],
      convertedFiles: [],
      progress: 0,
      completed: false,
      count: 0,
      extensionIcons: {
        jpg: jpgExtensionIcon,
        png: pngExtensionIcon,
        jpeg: jpgExtensionIcon,
        doc: docExtensionIcon,
        pdf: pdfExtensionIcon,
        xls: xlsExtensionIcon,
        txt: txtExtensionIcon,
        ppt: pptExtensionIcon,
        docx: docExtensionIcon,
        xlsx: xlsExtensionIcon,
        pptx: pptExtensionIcon,
      },
      extensionAllowed: ["jpg", "png"],

      // Customizable Area End
    };
    this.wrapperRef = React.createRef<any>();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    this.props.updateInternalNotes(this.state.internalNotes)
    // Customizable Area End
  }

  async componentWillReceiveProps(props: Props) {
    if (this.props.type === 'edit') {
      this.setState({
        internalNotes:props.internalNotes,
        editedFiles: props.editedinternalAttachements || [],
        completed: props.editedinternalAttachements?.length > 0 ? true : false,
        files: props.internalAttachments || [],
        convertedFiles: props.convertedFiles || []
      })

    }
  }

  // Customizable Area Start
  handleInternalNotesMessage = (event: any) => {
    this.setState({ internalNotes: event.target.value }, () => {
      this.props.updateInternalNotes(this.state.internalNotes)
    })

  }
  removeFileCallBack = () => {
    if (this.state.files.length === 0 && this.state.editedFiles.length === 0)
      this.setState({ completed: false, count: 0, progress: 0 });
  };
  removeUploadFile = (file: any) => {
    const { files, editedFiles } = this.state;
    const filesFiltered = files.filter((f: any) => {
      return f.name !== file.name;
    });
    const filesEditFiltered = editedFiles.filter((f: any) => {
      return f.id != file.id;
    });

    const filesRemoved = editedFiles.filter((f: any) => {
      return file.id == f.id;
    });

    this.setState(
      {
        files: filesFiltered,
        editedFiles: [...filesEditFiltered],
        convertedFiles: [...this.state.convertedFiles, ...filesRemoved],
      },
      () => {
        this.removeFileCallBack()
        if(this.props?.type==='edit'){
          this.props.getConvertedFiles(this.state.convertedFiles)
          this.props.getEditedFiles(this.state.editedFiles)
        }
       
      }
    );

  };



  fileUploadProceed = (files: any) => {
    const currentFileCount = this.state.files.length;
  
    const currentEditedFileCount = this.state.editedFiles.length;
    const maxFileCount = 20;
    const combinedFileCount = currentFileCount + currentEditedFileCount;

    if (combinedFileCount + files.length > maxFileCount) {
        this.props.openToastHandler(this.props.t? this.props.t("customers.maxFileCount"):"You can only select up to 20 files in total", "error");
      return;
    }
    
    if ([...this.state.files, ...files].length > 0 && [...this.state.files, ...files].length < 21 ||this.state.editedFiles<21) {
      // Update state with new unique files
      this.setState({ files: [...this.state.files, ...files] }, () => {
        this.pogressFileUpload(this.state.files);
      });
    } 
    
    else {
      this.props.openToastHandler(this.props.t?this.props.t("customers.maxFileCount"):"You can only select up to 20 files in total", "error");
    }
  };

  uploadFileChange = (event: any) => {
    const files = event.target.files;
    this.fileUploadProceed(files);
  };
  fileSize = (file: { size: number; name: any; },fileSize: number) => {
    if (file.size > fileSize) {
      this.props.openToastHandler(this.props.t ? this.props.t("customers.fileSizeLimit") : "Files exceeding 5MB in size cannot be uploaded.", "error");
      this.setState((prevState) => ({
        files: prevState.files.filter((existingFile: any) => existingFile.name !== file.name),
      }), () => {
        this.props.updateInternalAttachments(this.state.files)
      });
    }
  }
  prevFile=(ext: string | number)=>{
    if (!this.state.extensionIcons[ext]) {
      this.props.openToastHandler(`${ext} ${this.props.t? this.props.t("customers.notAllowedFiles"):"files are not allowed, please check the file name"}`, 'error');
      this.setState((prevStateV1) => ({
        files: prevStateV1.files.filter((existingFile: any) => existingFile.name?.substring(existingFile.name?.lastIndexOf(".") + 1) !== ext),
      }), () => {
        this.props.updateInternalAttachments(this.state.files)
      });
    }
  }
  pogressFileUpload = (files: any) => {
    if (files) {
      for (const file of files) {
        const lastindexDot = file.name?.lastIndexOf(".");

        const ext = lastindexDot !== -1 ? file.name?.substring(lastindexDot + 1) : "";

        const fileSize = 5 * 1024 * 1024; // 5MB file size limit
        this.fileSize(file,fileSize)
        this.prevFile(ext)
        this.props.updateInternalAttachments(this.state.files)
        const fileReader = new FileReader();
        fileReader.onloadstart = this.fileUploadLoadStart;
        fileReader.onprogress = (event: any) => this.fileUploadOnPogress(event);
        fileReader.onload = this.fileUploadOnLoad;
        fileReader.readAsDataURL(file);
      }
    }
  };

  fileUploadLoadStart = () => {
    this.setState({ progress: 0 });
  };

  fileUploadOnPogress = (event: any) => {
    if (event.lengthComputable) {
      let lodedFile = 0;
      let totalFileSize = 0;
      for (let file of this.state.files) {
        lodedFile += file.size * (event.loaded / event.total);
        totalFileSize += file.size;
      }

      const completedPercentage = (lodedFile / totalFileSize) * 100;

      this.setState({ progress: completedPercentage });
    }
  };
  onLoadReadCallbackTimeout = () => {
    this.setState({ completed: true });
  };

  onLoadReadCallBack = () => {
    if (this.state.count === this.state.files.length) {
      this.setState({ progress: 100 });
      setTimeout(this.onLoadReadCallbackTimeout, 500);
    }
  };
  fileUploadOnLoad = () => {
    this.setState(
      (prevState) => ({
        count: prevState.count + 1,
      }),
      this.onLoadReadCallBack
    );
  };
  downloadUploadFiles = async (file: any) => {
    try {
      const url = file.url ? file.url : URL.createObjectURL(file);

      const response = await fetch(url);
      const blob = await response.blob();

      // Create an anchor element
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = file.name;

      // Trigger a click on the anchor link
      downloadLink.click();

      // Clean up the object URL
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      this.props.openToastHandler(this.props.t ? this.props.t("customers.fileErr"):"Error downloading the file, please try again.", 'error');
    }
  };
  // Customizable Area End
}
