//@ts-nocheck
import React from 'react';
// Customizable Area Start
import {
    Dialog, DialogTitle, DialogContent, withStyles, Button, Typography, Grid, DialogActions,  Divider,InputAdornment, Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {  StyledTextFieldRequest } from "../../RequestManagement/src/Utility.web";
import CollectedPaymentModalController from './CollectedPaymentModalController.web';
import CustomSearchableSelect from "../../../components/src/CustomSearchableSelect.web";
import moment from "moment";
import { CONSTANTS } from "../../../components/src/theme"
import CustomDatePicker from "../../../components/src/DatePicker";
import { t } from "i18next";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
export const CollectedPaymentModalStyle = (theme:any) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: '#000000',
        width: "15px",
        height: "16px",
    },
    display:{
        display:"flex",
        justifyContent:"end"
    },
    ml_10:{
        marginLeft:"14px"
    },
    fontW_600:{
        fontWeight:CONSTANTS.fontWeight.fontWeight600
    },
    fontW_500:{
        fontWeight:CONSTANTS.fontWeight.fontWeight500
    },
    text_lightBrown: {
        color: CONSTANTS.color.lightBrown
    },
    sendBtn: {
        '&:hover': {
            backgroundColor: CONSTANTS.color.blueViolet,
            cursor: "pointer",
            border:"none",
            color: theme.palette.common.white,
        },
        borderRadius: "8px",
        backgroundBlendMode: "normal",
        background: CONSTANTS.color.darkBlue,
        width: "100%",
        height: "50px",
        color: CONSTANTS.color.white,
        textTransform: "capitalize",
        transition: 'background-color 0.3s, color 0.3s',
    },
    cancelBtn_hide_smallScreen:{
        display:'flex',
        [theme.breakpoints.down("xs")]: {
          display:'none'
        }
      },
     
      cancelBtn_hide_largeScreen:{
        display:'flex',
        [theme.breakpoints.between("sm", 'lg')]: {
          display:'none'
        },
      },
      gap:{
        [theme.breakpoints.down("xs")]: {
          gap:"0.5rem"
          },
      },
    btnCancel: {
        borderRadius: "8px",
        backgroundBlendMode: "normal",
        background: CONSTANTS.color.white,
        color: CONSTANTS.color.lightGrey,
        width: "100%",
        height: "50px",
        textTransform: "capitalize",
        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
        '&:hover': {
            backgroundColor: '#999999',
            border:"none",
            color: theme.palette.common.white,
            cursor: "pointer"
        }
    },
    changeOrder: {
        [theme.breakpoints.down("xs")]: {
           order:"3"
        },
    },
    marginTop:{
        marginTop:"15px",
    },
    marginTop_30:{
        marginTop:"30px",
    },
    btnEmail:{
        borderRadius: "8px",
        backgroundBlendMode: "normal",
        borderColor: CONSTANTS.color.darkBlue,
        background: CONSTANTS.color.white,
        color: CONSTANTS.color.darkBlue,
        marginLeft:'1rem',
        width:"100%",
        height: "50px",
        textTransform: "capitalize",
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
            cursor: "pointer",
            backgroundColor: CONSTANTS.color.blueViolet,
            color: theme.palette.common.white,
            border:"none",
        },
        [theme.breakpoints.down('xs')]: {
            margin:0
        },
    },
    dialogBox: {
        display: "flex",
        borderBottom: "1px solid #979797",
        top: "17px",
        position: "relative",
        paddingBottom: "0px"
    },
    dialogContent: {
        padding:"17px 28px"
    },
    backArrow: {
        width: "26px",
        color: "#000000b0"
    },
    transformStyle: {
        transform: "rotate(180deg)"
    },
    dialogText: {
        marginLeft: "10px",
        marginRight:"10px",
        alignContent:"center"
    },
    payBalance: {
        display: "flex",
    },
    justifyContent:{
        justifyContent:'space-between'
    },
    payBalanceText: {
        marginLeft: "4px"
    }
});

// Customizable Area End
export class CollectedPaymentModal extends CollectedPaymentModalController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    payBalanceCheckBox = () => {
        return <StyledCheckbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox', 'data-testid': 'handleToggleColumnRequest' }}
            onChange={this.props.handlePay_balence}
            checked={this.props.collectedPaymentForm && this.props.collectedPaymentForm.pay_balance}
        />
    }
    collectedCheckBox = () => {
        return <StyledCheckbox
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox', 'data-testid': 'handleToggleColumnRequestCollected' }}
            onChange={this.props.handleCollected}
            checked={this.props.collectedPaymentForm && this.props.collectedPaymentForm.collected}
        />
    }
    backArrow = () => {
        const { classes } = this.props;
        return this.props.selectedLang === "ar" ? `${classes.backArrow} ${classes.transformStyle}` : classes.backArrow
    }
    // Customizable Area End

    render() {
         // Customizable Area Start
        const { classes } = this.props
        const names = [
            { value: "cash", label: `${t('requiredDeposit.menuItem.cash')}`},
            { value: "cheque", label: `${t('requiredDeposit.menuItem.cheque')}`},
            { value: "credit_card", label: `${t('requiredDeposit.menuItem.creditCard')}`},
            { value: "bank_transfer", label: `${t('requiredDeposit.menuItem.bankTransfer')}`},
            { value: "money_order", label: `${t('requiredDeposit.menuItem.moneyOrder')}`},
            { value: "other", label: `${t('requiredDeposit.menuItem.other')}`},
          ];
          // Customizable Area End
        return (
            // Customizable Area Start
            <Dialog
                maxWidth="lg" 
                open={this.props.handleCollectionPaymentModal}
                onClose={(reason:any)=>
                    reason !== 'backdropClick' ?? this.props.handleCollectionPaymentClose
                }
                PaperProps={{
                    style: {
                        minWidth: "300px",
                        backgroundColor: CONSTANTS.color.white,
                        boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
                        borderRadius: 10,
                    },
                }}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
                            <CloseIcon />
                        </IconButton>
                <DialogTitle classes={{ root: classes.dialogTitle }}>
                    <Box className={classes.dialogBox}>
                        <IconButton className={this.backArrow()}>
                            <ArrowBack />
                        </IconButton>
                    <Typography variant='subtitle1'className={`${classes.fontW_500} ${classes.text_lightBrown} ${classes.dialogText}`}>{t('requiredDeposit.title')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                   <Grid container className={classes.marginTop} direction='row' alignItems='center' justifyContent="space-between">
                        <Grid item xs={5} className={this.props.selectedLang === 'ar' ? classes.display : ""}>
                            <Typography className={this.props.selectedLang === 'ar' ? classes.ml_10 : ""} variant='body1'>{t('requiredDeposit.label.method')}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <CustomSearchableSelect
                                onChangeFun={this.props.handleChangePaymentMethodRef}
                                defaultVal={{
                                    label: names.find((item: any) =>
                                        item.value == this.props.collectedPaymentForm.payment_method)?.label, value: this.props.collectedPaymentForm.payment_method,
                                }}
                                name="payment_method"
                                options={names}
                            />
                        </Grid>
                   </Grid>
                   <Grid container className={classes.marginTop} direction='row' alignItems='center' justifyContent="space-between">
                        <Grid item xs={5} className={this.props.selectedLang === 'ar' ? classes.display : ""}>
                            <Typography className={this.props.selectedLang === 'ar' ? classes.ml_10 : ""} variant='body1'>{t('requiredDeposit.label.amount')}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <StyledTextFieldRequest
                                variant="outlined"
                                onChange={this.props.handleChange}
                                name={"amount"}
                                type='number'
                                fullWidth
                                inputProps={{ 'data-testid' :'handleChange' }}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {this.props.currency}
                                      </InputAdornment>
                                    ),
                                }}
                                value={this.props.collectedPaymentForm&&this.props.collectedPaymentForm.amount}
                            >                                   
                            </StyledTextFieldRequest>                                    
                        </Grid>
                   </Grid>
                   <Grid container className={classes.marginTop}>
                   <Grid item xs={5}> </Grid>
                    <Grid item xs={7} className={classes.payBalance}>
                       {this.payBalanceCheckBox()}
                            <Typography className={`${this.props.selectedLang === 'ar' ? classes.ml_10 : ""} ${classes.payBalanceText}`} variant='body1'>{t('requiredDeposit.label.payBalance')}</Typography>

                       </Grid>
                   </Grid>
                   <Grid container className={classes.marginTop} direction='row'  alignItems='center' justifyContent="space-between">
                        <Grid item xs={5} className={this.props.selectedLang === 'ar' ? classes.display : ""}>
                            <Typography className={this.props.selectedLang === 'ar' ? classes.ml_10 : ""} variant='body1'>{t('requiredDeposit.label.date')}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <CustomDatePicker
                                selectedDate={this.props.collectedPaymentForm&&this.props.collectedPaymentForm.transaction_date}
                                dateChange={this.props.handleTransactionDate}
                            />
                        </Grid>
                   </Grid>
                   <Grid container className={classes.marginTop} direction='row' alignItems='center' justifyContent="space-between">
                        <Grid item xs={5} className={this.props.selectedLang === 'ar' ? classes.display : ""}>
                            <Typography
                                className={this.props.selectedLang === 'ar' ? classes.ml_10 : ""} variant='body1'>
                                {t('requiredDeposit.label.details')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <StyledTextFieldRequest
                                variant="outlined"
                                onChange={this.props.handleChange}
                                name={"details"}
                                value={this.props.collectedPaymentForm&&this.props.collectedPaymentForm.details}
                                fullWidth
                            >                                   
                            </StyledTextFieldRequest>                                    
                        </Grid>
                   </Grid>
                   <Grid container>
                         <Grid item xs={12} container>
                            <Typography variant='subtitle2' className={`${classes.text_lightBrown} ${classes.marginTop_30}`}>{t('requiredDeposit.payBalance')}</Typography>
                        </Grid>
                   </Grid>
                   <Grid container style={{justifyContent:'space-between'}} className={classes.marginTop}>
                         <Grid item>
                            <Typography variant='body1'>{t('requiredDeposit.totalInvoice')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{this.props.currency}{(this.props.calculateTotal()-this.props.collectedPaymentForm?.amount).toFixed(2)}</Typography>
                        </Grid>
                   </Grid>
                   <Grid container className={`${classes.marginTop} ${classes.justifyContent}`}>
                         <Grid item>
                            <Typography variant='body1'>{t('requiredDeposit.requiredDeposit')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{this.props.currency}{(this.props.calculateTotal()-this.props.collectedPaymentForm?.amount).toFixed(2)}</Typography>
                        </Grid>
                   </Grid>
                   <Grid container className={`${classes.marginTop} ${classes.justifyContent}`}>
                   <Grid item sm={6} className={classes.payBalance}>
                            {this.collectedCheckBox()}
                            <Typography className={`${this.props.selectedLang === 'ar' ? classes.ml_10 : ""} ${classes.payBalanceText}`} variant='body1'>{t('requiredDeposit.depositCollection')}</Typography>
                        
                    </Grid>
                   </Grid>
                   <Grid container className={`${classes.marginTop} ${classes.justifyContent}`}>
                         <Grid item>
                            <Typography variant='body1'>{t('requiredDeposit.PaymentCollectionAmount')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{this.props.currency}{(this.props.calculateTotal()-this.props.collectedPaymentForm?.amount).toFixed(2)}</Typography>
                        </Grid>
                   </Grid>
                   <Divider className={classes.marginTop}/>
                   <Grid container style={{justifyContent:'space-between'}} className={classes.marginTop}>
                         <Grid item>
                            <Typography variant='body1'>{t('requiredDeposit.invoiceBalance')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{this.props.currency}{(this.props.calculateTotal()-this.props.collectedPaymentForm?.amount).toFixed(2)}</Typography>
                        </Grid>
                   </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogContent}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between' direction='row' className={classes.gap}>
                           
                             <Grid item xs={5} sm={2}>
                                <Button className={classes.btnCancel} variant='outlined' onClick={this.props.onClose}>
                                    <Typography variant='body2'>{t('invoice.button.cancel')}</Typography>
                                </Button>
                            </Grid>
                            <Grid item >
                                
                            </Grid>
                            {!this.props.hideEmailButton&&
                            <Grid item xs={12} sm={6} className={classes.changeOrder}>
                                <Button variant='outlined' className={classes.btnEmail} onClick={this.props.handleSavePaymentAndEmailReceipt}>
                                    <Typography variant='body2' style={{lineHeight:'initial'}}>{t('requiredDeposit.button.saveReceipt')}</Typography> 
                                </Button>
                            </Grid>}
                            <Grid item xs={5} sm={2}>
                                <Button variant='outlined' onClick={this.props.handleCollectionPaymentSave} className={classes.sendBtn}>
                                <Typography variant='body2'>{t('requiredDeposit.button.save')}</Typography> 
                                </Button>
                            </Grid>
                           
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
//Add a style or MUI customized style

export default withStyles(CollectedPaymentModalStyle)(CollectedPaymentModal);
// Customizable Area End
