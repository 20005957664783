//ts-nocheck
import React from "react";
// Customizable Area Start
import {
  withStyles,
  Typography,
  createStyles, Grid, Button, Theme
} from "@material-ui/core";
import Profilesetupcontroller from "./ProfilesetUpcontroller.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countryCodes } from "../../dashboard/src/utility.web";
import { CONSTANTS } from "../../../components/src/theme";
import { CustomAutocomplete, CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
import { withTranslation } from "react-i18next";

// Customizable Area End
// Customizable Area Start
export const StepsButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: CONSTANTS.color.darkBlue,
      width: '10rem',
      height: "3rem",
      borderRadius: 10,
      color: theme.palette.common.white,
      fontSize: "22px",
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: "capitalize",
      '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        cursor: "pointer",
        color: theme.palette.common.white,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: "50px",
        width: "100%"
      }
    }
  }))(Button)
// Customizable Area End

export class Profilesetup extends Profilesetupcontroller {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    //@ts-ignore
    const {t, classes, profile, handleProfileChange, activeStep, getSteps, handleLoginSubmit, isProfileError, progressbarclass, countryCode, countryName, countryInputValue, countryCodeInputValue, countryInputChange, countryCodeInputChange, handleCountryChange, handleCountryCodeChange } = this.props

    return (
      <>
        <form onSubmit={handleLoginSubmit}>
          <Grid container style={{ justifyContent: "center" }} spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.titletext}>{t('profileSetup.heading')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={styles.descriptionText}>{t('profileSetup.firstName')}
                <Typography display="inline" variant="caption" color="error">
                  *
                </Typography>
              </Typography>
              <CustomizedTextField
                className={classes.textField}
                variant="outlined"
                name="first_name"
                value={profile.first_name}
                onChange={handleProfileChange}
                error={Boolean(isProfileError.first_name)}
              />
              <Typography variant="body2" color="error">{isProfileError.first_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={styles.descriptionText}>{t('profileSetup.lastName')}
                <Typography display="inline" variant="caption" color="error">
                  *
                </Typography>
              </Typography>
              <CustomizedTextField
                className={classes.textField}
                variant="outlined"
                fullWidth
                name="last_name"
                value={profile.last_name}
                onChange={handleProfileChange}
                error={Boolean(isProfileError.last_name)} />
              <Typography variant="body2" color="error">{isProfileError.last_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={styles.descriptionText}>{t('profileSetup.phoneNumber')}
                <Typography display="inline" variant="caption" color="error">
                  *
                </Typography>
              </Typography>
              <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Grid item xs={4} sm={3}>
                  <CustomAutocomplete
                    id="combo-box-demo"
                    disableClearable
                    options={countryCodes}
                    value={countryCode}
                    noOptionsText={null}
                    onChange={handleCountryCodeChange}
                    inputValue={countryCodeInputValue}
                    onInputChange={countryCodeInputChange}
                    renderInput={(params) => <CustomizedTextField {...params}
                      className={classes.textField}
                      variant="outlined"
                      name="country"
                      error={Boolean(isProfileError.countryCode)}
                    />}
                  />
                </Grid>
                <Grid item xs={8} sm={9}>
                  <CustomizedTextField
                    className={classes.numberInput}
                    variant="outlined"
                    fullWidth
                    name="phone_number"
                    value={profile.phone_number}
                    onChange={handleProfileChange}
                    error={Boolean(isProfileError.phone_number)} />
                </Grid>
                <Grid container direction="row" className={classes.phonevalidity}>
                  <Grid item xs={3} md={3}>
                    <Typography variant="body2" color="error"> {isProfileError.countryCode}</Typography>
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <Typography variant="body2" color="error"> {isProfileError.phone_number}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={styles.descriptionText}>{t('profileSetup.country')}
                <Typography display="inline" variant="caption" color="error">
                  *
                </Typography>
              </Typography>
              <CustomAutocomplete
                data-test-id="handleKeyDownInput"
                id="combo-box-demo"
                disableClearable
                options={this.state?.countryList}
                value={countryName}
                noOptionsText={null}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                onInputChange={countryInputChange}
                onKeyDown={(e) => this.handleKeyDownInput(e, handleLoginSubmit)}
                renderInput={(params) => <CustomizedTextField {...params}
                  className={classes.textField}
                  variant="outlined"
                  name="country"
                  error={Boolean(isProfileError?.countryName)}
                />}
              />
              <Typography variant="body2" color="error">{isProfileError.countryName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.flex_wrap}>
                <Grid item xs={12} sm={6} className={classes.pogresss}>
                  {getSteps && getSteps.map((item: any, index: any) => {
                    return (
                      <div
                        key={index} className={progressbarclass(index, activeStep, classes)}>
                      </div>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sm={6} style={{ alignItems: "end", display: "flex", justifyContent: "end" }}>
                  <StepsButton data-test-id="btnEmailLogin" type="submit">
                    <Typography variant="body1" style={styles.stepsBtn}>{getSteps && activeStep === getSteps.length - 1 ? t('beforeScreen.finishBtn') : t('beforeScreen.btnNext')}</Typography>
                  </StepsButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = {
  descriptionText: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  stepsBtn: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  inputText: {
    color: CONSTANTS.color.darkBlack
  }
}

export const profileStyles = (theme:any) =>({
  completeText:{
'& .MuiAutocomplete-root':{
  color:'red'
}
  },
  paper: {
    width: "100%"
  },
  titletext: {
    fontFamily: "Rubik",
    fontSize: "22px",
    fontWeight: 600,
    paddingTop: "1rem",
  },
  logoImg: {
    width: "150px"
  },

  btntext: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    textTransform: "capitalize"
  },
  subtext: {
    color: "#96948f",
    fontFamily: "Rubik",
    fontSize: "15px",
    fontWeight: 400,
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  errortext: {
    color: "red"
  },
  active: {
    color: "#1C18AF",
    width: "70px",
    height: "10px",
    background: "#1C18AF",
    borderRadius: "25px",
    marginRight: "5px"
  },
  inActive: {
    width: "70px",
    height: "10px",
    background: "#E7E7E7",
    borderRadius: "25px",
    marginRight: "5px"

  },
  finish: {
    width: "70px",
    height: "10px",
    background: "#1C18AF",
    borderRadius: "25px"
  },
  flex_wrap: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "20px",
      width: "100%",
    }

  },
  pogresss: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "end"
  },
  phonevalidity: {
    marginLeft: "10px"
  },
  textField: {
    // width: "450px",
    width: "100%",
  },
  numberInput: {
    // width: "320px",
    width: "100%",
  }
})
//@ts-ignore
const  profileComponent=withStyles(profileStyles)(Profilesetup)
export default withTranslation()(profileComponent);
// Customizable Area End