
//@ts-nocheck
// Customizable Area Start

import React from "react";
import {
TextField,
withStyles,Select,MenuItem,Button
} from "@material-ui/core";
import { CONSTANTS } from '../../../components/src/theme';
export const StyledTextField = withStyles({
    root: {

      "& .MuiInputBase-root": {
        borderRadius: "10px",
        fontFamily: "Rubik",
        height:"52px"
      },
      "& .MuiOutlinedInput-input":{
        padding:"15px 14px"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
        borderColor:"#05037a",
        borderWidth:"2px"
      },
      "& .MuiOutlinedInput-root:hover":{
        borderColor:"rgba(0, 0, 0, 0.23)",

      },
      "& .MuiSelect-select:focus":{
        backgroundColor:"white"
      },
      "& .MuiOutlinedInput-root.Mui-error":{
        backgroundColor:"rgb(255,239,239)",
      },
      "& .MuiSvgIcon-root":{
          fontSize: "1.7rem",

      }
    }
  })(TextField);
  
  export const StyledSelect = withStyles({
    select: {
      fontSize: "18px",
      fontFamily: "Rubik",
      marginLeft: "0.5rem"
    },
    icon: {
      fontSize: "2.3rem",
      top: "auto"
    },
  })(Select);
  
  export const StyledMenuItem = withStyles({
    root: {
      backgroundColor: "#fff",
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#41cb41"
      },
      "& .MuiTypography-body1": {
        fontSize: "18px",
        fontFamily: "Rubik"
      },
    }
  })(MenuItem);
  
  export const StyledButton=withStyles({
  root:{
    backgroundColor: "#1C18AF",
    color: "#ffffff",
    fontSize: "22px",
    fontWeight: 400,
    fontFamily: "Rubik",
    textAlign: "center",
    borderRadius:"8px",
    width: '10rem',
    height: '3rem',
    transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        cursor: "pointer",
      },
}
})(Button)

export const TextFieldAutoComplete = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: "52px",
      borderRadius: "10px",
      fontSize: "16px",
      fontFamily: "Rubik",
    },
    "& .MuiOutlinedInput-input":{
      cursor: "pointer",
      padding:"15px 12px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor:"#05037a",
      borderWidth:"2px"
    },
    "& .MuiSvgIcon-root":{
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none"
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "90px"
    }
  }
})(TextField);
// Customizable Area End