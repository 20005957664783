import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography,
  TableContainer,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { searchIcon, dropDownSelectIcon } from "./assets";
import { CONSTANTS } from "../../../components/src/theme";
import SiteModal from "../../../components/src/CommonSiteModal.web";
import {CommonListPage} from "../../../components/src/CommonListPage"
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
// Customizable Area End

import CustomersRequestController, {
  Props,
} from "./CustomerRequestsController.web";

// Customizable Area Start
export const CustomerStyledTableContainer = withStyles({
  root: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
})(TableContainer);

// Customizable Area End

export class CustomerQuotes extends CustomersRequestController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  sortRequestsData = (rowItem: any) => this.sortByProperty(rowItem.key);
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,t }: any = this.props;
    const CustQoutesMockRow = [
      { id: 1, key: "quote_title", title: `${t('quote.title')}`},
      { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}`},
      { id: 3, key: "due_date", title: `${t('quote.expirationDate')}`},
      { id: 4, key: "product_name", title: `${t('quote.productName')}`},
      { id: 5, key: "service_name", title: `${t('quote.serviceName')}`},
      { id: 6, key: "quote_date", title: `${t('quote.quoteDate')}`},
      { id: 7, key: "total_quote_amount", title: `${t('quote.amount')}`}
    ];
    
    return (
      <>
       <SiteModal
          siteModal={this.state.siteDailog}
          classes={classes}
          handleSiteCloseModal={this.handleReqCloseModal}
          t={t}          
          createSite={this.goToSitePage}
          handleChangeSearchCustomerSite={this.handleChangeSearchCustomerRequest}
          siteInformationData={this.state.customerSitesData}
          siteDetails={this.state.siteDetails}
          selectedSiteCustomer={this.selectedReqCustomer}
          />
        <Box>
          <Box className={classes.SitessearchContainer}>
              <Box>
                <img style={{...webStyle.searchIcon,marginTop:"5px"}} src={searchIcon} alt="" />
              </Box>
              <InputBase
                value={this.state.searchQuery}
                onChange={this.handleChangeSearchCustomerRequest.bind(this,"")}
                style={webStyle.inputBase}
                className={classes.quotesinputBase}
                placeholder={t("customers.searchQuote")}
                inputProps={{ "aria-label": "search" }}
              />
            </Box>
          <CommonListPage
            type="QUOTES"
            lang={this.state.lang}
            webStyle={webStyle}
            classes={classes}
            sortingOrder={this.state.sortingOrder}
            statusOrder={this.state.statusOrder}
            statusData={this.state.statusData}
            requestMockTableRow={CustQoutesMockRow}
            sortingProperty={this.state.sortingProperty}
            popoverHeading={this.state.popoverHeading}
            handleTooltipClose={this.handleTooltipClose}
            dropDownSelectIcon={dropDownSelectIcon}
            sortRequestsData={this.sortRequestsData}
            t={t}
            anchorEl={this.state.anchorEl}
            anchorId={this.state.anchorId}
            siteData={undefined}
            listData={this.state.listData}
            handleTooltipOpen={this.handleTooltipOpen}
            handleViewData={this.handleViewData}

          />
          <Box
            style={webStyle.buttonsContainerCreateNewQuote}
            onClick={this.handleNavigate.bind(this, "/Quote/create",localStorage.getItem('customer_id')||"")}
          >
            <CreateQuote>
              <Typography
                style={webStyle.buttonTextCreateNewQuote}
                
                variant="subtitle2"
              >
                {t("customers.createQuote")}
              </Typography>
            </CreateQuote>
          </Box>
        </Box>
        {this.state.getCustomersRequestLoading ? (
          <Backdrop
            style={webStyle.quotesBackdrop}
            open={this.state.getCustomersRequestLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  quotesBackdrop: {
    zIndex: 1000 + 1,
    color: "#05037a",
    justifyContent: "center",
    alignItems: "center",
  },
  customersRequestsContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "400px",
  },
  searchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  inputBase: {
    width: "100%",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    lineHeight: "16px",
    color:CONSTANTS.color.grey
  },
  tableCellHead: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    width: "20%",
  },
  tableHeadSpan: {
    cursor: "pointer",
    padding: "0.8rem",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeadTextQuotes: {
    fontSize: "18px",
  },
  tableCellBody: {
    color: "black",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 500,
    
    borderBottom: "none",
    paddingTop: 0,
    width: "20%",
    padding: ".8rem",
  },
  noQuoteContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "3rem",
  },
  noQuoteText: {
    color: "#34313a",
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  noQuoteSubText: {
    color: "#999999",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  buttonsContainerCreateNewQuote: {
    padding: "4rem 0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonTextCreateNewQuote: {
    color: "#ffffff",
    fontFamily: "Rubik",
    fontSize: "22px",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)",
  },
};

export default withStyles(styles)(CustomerQuotes);
// Customizable Area End
