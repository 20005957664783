//@ts-nocheck
import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import MuiAlert from "@material-ui/lab/Alert";
import {
  withStyles,
} from "@material-ui/core/styles";

const StyledMuiAlert = withStyles({
  root: {
    "& .MuiAlert-icon": {
      marginLeft: "12px !important",
    },
    "& .MuiAlert-action": {
      paddingRight: "16px !important",
    }
  }
})(MuiAlert);

export const withSnackbar = (WrappedComponent:any) => {
  return (props:any) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState(
      "success"
    ); /** error | warning | info */

    const openToastHandler = (message, severity = "success" | "info" | "warning" | "error" | undefined) => {
      setMessage(message);
      setSeverity(severity);
      setOpen(true);
    };

    const handleClose = (event:any, reason:any) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} openToastHandler={openToastHandler} />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          autoHideDuration={6000}
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide}
        >
          <StyledMuiAlert variant="filled" onClose={handleClose} severity={severity}>
            {message}
          </StyledMuiAlert>
        </Snackbar>
      </>
    );
  };
};
