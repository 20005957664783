Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.toasterText={
  hardDeleteText:"Canceled Invoice Deleted Permanently"
}
exports.titleRex = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/
exports.createInvoiceAPIEndPoint = "bx_block_request_management/invoices";
exports.jobEndPoint = "bx_block_request_management/jobs"
exports.createInvoiceAPIMethod = "POST";
exports.allCustomers="account_block/customers"
exports.getCustomersList="bx_block_request_management/customers_list"
exports.getInvoicePdf = "bx_block_request_management/invoice_pdf_details"
exports.products_and_servicesEndPoint = "bx_block_request_management/product_and_services"
exports.getCustomerSites="bx_block_location/site_informations"
exports.getSingleInvoiceDetails = "bx_block_request_management/invoices"
exports.showJobs="bx_block_request_management/upcoming_and_in_progress_list"
exports.getProduct="bx_block_settings/products"
exports.getService="bx_block_settings/services"
exports.getCurrency='bx_block_languagesupport/languagesupports/currency_symbol'
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.searchInvoices="bx_block_request_management/search_invoice";
exports.getInvoices="bx_block_request_management/invoices";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.storageToken="authToken"
exports.paymentApiMethodtype = "PATCH"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InvoiceBilling";
exports.labelBodyText = "InvoiceBilling Body";
exports.invoiceTitleText = "Invoice Title";
exports.invoicePlaceholderLabel = "Invoice for"
exports.invoiceHeading = "INVXXXX";
exports.invoiceDetailsText = "Invoice Details";
exports.issueDateLabel = "Issue Date";
exports.paymentDueLabel = "Payment Due";
exports.locationLabel = "Location";
exports.jobReferenceLabel = "Job Reference";
exports.invoiceDetailsText = "Invoice Details";
exports.moreActionBtnLabel = " More Actions.."
exports.cancelBtnLabel = "Cancel";
exports.deleteBtnLabel = "Delete";
exports.archiveBtnLabel = "Archive";
exports.editBtnLabel = "Edit";
exports.invoiceUpdateBtnLabel = "Update Invoice";
exports.updateAndBtnLabel = "Update and..";
exports.saveInvoiceBtnLabel = "Save Invoice"
exports.saveAndBtnLabel = "Save and..."
exports.selectCustomerBtnLabel = "Select Customer"
exports.applyLabel = "Apply"
exports.InternalNotesText="Internal Notes & Attachments"
exports.createInvoiceBtnLabel = "Create An Invoice"
exports.searchCustomerInputPlaceholder = "Search Customer..."
exports.btnExampleTitle = "Submit";
exports.invoiceValidation ="Please enter 6 Digit Invoice Number"
exports.enterInvoice="Invoice Number"
exports.invoiceNumber="Enter Invoice Number (for example 299206)"
exports.keyboardType="phone-pad"
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_invoice/invoice/generate_invoice_pdf?invoice_number=";
exports.exampleAPiMethod = "GET";
// Customizable Area End