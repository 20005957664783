import React from 'react';
import {Grid,Typography } from '@material-ui/core';

interface Props {
    message:String
}

const ErrorMessage = (props: Props) => {
    
    return (
        <>
           <Grid item xs={12} style={webStyle.mt10}>
            <Grid container direction='row' justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Typography component={'div'} color="error">{props.message}</Typography>

                </Grid>

            </Grid>

           </Grid>
        </>
    );
};

const webStyle = {

    errorText: {
        color: "red",
        fontSize: "15px",
        fontWeight: 400,
        fontFamily: "Rubik"
   },
   mt10:{
    marginTop:"10px"

   }
}

export default ErrorMessage;
