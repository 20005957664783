//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Typography, Grid, MenuItem, Button, TextField,InputAdornment
} from "@material-ui/core";
import BussinessController from "./BussinessController.web";
import { uploadImg, previewLogo } from "./assets";
import ClearIcon from '@material-ui/icons/Clear';
import { FileUpload } from "../../../components/src/FileUpload.web";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
import { CONSTANTS } from "../../../components/src/theme";
import { StepsButton } from "./ProfileSetup.web";
import { withTranslation } from "react-i18next";
// Customizable Area End
// Customizable Area Start
export const StyledTextFieldAutoComplete = withStyles({
  root: {
    "& .MuiInputBase-root": {
      // height: "55px",
      borderRadius: "10px",
      fontSize: "16px",
      fontFamily: "Rubik",
    },
    "& .MuiOutlinedInput-input": {
      cursor: "pointer",
      padding: "15px 12px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#05037a",
      borderWidth: "2px"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none"
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "90px"
    }
  }
})(TextField);
// Customizable Area End


export class BussinessWeb extends BussinessController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    // Customizable Area Start
    //@ts-ignore

    const { t,classes, bussiness, handleBussinessChange, isBussinessError, handlebussinessForm, getSteps, activeStep, progressbarclass, handleSelectFiles, handleOnDragOver, handleOnDrop,
       companyLogo, handleDeleteUploadedLogo,otherField,otherOnChange,otherFielsText } = this.props;
    return (
      <>
        <form onSubmit={handlebussinessForm}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start" spacing={2}>
              <Grid item xs={11}>
                <Typography component={'div'} className={classes.titletext}>{t('Bussiness.heading')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary" className={classes.subtext}>{t('Bussiness.logo')}</Typography>
                <Grid container direction="row" justifyContent="flex-start">
                  {!this.props.completed ? (
                    <>
                      <Grid item xs={12} sm={9} style={{ position: "relative" }}>
                        <CustomizedTextField
                          className={classes.uploadField}
                          variant="outlined"
                          placeholder="Drag your files here"
                          name="company_logo"
                          fullWidth
                          onDragOver={handleOnDragOver}
                          onDrop={handleOnDrop}
                          InputProps={{
                            readOnly: true,
                            classes: { input: classes['input'] },
                              endAdornment: (
                                <InputAdornment position='end' style={{ marginRight: "-10px" }}>
                                  <Button
                                    variant="outlined"
                                    component="label"
                                    disableRipple
                                    disableElevation
                                    disableFocusRipple
                                    onDragOver={handleOnDragOver}
                                    onDrop={handleOnDrop}
                                    className={classes.upload}
                                  >
                          <img
                            width="20px" height={"20px"}
                            src={uploadImg}
                            alt=""
                          />&nbsp;&nbsp;&nbsp;
                          <input
                            data-test-id="handleSelectFiles"
                            hidden
                            type="file"
                            accept="image/*"
                            onChange={handleSelectFiles}
                          />
                          Upload Files

                        </Button>
                                </InputAdornment>
                              ),
                          }}
                        />
                        {this.props.progress > 0 && (
                          <FileUpload
                            {...this.props}
                            progress={this.props.progress}
                          />
                        )}
                      </Grid>
                      <Grid item xs={3} />
                    </>
                  ) : (
                    <>
                      {companyLogo &&
                        <Grid item xs={9}>

                          <div style={{
                            width: "231px",
                            height: "85px",
                            borderRadius: "8px",
                            background: "#ffffff",
                            boxShadow: "0px 6px 17px rgba(28,24,175,0.07)"
                          }}>
                            <Grid item xs={12}>
                              <Grid container direction="row" justifyContent="flex-start" style={{ padding: "1rem" }}>
                                <Grid item xs={12} container justifyContent="flex-end">
                                  <ClearIcon
                                    className={classes.deleteIcon}
                                    onClick={handleDeleteUploadedLogo}
                                  />
                                </Grid>
                                <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center">
                                  <Grid item xs={3}>
                                    <img src={companyLogo.name && this.props.extensionIcons[companyLogo.name.split(".")[1]]} width="35.97px" height="40px" />
                                  </Grid>
                                  <Grid item xs={1} />
                                  <Grid item xs={8}>
                                    <Typography component={'div'} className={classes.textOverFlow}>{companyLogo.name}</Typography>
                                    <Typography component={'div'}>{Math.floor(companyLogo.size / 1024)}kb</Typography>
                                  </Grid>

                                </Grid>
                              </Grid>

                            </Grid>
                          </div>
                        </Grid>
                      }
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary" className={classes.subtext}>{t('Bussiness.name')}
                  <Typography display="inline" variant="caption" color="error">*</Typography>
                </Typography>
                <CustomizedTextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  name="company_name"
                  value={bussiness?.company_name}
                  onChange={handleBussinessChange}
                />
                <Typography variant="caption" color="error">{isBussinessError?.company_name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary" className={classes.subtext}>{t('Bussiness.size')}
                  <Typography display="inline" variant={'caption'} color="error">*</Typography>
                </Typography>
                <CustomizedTextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  select
                  name="compamy_size"
                  value={bussiness?.compamy_size}
                  onChange={handleBussinessChange}
                >

                  {this.state.companySize ? this.state.companySize.map((option: any) => (
                    <MenuItem key={option.id} value={option.value} className={classes.menuText}>
                      {option.name}
                    </MenuItem>
                  )) : null}
                </CustomizedTextField>
                <Typography variant="caption" color="error">{isBussinessError?.compamy_size}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary" className={classes.subtext}>{t('Bussiness.industry')}
                  <Typography display="inline" variant="caption" color="error">*</Typography>
                </Typography>
                <CustomizedTextField
                  data-test-id="handleKeyDownTextfield"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  select
                  name="industy_type"
                  value={bussiness?.industy_type}
                  onChange={handleBussinessChange}
                  onKeyDown={(e) => this.handleKeyDownTextfield(e, handlebussinessForm, bussiness)}
                >
                  {this.state.industries && this.state.industries.map((option: any) => {

                    return (
                      <MenuItem key={option.id} value={option.name} className={classes.menuText}>
                        {option.name}
                      </MenuItem>

                    )
                  })}
                </CustomizedTextField>
             
                {otherField && (   
          <CustomizedTextField
          style={webStyle.otherFields}
          data-test-id="otherOnChange"
            variant="outlined"
            fullWidth
            placeholder="Please specify"
            value={otherFielsText}
            onChange={otherOnChange}
           
           
          />
         )} 
       
                <Typography variant="body2" color="error">{isBussinessError?.industy_type}</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Grid container direction="row" justifyContent="space-between" spacing={4}>
                  <Grid item xs={12} sm={6} className={classes.pogresss}>
                    {getSteps && getSteps.map((item: any, index: any) => {

                      return (
                        <div
                          key={index} className={progressbarclass(index, activeStep, classes)}>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6} container justifyContent="flex-end">
                    <StepsButton data-test-id="handleBusiness" type="submit">
                      {getSteps && activeStep === getSteps.length - 1 ? <Typography variant="subtitle1" style={{ textTransform: "capitalize" }}>
                      {t('beforeScreen.finishBtn')}

                      </Typography> : <Typography variant="body1" style={{ textTransform: "capitalize" }}>
                      {t('beforeScreen.btnNext')}
                        
                        </Typography>}
                    </StepsButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
     
    );
     // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  otherFields:{
    marginTop:"15px"
  }
}
export const BussinessStyles = (theme: Theme) =>
  createStyles({
    inActive: {
      width: "70px",
      height: "10px",
      background: CONSTANTS.color.pogressGrey,
      borderRadius: "25px"
    },
    menuText: {
      color: theme.palette.common.black
    },
    titletext: {
      fontWeight: CONSTANTS.fontWeight.fontWeight600,
      paddingTop: "1rem",
      [theme.breakpoints.down('xs')]: {
        fontSize: '30px'
      }
    },
    logoImg: {
      width: "150px"
    },
    subtext: {
      fontWeight: theme.typography.fontWeightRegular,
      paddingTop: "5px",
      paddingBottom: "5px"
    },
    pogresss: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "end"
    },
    upload: {
      width: "147px",
      right: 0,
      top: 0,
      height: "54px",
      border: "2px dashed #1c18af",
      background: "rgba(28, 24, 175, 0.05)",
      color: "#1c18af",
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: 500,
      textTransform: "capitalize",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        right: "0px",
      }
    },
    finish: {
      width: "70px",
      height: "10px",
      background: CONSTANTS.color.darkBlue,
      borderRadius: "25px"
    },
    deleteIcon: {
      fontSize: "15px"
    },
    progress: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "end"
    },
    uploadField: {
      width: "100%",
      height: "52px",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    textOverFlow: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    'input': {
      '&::placeholder': {
        fontSize: 14,
      }
    },
    active: {
      color: CONSTANTS.color.darkBlue,
      width: "70px",
      height: "10px",
      background: CONSTANTS.color.darkBlue,
      borderRadius: "25px"
    },
  })

  const bussinessComponent=withStyles(BussinessStyles)(BussinessWeb);
  export default withTranslation()(bussinessComponent)
// Customizable Area End
