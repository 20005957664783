import React from "react";

// Customizable Area Start
import {
  Box,
  Drawer,
  Hidden,
  List,
  Tabs,
  Tab
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import {
  logo,
  createIcon,
  dashboardIcon,
  tasksIcon,
  requestsIcon,
  jobsIcon,
  mapsIcon,
  invoicesIcon,
  quotesIcon,
  calendarIcon,
  timesheetIcon,
  suppliersIcon,
  customersIcon,
  employeesIcon,
  reportsIcon,
  settingsIcon,
} from "./assets";
import DashboardNavBar from "./DashboardNavBar.web";
import { CONSTANTS } from "../../../components/src/theme";
// Customizable Area End

import DashboardSideBarController, {
  Props,
} from "./DashboardSideBarController.web";

// Customizable Area Start
export const StyledTabs = withStyles({
  root: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    "& .MuiButtonBase-root": {
      minHeight: "56px",
      minWidth: "48px"
    },
    "& .MuiTab-textColorInherit": {
      color: CONSTANTS.color.lightBlack,
    },
    "& .Mui-selected": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white
    },
    "& .MuiTabs-scrollable": {
      overflowX: "hidden",
    }
  }
})((props: any) => <Tabs {...props} TabIndicatorProps={{ style: { display: 'none' } }} />);

const StyledTab = withStyles({
  root: {
    "& .MuiTab-root": {
      minWidth: "90px"
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start"
    },
    "&:hover": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white
    }
  }
})(Tab);

const sideBarData = [
  {
    id: 1,
    icon: createIcon,
    width: "22px",
    height: "22px",
  },
  {
    id: 2,
    icon: dashboardIcon,
    width: "23px",
    height: "21px",
  },
  {
    id: 3,
    icon: tasksIcon,
    width: "21px",
    height: "20px",
  },
  {
    id: 4,
    icon: requestsIcon,
    width: "20px",
    height: "20px",
  },
  {
    id: 5,
    icon: quotesIcon,
    width: "20px",
    height: "20px",
  },
  {
    id: 6,
    icon: jobsIcon,
    width: "20px",
    height: "20px",
  },
  {
    id: 7,
    icon: invoicesIcon,
    width: "16px",
    height: "21px",
  },
  {
    id: 8,
    icon: mapsIcon,
    width: "20px",
    height: "20px",
  },

  {
    id: 9,
    icon: calendarIcon,
    width: "20px",
    height: "20px",
  },
  {
    id: 10,
    icon: timesheetIcon,
    width: "20px",
    height: "20px",
  },
];

const sideBarData2 = [
  {
    id: 1,
    icon: suppliersIcon,
    width: "26px",
    height: "25px",
  },
  {
    id: 2,
    icon: customersIcon,
    width: "28px",
    height: "21px",
  },
  {
    id: 3,
    icon: employeesIcon,
    width: "30px",
    height: "21px",
  },
]

const sideBarData3 = [
  {
    id: 1,
    icon: reportsIcon,
    width: "23px",
    height: "21px",
  },
  {
    id: 2,
    icon: settingsIcon,
    width: "17px",
    height: "20px",
  },
]
// Customizable Area End

export class FreeTrialExpiredSideBar extends DashboardSideBarController {
  constructor(props: Props) {
    super(props);
  }

  navMenu = () => {
    this.setState({ open: true });
  }

  render() {
    // Customizable Area Start
    const renderContent = (
      <Box>
        <Box sx={{ mb: 10, mx: 2.5 }}>
          <Box style={webStyle.logoContainer}>
            <img style={webStyle.logo} src={logo} alt="Logo" />
          </Box>
        </Box>

        <Box>
          <List disablePadding style={{ padding: 1 }}>
            <StyledTabs
              data-test-id="handleTabValueChange"
              orientation="vertical"
              variant="scrollable"
              aria-label="Vertical tabs example"
            >
              {sideBarData.map((item: any) => {
                return (
                  <StyledTab
                    data-test-id="handleTabNavigate1"
                    icon={<img style={{width: item.width, height: item.height}} src={item.icon} alt="" />}
                    key={item.id}
                    onClick={() => this.handleTabNavigate1(item)}
                  />
                );
              })}
            </StyledTabs>

            <div style={webStyle.divider}></div>

            <StyledTabs
              data-test-id="handleTabValueChange1"
              orientation="vertical"
              variant="scrollable"
              aria-label="Vertical tabs example"
            >
              {sideBarData2.map((item: any) => {
                return (
                  <StyledTab
                    data-test-id="handleTabNavigate2"
                    icon={<img style={{width: item.width, height: item.height}} src={item.icon} alt="" />}
                    key={item.id}
                    onClick={() => this.handleTabNavigate2(item)}
                  />
                );
              })}
            </StyledTabs>

            <div style={webStyle.divider}></div>

            <StyledTabs
              data-test-id="handleTabValueChange2"
              orientation="vertical"
              variant="scrollable"
              aria-label="Vertical tabs example"
            >
              {sideBarData3.map((item: any) => {
                return (
                  <StyledTab
                    data-test-id="handleTabNavigate3"
                    icon={<img style={{width: item.width, height: item.height}} src={item.icon} alt="" />}
                    key={item.id}
                    onClick={() => this.handleTabNavigate3(item)}
                  />
                );
              })}
            </StyledTabs>
          </List>
        </Box>
      </Box>
    );
    return (
      <>
        <DashboardNavBar width={94} {...this.props} navMenu={this.navMenu} navMenuItemProps={this.props.navMenuItem} />
        <Box style={{zIndex: 1000}}>
          <Hidden mdDown>
            <Drawer
              open
              anchor={this.state.languageSelected === 'ar' ? "right": "left"}
              variant="permanent"
              PaperProps={{
                style: {
                  width: "95px",
                  borderRight: `1px solid ${CONSTANTS.color.paleGrey}`,
                  paddingBottom: "4rem",
                  overflowX: "hidden",
                  [this.state.languageSelected === 'ar' ? 'paddingLeft' : 'paddingRight']: "5px",
                }
              }}
            >
              {renderContent}
            </Drawer>
          </Hidden>
          <Hidden lgUp>
            <Drawer
              anchor={this.state.languageSelected === 'ar' ? "right": "left"}
              open={this.state.open}
              onClose={this.handleCloseDrawer}
              PaperProps={{
                style: {
                  width: "95px",
                  borderRight: `1px solid ${CONSTANTS.color.paleGrey}`,
                  paddingBottom: "4rem",
                  overflowX: "hidden",
                  [this.state.languageSelected === 'ar' ? 'paddingLeft' : 'paddingRight']: "5px",
                }
              }}
            >
              {renderContent}
            </Drawer>
          </Hidden>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  
});

const webStyle = {
  logoContainer: {
    marginTop: "3rem"
  },
  logo: {
    cursor: "pointer",
    height: "36px",
    width: "38px"
  },
  divider: {
    margin: "2rem"
  }
}

export default withStyles(styles)(FreeTrialExpiredSideBar);
// Customizable Area End