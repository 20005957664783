import { withStyles, createStyles,Theme } from "@material-ui/core";
import { CONSTANTS } from "./theme";

const withCommonStyles = (wrappedComponent:any) => {
    return withStyles((theme:Theme) =>
        createStyles({
           
            mainHeading30: {
                fontSize: "28px",
                fontWeight: CONSTANTS.fontWeight.fontWeight600,
                fontStyle: "normal",
                color: CONSTANTS.color.lightBrown
            },
            mt_52:{
                 marginTop: "-52px" ,
                 [theme.breakpoints.down('sm')]:{
                    marginTop: "5px !important"
                }
            },
            titleHeading26: {
                fontWeight: theme.typography.fontWeightMedium,
                color: CONSTANTS.color.lightBrown,
                [theme.breakpoints.down('xs')]:{
                    fontSize:24
                }
            },
            titleHeading24: {
                fontSize: "24px",
                fontWeight: theme.typography.fontWeightRegular,
            },
            subTitle20: {
                fontWeight: theme.typography.fontWeightRegular,
                [theme.breakpoints.down('xs')]:{
                    fontSize:18
                }
            },
            subTitle18: {
                fontWeight:theme.typography.fontWeightMedium,
                fontStyle: "normal",
                color: CONSTANTS.color.darkBlue
            },
            description18: {
                fontFamily: "Rubik",
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                color: "#a3a3a3"
            },
            mt_40: {
                [theme.breakpoints.up('md')]: {
                    marginTop: '-40px'
                }

            },
            mt_38: {
                    marginTop: '-40px',
                    [theme.breakpoints.down('sm')]: {
                        marginTop: '-15px'
                    }
            },
            mt_50: {
                [theme.breakpoints.up('md')]: {
                    marginTop: '-50px'
                }
            },
            paperQuote: {
                display: "flex",
                padding: "1rem",
                justifyContent: "center",
                marginTop: "-99px",
                [theme.breakpoints.down("sm")]: {
                    marginTop: "-23px"
                  }
              },
            quoteDrawerLayout: {
                zIndex: 1,
                height: "100px",
                [theme.breakpoints.down("sm")]: {
                    position: 'absolute',
                    zIndex: 1,
                    marginTop: '17px',
                },
            [theme.breakpoints.between("xs","sm")]: {
                padding: '0px 0px 0px 24px'
            },
            [theme.breakpoints.down("xs")]: {
                padding: '0px 0px 0px 15px'
            }
        },
        jobDrawerLayout: {
            zIndex: 1,
            height: "100px",
            [theme.breakpoints.down("sm")]: {
                position: 'absolute',
                zIndex: 1,
                marginTop: '55px',
            },
            [theme.breakpoints.between("xs","sm")]: {
                padding: '0px 0px 0px 24px'
            },
            [theme.breakpoints.down("xs")]: {
                padding: '0px 0px 0px 15px'
            }
        },
            saveEndButtons: {
                fontFamily: "Rubik",
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                color: "#34313a"
            },
            //Button Text styles
            containedButton24: {
                fontSize: "22px",
                fontWeight: 400,
                textAlign: "center",
                textTransform: "capitalize",
                color: "#ffffff",
                borderRadius: "10px",
                background: "#1c18af",
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                    cursor: "pointer",
                    backgroundColor: CONSTANTS.color.blueViolet,
                    color: theme.palette.common.white,
                },
            },
            outlinedCancelButton24: {
                fontSize: "22px",
                fontWeight: 500,
                textAlign: "center",
                textTransform: "capitalize",
                color: "#999999",
                borderRadius: "10px",
                transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                background: "#ffffff",
                border: "1px solid #999999",
                '&:hover': {
                  backgroundColor: '#999999',
                  color: theme.palette.common.white,
                  cursor: "pointer"
                }
            },
            outlinedDeleteButton24: {
                fontSize: "22px",
                fontWeight: 500,
                textAlign: "center",
                textTransform: "capitalize",
                color: "#ff5454",
                borderRadius: "10px",
                background: "#ffffff",
                border: "1px solid #ff5454",
                transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                '&:hover': {
                  backgroundColor: '#ff5454',
                  color: theme.palette.common.white,
                  cursor: "pointer"
                }
            },
            outlinedEditButton24: {
                fontSize: "22px",
                fontWeight: 400,
                color: "#1c18af",
                borderRadius: "10px",
                border: "1px solid #4d4aca",
                textTransform: "capitalize",
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                    color: theme.palette.common.white,
                    backgroundColor: CONSTANTS.color.blueViolet,
                    border:"none",
                    cursor: "pointer"
                },
            },
            gutterBottom: {
                marginBottom: "0.35rem",
            },
            gutterBottom2: {
                marginBottom: "0.75rem",
            },
            gutterBottom3: {
                marginBottom: "1.5rem",
            },
            gutterTop: {
                marginTop: "0.35rem",
            },
            gutterTop2: {
                marginTop: "0.75rem",
            },
            gutterTop3: {
                marginTop: "1.5rem",
            },
            gutterTop4: {
                marginTop: "2em",
            },
            gutterTop5: {
                marginTop: "2.5em",
            },
            gutterTop6: {
                marginTop: "3.5em",
            },
            whiteText: {
                color: "#fff",
            },
            errorText: {
                color: "red",
                fontSize: "15px",
                fontWeight: 400,
                fontFamily: "Rubik"
            },
            cursor: {
                cursor: "pointer"
            },
            overflow: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            flex_center:{
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
            },
            flex_end:{
                display:"flex",
                alignItems:"center",
                justifyContent:"flex-end",
                [theme.breakpoints.down("sm")]:{
                justifyContent:"center",

                }
            },
            flex_start:{
                display:"flex",
                alignItems:"center",
                justifyContent:"flex-start"
            },
            popupBtnText:{
                color:CONSTANTS.color.blackDark,
                fontWeight:theme.typography.fontWeightRegular
            },
            hide: {
                display: 'none',
              },
              drawer: {
                flexShrink: 0,
              },
              quoteDrawerPaper: {
                maxHeight: "100%",
                height: "740px",
                position: "relative",
                zIndex: 0,
                boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
                borderRadius: "5px",
                border: "1px solid rgba(0,0,0,0.1)",
                [theme.breakpoints.up("xl")]: {
                  width: "100%"
                }
              },
              mt_141:{
                marginTop: '-141px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '-15px'
                }
            },
              drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: "2rem",
                justifyContent: 'flex-start',
                color: " #999999",
              },
              paper: {
                display: "flex",
                padding: "1rem",
                justifyContent: "center",
                marginTop: "-79px",
                [theme.breakpoints.down("sm")]: {
                    marginTop: "4px"
                  }
              },
              iconLayout: {
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginRight:"0.6rem"
            },
            mobielbuttonLayout:{
                [theme.breakpoints.down('xs')]:{
                    flexDirection:'column-reverse'
                }
            },
            buttonWidth:{
                [theme.breakpoints.down('xs')]:{
                    width:'100%'
                }
            }
        })
    )(wrappedComponent);
};

export default withCommonStyles;
