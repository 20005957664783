import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    openToastHandler: any;
    t:(key:string)=>string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profileAnswers: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class BeforeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    profileAnswersListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            profileAnswers: [],
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getProfileAnswersList()
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId === this.profileAnswersListApiCallId) {
                if (responseJson && !responseJson.errors) {
                    this.setState({ profileAnswers: responseJson?.data })
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, SignUp: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (typeof SignUp === 'function') {
                SignUp(e);
            }
        }
      }
    
    getProfileAnswersList = async() => {
        const header: any = {
            "Content-Type": configJSON.validationApiContentType
        };
        const lang=await getStorageData('lang')
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.profileAnswersListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.profileAnswes}?lang=${lang}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
}
// Customizable Area End