import React from 'react'
import { CONSTANTS } from './theme';
import { withStyles,Switch } from "@material-ui/core";
import { createStyles,Theme } from "@material-ui/core/styles";

export const AntSwitch = withStyles((theme: Theme) =>
createStyles({
  root: {
    width: 49,
    height: 27,
    padding: 0,
    borderRadius:"18px"
  },
  switchBase: {
    padding: 4,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      paddingLeft:"14px",
      color: "white",
      '& + $track': {
        opacity: 1,
        backgroundColor: CONSTANTS.color.darkBlue,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 19,
    height: 19,
    boxShadow: 'none',
  },
  track: {
    border: "1px solid gray",
    borderRadius: "18px",
    opacity: 1,
    backgroundColor: "white",
  },
  checked: {},
}),
)(Switch);