Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";
exports.storageToken="authToken"
exports.btnExampleTitle = "CLICK ME";
exports.searchMapLocation='bx_block_location/map_search'
exports.getAllEmployesAPiEndPoint = "bx_block_request_management/task_allocates/get_employees";
// Customizable Area End