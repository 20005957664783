import React from "react";

// Customizable Area Start
import {
  Box,
  Container
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import { SettingsSideBar } from "./SettingsSideBar.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import SettingsController, {
  Props,
} from "../../Settings/src/SettingsController.web";

// Customizable Area Start

// Customizable Area End

export class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.settings')} active={false} active1={false} active2={1} />
        <StyledRoot>
          <Box
            sx={webStyle.boxContainerSettings}
            className={classes.boxContainerSettings}
          >
            <Container maxWidth={false}>
              <SettingsSideBar {...this.props} />
            </Container>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainerSettings: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important"
    }
  }
});

const webStyle = {
  boxContainerSettings: {
    marginTop: "8rem",
  }
}

export default withTranslation()(withStyles(styles)(Settings));
// Customizable Area End