export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const emailIcon=require("../assets/email.png")
export const downloadIcon=require("../assets/download.png")
export const invoiceIcon=require("../assets/invoice.png")
export const checkIcon=require("../assets/check.png")
export const plusIcon=require("../assets/plus.png")
export const tickEmpty=require("../assets/tickEmpty.png")
export const leftArrow = require("../assets/arrowLeft.png");
export const rightArrow = require("../assets/arrowRight.png");
export const extensionPng=require("../assets/pngExtension.png")
export const extensionJpg=require("../assets/jpgExtension.png")
export const extensionPpt=require("../assets/pptExtension.png")
export const extensionXls=require("../assets/xlsExtension.png")
export const extensionDoc = require("../assets/docExtension.png")
export const extensionTxt=require("../assets/txtExtension.png")
export const extensionPdf = require("../assets/pdfExtension.png")
export const downArrowIcon=require("../assets/downIcon.png")
export const calenderActive=require("../assets/calenderActive.png")
export const calendar = require("../assets/calendar.png")
export const editIcon = require("../assets/editPencil.png")
export const deleteIcon=require("../assets/delete.png")
export const calenderNonActive=require("../assets/calenderNonActive.png")


