// Customizable Area Start
import React from "react";
import {
  Box,
  Grid , 
  styled ,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions ,
  Button,
  IconButton,
  DialogContentText, 
  Tabs,Tab,Typography,Modal,InputBase,InputAdornment,ClickAwayListener,Checkbox,Divider, TextField,MenuItem, FormControlLabel
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toastweb";
import { withTranslation } from "react-i18next";
import TimeSheetHoursController, {
  configJSON, JobData, JobStatusData, Props,
  StatusData, RequestType, RequestStatusData, StatusObject, RequestResType
} from "./TimeSheetHoursController";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import CustomDatePicker from "../../../components/src/DatePicker";
import { StyledTextFieldQuote } from "../../../blocks/RequestManagement/src/Utility.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import CloseIcon from '@material-ui/icons/Close';
import { searchIcon, calendarIcon, imagebutton_left,imagebutton_right, } from "../../../blocks/dashboard/src/assets";
import withPermission from "../../../components/src/withPermission.web"
import { imgbtnBack } from "./assets";
import TimePicker from "../../../components/src/TimePicker";
import { CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
import { CONSTANTS } from '../../../components/src/theme' 
import { StyledTextField } from "../../../blocks/RequestManagement/src/Requests.web"
import { dropDownSelectIcon } from "../../../blocks/RequestManagement/src/assets"
import CustomDatePickers from "../../../components/src/CustomDatePicker";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { t } from "i18next";

interface JobsTableRow {
    statusKey: string
    id: number
    title: string
}

interface JobsStatusData {
  id: number
  title: string
  statusName: string
}
// Customizable Area End

export class TimeSheetHours extends TimeSheetHoursController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
      }
    // Customizable Area Start
    jobsTableRow = (mockJobsTableRow: JobsTableRow[]) => {
      return <>
          {mockJobsTableRow.map((item) => (
              <React.Fragment key={item.id}>
                  <Grid item xs={12} 
                      data-test-id={`handleSort_${item.id}`}
                      key={item.id}
                      style={this.state.sortColumn === item.statusKey
                        ? webStyle.jobTabRowGrid
                        : webStyle.sortJobTabRowGrid}
                        onClick={() => this.handleSort(item.statusKey)}
                  >
                      <span style={webStyle.jobTableHeadSpan}>
                          <Typography variant="body1"
                              style={webStyle.tableText}>
                              {item.title}
                          </Typography>
                          <img
                                  src={dropDownSelectIcon}
                                  alt=""
                                style={{
                                  ...webStyle.dropDownSelectIconTable,
                                  ...(this.state.sortColumn === item.statusKey && this.state.sortDirection === 'asc'
                                      ? webStyle.imgTransform
                                      : webStyle.imgDropDownSelectIcon)
                              }}
                          />
                      </span>
                  </Grid>
              </React.Fragment>
          ))}
      </>
  }
      statusModalJob = (jobStatusData: JobStatusData[]) => {
        return (
          <ClickAwayListener onClickAway={this.handleStatusOpen}>
                <Typography>
                    <Box style={webStyle.toggleJobModalContainer}>
                        <Box style={webStyle.toggleModalStyleJob}>
                            {jobStatusData.map((item: JobStatusData) => (
                                <Box key={item.id} style={webStyle.statusModal}>
                                  <FormControlLabel
                                  control={
                                    <Checkbox
                                    data-test-id="handlestatus"
                                      inputProps={{ 'aria-label': `handleSelectStatus_${item.id}` }}
                                      style={webStyle.checkBoxStyle}
                                        checked={this.state.checkedItems.some((subItem: string) => item.statusName === subItem)}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSelectedStatus(item, jobStatusData, event)} 
                                    />
                                  }
                                  label={item.title}
                              />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Typography>
            </ClickAwayListener>
        );
    }

  getStatusTextStyle = (statusKey: string) => {
    switch (statusKey) {
      case "upcoming":
        return webStyle.statusKeysTextPending;
      case "in_progress":
        return webStyle.statusKeysText;
      case "invoiced":
      case "to_be_invoiced":
        return webStyle.statusKeysTextConverted;
      case "completed":
        return webStyle.statusKeysTextQuoted;
      case "late":
        return webStyle.statusKeysTextOverdue;
      default:
        return webStyle.statusKeysTextCancelled;
    }
  }

  getStatusDividerStyle = (statusKey: string) => {
    switch (statusKey) {
      case "upcoming":
        return webStyle.dividerColorPending;
      case "in_progress":
        return webStyle.dividerLine;
      case "invoiced":
      case "to_be_invoiced":
        return webStyle.dividerColorConverted;
      case "completed":
        return webStyle.dividerColorQuoted;
      case "late":
        return webStyle.dividerColorOverdue;
      default:
        return webStyle.dividerColorCancelled;
    }
  }
  

  jobHeadings = (statusKey: string, jobStatusData: JobsStatusData[]) => {    
    return (
        <>
            <Grid container>
                <Typography variant="body2" 
                style={this.getStatusTextStyle(statusKey)}
                    >
                    {statusKey === 'in_progress' && `${this.props.t('jobs.statusMenu.inProgress')}` || statusKey === 'late' && `${this.props.t('jobs.statusMenu.overdue')}` || statusKey === "to_be_invoiced" && `${this.props.t('jobs.statusMenu.invoiced')}` ||
                        jobStatusData?.find((item) => item.statusName === statusKey)?.title
                    }
                </Typography>
            </Grid>
            <Divider style={this.getStatusDividerStyle(statusKey)}
            />
        </>
    )
} 

jobsViewData = (item: JobData) => {
  return <>
      <Grid item style={webStyle.jobCellLayout} >
          <Typography variant="body1" style={webStyle.tableText1} data-test-id="job_title">
              {item.attributes.job_title}
          </Typography>
      </Grid>
      <Grid item style={webStyle.jobCellLayout} >
          <Typography variant="body1" style={webStyle.tableText1} >
              {item.attributes.custmoer_name}
          </Typography>
      </Grid>
      <Grid item style={webStyle.jobCellLayout} >
          <Typography variant="body1" style={webStyle.tableText1} >
              {moment(item.attributes.start_date).format("DD/MM/YYYY").toString()} - {moment(item.attributes.end_date).format("DD/MM/YYYY").toString()}
          </Typography>
      </Grid>

  </>
}

  requestTableRow = (mockJobsTableRow: {
    id: number, statusKey: string, title : string
  }[]) => {
    return <>
      {mockJobsTableRow.map((item) => (
        <>
          <Grid item xs={12}
            key={item.id}
            onClick={() => this.handleSort(item.statusKey)}
            data-test-id={`handleRequestSort_${item.id}`}
            style={this.state.sortColumn === item.statusKey
              ? webStyle.jobTabRowGrid
              : webStyle.sortJobTabRowGrid}
          >
            <span style={webStyle.jobTableHeadSpan}>
              <Typography variant="body1"
                style={webStyle.tableText}>
                {item.title}
              </Typography>
              <img
                style={{
                  ...webStyle.dropDownSelectIconTable,
                  ...(this.state.sortColumn === item.statusKey && this.state.sortDirection === 'asc'
                    ? webStyle.imgTransform
                    : webStyle.imgDropDownSelectIcon)
                }}
                src={dropDownSelectIcon}
                alt=""
              />
            </span>
          </Grid>
        </>
      ))}
    </>
  }

  statusModalRequest = (requestStatusData: JobStatusData[]) => {
    return (
      <ClickAwayListener onClickAway={this.handleStatusOpen}>
        <Typography>
          <Box style={webStyle.toggleJobModalContainer}>
            <Box style={webStyle.toggleModalStyleJob}>
              {requestStatusData.map((item: JobStatusData) => (
                <Box key={item.id} style={webStyle.statusModal}>
                             <FormControlLabel
                                  control={
                                    <Checkbox
                                      inputProps={{ 'aria-label': `request_status_${item.statusName}` }}
                                      style={webStyle.checkBoxStyle}
                                      checked={this.state.checkedItemsRequest.some((subItem: string) => item.statusName === subItem)}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSelectedRequestStatus(event, item, requestStatusData)}
                                    />
                                  }
                                  label={item.title}
                              />
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }


  isReqeustStatusData = (status: RequestStatusData | string): status is RequestStatusData => {
    return (status as RequestStatusData).data !== undefined;
  };

  isStatusData(status: string | StatusData): status is StatusData {
    return (status as StatusData).data !== undefined;
  }

  requestHeadingStyle = (statusKey: string) => {
    switch (statusKey) {
      case "pending":
        return webStyle.statusTextPending;
      case "quoted":
        return webStyle.statusTextQuoted;
      case "overdue":
        return webStyle.statusTextOverdue;
      default:
        return webStyle.statusTextCancelled;
    }
  }

  requestDividerstyle = (statusKey: string) => {
    switch (statusKey) {
      case "pending":
        return webStyle.diviColorPending;
      case "quoted":
        return webStyle.diviColorQuoted;
      case "overdue":
        return webStyle.diviColorOverdue;
      default:
        return webStyle.diviColorCancelled;
    }
  }
  requestHeadings = (statusKey: string, mockRequestsStatusData: {
    id: number; title: string; statusName: string
  }[]) => {
    
    const { t } = this.props
    if (statusKey === "converted_to_job") {
      return (
        <>
          <Grid container>
            <Typography variant="body2" style={webStyle.statusTextConverted}>{t('request.statusMenu.convertedToJob')}</Typography>
          </Grid>
          <Divider style={webStyle.dividerColor} />
        </>
      )

    } else {
      return (
        <>
          <Grid container >
            <Typography variant="body2" style={this.requestHeadingStyle(statusKey)} data-test-id="request-heading">
              {mockRequestsStatusData?.find((item) => item.statusName === statusKey)?.title}
            </Typography>
          </Grid>
          <Divider style={this.requestDividerstyle(statusKey)} />
        </>
      )
    }
  }
  // istanbul ignore next
  selectJobRequest = () => {
    const condition = this.state.selectedJob?.attributes.custmoer_name ? this.state.selectedJob?.attributes.custmoer_name : this.state.selectedRequest?.attributes.custmoer_name
    return(
      <>
       { this.state.isEditKey ?(
        <Typography data-test-id="condition" className="spacingSmall">{condition?condition:this.state.customerName}</Typography>
       ):(
       <Typography className="spacingSmall">{condition}</Typography>
       )}
          <Button
            className="textSize"
            onClick={this.handleButtonClick}
            data-test-id="btnclick-Change"
          >
          {t('timesheet.jobRequestButton')}
          </Button>
       
      </>
    )
  }
  requestViewData = (item: RequestType) => {
    return (<>
      <div  style={webStyle.jobCellLayout} >
        <Typography variant="body1" style={webStyle.tableText1} data-test-id="request_attributes">
          {item.attributes.request_title}
        </Typography>
      </div>
      <Grid item style={webStyle.jobCellLayout} >
        <Typography variant="body1" style={webStyle.tableText1}  data-test-id="request_attributes_custmoer_name">
          {item.attributes.custmoer_name}
        </Typography>
      </Grid>
      <Grid item style={webStyle.jobCellLayout} >
        <Typography variant="body1" style={webStyle.tableText1} >
          {moment(item.attributes.created_at).format("DD/MM/YYYY").toString()}
        </Typography>
      </Grid>

    </>)
  }

  statusStyle = (title: string) => {
    switch (this.state.dateStatus) {
      case title:
        return webStyle.menuItemStyleBg;
      case 'menuItemStyle':
         return webStyle.menuItemStyle;  
      default:
          return webStyle.menuItemStyle;
  }
  }

  handleStatusModal = (mockDateStatus : {
    id: number, title: string,value:string
  }[]) => {
    if(this.state.isJOb){
return   <ClickAwayListener onClickAway={this.handleStatusModalClose}>
<Typography>
  <Box style={webStyle.statusModalContainer}>
    <Box style={webStyle.dayModal}>
      {mockDateStatus.map((item) => (
        <MenuItem
        style={this.statusStyle(item.title)}
          key={item.id}
          value={item.title}
          id={`selectStatus_${item.id}`}
          data-test-id={`selectStatus_${item.id}`}
          onClick={() => this.handleSelectStatus(item)}
        >
          {item.title}
        </MenuItem>
      ))}
    </Box>
  </Box>
</Typography>
</ClickAwayListener>
    } else{
      return   <ClickAwayListener onClickAway={this.handleStatusModalClose}>
      <Typography>
        <Box style={webStyle.statusModalContainer}>
          <Box style={webStyle.dayModal}>
            {mockDateStatus.map((item) => (
              <MenuItem
              style={this.statusStyle(item.title)}
                key={item.id}
                value={item.title}
                id={`selectStatus_${item.id}`}
                data-test-id={`selectStatus_${item.id}`}
                onClick={() => this.handleSelectStatusRequest(item)}
              >
                {item.title}
              </MenuItem>
            ))}
          </Box>
        </Box>
      </Typography>
    </ClickAwayListener>
    }
  }

    // Customizable Area End
  render() {
    // Customizable Area Start
    const{t}=this.props
    const {timesheetDate, errors, tabValue, statusModal, isTextFieldVisible, searchJobs, allJobsData, 
      allRequestsList, isJOb,selectLanguage} = this.state;

    const jobStatusData = [
      { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
      { id: 2, title: `${t('jobs.statusMenu.upcoming')}`, statusName: "upcoming" },
      { id: 3, title: `${t('jobs.statusMenu.inProgress')}`, statusName: "in_progress" },
      { id: 4, title: `${t('jobs.statusMenu.invoiced')}`, statusName: "invoiced" },
      { id: 5, title: `${t('jobs.statusMenu.completed')}`, statusName: "completed" },
      { id: 6, title: `${t('jobs.statusMenu.overdue')}`, statusName: "late" },
      { id: 7, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
  ]

const mockJobsTableRow = [
    { id: 1, statusKey: "job_title", title: `${t('jobs.tableRow.title')}` },
    { id: 2, statusKey: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
    { id: 3, statusKey: "start_date,end_date", title: `${t('jobs.tableRow.startEndDate')}` },
]



    const requestsStatusData = [
      { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
      { id: 2, title: `${t('request.statusMenu.pending')}`, statusName: "pending" },
      { id: 3, title: `${t('request.statusMenu.quoted')}`, statusName: "quoted" },
      { id: 4, title: `${t('request.statusMenu.convertedToJob')}`, statusName: "converted_to_job" },
      { id: 5, title: `${t('request.statusMenu.overdue')}`, statusName: "overdue" },
      { id: 6, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
    ]

    const requestStatusData = [
      { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
      { id: 2, title: `${t('request.statusMenu.pending')}`, statusName: "pending" },
      { id: 3, title: `${t('request.statusMenu.quoted')}`, statusName: "quoted"},
      { id: 4, title: `${t('request.statusMenu.convertedToJob')}`, statusName: "converted_to_job"  },
      { id: 5, title: `${t('request.statusMenu.overdue')}`, statusName: "overdue" },
      { id: 6, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
    ]

    const mockRequestTableRow = [
      { id: 1, statusKey: "request_title", title: `${t('request.title')}` },
      { id: 2, statusKey: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
      { id: 3, statusKey: "appointment_date", title: `${t('request.subTitles.dateText')}` },
    ]

  
    


    const jobTabStyle = () => {
      const styleMapping:{[key: string]: React.CSSProperties } = {
        0: webStyle.activeTab,
        1: { ...webStyle.tabstyle, ...webStyle.Jobtabstyle },
      };
    
      return styleMapping[tabValue]
    }

    const requesTabStyle = () => {
      const styleMapping:{[key: string]: React.CSSProperties } = {
        0: { ...webStyle.tabstyle, ...webStyle.Requesttabstyle },
        1: webStyle.activeTab,
      };
    
      return styleMapping[tabValue]
    };
    




    const statusMenuStyle = () => {
      const styleMapping  :{[key: string]: React.CSSProperties }= {
        true: webStyle.transform,
        false: webStyle.dropDownSelectIcon,
      };
    
      return styleMapping[statusModal.toString()]
    }

    return (
      <>
      <CustomFormTimeSheet>
      <DashboardSideBar navigation={undefined} id={""} permissions={undefined} oldProps={undefined} history={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.timesheet')} active={9} active1={false} active2={false} />

          <Box 
         className={selectLanguage === "ar" ? "boxMain" : "boxResponse"}
          >
            <div style={webStyle.dflex}>
              <img src={imgbtnBack} style={webStyle.imgwidth} alt="User" data-test-id="backBtn" onClick={this.handleBackBtn} />
              <h3 className="textsizes">{t('timesheet.timesheetHours')}</h3>
            </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} className="spacing">
              <span>{t('settings.date')}*</span>
              <CustomDatePicker className="spacingSmall"
               selectedDate={timesheetDate}
               placeholder="MM/DD/YYYY"
               dateChange={this.handleTimesheetDateChange} 
               />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="spacing">
              <span>{t('timesheetTable.starts')}*</span>
                <TimePicker
                  time={this.state.startTime}
                  data-test-id="change_time"
                  placeholder="00:00 AM"
                  onChange={(timeValue: string) => this.handleChangeHours(timeValue)}
                  timeFormat={this.state.timeFormat}
                  t={t}
                  languageSelected={this.state.selectLanguage}/>
                   <div data-test-id="standard-weight-helper-text" style={webStyle.errorText}>{errors.startTime}</div>  
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="spacing">
              <span>{t('Calendar.label.end')}*</span>
                <TimePicker
                  time={this.state.endTime}
                  data-test-id="change_endtime"
                  placeholder="00:00 PM"
                  onChange={(timeValue: string) => this.handleChangeHoursendTime(timeValue)}
                  timeFormat={this.state.timeFormat}
                  t={t}
               languageSelected={this.state.selectLanguage}
                                                    />
                <div data-test-id="standard-weight-helper-text" style={webStyle.errorText}>{errors.endTime}</div>                                     
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="spacing">
              <span>{t('jobs.label.duration')}</span>
                <CustomizedTextField
                  variant="outlined"
                  fullWidth
                  name={"quoteTitle"}
                  data-test-id="handleQuoteChange"
                  inputProps={{ "data-test-id": "timesheet_duration" }}
                  value={this.state.duration}
                  onChange={this.handleDuration}
                   />
                    <div data-test-id="standard-weight-helper-text" style={webStyle.errorText}>{errors.duration}</div>
            </Grid>
          </Grid> 
          <Grid item xs={6} sm={3} className="spacing">
          <span className="spacingSmall">{t('timesheet.jobRequestTitle')}*</span>   
        {this.selectJobRequest()}
        </Grid>
          <Modal
            open={isTextFieldVisible}
            onClose={this.handlePopupClose}
            data-test-id="Job_Request_modal"
            style={webStyle.jobRequestModal}
          >
            <div style={webStyle.jobContent}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                  <IconButton aria-label="close" style={webStyle.closeIcon} onClick={this.handlePopupClose}><CloseIcon /></IconButton>
              </Typography>
              <Typography id="modal-modal-title"  style={webStyle.modalTitle}>
                  <TabTimeSheet aria-label="simple tabs example" data-test-id="tab_imeSheet"  value={tabValue} onChange={(event, newValue) => this.handleTabValue(newValue)}>
                      <Tab data-test-id="Job_Tab" label={t('dashboardSidebar.job')} style={jobTabStyle()} />
                      <Tab data-test-id="Request_Tab" label={t('dashboardSidebar.request')} style={requesTabStyle()} />
                  </TabTimeSheet>
              </Typography>
              <hr />
              <Typography id="modal-modal-description" style={webStyle.modalDiscription}>
                  <Grid container direction="row" spacing={1}>
                      <Grid item lg={6} md={6} sm={6}>
                        <Box style={webStyle.jobSearchContainer}>
                          <Box>
                            <img style={webStyle.jobSearchIcon} src={searchIcon} alt="" />
                          </Box>
                          <InputBase
                            placeholder={`${t('jobs.placeholder.searchJob')}`}
                            inputProps={{  'aria-label': 'search','data-test-id': 'searchJob' }}
                            value={searchJobs}
                            onChange={this.handleSearchJobs}
                          onKeyPress={this.handleApiresponce}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={3}  md={3} sm={3}
                       >
                           <Box className="child-component">
                           <CustomDatePickers
                                  data-test-id="custom-date-pickers"
                                  type='Job'
                                  selectedDate={this.state.selectedDates}
                                  dateModal={this.state.dateModal}
                                  status={this.state.dateStatus}
                                  languageSelected={this.state.selectedLanguaged}
                                  datePickerRef={this.datePickerRef}
                                  handleSelectStatus={this.handleSelectDateStatus}
                                  handleDateChange={this.handleDateChange}
                                  handleStatusModalClose={this.handleStatusModalClose}
                                  handleDateModalOpen={this.handleDateModalOpen}
                                   />                         
                            </Box>
                      </Grid>
                      <Grid item lg={3}  md={3} sm={3}>
                      {
                        isJOb ?
                          <>
                          <StyledTextField
                              fullWidth
                              defaultValue={`${t('invoice.placeholder.status')}`}
                              variant="outlined"
                              placeholder="status"
                              inputProps={{ "data-test-id": "statusMenuOpen_job" }}
                              InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <img  
                                          style={statusMenuStyle()}
                                          src={dropDownSelectIcon} alt="" />
                                      </InputAdornment>
                                  ),
                              }}
                              onClick={this.handleStatusOpen}
                          >
                          </StyledTextField>
                          {statusModal ? (
                              this.statusModalJob(jobStatusData)
                            ) : null}
                          </> :
                          <>
                            <StyledTextField
                              fullWidth
                              defaultValue={`${t('invoice.placeholder.status')}`}
                              variant="outlined"
                              inputProps={{ "data-test-id": "statusMenuOpen" }}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                     style={statusMenuStyle()}
                                      src={dropDownSelectIcon} alt="" />
                                  </InputAdornment>
                                ),
                              }}
                              onClick={this.handleStatusOpen}
                            >
                            </StyledTextField>
                            {statusModal ? (
                              this.statusModalRequest(requestStatusData)
                            ) : null}
                          </>
                      }

                    </Grid>
                  </Grid>
                  <Box
                    role="tabpanel"
                    hidden={tabValue !== 0}
                    data-test-id={`wrapped-tabpanel-${0}`}
                    aria-labelledby={`wrapped-tab-${0}`}
                    >
                        <Grid container style={webStyle.jobContainer}>
                        <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                            <Grid container style={webStyle.tableRowLayout}>
                                {this.jobsTableRow(mockJobsTableRow)}
                            </Grid>
                            <Divider />
                           {(Object.keys(allJobsData) as (keyof StatusObject)[])?.map((statusKey, index: number) => {
                          const key = statusKey;
                          const status = (allJobsData as StatusObject)[key];
                                return (
                                    <>
                                  {this.jobHeadings(key, jobStatusData)}
                                        {this.isStatusData(status) && status?.data?.length === 0 ?                                            
                                        <Grid container justifyContent="center" alignItems="center" key={index}
                                                style={{ height: '150px' }}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" style={webStyle.emptyData} align="center">
                                                        {t('ProductService.noDataFound')}.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                             <Grid container style={webStyle.tableLayout} key={index}>
                                            <Grid item xs={12}>
                                                {((status.data as JobData[]) || [])?.map((item:JobData) => (
                                                    <GridRow key={item.id} container style={webStyle.jobViewRow}
                                                    data-test-id={`handleView_${item.id}`}
                                                    onClick={() => this.handleViewJob(item)}
                                                    >
                                                        {this.jobsViewData(item)}
                                                    </GridRow>
                                                ))}
                                            </Grid>
                                        </Grid>
                                        }
                                    </>
                                )
                            })}
                           
                        </Grid>
                    </Grid>
                  </Box>
                  <Box
                    role="tabpanel"
                    hidden={tabValue !== 1}
                    id={`wrapped-tabpanel-${1}`}
                    aria-labelledby={`wrapped-tab-${1}`}
                  >
                    <Grid container style={webStyle.jobContainer}>
                      <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                        <Grid container style={webStyle.tableRowLayout}>
                          {this.requestTableRow(mockRequestTableRow)}
                        </Grid>
                        <Divider />
                        {(Object.keys(allRequestsList) as (keyof RequestResType)[])?.map((statusKey,index) => {
                          const key = statusKey;
                          const status = (allRequestsList as RequestResType)[key];

                          return (
                            <React.Fragment key={index}>
                              {this.requestHeadings(statusKey, requestsStatusData)}
                              { status?.data?.length === 0 ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '150px' }}>
                                  <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={webStyle.emptyData} align="center">
                                      {t('ProductService.noDataFound')}.
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : 
                              <Grid container style={webStyle.tableLayout}>
                              <Grid item xs={12}>
                                {(status.data as RequestType[])?.map((item: RequestType) => {
                                  return(
                                  <GridRow
                                  key={item.id}
                                    container
                                    style={webStyle.jobViewRow}
                                    data-test-id={`handleRequestView_${item.id}`}
                                    onClick={() => this.handleViewRequest(item)}
                                  >
                                    {this.requestViewData(item)}
                                  </GridRow>
                                )})}
                              </Grid>
                            </Grid>}

                            </React.Fragment>
                          );
                        })}

                      </Grid>
                    </Grid>
                  </Box>
                 </Typography>
            </div>
          </Modal>
          
          <Grid className="spacing" >
          <span className="spacingSmall">{t('suppliers.description')}</span> 
          </Grid>
             <StyledTextFieldQuote
                    variant="outlined"
                    className="spacingSmall"
                    multiline
                    minRows={5}
                    maxRows={5}
                    style={webStyle.notesInput}
                    placeholder={t('timesheet.typeHere')}
                    name="message"
                    onChange={this.handleDescription}
                    value={this.state.description}
                    data-test-id= "description"
                    inputProps={{ "data-test-id": "description_timesheet" }}
                  />
          <div data-test-id="standard-weight-helper-text" style={webStyle.errorText}>{errors.description}</div>
          <div className="justify">
            {
              
              this.state.isEditKey ? 
<>
               <Button className="btnCancel" data-test-id="delete_timesheet" onClick={this.handleDelete}>{t('boEmployees.delete')}</Button>
              <Button className="btnSave" data-test-id="Edit_timesheet" onClick={this.handleEditTimesheet}>{t('boEmployees.edit')}</Button>     
              </> : 
              <>
               <Button className="btnCancel" data-test-id="btnclick_time" onClick={() =>this.navigateTo({props:this.props,screenName: "Timesheet"})}>{t('boEmployees.cancel')}</Button>
              <Button className="btnSave" data-test-id="save_timesheet" onClick={this.handleSaveTimesheet}>{t('boEmployees.save')}</Button>     
              </>
            }
                        
              </div> 
          </Box>
          </CustomFormTimeSheet>
          <StyledRoot>
          <CustomTimeSheet
          data-test-id="customTimeSheet"
          open={this.state.isPopupOpen}
          onClose={this.handlePopupClose}
          style={webStyle.dialogBox}
        >
          <div className="display">   
          <DialogTitle style={webStyle.textlg}>Delete Timesheet Entry</DialogTitle>
          <IconButton onClick={this.handlePopupClose}>
            <CloseIcon />
        </IconButton>
        </div>
          <DialogContent>
            <DialogContentText style={webStyle.textsm}>
            Are you sure you want to permanently delete timesheet entry?
            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button data-test-id="popupclose" onClick={this.handlePopupClose} className="btnCancelpopup">
              Cancel
            </Button>
            <Button data-test-id="popupdelete" onClick={this.handlePopupDelete} className="btndeletepopup">
              Delete
            </Button>
          </DialogActions>
        </CustomTimeSheet>
        </StyledRoot>
    
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  textareaInput: {
    width: "100%",
    height: "130px !important",
    borderRadius: "8px",
  },
  textareaInput2: {
    width: "100%",
    borderRadius: "8px",
  },
  textlg:{
    fontSize:"22px"
  },
  imgwidth:{
    width:"32px",
    height:"24px",
    cursor: "pointer"
  },
  dflex:{
    display:"flex",
    padding:"0 0 10px 0"
  },
  modalTitle: {
    marginBottom: "15px"
  },
  modalDiscription: {
    marginTop: "24px"
  },
  statusModal: {
    display:"flex",
    gridGap:"0.5rem",
    marginBottom:"3px"
  },
  checkBoxStyle: {
    padding:"0px",
     color: "#1c18af"
  },
  jobContent: {
  position: 'absolute' as 'absolute',
  top: '20%',
  left: '50%',
  width: "960px",
  background: "#fff",
  padding: "20px 24px",
  border:"0px",
  boxShadow: "24px",
  p: "4px",
  maxWidth: "90%",
  maxHeight: "calc(100% - 114px)",
  transform: "translate(-50%, -17%)",
  overflowY: "auto" as 'auto'
  },
  activeTab: {
    width: "90px",
    minWidth: "80px",
    background: "#1C18AF",
    borderRadius: "10px",
    color: "#fff",
    paddingBlock: "0px",
    height: "44px",
    minHeight: "20px",
    textTransform: "capitalize" as "capitalize"
  },
  tabstyle: {
    width: "90px",
    height: "44px",
    paddingBlock: "0px",
    minWidth: "80px",
    minHeight: "20px",
    cursor: "pointer",
    padding: "10px 20px",
    border: "1px solid transparent",
    backgroundColor: "#e0e0e0",
    transition: "background-color 0.3s ease, color 0.3s ease",
    color: "#000",
    textTransform: "capitalize" as "capitalize"
  },
  Jobtabstyle: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: '10px',
  },
  Requesttabstyle: {
    borderBottomRightRadius: '10px',
    borderTopRightRadius: "10px",
  },
  errorText: {
    display:"flex",
    marginTop: "5px",
    color: CONSTANTS.color.crimsonRed,
  },
  dialogBox:{
   
  },
  textsm:{
    fontSize:"16px"
  },
  jobSearchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
},
jobSearchIcon: {
  height: "20px",
  width: "20px",
  margin: "0 0.6rem 0 1.3rem",
},
dropDownSelectIcon: {
  width: "13px",
  height: "9px",
  cursor: "pointer"
},
transform: {
  width: "13px",
  height: "9px",
  cursor: "pointer",
  transform: "rotate(180deg)"
},
closeIcon:{
  float: "right" as "right",
  padding: "0px",
},
toggleJobModalContainer: {
  position: "absolute" as const,
  padding: "1rem",
  width: "200px",
  zIndex: 9,
  minHeight: "194px",
  borderRadius: "5px",
  backgroundColor: CONSTANTS.color.white,
  boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
},
toggleModalStyleJob: {
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
},
jobContainer: {
  marginTop: "2rem",
  maxWidth: "100%",
  backgroundColor: CONSTANTS.color.white,
  boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  minHeight: "300px",
  overflow: 'scroll',
},
tableRowLayout:{
  display: 'flex',
  width: '100%',
  flexWrap: 'nowrap' as 'nowrap',
  gridAutoFlow: 'column',
},
jobTabRowGrid: {
  padding: '16px',
  backgroundColor: "rgba(232,232,232,0.4)", 
  width: `${(100 / 3)}%`, 
  minWidth: "185px" 
},
sortJobTabRowGrid: {
  width: `${(100 / 3)}%`,
   minWidth: "185px",
   padding: '16px',
},
jobTableHeadSpan: {
  cursor: "pointer",
  borderRadius: "2px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
},
tableText: {
  fontWeight: 400,
  whiteSpace: 'nowrap' as "nowrap" 
},
dropDownSelectIconTable: {
  width: "13px",
  height: "9px",
  cursor: "pointer",
  marginLeft: "2rem",
} as React.CSSProperties,
imgTransform: {
  transform: "rotate(180deg)"
},
imgDropDownSelectIcon: {
  width: "13px",
  height: "9px",
  cursor: "pointer"
},
jobRequestModal: {
  maxHeight: "calc(100% - 64px)",
  overflow: 'scroll',
},
statusKeysTextPending: {
  color: "#FF5D65",
  fontWeight: 500,
  marginTop: "10px",
  marginLeft: "16px",
  textTransform: "capitalize" as 'capitalize'
},

statusKeysText: {
  fontWeight: 500,
  marginTop: 10,
  marginLeft: 10,
  textTransform: "capitalize" as 'capitalize',
  color: "#FF9718"
},
statusKeysTextQuoted: {
  color: "#4B7206",
  fontWeight: 500,
  marginTop: 10,
  marginLeft: 10,
  textTransform: "capitalize" as 'capitalize'
},

statusKeysTextConverted: {
  color: "#FF6FB8",
  fontWeight: 500,
  marginTop: 10,
  marginLeft: 10,
  textTransform: "capitalize" as 'capitalize'
},
statusKeysTextOverdue: {
  color: "#902744",
  fontWeight: 500,
  marginTop: 10,
  marginLeft: 10,
  textTransform: "capitalize"as 'capitalize'
},
statusKeysTextCancelled: {
  color: "#BDBDBD",
  fontWeight: 500,
  marginTop: 10,
  marginLeft: 10,
  textTransform: "capitalize"as 'capitalize'
},
dividerColorPending: {
  marginTop: "8px",
  backgroundColor: "#FF5D65"
},
dividerLine: {
  marginTop: "8px",
  backgroundColor: "#FF9718"
},
dividerColorQuoted: {
  marginTop: "8px",
  backgroundColor: "#4B7206"
},
dividerColorConverted: {
  marginTop: "8px",
  backgroundColor: "#FF6FB8"
},
dividerColorOverdue: {
  marginTop: "8px",
  backgroundColor: "#902744",
},
dividerColorCancelled: {
  marginTop: "8px",
  backgroundColor: "#BDBDBD"
},
emptyData: {
  color: CONSTANTS.color.darkGrey,
  fontWeight: 500,
},
tableLayout:{
  maxHeight: '300px', 
  overflow: 'scroll', 
  minWidth: "fit-content"
},
jobViewRow: {
  display: 'flex',
  flexWrap: 'nowrap' as 'nowrap',
  width: '100%',
  zIndex: 0,
  gridAutoFlow: 'column',
  position: 'relative' as 'relative',
  paddingBottom: "10px",
  justifyContent: 'end'
},
jobCellLayout: {
  padding: '16px 16px 0px 16px',
  width: `${(100 / 3)}%`, 
  minWidth: "185px"
},
tableText1: {
  fontWeight: CONSTANTS.fontWeight.fontWeight500,
  color: CONSTANTS.color.lightBrown
},
statusModalContainer: {
  position: "absolute" as 'absolute',
  zIndex: 9,
  width: "100%",
  minHeight: "102px",
  maxWidth: "150px",
  backgroundColor: CONSTANTS.color.white,
  borderRadius: "5px",
  boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
},
menuItemStyleBg: {
  color: CONSTANTS.color.grey,
  fontSize: "1.1667rem",
  fontWeight: CONSTANTS.fontWeight.fontWeight400,
  backgroundColor: CONSTANTS.color.cement
},
menuItemStyle: {
  color: CONSTANTS.color.grey,
  fontSize: "1.1667rem",
  fontWeight: CONSTANTS.fontWeight.fontWeight400,
},
textfieldStyleAr: {
  "& .MuiOutlinedInput-adornedEnd": {
    paddingLeft:"7px"
  },
} as React.CSSProperties ,
textfieldStyleEn: {
  "& .MuiOutlinedInput-adornedEnd": {
    paddingLeft:"0px"
  },
  "& .MuiOutlinedInput-input": {
    cursor: "pointer",
    padding: "15px 0 15px 14px",
  },
} as React.CSSProperties ,
dayModal: {
  gridGap:"0.5rem",
  marginBottom:"3px"
},
startEndDateContainer: {
  display: "flex",
  width: '100%',
  position: "absolute" as 'absolute',
  height: "80px",
  maxWidth: "274px",
  borderRadius: "5px",
  backgroundColor: CONSTANTS.color.white,
  transform: "translate(152px, 167px)",
  padding: "0.8rem",
  boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  zIndex:1,
  justifyContent: "space-between",
},
startEndDateContainerTF: {
  display: "flex",
  position: "absolute" as 'absolute',
  width: '100%',
  maxWidth: "274px",
  borderRadius: "5px",
  height: "80px",
  boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  padding: "0.8rem",
  backgroundColor: CONSTANTS.color.white,
  zIndex:1,
  justifyContent: "space-between",
  transform: "translate(-152px, 167px)"
},
statEndDateInnerContainer: {
  display: "flex",
  flexDirection: "column" as 'column',
  justifyContent: "center",
},
startDateText: {
  color: CONSTANTS.color.lightGrey,
  fontSize: "14px",
  fontWeight: CONSTANTS.fontWeight.fontWeight400,
},
startDateTextfield: {
  display: 'flex',
  justifyContent: "space-between",
  width: "121px",
  alignItems: "center",
  borderRadius: "4px",
  minHeight: "30px",
  border: "1px solid rgba(0,0,0,0.1)",
  padding: "4px"
},
startDateStyle: {
  color: CONSTANTS.color.textLightGrey,
  fontSize: "14px",
  fontWeight: CONSTANTS.fontWeight.fontWeight500,
  lineHeight: 0
},
calendarIcon: {
  height: "14px",
  width: "14px",
  cursor: "pointer",
},
datePickerContainerAR: {
  position: "absolute" as 'absolute',
  zIndex: 1,
  transform: "translate(-152px, 252px)",
  backgroundColor: "#fff",
  padding: "1rem 0",
  maxWidth: "100%",
  border: 0,
  boxShadow: "0px 0px 15px rgba(0,0,0,0.12)",
  width: "274px",
  display: "flex",
  justifyContent: "center",
},
datePickerContainer: {
  position: "absolute" as 'absolute',
  transform: "translate(152px, 252px)",
  padding: "1rem 0",
  zIndex: 1,
  border: 0,
  backgroundColor: "#fff",
  width: "274px",
  maxWidth: "100%",
  boxShadow: "0px 0px 15px rgba(0,0,0,0.12)",
  display: "flex",
  justifyContent: "center",
},
customHeader: {
  margin: "0px 6px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
},
textFormate: {
  fontSize: "16px",
  fontWeight: CONSTANTS.fontWeight.fontWeight600,
  color: CONSTANTS.color.darkBlue
},
imageButton: {
  height: "25px",
  width: "25px",
  cursor: "pointer"
},
dateHr: {
  display: 'flex',
  height: "1px",
  margin: "10px -18px",
  backgroundColor: CONSTANTS.color.darkBlue
},
  dividerColor: {
    marginTop: "8px",
    backgroundColor: "#74ab39"
  },
  statusTextConverted: {
    color: "#74ab39",
    fontWeight: 500,
    marginTop: "10px",
    marginLeft: "10px",
    textTransform: "capitalize" as 'capitalize'
  },
  statusTextPending: {
    fontWeight: 500,
    color: "#6764d6",
    marginTop: "10px",
    marginLeft: "10px",
    textTransform: "capitalize" as 'capitalize'
  },
  toggleIconsContainerJob: {
    display: "flex",
    alignItems: "center"
  },
  statusTextQuoted: {
    color: "#fa6400",
    fontWeight: 500,
    marginTop: "10px",
    marginLeft: "10px",
    textTransform: "capitalize" as 'capitalize'
  },
  statusTextOverdue: {
    color: "#e02020",
    marginTop: "10px",
    fontWeight: 500,
    marginLeft: "10px",
    textTransform: "capitalize" as 'capitalize'
  },
  statusTextCancelled: {
    color: "#8C8C8C",
    marginTop: "10px",
    fontWeight: 500,
    marginLeft: "10px",
    textTransform: "capitalize" as 'capitalize'
  },
  diviColorPending: {
    marginTop: "8px",
    backgroundColor: "#6764d6"
  },
  diviColorConverted: {
    marginTop: "8px",
    backgroundColor: "#74ab39"
  },
  diviColorQuoted: {
    marginTop: "8px",
    backgroundColor: "#fa6400"
  },
  diviColorOverdue: {
    marginTop: "8px",
    backgroundColor: "#e02020",
  },
  diviColorCancelled: {
    marginTop: "8px",
    backgroundColor: "#8C8C8C"
  },
  notesInput:{
    width: "100%",
      borderRadius: "8px",
  }
}
const CustomFormTimeSheet = styled(StyledRoot)({

  "& .boxResponse": {
    marginTop: "8rem",
    marginLeft:"310px",
    marginRight:"30px",
    "@media (max-width: 1279px)": {
      marginLeft:"15px",
    },
  },

  "& .boxMain" : {
    marginRight:"283px",
    marginTop: "8rem",
    marginLeft:"27px",
    "@media (max-width: 1279px)": {
      marginRight:"15px",
    },
  },
  "& .btnCancel":{
    height:"67px",
    width:"202px",
    borderRadius:"10px",
    color:"#999999",
    fontSize:"22px",
    fontWeight: 500,
    textAlign: "center",
    textTransform: "capitalize",
    background: "#ffffff",
    border: "1px solid #999999",
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    "@media (max-width: 600px)": {
      width: "100%",
      maxWidth:"500px",
      height:"50px"
    },
    '&:hover': {
      backgroundColor: '#999999',
      color: 'white',
      cursor: "pointer"
    },
  },
  "& .btnSave":{
    height:"67px",
    width:"202px",
    borderRadius:"10px",
    backgroundColor:"#1C18AF",
    color:"#FFFFFF",
    fontSize:"22px",
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    "@media (max-width: 600px)": {
      width: "100%",
      maxWidth:"500px",
      order:-1,
      height:"50px"
    },
    
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: 'white',
      border: "none",
      cursor: "pointer"
    },
  },
  "& .MuiOutlinedInput-multiline":{
    height:"148px",
  },
  "& .textSize":{
    fontSize:"22px",
    marginTop:"10px",
    width:"290px",
    height:"67px",
    borderRadius: "15px",
    backgroundColor: "#1C18AF",
    color: "white",
    padding: "10px 20px",
    border: "none",
    textTransform: "none" as const,
    fontWeight:"normal",
    "@media (max-width: 420px)": {
      width:"320px"
    },
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer"
    },
  },
  "& .justify":{
    display:"flex",
    justifyContent:"space-between",
    marginTop:"30px",
    "@media (max-width: 600px)": {
      gap: "20px",
      flexDirection: "column",
      alignItems: "center"
    },
    
  },
  "& .spacing":{
    margin:"20px 0 10px 0",
  },
  "& .spacingSmall":{
    marginTop:"10px",
  },
  // "& .spacingSmalle":{
  //   // marginTop:"10px",
  //   height:"110px"
  // },
  "& .textFieldWidth":{
    border :"1px solid black",
    width:"100%"
  },
  "& .textsizes": {  
    fontSize:"24px",
    fontWeight:500,
    marginInlineStart:"12px"
  },
})


const TabTimeSheet = styled(Tabs)({
  "& div" : {
    "& span" : {
      display: "contents"
    }
  }
})

const GridRow = styled(Grid)({
  "&:active": {
    backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      cursor: "pointer"
      }
})

const StyledTextFieldStatus = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "56px",
      fontSize: "1.1667rem",
      color: CONSTANTS.color.grey,
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      cursor: "pointer",
      lineHeight: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important"
      }
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: CONSTANTS.color.thickBlue,
    },
    "& .MuiSvgIcon-root": {
      marginTop: "-5px",
      fontSize: "2.1rem",
    }
  }
}))(TextField)
const CustomTimeSheet = styled(Dialog)({
  "& .btnCancelpopup":{
    height: "56px",
    width: "156px",
    background: "#ffffff",
    border: "1px solid #999999",
    fontSize: "22px",
    fontWeight: 500,
    borderRadius:"10px",
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: '#999999',
      color: "white",
      cursor: "pointer"
    },
    color: "#999999",
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    
  },
  "& .btndeletepopup":{
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer"
    },
    backgroundColor:"#1C18AF",
    color:"#FFFFFF",
    height:"56px",
    width:"156px",
    borderRadius:"10px",
    fontSize:"22px"
  },
  "& .MuiPaper-rounded":{
    borderRadius:"15px !important",
    padding:"15px !important",
    "@media (max-width: 420px)": {
      padding:"10px !important",
    },
  },
  "& .MuiButton-label":{
    fontWeight:"normal",
    textTransform:"capitalize",
  },
  "& .MuiDialogActions-root":{
   justifyContent:"space-between"
  },
  "& .MuiTypography-h6":{
    fontSize:"25px !important",
    color:"black !important",
    "@media (max-width: 420px)": {
      fontSize:"20px !important",
      width:"21vh" 
    },
   },
   "& .display":{
     justifyContent:"space-between",
    display:"flex",
   },
})

const TimesheetSnackbar = withSnackbar(TimeSheetHours)
export default withTranslation()(withPermission(TimesheetSnackbar));
// Customizable Area End