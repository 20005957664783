import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Divider,Avatar,IconButton
} from "@material-ui/core";
import { Theme, withStyles, StyleRulesCallback } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { searchIcon,noResultFoundImg } from "./assets";
import { userIcon } from "../../dashboard/src/assets"
import ClearIcon from '@material-ui/icons/Clear';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { StyledTextField } from "../../../components/src/CustomizableComponents.web"
import { withTranslation } from "react-i18next";
import moment from "moment";
// Customizable Area End

import SearchController, {
  Props,
} from "./SearchController.web";

// Customizable Area Start
interface MockSelectTabItem{
  id:number
  title:string
  tabName:string
}
interface SearchResultArrayItem {
  "type": string,
  "name": string,
  "email": string,
  "id": number,
  "title":string
  "start_date": string,
  "end_date": string,
  "customer_name"?: string,
  "code": string,
  "employee_image_url":string
  "employee_email":string
  "employee_name":string
  "appointment_date":string
  "appointment_time":string
  "status":string
}
const language = localStorage.getItem("lang")

const statusColorCode=(status:string)=>{
  switch (status) {
    case 'Archived':
      return "#8C8C8C";
    case 'Paid':
      return "#4EA5DD";
    case 'Payment Overdue':
      return "#8F2542";
    case 'Awaiting Payment':
      return "#0F4ED1";
    case 'Late':
      return "#902744";
    case 'Invoiced':
      return "#FF6FB8";
  }
}

const statusColor = ( status:string ) => {
  switch (status) {
    case 'Pending':
      return CONSTANTS.color.deepOrange;
    case 'Completed':
      return CONSTANTS.color.limeGreen;
    case 'Bad Debt':
      return "#993953";
    case 'In Progress':
      return CONSTANTS.color.darkBlue;
    case 'Sent':
      return "#005719";
    case 'Quoted':
      return "#fa6400";
    case 'Converted To Job':
      return "#74ab39";
    case 'Overdue':
      return "#e02020";
    case 'Cancelled':
      return "#8C8C8C";
    case 'Upcoming':
      return "#6764d6";
    default:
      return statusColorCode(status);
  }
};
const statusTitle = (status: string) => {
  if (status === 'bad_debt') {
    return "Bad Debt"
  } else if (status === 'in_progress') {
    return "In Progress"
  } else if (status === 'converted_to_job') {
    return "Convert To Job"
  } else if (status === 'awaiting_payment') {
    return "Awaiting Payment"
  } else if (status === 'payment_overdue') {
    return "Payment Overdue"
  } else{
    return status
  }
}
const typeBtn = (classes: ClassNameMap<string>, item: SearchResultArrayItem,selectTab:string) => {
  return <>
    {!selectTab &&
      <Grid item className={classes.ml_20}>
        <Button className={classes.typeBtn}>
          <Typography variant="body2">{item.type}</Typography>
        </Button>
      </Grid>
    }
  </>
}
const title = (classes: ClassNameMap<string>, item: SearchResultArrayItem,selectTab:string) => {
  return <Grid container className={classes.m_15}>
    <Grid item>
    <Typography className={classes.titleText} variant="subtitle2">{item.title}</Typography>
    </Grid>
    {typeBtn(classes,item,selectTab)}
  </Grid>
}
const customerName = (classes: ClassNameMap<string>, item: SearchResultArrayItem) => {
  return <Grid container>
    <Typography className={classes.dateLabel} variant="body1">Customer Name : {item.customer_name}</Typography>
  </Grid>
}
const statusBtn = (classes: ClassNameMap<string>, item: SearchResultArrayItem) => {
  return <>
    <Grid container className={classes.m_15}>
      <StyledStatusButton style={{ background: statusColor(item.status) }}><Typography>{statusTitle(item.status)}</Typography></StyledStatusButton>
    </Grid>
    <Divider className={classes.dividerWidth} />
  </>
}
const empData = (classes: ClassNameMap<string>, item: SearchResultArrayItem,selectTab:string) => {
  return <>
    <Grid container className={classes.m_15}>
      <Grid item>
      <Typography className={classes.titleText} variant="subtitle2">{item.name}</Typography>
      </Grid>
      {typeBtn(classes,item,selectTab)}
    </Grid>
    <Grid container><Typography className={classes.dateLabel} variant="body1">Email : {item.email}</Typography></Grid>
  </>
}
const tableData = (classes: ClassNameMap<string>, item: SearchResultArrayItem,selectTab:string) => {
  return <>
    {title(classes, item,selectTab)}
    {customerName(classes, item)}
    <Grid container>
      <Grid item><Typography className={classes.dateLabel} variant="body1">Start Date : {moment(item.start_date).format("MMM DD, YYYY")}</Typography></Grid>
      <Grid item><Typography variant="body1" className={`${classes.ml_40} ${classes.dateLabel}`}>End Date : {moment(item.end_date).format("MMM DD, YYYY")}</Typography></Grid>
    </Grid>
    {statusBtn(classes, item)}
  </>
}
const supplierData=(classes: ClassNameMap<string>,item: SearchResultArrayItem,selectTab:string)=>{
  return <>
    <Grid container className={classes.mt_10}>
      <Grid item>
        <img className={classes.userIcon} src={userIcon} />
      </Grid>
      <Grid item className={classes.ml_20} style={{ alignItems: "center", display: "flex" }}>
        <Typography className={classes.titleText} variant="subtitle2">
          {item.type==='setting' ? item.title :item.name }
        </Typography>
      </Grid>
      <Grid item style={{ alignItems: "center", display: "flex" }}>{typeBtn(classes,item,selectTab)}</Grid>
    </Grid>
  <Divider className={classes.dividerWidth} />
  </>
}
const invoiceQuoteJobTableData = (classes: ClassNameMap<string>, item: SearchResultArrayItem,selectTab:string) => {
  return <>
    {item.type === 'Invoice' && tableData(classes, item, selectTab)}
    {item.type === 'Quote' && tableData(classes, item, selectTab)}
    {item.type === 'Job' && tableData(classes, item, selectTab)}
  </>
}
const noResultFoundData = (classes: ClassNameMap<string>) => {
  return <Grid container justifyContent="center">
    <Grid item xs={12} className={classes.noResultFoundContainer}>
      <img className={classes.noResultFoundImg} src={noResultFoundImg} alt="" />
      <Grid container justifyContent="center">
        <Typography variant="subtitle1" className={classes.noResultFoundText}>No results found.</Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Typography variant="body1" color="textPrimary">
          We Couldn't Find A Match For "Heading". Please Try Another Search.
        </Typography>
      </Grid>
    </Grid>
  </Grid>
}
// Customizable Area End

export class Search extends SearchController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const buttonTab = [
      { id: 1, title: `${t('dashboardNavbar.task')}`, tabName: "task"},
      { id: 2, title: `${t('dashboardSidebar.request')}`, tabName: "request" },
      { id: 3, title: `${t('dashboardSidebar.job')}`, tabName: "job" },
      { id: 4, title: `${t('dashboardSidebar.invoice')}`, tabName: "invoice" },
      { id: 5, title: `${t('dashboardSidebar.quote')}`, tabName: "quote" },
      { id: 6, title: `${t('dashboardSidebar.timesheet')}`, tabName: "timesheet" },
      { id: 7, title: `${t('suppliers.supplier')}`, tabName: "supplier"},
      { id: 8, title: `${t('dashboardSidebar.customer')}`, tabName: "customer" },
      { id: 9, title: `${t('reports.employee')}`, tabName: "employee" },
      { id: 10, title: `${t('dashboardSidebar.setting')}`, tabName: "setting" },
    ]
    return (
      <>
        <DashboardSideBar oldProps={undefined} permissions={undefined} {...this.props} navMenuItem={t('dashboardNavbar.search')} active={false} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.languageSelected}>
          <Box sx={webStyle.container}>
            <Box style={webStyle.innerContainer} className={classes.innerContainer}>
              <Box style={webStyle.searchOuterContainer}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box style={webStyle.searchContainer}>
                      <img style={webStyle.searchIcon} src={searchIcon} alt="" />
                      <StyledTextField
                        variant="outlined"
                        placeholder="Search here..."
                        onChange={this.handleSearch}
                        value={this.state.advanceSearch}
                        languageSelected={this.state.languageSelected}
                        data-test-id='handleSearch'
                        InputProps={{
                          endAdornment: (
                            this.state.advanceSearch && (
                              <IconButton onClick={this.handleClear} edge="end">
                                <ClearIcon />
                              </IconButton>
                            )
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container style={webStyle.searchedLocationContainer}>
                <Grid item xs={11} className={classes.btnContainer}>
                  {buttonTab.map((item: MockSelectTabItem) => {
                    return <StyledButton
                      variant="contained"
                      data-test-id={`selectTab_${item.id}`}
                      disabled={!this.state.advanceSearch}
                      className={item.tabName === this.state.selectTab === true ? `${classes.margin} ${classes.tabBtn}` : classes.margin}
                      onClick={this.selectTab.bind(this, item)}
                    >
                      <Typography variant="body2">{item.title}</Typography>
                    </StyledButton>
                  })}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Divider />
          <Grid container style={webStyle.innerContainer1} className={classes.innerContainer1}>
            <Grid item xs={12}>
              {this.state.searchListData ?
                <>
                  <Grid container className={classes.mt_15}> 
                    <Grid item>
                      <Typography variant="subtitle2">
                        {buttonTab.find((item: MockSelectTabItem) => item.tabName === this.state.selectTab)?.title}
                      </Typography>
                    </Grid>
                    {this.state.selectTab &&
                      <Grid item className={`${classes.dataLengthNo} ${classes.ml_20}`}>
                        {this.state.searchListData && this.state.searchListData.results && this.state.searchListData.results.length}
                      </Grid>
                    }
                  </Grid>
                  <Grid container>
                    {this.state.searchListData.results && this.state.searchListData.results.map((item: SearchResultArrayItem) => {
                      return <>
                        {item.type === 'Task' &&
                          <>
                            {title(classes, item,this.state.selectTab)}
                            <Grid container alignItems="center">
                              <Grid item>
                                <Avatar style={{ marginBottom: '12px', marginTop: '15px' }} src={item.employee_image_url || ''} />
                              </Grid>
                              <Grid item className={classes.paddingLeft_9}>
                                <Typography variant="body1" className={classes.empNameText}>
                                  {item.employee_name}
                                </Typography>
                                <Typography className={classes.empEmailText}>
                                  {item.employee_email}
                                </Typography>
                              </Grid>
                            </Grid>
                            {statusBtn(classes, item)}
                          </>
                        }
                        {invoiceQuoteJobTableData(classes,item,this.state.selectTab)}
                        {item.type === 'Request' &&
                          <>
                            {title(classes, item,this.state.selectTab)}
                            {customerName(classes, item)}
                            <Grid container>
                              <Grid item><Typography className={classes.dateLabel} variant="body1">Appointment Date : {moment(item.appointment_date).format("MMM DD, YYYY")}</Typography></Grid>
                              <Grid item><Typography variant="body1" className={`${classes.ml_40} ${classes.dateLabel}`}>Appointment time : {moment(item.appointment_time).format('h:mm A')}</Typography></Grid>
                            </Grid>
                            {statusBtn(classes, item)}
                          </>
                        }
                        {item.type === 'Customer' && empData(classes, item, this.state.selectTab)}
                        {item.type === 'Employee' && empData(classes, item, this.state.selectTab)}
                        {item.type === 'Supplier' && supplierData(classes, item, this.state.selectTab)}
                        {item.type === 'setting' && supplierData(classes, item, this.state.selectTab)}
                      </>
                    })}
                  </Grid>
                </> : noResultFoundData(classes)
              }
              {this.state.searchListData && this.state.searchListData.length === 0 && noResultFoundData(classes)}
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <LoadingBackdrop
              loading={this.state.loading}
            />
          </Grid>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledButton = withStyles({
  root: {
    textTransform: "capitalize",
    backGround:"#F5F6F9",
    boxShadow:"none",
    borderRadius:"6px",
    maxWidth:'120px',
    minWidth:'90px',
    color:CONSTANTS.color.darkGrey,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer",
    }
  },
 
})(Button)
const StyledStatusButton = withStyles({
  root: {
    textTransform: "capitalize",
    boxShadow:"none",
    borderRadius:"6px",
    minWidth:'90px',
    color:CONSTANTS.color.white,
  },
 
})(Button)
export const styles:StyleRulesCallback<Theme, {}, string> = (theme: Theme) => ({
  innerContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 1.2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem !important",
    },
  },
  deepOrange:{
    color:CONSTANTS.color.deepOrange
  },
  userIcon: {
    borderRadius: "50%",
    height: "40px",
    width: "40px"
  },
  dataLengthNo: {
    borderRadius: "60%",
    color: CONSTANTS.color.white,
    background: CONSTANTS.color.darkBlue,
    paddingTop: "6px",
    width:"31px",
    height:"30px",
    textAlign:"center"
  },
  typeBtn:{
    width:"90px",
    textTransform: "capitalize",
    background:"#e0e0e0",
    color:"#959596",
    height:"30px",
    borderRadius:"6px"
  },
  dividerWidth:{
    width:"100%",
    margin:"10px 0px",
    height:"2px"
  },
  mt_10:{
    marginTop:"10px"
  },
  mt_15:{
    marginTop:"15px"
  },
  paddingLeft_9:{
    paddingLeft:"9px"
  },
  ml_40:{
    marginLeft:"40px"
  },
  ml_20:{
    marginLeft:"20px"
  },
  m_15:{
    margin:"15px 0px"
  },
  titleText:{
    fontWeight:CONSTANTS.fontWeight.fontWeight400
  },
  empNameText:{
    fontWeight:CONSTANTS.fontWeight.fontWeight500,
    color:"#676767"
  },
  dateLabel:{
    color:"#676767"
  },
  empEmailText:{
    fontWeight:CONSTANTS.fontWeight.fontWeight400,
    color:"#757679",
    fontSize:"16px"
  },
  btnContainer: {
    flexWrap: "nowrap",
    overflowX: "scroll",
    display: "flex",
    flexDirection: "row",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  noResultFoundContainer:{
    textAlign:"center",
    width:"100%",
    marginTop:"3rem"
  },
  tabBtn: {
    backgroundColor: "#1C18AF",
    color: "#ffffff",
  },
  noResultFoundImg: {
    width: "15%",
    height: "90%"
  },
  noResultFoundText: {
    color: CONSTANTS.color.darkGrey,
    textAlign: 'center',
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  customOption: {
    color: CONSTANTS.color.coolDarkGrey
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const webStyle = {
  container: {
    marginTop: "5rem",
    paddingBottom: "1rem",
  },
  innerContainer: {
    padding: '10px 24px'
  },
  innerContainer1: {
    padding: '10px 30px'
  },
  searchOuterContainer: {
    padding: "3rem 0 0 0"
  },
  searchContainer: {
    display: "flex",
    alignItems: "center"
  },
  searchIcon: {
    height: "20px",
    width: "20px",
    margin: language === 'ar' ? "0px 1.6rem 0px -2.6rem" : "0px -2.4rem 0 1.3rem" 
  },
  clearIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.4rem 0 -2.5rem",
  },
  autocompleteStyle: {
    width: '100%'
  },
  searchedLocationContainer: {
    padding: "1.5rem 0 0 0"
  },
}

const searchStyles = withStyles(styles)(Search);
export default withTranslation()(withSnackbar(searchStyles));
// Customizable Area End 