import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { checkLoggedInUserDashboard } from "../../../../packages/blocks/dashboard/src/utility.web";
import { apiCall,checkTokenExpired } from '../../../components/src/CommonFunction'
import { EmailAccount, EmailAccountProps } from "./TimeSheetWeeklyController";
import moment from "moment";
interface TimesheetAttributes {
  id: number;
  account_id: number;
  associate_id: number;
  associate_type: string;
  status: string | null;
  date: string;
  start_time: string;
  end_time: string;
  employee_id: number;
  description: string | null;
  rate: number | null;
  billable: boolean;
  created_at: string;
  updated_at: string;
  customer_name: string;
  duration: string
}

export interface Timesheet {
  id: string;
  type: string;
  attributes: TimesheetAttributes;
}

interface INavigateTo {
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
 interface EmployeeData {
  data:{
    attributes: {
      full_name: string;
      phone_number: string | null;
      email: string;
      reset_password_token: string;
      status: string;
      first_name: string;
      last_name: string | null;
      country_code: string | null;
      country: string | null;
      city: string | null;
      street_address: string | null;
      use_email_as_username: boolean;
      resend_email_invitation: boolean;
      online: boolean;
      default_billing_rate: string;
      language: string | null;
      post_code: string | null;
      latitude: number | null;
      longitude: number | null;
      terms: boolean | null;
      local: string | null;
      profile_image: string;
      employee_image:{
        url: string
      }
    }
  }
  errors:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler:Function
  t: (key: string) => string;
  isPopupOpen: boolean;
  cancelBtnText: string;
  isTextFieldVisible: boolean,
  saveBtnText: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  statusEl: null | HTMLElement;
  selectLan: string;
  view: string;
  timeSheetData: Timesheet[];
  getTimesheetLoading: boolean;
  selectedData:null | TimesheetAttributes
  isPopupOpen: boolean;
  cancelBtnText: string;
  isTextFieldVisible: boolean,
  saveBtnText: string;
  isEmployee: boolean;
  tabValue: number ;
  statusValue: string;
  timeSheetStatus:string
  empName:string
  empEmail:string
  empImg:string
  purchaseDate:Date | null;
  selectDate:Date | string;
  editId: string
  addEntriesToggle:boolean,
  selectedEmployee:EmailAccount | null,
  employeeState: EmailAccount[],
  role:string,
  boName:string,
  boLastName:string | null,
  boProfilImage:string ,
  timesheetStat:any

  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TimesheetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTimeSheetApiCallId: string = ""
  deleteTimesheetApiCallId: string = ""
  getEmployeTimeSheetApiCallId:string = ""
  getEmployeeApiCallId:string = ''
  getMyTimeSheetApiCallId: string = ""
  statusEmployeeApiCallId: string = ""
  apiCallIdTimesheetMenue : string = ""
  employeeDataCallApiIde : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      anchorEl: null,
      statusEl: null,
      selectLan:'',
      view: "daily",
      timeSheetData: [],
      selectedData: null,
      getTimesheetLoading: false,
      isPopupOpen: false,
      cancelBtnText: 'Cancel',
      isTextFieldVisible: false,
      saveBtnText: 'Save',
      isEmployee: false,
      tabValue:0,
      statusValue:'pending',
      timeSheetStatus:"Time",
      empName:'',
      empEmail:'',
      empImg:'',
      purchaseDate: new Date(),
      selectDate:new Date(),
      editId:"",
      addEntriesToggle:true,
      selectedEmployee:null,
      employeeState:[],
      role:"false",
      boName:"",
      boLastName:"",
      boProfilImage: "",
      timesheetStat:0
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const getTimeSheetStatus = localStorage.getItem("timeSheetStatus")
    if(getTimeSheetStatus){
      this.setState({timeSheetStatus:getTimeSheetStatus})
    }else{
      this.setState({timeSheetStatus:'Time'})
    }
    setStorageData("timesheetVieww",String(this.state.tabValue))
    const employeeRole = await getStorageData("isEmployee")
    this.setState({role:employeeRole})
    const selectedLanguage = await getStorageData("lang");
    const employee_Id = localStorage.getItem("employeeId");
    this.getAllEmployee();
    if(employee_Id){
      this.getEmployee()
    }
    this.getEmployee()
    this.setState({ selectLan: selectedLanguage})
    const employee = await checkLoggedInUserDashboard();
    this.setState({ isEmployee: employee });

    if(employee){
      this.setState({addEntriesToggle:true})
      this.getMyTimeSheetDataAPI()
    }else{
      this.getTimeSheetDataAPI()
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getTimeSheetApiCallId) {
        this.getTimesheetApiResponse(responseJson)
      }else if (apiRequestCallId ===this.deleteTimesheetApiCallId){
        this.deleteReceiveCall()
        this.handlePopupClose()
      }else if(apiRequestCallId === this.getEmployeTimeSheetApiCallId){
        this.getTimesheetApiResponse(responseJson)        
      }else if(apiRequestCallId === this.getEmployeeApiCallId){
        this.getEmployeeApiResponse(responseJson)        
      }else if(apiRequestCallId === this.getMyTimeSheetApiCallId){
        this.getTimesheetApiResponse(responseJson)
      }else if (apiRequestCallId === this.statusEmployeeApiCallId){
        this.employeeeStatus()
      }else if(apiRequestCallId === this.employeeDataCallApiIde){
        this.handleAllEmployee(responseJson as unknown as EmailAccountProps)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  /* istanbul ignore next */
  deleteReceiveCall = () => {
    if(this.state.role === "false"){
      if(this.state.tabValue === 0){
      this.getTimeSheetDataAPI();
      }else{
        this.getEmployeTimeSheetDataAPI()
      }
    }
    else{
      this.getMyTimeSheetDataAPI()
    }
  }
  /* istanbul ignore next */
  employeeeStatus = () => {
    if(this.state.role === "false"){
      if(this.state.tabValue === 0){
      this.getTimeSheetDataAPI();
      }else{
        this.getEmployeTimeSheetDataAPI()
      }
    }
    else{
      this.getMyTimeSheetDataAPI()
    }
  }
   /* istanbul ignore next */
  getEmployeeApiResponse=(responseJson:EmployeeData)=>{
    if (responseJson && !responseJson.errors) {
      this.setState({
        empName:responseJson.data.attributes.full_name,
        empEmail:responseJson.data.attributes.email,
        empImg:responseJson.data.attributes.employee_image&&responseJson.data.attributes.employee_image.url,
        boLastName:responseJson.data?.attributes?.last_name,
        boName:responseJson.data.attributes.first_name,
      })
      this.setState({boProfilImage:responseJson.data.attributes.profile_image})
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
      }
    }
  }
  handleAllEmployee = (responseJson:EmailAccountProps)=> {
    this.setState({employeeState:responseJson.data})
  }
  handleTabValue = async(value: number) => {
    this.setState({tabValue: value})
    if(value === 0){
      this.setState({addEntriesToggle:true})
      localStorage.setItem("timeSheetStatus","Time")
      this.getTimeSheetDataAPI();
    }else{
      const newDate = new Date(this.state.selectDate);
      this.setState({addEntriesToggle:false})
      localStorage.setItem("timeSheetStatus","Employee")
      this.getEmployeTimeSheetDataAPI(newDate)
    }
  }
  handleSearchTimeSheet = (event: EmailAccount) => {
    this.setState({selectedEmployee: event})
    this.timesheetClick(this.state.selectDate, event?.attributes?.full_name)
    
  }
  handleClick = (event: React.MouseEvent<HTMLElement>  ,item : TimesheetAttributes ) => { 
    this.setState({ anchorEl: event?.currentTarget , selectedData: item});
  };

  handleEdit = () => {
    this.setState({ anchorEl: null });
    setStorageData("editKey",JSON.stringify(this.state.selectedData) )
    this.navigateTo({props: this.props, screenName: "TimeSheetHours"})
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedData: null , isPopupOpen: false});
  };

  handleDeleteButton = () => {
    this.setState({ anchorEl: null, isPopupOpen: true});
  };

   /* istanbul ignore next */
  handleClickNextDate = () => {
    const newDate = new Date(this.state.selectDate);
    newDate.setDate(newDate.getDate() + 1);
    this.setState({selectDate:newDate})
    if(this.state.role === "false"){
    if(this.state.timeSheetStatus === "Time" && this.state.tabValue === 0){
      this.getTimeSheetDataAPI(newDate)
    }else{
      this.timesheetClick(newDate)
    }
  }else {
 this.getMyTimeSheetDataAPI(newDate)
  }
  };
 /* istanbul ignore next */
  handleClickPrevDate = () => {
    const newDate = new Date(this.state.selectDate);
    newDate.setDate(newDate.getDate() - 1);
    this.setState({selectDate:newDate})
    if(this.state.role === "false"){
    if(this.state.timeSheetStatus === "Time" && this.state.tabValue === 0){
      this.getTimeSheetDataAPI(newDate)
    }else{
      this.timesheetClick(newDate)
    }
  }else{
    this.getMyTimeSheetDataAPI(newDate)
  }
  };

  
  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
    this.setState({ isTextFieldVisible: false });
  };

  handlePopupDelete = () => {
    this.deleteTimesheet()
    this.setState({ isPopupOpen: false, cancelBtnText: 'Delete', saveBtnText: 'Save' });
  };

  handleCancelClick = () => {
    this.setState({ isPopupOpen: true ,cancelBtnText: 'Delete', saveBtnText: 'Edit' });
  };
  

  handleView = (value: string) => {
    setStorageData("view", value)
   this.setState({view : value})
   this.setState({ timeSheetStatus: value })
  }

  /* istanbul ignore next */
  getTimeSheetDataAPI = async(dates?:string | Date) => {
    const token =await getStorageData("authToken")
      
    const header = {
      token:token
    };
    this.setState({getTimesheetLoading:true}) 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeSheetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.timeSheetAPIEndPoint}?date=${moment(dates ?? this.state.selectDate)}&view=${this.state.view}`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    const statusView = await getStorageData("timeSheetStatus")
    if(statusView === "Employee"){
      this.setState({tabValue:1,addEntriesToggle:false})
      this.getEmployeTimeSheetDataAPI()
    }
  }

  getTimesheetApiResponse= (responseJson : {data:Timesheet[], error: string}) => {
    if(responseJson && !responseJson.error){
      this.setState({getTimesheetLoading:false ,timeSheetData : responseJson.data})
    }
  }

  navigateTo = ({
    props,
    screenName,
    raiseMessage,
    }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    raiseMessage &&
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
    }
/* istanbul ignore next */
    deleteTimesheet = async() => {
      const token =await getStorageData("authToken")
      const header = {
        "token": token
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.deleteTimesheetApiCallId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.timeSheetAPIEndPoint}/${this.state.selectedData?.id}`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
JObTabStyle = (tabValue: number, webStyle:  {activeTab :React.CSSProperties,tabstyle: React.CSSProperties}) => {
  switch(tabValue) {
    case 0:
      return webStyle.activeTab;
    case 1:
        return webStyle.tabstyle;
    default:
      return webStyle.tabstyle;
  }
}

RequestTabStyle = (tabValue: number, webStyle: {activeTab :React.CSSProperties,tabstyle: React.CSSProperties}) => {
  switch(tabValue) {
    case 0:
      return webStyle.tabstyle;
    case 1:
      return webStyle.activeTab;
    default:
      return webStyle.tabstyle;
  }
}

JObTabIconStyle = (tabValue: number, webStyle: {activeImageIcon: React.CSSProperties, imageIcon: React.CSSProperties}) => {
  switch(tabValue) {
    case 0:
      return webStyle.activeImageIcon;
     case 1:
        return webStyle.imageIcon;
    default:
      return webStyle.imageIcon;
  }
}

RequestTabIconStyle = (tabValue: number, webStyle:{activeImageIcon: React.CSSProperties, imageIcon: React.CSSProperties}) => {
  switch(tabValue) {
    case 0:
      return webStyle.imageIcon;
    case 1:
      return webStyle.activeImageIcon;
    default:
      return webStyle.imageIcon;
  }
}
getEmployee = async () => {
  const token = localStorage.getItem(configJSON.storageToken)
  const employee_Id = localStorage.getItem("employeeId");
  const account_id = await getStorageData("account_id");
  const apiEndPoint = configJSON.getEmpAPIEndPoint
  let urlese;
  if(this.state.role === "false"){
    urlese = `account_block/accounts/${account_id}`;
    }else{
      urlese = `account_block/employees/${employee_Id}`
    }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token": token
  };
  const getEmployeeApiCall = apiCall({
    header: header,
    httpBody: null,
    url: urlese,
    httpMethod: configJSON.apiMethodTypeGet,
  });
  this.getEmployeeApiCallId = getEmployeeApiCall.messageId;
  runEngine.sendMessage(getEmployeeApiCall.id, getEmployeeApiCall);
}
/* istanbul ignore next */
getEmployeTimeSheetDataAPI = async(dates?:string | Date) => {
  const token =await getStorageData("authToken")
  const header = {
    token:token
  };
  this.setState({getTimesheetLoading:true}) 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getEmployeTimeSheetApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_timesheetmanagement/timesheets/employee_timesheets?date=${moment(dates ?? this.state.selectDate)}&view=${this.state.view}`
  ); 
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
handleSatus = (value: string, event: React.MouseEvent<HTMLElement>) => {
this.setState({statusValue: value, statusEl: event?.currentTarget})
}

handleCloseSatus =() => {
  this.setState({statusValue: '', statusEl: null})
}

handleDateChange = (date: string) => {
  this.setState({selectDate:date})
}
getMyTimeSheetDataAPI = async(dates?:string | Date) => {
  const token =await getStorageData("authToken")
    
  const header = {
    token:token
  };
  this.setState({getTimesheetLoading:true}) 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getMyTimeSheetApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getTimeSheetAPIEndPoint}?lang=${this.state.selectLan}&view=${this.state.view}&date=${moment(dates ?? this.state.selectDate)}`
  ); 
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
// istanbul ignore next
getAllEmployee = async () => {
  const token =await getStorageData("authToken")
  const headers = {
    "Content-Type": "application/json",
    "token": token
  };
  const urles = `bx_block_appointment_management/appointments/get_employees`;
       
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.employeeDataCallApiIde = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${urles}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
// istanbul ignore next
timesheetClick = async(dates?:string | Date, search?:string) => {
  const token =await getStorageData("authToken")
  const header = {
    "Content-Type": "application/json",
    "token": token
};

const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
);

this.apiCallIdTimesheetMenue = requestMessage.messageId;

requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.timeSheetEmpEndPoint}/employee_timesheets?date=${moment(dates ?? this.state.selectDate)}&view=weekly&query=${search}`,
);

requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);

requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
);

runEngine.sendMessage(requestMessage.id, requestMessage);
}
/* istanbul ignore next */
handleEmployeeeStatus = async(status:string) => {
  this.setState({ statusEl: null });
  const token =await getStorageData("authToken")
  
  const header = {
    
    token: token,
  };
 const editUrlse = `${configJSON.getEditTimesheetAPiEndPoint}/${this.state.statusValue}/change_status`
 const formData = new FormData();
  
 formData.append("status",status);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.statusEmployeeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      editUrlse);
    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);

}
}

// Customizable Area End