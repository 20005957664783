// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End
// Customizable Area Start
import { apiCall,checkTokenExpired,dateMinusOne } from "../../../components/src/CommonFunction";
import moment from "moment";
interface CreateAppointment{
  title:string,
  employee_id:string,
  start_date:string,
  end_date:string,
  start_time:string,
  end_time:string
  email_team:string,
  description:string,
  all_day:string,
  customer_id:string,
  site_id:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  history?: any
  openToastHandler?:any
  handleOpenNewAppointMentModal:boolean
  handleCloseNewAppointMentModal:Function
  getAppointment:Function
  isOpenNewAppointment:boolean
  viewAppointmentDetails:boolean
  openEditAppointmentModal:boolean
  selectedEventData:any
  appointmentForm:any
  deleteAppointment:Function
  getEmployee:Function
  getSite:Function
  getEmployeeData:any
  getCustomerData:object
  getSiteData:object
  employeeId:string
  isEmployee:boolean
  view:string
  isActive:boolean
  selectLanguage:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  createAppointmentData: object
  updateAppointmentData: object
  loading: boolean
  errorMessage: string
  successMessage: string
  showErrorMessage: boolean
  showSuccessMessage: boolean
  vertical: string,
  horizontal: string,
  isClearable: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NewAppointmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAppointmentApiCallId:string=''
  updateAppointmentApiCallId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state={
      createAppointmentData:[],
      updateAppointmentData:[],
      loading:false,
      errorMessage:'',
      successMessage:'',
      showErrorMessage:false,
      showSuccessMessage:false,
      vertical: 'top',
      horizontal: 'center',
      isClearable:false,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.createAppointmentApiCallId) {
        this.createAppointmentResponse(responseJson)
      }else if(apiRequestCallId === this.updateAppointmentApiCallId) {
        this.updateAppointmentResponse(responseJson)
      }
    }
    // Customizable Area End
  }



  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  createAppointmentResponse(responseJson: { errors: { [x: string]: [0]; }; data: []; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ createAppointmentData: responseJson.data, loading: false })
      this.props.handleCloseNewAppointMentModal()
      this.props.getAppointment()
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        for (let key in responseJson.errors) {
          this.setState({ errorMessage: `${key} ${responseJson.errors[key][0]}`, showErrorMessage: true })
        }
        this.setState({ createAppointmentData: [] })
      }
    }
  }
  testNoc = () => {
    this.setState({ showSuccessMessage: false }, () => this.props.handleCloseNewAppointMentModal())
  }
  updateAppointmentResponse(responseJson: { errors: { [x: string]: [0]; }; data: []; message: string; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ updateAppointmentData: responseJson.data, loading: false })
      this.setState({ successMessage: `${responseJson.message}`, showSuccessMessage: true })
      setTimeout(this.testNoc, 1000)
      this.props.getAppointment()
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        for (let key in responseJson.errors) {
          this.setState({ errorMessage: `${key} ${responseJson.errors[key][0]}`, showErrorMessage: true })
        }
        this.setState({ updateAppointmentData: [] })
      }
    }
  }
  createAppointment = async (values:CreateAppointment) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({loading:true})
    let createAppointmentBody = new FormData();
    createAppointmentBody.append("data[title]", values.title);
    createAppointmentBody.append("data[description]",values.description || '');
    createAppointmentBody.append("data[start_date]", moment(new Date(values.start_date)).format('YYYY-MM-DD'));
    createAppointmentBody.append("data[end_date]", moment(new Date(values.end_date)).format('YYYY-MM-DD'));
    createAppointmentBody.append("data[start_time]", values.start_time);
    createAppointmentBody.append("data[end_time]", values.end_time);
    createAppointmentBody.append("data[customer_id]", values.customer_id);
    createAppointmentBody.append("data[site_id]", values.site_id);
    createAppointmentBody.append("data[employee_ids]", values.employee_id);
    if(values.email_team){
      createAppointmentBody.append("data[email_team]", values.email_team);
    }
    const createAppointmentApiCall = apiCall({
      header: header,
      httpBody: createAppointmentBody,
      url: `${configJSON.appointmentAPiEndPoint}?lang=${this.props.selectLanguage}`,
      httpMethod:configJSON.createAppointmentAPiMethod
    });
    this.createAppointmentApiCallId = createAppointmentApiCall.messageId;
    runEngine.sendMessage(createAppointmentApiCall.id, createAppointmentApiCall);
  }
  updateAppointment = async (values:CreateAppointment) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({loading:true})
    let updateAppointmentBody = new FormData();
    updateAppointmentBody.append("data[title]", values.title);
    updateAppointmentBody.append("data[start_date]", moment(new Date(values.start_date)).format('YYYY-MM-DD'));
    updateAppointmentBody.append("data[end_date]", moment(new Date(values.end_date)).format('YYYY-MM-DD'));
    updateAppointmentBody.append("data[start_time]", values.start_time);
    updateAppointmentBody.append("data[end_time]", values.end_time);
    updateAppointmentBody.append("data[customer_id]", values.customer_id);
    updateAppointmentBody.append("data[site_id]", values.site_id);
    updateAppointmentBody.append("data[employee_ids]", values.employee_id);
    updateAppointmentBody.append("data[all_day]", values.all_day);
    updateAppointmentBody.append("data[email_team]", values.email_team);
    const updateAppointmentApiCall = apiCall({
      header: header,
      httpBody: updateAppointmentBody,
      url: `${configJSON.appointmentAPiEndPoint}/${this.props.selectedEventData.id}?lang=${this.props.selectLanguage}`,
      httpMethod:configJSON.updateAppointmentAPiMethod
    });
    this.updateAppointmentApiCallId = updateAppointmentApiCall.messageId;
    runEngine.sendMessage(updateAppointmentApiCall.id, updateAppointmentApiCall);
  }
  checkValue = (openNewApp: any, value: any) => {
    if (openNewApp) {
      return value
    } else {
      return ''
    }
  }
  startEnd = (view: any, value1: any, value2: any) => {
      if (view) {
        return value1
      } else {
        return value2
      }
  }
  checkObjectIsEmpty(obj:any) {
    if(Object.keys(obj).length===0){
      return true
    }else{
      return false
    }
  }
  employeeInitialValue = () => {
    const employeeID = this.props.getEmployeeData?.find((item: any) =>
      item.id === this.props.employeeId
    )
    let id
    let name 
    let email
    let image
    if (!this.props.isEmployee) {
      if(this.checkObjectIsEmpty(this.props.selectedEventData)){
        id = null
        name = ''
        email = ''
        image = ''
      }else{
        id = this.props.selectedEventData.employee?.map((item:any)=>{
          return this.checkValue(!this.props.isOpenNewAppointment, item.employee_id)
        })
        name = this.props.selectedEventData.employee?.map((item:any)=>{
          return this.checkValue(!this.props.isOpenNewAppointment, item.employee_name)
        })
        email = this.props.selectedEventData.employee?.map((item:any)=>{
          return this.checkValue(!this.props.isOpenNewAppointment, item.employee_email)
        })
        image=this.props.selectedEventData.employee_img?.map((item: any) => {
         return this.checkValue(!this.props.isOpenNewAppointment, item.employee_image)
        })
      }
    } else {
      id = !this.props.isOpenNewAppointment ? this.props.selectedEventData.employee[0].employee_id : employeeID?.id
      name = !this.props.isOpenNewAppointment ? this.props.selectedEventData.employee[0].employee_name : employeeID?.attributes.full_name
      email = !this.props.isOpenNewAppointment ? this.props.selectedEventData.employee[0].employee_email : employeeID?.attributes.email
      image = !this.props.isOpenNewAppointment ? this.props.selectedEventData.employee[0].employee_image || '' : employeeID?.attributes.employee_image || ''
    }

    return {id,name,email,image}
  }
  startDate() {
    const date = moment(this.props.selectedEventData.start_date, 'YYYY-MM-DD')
    return moment.utc(date).toDate()
  }
  endDate() {
    const date = moment(this.props.selectedEventData.end_date, 'YYYY-MM-DD')
    return moment.utc(date).toDate()
  }
  formInitialValues = () => ({
    start_time : !this.props.isOpenNewAppointment ? this.props.selectedEventData.start_time : moment(new Date( this.props.appointmentForm.start)).format("HH:mm A").toString(),
    end_time : !this.props.isOpenNewAppointment ? this.props.selectedEventData.end_time : moment(new Date( this.props.appointmentForm.end)).format("HH:mm A").toString(),
    title : this.checkValue(!this.props.isOpenNewAppointment, this.props.selectedEventData.title),
    description : this.checkValue(!this.props.isOpenNewAppointment, this.props.selectedEventData.description),
    email_team : this.checkValue(!this.props.isOpenNewAppointment, this.props.selectedEventData.email_team),
    all_day : this.checkValue(!this.props.isOpenNewAppointment, this.props.selectedEventData.all_day),
    start_date: !this.props.isOpenNewAppointment ? this.startDate() : this.props.appointmentForm.start,
    end_date: !this.props.isOpenNewAppointment ? this.endDate() : 
    this.startEnd(this.props.view==='month',dateMinusOne(this.props.appointmentForm.end),this.props.appointmentForm.end),
    employee_id:this.employeeInitialValue().id||'',
    employee_name:this.employeeInitialValue().name,
    employee_email:this.employeeInitialValue().email,
    employee_image:this.employeeInitialValue().image,
    customer_id:this.checkValue(!this.props.isOpenNewAppointment, this.props.selectedEventData.customer_id),
    site_id:this.checkValue(!this.props.isOpenNewAppointment, this.props.selectedEventData.site_id)
  })
}
// Customizable Area End
