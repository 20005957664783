// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const homeScreen = require("../assets/home_screen.png");
export const bgGradientRectangle = require("../assets/bg_gradient_rectangle.png");
export const imgSearch = require("../assets/ic_search.png");
export const imgDots = require("../assets/ic_dots.png");
export const imgDotsDark = require("../assets/img_dots_dark.png");
export const imgDefault = require("../assets/img_default.png");
export const imgChat = require("../assets/img_chat.png");
export const imgCal = require("../assets/img_cal.png");
export const imgInfo = require("../assets/img_info.png");
export const imgListBg = require("../assets/img_list_bg.png");
export const imgMsg = require("../assets/img_msg.png");
export const imgPause = require("../assets/img_pause.png");
export const userPhoto = require("../assets/image_profile.png");
export const imgApproved = require("../assets/image_approved.png");
export const imgRejected = require("../assets/image_rejected.png");
export const imgPending = require("../assets/image_pending.png");
export const imgbtnBack = require("../assets/imgbtn_Back.png");
export const imgbtnDropdown = require("../assets/imagebutton_dropdown.png");
export const invoicesIcon = require("../../dashboard/assets/timesheetIcon.png");
export const customersIcon = require("../../dashboard/assets/customersIcon.png");
export const ThreedotsIcon = require("../assets/dots_three_vertical_icon.png");
// Customizable Area End
