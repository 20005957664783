import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {File} from "./Customers.web"
import { navigateTo } from '../../../components/src/CommonFunction'
export interface SiteProp {
  id: number | null;
  site_name: string;
  email: string | null;
  phone_number: string | null;
  site_address: string;
  state: string;
  city: string;
  zip_code: string;
  customer_id: number;
  longitude: string;
  latitude: string;
  created_at: string;
  updated_at: string;
  country: string | null;
}
export interface ProductItem {
  id: string;
  type: string;
  attributes: {
    product_id: number | null;
    service_id: number | null;
    quantity: number;
    unit_price: string;
    currency: string | null;
    total: string;
    description: string;
    job_id: string | null;
    quote_id: number;
    visit_id: string | null;
    product_name: string;
    files: File[] | null;
  };
}

interface EmployeeImage {
  id: number;
  name: string;
  email: string;
}

export interface CombinedProps {
  id: string;
  type: string;
  attributes: {
    // Common attributes
    pdf_url: string | null;
    products: {
      data: ProductItem[];
    };
    employees: EmployeeImage[];
    custmoer_name: string; 
    customer_name:string;
    custmer_name:string;
    created_at: string;
    updated_at: string;
    // Job attributes
    job_title: string;
    customer_id: number;
    account_id: number;
    employee_id: number | null;
    request_id: number | null |  string;
    site_id: number;
    status: string;
    scheduling: string;
    frequency: string; 
    duration: string; 
    summary: string; 
    every: number;
    start_date: string;
    end_date: string;
    internal_notes: string;

    start_time: string;
    end_time: string;
    job_id: string;

    // Quote attributes
    quote_title: string;
    service_detail: string;
    quote_date: string;
    due_date: string;
    sub_total: number;
    total: number;
    tax: number;
    tax_name: string;
    currency: string | null;
    discount: string;
    converted_to_job: boolean;
    quote_id: string;
    // Invoice attributes
    title: string;
    issue_date: string;
    payment_due: string;
    notes: string;

    // Request attributes
    on_site: boolean;
    appointment_date: string;
    all_day: boolean;
    other_source: string,
    source: string;
    request_title: string;
    appointment_time: string;
    converted_to_quote: boolean;
   
  };
}


import { performAuthorizationCheck, checkLoggedInUser,
   getLang, generateStatusMapping, handleTooltipOpenProducts, handleTooltipClose } from "./utility.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customersQuotesData: any;
  isErrorFormData: any;
  toaster: any;
  active: any;
  getCustomersRequestLoading: boolean;
  deleteCustomerRequestLoading: boolean;
  sortingOrder: string;
  lastClickedProperty: any;
  sortingProperty: any;
  selectedSite: string;
  searchQuery: string;
  timer: any;
  lang:string;
  siteDailog:boolean
  siteDetails:SiteProp
  customerSitesData:SiteProp[],
  statusData:any
  statusOrder:string[]
  anchorEl:Element | null,
  anchorId:string;
  listData:ProductItem[] | [],
  popoverHeading:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomersRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomersRequestApiCallId: string = "";
  searchCustomerRequestApiCallId: string = "";
  deleteCustomerRequestApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      statusOrder:[],
      statusData:{},
      customerSitesData: [],
      siteDetails: {
        id: null,
        site_name: "",
        email: "",
        phone_number: "",
        site_address: "",
        state: "",
        city: "",
        zip_code: "",
        customer_id: 2,
        longitude: "",
        latitude: "",
        created_at: "",
        updated_at: "",
        country: "",
      },
      getCustomersRequestLoading: false,
      isErrorFormData: {},
      toaster: false,
      active: 0,
      customersQuotesData: [],
      lastClickedProperty: null,
      sortingProperty: "id",
      sortingOrder: "desc",
      selectedSite: "",
      deleteCustomerRequestLoading: false,
      searchQuery: "",
      timer: "",
      lang:"en",
      siteDailog:false,
      popoverHeading: "",
      listData: [],
      anchorEl: null,
      anchorId: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const lang = await getLang();
    this.setState({ lang });
    this.setState({ getCustomersRequestLoading: true });
    const pathname = this.props.location.pathname;
    this.setProp(pathname);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleGetRequestDataCallback(
      apiRequestCallId,
      responseJson,
      errorResponse,
      this.getCustomersRequestApiCallId
    );
    this.handleSearchCustomerRequest(message);
    this.handleDeleteCustomerRequestData(message);

    // Customizable Area End
  }

  // Customizable Area Start
  setProp = (pathname: any) => {
    this.getCustomersRequest("REQUEST")
  };

  handleGetRequestDataCallback = async (
    apiRequestCallId: any,
    responseJson: any,
    errorResponse: any,
    getCustomersRequestApiCallId: any
  ) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === getCustomersRequestApiCallId) {
        this.handleGetAndSearchResponse(
          responseJson,
          errorResponse,
          this.props.location.pathname
        );
      }
    }
  };
  handleGetAndSearchResponse = async (
    responseJson: any,
    errorResponse: any,
    pathname: any
  ) => {
    if (!responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        this.setState({ getCustomersRequestLoading: false });
        return this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
      }
      this.setState({ getCustomersRequestLoading: false });
      this.setStateAccordingly(pathname, responseJson);
    } else {
      //Check Error Response
      this.setState({ getCustomersRequestLoading: false });
      await this.handleReqTokenExpireError(responseJson);
    }
  };

  setStateAccordingly = (pathname: any, responseJson: any) => {
    const setStatusData = (statusOrder: string[], data: any) => {
        this.setState({
            statusOrder,
            statusData: data
        });
    };

    if (this.state.siteDailog) {
        this.setState({ customerSitesData: responseJson.data });
        return;
    }

    let statusOrder;
    let data;

    if (pathname === "/CustomerRequests") {
        statusOrder = this.state.lang === "en" ?
            ["pending", "quoted", "converted_to_job", "overdue", "cancelled"] :
            ["قيد الانتظار", 'مقتبس', 'تحويل_إلى_وظيفة', 'تأخرت', 'ألغيت'];

        data = generateStatusMapping(responseJson.data, statusOrder);
    } else if (pathname === "/CustomerJobs") {
        statusOrder = this.state.lang === "en" ?
            ["upcoming", "in_progress", "completed", "to_be_invoiced", "late", "cancelled"] :
            ['القادمة', 'قيد التنفيذ', 'مكتمل', 'مفوترة', 'متأخر', 'ألغيت'];

        data = generateStatusMapping(responseJson.data, statusOrder);
    } else {
        statusOrder = this.state.lang === "en" ?
            ["pending", "sent", "converted", "overdue", "cancelled"] :
            ['قيد الانتظار', 'المرسلة', 'تحويل', 'متأخر', 'ملغى'];
        data = generateStatusMapping(responseJson.data, statusOrder);
    }
    setStatusData(statusOrder, data);
};


  handleReqTokenExpireError = async (responseJson: any) => {
    if (
      responseJson.errors[0]?.token == "Token has Expired" ||
      responseJson.errors[0]?.token == "Invalid token"
    ) {
      this.props?.openToastHandler(responseJson?.errors[0].token, "error");
      this.reqtokenExpired();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  reqtokenExpired = async () => {
    const isEmployee = checkLoggedInUser();
    this.tokenReqExpiredCallback(isEmployee);
  };

  tokenReqExpiredCallback = (isEmployee: any) => {
    const redirectUrl = isEmployee ? "/Employee/Login" : "/Login";
    setTimeout(this.tokenReqExpiredTimeCallback.bind(this, redirectUrl), 1000);
  };

  tokenReqExpiredTimeCallback = (url: any) => {
    localStorage.clear();
    this.props.history.push(url);
  };
  handleDeleteCustomerRequestData = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteCustomerRequestApiCallId) {
        if (!responseJson.errors) {
          this.setState({ deleteCustomerRequestLoading: false });
          this.setState({ toaster: true });
          setTimeout(this.closeToasterRequest, 10000);
          this.getCustomersRequest("REQUEST");
        } else {
          //Check Error Response
          this.setState({ deleteCustomerRequestLoading: false });
          this.handleReqTokenExpireError(responseJson);
        }
        this.setState({ deleteCustomerRequestLoading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };
  closeToasterRequest = () => this.setState({ toaster: false });

  getReqType = (type: string) => type === "REQUEST" ? "requests" : "sites";
  getJobType = (type: string) => type === "sites" ? "sites" : "jobs";
  getQuoteType = (type: string) => type === "sites" ? "sites" : "quotes";

  getReqUrl = (pathname: string, customer_id: any, isSearch: boolean,type:string) => {
    let reqType =
      pathname === "/CustomerRequests"
        ? this.getReqType(type)
        : this.getReqUrlCall(pathname,type);
    let url =
      this.state.sortingOrder === ""
        ? `account_block/customer_data?type=${reqType}&customer_id=${customer_id}&lang=${this.state.lang}`
        : `account_block/customer_data?type=${reqType}&customer_id=${customer_id}&field=${this.state.sortingProperty}&order=${this.state.sortingOrder}&query=${this.state.searchQuery}&lang=${this.state.lang}`;
    return url;
  };

  getReqUrlCall = (pathname: string, type: string) => {
    return pathname === "/CustomerJobs" ? this.getJobType(type) :
      this.getQuoteType(type);
  };

  getCustomersRequest = (type:string) => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const customer_id = localStorage.getItem("customer_id");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomersRequestApiCallId = requestMessage.messageId;
    let url = this.getReqUrl(this.props.location.pathname, customer_id, false,type);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCustomerRequestsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteCustomerRequest = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCustomerRequestApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/requests/${this.state.selectedSite}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleSearchCustomerRequest = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleSearchRequestDataCallback(
      apiRequestCallId,
      responseJson,
      errorResponse,
      this.searchCustomerRequestApiCallId
    );
  };

  handleSearchRequestDataCallback = async (
    apiRequestCallId: any,
    responseJson: any,
    errorResponse: any,
    searchCustomerRequestApiCallId: any
  ) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === searchCustomerRequestApiCallId) {
        this.handleGetAndSearchResponse(
          responseJson,
          errorResponse,
          this.props.location.pathname
        );
      }
    }
  };
  
  searchCustomerRequest = (type:string) => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const customer_id = localStorage.getItem("customer_id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchCustomerRequestApiCallId = requestMessage.messageId;
    let url = this.getReqUrl(this.props.location.pathname, customer_id, true,type);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCustomerSiteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleChangeSearchCustomerRequest = (type:string,e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    clearTimeout(this.state.timer);
    const timer = setTimeout(this.changeTime.bind(this,type), 500);
    this.setState({ searchQuery: inputValue, timer });
  };

  changeTime = (type:string) => {
    this.executeInputChange(type);
  };

  executeInputChange(type:string) {
    // Perform the input change logic here, e.g., call an API with the new input value.
    this.setState({ getCustomersRequestLoading: true });
    this.searchCustomerRequest(type);
  }

  handleCloseToasterDelete = () => {
    this.setState({ toaster: false });
  };

  handleCreateCustomerRequest = async () => {
    const isAuthorized = await performAuthorizationCheck("customers_data");
    this.handleCreateCustomerRequestCall(isAuthorized);
  };
  handleCreateCustomerRequestCall = async (isAuthorized: boolean) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      if (this.state.siteDetails.id) {
        this.props.history.push("/request/create",
          {
            id: localStorage.getItem("customer_id"),
            reDirectionFrom: 'customer'
          });
      } else {
        this.setState({ siteDailog: true }, this.getCustomersRequest.bind(this, "sites"));
      }


    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };

  handleSelectedSite = async (id: any) => {
    const isAuthorized = await performAuthorizationCheck("customers_data");
    this.handleSelectedSiteCall(isAuthorized, id);
  };

  handleSelectedSiteCall = (isAuthorized: any, id: any) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      if (this.state.selectedSite !== "") {
        return this.setState({ selectedSite: "" });
      }
      this.setState({ selectedSite: id });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };

  handleDelete = async () => {
    const isAuthorized = await checkLoggedInUser();
    this.handleDeleteCallback(isAuthorized);
  };

  handleDeleteCallback = async (isAuthorized: any) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      this.deleteCustomerRequest();
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  sortCallback = () => {
    this.setState({ getCustomersRequestLoading: true });
    this.getCustomersRequest("REQUEST");
  };
  handleViewData = (rowItem: { id: string; type: string }) => {
    localStorage.setItem("customerTabId", rowItem.id)
    if (rowItem.type === 'request') {
      navigateTo({ props: this.props, screenName: "RequestEdit" })
    }
    else if (rowItem.type === 'quote') {
      navigateTo({ props: this.props, screenName: "QuoteCreate" })
    }
    else if (rowItem.type === 'job') {
      navigateTo({ props: this.props, screenName: "JobEdit" })
    }
  }
  sortByProperty = (property: any) => {
    if (
      !(
        property === "team" ||
        property === "product_name" ||
        property === "service_name" ||
        property === "product_quantity"
      )
    ) {
      this.setState(
        {
          sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
          sortingProperty: property,
        },
        this.sortCallback
      );
    }
  };

  handleNavigate = async (url: any,customer:string|number) => {
    const isAuthorized = await performAuthorizationCheck("customers_data");
    this.handleCreateCall(isAuthorized, url,customer);
  };

  handleCreateCall = (isAuthorized: any, url: any,customer:string|number) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      if (this.state.siteDetails.id) {
        localStorage.removeItem("quote_id");        
        this.props.history.push(url,{id:customer,reDirectionFrom:'customer'});
      }else {
        this.setState({ siteDailog: true }, this.getCustomersRequest.bind(this, "sites"));
      }
      
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  handleReqCloseModal = ()=>{
    this.setState({
      siteDailog:false
    })
  }

  selectedReqCustomer = (data: SiteProp) => {
    this.setState({
        siteDetails: data,
        siteDailog: false,
    })
    localStorage.setItem("site_id",JSON.stringify(data.id)); localStorage.setItem("createFromCustomer",'true')
    if(this.props.location.pathname==='/CustomerJobs'){this.handleNavigate("/Job/create",localStorage.getItem("customer_id")||"")
    }else if(this.props.location.pathname==='/CustomerQuotes'){this.handleNavigate("/Quote/create",localStorage.getItem('customer_id')||"")
    }else if(this.props.location.pathname==='/CustomerRequests'){this.handleCreateCustomerRequest()}
  }

  handleNavigation = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToSitePage = () => {
    this.handleNavigation("CustomerSites")
  }

  handleTooltipOpen = (item: any, product_id: string, popoverHead: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { anchorEl, anchorId, listData, popoverHeading } = handleTooltipOpenProducts(item, product_id, popoverHead, event)
    this.setState({
      anchorEl, anchorId, listData, popoverHeading
    })
  }
  handleTooltipClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { anchorEl, anchorId, listData, popoverHeading } = handleTooltipClose();
    this.setState({
      anchorEl, anchorId, listData, popoverHeading
    })
  }
  // Customizable Area End
}
