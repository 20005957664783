import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { searchIcon, dropDownSelectIcon, deleteIcon } from "./assets";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web"
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import SiteModal from '../../../components/src/CommonSiteModal.web';
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import {CommonListPage} from "../../../components/src/CommonListPage"
// Customizable Area End

import CustomerRequestsController, {
  Props,
} from "./CustomerRequestsController.web";

// Customizable Area Start

// Customizable Area End

export class CustomerRequests extends CustomerRequestsController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  sortRequestsData = (rowItem: any) => this.sortByProperty(rowItem.key);
  handleSelectRequest = (item: any) => this.handleSelectedSite(item.id);

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,t }: any = this.props;
    const requestMockTableRow = [
      { id: 1, key: "request_title", title: t(`customers.requestMockTableRow.${0}.title`) },
      { id: 2, key: "customer_name", title: t(`customers.requestMockTableRow.${1}.title`) },
      { id: 3, key: "source", title: t(`customers.requestMockTableRow.${2}.title`) },
      { id: 4, key: "appointment_date", title: t(`customers.requestMockTableRow.${3}.title`) },
    ];
    return (
      <>
        <SiteModal
          classes={classes}
          siteInformationData={this.state.customerSitesData}
          siteModal={this.state.siteDailog}
          handleSiteCloseModal={this.handleReqCloseModal}
          handleChangeSearchCustomerSite={this.handleChangeSearchCustomerRequest}
          t={t}          
          siteDetails={this.state.siteDetails}
          selectedSiteCustomer={this.selectedReqCustomer}
          createSite={this.goToSitePage}
          />
        <Box>
            <Box className={classes.SitessearchContainer}>
              <Box>
                <img
                  style={{...webStyle.RequestsearchIcon, marginTop:"5px"}}
                  src={searchIcon}
                  alt=""
                />
              </Box>
              <InputBase
                data-test-id="handleChangeSearchCustomer"
                style={webStyle.RequestinputBase}
                className={classes.RequestinputBase}
                placeholder={t("customers.searchRequest")}
                inputProps={{ "aria-label": "search" }}
                value={this.state.searchQuery}
                onChange={this.handleChangeSearchCustomerRequest.bind(this,"REQUEST")}
              />
            </Box>
          <CommonListPage
            webStyle={webStyle}
            classes={classes}
            requestMockTableRow={requestMockTableRow}
            sortingProperty={this.state.sortingProperty}
            sortingOrder={this.state.sortingOrder}
            statusOrder={this.state.statusOrder}
            statusData={this.state.statusData}
            sortRequestsData={this.sortRequestsData}
            handleViewData={this.handleViewData}
            t={t}
            dropDownSelectIcon={dropDownSelectIcon}
            type="REQUESTS"
            lang = {this.state.lang}
          />
          <Box style={webStyle.RequestbuttonsContainer}>
            <CreateQuote>
              <Typography
                style={webStyle.RequestbuttonTextCreateNewRequest}
                onClick={this.handleCreateCustomerRequest}
                variant="subtitle2"
              >
                {t("customers.createRequest")}
              </Typography>
            </CreateQuote>
          </Box>
        </Box>
        {this.state.getCustomersRequestLoading ? (
          <Backdrop
            style={{
              zIndex: 1000 + 1,
              color: "#05037a",
              justifyContent: "center",
              alignItems: "center",
            }}
            open={this.state.getCustomersRequestLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
 
});

const webStyle = {
  RequestsearchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  RequestinputBase: {
    width: "100%",
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: 400,
    lineHeight: "16px",
    color:CONSTANTS.color.grey
  },
  RequestcustomersRequestsContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "300px",
    overflow: 'scroll',
  },
  RequesttableCellHead: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    width:"20%",
  },
  RequesttableCellBody: {
    color: "black",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 500,
    borderBottom: "none",
    paddingTop:0,
    width:"20%",
    padding:"0.8rem"
  },
  RequesttableHeadSpan: {
    cursor: "pointer",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  RequesttableHeadText: {
    fontSize: "18px",
    whiteSpace:"nowrap"
  },
  RequestnoRequestContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
  },
  RequestnoRequestText: {
    color: "#34313a",
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  RequestnoRequestSubText: {
    color: "#999999",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  RequestbuttonsContainer: {
    padding: "4rem 0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  RequestbuttonTextCreateNewRequest: {
    color: "#ffffff",

    fontWeight: 400,
    textAlign: "center" as "center",
  },
  RequestRequestdropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
  },
  RequestdropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)",
  },
  RequestdeleteIconContainer: {
    maxWidth: "79px",
    height: "61px",
    width:"100%",
    backgroundColor: "rgb(253 188 188)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    
  },
  RequestdeleteIcon: {
    width: "24px",
    height: "24px",
  },
  Requesttoaster: {
    position: "relative" as "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft: "2rem",
    width: "100%",
    maxWidth: "689px",
    minHeight: "80px",
    borderRadius: "8px",
    backgroundColor: "#1c18af",
    marginTop: "5px",
  },
  RequestcloseIcon: {
    position: "absolute" as "absolute",
    left: "96%",
    color: " #fff",
    top: "29%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  RequesttoasterText: {
    color: "#ffffff",
    fontWeight: 400,
  },
};

const customerRequestStyles :any = withTranslation()(withStyles(styles)(CustomerRequests));
const customerRequestRouter  = withRouter(customerRequestStyles);
const customerRequestSnackBar = withSnackbar(customerRequestRouter);
export default withPermission(customerRequestSnackBar)
// Customizable Area End
