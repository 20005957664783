// Customizable Area Start
import React from "react";
import { add_icon, right_arrow } from "./assets";

import JobListingController, {
  configJSON,
  JobListDataType
} from "./JobListingController";

class JobListing extends JobListingController {
  static JobListing: JobListing;
  render() {
    return (
      <div>
        <div
          className="header"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
            backgroundColor: "#0D2666"
          }}
        >
          <div
            className="back_button"
            style={{
              margin: "0px",
              backgroundColor: "#fff",
              borderRadius: "100px",
              width: "30px",
              height: "30px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <img
              src={right_arrow}
              style={{
                width: "40%",
                height: "40%",
                transform: "rotate(180deg)"
              }}
            />
          </div>
          <div>
            <h2 className="title" style={{ margin: "0px", color: "#fff" }}>
              {configJSON.compnaypagetitle}
            </h2>
          </div>
          <div
            className="add_company_button"
            style={{
              margin: "0px",
              width: "30px",
              height: "30px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "100px"
            }}
          >
            <img src={add_icon} style={{ width: "60%", height: "60%" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            fontFamily: "Roboto-Medium",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "60px"
          }}
        >
          <p style={{ marginBottom: "30px" }}>{configJSON.labelTitleText}</p>
          <div style={webStyle.inputDiv}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "300px"
              }}
            >
              <input
                data-test-id="txtInput"
                type={"text"}
                placeholder="Serch here ...."
                value={this.state.searchText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.handler(event.target?.value?.trim());
                }}
                style={{
                  padding: "6px 10px",
                  border: "solid 1px rgb(222 222 222)"
                }}
              />
            </div>
          </div>
        </div>
        {this.state.jobArray?.length == 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px"
            }}
          >
            <p className="nothing_found">{"No data found"}</p>
          </div>
        ) : (
          <div className="my_company_list" style={{ margin: "15px" }}>
            {this.state.jobArray.map((item: JobListDataType) => {
              return (
                <div
                  data-test-id="job_list"
                  key={`item-${item.id}`}
                  style={{
                    display: "flex",
                    height: "60px",
                    marginTop: "20px",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{
                      height: "60px",
                      width: "70px",
                      overflow: "hidden"
                    }}
                  >
                    {item.attributes?.image_url && (
                      <img
                        src={item.attributes?.image_url}
                        style={{
                          height: "100%",
                          width: "100%",
                          borderWidth: "1px",
                          borderColor: "red",
                          borderRadius: "5px"
                        }}
                        alt={"company profile picture"}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      height: "100%",
                      width: "95%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "10px"
                    }}
                  >
                    <h3 style={{ margin: "0px" }}>
                      {item.attributes.company_name}
                    </h3>
                    <p style={{ margin: "0px" }}>
                      {item.attributes.followers_count} followers
                    </p>
                  </div>
                  <div
                    className={`goto_company_profile${item.id}`}
                    style={{
                      margin: "0px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "100px",
                      width: "30px",
                      height: "30px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex"
                    }}
                  >
                    <img
                      src={right_arrow}
                      style={{ width: "40%", height: "40%" }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default JobListing;

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "60px"
  },
  wrapperStyle: {
    maxWidth: "1000px",
    margin: "0 auto",
    marginTop: "30px",
    marginBottom: "30px"
  },
  listingStyle: {
    padding: "25px 20px",
    border: "solid 1px #dfdddd",
    width: "100%",
    borderRadius: "5px",
    marginBottom: "20px"
  },
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "300px"
  },
  buttonDivStyle: {
    border: "none"
  },
  buttonStyle: {
    color: "#fff",
    padding: "6px 15px",
    border: "solid 1px rgb(33 150 243)",
    background: "rgb(33 150 243)",
    cursor: "pointer"
  },
  p1: {
    fontSize: "22px",
    marginTop: 0,
    fontWeight: "600",
    marginBottom: "10px"
  },
  p2: {
    fontSize: "16px",
    fontWeight: "400"
  },
  inputDiv: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    marginTop: "20px"
  }
};
// Customizable Area End
