import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@material-ui/core";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SortIcon from "@material-ui/icons/Sort";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FiberNewIcon from "@material-ui/icons/FiberNew";

// Customizable Area End
import SortingController, {Props, configJSON} from "./SortingController";
import {Catalogue} from "./model/Catalogue";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Sorting extends SortingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWebList(item: Catalogue) {
    let value = item.attributes;

    return (
      <Box
        data-test-id="webListElement"
        key={`web-item-${item.id}`}
        sx={webStyle.productBox}
      >
        <Box sx={webStyle.ImgContainer}>
          <img style={webStyle.productImg} src={value.images?.[0].url} />
        </Box>
        <Box sx={webStyle.detailContent}>
          <Typography variant="h6">{value.name}</Typography>
          <Typography variant="h6">{value.price}</Typography>
          <Typography variant="h6">{value.average_rating}</Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        {/* Customizable Area Start */}
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid #ccc",
            }}
          >
            <IconButton
              data-test-id="sortModalOpen"
              aria-label="Sort"
              onClick={() => this.modalVisible()}
            >
              <SortIcon />
            </IconButton>
            <Typography variant="h6" component="h2">
              {configJSON.sortBtnLabel}
            </Typography>
          </Box>
          <Box sx={webStyle.productContainer}>
            {this.state.data &&
              this.state.data.map((item: any) => this.getWebList(item))}
          </Box>
        </Container>
        <Modal
          open={this.state.isVisible}
          onClose={this.modalVisible}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={webStyle.modalStyle}>
            <IconButton
              data-test-id="sortModalClose"
              aria-label="Close"
              style={{ position: "absolute", top: "5px", right: "0px" }}
              onClick={() => this.modalVisible()}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {configJSON.sortByBtnLabel}
            </Typography> 
            <List>
              <ListItem>
                <ListItemIcon>
                  <ArrowDownwardIcon />
                </ListItemIcon>
                <ListItemText
                  data-test-id="sortPriceAsc"
                  primary={configJSON.priceLowToHightBtnLabel}
                  secondary={null}
                  onClick={() => this.sortPriceAsc()}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowUpwardIcon />
                </ListItemIcon>
                <ListItemText
                  data-test-id="sortPriceDesc"
                  primary={configJSON.priceHightToLowBtnLabel}
                  secondary={null}
                  onClick={() => this.sortPriceDesc()}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberNewIcon />
                </ListItemIcon>
                <ListItemText
                  data-test-id="sortNewest"
                  primary={configJSON.newestProductsBtnLabel}
                  secondary={null}
                  onClick={() => this.sortNewest()}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AutorenewIcon />
                </ListItemIcon>
                <ListItemText
                  data-test-id="sortPopularity"
                  primary={configJSON.popularityBtnLabel}
                  secondary={null}
                  onClick={() => this.sortPopularity()}
                />
              </ListItem>
            </List>
          </Box>
        </Modal>
        {/* Customizable Area End */}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 450,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
