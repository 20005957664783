import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Hidden,
  Checkbox,
  InputBase,
  Typography,
  ClickAwayListener,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import {
  searchIcon,
  emptyIcon,
  plusIcon,
  radioButton,
  radioButtonOff,
  dropDownArrow,
} from "./assets";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import CustomDatePicker from "../../../components/src/DatePicker";
import { CONSTANTS } from "../../../components/src/theme";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import ReportsController, {
  Props,
} from "./ReportsController.web";

// Customizable Area Start
const StyledCheckbox = withStyles({
  root: {
    height: "24px",
    width: "24px",
    color: "rgba(0,0,0,0.1)",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#1c18af",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
      strokeWidth: '2px',
    }
  }

})(Checkbox);

export const StyledButton = withStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: "216px",
    minHeight: "64px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.darkBlue,
    textTransform: "none" as const,
    float: "right" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
    "& .MuiButton-label": {
      display: "flex",
      gap: "1rem"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
      maxWidth: "100% !important",
      minHeight: "48px !important",
    }
  }
}))(Button);

export const StyledBox = withStyles((theme) => ({
  root: {
    maxWidth: "97%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
    }
  }
}))(Box);

export const StyledBoxInput = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "275px !important",
    }
  }
}))(Box);

export const StyledBoxSearch = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column !important",
    }
  }
}))(Box);

type EmployeeData = {
  id: string,
  icon: string,
  name: string,
  email: string,
}
type CustomerData = {
  id: string,
  icon: string,
  name: string,
  email: string,
}
// Customizable Area End

export class ReportsData extends ReportsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  customerLength = () => {
    const { selectedCustomer, customerLength } = this.state;

    return (
      selectedCustomer.length > (customerLength ? 3 : 2) && (
        <Typography style={webStyle.employeelabelStyle}>
          +{selectedCustomer.length - (customerLength ? 3 : 2)}
        </Typography>
      )
    );
  }

  customerSearche() {

    return (
      this.state.customerModal && (

        <ClickAwayListener data-test-id="handleClickcustomerOutSide" onClickAway={this.handleClickOutCustomerSide}>
          <StyledBox style={webStyle.employeeDetailsOuterContainer}>
            <Box style={webStyle.employeeDetailsBox}>

              {Array.isArray(this.state.cutomerData) && this.state.cutomerData.map((item: CustomerData) => (


                <Box key={item.id} style={this.state.languageSelected === "ar" ? { ...webStyle.checkboxContainer } : webStyle.checkboxContainer}>
                  <StyledCheckbox
                    data-test-id="getCustomerList"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={this.state.selectedCustomer.map((item: CustomerData) => item.id).includes(item.id)}
                    onChange={(event) => this.handleCheckboxCustomer(event, item)}
                  />
                  <Box
                    data-test-id="handleSelectCustomer"
                    style={webStyle.employeeDetailsContainer}
                  >
                    <img style={webStyle.employeeIcon} src={item.icon} alt="icon" />
                    <Box style={webStyle.assigneeDetails}>
                      <Typography style={webStyle.taskHeading}>
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </StyledBox>
        </ClickAwayListener>
      )
    );
  }

  createCustomer() {
    const { enable } = this.state;
    return (
      <Box
        data-test-id="handleRadioChange"
        style={
          enable === false ?
            webStyle.createCustomRadioButtonContainer :
            { ...webStyle.createCustomRadioButtonContainer, ...webStyle.radioButtonOn }
        }
        onClick={this.handleRadioChange}
      >
        <img
          style={webStyle.createCustomRadioButton}
          src={enable === false ? radioButtonOff : radioButton}
          alt=""
        />
      </Box>
    )
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { openPopup, enable } = this.state;
    const { fromDate, toDate } = this.state.requestDate;
    window.addEventListener('resize', this.handleEmployeeLengthMobile);
    window.addEventListener('resize', this.handleCustomerLengthMobile);

    const employeeslength3 = this.state.selectedEmployees.slice(0, 3);
    const employeeslength2 = this.state.selectedEmployees.slice(0, 2);
    const data = this.state.employeesLength ? employeeslength3 : employeeslength2;
    const customerlength3 = this.state.selectedCustomer.slice(0, 3);
    const customerlength2 = this.state.selectedCustomer.slice(0, 2);
    const dataCustomer = this.state.customerLength ? customerlength3 : customerlength2;
    const { t } = this.props;

    return (
      <>
        <Box style={webStyle.boxInnerContainer} className={classes.boxInnerContainer}>
          <Grid container spacing={3} style={webStyle.gridContainer}>
            <Grid item lg={5} md={5} xs={12}>
              <Typography style={webStyle.bodyText} gutterBottom color="textPrimary">
                {t('reports.reportFrom')}
              </Typography>
              <CustomDatePicker
                data-test-id="handleDateChange"
                selectedDate={fromDate}
                dateChange={this.handleFromDateChange}
              />
            </Grid>
            <Grid item lg={5} md={5} xs={12}>
              <Typography style={webStyle.bodyText} gutterBottom color="textPrimary">
                {t('reports.reportTo')}
              </Typography>
              <CustomDatePicker
                data-test-id="handleDateChange"
                selectedDate={toDate}
                dateChange={this.handleToDateChange}
              />
            </Grid>
          </Grid>
          <Typography style={webStyle.customerText} gutterBottom color="textPrimary">
            {t('reports.customer')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={10} md={10} xs={12}>
              <StyledBox
                data-test-id="handleClickOpenCustomerPopup"
                style={webStyle.searchContainer}
                onClick={this.handleClickOpenCustomerPopup}
              >
                <StyledBoxInput style={webStyle.inputBoxContainer}>
                  <img style={webStyle.searchIconEmp} src={searchIcon} alt="serchIcon" />
                  {dataCustomer.map((customer: CustomerData) => (
                    <Box style={webStyle.employeeContainer} key={customer.id}>
                      <Typography style={webStyle.employeelabelStyle}>
                        {customer.name}
                      </Typography>
                      <CloseIcon
                        data-test-id="handleRemoveCustomer"
                        style={webStyle.removeButton}
                        onClick={() => this.handleRemoveCustomer(customer.id)}
                      />
                    </Box>
                  ))}
                  {this.customerLength()}

                </StyledBoxInput>
                <InputBase
                  data-test-id="handleRemoveCustomer"
                  style={{ ...webStyle.inputBase, ...webStyle.employeeSearch }}
                  inputProps={{ 'aria-label': 'search' }}
                  placeholder={t('reports.searchCustomer')}
                  value={this.state.selectedCustomerName}
                  onChange={this.handleChangeCustomerName}
                  endAdornment={
                    <InputAdornment position="end">
                      <Hidden xsDown>
                        <IconButton aria-label="search">
                          <ExpandMoreIcon />
                        </IconButton>
                      </Hidden>
                    </InputAdornment>
                  }
                />
              </StyledBox>
              {this.customerSearche()}
            </Grid>
          </Grid>
          <Typography style={webStyle.customerText} gutterBottom color="textPrimary">
            {t('reports.employee')}
          </Typography>
          <Box style={webStyle.radioContainer}>
            {this.createCustomer()}
            <Typography variant="body1" style={enable ? { ...webStyle.staffMemberLabel, color: CONSTANTS.color.lightBrown } : webStyle.staffMemberLabel}>
              {t('reports.addIndividual')}
            </Typography>
          </Box>
          {!enable && <Typography style={webStyle.allStaffMemberLabel}>
            {t('reports.allStaffMembersSelected')}
          </Typography>}
          {enable &&
            <StyledBoxSearch style={webStyle.employeeSearchContainer} >
              <Box style={webStyle.employeesContainer} sx={{marginTop:'5px'}}>
                <img style={webStyle.emptyIcon} src={emptyIcon} alt="" />
                <Box style={this.state.languageSelected === "ar" ? { ...webStyle.plusContainer, marginRight: "-15px", marginLeft: 0 } : webStyle.plusContainer}>
                  <img style={webStyle.plusIcon} src={plusIcon} alt="" />
                </Box>
              </Box>
              <Grid container spacing={3}>
                <Grid item lg={10} md={10} xs={12}>
                  <StyledBox
                    data-test-id="handleClickOpenEmployeePopup"
                    style={webStyle.searchContainer}
                    onClick={this.handleClickOpenEmployeePopup}
                  >
                    <StyledBoxInput style={webStyle.inputBoxContainer}>
                      <img style={webStyle.searchIconEmp} src={searchIcon} alt="" />
                      {data.map((employee: EmployeeData) => (
                        <Box style={webStyle.employeeContainer} key={employee.id}>
                          <Typography style={webStyle.employeelabelStyle}>
                            {employee.name}
                          </Typography>
                          <CloseIcon
                            data-test-id="handleRemoveEmployee"
                            style={webStyle.removeButton}
                            onClick={() => this.handleRemoveEmployee(employee.id)}
                          />
                        </Box>
                      ))}
                      {this.state.selectedEmployees.length > (this.state.employeesLength ? 3 : 2) && (
                        <Typography style={webStyle.employeelabelStyle}>
                          +{this.state.selectedEmployees.length - (this.state.employeesLength ? 3 : 2)}
                        </Typography>
                      )}
                    </StyledBoxInput>
                    <InputBase
                      data-test-id="handleChangeEmployeeName"
                      style={{ ...webStyle.inputBase, ...webStyle.employeeSearch }}
                      inputProps={{ 'aria-label': 'search' }}
                      placeholder={t('reports.searchEmployee')}
                      value={this.state.selectedEmployeeName}
                      onChange={this.handleChangeEmployeeName}
                      endAdornment={
                        <InputAdornment position="end">
                          <Hidden xsDown>
                            <IconButton aria-label="search">
                              <ExpandMoreIcon />
                            </IconButton>
                          </Hidden>
                        </InputAdornment>
                      }
                    />
                  </StyledBox>
                  {this.state.employeeAssigneeModal && (
                    <ClickAwayListener data-test-id="handleClickOutSide" onClickAway={this.handleClickOutSide}>
                      <StyledBox style={webStyle.employeeDetailsOuterContainer}>
                        <Box style={webStyle.employeeDetailsBox}>
                          {this.state.employeeData.map((item: EmployeeData) => (
                            <Box key={item.id} style={this.state.languageSelected === "ar" ? { ...webStyle.checkboxContainer, paddingLeft: 0, paddingRight: "1rem" } : webStyle.checkboxContainer}>
                              <StyledCheckbox
                                data-test-id="handleCheckbox"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                checked={this.state.selectedEmployees.map((item: EmployeeData) => item.id).includes(item.id)}
                                onChange={(e) => this.handleCheckbox(e, item)}
                              />
                              <Box
                                data-test-id="handleSelectEmployee"
                                style={webStyle.employeeDetailsContainer}
                              >
                                <img style={webStyle.employeeIcon} src={item.icon} alt="" />
                                <Box style={webStyle.assigneeDetails}>
                                  <Typography style={webStyle.taskHeading}>
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </StyledBox>
                    </ClickAwayListener>
                  )}
                </Grid>
              </Grid>
            </StyledBoxSearch>
          }
        </Box>
        <Box style={webStyle.popupContainer}>
          {openPopup &&
            <ClickAwayListener data-test-id="handleClickAway" onClickAway={this.handleClickAway}>
              <Box style={webStyle.downloadPopup}>
                <img style={webStyle.dropArrow} src={dropDownArrow} alt="" />
                <Typography style={webStyle.downloadFormatText} className={classes.mobileDownloadText} variant="subtitle2">
                  {t('reports.downloadFormat')}
                </Typography>
                <Typography data-test-id="pdfId" onClick={() => this.pdfDownlod('pdf', this.state.requestDate.fromDate, this.state.requestDate.toDate)} style={webStyle.fileText} className={classes.mobileDownloadText} variant="subtitle2">
                  {t('reports.pdfFile')}
                </Typography>
                <Typography data-test-id="csvId" onClick={() => this.pdfDownlod('csv', this.state.requestDate.fromDate, this.state.requestDate.toDate)} style={webStyle.fileText} className={classes.mobileDownloadText} variant="subtitle2">
                  {t('reports.csvFile')}
                </Typography>
              </Box>
            </ClickAwayListener>
          }
          <StyledButton
            data-test-id="handleDownloadButton"
            // style={webStyle.downloadButton}
            onClick={this.handleDownloadButton}
          >
            <Typography style={webStyle.downloadText} className={classes.downloadText} variant="subtitle2">
              {t('reports.download')}
            </Typography>
            <img style={openPopup ? { ...webStyle.dropDownArrow, transform: "rotate(180deg)" } : webStyle.dropDownArrow} src={dropDownArrow} alt="" />
          </StyledButton>
        </Box>
        <LoadingBackdrop
          loading={this.state.getEmployeesListLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({

});

const webStyle = {
  boxInnerContainer: {
    paddingBottom: "20rem",
  },
  bodyText: {
    fontSize: "18px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  customerText: {
    fontSize: "18px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    paddingTop: "1.5rem"
  },
  gridContainer: {
    paddingTop: "1.5rem"
  },
  searchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  inputBoxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  searchIconEmp: {
    height: "20px",
    width: "20px",
    margin: "0px 10px 0px 15px",
  },
  employeeContainer: {
    display: "flex",
    alignItems: "center",
    gap: '5px',
    backgroundColor: "rgb(228, 227, 244)",
    border: "1px solid rgb(111 106 189)",
    borderRadius: "8px",
    padding: "2px 5px"
  },
  employeelabelStyle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  removeButton: {
    cursor: "pointer",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "52px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.2)",
  },
  inputBase: {
    width: "100%",
    fontSize: "20px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    paddingLeft: "10px",
    lineHeight: "16px",
    color: CONSTANTS.color.grey
  },
  employeeSearch: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  employeeSearchContainer: {
    display: "flex",
    justifyContent: "center",
    gap: '1rem'
  },
  employeesContainer: {
    display: "flex",
  },
  emptyIcon: {
    width: "40px",
    height: "40px",
  },
  plusContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50px",
    backgroundColor: CONSTANTS.color.lightBlue,
    cursor: "pointer",
    marginLeft: "-15px",
  },
  plusIcon: {
    width: "11px",
    height: "21px",
  },
  downloadPopup: {
    width: "100%",
    maxWidth: "286px",
    minHeight: "181px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    float: "right" as const,
    position: "absolute" as const,
    bottom: "15px",
    right: "0px",
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column" as const,
    gap: "1rem",
  },
  popupContainer: {
    position: "relative" as const,
  },
  downloadButton: {
    width: '100%',
    maxWidth: "216px",
    minHeight: "64px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.darkBlue,
    textTransform: "none" as const,
    float: "right" as const,
  },
  downloadText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  dropDownArrow: {
    width: "16px",
    height: "8px"
  },
  dropArrow: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    bottom: "-6px",
    right: "42px",
  },
  downloadFormatText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  fileText: {
    color: CONSTANTS.color.blackDark,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer",
  },
  createCustomRadioButtonContainer: {
    width: "54px",
    height: "29px",
    borderRadius: "16px",
    border: "2px solid rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
    justifyContent: "flex-start",
    paddingLeft: "5px"
  },
  radioButtonOn: {
    border: "0px solid rgba(0,0,0,0.1)",
    backgroundColor: "rgb(235 234 243)",
    justifyContent: "flex-end",
    paddingRight: "5px"
  },
  createCustomRadioButton: {
    width: "21px",
    height: "21px",
  },
  radioContainer: {
    display: "flex",
    padding: "1rem 0px 1.5rem",
    gap: "1rem"
  },
  staffMemberLabel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    color: CONSTANTS.color.midGrey,
  },
  allStaffMemberLabel: {
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    color: CONSTANTS.color.midGrey
  },
  employeeDetailsOuterContainer: {
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    padding: "0px",
    borderRadius: "5px",
    minHeight: "160px",
  },
  employeeDetailsBox: {
    overflow: "auto",
    maxHeight: "195px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
    paddingRight: "1rem"
  },
  employeeIcon: {
    height: "34px",
    width: "34px",
    borderRadius: "50%"
  },
  assigneeDetails: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start"
  },
  taskHeading: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    fontSize: "14px",
    lineHeight: "24px",
    cursor: "pointer"
  },
  employeeDetailsContainer: {
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    gap: "1rem",
    padding: "0.5rem 1rem"
  },
}

const reportsDataStyle = withStyles(styles)(ReportsData);
export default withSnackbar(reportsDataStyle);
// Customizable Area End