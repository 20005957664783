
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    openToastHandler: any;
    t:(key:string)=>string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: any
    isErrorEmailData: any
    forgotPasswordLoader:boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ForgotPasswordControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {


    // Customizable Area Start
    getForgotEmailApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [

            getName(MessageEnum.RestAPIResponceMessage)

        ];

        this.state = {
            // Customizable Area Start
            email: "",
            isErrorEmailData: {},
            forgotPasswordLoader:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiReCquestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiReCquestCallID === this.getForgotEmailApiCallId) {
                if (responseJson && !responseJson.errors) {

                    localStorage.setItem("OtpToken", responseJson?.meta?.token)
                    localStorage.setItem("Resend email", this.state.email)
                    localStorage.setItem("otpCount",JSON.stringify(0))
                    this.setState({
                        forgotPasswordLoader:true
                    })
                    this.props.openToastHandler(this.props.t('forgotPassword.success'), "success",)
                    setTimeout(() => {
                        this.props.navigation.navigate("OtpPassword", { email: this.state.email })
                    }, 1000)
                }
                    else{
                    this.props.openToastHandler(responseJson.errors[0].otp, "error")

                }
            }
            // Customizable Area End
        }
    }
    // Customizable Area Start

    handleChange = (e: any) => {
        const errors: any = {};
        this.setState({
            email: e.target.value,
            isErrorEmailData: errors
        })
    }

    validateForgotEmail = (values: string, errors: any) => {
        const emailValidation: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!values) {
            errors.email = this.props.t('forgotPassword.empty');
        } else if (!emailValidation.test(values)) {
            errors.email = this.props.t('forgotPassword.valid');
        }
        return errors;
    };

    handleSubmitEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errors: any = {};
        this.validateForgotEmail(this.state.email, errors);
        this.setState({ isErrorEmailData: errors },
            () => {
                if (Object.keys(this.state.isErrorEmailData).length === 0) {
                    this.forgotEmail();
                }
            },
        );
    }
    forgotEmail = async() => {
        const headers: any = {
            "content-Type": configJSON.validationApiContentType
        }
        const role = localStorage.getItem("role");
        
        const data = {
            "data":
            {
                "type": "email_account",
                "role":role,
                "attributes":
                {
                    "email": this.state.email
                }
            }
        }
        const lang=await getStorageData('lang')
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getForgotEmailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.forgotPassword}?lang=${lang}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    // Customizable Area End
}
