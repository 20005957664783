//@ts-nocheck
import React, { useEffect, useMemo } from "react";

import CryptoJS from "crypto-js";
import { getStorageData } from "../../framework/src/Utilities";

export const PermissionsContext = React.createContext<{
  permissions: any;
  setPermissions: any;
}>({
  permissions: [],
  setPermissions: () => {},
});


export const PermissionsProvider = ({ children }: any) => {
  const [permissions, setPermissions] = React.useState([]);
  let secretKey = "eD%TN@3bc%l},F5eplIMCyd9h!Y2";
  useEffect(() => {
    initPermission();
  }, []);

  const initPermission = async () => {
    let permissions;
    let bytes;
    const ciphertext = await getStorageData("permissions");
    if (ciphertext) {
      bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
      permissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    if (permissions) {
      setPermissions(permissions);
    } else {
      setPermissions([]);
    }
  };

  return (
    <PermissionsContext.Provider
      value={useMemo(() => {
        return {
          permissions: permissions,
          setPermissions: setPermissions,
        };
      }, [permissions])}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
