//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    Typography,
    Grid,
    InputAdornment,
    IconButton,
    withStyles,MenuItem,ListItemIcon,ListItemText
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CloseIcon from '@material-ui/icons/Close';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import CheckIcon from '@material-ui/icons/Check';
import { Theme } from "@material-ui/core/styles"
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web"
import { withRouter } from "react-router";
import RegisterWebController, { Props } from "./RegisterController.web";
import { StyledButton } from "../../email-account-registration/src/utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
// Customizable Area End

export class Register extends RegisterWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    msgTextStyle=(isFocused:string,isAutoFilled:boolean)=>{
        if (isFocused) {
            return 'red'
        } else if (isAutoFilled) {
            return 'green'
        } else {
            return '#818182'
        }
    }
    errorMsgIconColor = (isAutoFilled:boolean) => {
        if (isAutoFilled) {
            return 'green'
        } else {
            return '#818182'
        }
    }
    errorMsgGrid1 = (classes) => {
        return <>
            <MenuItem>
                <Brightness1Icon style={{ color: this.state.isLengthValid ? 'green' : this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled) }} />
                <ListItemText
                    className={classes.errorMsgText} style={{ color: this.state.isLengthValid ? 'green' : this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled) }}
                    primary={`${this.props.t("BoAuthErrors.password.passwordLength")}`}
                />
                <ListItemIcon>
                    {!this.state.isLengthValid && this.state.isFocused ?
                        <CloseIcon style={{ color: 'red' }} /> :
                        <CheckIcon style={{ color: this.state.isLengthValid === true ? 'green' : this.errorMsgIconColor(this.state.isAutoFilled) }} />
                    }
                </ListItemIcon>
            </MenuItem>
            <MenuItem>
                <Brightness1Icon
                    style={{ color: this.state.isSpecialCharacter ? 'green' : this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled) }}
                />
                <ListItemText
                    className={classes.errorMsgText} style={{
                        color: this.state.isSpecialCharacter ? 'green' :
                            this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled)
                    }}
                    primary={`${this.props.t("BoAuthErrors.password.validSpecialChar")}`}
                />
                <ListItemIcon>
                    {!this.state.isSpecialCharacter && this.state.isFocused ?
                        <CloseIcon style={{ color: 'red' }} /> :
                        <CheckIcon style={{ color: this.state.isSpecialCharacter ? 'green' : this.errorMsgIconColor(this.state.isAutoFilled) }} />
                    }
                </ListItemIcon>
            </MenuItem>
        </>
    }
    errorMsgGrid2 = (classes) => {
        return <>
            <MenuItem>
                <Brightness1Icon
                    style={{ color: this.state.isUpperCaseValid ? 'green' : this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled) }}
                />
                <ListItemText
                    className={classes.errorMsgText} style={{
                        color: this.state.isUpperCaseValid ? 'green' :
                            this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled)
                    }}
                    primary={`${this.props.t("BoAuthErrors.password.validUpperCase")}`}
                />
                <ListItemIcon>
                    {!this.state.isUpperCaseValid && this.state.isFocused ?
                        <CloseIcon style={{ color: 'red' }} /> :
                        <CheckIcon style={{ color: this.state.isUpperCaseValid ? 'green' : this.errorMsgIconColor(this.state.isAutoFilled) }} />
                    }
                </ListItemIcon>
            </MenuItem>
            <MenuItem>
                <Brightness1Icon
                    style={{ color: this.state.isLowerCaseValid ? 'green' : this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled) }}
                />
                <ListItemText
                    className={classes.errorMsgText} style={{
                        color: this.state.isLowerCaseValid ? 'green' :
                            this.msgTextStyle(this.state.isFocused, this.state.isAutoFilled)
                    }}
                    primary={`${this.props.t("BoAuthErrors.password.validLowerCase")}`}
                />
                <ListItemIcon>
                    {!this.state.isLowerCaseValid && this.state.isFocused ?
                        <CloseIcon style={{ color: 'red' }} /> :
                        <CheckIcon style={{ color: this.state.isLowerCaseValid ? 'green' : this.errorMsgIconColor(this.state.isAutoFilled) }} />
                    }
                </ListItemIcon>
            </MenuItem>
        </>
    }
    // Customizable Area End

    render() {
         // Customizable Area Start
        const { classes,  } = this.props
        const { signUpEmail, signUpPassword } = this.state.signUpForm;
        return (
           
            <>
                <form onSubmit={this.submitButton}>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12} >
                            <Typography variant="body2" color="textPrimary" className={classes.labeltext}>{this.props.t("BoAuth.emailLabel")}
                                <Typography display="inline" variant="caption" color="error">*</Typography>
                            </Typography>
                            <CustomizedTextField
                                placeholder={this.props.t("BoAuth.emailPlaceHolder")}

                                className={classes.textField}
                                variant="outlined"
                                fullWidth
                                name="signUpEmail"
                                value={signUpEmail}
                                onChange={this.handleChange}
                                error={Boolean(this.state.signUpErrors.signUpEmail)}
                                helperText={this.state.signUpErrors.signUpEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textPrimary" className={classes.labeltext}>{this.props.t("BoAuth.passwordLabel")}
                                <Typography display="inline" variant="caption" color="error">*</Typography>
                            </Typography>
                            <CustomizedTextField
                                placeholder={this.props.t("BoAuth.passwordPlaceholder")}
                                className={`${classes.textField} ${classes.helperTextContainer}`}
                                variant="outlined"
                                fullWidth
                                name="signUpPassword"
                                value={signUpPassword}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                error={Boolean(this.state.signUpErrors.signUpPassword)}
                                helperText={
                                    <Grid item xs={12} lg={8} className={classes.errorMsgGrid}>
                                        {this.errorMsgGrid1(classes)}
                                        {this.errorMsgGrid2(classes)}
                                        <MenuItem>
                                            <Brightness1Icon
                                                style={{ color: this.state.isNumberValid ? 'green' : this.msgTextStyle(this.state.isFocused,this.state.isAutoFilled) }}
                                            />
                                            <ListItemText
                                                className={classes.errorMsgText} style={{ color: this.state.isNumberValid ? 'green' : 
                                                this.msgTextStyle(this.state.isFocused,this.state.isAutoFilled) }}
                                                primary={`${this.props.t("BoAuthErrors.password.validNumeric")}`}
                                            />
                                            <ListItemIcon>
                                                {!this.state.isNumberValid && this.state.isFocused?
                                                    <CloseIcon style={{color:'red'}} />:
                                                    <CheckIcon style={{ color: this.state.isNumberValid ? 'green' : this.errorMsgIconColor(this.state.isAutoFilled) }}/>
                                                }
                                            </ListItemIcon>
                                        </MenuItem>
                                    </Grid>
                                }
                                type={this.state.visibilityPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                data-test-id="showPassword"
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                edge="end"
                                            >
                                                {this.state.visibilityPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    onAnimationStart: this.makeAnimationStartHandler()
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.signupaddCheckBoxContainer}>
                            <StyledCheckbox
                                data-test-id="handleCheckbox"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                checked={this.state.checkBox === "true"}
                                onClick={this.handleCheckSignup}
                            />
                            <Typography
                                data-test-id="handleShowTerms"
                                variant="caption"
                                className={classes.signuptermsHeading}
                                onClick={this.goToTermspageSignup}
                            >
                                {this.props.t('BoAuth.terms')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <StyledButton disabled={this.state.checkBox !== "true"} data-test-id="btnRegister" type="submit" className={classes.signUpButton}>
                                <Typography variant="body1" className={classes.btntext}>{this.props.t('BoAuth.signUp')}</Typography>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </form>
            </>
           
        );
         // Customizable Area End
    }
}

// Customizable Area Start
//@ts-ignore
export const RegisterStyles = ((theme: Theme) => ({
    labeltext: {
        fontWeight: theme.typography.fontWeightRegular,
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    helperTextContainer:{
        "& .MuiFormHelperText-contained":{
            marginLeft:"0px",
            marginRight:"0px"
        },
        "& .MuiIconButton-edgeEnd":{
            "& .MuiSvgIcon-root":{
                fontSize:"25px",
            }
        },
        "& .MuiSvgIcon-root":{
            fontSize:"8px",
            marginLeft:"6px"
        },
        "& .MuiMenuItem-root": {
            lineHeight: 0.2,
            paddingTop:"0px",
            paddingBottom:"0px"
        },
        "& .MuiListItemIcon-root":{
            minWidth:"16px",
            "& .MuiSvgIcon-root":{
                fontSize:"18px",
            },
        },
        "& .MuiListItemText-root":{
            marginBottom:"0px",
            marginTop:"0px"
        },
        "& .MuiTypography-body1": {
            fontSize: "16px",
            marginLeft: "6px",
            fontWeight: 600
        },
        "& .MuiListItem-gutters":{
            paddingLeft:"0px"
        } 
    },
    errorMsgGrid:{
        background:"#FAFAFF",
        width:"100%",
        borderLeft: "5px solid #1C18AF",
        margin:"18px 0 0 0"
    },
   
    logoImg: {
        width: "150px"
    },
    btntext: {
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "none",
        color: theme.palette.common.white
    },
    registerGrid: {
        marginTop: "5px",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            alignItems: 'normal'
        }
    },
    signupaddCheckBoxContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        alignSelf: "flex-start",
        marginTop: "10px"
    },
    signuptermsHeading: {
        borderBottom: "1px solid #1C18AF",
        marginTop: "2px",
        color: "#1C18AF",
        cursor: "pointer",
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 13
        }
    },
    signUpButton: {
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        }
    }
})
)
const RegisterWebRouter = withRouter(Register)
const RegisterToaster = withSnackbar(RegisterWebRouter);
export default withStyles(RegisterStyles)(RegisterToaster);
 // Customizable Area End
