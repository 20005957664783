import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextareaAutosize,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Delete, Close } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import NotesController, {
  Props,
  configJSON,
} from "./NotesController";

export default class Notes extends NotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box>
            <List>
              {this.state.notesList?.length > 0 ? this.state.notesList?.map((element) => (
                <ListItem key={element.id} style={webStyle.noteItemStyle}>
                  <Box>
                    <ListItemText primary={element.attributes.note} />
                    <ListItemText style={webStyle.dateTxtStyle} primary={`${element.attributes.created_date} ${element.attributes.created_time}`} />
                  </Box>
                  <ListItemSecondaryAction>
                    <IconButton edge="end" >
                      <EditIcon data-test-id='btnUpdateNotes' onClick={() => this.ShowNotesModalUpdateState(element)} />
                    </IconButton>
                    <IconButton edge="end" data-test-id='btnDeleteNotes' onClick={() => this.deleteModalOpen(element.attributes)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )) :
                (<ListItem >
                  <ListItemText style={webStyle.emptyListItemTxt} primary={configJSON.labelNoNote} />
                </ListItem>)
              }
            </List>
            <Box
              data-test-id="btnAddNotes"
              onClick={this.ShowNotesModalSetState}
              component="button"
              sx={webStyle.buttonStyle}
            >
              <Button color={"primary"} >{configJSON.btnAdd}</Button>
            </Box>
          </Box>
        </Container>
        <Dialog
          open={this.state.isShowNotesModal}
          onClose={this.ShowNotesModalSetState}
          data-test-id='addNoteModal'
        >
          <IconButton style={webStyle.iconBtnStyle} onClick={this.notesDailougModalClose} data-test-id={'modelCloseIcon'}><Close style={webStyle.iconStyle} /></IconButton>
          <DialogTitle style={webStyle.dialogTitleStyle}>{configJSON.labelTitleText}</DialogTitle>
          <DialogContent>
            <TextareaAutosize
              data-test-id={'txtInput'}
              placeholder={configJSON.labelBodyText}
              value={this.state.notes}
              onChange={(event) => this.setNotesCell(event.target.value)}
              minRows={4}
              style={{
                resize: 'none',
                ...webStyle.inputStyle
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" variant='contained' onClick={this.notesDailougModalClose} data-test-id='closeAddModal'>{configJSON.cancelBtn}</Button>
            <Button
              variant='contained'
              data-test-id="btnAddExample"
              onClick={this.checkNotesText}
            >{this.state.isCreate ? configJSON.btnAdd : configJSON.updateBtnText}</Button>
          </DialogActions>
        </Dialog>
        <Box>
          <Dialog
            open={this.state.isDeleteModalOpen}
            onClose={this.deleteDailogueClose}
          >
            <DialogTitle id="alert-dialog-title">
              {configJSON.deleteModalText}
            </DialogTitle>

            <DialogActions>
              <Button onClick={this.deleteDailogueClose} color='secondary' data-test-id='deleteModalCloseBtn' variant="contained" >{configJSON.cancelBtn}</Button>
              <Button onClick={this.showNotesModalDeleteNotesWeb} variant='contained'  autoFocus>
                {configJSON.deleteBtn}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    width: "40%",
    height: "45px",
    border: "none",
    backgroundColor: "rgb(255, 207, 21)",
    borderRadius: "25px",
    marginTop: "20px",
    marginLeft: "28%",
    '& .MuiButton-label': {
      fontSize: 16,
      fontWeight: '600',
      color: 'white'
    }
  },
  inputStyle: {
    fontSize: "16px",
  },
  textFieldInput: {
    fontSize: "24px",
    padding: "5%",
  },
  placeholder: {
    fontSize: '24px',
  },
  updateModalStyle: {
    "&.MuiPaper-root": {
      background: "#866226"
    },
  },
  noteItemStyle: {
    border: "1px solid #ccc",
    marginTop: 15,
    borderRadius: 5,
  },
  dateTxtStyle: {
    fontSize: 10,
    color: "grey"
  },
  iconBtnStyle: {
    width: 26,
    height: 26,
    marginLeft: "85%",
    marginTop: "5%",
  },
  iconStyle: {
    color: 'black'
  },
  dialogTitleStyle: {
    fontWeight: 600
  },
  dialogStyle: {
    '& .MuiDialogPaper': {
      borderRadius: "10px",
      boxShadow: 'none',
      padding: "15px"
    }
  },
  emptyListItemTxt: {
    color: "red",
  }
};
// Customizable Area End
