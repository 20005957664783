import React from "react";
import {
  Box,
  Typography,
  Popover
} from '@material-ui/core';
import {SiteProp} from "../../blocks/CustomisableUserProfiles/src/Customers.web"
import {ProductItem} from "../../blocks/CustomisableUserProfiles/src/CustomerRequestsController.web"

interface Props {
    anchorEl: Element | null,
    anchorId: string,
    popoverHeading: string,
    listData: ProductItem[] | undefined,
    siteData:SiteProp[] | undefined
    handleTooltipClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const ListPopover = (props: Props) => {
    const renderName = (item: string) => {
       return (
        <Box display={"flex"} >
        <Typography style={{ textTransform: "capitalize", color: "#666666" }} variant="body2">
            {item}
        </Typography>
    </Box>
       )
    }
    return (
    <>
      <Popover
          id={props.anchorId}
              open={Boolean(props.anchorEl)}
              anchorEl={props.anchorEl}
              onClose={props.handleTooltipClose}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
          >
              <Box style={webStyles.productPopover} p={1} >
                  <Typography variant="body1"
                      style={webStyles.popoverHeading}>{props.popoverHeading}</Typography>
                  {

                        props.popoverHeading === "Sites" ?

                        props.siteData && props.siteData.map((item: SiteProp) => {
                                return (
                                    renderName(item.site_name)
                                )
                            })
                            : props.listData && props.listData.map((item: ProductItem) => {
                                return (renderName(item.attributes.product_name)
                                )
                            })
                    }

              </Box>
          </Popover>
      </>
  );
}

export const webStyles = {
    productPopover: {
        backgroundColor: 'white',
        color: "black",
        fontSize: 15,
        width: "191px",
        borderRadius: "5px",
        padding: "0.5rem 0.5rem 0 0.5rem",
        height: "200px",
        overflowX: "scroll" as const,
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)"
    },
    popoverHeading: {
        color: "#999999"
    },
};

export default  ListPopover

