//@ts-nocheck
import React from 'react';
// Customizable Area Start
import { Button, Dialog, Grid,Avatar,Snackbar, Typography ,Divider, DialogContent,DialogTitle,DialogActions} from '@material-ui/core';
import {  StyledTextFieldRequest,StyledTextFieldQuote } from "../../RequestManagement/src/Utility.web";
import { StyledCheckbox } from './Calendar.web'
import TimePicker from "../../../components/src/TimePicker";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { searchIcon } from "../../CustomisableUserProfiles/src/assets";
import ClearIcon from '@material-ui/icons/Clear';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import NewAppointmentController, { Props } from './NewAppointmentController.web';
import withCalendarStyles from "../../appointmentmanagement/src/CommonStyle.web";
import EmpSelectInput from './EmpSelectInput.web'
import CustomDatePicker from "../../../components/src/DatePicker";
import { CONSTANTS } from "../../../components/src/theme"
import { dateMinusOne } from "../../../components/src/CommonFunction";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from 'yup'
import { t } from "i18next";
import Select, { components } from 'react-select'
// Customizable Area End
export const configJSON = require("./config");
// Customizable Area Start
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const dotStyle = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 8,
        content: '" "',
        display: "block",
        height: 20,
    }
});
export function customSelectStyles () {
    return {
        indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
        control: (styles) => ({
            ...styles, borderRadius: "8px", padding: "7px",
            borderColor: "hsl(0,0%,80%)",
            boxShadow: "none",
            '&:hover': {
                borderColor: "hsl(0,0%,80%)",
            },
        }),
        valueContainer: (styles) => ({ ...styles, padding: "0px 0px" }),
        placeholder: (styles) => ({ ...styles, ...dotStyle("#ccc") }),
        singleValue: (styles) => ({ ...styles, color: CONSTANTS.color.lightGrey, fontSize: '19px' }),
        menu: (styles) => ({ ...styles, zIndex: 99999 }),
    }
};
 // Customizable Area End
export class NewAppointment extends NewAppointmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    finalErrorMessage(error: any, touch: any,classes:any) {
        if (error) {
            if (touch) {
                return <div className={`${classes.fieldError} ${classes.text_red}`}>
                    {error}
                </div>
            }
        }
    }
    formDialogueTitle() {
        const { classes } = this.props
        return <>
            <DialogTitle>
                {this.props.viewAppointmentDetails &&
                    <Grid container style={{ justifyContent: 'end' }}>
                        <ClearIcon className={classes.text_lightBrown} onClick={this.props.handleCloseNewAppointMentModal} />
                    </Grid>
                }
                <Typography variant='h6' className={this.props.viewAppointmentDetails ? classes.text_lightBrown : `${classes.text_lightBrown} ${classes.paddingTop_15}`}>
                    {this.props.isOpenNewAppointment && `${t('Calendar.newAppointment')}`}
                    {this.props.openEditAppointmentModal && `${t('Calendar.editAppointment')}`}
                    {this.props.viewAppointmentDetails && <span>{t('Calendar.appointment')} - {this.props.selectedEventData.title}</span>}
                </Typography>
            </DialogTitle>
        </>
    }
    formDialogueAction(){
        const { classes } = this.props 
        return <>
            {!this.props.viewAppointmentDetails &&
                <DialogActions className={classes.p_25}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <Button variant="outlined"
                                className={this.props.isOpenNewAppointment ? `${classes.cancelBtn} ${classes.text_border_red} ${classes.text_red}` : `${classes.deleteBtn} ${classes.text_border_gray}`}
                                onClick={this.props.handleCloseNewAppointMentModal}
                            >
                                <Typography variant='body1'>{t('Calendar.button.cancel')}</Typography>
                            </Button>
                            </Grid>
                        <Grid item sm={5} className={classes.btn_hide_smallScreen}>
                            {!this.props.isOpenNewAppointment &&
                                <Button variant="outlined"
                                    className={ this.props.selectLanguage ==='ar' ? `${classes.deleteBtn} ${classes.mr_15} ${classes.text_border_red} ${classes.text_red}` : `${classes.deleteBtn} ${classes.marginLeft_25} ${classes.text_border_red} ${classes.text_red}` }
                                    onClick={this.props.deleteAppointment}
                                >
                                    <Typography variant='body1'>{t('Calendar.button.delete')}</Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid xs={6} item sm={4} style={{ textAlign: 'end' }}>
                            <Button variant="outlined"
                                type='submit'
                                className={classes.saveBtn}
                                data-test-id='set-new-appointment'
                            >
                                <Typography variant='body1'>{t('Calendar.button.save')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.btn_hide_largeScreen}>
                            {!this.props.isOpenNewAppointment &&
                                <Button variant="outlined"
                                    className={`${classes.deleteBtn} ${classes.mt_10} ${classes.text_border_red} ${classes.text_red}`}
                                    onClick={this.props.deleteAppointment}
                                >
                                    <Typography variant='body1'>{t('Calendar.button.delete')}</Typography>
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </DialogActions>
            }
        </>
    }
    descriptionInput({ values, handleChange }) {
        const { classes } = this.props
        return <> {this.props.isOpenNewAppointment &&
            <>
                <Typography variant='body1' className={!this.props.viewAppointmentDetails ? `${classes.marginBottom_5} ${classes.text_lightGrey}`:classes.text_lightGrey} style={{ marginTop: "20px" }}>{t('Calendar.label.descriptionLabel')}</Typography>
                <StyledTextFieldQuote
                    variant="outlined"
                    multiline
                    fullWidth
                    minRows={4}
                    name='description'
                    onChange={handleChange}
                />
            </>
        }
        {this.props.viewAppointmentDetails &&
            <>
                <Typography variant='body1' className={`${classes.mb_5} ${classes.text_lightGrey}`} style={{ marginTop: "20px" }}>{t('Calendar.label.descriptionLabel')}</Typography>
                <Typography variant='body1' className={classes.font_gray}>{values.description}</Typography>
            </>
        }
        </>
    }
    customerInput({ values, PlaceholderText,ClearIndicator,setFieldValue }) {
        const { classes } = this.props
        return <>
            <Typography variant='body1' style={{ marginTop: "20px" }} className={!this.props.viewAppointmentDetails ? `${classes.marginBottom_5} ${classes.text_lightGrey}` : classes.text_lightGrey}>
                {t('Calendar.label.customer')}{!this.props.viewAppointmentDetails && <span className={`${classes.text_red}`}>*</span>}
            </Typography>
            {this.props.viewAppointmentDetails && <Typography variant='body1' className={classes.font_gray}>{this.props.getCustomerData.find((item) =>
                item.id == values.customer_id)?.attributes.name}</Typography>}
            {!this.props.viewAppointmentDetails &&
                <Select
                    components={{ PlaceholderText, ClearIndicator }}
                    isClearable
                    styles={customSelectStyles()}
                    data-test-id='select-customer'
                    defaultValue={{
                        label: this.props.getCustomerData?.find((item) =>
                            item.id == values.customer_id)?.attributes.name,
                        value: values.customer_id,
                    }}
                    theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: "#1c18af" } })}
                    placeholder={<PlaceholderText />}
                    options={this.props.getCustomerData.map((item) => {
                        return {
                            value: item.id,
                            label: item.attributes.name
                        }
                    })}
                    onChange={(selected) => {
                        setFieldValue('customer_id', selected && selected.value)
                        setFieldValue('site_id', null)
                        this.props.getSite(selected.value)
                    }}
                />
            }
        </>
    }
    siteInput({values, PlaceholderText, ClearIndicator,setFieldValue }) {
        const { classes } = this.props
        return <>
            <Typography variant='body1'
                style={{ marginTop: "20px" }}
                className={!this.props.viewAppointmentDetails ? `${classes.marginBottom_5} ${classes.text_lightGrey}` : classes.text_lightGrey}>
                {t('Calendar.label.site')}
                {!this.props.viewAppointmentDetails && <span className={`${classes.text_red}`}>*</span>}
            </Typography>
            {this.props.viewAppointmentDetails && <Typography variant='body1' className={classes.font_gray}>
                {this.props.getSiteData?.find((item) =>
                    item.id == values.site_id)?.site_name}
            </Typography>
            }
            {!this.props.viewAppointmentDetails &&
                <Select
                    components={{ PlaceholderText, ClearIndicator }}
                    isClearable
                    data-test-id='select-site'
                    value={{
                        label: values.site_id ?
                            this.props.getSiteData.find((item) =>
                                item.id == values.site_id).site_name : null,
                        value: values.site_id,
                    }}
                    placeholder={<PlaceholderText />}
                    theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: "#1c18af" } })}
                    styles={customSelectStyles()}
                    options={this.props.getSiteData.map((item) => {
                        return {
                            value: item.id,
                            label: item.site_name
                        }
                    })}
                    onChange={(selected) => {
                        setFieldValue('site_id', selected && selected.value)
                    }}
                />
            }
        </>
    }
    emailTeam({ values, handleChange }) {
        const { classes } = this.props
        return <Grid container alignItems="center"
            className={`${classes.pl_4}`}
        >
            {!this.props.viewAppointmentDetails &&
                <>
                    <StyledCheckbox
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        onChange={handleChange}
                        name='email_team'
                        checked={values.email_team}
                    />
                    <Typography variant='body1' className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.pr_9}` : classes.font_gray}>{t('Calendar.label.emailTeam')}</Typography>
                </>
            }
        </Grid>
    }
    allDayCheckbox({ values, classes, handleChange }) {
        return <>
            {!this.props.isOpenNewAppointment && !this.props.viewAppointmentDetails &&
                <Grid item xs={12} sm={6}>
                    <Grid container alignItems="center" className={`${classes.pl_4} ${classes.mt_10}`}>
                        <StyledCheckbox
                            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                            onChange={handleChange}
                            name='all_day'
                            checked={values.all_day}
                        />
                        <Typography variant='body1' className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.pr_9}` : classes.font_gray}>{t('Calendar.label.allDay')}</Typography>
                    </Grid>
                </Grid>
            }
        </>
    }
    isDivisibleByThree(number){
        return number % 3 ===0;
    }
    employeeSelectData({ classes }) {
        return <Grid container alignItems="center" className={classes.paddingLeft_18}> 
            {this.props.selectedEventData.employee.length>0 && this.props.selectedEventData.employee.map((item, index) => {
                return <>
                    <Grid item xs={12} sm={this.props.selectedEventData.employee.length===1 ? 12 :4}>
                        <Grid container>
                            <Grid item xs={this.props.selectedEventData.employee.length>1 && 3}>
                                <Avatar style={{ marginBottom: '12px', marginTop: '15px' }} src={this.props.selectedEventData.employee_img[index].employee_image || ''} />
                            </Grid>
                            <Grid item xs={this.props.selectedEventData.employee.length>1 && 8} style={{ paddingLeft: '9px',marginTop:"8px" }}>
                                <>
                                    <Typography variant="body1" className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.fontWeight_500} ${classes.titleText} ${classes.pr_9}` : `${classes.font_gray} ${classes.fontWeight_500} ${classes.titleText}`}>
                                        {item.employee_name}
                                    </Typography>
                                    <Typography className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.font_14} ${classes.titleText} ${classes.pr_9}` : `${classes.font_gray} ${classes.font_14} ${classes.titleText}`}>
                                        {item.employee_email}
                                    </Typography>
                                </>
                            </Grid>
                            {!this.isDivisibleByThree(index+1) && index < this.props.selectedEventData.employee.length - 1 &&
                                <Grid item xs={1} style={{ alignItems: "center", display: "flex" }}>
                                    <Divider className={classes.divider} orientation="vertical" />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    
                </>
            })}
        </Grid>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const AppointmentSchema = Yup.object().shape({
            title: Yup.string().required(`${t('Calendar.ErrorMessage.title')}`),
            employee_id: Yup.string().required(`${t('Calendar.ErrorMessage.team')}`),
            customer_id:Yup.string().required(`${t('Calendar.ErrorMessage.CustomerName')}`),
            site_id:Yup.string().nullable(true).required(`${t('Calendar.ErrorMessage.SiteName')}`),
            start_date: Yup.date().min(dateMinusOne(new Date()), `${t('Calendar.ErrorMessage.minDate')}`).required(`${t('Calendar.ErrorMessage.startDate')}`),
            end_date: Yup.date().min(dateMinusOne(new Date()), `${t('Calendar.ErrorMessage.minDate')}`).required(`${t('Calendar.ErrorMessage.endDate')}`),
            start_time: Yup.string().required(`${t('Calendar.ErrorMessage.startTime')}`),
            end_time: Yup.string().required(`${t('Calendar.ErrorMessage.endTime')}`),
        })
        const PlaceholderText = () => {
            return (
                <Grid container>
                    <Grid item style={{display:"flex"}}>
                        <img className={classes.searchIcon1} src={searchIcon} alt="" />
                    </Grid>
                </Grid>
            )
        }
          const ClearIndicator: React.FC<ClearIndicatorProps> = props => {
            return (
                <components.ClearIndicator {...props}>
                    <Grid>
                        {props.getValue()[0].value !== '' ?
                            <ClearIcon style={{ display: "none" }} /> : ''}
                    </Grid>
                </components.ClearIndicator>
            );
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Dialog
                    open={this.props.handleOpenNewAppointMentModal}
                    onClose={this.props.handleCloseNewAppointMentModal}
                    PaperProps={{
                        style: {
                            width: "500px",
                            borderRadius: '20px',
                        },
                    }}>
                    {this.formDialogueTitle()}
                    <Formik
                        initialValues={this.formInitialValues()}
                        validationSchema={AppointmentSchema}
                        data-test-id='formikAppointmentForm'
                        onSubmit={(values: any) => {this.props.isOpenNewAppointment ?
                            this.createAppointment(values) : this.updateAppointment(values)}}
                    >
                        {({ errors, values, touched, handleSubmit, handleChange, setFieldValue, getFieldValue }: any) => (
                            <form onSubmit={handleSubmit} style={{ overflow: 'auto' }}>
                                <DialogContent style={{ overflow: 'hidden', paddingTop: '0px' }}>
                                    <Typography variant='body1' className={!this.props.viewAppointmentDetails ?`${classes.marginBottom_5} ${classes.text_lightGrey}`:classes.text_lightGrey }>
                                    {t('Calendar.label.titleLabel')}{!this.props.viewAppointmentDetails && <span className={`${classes.text_red}`}>*</span>}
                                    </Typography>
                                    {!this.props.viewAppointmentDetails ?
                                        <StyledTextFieldRequest
                                            variant="outlined"
                                            inputProps={{ 'aria-label': 'title' }}
                                            fullWidth
                                            name='title'
                                            onChange={handleChange}
                                            value={values.title}
                                        />
                                        : <Typography variant='body1' className={classes.font_gray}>
                                            <div className={classes.titleText}>
                                                {values.title}
                                            </div>
                                        </Typography>
                                    }
                                    {this.finalErrorMessage(errors.title, touched.title, classes)}
                                    {this.descriptionInput({ values, handleChange })}
                                    {this.customerInput({ values, PlaceholderText,ClearIndicator,setFieldValue })}
                                    {this.finalErrorMessage(errors.customer_id, touched.customer_id, classes)}
                                    {this.siteInput({values, PlaceholderText,ClearIndicator,setFieldValue })}
                                    {this.finalErrorMessage(errors.site_id, touched.site_id, classes)}
                                    <Typography variant='body1' className={`${classes.marginBottom_5} ${classes.text_lightGrey}`} style={{ marginTop: "20px" }}>{t('Calendar.label.assignTeamMember')}
                                        {!this.props.viewAppointmentDetails && <span className={`${classes.text_red}`}>*</span>}
                                    </Typography>
                                    {!this.props.viewAppointmentDetails &&
                                        <>
                                            <EmpSelectInput
                                                isEmployee={this.props.isEmployee}
                                                getEmployeeData={this.props.getEmployeeData}
                                                values={values}
                                                type='appointment'
                                                isActive={this.props.isActive}
                                                selectLanguage={this.props.selectLanguage}
                                                data-test-id='assignDropdown'
                                                onChange={(selected: any) => {
                                                    setFieldValue('employee_id', selected && selected.length > 0 && selected.map((item: any) => item.value))
                                                }}
                                                onClick={(employeeId) => setFieldValue("employee_id", values.employee_id.filter((item) => {
                                                    return item !== employeeId
                                                }))}
                                            />
                                        </>
                                    }
                                    {this.props.viewAppointmentDetails && this.employeeSelectData({classes})}
                                    {this.finalErrorMessage(errors.employee_id, touched.employee_id, classes)}
                                    {this.emailTeam({values,handleChange})}
                                    <Typography variant='body1' className={`${classes.marginBottom_5} ${classes.text_lightGrey} ${classes.mt_20}`}>
                                        {t('Calendar.label.start')} {!this.props.viewAppointmentDetails && <span className={`${classes.text_red} ${classes.marginLeft_4}`}>*</span>}
                                    </Typography>
                                    {!this.props.viewAppointmentDetails &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <CustomDatePicker
                                                    selectedDate={values.start_date}
                                                    dateChange={(date) =>{
                                                        setFieldValue('start_date', date)}
                                                    }
                                                />
                                                {this.finalErrorMessage(errors.start_date, touched.start_date, classes)}
                                            </Grid>
                                            <Grid item xs={12} sm={6} className={classes.marginTop_0}>
                                                <TimePicker
                                                    name="start_time"
                                                    time={values.start_time}
                                                    data-test-id='start-time'
                                                    onChange={(timeValue: any) =>
                                                        setFieldValue('start_time', timeValue)
                                                    }
                                                    t={t}
                                                    languageSelected={this.props.selectLanguage}
                                                />
                                                {this.finalErrorMessage(errors.start_time, touched.start_time, classes)}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.props.viewAppointmentDetails &&
                                        <Typography variant='body1' className={classes.font_gray}>{moment(values.start_date).format("DD/MM/YYYY").toString()}, {values.start_time}</Typography>
                                    }
                                    <Typography variant='body1' className={`${classes.marginBottom_5} ${classes.text_lightGrey} ${classes.mt_20}`}>
                                        {t('Calendar.label.end')}
                                        {!this.props.viewAppointmentDetails && <span className={`${classes.text_red}`}>*</span>}
                                    </Typography>
                                    {!this.props.viewAppointmentDetails &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <CustomDatePicker
                                                    selectedDate={values.end_date}
                                                    dateChange={(date) =>
                                                        setFieldValue('end_date', date)
                                                    }
                                                />
                                                {this.finalErrorMessage(errors.end_date, touched.end_date, classes)}
                                            </Grid>
                                            <Grid item xs={12} sm={6} className={classes.marginTop_0}>
                                                <TimePicker
                                                    name="end_time"
                                                    data-test-id='end-time'
                                                    time={values.end_time}
                                                    defaultValue={values.end_time}
                                                    onChange={(timeValue: any) =>
                                                        setFieldValue('end_time', timeValue)
                                                    }
                                                    t={t}
                                                    languageSelected={this.props.selectLanguage}
                                                />
                                                {this.finalErrorMessage(errors.end_time, touched.end_time, classes)}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.props.viewAppointmentDetails &&
                                        <Typography variant='body1' className={`${classes.font_gray} ${classes.marginBottom_40}`}>{moment(values.end_date).format("DD/MM/YYYY").toString()}, {values.end_time}</Typography>
                                    }
                                    {this.allDayCheckbox({classes,values,handleChange})}
                                </DialogContent>
                                {this.formDialogueAction()}
                                <Grid>
                                    <LoadingBackdrop
                                        loading={this.state.loading}
                                    />
                                </Grid>
                                {this.props.showErrorMessage &&
                                    <Grid item xs={12}>
                                        <Snackbar
                                            open={true}
                                            autoHideDuration={4000}
                                            anchorOrigin={{ vertical: this.state.vertical, horizontal: this.state.horizontal }}
                                            key={this.state.vertical + this.state.horizontal}
                                        >
                                            <Alert severity="error" onClose={this.handleCloseErrorToaster}>{this.state.errorMessage}</Alert>
                                        </Snackbar>
                                    </Grid>
                                }
                                {this.state.showSuccessMessage &&
                                    <Grid item xs={12}>
                                        <Snackbar
                                            open={true}
                                            autoHideDuration={4000}
                                            anchorOrigin={{ vertical: this.state.vertical, horizontal: this.state.horizontal }}
                                            key={this.state.vertical + this.state.horizontal}
                                        >
                                            <Alert severity="success">{this.state.successMessage}</Alert>
                                        </Snackbar>
                                    </Grid>
                                }
                            </form>
                        )}
                    </Formik>
                </Dialog>
                <Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export default(withCalendarStyles)(NewAppointment);
// Customizable Area End
