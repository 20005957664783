import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeId: number | null;
  activeId2: number | null;
  privacyPolicyData: any;
  privacyLoading: boolean;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  privacyPolicyPageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeId: 1,
      activeId2: 1,
      privacyPolicyData: {},
      privacyLoading: false,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });

    this.privacyPolicyPage();
    this.setState({ privacyLoading: true });
    window.scrollTo(0, 0);    
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.privacyPolicyPageApiCallId) {
          if (!responseJson.errors) {
            this.setState({ privacyLoading: false });
            this.setState({ privacyPolicyData: responseJson });
          } else {
            //Check Error Response
            this.setState({ privacyLoading: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectSection = (e: any, id: any) => {
    e.preventDefault();
    this.setState({
      activeId: id,
      activeId2: null,
    });
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  handleSelectSection2 = (e: any, id: any) => {
    e.preventDefault();
    this.setState({
      activeId2: id,
      activeId: null,
    });
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  privacyPolicyPage = async () => {
    const selectedLanguage = await getStorageData("lang");
    const header = {
      "Content-Type": configJSON.privacyPolicyAPIContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.privacyPolicyPageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.privacyPolicyAPIEndPoint}?lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.privacyPolicyAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End