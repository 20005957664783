import React from "react";

// Customizable Area Start
import {
  Box,
  Drawer,
  Hidden,
  List,
  Typography,
  Tabs,
  Tab,
  Modal,
  Button,
  TextField,
  IconButton
} from "@material-ui/core";
import {
  Theme,
  withStyles,
  styled
} from "@material-ui/core/styles";
import {
  lamsaLogo,
  createIcon,
  dashboardIcon,
  tasksIcon,
  requestsIcon,
  jobsIcon,
  mapsIcon,
  invoicesIcon,
  quotesIcon,
  calendarIcon,
  timesheetIcon,
  suppliersIcon,
  customersIcon,
  employeesIcon,
  reportsIcon,
  settingsIcon,
  dialogBoxIcon,
  close,
  searchIcon,
  questionMarkIcon
} from "./assets";
import DashboardNavBar from "./DashboardNavBar.web";
import { DRAWER_WIDTH } from "./utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web"
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import DashboardSideBarController, {
  Props,
} from "./DashboardSideBarController.web";

// Customizable Area Start
export const StyledDrawer = withStyles({
  root: {
    "& .MuiDrawer-paperAnchorLeft": {
    }
  }
})(Drawer);
export const FaqsIconButton = withStyles((theme) => ({
  root: {
    marginLeft: "0.5rem",
    boxShadow: "0px 3px 6px rgba(28,24,175,0.1)",
    [theme.breakpoints.up("sm")]: {
      display: "none !important"
    }
  }
}))(IconButton);

export const StyledBox = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "162px !important",
      minHeight: "117px !important",
      transform: "translate(11%, 30%) !important",
      display: "flex !important",
      flexDirection: "column !important",
      padding: "1rem 1rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translate(11%, 35%) !important",
    }
  }
}))(Box);

export const StyledImg = styled('img')(({ theme }: any) => ({
  [theme.breakpoints.down("md")]: {
    transform: "translate(135px, 143px) !important"
  },
  [theme.breakpoints.down("xs")]: {
    transform: "translate(135px, 165px) !important"
  },
}));

const StyledTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      paddingLeft: "2.5rem",
      color: CONSTANTS.color.coolDarkGrey,
      height: "42px",
    },
  }
})(TextField);

export const StyledTabs = withStyles((theme: Theme) => ({
  root: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    "& .MuiButtonBase-root": {
      minHeight: "56px",
      [theme.breakpoints.down("xs")]: {
        minHeight: "48px",
        maxWidth: "202px",
      }
    },
    "& .MuiTab-textColorInherit": {
      color: CONSTANTS.color.lightBlack
    },
    "& .Mui-selected": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white
    },
  }
}))((props: any) => <Tabs {...props} TabIndicatorProps={{ style: { display: 'none' } }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    "& .MuiTab-wrapper": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: "1rem",
      gap: "0.8rem",
      textTransform: "capitalize" as const,
      fontSize: "16px",
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0 !important",
      }
    },
    "& .MuiSvgIcon-root": {
      marginRight: "1rem",
    },
    "&:hover": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white,
      "& img": {
        filter: "brightness(0) invert(1)"
      }
    }
  }
}))(Tab);

const StyledButton = withStyles({
  root: {
    "& .MuiButton-label": {
      display: "block"
    },
    "&:hover": {
      backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& img": {
        filter: "brightness(0) invert(1)"
      }
    },
  }
})(Button);

export const StyledSideBarBox = withStyles((theme) => ({
  root: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "48px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "36px !important",
    },
  }
}))(Box);

export const StyledHeadingBox = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      justifyContent: "space-between !important",
      alignItems: "center !important",
      padding: "0 2rem 1rem 1.5rem !important"
    }
  }
}))(Box);

export const StyledTypography = withStyles((theme) => ({
  root: {
    padding: "0 2rem 1.5rem 3rem",
    color: CONSTANTS.color.lightGrey,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
    marginLeft: "-0.2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0rem 0rem 0rem !important",
      fontSize: "12px !important",
    }
  }
}))(Box);

export const StyledCloseIcon = styled('img')(({ theme }) => ({
  width: "10px",
  height: "10px",
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    display: "none !important",
  }
}));

export const StyledSearchBox = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "0px 1rem 0.5rem 1rem",
    gap: "8px",
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
    }
  }
}))(Box);

export const StyledSearchContainer = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      justifyContent: "space-between !important",
      alignItems: "center !important",
    }
  }
}))(Box);

const buttonStyle = (item: any, selectedId: number) => {
  return item.id === selectedId ? {...webStyle.createItem, backgroundColor: CONSTANTS.color.darkBlue} : webStyle.createItem
}

const createPopupIconStyle = (item: any, selectedId: number) => {
  return item.id === selectedId ? {width: item.width, height: item.height, filter: "brightness(0) invert(1)"} : {width: item.width, height: item.height}
}

const createPopupTextStyle = (item: any, selectedId: number) => {
  return  item.id === selectedId ? {...webStyle.createText, color: CONSTANTS.color.white} : webStyle.createText
}

const renderIcon = (item: any, active: any, create: boolean) => {
  return (
    !create ?
      <img
        style={
          active === item.id - 1 ?
          {width: item.width, height: item.height, marginBottom: "0px", filter: "brightness(0) invert(1)"} :
          {width: item.width, height: item.height, marginBottom: "0px"}
        }
        src={item.icon}
        alt=""
      />
    :
      <img
        style={
          item.id === 1 && create ?
          {width: item.width, height: item.height, marginBottom: "0px", filter: "brightness(0) invert(1)"} :
          {width: item.width, height: item.height, marginBottom: "0px"}
        }
        src={item.icon}
        alt=""
      />
    );
}
// Customizable Area End

export class DashboardSideBar extends DashboardSideBarController {
  constructor(props: Props) {
    super(props);
  }

  navMenu = () => {
    this.setState({ open: true });
  }

  render() {
    // Customizable Area Start
    const { languageSelected } = this.state;
    const { t } = this.props;
    const sideBarData = [
      {
        id: 1,
        title: t('dashboardSidebar.create'),
        icon: createIcon,
        width: "22px",
        height: "22px",
      },
      {
        id: 2,
        title: t('dashboardSidebar.dashboard'),
        icon: dashboardIcon,
        width: "23px",
        height: "21px",
      },
      {
        id: 3,
        title: t('dashboardSidebar.tasks'),
        icon: tasksIcon,
        width: "21px",
        height: "20px",
      },
      {
        id: 4,
        title: t('dashboardSidebar.requests'),
        icon: requestsIcon,
        width: "20px",
        height: "20px",
      },
      {
        id: 5,
        title: t('dashboardSidebar.quotes'),
        icon: quotesIcon,
        width: "20px",
        height: "20px",
      },
      {
        id: 6,
        title: t('dashboardSidebar.jobs'),
        icon: jobsIcon,
        width: "20px",
        height: "20px",
      },
      {
        id: 7,
        title: t('dashboardSidebar.invoices'),
        icon: invoicesIcon,
        width: "16px",
        height: "21px",
      },
      {
        id: 8,
        title: t('dashboardSidebar.maps'),
        icon: mapsIcon,
        width: "20px",
        height: "20px",
      },
      {
        id: 9,
        title: t('dashboardSidebar.calendar'),
        icon: calendarIcon,
        width: "20px",
        height: "20px",
      },
      {
        id: 10,
        title: t('dashboardSidebar.timesheet'),
        icon: timesheetIcon,
        width: "20px",
        height: "20px",
      },
    ];
    
    const sideBarData3 = [
      {
        id: 1,
        title: t('dashboardSidebar.reports'),
        icon: reportsIcon,
        width: "23px",
        height: "21px",
      },
      {
        id: 2,
        title: t('dashboardSidebar.settings'),
        icon: settingsIcon,
        width: "17px",
        height: "20px",
      },
    ]
    
    const mockCreateData = [
      
      { id: 1, title: t('dashboardSidebar.customer'), width: "28px", height: "20px", icon: customersIcon },
      { id: 2, title: t('dashboardSidebar.request'), width: "30px", height: "31px", icon: requestsIcon },
      { id: 3, title: t('dashboardSidebar.quote'), width: "29px", height: "30px", icon: quotesIcon },
      { id: 4, title: t('dashboardSidebar.job'), width: "29px", height: "29px", icon: jobsIcon },
      { id: 5, title: t('dashboardSidebar.invoice'), width: "23px", height: "29px", icon: invoicesIcon },
    ]
    
    let sideBarDataBusiness = [
      {
        id: 1,
        title: t('dashboardSidebar.suppliers'),
        icon: suppliersIcon,
        width: "26px",
        height: "25px",
      },
      {
        id: 2,
        title: t('dashboardSidebar.customers'),
        icon: customersIcon,
        width: "25px",
        height: "20px",
      },
      {
        id: 3,
        title: t('dashboardSidebar.employees'),
        icon: employeesIcon,
        width: "25px",
        height: "20px",
      },
    ];

    let sideBarDataEmployee = [
      {
        id: 1,
        title: t('dashboardSidebar.suppliers'),
        icon: suppliersIcon,
        width: "26px",
        height: "25px",
      },
      {
        id: 2,
        title: t('dashboardSidebar.customers'),
        icon: customersIcon,
        width: "25px",
        height: "19px",
      },
      
    ];

    let sideBarData2 = this.state.isEmployee ? sideBarDataEmployee :sideBarDataBusiness;
    const renderContent = (
      <Box>
        <StyledSideBarBox>
          <Hidden mdDown>
            <Box style={webStyle.logoContainer}>
              <img
                style={webStyle.lamsaLogo}
                src={lamsaLogo}
                alt="Logo"
                onClick={this.handleNavigateDashboard}
              />
            </Box>
          </Hidden>
        </StyledSideBarBox>

        <Box>
          <List disablePadding style={{padding: 1}}>
            <StyledHeadingBox>
              <StyledTypography component={'div'}>
                {this.state.isEmployee ? t('dashboardSidebar.employee') : t('dashboardSidebar.bo')}
              </StyledTypography>
              <StyledCloseIcon src={close} alt="" onClick={this.handleCloseDrawer} />
            </StyledHeadingBox>
            <StyledSearchContainer>
              <StyledSearchBox>
                <img style={webStyle.searchIcon} src={searchIcon} alt="" />
                <StyledTextField
                  variant="outlined"
                  placeholder={t('dashboardSidebar.search')}
                />
                <FaqsIconButton
                  onClick={this.handleNavigateFAQs}
                >
                  <img style={webStyle.questionMarkIcon} src={questionMarkIcon} alt="Icon" />
                </FaqsIconButton>
              </StyledSearchBox>
            </StyledSearchContainer>
            <StyledTabs
              data-test-id="handleTabValueChange"
              data-testid={"handleTabValueChange"}
              orientation="vertical"
              variant="scrollable"
              value={this.state.create ? 0 : this.props.active}
              onChange={this.handleTabValueChange}
              aria-label="Vertical tabs example"
            >
              {sideBarData.map((item: any) => {
                return (
                  <StyledTab
                   ref={this.props.active === item.id ?this.tabRef: null}
                    data-test-id="handleClickCreate"
                    data-testid={`handleClickCreate`} 
                    icon={
                      <Box style={webStyle.tabIconStyle}>
                        {renderIcon(item, this.props.active, this.state.create)}
                      </Box>
                    }
                    label={item.title}
                    key={item.id}
                    onClick={() => this.handleClickCreate(item.id)}
                  />
                );
              })}
            </StyledTabs>

            <div style={webStyle.divider}></div>

            <StyledTabs
              data-test-id="handleTabValueChange1"
              orientation="vertical"
              variant="scrollable"
              value={this.props.active1}
              onChange={this.handleTabValueChange3}
              aria-label="Vertical tabs example"
            >
              {sideBarData2.map((item: any) => {
                 const onStyle = this.getSidebarImageStyleTab2(item)
                return (
                  <StyledTab
                  ref={(this.props.active1 === item.id -1)?this.tabRef: null}
                    icon={
                      <Box style={webStyle.tabIconStyle}>
                        <img style={{...onStyle}} src={item.icon} alt="" />
                      </Box>
                    }
                    label={item.title}
                    key={item.id}
                  />
                );
              })}
            </StyledTabs>

            <div style={webStyle.divider}></div>

            <StyledTabs
              data-test-id="handleTabValueChange2"
              orientation="vertical"
              variant="scrollable"
              value={this.props.active2}
              onChange={this.handleTabValueChange2}
              aria-label="Vertical tabs example"
            >
              {sideBarData3.map((item: any) => {
                const onStyle = this.getSidebarImageStyle(item)
                return (
                  <StyledTab
                    icon={
                      <Box style={webStyle.tabIconStyle}>
                        <img
                        style={{...onStyle}}
                         src={item.icon} alt="" />
                      </Box>
                    }
                    ref={(this.props.active2 === item.id-1) ?this.tabRef: null}
                    label={item.title}
                    key={item.id}
                  />
                );
              })}
            </StyledTabs>
          </List>
        </Box>
      </Box>
    );
    window.addEventListener('resize', this.showCreatePopup);
    return (
      <>
        <DashboardNavBar width={undefined} {...this.props} navMenu={this.navMenu} navMenuItemProps={this.props.navMenuItem} />
        <Box style={webStyle.drawerContainer}>
          <Hidden mdDown>
            <StyledDrawer
              open
              anchor={languageSelected === "ar" ? "right" : "left"}
              variant="permanent"
              PaperProps={{
                style: {
                  width: DRAWER_WIDTH,
                  backgroundColor: 'background.default',
                  borderRightStyle: 'solid',
                  borderRight: `1px solid ${CONSTANTS.color.paleGrey}`,
                  paddingLeft: languageSelected === "ar" ? "2px" : "",
                  paddingBottom: "4rem"
                }
              }}
            >
              {renderContent}
            </StyledDrawer>
          </Hidden>
          <Modal
            data-test-id="handleClickAwayCreate"
            open={this.state.create}
            onClose={this.handleClickAwayCreate}
          >
            <>
              <StyledImg
                style={
                  this.state.languageSelected === "ar" ?
                  {...webStyle.dialogBoxIcon, transform: "translate(-72px, 234px)"} :
                  webStyle.dialogBoxIcon
                }
                src={dialogBoxIcon}
                alt=""
              />
              <StyledBox
                style={
                  this.state.languageSelected === "ar" ?
                  {...webStyle.createPopup, transform: "translate(-2%, 207%)"} :
                  webStyle.createPopup
                }
              >
                {mockCreateData.map((item) => (
                  <StyledButton
                    data-test-id="handleNavigateCreateItems"
                    style={buttonStyle(item, this.state.selectedId)}
                    onClick={() => this.handleNavigateCreateItems(item.id)}
                  >
                    <img
                      style={createPopupIconStyle(item, this.state.selectedId)}
                      src={item.icon}
                      alt=""
                    />
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      style={createPopupTextStyle(item, this.state.selectedId)}
                    >
                      {item.title}
                    </Typography>
                  </StyledButton>
                ))}
              </StyledBox>
            </>
          </Modal>
          <Hidden lgUp>
            <StyledDrawer
              open={this.state.open}
              anchor={languageSelected === "ar" ? "right" : "left"}
              onClose={this.handleCloseDrawer}
              PaperProps={{
                style: {
                  width: DRAWER_WIDTH,
                  paddingBottom: "4rem",
                  paddingLeft: languageSelected === "ar" ? "2px" : ""
                }
              }}
            >
              {renderContent}
            </StyledDrawer>
          </Hidden>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  logoContainer: {
    marginLeft: "36px",
    marginTop: "3rem"
  },
  lamsaLogo: {
    cursor: "pointer",
    height: "40px",
    width: "125px"
  },
  dialogBoxIcon: { 
    height: "9px",
    width: "20px",
    position: "fixed" as const,
    transform: "translate(88px, 234px)",
    zIndex: 10000,
  },
  createPopup: {
    width: "100%",
    maxWidth: "813px",
    minHeight: "117px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    position: "fixed" as const,
    transform: "translate(2%, 207%)",
    zIndex: 10000,
    display: "flex",
    alignItems: "center",
    gap: "6px",
    padding: "0 1rem",
  },
  createItem: {
    width: '100%',
    maxWidth: "151px",
    minHeight: "87px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    padding: "0.7rem",
    cursor: "pointer"
  },
  createText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textTransform: "capitalize" as const,
  },
  divider: {
    height: "1px",
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
    margin: "2rem"
  },
  tabIconStyle: {
    minWidth: "25px",
    marginBottom: "-5px"
  },
  drawerContainer: {
    zIndex: 1001
  },
  searchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 -2.4rem 0 1.3rem",
  },
  questionMarkIcon: {
    width: "20px",
    height: "20px",
  },
}

const dashboardSideBarSnack = withSnackbar(DashboardSideBar)
export default withTranslation()(withPermission(dashboardSideBarSnack));
// Customizable Area End