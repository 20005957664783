export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const dropDownSelectIcon = require("../assets/dropDownSelectIcon.png");
export const noTasksImage = require("../assets/noTasksImage.png");
export const todoIcon = require("../assets/todoIcon.png");
export const userIcon = require("../assets/userIcon.png");
export const calendarIcon = require("../assets/calendarIcon.png");
export const urgencyIcon = require("../assets/urgencyIcon.png");
export const employeeIcon = require("../assets/employeeIcon.png");
export const deleteTaskIcon = require("../assets/deleteTaskIcon.png");
export const dialogBoxIcon = require("../assets/dialogBoxIcon.png");
export const emptyImage = require("../assets/emptyImage.png");
export const completedIcon = require("../assets/completedIcon.png");
export const backIconMobile = require("../assets/backIconMobile.png");
