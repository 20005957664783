import React from "react";

// Customizable Area Start
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import {
  tickFilled,
  tickEmpty,
} from "./assets";
import {
  withStyles
} from "@material-ui/core/styles";
import { styled } from "@material-ui/styles"
import "./Style.css";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import CompareLamsaFeaturesController, {
  Props,
} from "./CompareLamsaFeaturesController.web";

// Customizable Area Start
const StyledTableHead = withStyles({
  root: {
    "& .MuiTableCell-root": {
      minWidth: "110px !important",
    }
  }
})(TableHead);
// Customizable Area End

export class CompareLamsaFeatures extends CompareLamsaFeaturesController {
  constructor(props: Props) {
    super(props);
  }

  renderTableCell = (
    <>
      <TableCell align="right" style={webStyle.tableHeadingColumn} className="tableHeading">Try Us</TableCell>
      <TableCell align="right" style={webStyle.tableHeadingColumn} className="tableHeading">Essential</TableCell>
      <TableCell align="right" style={webStyle.tableHeadingColumn} className="tableHeading">Standard</TableCell>
      <TableCell align="right" style={webStyle.tableHeadingColumn} className="tableHeading">Premium</TableCell>
    </>
  );

  tableHeadOne = (
    <TableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell style={webStyle.tableHeading} className="tableHeading">Job Management</TableCell>
        {this.renderTableCell}
      </TableRow>
    </TableHead>
  );

  tableHeadTwo = (
    <TableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell style={webStyle.tableHeading} className="tableHeading">Request & Quoting</TableCell>
        {this.renderTableCell}
      </TableRow>
    </TableHead>
  );

  tableHeadThree = (
    <TableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell style={webStyle.tableHeading} className="tableHeading">Invoices & Payments</TableCell>
        {this.renderTableCell}
      </TableRow>
    </TableHead>
  );

  tableHeadFour = (
    <TableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell style={webStyle.tableHeading} className="tableHeading">Customers</TableCell>
        {this.renderTableCell}
      </TableRow>
    </TableHead>
  );

  tableHeadFive = (
    <TableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell style={webStyle.tableHeading} className="tableHeading">Worker Management</TableCell>
        {this.renderTableCell}
      </TableRow>
    </TableHead>
  );

  tableHeadSix = (
    <TableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell style={webStyle.tableHeading} className="tableHeading">Additional Features</TableCell>
        {this.renderTableCell}
      </TableRow>
    </TableHead>
  );

  render() {
    // Customizable Area Start
    const { t } = this.props
    const tableHeading = () => {
      return this.state.languageSelected === "ar" ? {...webStyle.tableHeading, paddingRight: "4rem"} : webStyle.tableHeading
    }
    
    const renderTableCell = (
      <>
        <TableCell align="right">
          <Typography style={webStyle.tableHeadingColumn} variant="body2">
            {t("landingPage.Pricing.tryUs")}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography style={webStyle.tableHeadingColumn} variant="body2">
            {t("landingPage.Pricing.essential")}
          </Typography>
        </TableCell>
        <TableCell align="right" >
          <Typography style={webStyle.tableHeadingColumn} variant="body2">
            {t("landingPage.Pricing.standard")}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography style={webStyle.tableHeadingColumn} variant="body2">
            {t("landingPage.Pricing.premium")}
          </Typography>
        </TableCell>
      </>
    );
  
    const tableHeadOne = (
      <StyledTableHead style={webStyle.tableHead}>
        <TableRow>
          <TableCell>
            <Typography style={tableHeading()} variant="body2" className="tableHeading">
              {t("landingPage.Pricing.jobManagement")}
            </Typography>
          </TableCell>
          {renderTableCell}
        </TableRow>
      </StyledTableHead>
    );
  
    const tableHeadTwo = (
      <StyledTableHead style={webStyle.tableHead}>
        <TableRow>
          <TableCell>
            <Typography style={tableHeading()} variant="body2" className="tableHeading">
              {t("landingPage.Pricing.request&Quoting")}
            </Typography>
          </TableCell>
          {renderTableCell}
        </TableRow>
      </StyledTableHead>
    );
  
    const tableHeadThree = (
      <StyledTableHead style={webStyle.tableHead}>
        <TableRow>
          <TableCell>
            <Typography style={tableHeading()} variant="body2" className="tableHeading">
              {t("landingPage.Pricing.invoices&Payments")}
            </Typography>
          </TableCell>
          {renderTableCell}
        </TableRow>
      </StyledTableHead>
    );
  
    const tableHeadFour = (
      <StyledTableHead style={webStyle.tableHead}>
        <TableRow>
          <TableCell>
            <Typography style={tableHeading()} variant="body2" className="tableHeading">
              {t("landingPage.Pricing.customers")}
            </Typography>
          </TableCell>
          {renderTableCell}
        </TableRow>
      </StyledTableHead>
    );
  
    const tableHeadFive = (
      <StyledTableHead style={webStyle.tableHead}>
        <TableRow>
          <TableCell>
            <Typography style={tableHeading()} variant="body2" className="tableHeading">
              {t("landingPage.Pricing.workerManagement")}
            </Typography>
          </TableCell>
          {renderTableCell}
        </TableRow>
      </StyledTableHead>
    );
  
    const tableHeadSix = (
      <StyledTableHead style={webStyle.tableHead}>
        <TableRow>
          <TableCell>
            <Typography style={tableHeading()} variant="body2" className="tableHeading">
              {t("landingPage.Pricing.additionalFeatures")}
            </Typography>
          </TableCell>
          {renderTableCell}
        </TableRow>
      </StyledTableHead>
    );
    
    const tableRow = (row: any) => (
      <TableRow key={row?.id}>
        <TableCell align="center" scope="row" style={this.state.languageSelected === "ar" ? {...webStyle.tableHeadingRow, padding: "2rem 4rem 2rem 0rem"} : webStyle.tableHeadingRow} className="tableHeading">
          {row?.feature_name}
        </TableCell>
        <TableCell align="center"><img style={webStyle.checkIcon} className="checkIcon" src={row?.try_us === true ? tickFilled : tickEmpty} alt="" /></TableCell>
        <TableCell align="center"><img style={webStyle.checkIcon} className="checkIcon" src={row?.essential === true ? tickFilled : tickEmpty} alt="" /></TableCell>
        <TableCell align="center"><img style={webStyle.checkIcon} className="checkIcon" src={row?.standard === true ? tickFilled : tickEmpty} alt="" /></TableCell>
        <TableCell align="center"><img style={webStyle.checkIcon} className="checkIcon" src={row?.premium === true ? tickFilled : tickEmpty} alt="" /></TableCell>
      </TableRow>
    );
    
    return (
      <>
        <Grid container style={webStyle.container}>
          <Grid item lg={12} md={12} xs={12}>
            <Typography
              style={webStyle.headingText}
              className="headingText"
              variant="h2"
            >
              {t("landingPage.Pricing.compareLamsaFeatures")}
            </Typography>
          </Grid>
          <TableContainerDiv >
            <Paper>
            <Table style={{minWidth: 650}}>
              {tableHeadOne}
              <TableBody style={webStyle.tableBody}>
                {this.state.lamsaFeaturesData && this.state.lamsaFeaturesData[0]?.job_management?.map((row: any) => (
                  tableRow(row)
                  ))}
              </TableBody>
            </Table>
            </Paper>
          </TableContainerDiv>
          <TableContainerDiv style={webStyle.marginStyle} >
            <Paper>
            <Table style={{minWidth: 650}}>
              {tableHeadTwo}
              <TableBody style={webStyle.tableBody}>
                {this.state.lamsaFeaturesData && this.state.lamsaFeaturesData[1]?.request_quoting?.map((row: any) => (
                  tableRow(row)
                  ))}
              </TableBody>
            </Table>
            </Paper>
          </TableContainerDiv>
          <TableContainerDiv style={webStyle.marginStyle} >
            <Paper>
            <Table style={{minWidth: 650}}>
              {tableHeadThree}
              <TableBody style={webStyle.tableBody}>
                {this.state.lamsaFeaturesData && this.state.lamsaFeaturesData[2]?.invoices_payments?.map((row: any) => (
                  tableRow(row)
                  ))}
              </TableBody>
            </Table>
            </Paper>
          </TableContainerDiv>
          <TableContainerDiv style={webStyle.marginStyle} >
            <Paper>
            <Table style={{minWidth: 650}}>
              {tableHeadFour}
              <TableBody style={webStyle.tableBody}>
                {this.state.lamsaFeaturesData && this.state.lamsaFeaturesData[3]?.customers?.map((row: any) => (
                  tableRow(row)
                  ))}
              </TableBody>
            </Table>
              </Paper>
          </TableContainerDiv>
          <TableContainerDiv style={webStyle.marginStyle} >
            <Paper>
            <Table style={{minWidth: 650}}>
              {tableHeadFive}
              <TableBody style={webStyle.tableBody}>
                {this.state.lamsaFeaturesData && this.state.lamsaFeaturesData[4]?.worker_management?.map((row: any) => (
                  tableRow(row)
                  ))}
              </TableBody>
            </Table>
             </Paper>
          </TableContainerDiv>
          <TableContainerDiv style={webStyle.marginStyle} >
         <Paper>
            <Table style={{minWidth: 650}}>
              {tableHeadSix}
              <TableBody style={webStyle.tableBody}>
                {this.state.lamsaFeaturesData && this.state.lamsaFeaturesData[5]?.additional_features?.map((row: any) => (
                  tableRow(row)
                ))}
              </TableBody>
            </Table>
         </Paper>
          </TableContainerDiv>
        </Grid>
        <LoadingBackdrop
          loading={this.state.compareLamsaFeaturesLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const TableContainerDiv = styled(TableContainer)({
  "@media(max-width:700px)": {
    scrollbarWidth: "thin"
  }
})

const webStyle = {
  container: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headingText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    marginBottom: "3rem",
    marginTop: "1rem"
  },
  tableHead: {
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.airyCloudBlue,
  },
  tableBody: {
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
  },
  tableHeading: {
    color: CONSTANTS.color.darkBlue,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
    textAlign: "start" as const,
    paddingLeft: "4rem",
  },
  tableHeadingColumn: {
    color: CONSTANTS.color.lightGrey,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
  },
  tableHeadingRow: {
    color: CONSTANTS.color.midGrey,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    lineHeight: "24px",
    padding: "2rem 0 2rem 0",
    width: "336px",
    textAlign: "start" as const,
    paddingLeft: "4rem",
  },
  checkIcon: {
    width: "24px",
    height: "24px",
  },
  marginStyle: {
    marginTop: "3rem"
  }
}

export default withTranslation()(CompareLamsaFeatures);
// Customizable Area End