import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  Container,
} from "@material-ui/core";
import {
  bg1,
  bg2,
  bg3,
  bg4,
  muchMoreIcon,
} from "./assets";
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import NavBar from "./NavBar.web";
import Footer from "./Footer.web";
import "./WhyLamsa.css";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
// Customizable Area End

import WhyLamsaController, {
  Props,
} from "./WhyLamsaController.web";

// Customizable Area Start
const bgImage = (languageSelected: string) => {
  return {...webStyle.bg1, transform: languageSelected === "ar" ? "translate(-9rem, -2rem)" : "translate(-9rem, 1rem)"}
}

const bgImageDesign = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.bg3, transform: "translate(-36rem, 26rem)"} : webStyle.bg3
}

const bgImageIndustries = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.gridBoxesIndustries, marginBottom: "9rem"} : webStyle.gridBoxesIndustries
}

const stepsContainerStyle = (classes: ClassNameMap, languageSelected: string) => {
  return languageSelected === "ar" ? classes.stepsContainerAr : classes.stepsContainer
}
// Customizable Area End

export class WhyLamsa extends WhyLamsaController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const mockData = [
      {
        id: 1,
        icon: this.state.whyLamsaData[1]?.designed?.data[0]?.attributes?.image,
        text: this.state.whyLamsaData[1]?.designed?.data[0]?.attributes?.discription,
        innerText: this.state.whyLamsaData[1]?.designed?.data[0]?.attributes?.sub_discription,
        width: "99px",
        height: "99px",
      },
      {
        id: 2,
        icon: this.state.whyLamsaData[1]?.designed?.data[1]?.attributes?.image,
        text: this.state.whyLamsaData[1]?.designed?.data[1]?.attributes?.discription,
        innerText: this.state.whyLamsaData[1]?.designed?.data[1]?.attributes?.sub_discription,
        width: "106px",
        height: "106px",
      },
      {
        id: 3,
        icon: this.state.whyLamsaData[1]?.designed?.data[2]?.attributes?.image,
        text: this.state.whyLamsaData[1]?.designed?.data[2]?.attributes?.discription,
        innerText: this.state.whyLamsaData[1]?.designed?.data[2]?.attributes?.sub_discription,
        width: "101px",
        height: "101px",
      },
      {
        id: 4,
        icon: this.state.whyLamsaData[1]?.designed?.data[3]?.attributes?.image,
        text: this.state.whyLamsaData[1]?.designed?.data[3]?.attributes?.discription,
        innerText: this.state.whyLamsaData[1]?.designed?.data[3]?.attributes?.sub_discription,
        width: "76px",
        height: "102px",
      },
    ]
    const industriesMockData = [
      {
        id: 1,
        icon: this.state.whyLamsaData[4]?.industries?.data[0]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[0]?.attributes?.discription,
        width: "92px",
        height: "71px"
      },
      {
        id: 2,
        icon: this.state.whyLamsaData[4]?.industries?.data[1]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[1]?.attributes?.discription,
        width: "74px",
        height: "74px"
      },
      {
        id: 3,
        icon: this.state.whyLamsaData[4]?.industries?.data[2]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[2]?.attributes?.discription,
        width: "72px",
        height: "72px"
      },
      {
        id: 4,
        icon: this.state.whyLamsaData[4]?.industries?.data[3]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[3]?.attributes?.discription,
        width: "70px",
        height: "70px"
      },
      {
        id: 5,
        icon: this.state.whyLamsaData[4]?.industries?.data[4]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[4]?.attributes?.discription,
        width: "75px",
        height: "66px"
      },
      {
        id: 6,
        icon: this.state.whyLamsaData[4]?.industries?.data[5]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[5]?.attributes?.discription,
        width: "72px",
        height: "72px"
      },
      {
        id: 7,
        icon: this.state.whyLamsaData[4]?.industries?.data[6]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[6]?.attributes?.discription,
        width: "72px",
        height: "72px"
      },
      {
        id: 8,
        icon: this.state.whyLamsaData[4]?.industries?.data[7]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[7]?.attributes?.discription,
        width: "67px",
        height: "67px"
      },
      {
        id: 9,
        icon: this.state.whyLamsaData[4]?.industries?.data[8]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[8]?.attributes?.discription,
        width: "74px",
        height: "74px"
      },
      {
        id: 10,
        icon: this.state.whyLamsaData[4]?.industries?.data[9]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[9]?.attributes?.discription,
        width: "71px",
        height: "71px"
      },
      {
        id: 11,
        icon: this.state.whyLamsaData[4]?.industries?.data[10]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[10]?.attributes?.discription,
        width: "72px",
        height: "72px"
      },
      {
        id: 12,
        icon: this.state.whyLamsaData[4]?.industries?.data[11]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[11]?.attributes?.discription,
        width: "74px",
        height: "74px"
      },
      {
        id: 13,
        icon: this.state.whyLamsaData[4]?.industries?.data[12]?.attributes?.image,
        title: this.state.whyLamsaData[4]?.industries?.data[12]?.attributes?.discription,
        width: "84px",
        height: "71px"
      },
      {
        id: 14,
        icon: muchMoreIcon,
        title: t("landingPage.WhyLamsa.muchMore"),
        width: "46px",
        height: "25px"
      },
    ]
    const { classes }: any = this.props;
    return (
      <>
        <NavBar {...this.props} active={1} homePage={this.whyLamsaPage} oldProps={this} />
        <Box
          style={webStyle.boxContainer1}
          className={classes.boxContainer1}
        >
          <Container maxWidth={"lg"}>
            <Grid container spacing={2} className={classes.columnReverse}>
              <Grid item lg={6} md={6} xs={12} className="whyLamsaGridleftContent">
                <Grid container>
                  <Grid item lg={10} md={10} xs={12}>
                    {!this.state.whyLamsaLoading &&
                      <Typography
                        style={webStyle.heroHeader}
                        className={classes.heroHeader}
                        variant="h3"
                        dangerouslySetInnerHTML={{
                          __html: (
                            `${this.state.whyLamsaData[0]?.section_1?.data[0]?.attributes?.title}`
                          )
                        }}
                      ></Typography>
                    }
                  </Grid>
                  <Grid item lg={10} md={10} xs={12}>
                    <Button
                      className={classes.buttonContainerGetStarted}
                      onClick={this.handleGetStartedButton}
                    >
                      {!this.state.whyLamsaLoading &&
                        <Typography
                          style={webStyle.buttonTextGetStarted}
                          className={classes.buttonTextGetStarted}
                          variant="caption"
                          dangerouslySetInnerHTML={{
                            __html: (
                              `${this.state.whyLamsaData[0]?.section_1?.data[0]?.attributes?.button_text}`
                            )
                          }}
                        ></Typography>
                      }
                    </Button>
                  </Grid>
                  <Grid item lg={10} md={10} xs={12}>
                    {!this.state.whyLamsaLoading &&
                      <Typography
                        style={webStyle.tryLamsa}
                        className={classes.tryLamsa}
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: (
                            `${this.state.whyLamsaData[0]?.section_1?.data[0]?.attributes?.discription}`
                          )
                        }}
                      ></Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Grid container className="whyLamsaGridRightContent">
                  <Grid item lg={11} md={11} xs={12} className={classes.rightContent}>
                    <img
                      style={webStyle.whyLamsaHeroImage}
                      className={classes.whyLamsaHeroImage}
                      src={this.state.whyLamsaData[0]?.section_1?.data[0]?.attributes?.image}
                      alt="Image"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer2}
          className={classes.boxContainer2}
        >
          <Container maxWidth={"lg"}>
            <Box style={webStyle.flexDirectionColumn}>
              <div style={webStyle.relativeStyle}>
                <img style={webStyle.bg1} className={classes.bg1} src={bg1} alt="" />
              </div>
              <Grid item lg={12} md={12} xs={12}>
                {!this.state.whyLamsaLoading &&
                  <Typography
                    style={webStyle.designText}
                    className={classes.designText}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.whyLamsaData[1]?.designed?.data[0]?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                }
              </Grid>
              <div style={webStyle.relativeStyle}>
                <img style={webStyle.bg4} className={classes.bg4} src={bg4} alt="" />
              </div>
              <div style={webStyle.relativeStyle}>
                <img style={webStyle.bg2} className={classes.bg2} src={bg2} alt="" />
              </div>
              <div style={webStyle.relativeStyle}>
                <img style={bgImageDesign(this.state.languageSelected)} className={classes.bg3} src={bg3} alt="" />
              </div>
              <div style={webStyle.relativeStyle}>
                <img style={webStyle.bg2nd} className={classes.bg2nd} src={bg2} alt="" />
              </div>
              <Grid container style={webStyle.gridContainer} spacing={4} justifyContent="center">
                {mockData?.map((item) => (
                  <Grid item lg={3} md={4} xs={6} key={item.id} style={webStyle.gridItem} className={`${item.id === 4 ? classes.invoiceStyle : classes.gridItem}`}>
                    <Box style={webStyle.gridBoxes} className={classes.gridBoxes}>
                      <Box style={webStyle.gridBoxContainer}>
                        <img
                          style={{width: item?.width, height: item?.height}}
                          className={`${item.id === 4 ? classes.invoice : classes.icons}`}
                          src={item?.icon}
                          alt="Image"
                        />
                      </Box>
                      {!this.state.whyLamsaLoading &&
                        <Typography
                          style={{...webStyle.innerText1, fontWeight: CONSTANTS.fontWeight.fontWeight400}}
                          className={classes.innerText1}
                          variant="subtitle2"
                          dangerouslySetInnerHTML={{
                            __html: (
                              `${item.text}`
                            )
                          }}
                        ></Typography>
                      }
                      {!this.state.whyLamsaLoading &&
                        <Typography
                          style={webStyle.innerText2}
                          variant="h5"
                          dangerouslySetInnerHTML={{
                            __html: (
                              `${item?.innerText}`
                            )
                          }}
                        ></Typography>
                      }
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer3}
          className={classes.boxContainer3}
        >
          <Container maxWidth={"lg"}>
            <Box style={webStyle.flexDirectionColumn}>
              <Grid item lg={12} md={12} xs={12}>
                {!this.state.whyLamsaLoading &&
                  <Typography
                    style={webStyle.getMoreText}
                    className={classes.getMoreText}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.whyLamsaData[2]?.get_more?.data[0]?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                }
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {!this.state.whyLamsaLoading &&
                  <Typography
                    style={webStyle.subText}
                    className={classes.subText}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.whyLamsaData[2]?.get_more?.data[0]?.attributes?.discription}`
                      )
                    }}
                  ></Typography>
                }
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <img
                  style={webStyle.whyLamsaCalendarImage}
                  src={this.state.whyLamsaData[2]?.get_more?.data[0]?.attributes?.image}
                  alt="Image"
                />
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer4}
          className={classes.boxContainer4}
        >
          <Container maxWidth={"lg"}>
            {!this.state.whyLamsaLoading &&
              <Box style={webStyle.flexDirectionColumn}>
                <Grid item lg={12} md={12} xs={12}>
                  <div style={webStyle.relativeStyle}>
                    <img style={{...webStyle.bg1, transform: "translate(-2rem, 10rem)"}} className={classes.bg12} src={bg1} alt="" />
                  </div>
                  <Typography
                    style={webStyle.getMoreText}
                    className={classes.getMoreText}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.whyLamsaData[3]?.operations?.data[0]?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                </Grid>
                <Typography
                  style={{...webStyle.subText, ...webStyle.subTextWidth}}
                  className={classes.subText}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: (
                      `${this.state.whyLamsaData[3]?.operations?.data[0]?.attributes?.discription}`
                    )
                  }}
                ></Typography>
                <div style={webStyle.relativeStyle}>
                  <img style={{...webStyle.bg4, transform: "translate(35rem, 7rem)"}} className={classes.bg4} src={bg4} alt="" />
                </div>
                <div style={webStyle.relativeStyle}>
                  <img style={{...webStyle.bg2, transform: "translate(-38rem, 18rem)"}} className={classes.bg22} src={bg2} alt="" />
                </div>
                <div style={webStyle.relativeStyle}>
                  <img style={{...webStyle.bg3, transform: "translate(-37rem, 36rem)"}} className={classes.bg3} src={bg3} alt="" />
                </div>
                <div style={webStyle.relativeStyle}>
                  <img style={{...webStyle.bg2nd, transform: "translate(36rem, 37rem)"}} className={classes.bg2nd} src={bg2} alt="" />
                </div>
                <Grid item lg={12} md={12} xs={12}>
                  <img
                    style={webStyle.whyLamsaDashboardImage}
                    src={this.state.whyLamsaData[3]?.operations?.data[0]?.attributes?.image}
                    alt="Image"
                  />
                </Grid>
              </Box>
            }
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer3}
          className={classes.boxContainer5}
        >
          <Container maxWidth={"lg"}>
            <Box style={webStyle.flexDirectionColumn}>
              <Grid item lg={12} md={12} xs={12}>
                {!this.state.whyLamsaLoading &&
                  <Typography
                    style={webStyle.industriesText}
                    className={classes.industriesText}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.whyLamsaData[4]?.industries?.data[0]?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                }
              </Grid>
              <Grid container style={webStyle.gridContainer} className={classes.gridContainer} spacing={2}>
                {industriesMockData.map((item) => (
                  <Grid item key={item.id}>
                    <Box
                      style={bgImageIndustries(this.state.languageSelected)}
                        className={classes.gridBoxesIndustries}
                      >
                      <Box style={webStyle.gridBoxContainer}>
                        <img
                          style={{width: item.width, height: item.height}}
                          src={item.icon}
                          alt="Image"
                        />
                      </Box>
                      {!this.state.whyLamsaLoading &&
                        <Typography
                          style={webStyle.industriesItemText}
                          className={classes.industriesItemText}
                          variant="subtitle1"
                          dangerouslySetInnerHTML={{
                            __html: (
                              `${item.title}`
                            )
                          }}
                        ></Typography>
                      }
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer5}
          className={classes.boxContainer5}
        >
          <Container maxWidth={"lg"}>
            <Box style={webStyle.flexDirectionColumn}>
              {!this.state.whyLamsaLoading &&
                <Grid item lg={12} md={12} xs={12}>
                  <div style={webStyle.relativeStyle}>
                    <img style={bgImage(this.state.languageSelected)} className={classes.bg12} src={bg1} alt="" />
                  </div>
                  <Typography
                    style={webStyle.howToGetStarted}
                    className={classes.howToGetStarted}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.whyLamsaData[5]?.get_started?.data[0]?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                  <Typography
                    style={webStyle.howToGetStartedSubText}
                    className={classes.howToGetStartedSubText}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.whyLamsaData[5]?.get_started?.data[0]?.attributes?.discription}`
                      )
                    }}
                  ></Typography>
                  <div style={webStyle.relativeStyle}>
                    <img style={{...webStyle.bg4, transform: "translate(47rem, 15px)"}} className={classes.bg4} src={bg4} alt="" />
                  </div>
                  <div style={webStyle.relativeStyle}>
                    <img style={{...webStyle.bg2nd, transform: "translate(-23rem, 12rem)"}} className={classes.bg2nd} src={bg2} alt="" />
                  </div>
                  <div style={webStyle.relativeStyle}>
                    <img style={{...webStyle.bg3, transform: "translate(-19rem, 30rem)"}} className={classes.bg3} src={bg3} alt="" />
                  </div>
                </Grid>
              }
              <Grid container spacing={4} style={webStyle.gridLastContainer} className={classes.gridLastContainer}>
                {!this.state.whyLamsaLoading &&
                  <Grid item lg={6} md={6} xs={12} className={classes.gridItemContainer}>
                    <Typography
                      style={webStyle.stepsText}
                      className={classes.stepsText}
                      variant="subtitle1"
                      dangerouslySetInnerHTML={{
                        __html: (
                          `${this.state.whyLamsaData[6]?.start_now?.data[0]?.attributes?.title}`
                        )
                      }}
                    ></Typography>
                    <ol style={webStyle.stepsContainer} className={stepsContainerStyle(classes, this.state.languageSelected)}>
                      {this.state.whyLamsaData[6]?.start_now?.data?.map((item: any) => (
                        <Typography
                          key={item.id}
                          component={"li"}
                          style={webStyle.stepsSubText}
                          className={classes.stepsSubText}
                          variant="subtitle1"
                          dangerouslySetInnerHTML={{
                            __html: (
                              `${item?.attributes?.discription}`
                            )
                          }}
                        ></Typography>
                      ))}
                    </ol>
                    <Button
                      className={classes.buttonContainerStartNow}
                      onClick={this.handleGetStartedButton}
                    >
                      <Typography
                        style={webStyle.buttonTextStartNow}
                        className={classes.buttonTextStartNow}
                        variant="caption"
                        dangerouslySetInnerHTML={{
                          __html: (
                            `${this.state.whyLamsaData[5]?.get_started?.data[0]?.attributes?.button_text}`
                          )
                        }}
                      ></Typography>
                    </Button>
                  </Grid>
                }
                <Grid item lg={6} md={6} xs={12} className={classes.gridSignUpContainer}>
                  <img
                    style={webStyle.whyLamsaSignupImage}
                    src={this.state.whyLamsaData[5]?.get_started?.data[0]?.attributes?.image}
                    alt="Image"
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Footer {...this.props} active={1} homePage={this.whyLamsaPage} oldProps={this} />
        <LoadingBackdrop
          loading={this.state.whyLamsaLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainer1: {
    [theme.breakpoints.down("sm")]: {
      height: "auto !important",
      marginTop: "8rem !important",
      padding: "0px 0.5rem !important"
    }
  },
  heroHeader: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "1rem !important",
      lineHeight: "30px !important",
      fontSize: "24px !important",
      fontWeight: `${CONSTANTS.fontWeight.fontWeight800} !important`,
    }
  },
  tryLamsa: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important"
    }
  },
  columnReverse: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column-reverse !important",
    }
  },
  rightContent: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start !important",
    }
  },
  whyLamsaHeroImage: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem !important",
    }
  },
  boxContainer2: {
    [theme.breakpoints.down("md")]: {
      padding: "8rem 0rem 10rem 0rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem !important",
      padding: "2rem 0.5rem 2rem 0.5rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem !important",
    },
  },
  bg1: {
    [theme.breakpoints.down("sm")]: {
      height: "20px !important",
      width: "22px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(-10rem, -15px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg12: {
    [theme.breakpoints.down("sm")]: {
      height: "20px !important",
      width: "22px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(1rem, -15px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg2: {
    [theme.breakpoints.down("md")]: {
      height: "17px !important",
      width: "17px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(-10rem, 6px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg22: {
    [theme.breakpoints.down("md")]: {
      height: "17px !important",
      width: "17px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(-10rem, 27px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg3: {
    [theme.breakpoints.down("md")]: {
      display: "none !important"
    }
  },
  bg2nd: {
    [theme.breakpoints.down("md")]: {
      display: "none !important"
    }
  },
  innerText1: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
      lineHeight: "18px !important"
    }
  },
  gridItem: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "7rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "6rem !important",
    }
  },
  invoiceStyle: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem !important",
      marginBottom: "7rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "6rem !important",
    },
  },
  bg4: {
    [theme.breakpoints.down("md")]: {
      width: "18px !important",
      height: "20px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(8rem, 30px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  icons: {
    [theme.breakpoints.down("xs")]: {
      width: "79px !important",
      height: "79px !important",
    }
  },
  invoice: {
    [theme.breakpoints.down("xs")]: {
      width: "59px !important",
      height: "79px !important",
    }
  },
  gridBoxes: {
    [theme.breakpoints.down("xs")]: {
      width: "165px !important",
      height: "165px !important",
    }
  },
  getMoreText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
      lineHeight: "35px !important",
    }
  },
  flexDirectionColumn: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start !important",
    }
  },
  subText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "30px !important",
    }
  },
  designText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
      lineHeight: "32px !important",
    }
  },
  boxContainer3: {
    [theme.breakpoints.down("sm")]: {
      padding: "3rem 0.5rem !important",
    },
  },
  boxContainer4: {
    [theme.breakpoints.down("sm")]: {
      padding: "6rem 0.5rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0.5rem !important",
    },
  },
  boxContainer5: {
    [theme.breakpoints.down("sm")]: {
      padding: "4rem 0.5rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 0.5rem !important",
      marginBottom: "1rem !important",
      marginTop: "3rem !important",
    },
  },
  howToGetStarted: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: "29px !important",
      fontSize: "24px !important",
    },
  },
  howToGetStartedSubText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "26px !important",
    },
  },
  industriesText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
      lineHeight: "29px !important",
    },
  },
  stepsText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "24px !important",
    },
  },
  gridLastContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem !important",
    },
  },
  gridItemContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      flexDirection: "column !important",
      justifyContent: "center !important",
      alignItems: "center !important",
    },
  },
  stepsSubText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "24px !important",
    },
  },
  buttonContainerStartNow: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important",
      maxWidth: "100% !important",
    },
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.thickBlue,
    borderRadius: "10px",
    minHeight: "56px",
    maxWidth: "166px",
    width: "100%",
    marginTop: "2.5rem"
  },
  buttonTextStartNow: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    }
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem !important",
    },
  },
  gridBoxesIndustries: {
    [theme.breakpoints.down("xs")]: {
      width: "146px !important",
      height: "146px !important",
      marginBottom: "1rem !important",
      marginTop: "2.5rem !important",
    },
  },
  industriesItemText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px !important",
      lineHeight: "26px !important",
    }
  },
  gridSignUpContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      justifyContent: "center !important",
      marginTop: "3rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem !important",
    },
  },
  stepsContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "2rem !important",
    },
  },
  stepsContainerAr: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0rem !important",
      paddingRight: "2rem !important",
    },
  },
  buttonContainerGetStarted: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important",
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.thickBlue,
    borderRadius: "10px",
    minHeight: "56px",
    width: "100%",
    marginTop: "1.5rem",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      cursor: "pointer",
    },
  },
  buttonTextGetStarted: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },
});

const webStyle = {
  boxContainer1: {
    marginTop: "11rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 2rem"
  },
  heroHeader: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "45px"
  },
  buttonTextGetStarted: {
    color: CONSTANTS.color.white,
    fontFamily: 'Poppins',
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "none" as const
  },
  tryLamsa: {
    marginTop: "1rem",
    color: CONSTANTS.color.thickBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "26px",
    opacity: 0.7,
    width: "100%"
  },
  whyLamsaHeroImage: {
    maxWidth: "585px",
    width: "100%",
    height: "auto"
  },
  boxContainer2: {
    backgroundColor: CONSTANTS.color.paleLavendar,
    marginTop: "6rem",
    padding: "8rem 0rem 16rem 0rem"
  },
  boxContainer3: {
    padding: "6rem 2rem"
  },
  boxContainer4: {
    backgroundColor: CONSTANTS.color.paleLavendar,
    padding: "6rem 2rem"
  },
  boxContainer5: {
    backgroundColor: CONSTANTS.color.paleLavendar,
    padding: "6rem 2rem",
    marginBottom: "6rem"
  },
  flexDirectionColumn: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  relativeStyle: {
    position: "relative" as const,
  },
  bg1: {
    width: "27px",
    height: "25px",
    position: "absolute" as const,
    transform: "translate(-27rem, -40px)",
  },
  bg2: {
    width: "17px",
    height: "17px",
    position: "absolute" as const,
    transform: "translate(-38rem, 10rem)",
  },
  bg2nd: {
    width: "17px",
    height: "17px",
    position: "absolute" as const,
    transform: "translate(37rem, 27rem)",
  },
  bg3: {
    width: "33px",
    height: "34px",
    position: "absolute" as const,
    transform: "translate(-38rem, 26rem)",
  },
  bg4: {
    width: "33px",
    height: "34px",
    position: "absolute" as const,
    transform: "translate(36rem, -40px)",
  },
  designText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
    maxWidth: "820px",
    width: "100%"
  },
  gridContainer: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridBoxes: {
    width: "203px",
    height: "220px",
    borderRadius: "15px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(163,177,198,0.25)",
  },
  gridBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  innerText1: {
    textAlign: "center" as const,
    marginTop: "1.5rem",
  },
  innerText2: {
    textAlign: "center" as const,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  dashboardImage: {
    marginTop: "3rem",
    maxWidth: "1112px",
    width: "100%",
    height: "auto"
  },
  getMoreText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    textAlign: "center" as const,
  },
  subText: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    textAlign: "center" as const,
    maxWidth: "705px",
    width: "100%",
    marginTop: "1.5rem"
  },
  subTextWidth: {
    maxWidth: "747px"
  },
  whyLamsaCalendarImage: {
    maxWidth: "1040px",
    width: "100%",
    height: "auto",
    marginTop: "3rem"
  },
  whyLamsaDashboardImage: {
    maxWidth: "1034px",
    width: "100%",
    height: "auto",
    marginTop: "4rem"
  },
  industriesText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    textAlign: "center" as const,
  },
  industriesItemText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
    marginTop: "0.5rem"
  },
  gridBoxesIndustries: {
    width: "150px",
    height: "150px",
    borderRadius: "15px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(163,177,198,0.25)",
    marginBottom: "6rem"
  },
  howToGetStarted: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    textAlign: "center" as const,
  },
  howToGetStartedSubText: {
    opacity: 0.7,
    maxWidth: "607px",
    width: "100%",
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
    marginTop: '1rem'
  },
  gridLastContainer: {
    marginTop: '3rem',
    display: "flex",
    alignItems: "center",
  },
  whyLamsaSignupImage: {
    maxWidth: "637px",
    width: "100%",
    height: "auto"
  },
  stepsText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
  },
  stepsContainer: {
    maxWidth: "600px",
    width: "100%",
    paddingLeft: "2.5rem",
    display: "flex",
    flexDirection: "column" as const,
    gap: "10px",
  },
  stepsSubText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginTop: "1.5rem"
  },
  buttonTextStartNow: {
    color: CONSTANTS.color.white,
    fontFamily: 'Poppins',
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "none" as const
  }
}

const WhyLamsaStyles = withStyles(styles)(WhyLamsa);
export default withTranslation()(WhyLamsaStyles);
// Customizable Area End