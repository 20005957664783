import React from "react";

// Customizable Area Start
import { withRouter } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Avatar,
  TextField,
  withWidth
} from "@material-ui/core";
import { withStyles , } from "@material-ui/core/styles";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { ProfileSubSideBar } from "./ProfileSubSideBar.web";
import { StyledRoot } from "./utility.web";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { EmployeeProfileStyledButton } from "./EmployeeProfile.web";
import { userIcon } from "./assets";
import { withSnackbar } from "../../../components/src/toastweb";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ProfileController, { Props } from "./ProfileController.web";

// Customizable Area Start

export const StyledTextField = withStyles((theme)=>({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      fontFamily: "Rubik",
      fontSize: "1.1667rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.0625rem !important"
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 14px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1)",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.1)",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
}))(TextField);

// Customizable Area End

export class ChangePassword extends ProfileController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes , t}: any = this.props;
    const { firstName, lastName } = this.state.formDetails;
    const getConditionalStyle = (isError: any) => {
      return isError
        ? { backgroundColor: CONSTANTS.color.lightRed, borderRadius: "10px" }
        : {};
    };
    return (
      < >
        <DashboardSideBar
          permissions={undefined}  oldProps={undefined}
          {...this.props}
          navMenuItem={this.props.t('dashboardNavbar.profile')}
          active={false}
          active1={false}
          active2={false}        />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "5rem",
            }}
            className={classes.passwordboxContainer}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.renderFormPassword}
            >
              <Grid xs style={{ paddingTop: "55px" }}>
                <ProfileSubSideBar
                  {...this.props}
                  navMenuItem={t("boProfile.profile")}
                  profileActive={1}
                
                />
              </Grid>
              <Grid
                  className={classes.mainCont}
              >
                <Container
                  maxWidth={"lg"}
                  style={{ padding: 0, }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ paddingTop: "30px" }}
                    className={classes.passwordpageTitle}
                  >
                    {t("boProfile.changePassword")}
                  </Typography>
                </Container>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 0px",
                    marginTop: "58px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={this.state.profile_image || userIcon}
                      alt="Profile"
                      className={classes.companyLogo}
                      style={{ width: "64px", height: "64px" }}
                    />

                    <Typography variant="subtitle2" className={classes.passworduserName}>
                      {firstName} {lastName}
                    </Typography>
                  </Box>

                  <Grid container justifyContent="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.passwordtextFieldTitle}
                        variant = "body1"
                        style={{ marginTop: "30px", marginBottom: "15px" }}
                      >
                        {t("boProfile.oldPassword")}
                      </Typography>

                      <StyledTextField
                        data-test-id="passwords"
                        variant="outlined"
                        autoFocus
                        fullWidth
                        name="oldPassword"
                        value={this.state.passwordData.oldPassword}
                        onChange={this.setPassword}
                        type={this.checkEnableNew(
                          this.state.enablePasswordFieldOld
                        )}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="showPassword"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPasswordOld}
                                edge="end"
                              >
                                {this.state.enablePasswordFieldOld ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.passwordtextFieldTitle}
                        variant = "body1"
                        style={{ marginBottom: "15px" }}
                      >
                         {t("boProfile.newPassword")}
                      </Typography>

                      <StyledTextField
                        style={getConditionalStyle(
                          this.state.isErrorFormData.newPassword ||
                            this.state.isErrorFormData.commonError
                        )}
                        data-test-id="passwords"
                        variant="outlined"
                        fullWidth
                        name="newPassword"
                        value={this.state.passwordData.newPassword}
                        onChange={this.setPassword}
                        helperText={this.state.isErrorFormData.newPassword}
                        type={this.checkEnableNew(
                          this.state.enablePasswordFieldNew
                        )}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="showPassword"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPasswordNew}
                                edge="end"
                              >
                                {this.state.enablePasswordFieldNew ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(
                          this.state.isErrorFormData.newPassword ||
                            this.state.isErrorFormData.commonError
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.passwordtextFieldTitle}
                        variant = "body1"
                        style={{ marginBottom: "15px" }}
                      >
                          {t("boProfile.confirmPassword")}
                      </Typography>

                      <>
                        <StyledTextField
                          data-test-id="passwords"
                          error={Boolean(
                            this.state.isErrorFormData.confirmPassword ||
                              this.state.isErrorFormData.commonError
                          )}
                          variant="outlined"
                          fullWidth
                          style={getConditionalStyle(
                            this.state.isErrorFormData.confirmPassword ||
                              this.state.isErrorFormData.commonError
                          )}
                          name="confirmPassword"
                          helperText={
                            this.state.isErrorFormData.confirmPassword ||
                            this.state.isErrorFormData.commonError
                          }
                          value={this.state.passwordData.confirmPassword}
                          onChange={this.setPassword}
                          type={this.checkEnableNew(
                            this.state.enablePasswordFieldConfirm
                          )}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="showPassword"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPasswordConfirm}
                                  edge="end"
                                >
                                  {this.state.enablePasswordFieldConfirm ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {this.state.error && (
                          <Typography variant="body1" className={classes.passworderrorMessage}>
                            {this.state.errorMessage}
                          </Typography>
                        )}
                      </>
                    </Grid>
                  </Grid>
                  {this.state.success && this.state.successMessage && (
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.passwordsuccessMessage}>
                        {this.state.successMessage}
                      </Typography>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    className={classes.renderSavePassword}
                  >
                    <Grid
                      container
                      className={classes.btnProfilePassword}
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          textAlign: "end",
                          marginTop: "1rem",
                          width: "100%",
                        }}
                      >
                        <EmployeeProfileStyledButton
                          data-test-id="btnEmailLogin"
                          onClick={this.profileResetPasswordSubmit}
                          className={classes.passwordupdateButton}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.passwordupdateButtonText}
                          >
                             {t("boProfile.update")}
                          </Typography>
                        </EmployeeProfileStyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  mainCont:{
    width: "75%",
    padding: "20px",
    borderLeft: "1px solid #e4e4e4",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  mainContPasssword:{
    width: "75%",
    padding: "20px",
    borderLeft: "1px solid #e4e4e4",
    [theme.breakpoints.down("md")]: {
      width: "73%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  renderFormPassword: {
    flexWrap: "nowrap" as const,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap" as const
    },
  },
  btnProfilePassword:{
    display:"flex",
    flexDirection:"column" as const,
    alignItems:"flex-end" as const,
    [theme.breakpoints.down("xs")]: {
      alignItems:"normal",
    },

  },
  mainContPasswordV1:{
  
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  renderSavePassword:{
    width: "100%",
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  passwordboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  passwordpageTitle: {
    width: "256px",
    height: "28px",
    color: CONSTANTS.color.lightBrown,
    fontWeight: theme.typography.fontWeightMedium,
   
  },
  passwordprofilePic: {
    width: "64px",
    height: "64px",
  },

  passworduserName: {
    height: "26px",
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
    marginLeft: "20px",
  },
  passwordupdateButton: {
    width: "200px",
    height: "67px",
    borderRadius: "10px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    textAlign: "end" as const,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  passwordupdateButtonText: {
    width: "75px",
    height: "32px",
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
  },
  passwordtextFieldTitle: {
    height: "16px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "16px",
  },
  passworderrorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
    
  },
  passwordsuccessMessage: {
    marginTop: "5px",
    color: "#0b9500",
   
  },
  ProfileSidebarboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  ChangeProfileSidebarsideBarTitle: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 0 2rem",
      marginLeft: "-0.2rem",
    },
  },

  ChangeProfileSidebarsideBarTitleAr: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 2rem 0 0",
      marginRight: "-0.2rem",
    },
  },
});

const webStyle = {};

//@ts-ignore
const changePasswordRouter = withSnackbar(withRouter(ChangePassword));
export default withTranslation()(withStyles(styles)(withWidth()(changePasswordRouter)));
// Customizable Area End
