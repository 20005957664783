//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  Tab,
  Box,
} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden"
import { withRouter } from "react-router";
import { mainLogo, firstSideImg, sidePage2, sidePage1, sidePage3, mobileArrow } from "./assets";
import { Theme } from "@material-ui/core/styles"
import { CONSTANTS } from "../../../components/src/theme";
import { StyledTabs } from "../../../components/src/CustomizableComponents.web";
import { mobileLogo } from "../../LanguageSupport/src/assets";
import { goBack } from "../../../components/src/CommonFunction";
import LoginController, { Props } from "./LoginController.web";
import LoginWeb from "./Login.web";
import RegisterWeb from "./Register.web";
import { withTranslation } from "react-i18next";
// Customizable Area End
// Customizable Area Start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Customizable Area End

export class UserAccessWeb extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
     // Customizable Area Start
    //@ts-ignore
    const { classes, t } = this.props;
    return (
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center" className={classes.imageLayout}>
          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={`${classes.bgImgLayout} ${classes.height_100vh}`} />
          </Hidden>
          <Hidden only={'xs'}>
            <Grid item xs={12} sm={1} lg={2} xl={3} className={classes.secondLogo}>
              <img style={{ width: "80px" }} src={firstSideImg} alt="firstSideImg" />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={5} md={5} lg={4} xl={4} style={{ padding: '20px' }}>
            <Grid container direction="row" spacing={2}>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Grid item xs={12} onClick={() => goBack(this.props.navigation)}>
                  <img className={classes.mobileArrow} src={mobileArrow}
                    width={12} height={24} alt="mobile arrow" />
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <img src={mainLogo} className={classes.companyLogo} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.titleHeading}>{this.props.location.pathname == "/Register"
                  ? t("BoAuth.signUpTitle")

                  : t("BoAuth.loginTitle")}

                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary" className={classes.subTitletext}>
                  {t("BoAuth.subtitle")}!
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px", padding: '0 10px' }}>
                <StyledTabs
                  value={this.state.tabvalue}
                  onChange={this.handleTabChange}
                  aria-label="simple tabs example"
                  data-test-id="tabChange"
                >
                  <Tab
                    label={t('BoAuth.login')}
                    className={
                      this.state.tabvalue === 0
                        ? classes.selectedTab
                        : classes.tabColor
                    }
                    {...a11yProps(0)}

                  />
                  <Tab
                    label={t('BoAuth.signUp')}
                    className={
                      this.state.tabvalue === 1
                        ? classes.selectedTab
                        : classes.tabColor
                    }
                    {...a11yProps(1)}
                  />
                </StyledTabs>
              </Grid>
            </Grid>
            <TabPanel to="/Login" value={this.state.tabvalue} index={0}>
              <LoginWeb {...this.props} />
            </TabPanel>
            <TabPanel to="/Register" value={this.state.tabvalue} index={1}>
              <RegisterWeb {...this.props} />
            </TabPanel>
          </Grid>
          <Grid item xs={12} sm={1} md={2} lg={3} />
          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Grid item xs={12} sm={1} lg={2} xl={3} className={classes.secondLogo}>
              <img className={classes.image2} src={firstSideImg} alt="firstSideImg" />
            </Grid>
          </Hidden>
          <Hidden only={'xs'}>
            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={`${classes.bgImgLayout} ${classes.height_100}`} />
          </Hidden>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
//@ts-ignore
const UserAccessWebRouter = withRouter(UserAccessWeb);
const UserAccessWebStyles = withStyles((theme: Theme) =>
  createStyles({
    imageLayout: {
      [theme.breakpoints.up("sm")]: {
        height: '100%'
      },
    },
    height_100:{
      height:"100%" 
    },
    height_100vh:{
      height:"100vh"
    },
    bgImgLayout: {
      backgroundImage: `url(${sidePage1})`,
      backgroundSize: "100% 100%",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${mobileLogo})`,
        maxHeight: '86px',
      },
      [theme.breakpoints.up('sm')]: {
        backgroundImage: `url(${sidePage1})`,
      },
      [theme.breakpoints.between("sm", 'md')]: {
        backgroundImage: `url(${sidePage2})`,
      },
      [theme.breakpoints.between('md', "lg")]: {
        backgroundImage: `url(${sidePage3})`,
      },
    },
    titleHeading: {
      color: theme.palette.common.black,
      fontWeight: CONSTANTS.fontWeight.fontWeight600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 30
      },
    },
    companyLogo: {
      width: "150px",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "120px",
      },
    },
    subTitletext: {
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.down("sm")]: {
        fontSize: '1.1rem'
      },
    },
    tabColor: {
      backgroundColor: "#F2F2F2",
      color: "#787878",
      borderRadius: "5px",
      width: "220px",
      textTransform: "capitalize",
      [theme.breakpoints.between("xs", 'sm')]: {
        width: "100%",
        maxWidth: '151px'
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "80px"
      },
      [theme.breakpoints.between("md", "lg")]: {
        width: "120px"
      },
      [theme.breakpoints.between("lg", "xl")]: {
        width: "100%",
        maxWidth: "180px"
      },
    },
    selectedTab: {
      backgroundColor: "#E8E7F7",
      color: CONSTANTS.color.darkBlue,
      borderRadius: "5px",
      width: "220px",
      textTransform: "capitalize",
      [theme.breakpoints.between("xs", 'sm')]: {
        width: "100%",
        maxWidth: '151px'
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "100px"
      },
      [theme.breakpoints.between("md", "lg")]: {
        width: "120px"
      },
      [theme.breakpoints.between("lg", "xl")]: {
        width: "100%",
        maxWidth: "180px"
      },
    },
    loginLabelText: {
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.caption,
      },
    },
    buttonLayout: {
      marginTop: theme.spacing(2)
    },
    submitButtonLayout: {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'normal'
      }
    },
    secondLogo: {
      display: 'flex',
      alignItems: 'end',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        zIndex: -99
      }
    },
    image2: {
      width: '80px',
      [theme.breakpoints.down('xs')]: {
        width: '47px',
        height: '47px'
      }
    }
  })
)(UserAccessWebRouter);
export default withTranslation()(UserAccessWebStyles);
 // Customizable Area End
