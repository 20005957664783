import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t:(key:string)=>string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  termsData:any
  termLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termsConditionPageApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      termsData:[],
      termLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
     // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.termsConditionPageApiCallId) {
          if (!responseJson.errors) {
            this.setState({ termsData: responseJson.data });
            this.setState({ termLoading: false });
          } else {
            //Check Error Response
            this.setState({ termLoading: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    this.termsConditionPage();
    this.setState({ termLoading: true });
    window.scrollTo(0, 0);
   
  }
  termsConditionPage = async () => {
    const header = {
      "Content-Type": configJSON.termsConditionAPIContentType,
    };
    
const lang=await getStorageData('lang')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.termsConditionPageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${ configJSON.termsConditionAPIEndPoint}?lang=${lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.termsConditionAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  acceptTerms = ()=>{
    const cameFrom = localStorage.getItem("cameFrom")
    localStorage.setItem("terms","true")
    this.handleGoBack(cameFrom)
  }
  handleGoBack = (cameFrom:any)=>{
    this.props.navigation.navigate(cameFrom)
  }

  // Customizable Area End
}
