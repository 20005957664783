import React from "react";

// Customizable Area Start
import {
  Box, Grid, InputBase, Tooltip, Button,
  MenuItem, Menu, Divider, TextField, Typography,
  Popover ,styled
} from "@material-ui/core";
import {
  withStyles
} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom"
import { filterMap } from "./assets";
import {calendarIcon} from "../../dashboard/src/assets"
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { arrowRight,leftArrow } from '../../RequestManagement/src/assets';
import { searchIcon } from "../../CustomisableUserProfiles/src/assets";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../dashboard/src/ReactDatePicker.css";
import moment from "moment";
import CustomSearchableSelect from "../../../components/src/CustomSearchableSelect.web";
import { CONSTANTS } from '../../../components/src/theme'
import { withTranslation } from "react-i18next";
import { ar } from "date-fns/locale";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import MapsController, {
  Props,
} from "./MapsController.web";

// Customizable Area Start
export const StyledTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      paddingLeft: "0.5rem"
    },
  }
})(TextField);

// Customizable Area End

export class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
   LanguageArrow = (selectLanguage:string) => {
     return (
       <>
         {selectLanguage === 'en' ? <ArrowForwardIcon /> :
           <ArrowBackOutlinedIcon />}
       </>
     );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const dateObject = new Date(this.state.selectedDate);
    if (this.state.selectLang === 'ar') {
      registerLocale("ar", ar);
      moment.locale('ar')
    } else {
      moment.locale('en')
    }
    
    const formattedDate = moment(dateObject).format("DD/MM/YYYY");
    const sortData = [
     
      { value: "alphabetical", label: `${t('map.alphabetical')}` },
      { value: "date", label: `${t('map.date')}` },
    ];
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined}  {...this.props} navMenuItem={t('dashboardNavbar.maps')} active={7} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.selectLang} style={{ overflowX: 'hidden',overflowY:'auto', backgroundColor: CONSTANTS.color.veryLightGrey , maxWidth:"100%" }}>
          <Box style={{ marginTop: "8rem" , width: "100%" , paddingLeft:'1.3rem', paddingRight:'1.3rem' , marginBottom:'2%' , height:'100vh'}}
            className={classes.boxContainer}
          >
            <Grid container direction="row" spacing={4}>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Box style={webStyle.searchMapContainer}>
                      <Box>
                        <img style={webStyle.searchIconMap} src={searchIcon} alt="" />
                      </Box>
                      <InputBase
                        style={webStyle.mapInputBase}
                        placeholder={`${t('map.placeholder')}`}
                        fullWidth
                        inputProps={{ 'aria-label': 'search' }}
                        value={this.state.searchMapLocation}
                        onChange={this.handleChangeSearchMaps}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                  <StyledBox style={webStyle.itemCenter}>
                    
                  </StyledBox>
                  </Grid>
                  <Grid item xs={2} style={{ justifyContent:"end", display: 'flex', background: CONSTANTS.color.white, minHeight: '56px' }}>
                    <Button style={webStyle.width30} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleOpenFilterMenu}>
                      <img style={{ height: '20px' }} src={filterMap} alt="" />
                    </Button>
                    <StyledMenu
                      id="customized-menu"
                      anchorEl={this.state.filterMenu}
                      keepMounted
                      open={Boolean(this.state.filterMenu)}
                      onClose={this.handleCloseFilterMenu}
                    >
                      <MenuItem disableRipple>
                        <Box style={webStyle.filerDiv} >
                          <Typography variant="subtitle2" className={classes.fontW_500}>{t('map.filters')}</Typography>
                          <Typography data-test-id='resetId' variant="subtitle2" className={classes.fontW_500} style={webStyle.fontCol} onClick = {this.resetFunction}>{t('map.reset')}</Typography>
                        </Box>
                      </MenuItem>
                      <Divider style={{ marginBottom: '5px' }} />
                      <MenuItem style={{ overflow: 'visible' }} disableRipple>
                        <StyledBox2 item xs={12}>
                        <div style={webStyle.displays}>
                          <CustomSearchableSelect
                            name="sortFilter"
                            data-test-id='sort-filter'
                            options={sortData}
                            value={this.state.sortFilter}
                            menuHeight='100px'
                            onChangeFun={this.handleChangeSortFilter}
                            placeholder={t('map.sort')}
                            maxHeight="100px"
                            defaultVal={sortData[1]}

                          />
                          <CloseIcon style={webStyle.BtnCenter} data-test-id="clear-list" onClick={() =>this.clearSortList()}></CloseIcon>
                          </div>
                        </StyledBox2>
                      </MenuItem>
                      <MenuItem style={{ overflow: 'visible' }} disableRipple>
                        <Grid item xs={12}>
                        <div style={webStyle.displays}>
                          <Box style={webStyle.mapsCalendar} >
                            <Typography variant="caption" style={webStyle.mapsTextWeek} component={"div"} >
                                {this.state.selectedDate === null || this.state.selectedDate === '' ? <Typography>{t('map.datePlaceholder')}</Typography> : moment(this.state.selectedDate).format("DD/MM/YYYY")}
                            </Typography>
                            <img
                              aria-controls="popover"
                              aria-haspopup="true"
                              id="popover"
                              data-test-id="handleShowCalendar"
                              style={webStyle.mapsCalendarIcon}
                              src={calendarIcon}
                              alt=""
                              onClick={this.handleShowCalendar}
                            />
                            <Popover
                              open={Boolean(this.state.showDatePicker)} // Open the Popover when anchorEl is set                             
                              onClose={this.handleClosePopup}
                              anchorReference="anchorPosition"
                              anchorPosition={this.state.anchorEl}
                            >
                              <DatePicker
                                data-test-id="datePicker"
                                locale="ar"
                                selected={this.state.selectedDate}
                                onChange={this.handleDateChange}
                                inline
                                ref={this.datePickerRef}
                                onClickOutside={this.handleHideCalendar}
                                renderCustomHeader={({ date, decreaseMonth, increaseMonth, }) => (
                                  <div
                                    style={{
                                      margin: "0px 18px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span className={classes.arrowIcon} onClick={decreaseMonth}>{"<"}</span>
                                    <span className={classes.textFormate}>{moment(date).format("MMMM YYYY")}</span>
                                    <span className={classes.arrowIcon} onClick={increaseMonth}>{">"}</span>
                                  </div>
                                )}
                                renderDayContents={(_, date) => {
                                  return <span>{moment(date).format("DD")}</span>;
                                }}
                              />
                            </Popover>
                          </Box>
                          <CloseIcon style={webStyle.BtnCenter}
                          data-test-id="clear-date-list"
                             onClick={() =>this.clearDateList()} ></CloseIcon>
                          </div>
                        </Grid>
                      </MenuItem>
                      {!this.state.isEmployee &&
                        <MenuItem style={{ overflow: 'visible' }} disableRipple>
                          <StyledBox3 item xs={12}>
                            <div style={webStyle.displays}>        
                            <Box style={webStyle.stylewidths}>
                              <Autocomplete
                                id="country-select-demo"
                                style={{ width: '100%' }}
                                value={this.state.typedValue}
                                onChange={this.handleAutoCompleteChange}
                                inputValue={this.state.searchQuery}
                                onInputChange={this.handleChangeGetEmployeeData}
                                options={this.state.employeesData}
                                getOptionLabel={this.renderOptionLabel}
                                renderOption={this.renderOption}
                                disableClearable={true}
                                renderInput={this.renderInput}
                              />
                            </Box>
                            <CloseIcon style={webStyle.BtnCenter} 
                            data-test-id="clear-emp-list"
                            onClick={() =>this.clearEmpListss()}
                            />
                            </div>
                          </StyledBox3>
                        </MenuItem>
                      }
                    </StyledMenu>
                  </Grid>
                </Grid>
                <Grid>
                  <LoadingBackdrop
                    loading={this.state.mapLoading}
                  />
                </Grid>
                <Grid container>
                  <Typography variant="subtitle1" className={classes.fontW_500} style={webStyle.mapsHeadText}>
                    {this.state.selectedRadioBtn === "job" ? `${t('dashboardNavbar.jobs')}` : `${t('dashboardNavbar.employee')}`}
                  </Typography>
                </Grid>
                <Grid container className={classes.boxContainer2} style={{ overflow: 'scroll' }}>
                  {this.state.selectedRadioBtn === 'job' &&
                    this.state.getMapListData &&
                    this.state.getMapListData.length > 0 &&
                    <Grid item xs={12}>
                      {
                        this.state.getMapListData.map((item: any) => {
                          return (
                            <>
                              <StyledGrids container className={this.state.selectLang === 'ar' ? classes.box_padding1 : classes.box_padding}
                                onClick={() => this.saveMapJobData(item?.attributes?.site_address, item)}
                                data-test-id='job-location'
                              >
                                <Grid item xs={11}>
                                  <Typography variant="body1" className={`${classes.fontW_500} ${classes.text_gray}`}> {t('map.job')}{item?.id}</Typography>
                                  <Grid container className={classes.text_gray}>
                                    {item?.attributes?.job_title}
                                  </Grid>
                                </Grid>
                                <Grid item xs={1} style={{ alignItems: 'center', display: 'flex' }}>
                                  {this.state.selectLang === 'en' ? <img src={arrowRight} width={"10px"} height={"15px"} /> : 
                                    <img src={leftArrow} width={"10px"} height={"15px"} />
                                  }
                                </Grid>
                              </StyledGrids>
                              <Divider />
                            </>
                          )
                        })}
                    </Grid>
                  }
                  {this.state.getMapListData.length === 0 &&
                    <Grid item xs={12} style={webStyle.noDataDiv}>
                      <Typography variant="subtitle1" style={webStyle.noDataText} component={"div"}>
                        {t('ProductService.noDataFound')}.
                      </Typography>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={8} className={classes.marginTop20} data-test-id='grid-direction'>
                <GoogleMap
                  mapContainerStyle={webStyle.mapContainerStyle}
                  center={this.state.getPlace?.selectedPlace}
                  zoom={5}
                  options={{
                    disableDefaultUI: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: true,
                    zoomControlOptions: {
                      position: 3, // ControlPosition.TOP_LEFT
                    },
                  }}
                  onLoad={(map) => {
                    this.mapRef.current = map;
                  }}
                >
                  {this.state.getPlace && (
                    <Tooltip
                      title={
                        this.state.getPlace?.jobData?.job_title
                      }
                      placement="top" arrow>
                      <Marker onClick={this.jobView} position={this.state.getPlace?.selectedPlace} />
                    </Tooltip>
                  )}
                  <Box style={webStyle.overlayParent}>
                    <Box
                      style=
                      {webStyle.overlayParentChild}>
                    <Box>
                    <Box>
                      <Typography variant="body1" style={webStyle.overlayJobId}>
                        {this.state.overlayData.job_id}
                      </Typography>

                      <Typography variant="body2" style={{...webStyle.overlayTitle , textAlign: "left" ,paddingBottom:'5%' }}>
                        {this.state.overlayData.job_tite}
                      </Typography>
                      
                      <Typography variant="body2" style={{...webStyle.overlayCustomerName , textAlign: "left" }}>
                        {this.state.overlayData.customer_name}
                      </Typography>
                    </Box>
                    <Box style={{...webStyle.overlayCustomerAddress , textAlign:"left"}}>
                      <Typography variant="caption">{this.state.overlayData.address}</Typography>
                    </Box>
                        </Box>
                      
                      <Typography variant="body2" style={webStyle.overlayTitle}>
                        <span style={webStyle.overlayNextSpan}>
                          {t('map.nextTime')} - </span>{this.state.overlayData.start_date}, {this.state.overlayData.start_time}
                      </Typography>
                    </Box>
                    
                  </Box>
                </GoogleMap>
              </Grid>
            </Grid>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles:any = (theme: any) => ({
  boxContainer: {
    [theme.breakpoints.down("xs")]: {
      padding:"0px 17px !important"
    }
  },
  textFormate: {
    fontSize: "16px",
    fontWeight: 600
  },
  arrowIcon: {
    fontSize: '20px',
    marginTop: "-3px",
    color: "#ccc",
    fontWeight: 600,
    cursor: "pointer"
  },
  box_padding:{
    padding:'20px 0px 20px 17px',
    cursor:'pointer'
  },
  box_padding1:{
    padding:'20px 17px 20px 0px',
    cursor:'pointer'
  },
  marginTop20:{
    marginTop:"0px",
    [theme.breakpoints.down("xs")]: {
      marginTop:"25px"
    }
  },
  paddingLeft_36:{
    paddingLeft:"36px"
  },
  paddingRight_36:{
    paddingRight:"36px"
  },
  text_gray: {
    color: CONSTANTS.color.gray,
  },
  text_black:{
    color: CONSTANTS.color.black,
  }
  ,
  fontW_500: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  boxContainer2: {
    borderRadius: "8px",
    width:'100%',
    minHeight:'78vh',
    background: CONSTANTS.color.white,
    marginTop:'30px',
    boxShadow: "0px 0px 20px rgba(0,0,0,0.19)",
    marginBottom:'1.5%'
  },
  tabBtn: {
    marginTop: '20px',
    borderRadius: "8px",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    height: "46px",
    textTransform: "capitalize"
  }
});
const StyledMenu = withStyles({
  paper: {
      borderRadius: "8px",
      background: CONSTANTS.color.white,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
      width:'300px',
      margin:"50px",
      height:"261px"
  },
})(Menu);
const StyledBox = styled(Grid)({
  "& .arrowIconBtn":{
    height: '40px',
    width:"100%",
    minWidth:"30px",
    backgroundColor :"#1c18af !important",
    color:"white"
  },
  "& .paper-72":{
    margin:"50px"
  }
});
const StyledBox2 = styled(Grid)({
  "& .css-2b097c-container":{
    width:"100%"
  }
});
const StyledBox3 = styled(Grid)({
  "& .MuiOutlinedInput-root":{
    height:"50px"
  }
});
const StyledGrids = withStyles({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },

    },
  }
})(Grid);

const webStyle = {
  itemCenter:{
    alignItems: 'center', 
    display: 'flex', 
    background: CONSTANTS.color.white,
    minHeight: '56px'
  },
  stylewidths:{ 
  display: "flex",
  alignItems: "center",
  width:"280px" 
  },
  BtnCenter:{
    margin:"12px 0 0 6px"
  },
  overlayParentChild: {
    display: "flex",
    flexDirection: "row-reverse" as const, justifyContent: "space-between"
  },
  overlayJobId: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textDecoration: "underline"
  },

  overlayNextSpan :{
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  overlayTitle :{
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  overlayCustomerName :{
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
  },
  overlayCustomerAddress :{
    color: CONSTANTS.color.silverGrey,
    fontWeight: 400
  },
  width30:{
    minWidth:"30px"
  },
  overlayParent :{
    position: "absolute" as const,
    bottom: 0, 
    left: 0,
    transform: "translateX(50% , 50%)",
    padding: "10px",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "125px",
    display: "flex",
    flexDirection: "column" as const,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    
  },
  clearButton:{
    backgroundColor: CONSTANTS.color.darkBlue,
    cursor: "pointer",
    color:CONSTANTS.color.white,
    fontSize:"15px"
  },
  noDataDiv:{
    display:"flex",
    alignItems:"center" as const,
    justifyContent:"center"
  },
  noDataText:{
    color: CONSTANTS.color.darkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
 
  mapsCalendar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "53px",
    borderRadius: "7px",
    backgroundColor: CONSTANTS.color.white,
    cursor: "pointer",
    color: "grey",
    padding: "0 1rem 0 1rem",
    border: "1px solid lightgray"
  },
  mapsTextWeek: {
    color: CONSTANTS.color.gray,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
  },
  mapsCalendarIcon: {
    width: "20px",
    height: "20px",
  },
  searchMapContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    background:'rgb(255, 255, 255)',
    borderRadius: "8px",
  },
  mapContainerStyle: {
    width: "100%",
    height: "100%",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)"
  },
  mapInputBase: {
    width: "100%",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
  },
  searchIconMap: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  mapsHeadText: {
    color: CONSTANTS.color.lightBrown,
    lineHeight: "20px",
    marginTop:"20px"
  },
  filerDiv : {
    display: "flex", justifyContent: "space-between",
    alignItems:"center" as const,
    width:"100%"
  },
  fontCol :{
    color:"#1c18af "
  },
  displays:{
    display:"flex",
  }
}
const MapSnackbar = withSnackbar(Maps)
const MapRouter = withRouter(MapSnackbar)
export default withTranslation()(withStyles(styles)(MapRouter));
// Customizable Area End