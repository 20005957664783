import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  Typography,
  Hidden,
  Tab,
  Tabs,
  Drawer
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import {
  requestIcon,
  jobReportIcon,
  jobFinancialIcon,
  timesheetIcon,
  backIconMobile
} from "./assets";
import { CONSTANTS } from "../../../components/src/theme";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
// Customizable Area End

import ReportsController, {
  Props,
} from "./ReportsController.web";

// Customizable Area Start
type SideBarItem = {
  id: number;
  title: string;
  icon: string;
}

interface StyledDrawerProps {
  languageSelected: string;
}

export const StyledDrawer = withStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        marginRight: (props: StyledDrawerProps) => props.languageSelected === "ar" && "0rem !important",
        marginLeft: (props: StyledDrawerProps) => props.languageSelected !== "ar" && "0rem !important",
      }
    },
    "& .MuiDrawer-paperAnchorLeft": {
      left: (props: StyledDrawerProps) => props.languageSelected === "ar" && "auto",
      right: (props: StyledDrawerProps) => props.languageSelected === "ar" && 0,
    }
  }
}))(Drawer);

export const StyledBoxReports = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.8rem !important",
      marginTop: "8rem !important",
      marginBottom: "0.5rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.2rem !important"
    },
  }
}))(Box);

export const StyledList = withStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      [theme.breakpoints.down("sm")]: {
        alignItems: "baseline",
      }
    }
  }
}))(List);

export const StyledTabsReports = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "& .MuiButtonBase-root": {
      minHeight: "56px",
      [theme.breakpoints.down("xs")]: {
        minHeight: "43px",
        maxWidth: "100%",
        justifyContent: "flex-start"
      },
      [theme.breakpoints.down(480)]: {
        width: "94px"
      },
      [theme.breakpoints.down(410)]: {
        width: "84px"
      },
      [theme.breakpoints.down(375)]: {
        width: "80px"
      },
    },
    "& .MuiTab-textColorInherit": {
      color: CONSTANTS.color.lightBlack
    },
    "& .Mui-selected": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white,
    },
    "& .MuiTabScrollButton-root": {
      display: "none !important"
    }
  }
}))((props: any) => <Tabs {...props} TabIndicatorProps={{ style: { display: 'none' } }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    "& .MuiTab-wrapper": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      marginLeft: "1rem",
      gap: "0.8rem",
      textTransform: "capitalize" as const,
      fontSize: "16px",
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0.5rem",
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem",
        marginLeft: "0rem",
      },
      [theme.breakpoints.down(480)]: {
        fontSize: "12px",
      },
    },
    "& .MuiSvgIcon-root": {
      marginRight: "1rem",
    },
    "&:hover": {
      borderRadius: "10px",
      color: CONSTANTS.color.white,
      backgroundColor: CONSTANTS.color.darkBlue,
      "& img": {
        filter: "brightness(0) invert(1)"
      }
    }
  }
}))(Tab);
// Customizable Area End

export class ReportsSideBar extends ReportsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const reportsSideBar = [
      {
        id: 1,
        title: t('reports.requests'),
        icon: requestIcon,
      },
      {
        id: 2,
        title: t('reports.jobReport'),
        icon: jobReportIcon,
      },
      {
        id: 3,
        title: t('reports.jobFinancialReport'),
        icon: jobFinancialIcon,
      },
      {
        id: 4,
        title: t('reports.timesheet'),
        icon: timesheetIcon,
      },
    ]

    window.addEventListener('resize', this.handleSideBarMobile);
    const renderContent = (
      <StyledList disablePadding style={{ padding: 1 }}>
        <StyledTabsReports
          data-test-id="handleTabChange"
          orientation={this.state.mobileSideBar ? "vertical" : "horizontal"}
          variant="scrollable"
          value={this.props.active}
          onChange={this.handleTabChange}
          aria-label="Vertical tabs example"
        >
          {reportsSideBar.map((item: SideBarItem) => {
            return (
              <StyledTab
                icon={
                  <Box style={webStyle.styledTabIcon}>
                    <img
                      style={
                        this.props.active === item.id - 1 ?
                        webStyle.activeIcon :
                        webStyle.inactiveIcon
                      }
                      src={item.icon}
                      alt=""
                    />
                  </Box>
                }
                label={item.title}
                key={item.id}
              />
            );
          })}
        </StyledTabsReports>
      </StyledList>
    );

    return (
      <>
        <Box style={webStyle.drawerContainer}>
          <Hidden smDown>
            <StyledDrawer
              open
              variant="permanent"
              languageSelected={this.state.languageSelected}
              PaperProps={{
                style: this.state.languageSelected === "ar" ?
                {...webStyle.drawerPaperStyle, ...webStyle.drawerPaperStyleAr} :
                webStyle.drawerPaperStyle
              }}
            >
              <Typography variant="body2" color="textPrimary" style={webStyle.reportsHeading}>
                {t('reports.reportInfo')}
              </Typography>
              {renderContent}
            </StyledDrawer>
          </Hidden>
        </Box>
        <Hidden mdUp>
          <StyledBoxReports>
            <Box
              style={
                this.state.languageSelected === "ar" ?
                {...webStyle.backIconContainer, padding: "0px 1rem 1.5rem 0rem"} :
                webStyle.backIconContainer
              }
            >
              <img
                data-test-id="handleClickGoBack"
                style={this.state.languageSelected === "ar" ? {...webStyle.backIconMobile, transform: "rotate(180deg)"} : webStyle.backIconMobile}
                src={backIconMobile}
                alt=""
                onClick={this.handleClickGoBack}
              />
              <Typography variant="body2" color="textPrimary" style={webStyle.reportsHeadingMobile}>
                {t('reports.reportInfo')}
              </Typography>
            </Box>
            {renderContent}
            <span style={webStyle.hr}></span>
          </StyledBoxReports>
        </Hidden>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({

});

const webStyle = {
  drawerContainer: {
    zIndex: 1000
  },
  drawerPaperStyle: {
    width: "295px",
    marginTop: "5.8rem",
    marginLeft: "17.5rem",
    borderRight: `1px solid ${CONSTANTS.color.paleGrey}`,
    paddingTop: "2.8rem",
    paddingBottom: "4rem"
  },
  drawerPaperStyleAr: {
    marginLeft: 0,
    marginRight: "17.5rem"
  },
  reportsInfoHeading: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
    padding: "0px 2rem 1.5rem 3rem"
  },
  activeIcon: {
    height: "24px",
    width: "24px",
    marginBottom: "0px",
    filter: "brightness(0) invert(1)"
  },
  inactiveIcon: {
    height: "24px",
    width: "24px",
    marginBottom: "0px"
  },
  styledTabIcon: {
    minWidth: "25px",
    marginBottom: "-5px"
  },
  backIconContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "0px 0rem 1.5rem 1.5rem",
  },
  backIconMobile: {
    width: "12px",
    height: "24px"
  },
  reportsHeading: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    padding: "0px 2rem 1.5rem 3rem"
  },
  reportsHeadingMobile: {
    fontSize: "24px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    color: CONSTANTS.color.lightBrown
  },
  hr: {
    display: "flex",
    height: "2px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "1rem 0px 0.5rem"
  },
}

export default withTranslation()(withStyles(styles)(ReportsSideBar));
// Customizable Area End