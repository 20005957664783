import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Radio,
  Typography,
} from "@material-ui/core";
import {
  withStyles
} from "@material-ui/core/styles";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { PurchaseOrderPDF } from "./PurchaseOrderPDF.web";
import { DeleteDialog } from "./DeleteDialog.web";
export const configJSON = require("./config");
import withPermission from "../../../components/src/withPermission.web";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import PurchasesController, {
  Props,
} from "./PurchasesController.web";

// Customizable Area Start
const StyledBox = withStyles({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: CONSTANTS.color.darkBlue,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& .MuiInputBase-root .MuiInputBase-input": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& .MuiRadio-root": {
        "& .MuiSvgIcon-root": {
          fill: CONSTANTS.color.white
        }
      }
    },
  }
})(Box);
// Customizable Area End

export class PurchaseOrders extends PurchasesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { t } = this.props;
    return (
      <>
        <Box>
          <Box style={webStyle.purchaseOrderContainer} className={classes.purchaseOrderContainer}>
            <Typography style={webStyle.purchaseOrderText} className={classes.purchaseOrderText} variant="subtitle1">
              {t('suppliers.purchaseOrder')}
            </Typography>
            <span style={webStyle.hr}></span>
            <Box style={webStyle.container}>
              {this.state.purchasesData?.map((item: any) => (
                <Box key={item?.id}>
                  <StyledBox
                    data-test-id="handleRadioChange"
                    style={
                      item?.id === this.state.checkedId ?
                      {...webStyle.purchaseOrder, backgroundColor: CONSTANTS.color.lightBlue} :
                      webStyle.purchaseOrder
                    }
                    className={classes.purchaseOrder}
                    onClick={() => this.handleRadioChange(item?.id)}
                  >
                    <Box
                      style={
                        item?.id === this.state.checkedId ?
                        {...webStyle.purchaseIdSelect, backgroundColor: CONSTANTS.color.lightBlue} :
                        webStyle.purchaseIdSelect
                      }
                    >
                      <Radio color="primary" checked={item.id === this.state.checkedId} />
                      <Typography
                        style={webStyle.purchaseId}
                        className={classes.purchaseId}
                        variant="subtitle2"
                        onClick={this.handleRedirectionToPurchaseData}
                      >
                        {t('suppliers.purchaseOrderId')}{item?.attributes?.item}
                      </Typography>
                    </Box>
                  </StyledBox>
                  <span style={{...webStyle.hr, border: `1px solid ${CONSTANTS.color.paleGrey}`}}></span>
                </Box>
              ))}
              {this.state.noPurchaseText &&
                <Typography style={webStyle.noPurchasesText} variant="subtitle1">
                  {this.state.noPurchaseText}
                </Typography>
              }
            </Box>
          </Box>

          <Box
            style={
              this.state.checkedId === null ?
              {...webStyle.buttonsContainerCancel, justifyContent: "flex-end"} :
              webStyle.buttonsContainerCancel
            }
            className={classes.buttonsContainerCancel}
          >
            {this.state.checkedId ? (
              <Button
                data-test-id="handleOpenDownloadAsPdf"
                className={`${classes?.buttonStyleEdit} ${classes.buttonContainerDownloadPdf} ${classes?.buttonStyleCommon}`}
                onClick={this.handleOpenDownloadAsPdf}
              >
                <Typography
                  style={webStyle.buttonTextDownload}
                  className={classes.buttonText}
                  variant="subtitle2"
                >
                  {t('suppliers.downloadAsPdf')}
                </Typography>
              </Button>
            ) : (
              <Button
                className={`${classes?.buttonStyleEdit} ${classes.buttonContainerDownloadPdf} ${classes?.buttonStyleCommon}`}
                onClick={this.handleCreateNewPurchase}
              >
                <Typography
                  style={webStyle.buttonTextDownload}
                  className={classes.buttonText}
                  variant="subtitle2"
                >
                  {t('suppliers.createNewPurchase')}
                </Typography>
              </Button>
            )}

            <Box style={webStyle.buttonsContainer}>
              <Button
                className={`${classes?.buttonStyleMobile} ${classes.btnBoxCancel} ${classes?.buttonStyleCommon}`}
                onClick={this.handleCancelButton}
              >
                <Typography
                  style={webStyle.buttonTxtCancel}
                  className={classes.buttonText}
                  variant="subtitle2"
                >
                  {t('suppliers.cancel')}
                </Typography>
              </Button>
              {this.state.checkedId ? (
                <DeleteDialog
                  {...this.props}
                  title={t('suppliers.deletePurchase')}
                  description={t('suppliers.deletePurchaseConfirmation')}
                  deleteDialogOpen={this.state.deleteDialogOpen}
                  handleClickOpenDialog={this.handleClickOpenDialog}
                  handleCloseDialog={this.handleCloseDialog}
                  handleClickProceedDelete={this.handleClickProceedDelete}
                  loader={this.state.deletePurchaseLoading && (
                    <LoadingBackdrop
                      loading={this.state.deletePurchaseLoading}
                    />
                  )}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
        {this.state.openPdf && (
          <PurchaseOrderPDF
            {...this.props}
            openPdf={this.state.openPdf}
            openPdftype={'suppliers'}
            handleCloseDownloadPdf={this.handleCloseDownloadPdf}
            purchasePdfData={this.state.purchasePdfData}
            purchasePdfLoading={this.state.purchasePdfLoading}
            languageSelected={this.state.languageSelected}
          />
        )}
        <LoadingBackdrop
          loading={this.state.getPurchasesLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  container: {
    height: "650px",
    overflow: "auto"
  },
  purchaseOrderContainer: {
    marginTop: "0.5rem",
    maxWidth: "100%",
    minHeight: "697px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
  },
  purchaseOrderText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    padding: "1.8rem 1.8rem 1.6rem 3.5rem",
    position: "sticky" as const,
    top: 0
  },
  buttonsContainerCancel: {
    padding: "3rem 0",
    display: "flex",
    flexDirection: "row-reverse" as const,
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  buttonsContainer: {
    display: 'flex',
    width: "100%",
    gap: "1rem"
  },
  buttonTxtCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  buttonTextDownload: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  hr: {
    display: "flex",
    width: "100%",
    height: "1px",
    border: "1px solid rgb(194 187 207)",
  },
  purchaseOrder: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "86px",
    paddingLeft: "1rem",
    cursor: "pointer"
  },
  purchaseIdSelect: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  purchaseId: {
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  noPurchasesText: {
    color: CONSTANTS.color.darkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
}

const purchaseOrderStyles =  withStyles(styles)(PurchaseOrders);
export default withTranslation()(withPermission(purchaseOrderStyles));
// Customizable Area End