// Customizable Area Start
import { CONSTANTS } from '../../../components/src/theme';
import { withStyles, Theme,createStyles } from "@material-ui/core/styles";

const withCalendarStyles = (theme: Theme) =>
createStyles({
    text_lightBrown: {
        color: CONSTANTS.color.lightBrown
    },
    text_lightGrey: {
        color: CONSTANTS.color.lightGrey
    },
    text_red: {
        color: CONSTANTS.color.crimsonRed
    },
    mb_5: {
        marginBottom: '5px'
    },
    p_25:{
        padding:'25px'
    },
    pl_4: {
        paddingLeft: '4px'
    },
    pr_9: {
        paddingRight: '9px'
    },
    mt_20: {
        marginTop: "20px"
    },
    mt_15:{
        marginTop:'15px'
    },
    mt_10: {
        marginTop: "10px"
    },
    mt_0: {
        marginTop: "0px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-25px",
        }
    },
    mt_24:{
        marginTop:'-24px'
    },
    ml_15:{
        marginLeft:'15px'
    },
    mr_15:{
        marginRight:"15px"
    },
    text_border_red: {
        border: `1px solid ${CONSTANTS.color.crimsonRed}`,
    },
    font_14: {
        fontSize: '14px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '12px !important'
        },
    },
    errorText: {
        fontSize: "14px",
        margin: "5px 5px 5px 0"
    },
    divider: {
        width: "2px",
        height: "60%",
        color: CONSTANTS.color.grey
    },
    btn_hide_smallScreen:{
        display:'flex',
        [theme.breakpoints.down("xs")]: {
          display:'none'
        }
      },
    btn_hide_largeScreen: {
        display: 'flex',
        [theme.breakpoints.between("sm", "xl")]: {
            display: 'none'
        },
    },
    saveBtn: {
        width: "130px",
        height: "50px",
        borderRadius: "10px",
        border: `1px solid ${CONSTANTS.color.darkBlue}`,
        color: CONSTANTS.color.white,
        textTransform: "capitalize",
        background: CONSTANTS.color.darkBlue,
        "&:hover": {
            background: CONSTANTS.color.darkBlue,
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    paddingLeft_15: {
        paddingLeft: '15px'
    },
    paddingTop_15: {
        paddingTop: '15px'
    },
    marginBottom_40: {
        marginBottom: '40px'
    },
    marginLeft_4: {
        marginLeft: '-4px'
    },
    marginLeft_25: {
        marginLeft: '25px',
    },
    marginLeft_12: {
        marginLeft: '-12px',
    },
    marginLeft_10: {
        marginLeft: '10px',
    },
    marginTop_0:{
        marginTop: "0px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-10px",
        }
    },
    deleteBtn: {
        width: '130px',
        height: "50px",
        borderRadius: "10px",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    cancelBtn: {
        width: '130px',
        height: "50px",
        borderRadius: "10px",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    titleText: {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    font_gray: {
        color: CONSTANTS.color.grey
    },
    fontWeight_500: {
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
    },
    text_border_gray: {
        border: `1px solid ${CONSTANTS.color.lightGrey}`,
    },
    searchIcon: {
        height: "20px",
        width: "20px",
        marginLeft: '10px'
    },
    fieldError: {
        fontSize: "14px",
        margin: "5px 5px 5px 0"
    },
    searchIcon1: {
        height: "19px",
        width: "19px",
    },
})
export default withStyles(withCalendarStyles);
// Customizable Area End