import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Container,
  InputBase,
  Button
} from "@material-ui/core";
import {
  Theme,
  styled,
  withStyles
} from "@material-ui/core/styles";
import {
  iIcon,
  afterHoverIcon
} from "./assets";
import { SettingsSideBar } from "./SettingsSideBar.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledBox, StyledCheckbox } from "./CompanyInformation.web";
import { CONSTANTS } from "../../../components/src/theme";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
// Customizable Area End
import BillingController, {
  Props,
} from "./BillingController.web";

// Customizable Area Start
export const StyledInputBase = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-input": {
      textAlign: "center",
      color: CONSTANTS.color.midGrey,
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      fontSize: "18px",
    }
  }
}))(InputBase);
// Customizable Area End

export class Notification extends BillingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { t } = this.props;
    
  
    return (
      <>
        <DashboardSideBar permissions={undefined}  oldProps={undefined}  {...this.props} navMenuItem={this.props.t('dashboardNavbar.settings')} active={false} active1={false} active2={1} />
        <SettingsSideBar   progress={100} {...this.props} active={this.state.isEmployee ? 2 : 3} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            style={webStyle.boxContainerNotification}
            className={classes.boxContainerNotification}
          >
            <Container maxWidth={false}>
              <Typography style={webStyle.mainHeaderText} className={classes.mainHeaderText} variant="subtitle1">
                {t('settings.notifications')}
              </Typography>


              {this.state.mockNotificationData?.map((item:any) => (
                <Box display={"flex"} flexDirection={"column"} gridGap={"1rem"} key={item.id}>
                  <Typography style={webStyle.mainHeaderSubText} className={classes.mainHeaderSubText} variant="body1" color="textPrimary">
                    {item.attributes.setting_type}
                  </Typography>
                  <Box display={"flex"} flexDirection={"column"} gridGap={"1rem"}>
                    {
                      <>
                        {item.attributes.notify_desc &&
                          <Box display={"flex"} gridGap={"1rem"} alignItems={"center"}>
                            <StyledCheckbox
                              checked={item.attributes.notifies}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              onChange={this.handleCheckBox.bind(this, item, "notifies")}
                            />
                         
                            <Typography component={"span"} style={webStyle.checkBoxDesc} className={classes.mainHeaderDesc} variant="body1" color="textPrimary">
                              {item.attributes.notify_desc}
                            </Typography>
                          </Box>
                        }


                        <Box display={"flex"} gridGap={"1rem"} alignItems={"center"}>
                          <StyledCheckbox
                          data-test-id="checkbox"
                            checked={item.attributes.follow_up}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={this.handleCheckBox.bind(this, item, "follow_up")}
                          />
                          <Box>
                            <Typography component={"span"} style={webStyle.checkBoxDesc} className={classes.mainHeaderDesc} variant="body1" color="textPrimary">
                              {item.attributes.follow_up_desc.slice(0, 16)}
                            </Typography>
                            <span>
                              <StyledInputBase
                                data-test-id="days"
                                disabled={this.state.isEmployee}
                                style={webStyle.textfield}
                                placeholder={t('settings.eg')}
                                value={item.attributes.days}
                                onChange={this.handleAdddays.bind(this, item)}
                              />
                            </span>
                            <Typography component={"span"} style={webStyle.checkBoxDesc} className={classes.mainHeaderDesc} variant="body1" color="textPrimary">
                              {item.attributes.follow_up_desc.slice(16)}
                            </Typography>
                          </Box>
                          {(item.attributes.setting_type === "quote"
                            || item.attributes.setting_type === "يقتبس") && !this.state.isEmployee
                            &&
                            <Box
                              style={webStyle.positionStyle}
                              onMouseEnter={this.handleMouseEnterNotification}
                              onMouseLeave={this.handleMouseLeaveNotification}
                            >
                              <img
                                style={webStyle.iIcon}
                                className={this.state.languageSelected === "ar" ? classes.iIcon : {}}
                                src={this.state.showTextNotification ? afterHoverIcon : iIcon}
                                alt=""
                              />
                            </Box>
                         }  
                        </Box>
                      </>
                   
                    }
                   
                  </Box>
                </Box>
              ))}

              
              {!this.state.isEmployee && 
                <Button data-test-id="save" onClick={this.handleCreateNotification} className={classes.buttonContainerSave}>
                  <Typography style={webStyle.buttonTextSave} className={classes.buttonTextSave} variant="subtitle2">
                    {t('settings.save')}
                  </Typography>
                </Button>
              }

              {!this.state.isEmployee && this.state.showTextNotification &&
                <div
                  style={
                    this.state.languageSelected === "ar" ?
                      { ...webStyle.hoverStyle, transform: "translate(-85%, -358%)" } :
                      webStyle.hoverStyle
                  }
                  className={this.state.languageSelected === "ar" ? classes.hoverStyleAr : classes.hoverStyle}
                >
                  <Typography
                    style={webStyle.hoveredStyle}
                  >
                    {t('settings.hoverText')}
                  </Typography>
                </div>
              }
            </Container>
          </Box>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainerNotification: {
    [theme.breakpoints.down("md")]: {
      padding: "0 1rem 8rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.25rem 8rem !important",
      marginTop: "0rem !important"
    },
  },
  hoverStyle: {
    [theme.breakpoints.down("md")]: {
      transform: "translate(109%, -453%) !important",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "translate(123%, -410%) !important",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-1%, -522%) !important",
    },
   
  },
  hoverStyleAr: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-1%, -522%) !important",
    },
  },
  buttonContainerSave: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "192px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.darkBlue,
    float: "right" as const,
    margin: "10rem 0 4rem 0",
    textTransform:"none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "3rem 0px 3rem !important"
    },
    [theme.breakpoints.down(480)]: {
      maxWidth: "100% !important",
      minHeight: "48px !important"
    },
  },
  buttonTextSave: {
    [theme.breakpoints.down(480)]: {
      fontSize: "22px !important",
    },
  },
  mainHeaderText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    },
  },
  mainHeaderSubText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      paddingTop: "1rem !important",
    },
  },
  mainHeaderDesc: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  iIcon: {
    [theme.breakpoints.down("xs")]: {
      // transform: "translate(-90%, -178%) !important",
    },
  }
});

const webStyle = {
  boxContainerNotification: {
    marginTop: "8.5rem",
    paddingBottom: "10rem"
  },
  mainHeaderText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  mainHeaderSubText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "16px",
    paddingTop: "2rem",
    textTransform:"capitalize" as const
  },
  checkBoxContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingTop: "1.5rem"
  },
  checkBoxDesc: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  iIcon: {
    width: "18px",
    height: "18px",
   
  },
  positionStyle: {
    position: "relative" as const,
    marginTop: "0.5rem",
    display: "flex",
    alignSelf: "flex-start"
  },
  hoverStyle: {
    width: "100%",
    maxWidth: "308px",
    height: "54px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.lavenderBlue,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    position: "absolute" as const,
    transform: "translate(124%, -358%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99
  },
  textfield: {
    width: "100px",
    height: "40px",
    borderRadius: "5px",
    border: `1px solid ${CONSTANTS.color.steelGrey}`,
    margin: "0 0.5rem"
  },
  hoveredStyle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    fontSize: "14px",
    padding: "0 1rem"
  },
  buttonTextSave: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
}

export default withTranslation()(withStyles(styles)(Notification));
// Customizable Area End