import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  Typography,
  Hidden,
  Drawer,
  Tabs,
  Tab
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import {
  companyInfoIcon,
  productServicesIcon,
  billingIcon,
  notificationsIcon,
  backIconMobile
} from "./assets";
import { CONSTANTS } from "../../../components/src/theme";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
// Customizable Area End

import SettingsController, {
  Props,
} from "./SettingsController.web";

// Customizable Area Start
interface StyledDrawerProps {
  languageSelected: string;
}

export const StyledDrawer = withStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        marginLeft: (props: StyledDrawerProps) => props.languageSelected !== "ar" && "0rem !important",
        marginRight: (props: StyledDrawerProps) => props.languageSelected === "ar" && "0rem !important"
      }
    },
    "& .MuiDrawer-paperAnchorLeft": {
      right: (props: StyledDrawerProps) => props.languageSelected === "ar" && 0,
      left: (props: StyledDrawerProps) => props.languageSelected === "ar" && "auto"
    }
  }
}))(Drawer);

export const StyledBox = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.8rem !important",
      marginTop: "8rem !important",
      marginBottom: "1rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.2rem !important",
    },
  }
}))(Box);

export const StyledList = withStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      [theme.breakpoints.down("sm")]: {
        alignItems: "baseline"
      },
    }
  }
}))(List);

export const StyledTabs = withStyles((theme: Theme) => ({
  root: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    "& .MuiButtonBase-root": {
      minHeight: "56px",
      [theme.breakpoints.down("xs")]: {
        minHeight: "43px",
        justifyContent: "flex-start",
        maxWidth: "100%",
      },
      [theme.breakpoints.down(480)]: {
        width: "94px",
      },
      [theme.breakpoints.down(410)]: {
        width: "84px",
      },
      [theme.breakpoints.down(375)]: {
        width: "80px",
      },
    },
    "& .MuiTab-textColorInherit": {
      color: CONSTANTS.color.lightBlack
    },
    "& .Mui-selected": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white
    },
    "& .MuiTabScrollButton-root": {
      display: "none !important",
    }
  }
}))((props: any) => <Tabs {...props} TabIndicatorProps={{ style: { display: 'none' } }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    "& .MuiTab-wrapper": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: "1rem",
      gap: "0.8rem",
      textTransform: "capitalize" as const,
      fontSize: "16px",
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0.5rem",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "0rem",
        gap: "0.5rem",
      },
      [theme.breakpoints.down(480)]: {
        fontSize: "12px",
      },
    },
    "& .MuiSvgIcon-root": {
      marginRight: "1rem",
    },
    "&:hover": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white,
      "& img": {
        filter: "brightness(0) invert(1)"
      }
    }
  }
}))(Tab);
// Customizable Area End

export class SettingsSideBar extends SettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const settingsSideBarDataBusinessOwner = [
      {
        id: 1,
        title: t('settings.companyInformation'),
        icon: companyInfoIcon,
      },
      {
        id: 2,
        title: t('settings.productAndServices'),
        icon: productServicesIcon,
      },
      {
        id: 3,
        title: t('settings.billing'),
        icon: billingIcon,
      },
      {
        id: 4,
        title: t('settings.notification'),
        icon: notificationsIcon,
      },
    ]
    const settingsSideBarDataEmployee = [
      {
        id: 1,
        title: t('settings.companyInformation'),
        icon: companyInfoIcon,
      },
      {
        id: 2,
        title: t('settings.productAndServices'),
        icon: productServicesIcon,
      },
     
      {
        id: 3,
        title: t('settings.notification'),
        icon: notificationsIcon,
      },
    ]

    let settingsSideBarData = this.state.isEmployee ? settingsSideBarDataEmployee :settingsSideBarDataBusinessOwner;
  
    window.addEventListener('resize', this.handleSideBarHide);
    const renderContent = (
      <StyledList disablePadding style={{ padding: 1 }}>
        <StyledTabs
          data-test-id="handleTabValueChange"
          orientation={this.state.hideSideBar ? "vertical" : "horizontal"}
          variant="scrollable"
          value={this.props.active}
          onChange={this.handleTabValueChange}
          aria-label="Vertical tabs example"
        >
          {settingsSideBarData.map((item: any) => {
            return (
              <StyledTab
                icon={
                  <Box style={webStyle.tabIconStyle}>
                    <img style={this.props.active === item.id - 1 ? webStyle.activeIcon : webStyle.inactiveIcon} src={item.icon} alt="" />
                  </Box>
                }
                label={item.title}
                key={item.id}
              />
            );
          })}
        </StyledTabs>
      </StyledList>
    );

    const pathname = this.props.navigation.history?.location?.pathname;
    const pathToLabelMap: Record<string, string> = {
      "/Settings/CompanyInformation": t('settings.companySettings'),
      "/Settings/ProductAndServices": t('settings.productAndServices'),
      "/Settings/Billing": t('settings.billing'),
      "/Settings/ManagePlan": t('settings.manageYourPlan'),
      "/Settings/AddPayment": t('settings.addPayment'),
      "/Settings/Notification": t('settings.notification'),
    };
    return (
      <>
        <Box style={webStyle.container}>
          <Hidden smDown>
            <StyledDrawer
              open
              variant="permanent"
              languageSelected={this.state.languageSelected}
              PaperProps={
                {style: this.state.languageSelected === "ar" ?
                {...webStyle.drawerStyleWeb, ...webStyle.marginLeftRight} :
                webStyle.drawerStyleWeb}
              }
            >
              <Typography variant="body2" color="textPrimary" style={webStyle.companySettingsHeading}>
                {t('settings.companySettings')}
              </Typography>
              {renderContent}
            </StyledDrawer>
          </Hidden>
        </Box>
        <Hidden mdUp>
          <StyledBox>
            <Box style={this.state.languageSelected === "ar" ? {...webStyle.backIconContainer, padding: "0px 1.5rem 1.5rem 0"} : webStyle.backIconContainer}>
              <img style={this.state.languageSelected === "ar" ? {...webStyle.backIconMobile, transform :"rotate(180deg)"} : webStyle.backIconMobile} src={backIconMobile} alt="" onClick={this.handleGoBackIcon} />
              <Typography variant="body2" color="textPrimary" style={webStyle.companySettingsHeadingMobile}>
                {pathToLabelMap[pathname]}
              </Typography>
            </Box>
            {renderContent}
            <span style={webStyle.hr}></span>
          </StyledBox>
        </Hidden>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    zIndex: 1000
  },
  companySettingsHeading: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
    padding: "0px 2rem 1.5rem 3rem"
  },
  backIconContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "0px 0rem 1.5rem 1.5rem",
  },
  backIconMobile: {
    width: "12px",
    height: "24px"
  },
  companySettingsHeadingMobile: {
    fontSize: "24px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    color: CONSTANTS.color.lightBrown
  },
  hr: {
    display: "flex",
    height: "2px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "1.5rem 0px 0.5rem"
  },
  activeIcon: {
    width: "24px",
    height: "24px",
    marginBottom: "0px",
    filter: "brightness(0) invert(1)"
  },
  inactiveIcon: {
    width: "24px",
    height: "24px",
    marginBottom: "0px"
  },
  tabIconStyle: {
    minWidth: "25px",
    marginBottom: "-5px"
  },
  drawerStyleWeb: {
    width: "295px",
    borderRight: `1px solid ${CONSTANTS.color.paleGrey}`,
    marginTop: "5.8rem",
    marginLeft: "17.5rem",
    paddingTop: "2.8rem",
    paddingBottom: "4rem"
  },
  marginLeftRight: {
    marginLeft: 0,
    marginRight: "17.5rem"
  },
  drawerStyleMobile: {
    width: "295px",
    borderRight: `1px solid ${CONSTANTS.color.paleGrey}`,
    marginTop: "5.8rem",
    paddingTop: "2.8rem",
    paddingBottom: "4rem"
  }
}

export default withTranslation()(SettingsSideBar);
// Customizable Area End