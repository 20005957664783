import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core/styles"
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    saveMapData:any
    open: boolean|any
    getCustomerDetails: Function
    history: any
    getSiteDetails: Function
    selectedCustomerButtonStatus: any
    getCustomerSelectedStatus: Function
    handleCusValidation?: Function
    crudStatus: string
    location:any
    type:string
    requestId:string|number
    customerDetails:any,
    siteDetails:any
    t?: (key: string) => string;
    languageSelected:string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    searchCustomer: string
    searchSite: string
    customerDetails: any
    customersList: any
    siteDailog: boolean
    getCustomerPlace: any
    customerSitesList: any
    siteDetails: any
    customerSelected: boolean
    customerDailog: any
    siteSelected: boolean
    selectButtonStatus: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class CustomerSitesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdGetCustomers: string = ""
    apiCallIdGetCustomersSites: string = ""
    apiCallIdSearchCustomers: string = ""
    apiCallIdGetSearchCustomerSites: string = ""
    mapRef: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),

            // Customizable Area End
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            searchCustomer: "",
            searchSite: "",
            customerDetails: {},
            customersList: [],
            siteDailog: false,
            getCustomerPlace: {},
            customerSitesList: [],
            siteDetails: {},
            customerSelected: false,
            customerDailog: null,
            siteSelected: false,
            selectButtonStatus: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.setState({
            customerDailog: this.props.open
        })
        if (this.props.open) {
            this.allCustomers()
        }
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.apiCallIdGetCustomers) {
                this.getAllCustomerApiResponse(responseJson)
            } else if (apiRequestCallId === this.apiCallIdGetCustomersSites) {
                this.customerSiteApiResponse(responseJson)
            } else if (apiRequestCallId === this.apiCallIdSearchCustomers) {
                this.customersSearchApiResponce(responseJson)
            } else if (apiRequestCallId === this.apiCallIdGetSearchCustomerSites) {
                this.CustomerSitesSearchApiResponce(responseJson)
            }
        }
        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start
    getAllCustomerApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ customersList: responseJson.data })
        } else if (responseJson.errors) {
            this.setState({ customersList: [] })
        }
    }
    customerSiteApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ customerSitesList: responseJson.data })
        } else if (responseJson.errors) {
            this.setState({ customerSitesList: [] })
        }
    }
    customersSearchApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ customersList: responseJson.data })
        } else if (responseJson.errors) {
            this.setState({ customersList: [] })
        }
    }
    CustomerSitesSearchApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ customerSitesList: responseJson.data })
        } else if (responseJson.errors) {
            this.setState({ customerSitesList: [] })
        }
    }

    allCustomers = () => {
        const token = localStorage.getItem(configJSON.storageToken)

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdGetCustomers = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.allCustomers}?query=${this.state.searchCustomer}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    customerSearch = () => {
        const token = localStorage.getItem(configJSON.storageToken)

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdSearchCustomers = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/account_block/customer_search?query=${this.state.searchCustomer}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    siteSearch = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdGetSearchCustomerSites = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_request_management/sites_list?customer_id=${this.state.customerDetails.id}&query=${this.state.searchSite}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCustomerRequestSite = (custId: number) => {        
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIdGetCustomersSites = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCustomerSites}?customer_id=${custId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Search Functionality
    searchSites = (event: any) => {
        this.setState({ searchSite: event.target.value }, () => {
            this.siteSearch()
        })
    }
    searchCustomers = (event: any) => {
        this.setState({ searchCustomer: event.target.value }, () => {
            this.allCustomers()
        })
    }
    selectCustomer = (data: any) => {
        localStorage.setItem("customer_id", data.id)
        this.setState({
            selectButtonStatus: true,
            customerSelected: true,
            customerDailog: false,
            customerDetails: data,
        }, () => {
            this.handleSiteOpen()
            this.getCustomerRequestSite(data.id)
            this.props.getCustomerDetails(this.state.customerDetails)
        })
    }

    handleCloseDialog = () => {
        this.setState({
            customerDailog: false,
            customersList: [],
        }, () => {
            this.props.getCustomerSelectedStatus(this.state.customerSelected, this.state.customerDailog)
        });
        if (this.props.handleCusValidation) {
            if (!this.state.customerSelected) {
                this.props.handleCusValidation()
            }
        }
      
    }
    //siteDailog
    handleBack=()=>{
        this.setState({customerDailog: true}) 
    }
    handleSiteOpen = () => {
        this.setState({ siteDailog: true })
    }
    handleSiteClose = () => {
        this.setState({ siteDailog: false }, () => {
            this.props.getCustomerSelectedStatus(this.state.customerSelected, this.state.customerDailog)
        },)
        if (this.props.handleCusValidation) {
            if (this.state.customerSelected) {
                this.props.handleCusValidation()
            }
            if (this.state.siteSelected) {
                this.props.handleCusValidation()
            }
        }
        
       
    }
    selectedSiteCustomer = (data: any) => {
        this.setState({
            siteDetails: data,
            siteDailog: false,
            siteSelected: true,
            customerDailog: false
        }, () => {
            this.props.saveMapData(this.state.siteDetails)
            this.props.getCustomerSelectedStatus(this.state.customerSelected, this.state.customerDailog)
            this.props.getSiteDetails(this.state.siteDetails)
            this.props.selectedCustomerButtonStatus(this.state.selectButtonStatus)
        })
    }


    createCustomer = () => {
        localStorage.setItem("cancelRedirection", this.props.location.pathname)
        if(this.props.type==="editRequest"){
            this.props.history.push("/CreateCustomer",{crudType:this.props.type,requestId:this.props.requestId})
        }else if(this.props.type==="editJob"){
            this.props.history.push("/CreateCustomer",{crudType:this.props.type,jobId:this.props.requestId})
        }
        else{
            this.props.history.push("/CreateCustomer")
        }
    }
    createSite = () => {
        localStorage.setItem("cancelRedirection", this.props.location.pathname)
        this.props.history.push("/CustomerSites")
    }
    // Customizable Area End
}