export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mainLogo=require("../assets/logo.png");
export const sidePage=require("../assets/sidePage.png");
export const bgImage=require("../assets/bgimage.png")
export const logo=require("../assets/logo.png")
export const uploadImg=require("../assets/upload.png")
export const completedIcon = require("../assets/completedIcon.png");
export const previewLogo=require("../assets/previewLogo.png")
export const FirstBgImg1=require("../assets/FirstBgImg610px.png");
export const FirstBgImg2=require("../assets/FirstBgImg710px.png");
export const FirstBgImg3=require("../assets/FirstBgImg790px.png");
export const secondBgImg=require("../assets/secondBgImg.png");