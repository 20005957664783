import React from "react";

// Customizable Area Start
import {
  Box,
  Toolbar,
  AppBar,
  Hidden,
  IconButton,
  Typography,
  Avatar,
  Modal,
  Badge
} from "@material-ui/core";
import {
  Theme,
  styled,
  withStyles
} from "@material-ui/core/styles";
import {
  searchIcon,
  notificationIcon,
  questionMarkIcon,
  userIcon,
  dropDownIcon,
} from "./assets";
import MenuIcon from '@material-ui/icons/Menu';
import { DRAWER_WIDTH } from "./utility.web";
import withPermission from "../../../components/src/withPermission.web"
import { withSnackbar } from "../../../components/src/toastweb"
import { CONSTANTS } from "../../../components/src/theme";
import { DashboardNotifications} from "../../notifications/src/DashboardNotifications.web"
import { withTranslation } from "react-i18next";
import { Notifications } from "@material-ui/icons";
import { convertToArabicNumerals } from "../../CustomisableUserProfiles/src/utility.web";
// Customizable Area End

import DashboardNavBarController, {
  Props,
} from "./DashboardNavBarController.web";

// Customizable Area Start

const HEADER_DESKTOP = 92;

export const StyledRoot = styled(AppBar)(({ theme, width }: any) => ({
  boxShadow: "1px 1px",
  borderBottom: `1px ${CONSTANTS.color.paleGrey}`,
  backgroundColor: CONSTANTS.color.white,
  borderBottomStyle: "solid",
  [theme.breakpoints.up('lg')]: {
    width: width ? `calc(100% - ${width}px)` : `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

export const StyledToolbar = withStyles((theme: Theme) => ({
  root: {
    minHeight: HEADER_DESKTOP,
    [theme.breakpoints.down('md')]: {
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: "4px",
      paddingRight: "4px"
    },
  }
}))(Toolbar);

const StyledIconButton = withStyles({
  root: {
    boxShadow: "0px 3px 6px rgba(28,24,175,0.1)",
    marginRight: "1.5rem"
  }
})(IconButton);

export const StyledIconButtonMobile = withStyles({
  root: {
    boxShadow: "0px 3px 6px rgba(28,24,175,0.1)",
    marginRight: (props: any) => props.languageSelected !== "ar" ? "1rem" : 0,
    marginLeft: (props: any) => props.languageSelected === "ar" ? "1rem" : 0,
  }
})(IconButton);
// Customizable Area End

export class DashboardNavBar extends DashboardNavBarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const location = this.props.navigation.history?.location?.pathname;
    const notification_count = this.state.lang === "en"
      ? this.state.notification_count : ''
      //  : convertToArabicNumerals(this.state.notification_count)
    

    return (
      <StyledRoot
        style={this.state.languageSelected === "ar" ? {...webStyle.styledRoot, left: 0, right: "auto"} : webStyle.styledRoot}
        width={location==="/FreeTrialExpired" ? this.props.width:""}
        data-test-id="dashboard-navbar"
      >
        <Modal
          data-test-id="handleClickAwayCreate"
          open={this.state.notifyModal}
          onClose={this.closeNotifyModal} 
        >
          <DashboardNotifications t={function (key: string): string {
            throw new Error("Function not implemented.");
          } } infiniteScroll={false} per_page={4} diaLogBox={true} sliceString={29} {...this.props} />
        </Modal>
        <StyledToolbar>
          <Box style={webStyle.menuIconContainer} className={this.props.classes.menuIconContainer}>
            <Hidden lgUp>
              <IconButton
                onClick={this.props.navMenu}
                style={{marginRight: 1, color: 'text.primary'}}
              >
                <MenuIcon fontSize="default" />
              </IconButton>
            </Hidden>
            <Typography className={this.props.classes.navItemName} variant="h4" style={webStyle.navItemName}>
              {this.props.navMenuItemProps}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            style={webStyle.boxContainer}
            className={this.props.classes.boxContainer}
          >
            <StyledIconButton data-test-id="searchIcon" className={this.props.classes.iconButton} onClick={this.handleSearchButton}>
              <img style={webStyle.searchIcon} src={searchIcon} alt="Icon" />
            </StyledIconButton>
            {this.props.navMenuItemProps !== "Notifications" && this.props.navMenuItemProps !==  "إشعارات" &&
              <>
                <StyledIconButton data-test-id="openNotifyModal" onClick={this.openNotifyModal} className={this.props.classes.iconButton}>
                    <img style={webStyle.notificationIcon} src={notificationIcon} alt="Icon" />
                </StyledIconButton>
                {this.state.notification_count >= 1 &&
                  <Typography variant="caption"
                    style={webStyle.notifyCount}
                    >
                    {this.state.lang === "en"
                      ? this.state.notification_count
                        : convertToArabicNumerals(this.state.notification_count)
                    } 
                  </Typography>
                }
               
              </>
              }
           
          

            <StyledIconButton data-test-id="handleFAQButton" className={this.props.classes.iconButton} onClick={this.handleFAQButton}>
              <img style={webStyle.questionMarkIcon} src={questionMarkIcon} alt="Icon" />
            </StyledIconButton>
            
            <Box style={webStyle.profileContainer} data-test-id = "handleProfile" onClick={this.handleProfile}>
              <IconButton>
                <Avatar style={webStyle.userIcon} src={this.state.loggedInUser.profile_image || userIcon} alt="Profile" />
              </IconButton>

              <Typography variant="caption" style={webStyle.userName} className={this.props.classes.userName}>
                {this.state.loggedInUser.first_name}   {this.state.loggedInUser.last_name}
              </Typography>
              <IconButton className={this.props.classes.dropDownIcon}>
                <img style={webStyle.dropDownIcon} src={dropDownIcon} alt="Icon" />
              </IconButton>
            </Box>
            <Hidden smUp>
              {this.props.navMenuItemProps !== "Notifications" && this.props.navMenuItemProps !==  "إشعارات" &&
                <>
                  <StyledIconButtonMobile languageSelected={this.state.languageSelected} onClick={this.openNotifyModal} className={this.props.classes.notifications}>
                    <img style={webStyle.notificationIcon}  src={notificationIcon} alt="Icon" />
                  </StyledIconButtonMobile>
                 
                </>
              }

            
            </Hidden>
          </Box>
        </StyledToolbar>
      </StyledRoot>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      position:"relative" as const,
    }
  },
  iconButton: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important"
    }
  },

  userName: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 1rem 0 0.5rem !important"
    },
  },
  dropDownIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important"
    },
  },
  navItemName: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px !important",
    },
    "@media(max-width:400px)": {
      maxWidth: "71%",
      height: "38px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
  },
  menuIconContainer: {
    "@media(max-width:385px)": {
      width: "71%",
      height: "38px",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  dashhideLarge :{
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
  dashhideSmall :{
    [theme.breakpoints.down("xs")]: {
      display:"none !important"
     },
  },
  dashdialogBoxIcon: { 
    height: "9px",
    width: "20px",
    position: "fixed" as const,
    top:"75px",
    right:"394px",
    zIndex: 1000,
    
    [theme.breakpoints.down("md")]: {
      right:"378px",
    },
    [theme.breakpoints.down("sm")]: {
      right:"177px",
    },
    [theme.breakpoints.down("xs")]: {
      right:"32px",
    },
   
  },
  dashdialogBoxIconAr: { 
    height: "9px",
    width: "20px",
    position: "fixed" as const,
    top:"75px",
    left:"334px",
    zIndex: 1000,
    
    [theme.breakpoints.down("md")]: {
      left:"319px",
    },
    [theme.breakpoints.down("sm")]: {
      left:"152px",
    },
    [theme.breakpoints.down("xs")]: {
      left:"32px",
    },
   
  },
  dashnotifyHeadingBox:{
    display: "flex", width: "100%", alignItems: "center"
  },
  dashcreatePopup: {
    width: "100%",
    maxWidth: "448px",
    padding:"10px 10px 10px 10px",
    overflowY:"scroll" as const,
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    position: "absolute" as const,
    top:"83px",
    right:"312px",
    
    [theme.breakpoints.down("md")]: {
      right:"293px",
    },
    [theme.breakpoints.down("sm")]: {
      right:"134px",
    },
    [theme.breakpoints.down("xs")]: {
      right:"9px",
      width: "96%",
    },
   
    zIndex: 1000,
    display: "flex",
    flexDirection:"column" as const,
    alignItems: "center",
    gap: "6px",
  },
  dashcreatePopupAr: {
    width: "100%",
    maxWidth: "448px",
    height: "320px",
    overflowY:"scroll" as const,
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    position: "absolute" as const,
    top:"83px",
    left:"290px",
    
    [theme.breakpoints.down("md")]: {
      left:"277px",
    },
    [theme.breakpoints.down("sm")]: {
      left:"115px",
    },
    [theme.breakpoints.down("xs")]: {
      left:"9px",
      width: "96%",
    },
   
    zIndex: 1000,
    display: "flex",
    flexDirection:"column" as const,
    alignItems: "center",
    gap: "6px",
    padding: "1rem",
  },
  dashimgStyles: {
    width: "54px",
    height: "54px",
    [theme.breakpoints.down("xs")]: {
      width: "32px",
      height: "32px"
    },
    borderRadius:"50%"
  },
  dashnotifyheading: {
    color: "#34313a",
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  dashmarkRead: {
    color: "#1c18af",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer"
  },


  dashMoreIcon: {
    color: "#636363",
    alignSelf: "flex-start",
    cursor: "pointer"
  },
  dashMoreIconxs: {
    color: "#636363",
    cursor: "pointer"
  },
  dashReadLess: {
    color: "blue",
    marginLeft: "5px", cursor: "pointer"
  },
  dashCheckIcon: {
    color: "#0b9500", fontSize: "1.2rem"
  },
  dashDeleteIcon: {
    color:"#e02020",fontSize:"1.2rem"
  },
  dashMarkReadColor: {
    color: "#0b9500",
    cursor:"pointer"
  },
  dashmarkReadParent: {
    display: "flex",
    flexDirection: "row" as const,
    gap: "1rem",
    marginLeft: "auto",
  },
  dashmarkReadParentAr: {
    display: "flex",
    flexDirection: "row" as const,
    width: "75%",
    gap: "1rem",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },

  dashMarkUnreadColor: {
    color: "#636363",
    cursor:"pointer"
  },
  dashUnreadColor:{
    color: "#636363",
    fontSize: "1.2rem"
  },
  dashDeleteColor: {
    color: "#e02020",
    cursor:"pointer"
  },
  dashHr:{
    margin: "1rem 0 1rem 0"
  },

  dashtodayDate: {
    color: "#34313a",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer"
  },
  dashnotifyBody: {
    color: "#636363",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    fontSize:"12px",
    fontFamily: "rubik",
    
    wordBreak: "break-word" as const,
   
  },
  dashnotifyTitleWidth: {
    width:"57%"
  },
  dashnoNotifyText: {
    color: "#535353",
    fontWeight: 400,
    textAlign: "center" as const,
    marginTop:"auto"
  },
  dashdeleteText: {
    color: "#EB220A",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer"
  },
  dashnotifyTitle: {
    color: "#34313a",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  dashnotifyContainer: {
    display: "flex",
    flexDirection: "row" as const,
    width: "100%",
    gap:"0.5rem",
    alignItems: "center" as const,
    marginBottom:"1rem",
    cursor:"pointer"
  },
  dashnotifyImageParent: {
    width: "15%",
    display: "flex"
  },
  dashbadgeIcon: {
    color: "#1C18AF",
    fontSize: "0.8rem",
    position: "absolute" as const,
    left: "42px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
    },
  },
  dashbadgeIconAr: {
    color: "#1C18AF",
    fontSize: "0.8rem",
    position: "absolute" as const,
    right: "42px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
    },
  },
  dashnotifyBodyContainer: {
    display: "flex", flexDirection: "column" as const, width: "85%"
  },
  dashnotifyBodyParent:{
    display: "flex", 
    flexDirection: "row" as const, 
    justifyContent: "space-between",
     alignItems: "flex-end"  as const,
     [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"  as const,
    },
  },
  dashpopoverItems:{
    display:"flex",
    gap:"0.5rem",
    alignItems:"center",
    cursor:"pointer"
  },
  dashsmallPopup:{
    width: "173px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    gap: "6px",
    padding: "0.5rem",
  },
  dashnotifyCount:{
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#1c18af",
    color: "white"
  },
  notifications : {
    "@media(max-width:412px)":{
      width : "40px",
      height : "40px",
    }
  }
});

const webStyle = {
 
  styledRoot: {
    zIndex: 1000
  },
  navItemName: {
    color: CONSTANTS.color.darkSteelBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  menuIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  boxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position:"relative" as const,
  },
  searchIcon: {
    width: "20px",
    height: "20px",
  },
  positionStyle: {
    position: 'relative'as const
  },
  notificationLength: {
    width: "14px",
    height: "14px",
    position: "absolute" as const,
    transform: "translate(-246%, -118%)",
    top: "50%",
    left: "50%",
  },
  notificationIcon: {
    width: "20px",
    height: "22px",
  },
  notificationCount: {
    position: 'relative' as const,
    transform: "translate(10px, 0px)"
  },

  questionMarkIcon: {
    width: "20px",
    height: "20px",
  },
  userIcon: {
    width: "41px",
    height: "41px",
  },
  dropDownIcon: {
    width: "12px",
    height: "7px",
  },
  userName: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    color: CONSTANTS.color.black,
    margin: "0 2rem 0 0.5rem"
  },
  profileContainer: {
    display: "flex",
    alignItems: "center",
    marginRight:"1rem",
    cursor: "pointer"
  },
  notifyCount:{
    width: "16px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#FF0000",
    color: "white",
    top: "-12px",
    position: "relative" as const,
    left: "-8%",
  },
}

const dashboardNavBarStyles = withStyles(styles)(DashboardNavBar);
const dashboardNavBarSnack = withSnackbar(dashboardNavBarStyles)
export default withTranslation()(withPermission(dashboardNavBarSnack))
// Customizable Area End