import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { checkLoggedInUserSettings } from "./utility.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { apiCall, checkTokenExpired } from "../../../components/src/CommonFunction";

type GetCardData = {
    id: number,
    number: string,
    exp_month: string,
    exp_year: string,
    cvc: string,
    name: string,
    tap_card_id: string,
    account_id: number,
    created_at: string,
    updated_at: string,
    card_type: string,
    default_set: boolean
  }

type ErrorFormData = {
  cardName?: string;
  cardNumber?: string;
  expDate?: string;
  cvv?: string;
  paymentCard?: string;
}
interface Navigation {
    navigate: Function
}
interface History {
    push: Function
}
interface Location{

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: Navigation
    // Customizable Area Start
    openToastHandler: Function
    history: History
    location: Location
    t: (key: string) => string;
    classes: ClassNameMap<string>;
    // Customizable Area End
}

interface S {
  // Customizable Area Start
  paymentCard: string;
  plan:string
  showText: boolean;
  cardFormData: {
    cardName: string;
    cardNumber: string;
    expDate: string;
    cvv: string;
  };
  // savePayment: boolean;
  addNewCard: boolean;
  isErrorFormData: ErrorFormData;
  isEmployee: boolean;
  loading: boolean
  selectCard:boolean
  languageSelected: string;
  openCancelTransactionModal:boolean
  currency:string
  getCardData:GetCardData[]
  defaultSet:boolean
  anchorEl: null | HTMLElement;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string|number;
  // Customizable Area End
}

export default class AddPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCardApiCallId: string = ''
  deleteCardApiCallId: string = ''
  getCardApiCallId: string = ''
  setDefaultCardApiCallId: string = ''
  getCurrencyApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      paymentCard: "",
      selectCard:false,
      plan:"Gold",
      showText: false,
      cardFormData: {
        cardName: "",
        cardNumber: "",
        expDate: "",
        cvv: "",
      },
      addNewCard: false,
      isErrorFormData: {},
      isEmployee: false,
      loading: false,
      languageSelected: "",
      openCancelTransactionModal:false,
      currency:'',
      getCardData:[],
      defaultSet:false,
      anchorEl: null
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });
    
    const user = await checkLoggedInUserSettings()
    this.setState({ isEmployee: user })
    this.getCard()
    this.getCurrency()
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
       if (apiRequestCallId === this.getCardApiCallId) {
        this.getCardApiResponse(responseJson)
       } else if (apiRequestCallId === this.deleteCardApiCallId) {
        this.deleteCardApiResponse(responseJson)
       } else if (apiRequestCallId === this.createCardApiCallId) {
        this.createCardApiResponse(responseJson)
       } else if (apiRequestCallId === this.setDefaultCardApiCallId) {
         this.setDefaultCardApiResponse(responseJson)
       }else if (apiRequestCallId === this.getCurrencyApiCallId) {
        this.getCurrencyApiResponse(responseJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCurrencyApiResponse(responseJson:{currency_code:string,errors:string}) {
    if (responseJson && !responseJson.errors) {
      this.setState({ currency:responseJson.currency_code })
    } else {
      if (responseJson&&responseJson.errors) {
        this.setState({ currency:'' })
      }
    }
  }
  getCardApiResponse = (responseJson: GetCardData[]) => {
    if (responseJson && responseJson.length > 0) {
      this.setState({ getCardData: responseJson, loading: false,selectCard:false })
    } else {
        this.setState({ getCardData: [], loading: false })
    }
  };
  createCardApiResponse = (responseJson: { error: string }) => {
    if (responseJson && !responseJson.error) {
      this.setState({ loading: false ,selectCard: true})
      this.getCard()
    } else {
        if (responseJson && responseJson.error) {
          this.props.openToastHandler(responseJson.error, "error")
            this.setState({loading: false })
        }
    }
  };
  setDefaultCardApiResponse = (responseJson: { error: string }) => {
    if (responseJson && !responseJson.error) {
      this.setState({ loading: false })
      this.getCard()
    } else {
      if (responseJson && responseJson.error) {
        this.props.openToastHandler(responseJson.error, "error")
        this.setState({ loading: false })
      }
    }
  };
  deleteCardApiResponse=(responseJson: { errors: string; message: string; })=>{
    if (responseJson && !responseJson.errors) {
      this.setState({ loading: false })
      this.props.openToastHandler(responseJson.message, "success")
      this.getCard()
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history)
        this.setState({ loading: false })
      }
    }
  }
  handleSelectCard = (title: string) => {
    this.setState({ paymentCard: title ,selectCard:true});
  }

  handleMouseEnter = () => {
    this.setState({ showText: true });
  };

  handleMouseLeave = () => {
    this.setState({ showText: false });
  };

  handleChangeCardData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'expDate') {
      let formattedValue = value.replace(/\D/g, '');
  
      if (formattedValue.length > 2) {
        const month = formattedValue.slice(0, 2);
        const year = formattedValue.slice(2);
        formattedValue = `${month}/${year}`;
      }
  
      this.setState((prevState) => ({
        cardFormData: {
          ...prevState.cardFormData,
          [name]: formattedValue
        }
      }));
    } else if (name === 'cardNumber') {
      let formattedValue = value.replace(/\D/g, '');
  
      if (formattedValue.length > 16) {
        formattedValue = formattedValue.slice(0, 16);
      }
  
      formattedValue = formattedValue.trim();
  
      this.setState((prevState) => ({
        cardFormData: {
          ...prevState.cardFormData,
          [name]: formattedValue
        }
      }));
    } else if (name === 'cvv') {
      let formattedValue = value.replace(/\D/g, '');
  
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0, 3);
      }
  
      this.setState((prevState) => ({
        cardFormData: {
          ...prevState.cardFormData,
          [name]: formattedValue
        }
      }));
    } else if (name === 'cardName') {
      const formattedValue = value.replace(/[^A-Za-z ]/g, '');
      this.setState((prevState) => ({
        cardFormData: {
          ...prevState.cardFormData,
          [name]: formattedValue
        }
      }));
    } else {
      this.setState((prevState) => ({
        cardFormData: {
          ...prevState.cardFormData,
          [name]: value
        }
      }));
    }
  }
  validateFormData = (values: ErrorFormData) => {
    const { t } = this.props;
    const errors: ErrorFormData = {};
    const regexCardName = /^(?!.*(?: [^a-zA-Z']|[a-zA-Z'] [^a-zA-Z']) )[a-zA-Z']+(?: [a-zA-Z']+)*/;
    
    if (!values.cardName) {
      errors.cardName = t('settings.cardNameRequired');
    } else if (!regexCardName.test(values.cardName)) {
      errors.cardName = t('settings.pleaseEnterCardName');
    }

    if (!values.cardNumber) {
      errors.cardNumber = t('settings.cardNumberRequired');
    }

    if (!values.expDate) {
      errors.expDate = t('settings.expDateRequired');
    } else {
      const [month, year] = this.state.cardFormData.expDate.split('/');
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      const inputYear = Number(year);
      const currentYearLastTwoDigits = currentYear % 100;

      const inputMonth = Number(month);

      if (
        inputYear < currentYearLastTwoDigits ||
        (inputYear === currentYearLastTwoDigits &&
        (inputMonth < 1 || inputMonth > 12 || inputMonth <= currentMonth))
      ) {
        errors.expDate = t('settings.expDateFuture');
      }
    }

    if (!values.cvv) {
      errors.cvv = t('settings.cvvRequired');
    }
    
    return errors;
  }

  handleCancelButton = () => {
    this.setState({ openCancelTransactionModal: true });
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({ defaultSet: event.target&&event.target.checked })
  }
  handleCloseCancelTransactionModal = () => {
    this.setState({ openCancelTransactionModal: false });
  }
  handleContinue = () => {
    this.setState({
      cardFormData: {
        cardName: "",
        cardNumber: "",
        expDate: "",
        cvv: "",
      },
      openCancelTransactionModal: false,
      selectCard: false
    });
  }
  handleSaveButton = () => {
    this.setState({ isErrorFormData: this.validateFormData(this.state.cardFormData) },
      () => {
        if(Object.keys(this.state.isErrorFormData).length === 0) {
          this.addNewCard()
          this.setState({
            cardFormData: {
              cardName: "",
              cardNumber: "",
              expDate: "",
              cvv: "",
            },
          });
        }
      }
    );
  }
  handleDelete=(deleteId:number)=>{
    this.deleteCard(deleteId)
  }
  
  handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = (setDefaultCardNo:number) => {
    this.setState({ anchorEl: null });
    this.setDefaultCard(setDefaultCardNo)
  };
  getCard= () => {
    const header = {
      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": localStorage.getItem(configJSON.authToken),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ loading: true })
    this.getCardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCardAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.getAPIMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getCurrency = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const getCurrencyApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${configJSON.getCurrency}`,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.getCurrencyApiCallId = getCurrencyApiCall.messageId;
    runEngine.sendMessage(getCurrencyApiCall.id, getCurrencyApiCall);
  }
  addNewCard = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    this.setState({ loading: true })
    const expDate = this.state.cardFormData.expDate.replace('/', '');
    const expMonth = expDate.slice(0, 2);
    const expYear = expDate.slice(2);
    let formData = new FormData();
    formData.append("[number]", this.state.cardFormData.cardNumber);
    formData.append("[exp_month]", expMonth);
    formData.append("[exp_year]", expYear);
    formData.append("[cvc]", this.state.cardFormData.cvv);
    formData.append("[name]",  this.state.cardFormData.cardName);
    formData.append("[card_type]", this.state.paymentCard);
    formData.append("[currency]", this.state.currency);
    formData.append("[default_set]", this.state.defaultSet.toString())
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createCardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addCardAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setDefaultCard = (setDefaultCardNo:number) => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    this.setState({ loading: true })
    let formData = new FormData();
    formData.append("[id]", setDefaultCardNo.toString());
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setDefaultCardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setDefaultCardAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteCard = (cardId:number) => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ loading: true })
    this.deleteCardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCardAPIEndPoint}?id=${cardId}}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End