// @ts-nocheck
import React from "react";

// Customizable Area Start
import {
  Typography,
  // Customizable Area Start
  Container,
  Grid
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area End

import ProjectTemplatesController, {
  Props,
  configJSON,
} from "./ProjectTemplatesController";

export default class ProjectTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ProjectTemplatesAuctionView = () => {
    return (
      <>
        {this.state.productList && this.state.productList.map((item) => (
          <Grid item xs={12} style={{ margin: "20px 0px",backgroundColor: "#d3dbe8", padding:"10px", borderRadius:"10px" }} key={item.id} >
            <Grid container>
              <Grid item>
                <img src={item?.attributes?.image?.url} alt="image" style={{ width: "120px", height: "120px", borderRadius: "20px" }}/>
              </Grid>
              <Grid item xs style={{ paddingLeft: "20px" }}>
                <Typography className="textStyle">{item?.attributes?.name}</Typography>
                <div dangerouslySetInnerHTML={{__html:item?.attributes?.description}}/>
                <Typography className="textStyle">{item?.attributes?.status?item?.attributes?.status:''}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {this.state.productList == null &&
          <Grid item xs={12} style={{ margin: "20px 0px" }} >
            <Typography className={"textStyle"}>No Product Found</Typography>
          </Grid>
        }
      </>

    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={"md"}>
        {this.ProjectTemplatesAuctionView()}
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End