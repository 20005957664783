import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
//@ts-ignore
import Pdf from "react-to-pdf";

const ref = React.createRef();

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SaveAsPdfController, { Props, configJSON } from "./SaveAsPdfController";

export default class SaveAsPdf extends SaveAsPdfController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <Container ref={ref} maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>
            <Pdf targetRef={ref} filename="code-example.pdf">
              {/* @ts-ignore */}
              {({ toPdf }) => (
                <Box
                  data-test-id="btnAddExample"
                  onClick={toPdf}
                  component="button"
                  sx={webStyle.buttonStyle}
                >
                  <Button color={"primary"}>
                    {configJSON.btnExampleTitle}
                  </Button>
                </Box>
              )}
            </Pdf>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
