import React from "react";
import {
  Container,
  Button,
  Typography,
  withStyles,
  createStyles, Grid
  // Customizable Area Start
  , Theme, Hidden
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { withRouter } from "react-router";
import { languageLogo1, languagelogo2, logo, mobileLogo } from "./assets";
import { sidePage3, sidePage1, sidePage2, firstSideImg } from '../../email-account-login/src/assets';
import { StyledButton } from "../../email-account-registration/src/utility.web";
import LanguageSupportWebController, { Props } from "./LanguageSupportcontroller.web";
import { FirstBgImg1, secondBgImg } from "../../email-account-registration/src/assets";
import { withTranslation } from "react-i18next";
import { CONSTANTS } from "../../../components/src/theme";
// Customizable Area End

export class LanguageSupport extends LanguageSupportWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid container direction="row" alignItems="center" className={classes.imageContainer}>
          <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.bgImg} />
          <Grid item xs={12} sm={1} md={2} lg={2} />

          <Grid item xs={12} sm={5} md={5} lg={5} xl={4} >
            <Grid container direction="row" spacing={2} style={{ padding: 20 }}>
              <Grid item xs={12}>
                <img src={logo} className={classes.logoImg} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.titletext}>{t('languageSupport.heading')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subText}>{t('languageSupport.chooseLanguage')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={6}>
                    <div className={this.state.selectedLanguage =="en"?classes.selectedbutton:classes.button} onClick={()=>this.changeLanguage('en')}>
                      <div>
                        <Typography variant="caption" className={classes.btntext}>{t('languageSupport.enLanguage')}</Typography>
                      </div>
                      <div>
                        <img src={languageLogo1} className={classes.btnIcon} />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={this.state.selectedLanguage =="ar"?classes.selectedbutton:classes.button} 
                    onClick={()=>this.changeLanguage('ar')}
                    data-test-id='selectLanguage'
                    >
                      <div>
                        <Typography variant="caption" className={classes.btntext}>{t('languageSupport.arLanguage')}</Typography>
                      </div>
                      <div>
                        <img src={languagelogo2} className={classes.btnIcon} />
                      </div>
                    </div>
                  </Grid>

                </Grid>

              </Grid>
              <Grid item xs={12}>
                <Typography color="error" variant="caption">{this.state.languageError}</Typography>

              </Grid>
              <Hidden only={'xs'}>

                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.selectedBtn} style={{ marginTop: "20px" }}>
                    <StyledButton className={classes.languageBtn} data-test-id="nextBtn" onClick={this.handleNext}>
                      <Typography variant="caption" className={classes.btntext}>Next</Typography>
                    </StyledButton>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Grid item xs={12} className={classes.languageNextBtn}>
              <StyledButton className={classes.languageBtn} data-test-id="nextBtn" onClick={this.handleNext}>
                <Typography variant="caption" className={classes.btntext}>Next</Typography>
              </StyledButton>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={1} lg={2} xl={3} className={classes.languageSecondLogo}>
            <img className={classes.languageImage2} src={firstSideImg} alt="firstSideImg" />
          </Grid>

        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const LanguageSupportStyles=((theme: Theme) =>
  createStyles({
    imageContainer: {
      [theme.breakpoints.up("sm")]: {
        height: '100%',
      },
    },
    bgImg: {
      backgroundImage: `url(${FirstBgImg1})`,
      height: "100vh",
      backgroundSize: "100% 100%",
      position: "relative",
      transform: "rotateY(180deg)",
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${mobileLogo})`,
        maxHeight: "86px",
        transform: "none"
      },
      [theme.breakpoints.up("sm")]: {
        backgroundImage: `url(${sidePage1})`,
      },
      [theme.breakpoints.between("sm", 'md')]: {
        backgroundImage: `url(${sidePage2})`,
      },
      [theme.breakpoints.between('md', "lg")]: {
        backgroundImage: `url(${sidePage3})`,
      },
    },

    titletext: {
      fontFamily: "Rubik",
      fontSize: "28px",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 30,

      },
    },
    logoImg: {
      width: "150px",
      [theme.breakpoints.down("xs")]: {
        marginTop: 20

      },
    },
    button: {
      width: '100%',
      height:64,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      color:CONSTANTS.color.midGrey,
      border: `1px solid ${CONSTANTS.color.borderGreyLight}`,
      borderRadius: 10,
      cursor:'pointer',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        marginTop: "0.5rem"
      },
    },
    selectedbutton:{
      width: '100%',
      height:64,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      background:CONSTANTS.color.invisibleBlue,
      color:theme.palette.common.black,
      borderRadius: 10,
      cursor:'pointer',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        marginTop: "0.5rem"
      },
    },
    btntext: {
      fontFamily: "Rubik",
      fontSize: "18px",
      fontWeight: 400,
      textTransform: "none"
    },
    btnIcon: {
      marginLeft: "43px"
    },
    buttongrid: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"

      },
    },
    subText: {
      height: "21px",
      color: "#999999",
      fontFamily: "Rubik",
      fontSize: "18px",
      fontWeight: 400
    },
    selectedBtn: {
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      },
    },
    secondImage: {
      height: "100%",
      alignItems: "end",
      display: "flex",
      justifyContent: "end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "start"
      },
    },
    languageImage2: {
      width: '80px',
      [theme.breakpoints.down('xs')]: {
        width: '47px',
        height: '47px'
      }
    },
    languageSecondLogo: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'end',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0
      }
    },
    languageBtn: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    languageNextBtn: {
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: "10%",
        width: "100%",
        padding: '0 20px'
      }
    }
  })
)
const LanguageComponent=withStyles(LanguageSupportStyles)(LanguageSupport);
export default withTranslation()(LanguageComponent)
// Customizable Area End