import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography, Hidden
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import {
  searchIcon,
  openIcon,
  closeIcon,
  whiteBackArrowIcon
} from "./assets";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { StyledRoot } from "./utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { goBack } from "../../../components/src/CommonFunction";
import { withTranslation } from "react-i18next";
// Customizable Area End

import FAQsController, {
  Props,
} from "./FAQsController.web";

// Customizable Area Start
type FAQsData = {
  id: number;
  attributes: {
    question: string;
    answer: string;
  };
}
// Customizable Area End

export class FAQs extends FAQsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { show, faqsData, selectedFaq, searchFaq } = this.state;
    const { t } = this.props;
    return (
      <>
        <DashboardSideBar oldProps={undefined} permissions={undefined} {...this.props} navMenuItem={t('faqs.faqsLabel')} active={false} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.languageSelected}>
          <Box sx={webStyle.container} className={classes.container}>

            <Box style={webStyle.faqContainer} className={classes.faqContainer}>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>

                <img src={whiteBackArrowIcon}
                  data-test-id="back_icon"
                  style={this.state.languageSelected === "ar" ? {transform: "rotate(180deg)"} : {}}
                  onClick={() => goBack(this.props.navigation)}
                  width={12} height={24} alt="mobile arrow" />
              </Hidden>
              <Typography variant="h2" style={webStyle.faqHeading} className={classes.faqHeading}>
                {t('faqs.frequentlyAskedQuestions')}
              </Typography>
              <Typography variant="subtitle1" style={webStyle.faqSubHeading} className={classes.faqSubHeading}>
                {t('faqs.haveQuestions')}
              </Typography>
            </Box>
            <Box style={webStyle.searchQuestionsBox} className={classes.searchQuestionsBox}>
              <Grid container>
                <Grid item lg={8} md={12} xs={12}>
                  <Box style={webStyle.searchContainer}>
                    <Box>
                      <img style={webStyle.searchIcon} src={searchIcon} alt="" />
                    </Box>
                    <InputBase
                      data-test-id="handleChangeSearchFaqs"
                      style={webStyle.inputBase}
                      placeholder={t('faqs.search')}
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchFaq}
                      onChange={this.handleChangeSearchFaqs}
                    />
                  </Box>
                </Grid>
                <Grid container spacing={2} style={webStyle.questionsGrid}>
                  {faqsData?.map((item: FAQsData) => {
                    const { question, answer } = item?.attributes;
                    return (
                      <Grid item lg={12} md={12} xs={12} key={item?.id}>
                        <Box style={webStyle.questionsContainer}>
                          <Box>
                            <Typography variant="subtitle1" className={classes.questionText} style={webStyle.questionText}>
                              {question}
                            </Typography>
                            {(show && item.id === selectedFaq) &&
                              <Typography
                                className={classes.questionText}
                                style={webStyle.answerText}
                                variant="body1"
                                color="textPrimary"
                              >
                                {answer}
                              </Typography>
                            }
                          </Box>
                          <img
                            data-test-id="handleShowAnswer"
                            style={webStyle.closeIcon}
                            src={(show && item.id === selectedFaq) ? closeIcon : openIcon}
                            alt=""
                            onClick={() => this.handleShowAnswer(item?.id)}
                          />
                        </Box>
                        {item?.id !== faqsData[faqsData.length - 1]?.id && <span style={webStyle.hr}></span>}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {faqsData?.length === 0 &&
                <Typography style={webStyle.noDataFound} variant="subtitle1">
                  {t('faqs.noDataFound')}
                </Typography>
              }
            </Box>
          </Box>
        </StyledRoot>
        <LoadingBackdrop
          loading={this.state.getFaqsLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "12rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "8rem !important",
    },
  },
  faqContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 1.5rem !important",
      gap: "1.5rem !important"
    }
  },
  faqSubHeading: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: "24px !important",
    }
  },
  searchQuestionsBox: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1.5rem 0px !important",
    },
  },
  faqHeading: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
      width: 307
    },
  },
  questionText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: '18px !important',
    },

  }
});

const webStyle = {
  container: {
    marginTop: "6rem",
    paddingBottom: "20rem",
  },
  faqContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    maxWidth: "100%",
    minHeight: "257px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.darkBlue,
    padding: "0 3.5rem",
    gap: "6px"
  },
  faqHeading: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  faqSubHeading: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  searchQuestionsBox: {
    padding: "2rem 3rem 0"
  },
  searchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.3)",
  },
  inputBase: {
    width: "100%",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    color: CONSTANTS.color.lightBlack
  },
  questionsGrid: {
    paddingTop: "4rem"
  },
  questionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 0",
    alignItems:"center"
  },
  questionText: {
    color: CONSTANTS.color.forestGreen,
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  answerText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    linHeight: "24px",
    maxWidth: "848px",
    width: "100%",
    paddingTop: "10px"
  },
  closeIcon: {
    width: "31px",
    height: "31px",
    cursor: "pointer",
    marginTop:"3px"
  },
  hr: {
    display: "flex",
    marginTop: "1rem",
    border: "1px solid rgba(0,0,0,0.2)"
  },
  noDataFound: {
    color: CONSTANTS.color.darkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
}

const faqStyles = withStyles(styles)(FAQs);
const faqsSnackbar = withSnackbar(faqStyles);
export default withTranslation()(faqsSnackbar);
// Customizable Area End