import React, { useState } from "react";
import {
    Box,
    InputBase,withStyles,Theme
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import DatePicker,{registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../blocks/dashboard/src/ReactDatePicker.css";
import { calenderNonActive } from "../../blocks/JobListing2/src/assets";
import {
    imagebutton_left,
    imagebutton_right,
  } from "../../blocks/dashboard/src/assets";
import moment from "moment";
import 'moment/locale/ar'
import { CONSTANTS } from "./theme";
import { ar } from "date-fns/locale";
const selectedLanguage = localStorage.getItem("lang");
if (selectedLanguage === 'ar') {
    registerLocale("ar", ar);
    moment.locale('ar')
} else {
    moment.locale('en')
}
const CustomDatePicker = (props: any) => {
    const [selectedDate, setSelectedDate] = useState(props?.selectedDate);
    const [minDate,setMinDate]=useState(props?.minDate)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    React.useEffect(() => {
        if (props?.selectedDate) {
            
            setSelectedDate(props?.selectedDate);

            setAnchorEl(null);
        }
    }, [props?.selectedDate]);

    React.useEffect(() => {
        if (props?.minDate) {
            setMinDate(props?.minDate);
        }
    }, [props?.minDate]);
    
    const handleClose = () => {
        setAnchorEl(null);
      };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        if(props?.disabled){
            setAnchorEl(null);
        }
    };
    const { classes } = props;
    return (
        <>
            <div >
                <Box style={webStyle.container} onClick={handleClick}>
                    <Box style={webStyle.clockIconContainer} className={selectedLanguage === 'ar' ? classes.transform : ''}>
                        <img style={webStyle.clockIcon} className={selectedLanguage === 'ar' ? classes.transform : ''} src={calenderNonActive} alt="" />
                    </Box>
                    <InputBase
                        className={selectedLanguage === "ar" ? `${classes.inputBaseStyle} ${classes.transform}` : classes.inputBaseStyle}
                        disabled={props?.disabled}
                        defaultValue={selectedDate}
                        value={moment(selectedDate).format("DD/MM/YYYY").toString()}
                    />
                </Box>
            </div>
            <StyleMenu
                id="customized-menu"
                keepMounted
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Box style={webStyle.timeValuesContainer}>
                    <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        data-test-id="datePicker"
                        selected={selectedDate}
                        onChange={props.dateChange}
                        inline
                        minDate={minDate}
                        locale="ar"
                        renderCustomHeader={({
                            date,
                            decreaseMonth,
                            increaseMonth,
                        }) => (
                            <>
                                <div style={webStyle.customHeader}>
                                <span style={webStyle.textFormate}>{moment(date).format("MMMM YYYY")}</span>
                                <img
                                    src={selectedLanguage === "ar" ? imagebutton_right : imagebutton_left}
                                    style={webStyle.imageButton}
                                    onClick={decreaseMonth}
                                    alt=""
                                />
                                <img
                                    src={selectedLanguage === "ar" ? imagebutton_left : imagebutton_right}
                                    style={webStyle.imageButton}
                                    onClick={increaseMonth}
                                    alt=""
                                />
                                </div>
                                <span style={webStyle.dateHr}></span>
                            </>
                        )}
                        renderDayContents={(_, date) => {
                            return <span>{moment(date).format("DD")}</span>;
                        }}
                    />
                </Box>
            </StyleMenu>
        </>
    );
}

const webStyle = {
    container: {
        display: "flex",
        borderRadius: "10px",
        border: "1px solid rgba(0,0,0,0.1)",
        cursor: "pointer",
    },
    clockIconContainer: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: "68px",
        height: "56px",
        borderRadius: "10px 0 0 10px",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    clockIcon: {
        width: "20px",
        height: "20px"
    },
    arrows: {
        width: "7px",
        height: "11px",
        cursor: "pointer"
    },
    timeValuesContainer: {
        marginTop: "1rem",
        maxWidth: "367px",
        borderRadius: "12px",
    },
    arrowsContainer: {
        padding: "1rem 2rem 1rem 2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px"
    },
    amPmContainer: {
        width: "93px",
        height: "40px",
        borderRadius: "8px",
        border: "1px solid #e5e5e5",
        cursor: "pointer"
    },
    textFormate: {
        fontSize: "16px",
        fontWeight: CONSTANTS.fontWeight.fontWeight600,
        color: CONSTANTS.color.darkBlue
    },
    arrowIcon: {
        fontSize: '20px',
        marginTop: "-3px",
        color: "#ccc",
        fontWeight: 600,
        cursor: "pointer"
    },
    amPmStyle: {
        color: "#1c18af",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        borderRadius: "6px"
    },
    timeValues: {
        overflow: "scroll" as const,
        height: "85%",
    },
    
    timeValueItemContainer: {
        display: "flex",
        gap: "1.5rem",
        padding: "1rem 2rem",
        cursor: "pointer",
        justifyContent: "center"
    },
    timeValueItem: {
        color: "#19181a",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 400,
    },
    customHeader: {
        margin: "0px 6px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    imageButton: {
        height: "25px",
        width: "25px",
        cursor: "pointer"
    },
    dateHr: {
        display: 'flex',
        height: "1px",
        margin: "10px -18px",
        backgroundColor: CONSTANTS.color.darkBlue
    },
}
export const styles = (theme: Theme) => ({
    transform:{
        transform: 'rotate(180deg)'
    },
    inputBaseStyle: {
        width: "100%",
        color: CONSTANTS.color.midGrey,
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "0 10px 10px 0",
        paddingLeft: "0.5rem",
        cursor: "pointer",
        "& .MuiInputBase-input": {
            transform: selectedLanguage==='ar'? 'rotate(180deg)':"",
            textAlignLast:"auto"
        }
    },
  });
const StyleMenu = withStyles({
    paper: {
      borderRadius: "5px",
      background:"none",
      width:'243px',
      overflow:'hidden'
    },
  })((props:MenuProps) => (
    <Menu
      elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
export default withStyles(styles)(CustomDatePicker);
