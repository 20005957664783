//@ts-nocheck
import React from "react";
// Customizable Area Start
import BussinessWeb from "./Bussiness.web";
import BeforeWeStart from "./Before.web";
import Profilesetup from "./ProfileSetup.web";
// Customizable Area End

export default class StepperWeb extends React.Component {
  constructor(props: any) {
    super(props);
  }
  // Customizable Area Start
  getStepContent = (stepNumber: any, stepLabel: string) => {
    switch (stepNumber) {
      case 0:
        return (<Profilesetup
          getSteps={this.props.getSteps}
          activeStep={this.props.activeStep}
          handleProfileChange={this.props.handleProfileChange}
          profile={this.props.profile}
          handleLoginSubmit={this.props.handleLoginSubmit}
          isProfileError={this.props.isProfileError}
          progressbarclass={this.props.progressbarclass}
          handleBack={this.props.handleBack}
          countryCode={this.props.countryCode}
          handleCountryCodeChange={this.props.handleCountryCodeChange}
          countryCodeInputValue={this.props.countryCodeInputValue}
          countryCodeInputChange={this.props.countryCodeInputChange}
          countryName={this.props.countryName}
          handleCountryChange={this.props.handleCountryChange}
          countryInputValue={this.props.countryInputValue}
          countryInputChange={this.props.countryInputChange}
        />);
      case 1:
        return (<BussinessWeb
          getSteps={this.props.getSteps}
          activeStep={this.props.activeStep}
          handleBussinessChange={this.props.handleBussinessChange}
          bussiness={this.props.bussiness}
          isBussinessError={this.props.isBussinessError}
          handlebussinessForm={this.props.handlebussinessForm}
          progressbarclass={this.props.progressbarclass}
          handleBack={this.props.handleBack}
          handleLogo={this.props.handleLogo}
          handleSelectFiles={this.props.handleSelectFiles}
          handleOnDragOver={this.props.handleOnDragOver}
          handleOnDrop={this.props.handleOnDrop}
          handleProgressEvent={this.handleProgressEvent}
          companyLogo={this.props.companyLogo}
          progress={this.props.progress}
          handleDeleteUploadedLogo={this.props.handleDeleteUploadedLogo}
          completed={this.props.completed}
          extensionIcons={this.props.extensionIcons}
          otherField={this.props.otherField}
          otherOnChange={this.props.otherOnChange}
          otherFielsText={this.props.otherFielsText}

        />);
      case 2:
        return (<BeforeWeStart
          getSteps={this.props.getSteps}
          activeStep={this.props.activeStep}
          referbefore={this.props.referbefore}
          handleChangeReferBefore={this.props.handleChangeReferBefore}
          SignUp={this.props.SignUp}
          progressbarclass={this.props.progressbarclass}
          handleBack={this.props.handleBack}
          showOtherField={this.props.showOtherField}
          handleOther={this.props.handleOther}
          othertext={this.props.othertext}

        />)
      default:
        return (<Profilesetup
          getSteps={this.props.getSteps}
          activeStep={this.props.activeStep}
          handleProfileChange={this.props.handleProfileChange}
          profile={this.props.profile}
          handleLoginSubmit={this.props.handleLoginSubmit}
          isProfileError={this.props.isProfileError}
          handleBack={this.props.handleBack}
          countryCode={this.props.countryCode}
          handleCountryCodeChange={this.props.handleCountryCodeChange}
          countryCodeInputValue={this.props.countryCodeInputValue}
          countryCodeInputChange={this.props.countryCodeInputChange}
          countryName={this.props.countryName}
          handleCountryChange={this.props.handleCountryChange}
          countryInputValue={this.props.countryInputValue}
          countryInputChange={this.props.countryInputChange}
        />);
    }
  }
  // Customizable Area End
  render() {
    //@ts-ignore
    return (
      // Customizable Area Start
      <>
        {this.getStepContent(this.props.activeStep, this.props.activeLabel)}
      </>
      // Customizable Area End

    )
  }
}
