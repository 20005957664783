export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const requestIcon = require("../assets/requestIcon.png");
export const jobReportIcon = require("../assets/jobReportIcon.png");
export const jobFinancialIcon = require("../assets/jobFinancialIcon.png");
export const timesheetIcon = require("../assets/timesheetIcon.png");
export const rightArrow = require("../assets/rightArrow.png");
export const leftArrow = require("../assets/leftArrow.png");
export const searchIcon = require("../assets/searchIcon.png");
export const emptyIcon = require("../assets/emptyIcon.png");
export const plusIcon = require("../assets/plusIcon.png");
export const dropDownArrow = require("../assets/dropDownArrow.png");
export const backIconMobile = require("../assets/backIconMobile.png");
export const radioButton = require("../assets/radioButton.png");
export const radioButtonOff = require("../assets/radioButtonOff.png");
export const emptyImage = require("../assets/emptyImage.png");