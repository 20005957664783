import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  InputBase,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { DeleteDialog } from "./DeleteDialog.web";
import { withRouter } from "react-router-dom";

import {
  StyledBoxContainer,
  StyledBox,
  StyledBoxInternal,
  StyledTypography,
  StyledGrid,
  StyledTextFields,
} from "./CreateCustomer.web";
import AutocompleteV1 from "@material-ui/lab/Autocomplete";
import { countryCodes } from "../../dashboard/src/utility.web";
import { StyledTextFieldsAutoComplete } from "./CreateSupplier.web";
import "./CreateCustomer.css";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web";
import { CONSTANTS } from "../../../components/src/theme";
import ConfirmationModal from "../../../components/src/ConfirmationModal";
import InternalNotesAttachements from "../../RequestManagement/src/InternalNotesAttachements.web";
import { CancelButton,ContainedButton } from "../../../components/src/CustomizableComponents.web";
import DeleteModel from "../../RequestManagement/src/DeleteModal.web"; 
// Customizable Area End

import CreateCustomersController, {
  Props,
} from "./CreateCustomerController.web";

// Customizable Area Start

// Customizable Area End

export class EditCustomer extends CreateCustomersController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  reverseOrderEdit = (item: any, mode: string) => {
    const { t } = this.props;
    if (mode === "reverse") {
      return (
        <React.Fragment>
         <Typography
              variant="body2"
              style={
                item.enable === false
                  ? { ...webStyle.editCustonText, color: "rgba(0,0,0,0.5)" }
                  : webStyle.editCustonText
              }
            >
               {item.enable === false
                  ?  t(`customers.createCustData.${0}.radioDisableLabel`)
                  :  t(`customers.createCustData.${0}.radioEnableLabel`)}
            </Typography>
            <img
              style={webStyle.editCustradioButton}
              src={item.enable === false ? item.radioDisable : item.radioEnable}
              alt=""
            />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
        <img
              style={webStyle.editCustradioButton}
              src={item.enable === false ? item.radioDisable : item.radioEnable}
              alt=""
            />
              <Typography
              variant="body2"
              style={
                item.enable === false
                  ? { ...webStyle.editCustonText, color: "rgba(0,0,0,0.5)" }
                  : webStyle.editCustonText
              }
            >
               {item.enable === false
                  ?  t(`customers.createCustData.${0}.radioDisableLabel`)
                  :  t(`customers.createCustData.${0}.radioEnableLabel`)}
            </Typography>
        </React.Fragment>
      );
    }
  };
  showMockData = (webStyle: any, specialIds: any) => {
    const {t,classes} =this.props;
    const lang = this.state.lang;
    const classname = lang==="en"? classes.radioButtonWidth:classes.radioButtonWidthAr
    return this.state.mockAutomaticRemindersData.map((item: any,index:number) => (
      <Box style={{ paddingTop: "0.5rem" }} key={item.id}>
        <Typography variant="subtitle2" style={webStyle.quoteText}>
        {t(`customers.createCustData.${index}.title`)}
        </Typography>
        <Box className={classes.CreateCustquoteContainer}>
          <Typography variant="body1" style={webStyle.editCustfollowUpText}>
          {t(`customers.createCustData.${index}.desc`)}
            <span
              onClick={this.handleGoToSettings}
              style={{ color: "#1c18af", cursor: "pointer" , marginLeft:"4px" }}
            >
             {t(`customers.createCustData.${0}.button`)}
            </span>
          </Typography>
          <Box
            data-test-id="handleRadioChange"
            className={`${classname}`}
            style={{
              ...webStyle.editCustradioButtonContainer,
              ...(item.enable === false && { backgroundColor: "#d8d8d8" }),
              ...(this.state.showFields && webStyle.Nopointer),
            }}
            onClick={this.handleRadioSite.bind(this, item)}
          >
            {item.enable ?
              this.reverseOrderEdit(item, "reverse")
              :
              this.reverseOrderEdit(item, "")
            }


          </Box>
        </Box>
        {specialIds.includes(item.id) && (
          <span className={classes.CreateCusthr}></span>
        )}
      </Box>
    ));
  };
  deleteModal() {
    return <>
      {
        this.state.openUnarchiveDialog &&
        <DeleteModel
          deleteType={this.props.t("customers.formData.unArchiveCustomer")}
          subType={this.props.t("customers.formData.descriptionUnArchived")}
          open={this.state.openUnarchiveDialog}
          onClose={this.handleCloseUnarchiveDialog}
          handleDelete={this.unArchiveCustomer}
          t={this.props.t}
        />
      }
    </>
}
buttons = () => {
  const {t,classes} =this.props;
  if(this.state.customerStatus === 'archived'){
    return <ContainedButton
    style={{maxWidth:"172px"}}
    className={`${classes.buttonStyleEdit} ${classes.buttonStyleEditCommon}`}
    onClick={this.handleOpenUnarchiveDialog}
    data-testid='unArchiveCustomer'
  >
      <Typography variant="subtitle2" style={webStyle.buttonTextSave}>
        {t("customers.formData.unArchive")}
      </Typography>
  </ContainedButton>
  } else if(!this.state.showUpdateButton){
     return <ContainedButton
              style={{maxWidth:"172px"}}
              className={`${classes.buttonStyleEdit} ${classes.buttonStyleEditCommon}`}
              onClick={this.handleEditButton}
            >
              <Typography
                style={webStyle.buttonTextEditCustomer}
                className={classes.buttonEditText}
                variant="subtitle2"
              >
                {t("customers.formData.edit")}
              </Typography>
            </ContainedButton>
  } else if(this.state.showUpdateButton){
  return  <ContainedButton
              style={{maxWidth:"172px"}}
              className={`${classes.buttonStyleEdit} ${classes.buttonStyleEditCommon}`}
              onClick={this.handleUpdateButton}
            >
              {this.state.editCustomersLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                <Typography variant="subtitle2" style={webStyle.buttonTextSave}>
                  {t("customers.formData.update")}
                </Typography>
              )}
            </ContainedButton>
  }
}
deleteBtn = () =>{
  const {t,classes} =this.props;
  return <Box className={classes?.editCustomerButtonLayout}>
  <CancelButton
    style={{maxWidth:"200px"}}
    className={`${classes?.buttonStyleMobileEdit} ${classes?.buttonStyleEditCommon}`}
    onClick={this.handleCancelButton}
  >
    <Typography
      style={webStyle.buttonCustomerCancel}
      className={classes?.buttonEditText}
      variant="subtitle2"
    >
     {t("Calendar.button.cancel")}
    </Typography>
  </CancelButton>
  <DeleteDialog
    {...this.props}
    data-test-id="delete-dialog"
    title={this.state.customerStatus === "active" ?
      t("customers.formData.archiveCustomer") :
      t("customers.formData.deleteCustomer")}
    description={this.state.customerStatus === "active" ?
      t("customers.formData.descriptionArchived") :
      t("customers.formData.description")}
    deleteDialogOpen={this.state.deleteDialogOpen}
    handleClickOpenDialog={this.handleClickOpenDialog}
    handleCloseDialog={this.handleCloseDialog}
    handleClickProceedDelete={this.handleClickProceedDelete}
    status={this.state.customerStatus}
    loader={
      this.state.deleteCustomersLoading && (
        <Box>
          {this.state.deleteCustomersLoading ? (
            <Backdrop
              style={{
                zIndex: 1000 + 1,
                color: "#05037a",
                justifyContent: "center",
                alignItems: "center",
              }}
              open={this.state.deleteCustomersLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : null}
        </Box>
      )
    }
  />
</Box>
}
  handleRadioSite = (item: any) => this.handleRadioChange(item, "EDIT");

  renderAutoCompleteInputEdit = (params: any) => (
    <StyledTextFieldsAutoComplete languageSelected={this.state.lang} {...params} fullWidth variant="outlined" />
  );
  //  Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, width ,t } = this.props;
    const isMobile = ['xs'].includes(width);
    const disabledFiledStyle = this.state.showFields ? classes.fieldDisable : ""
    const specialIds = [1, 2, 3];
    const {
      name,
      email,
      phone,
      notes,
    } = this.state.editCustomerInformationFormData;
    return (
      <>
      
        <ConfirmationModal
          open={this.state.confirmationModal}
          title={t("customers.confirmationModal.title")}
          content={t("customers.confirmationModal.content")}
          cancel={t("Calendar.button.cancel")}
          leave={t("customers.confirmationModal.leave")}
          handleCancel={this.handleGoToSettings}
          handleLeave={this.handleGoToSettingsCallback}
          isMobile={isMobile}
         />
        <StyledBoxContainer
          style={webStyle.editCustpersonalInformationContainer}
        >
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} xs={12}>
              <StyledBox style={webStyle.editCustformContainer}>
                <StyledTypography style={webStyle.editCustpersonalInfoText}>
                {t("customers.formData.personalInformation")}
                </StyledTypography>
                <Grid item lg={12} md={12} xs={12}>
                  <StyledGrid container spacing={2}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.editCustgridItem}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.editCustcontactForm}
                        variant="body1"
                        gutterBottom
                      >
                          {t("customers.siteDialog.name")} <span style={webStyle.redColorEdit}>*</span>
                      </Typography>
                      <StyledTextFields
                        disabled={this.state.showFields}
                        data-test-id="formdata"
                        fullWidth
                        variant="outlined"
                        name="name"
                        className={disabledFiledStyle}
                        value={name}
                        onChange={this.handleChangeFormData}
                        error={Boolean(this.state.isErrorFormData.name)}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.editCusterrorMessage}
                      >
                        {this.state.isErrorFormData.name}
                      </Typography>
                    </Grid>
                  </StyledGrid>
                  <StyledGrid container spacing={3}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.editCustgridItems}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.editCustcontactForm}
                        variant="body1"
                        gutterBottom
                      >
                          {t("BoAuth.emailLabel")}
                      </Typography>
                      <StyledTextFields
                        disabled={this.state.showFields}
                        data-test-id="formdata"
                        fullWidth
                        variant="outlined"
                        name="email"
                        value={email}
                        className={disabledFiledStyle}
                        onChange={this.handleChangeFormData}
                      />
                      <Typography
                        style={webStyle.editCusterrorMessage}
                        variant="body2"
                      >
                        {this.state.isErrorFormData.email}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.editCustgridItems}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}
                        style={webStyle.editCustcontactForm}
                        variant="body1"
                        gutterBottom
                      >
                        {t("profileSetup.phoneNumber")}
                      </Typography>
                      <Box style={webStyle.editCustphoneContainer}>
                        <AutocompleteV1
                          id="country-select-demo"
                          style={{ width: "34%", color: CONSTANTS.color.lightBrown, }}
                          options={countryCodes}
                          value={this.state.countryCode}
                          disabled={this.state.showFields}
                          className={disabledFiledStyle}
                          onChange={this.handleChangeCountryCode}
                          autoHighlight
                          renderInput={this.renderAutoCompleteInputEdit.bind(
                            this
                          )}
                        />
                        <InputBase
                          disabled={this.state.showFields}
                          data-test-id="formdata"
                          style={webStyle.editCustinputBase}
                          className={
                            this.state.lang === "ar" ?
                            `${classes?.editCustinputBase} ${classes?.editCustinputBaseAr} ${disabledFiledStyle}` :
                           `${classes.editCustinputBase} ${disabledFiledStyle}`
                          }
                          name="phone"
                          value={phone}
                          error={Boolean(this.state.isErrorFormData.phone)}
                          onChange={this.handleChangeFormData}
                        />
                      </Box>
                      <Typography
                        style={webStyle.editCusterrorMessage}
                        variant="body2"
                      >
                        {this.state.isErrorFormData.phone}
                      </Typography>
                    </Grid>
                  </StyledGrid>
                </Grid>
              </StyledBox>    
            </Grid>
          

          </Grid>
          <StyledBoxInternal style={webStyle.editCustinternalFormContainer}>
            <StyledGrid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                  <InternalNotesAttachements
                    t={t}
                    editStatus={!this.state.showFields}
                    type={localStorage.getItem("editedCustomerId") && "edit"}
                    internalNotes={notes}
                    internalAttachments={this.state.files}
                    editedinternalAttachements={this.state.editedFiles}
                    convertedFiles={this.state.convertedFiles}
                    getConvertedFiles={this.getJobConvertedFiles}
                    getEditedFiles={this.getJobEditedFiles}
                    updateInternalNotes={this.updateInternalNotes}
                    languageSelected={this.state.lang}
                    updateInternalAttachments={this.updateInternalAttachments} />
                  <StyledTypography
                    style={{
                      ...webStyle.editCustpersonalInfoText,
                      lineHeight: "normal",
                      padding: "1.5rem 0 1rem 0",
                    }}
                  >
                    {t("customers.formData.automaticReminders")}
                  </StyledTypography>
                  <Box>
                    {this.showMockData(webStyle, specialIds)}
                  </Box>
              </Grid>
            </StyledGrid>
          </StyledBoxInternal>
        </StyledBoxContainer>
        <Box style={webStyle.buttonContainerEdit} className={classes?.buttonContainerEdit}>
          {this.buttons()}
          {this.state.customerStatus === 'archived' && this.deleteBtn()}
          {this.state.showUpdateButton && this.deleteBtn()}
        </Box>
        {this.deleteModal()}
        {this.state.getCustomerByIdLoading ? (
          <Backdrop
            style={{
              zIndex: 1000 + 1,
              color: "#05037a",
              justifyContent: "center",
              alignItems: "center",
            }}
            open={this.state.getCustomerByIdLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  editCustmapContainerStyle: {
    width: "370px",
    height: "370px",
    borderRadius: "10px",
  },
  editCustmainContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    alignItems: "center",
    gap: "15px",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  editCustinformationContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "174px",
    minHeight: "46px",
    borderRadius: "8px",
    border: "1px solid rgba(0,0,0,0.1)",
  },
  editCustinformationText: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  editCustpersonalInformationContainer: {
    marginTop: "0.5rem",
    paddingBottom: "3rem",
    maxWidth: "100%",
    minHeight: "1137px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  },
  editCustformContainer: {
    padding: "2.5rem 1rem 2rem 2rem",
  },
  editCustaddressFormContainer: {
    padding: "0.5rem 2rem 2rem 2rem",
  },
  editCustinternalFormContainer: {
    padding: "0rem 2rem 2rem 2rem",
  },
  editCustpersonalInfoText: {
    color: "#34313a",
    fontWeight: 500,
    lineHeight: "20px",
  },
  editCustgridItem: {
    marginTop: "1.5rem",
  },
  editCustgridItems: {
    marginTop: "1rem",
  },
  editCustcontactForm: {
    color: "#999999",
    fontWeight: 400,
    marginBottom: "0.8rem",
    lineHeight: "16px",
  },
  editCusterrorMessage: {
    marginTop: "5px",
    color: "red",
    fontWeight: 500,
  },
  editCustlocationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editCustlocationIcon: {
    width: "24px",
    height: "24px",
  },
  editCustlocationNames: {
    color: "#2b4038",
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 400,
    marginLeft: "0.5rem",
  },
  editCustcountryCodeContainer: {
    display: "flex",
    borderRadius: "10px 0 0 10px",
    maxWidth: "88px",
    width: "100%",
  },
  editCustinputBase: {
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    padding: "0 10px",
    width: "100%",
    border: "0.5px solid lightgrey",
    borderRadius: "0 8px 8px 0",
    color: CONSTANTS.color.lightBrown,
  },
  editCustphoneContainer: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #dedede",
    display: "flex",
    minHeight: "56px",
  },
  editCustgoogleMaps: {
    maxWidth: "367px",
    width: "100%",
    height: "auto",
  },
  editCustrightContent: {
    paddingTop: "4.5rem",
  },

  quoteText: {
    color: "rgba(0,0,0,0.5)",
    fontWeight: 500,
  },
  editCustfollowUpText: {
    color: "#999999",
    fontWeight: 400,
  },
  editCustradioButtonContainer: {
   
    height: "35px",
    borderRadius: "18px",
    backgroundColor: "#e6e6ff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  editCustradioButton: {
    width: "25px",
    height: "25px",
  },
  editCustonText: {
    color: "#1c18af",
    fontWeight: 500,
  },
  editCustbuttonsContainer: {
    padding: "4rem 0",
    flexDirection: "row-reverse" as const,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editCustbuttonContainerCancel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    maxWidth: "200px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    border: "1px solid #999999",
  },
  editCustbuttonTextCancel: {
    color: "#999999",
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  editCustbuttonContainerSave: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    maxWidth: "172px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    backgroundColor: "#1c18af",
  },
  buttonTextSave: {
    color: "#ffffff",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  editCusttoaster: {
    position: "fixed" as const,
    display: "flex",
    alignItems: "center",
    paddingLeft: "2rem",
    width: "100%",
    maxWidth: "689px",
    minHeight: "80px",
    borderRadius: "8px",
    backgroundColor: "#1c18af",
    marginTop: "5px",
  },
  editCustcloseIcon: {
    position: "absolute" as "absolute",
    left: "96%",
    color: " #fff",
    top: "29%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  editCusttoasterText: {
    color: "#ffffff",
    fontWeight: 400,
  },
  redColorEdit: {
    color: "red"
  },

  // supplierstyles
  buttonContainerEdit: {
    padding: "3rem 0",
    display: "flex",
    flexDirection: "row-reverse" as const,
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  buttonCustomerCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  buttonTextEditCustomer: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  Nopointer:{
    cursor:"auto"
  }
};

const editCustomerRouter :any = withStyles(styles)(EditCustomer);
const editCustomerWithRouter = withRouter(editCustomerRouter);
const editCustomerWithSnackBar = withSnackbar(editCustomerWithRouter);
export default withPermission(editCustomerWithSnackBar);
// Customizable Area End
