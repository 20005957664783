Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.authToken="authToken"

exports.btnExampleTitle = "CLICK ME";

exports.createAPIMethod = "POST";
exports.getAPIMethod = "GET";

// HomeController
exports.landingPageAPIContentType = "application/json";
exports.landingPageAPIEndPoint = "bx_block_dashboard/leading_pages";
exports.landingPageAPIMethod = "GET";

// WhyLamsaController
exports.whyLamsaAPIContentType = "application/json";
exports.whyLamsaAPIEndPoint = "bx_block_dashboard/why_lamsas";
exports.whyLamsaAPIMethod = "GET";

// PricingPlanController
exports.pricingPlanAPIContentType = "application/json";
exports.pricingPlanAPIEndPoint = "bx_block_dashboard/pricing_plans/plan_details";
exports.pricingPlanMonthlyAPIEndPoint = "bx_block_dashboard/pricing_plans";
exports.pricingPlanYearlyAPIEndPoint = "bx_block_dashboard/pricing_plans?serch_key=yearly";
exports.pricingPlanAPIMethod = "GET";

// ComparelamsaFeaturesController
exports.comparelamsaFeaturesAPIContentType = "application/json";
exports.comparelamsaFeaturesAPIEndPoint = "bx_block_dashboard/lamsa_features";
exports.comparelamsaFeaturesAPIMethod = "GET";

// ContactUsController
exports.contactUsAPIContentType = "application/json";
exports.contactUsFormAPIEndPoint = "bx_block_dashboard/contact_us";
exports.contactUsFormAPIMethod = "POST";
exports.getContactUsDataAPIEndPoint = "bx_block_contact_us/contact_contents";
exports.getContactUsDataAPIMethod = "GET";

// TermsAndConditionsController
exports.termsConditionsAPIContentType = "application/json";
exports.termsConditionsAPIEndPoint = "terms_and_condition";
exports.termsConditionsAPIMethod = "GET";

// PrivacyPolicyController
exports.privacyPolicyAPIContentType = "application/json";
exports.privacyPolicyAPIEndPoint = "bx_block_dashboard/privacy_policies";
exports.privacyPolicyAPIMethod = "GET";

//tap payments
exports.createChargeAPIEndPoint = "/bx_block_amazonpayintegration/create_charge"
exports.getChargeAPIEndPoint="/bx_block_amazonpayintegration/get_charge"
exports.tryUsAPIEndPoint = "/bx_block_amazonpayintegration/try_us"

// Customizable Area End