import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import i18n from 'i18next';

// Customizable Area Start
import { WithStyles } from '@material-ui/core'
import { setStorageData } from "../../../framework/src/Utilities";
import { languageSelect } from "../../../components/src/CommonFunction";

// Customizable Area End
export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  t:(key?: string, options?: object) => string;
  // Customizable Area End
}


declare const window: { document: { querySelector: (arg0: string) => { (): any; new(): any; setAttribute: { (arg0: string, arg1: string): void; new(): any; }; }; }; };

interface S {
  // Customizable Area Start
  selectedLanguage: string;
  languageError: string;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class LanguageSupportWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start

  getTaxationApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [

      getName(MessageEnum.RestAPIResponceMessage)

    ];

    this.state = {
      //Customizable Area Start
      selectedLanguage: "",
      languageError: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);


    // Customizable Area End

  }

  handleLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    this.setState({ selectedLanguage: lang }, () => {
      console.log("@@@", this.state.selectedLanguage);

      localStorage.setItem("lang", lang)
      if (localStorage.getItem("lang") === "ar") {
        if (lang === 'ar') {
          window.document.querySelector("html").setAttribute("dir", "rtl");
        } else {
          window.document.querySelector("html").setAttribute("dir", "ltr");
        }

      }
    });

  }
  // Customizable Area Start

  changeLanguage = (lang: string) => {
    this.setState({ selectedLanguage: lang });
  }
  
  handleNext = async () => {
    setStorageData("lang", this.state.selectedLanguage)

    if (!this.state.selectedLanguage) {
      this.setState({ languageError: (this.props.t('languageSupport.ErrorMessage')) })
    } else {
    languageSelect(this.state.selectedLanguage)
      
      this.props.navigation.navigate("Home")
    }
  }
  // Customizable Area End
}
