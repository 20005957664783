import React from "react";

// Customizable Area Start
import { withRouter } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Button,
  InputAdornment,
  InputBase,
  TextField,
  withWidth,
  styled
} from "@material-ui/core";
import { withStyles ,} from "@material-ui/core/styles";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { ProfileSubSideBar } from "./ProfileSubSideBar.web";
import { StyledRoot,countryCodes } from "./utility.web";
import { CONSTANTS } from "../../../components/src/theme";
import {
  logOutContent,
  profileDropDown,
  userIcon,
} from "./assets";
import {
  ProfileStyledButton,
  ProfileStyledMenuItem,

} from "../../CustomisableUserProfiles/src/utility.web";
import {
  EmployeeStyledTextField,

} from "./EmployeeProfile.web";
import { editIcon } from "../../Settings/src/assets";
import {StyledTextFieldsAutoComplete, renderImageUrl } from "./Profile.web";
import {StyledDialog} from "./EmployeeLogout.web"
import AutocompleteV2 from "@material-ui/lab/Autocomplete";
import { withTranslation } from "react-i18next";

// Customizable Area End

import ProfileController, { Props } from "./ProfileController.web";

// Customizable Area Start
export const StyledTextFields = withStyles((theme)=>({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Rubik",
      borderRadius: "10px",
      fontSize: "1.1667rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.0625rem !important"
      },
    },
  },
}))(TextField);
// Customizable Area End

export class Logout extends ProfileController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  renderAutoCompleteInputLogout = (params: any) => (
    <StyledTextFieldsAutoComplete {...params} 
    languageSelected={this.state.lang}  fullWidth variant="outlined" />
  );
  renderProfileImage = (webStyle:any) => {
    return (
      <Box>
        <Grid item>
          {!this.state.companyLogo && !this.state.profile_image && (
            <img
              src={userIcon}
              height="100"
              style={{ borderRadius: "50%", marginTop: "20px" }}
              width="100"
            />
          )}
          {this.state.companyLogo && (
            <img
              width="100"
              style={{ borderRadius: "50%", marginTop: "20px" }}
              src={renderImageUrl(this.state.companyLogo)}
              height="100"
            />
          )}
          {this.state.profile_image && (
            <img
              style={{ borderRadius: "50%", marginTop: "20px" }}
              height="100"
              width="100"
              src={this.state.profile_image}
            />
          )}

          <img
            style={webStyle.logoutEditIcon}
            src={editIcon}
            alt=""
            onClick={this.handleClickEditIcon}
          />
        </Grid>
      </Box>
    );
  };

  renderProfileFormLogout = (classes: any) => {
    const { t} = this.props
    const {
      firstName,
      lastName,
      email,
      phone,
      streetAddress,
      city,
      country,
      postcode,
    } = this.state.formDetails;
    return (
      <>
        <Grid className={classes.mainContV1Logout} xs style={{ paddingTop: "55px" }}>
          <ProfileSubSideBar
            {...this.props}
            navMenuItem={t("boProfile.profile")}
            profileActive={2}
           
          />
        </Grid>
        <Grid
           className={classes.mainContLogout}
        >
          {/* <Divider orientation="vertical"/> */}
          <Container maxWidth={"lg"} style={{ padding: 0 }}>
            <Typography
              style={{ marginTop: "30px" }}
              variant="subtitle1"
              className={classes.logoutprofileTitle}
            >
              {t("boProfile.profileInformation")}
            </Typography>
          </Container>

          <Grid container direction="column">
            <Grid item xs>
              <Box>
             {this.renderProfileImage(webStyle)}
              </Box>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                   {t("landingPage.ContactUs.firstName")}
                </Typography>
                <StyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.firstName)}
                  inputProps={{ className: classes.logouttextFielsFonts }}
                />
                <Typography variant="body2" style={webStyle.logouterrorMessage}>
                  {this.state.isErrorFormDetails.firstName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                  {t("landingPage.ContactUs.lastName")}
                </Typography>
                <StyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.lastName)}
                  inputProps={{ className: classes.logouttextFielsFonts }}
                />
                <Typography variant="body2" style={webStyle.logouterrorMessage}>
                  {this.state.isErrorFormDetails.lastName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                  {t("landingPage.ContactUs.email")}
                </Typography>
                <StyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your email address"
                  name="email"
                  value={email}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.email)}
                  inputProps={{ className: classes.logouttextFielsFonts }}
                  type="email"
                />
                <Typography variant="body2" style={webStyle.logouterrorMessage}>
                  {this.state.isErrorFormDetails.email}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                 {t("landingPage.ContactUs.phoneNumber")}
                </Typography>
                <Box style={webStyle.logoutPhoneContainer}>
                  <AutocompleteV2
                    id="country-select-demo"
                    style={{ width: "34%" }}
                    options={countryCodes}
                    
                    onChange={this.handleChangeCountryCode}
                    autoHighlight
                    renderInput={this.renderAutoCompleteInputLogout.bind(this)}
                    value={
                      this.state.countryCode !== "" &&
                      this.state.countryCode.toString().charAt(0) != "+"
                        ? "+" + this.state.countryCode.toString()
                        : this.state.countryCode.toString()
                    }
                  />

                  <InputBase
                   value={phone}
                   onChange={this.handleChangeFormDetails}
                   
                   error={Boolean(this.state.isErrorFormDetails.phone)}
                    data-test-id="formDetails"
                    fullWidth
                    style={webStyle.logoutInputBase}
                    name="phone"
                   
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                  {t("boProfile.streetAddress")}
                </Typography>
                <StyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  name="streetAddress"
                  value={streetAddress}
                  onChange={this.handleChangeFormDetails}
                  //error={Boolean(this.state.isErrorFormDetails.streetAddress)}
                  inputProps={{ className: classes.logouttextFielsFonts }}
                />
               
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                    {t("customers.siteDialog.city")}
                </Typography>
                <StyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="city"
                  value={city}
                  onChange={this.handleChangeFormDetails}
                  //error={Boolean(this.state.isErrorFormDetails.city)}
                  inputProps={{ className: classes.logouttextFielsFonts }}
                />
               
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                 {t("customers.siteDialog.country")}
                </Typography>
                <StyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="country"
                  value={country}
                  onChange={this.handleChangeFormDetails}
                  //error={Boolean(this.state.isErrorFormDetails.country)}
                  inputProps={{ className: classes.logouttextFielsFonts }}
                />
              
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.logoutgridItems}>
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                {t("customers.siteDialog.postCode")}
                </Typography>
                <StyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="postCode"
                  value={postcode}
                  onChange={this.handleChangeFormDetails}
                  //error={Boolean(this.state.isErrorFormDetails.lastName)}
                  inputProps={{ className: classes.logouttextFielsFonts }}
                />
             
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                style={webStyle.logoutgridItems}
              >
                <Typography
                  style={webStyle.logoutcontactForm}
                   variant = "body1"
                  gutterBottom
                >
                     {t("customers.siteDialog.language")}
                </Typography>
                <EmployeeStyledTextField
                  fullWidth
                  variant="outlined"
                  defaultValue={t(`boProfile.english`)}
                  languageSelected={this.state.lang}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          style={
                           webStyle.logoutcontactUsDropDown
                          }
                          src={profileDropDown}
                          alt=""
                        />
                      </InputAdornment>
                    ),
                    className: classes.logouttextFielsFonts,
                  }}
                 
                />
                {this.state.toggleLanguageModal ? (
                  <Typography>
                    <Box style={webStyle.logouttoggleModalContainer}>
                      <Box style={webStyle.logouttoggleModalInnerContainer}>
                        <Box>
                          <ProfileStyledMenuItem
                            disableRipple
                            style={webStyle.logoutmenuItem}
                            value={t(`boProfile.english`)}
                          >
                            <Typography>{t(`boProfile.english`)}</Typography>
                          </ProfileStyledMenuItem>
                        </Box>
                      </Box>
                    </Box>
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ textAlign: "end", margin: "127px 0 25px 0" }}
            >
              <ProfileStyledButton
                data-test-id="btnEmailLogin"
                onClick={this.handleDetailsSubmit}
                className={classes.logoutsaveButton}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.logoutsaveButtonText}
                >
                 {t("boProfile.save")}
                </Typography>
              </ProfileStyledButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,t }: any = this.props;

    return (
      <>
        <DashboardSideBar
          permissions={undefined}  oldProps={undefined}
          {...this.props}
          navMenuItem={this.props.t('dashboardNavbar.profile')}
          active={false}
          active1={false}
          active2={false}        />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "5rem",
            }}
            className={classes.logoutboxContainer}
          >
            <Grid
                alignItems="flex-start"
                container
                direction="row"
                justifyContent="space-between"
                className={classes.renderForm}
            >
              <LogoutGrid  open={this.state.openLogOut}>
                <DialogContent
                  className={classes.logoutdialogContentStyle}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    className={classes.logoutContainer}
                  >
                    <Grid item xs={12} >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        
                      >
                        <Grid item xs={6} className={classes.logoutContent}>
                          <Typography variant="subtitle1" className={classes.logouttitleText}>
                            {t("boProfile.logout")}
                          </Typography>
                          <Typography variant="body1" className={classes.logouttitleBody}>
                          {t("boProfile.confirmMsg")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "end" }}>
                          <img
                            src={logOutContent}
                            alt="logout contents"
                            className={classes.logouttitleBodyImage}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid item xs={5} >
                          <Button
                            variant="outlined"
                            size="large"
                            className={classes.logoutcancelButton}
                            onClick={this.handleCancelLogout}
                          >
                            <Typography variant="subtitle2" className={classes.logoutcancelText}>
                             {t("landingPage.StyleDialog.cancel")}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          style={{  textAlign: "end" }}
                        >
                          <Button
                            variant="outlined"
                            size="large"
                            className={classes.ProfilelogoutButton}
                            onClick={this.handleLogout}
                          >
                            <Typography variant="subtitle2" className={classes.ProfilelogoutButtonText}>
                             {t("boProfile.logout")}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </LogoutGrid>
              {this.renderProfileFormLogout(classes)}
            </Grid>
          </Box>
        </StyledRoot>
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  mainContLogout:{
    width: "75%",
    padding: "20px",
    borderLeft: "1px solid #e4e4e4",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  mainContV1Logout:{
  
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  logoutContainer:{
    gap:"2rem"
  },
  logoutContent:{
    display:"flex",
    flexDirection:"column" as const,
    justifyContent:"start",
    [theme.breakpoints.down("xs")]: {
      justifyContent:"center",
    },
  },
  logoutboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  logoutcountryCodeField: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 0rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem !important",
        marginTop: "0px !important",
        marginRight: "-10px !important",
      },
    },
  },
  logoutdialogContentStyle: {
    width: "600px",
    height: "276px",
    borderRadius: "20px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
    paddingTop:0,
    padding:"30px 15px 15px 15px !important",
    [theme.breakpoints.down("sm")]: {
      width: "540px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "310px",
      height: "252px",
      padding:"22px 20px 22px 20px !important",
    },
   
  },
  logoutcancelButton: {
    width: "100%",
    height: "56px",
    borderRadius: "10px",
    border: "1px solid #e02020",
    color:"#e02020",
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: '#ff5454',
      color: theme.palette.common.white,
      cursor: "pointer",
      border:"none"
    },
    [theme.breakpoints.down("xs")]: {
     
      height: "48px",
      padding:0,
    
    },
  },
  logoutcancelText: {
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
    textTransform: "none" as const,
  },
  ProfilelogoutButton: {
    width: "100%",
    height: "56px",
    borderRadius: "10px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        border: "none",
        cursor: "pointer"
    },
    [theme.breakpoints.down("xs")]: {
    
      height: "48px",
      padding:0,
    
    },
  },
  ProfilelogoutButtonText: {
    
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
    textTransform: "none" as const,
  },
  logouttitleText: {
 
    color: CONSTANTS.color.lightBrown,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "20px",
    marginBottom: "12px",
  },
  logouttitleBody: {
    
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "25px",
  },
  logouttitleBodyImage: {
    width: "125px",
    height: "127px",
    [theme.breakpoints.down("xs")]: {
      width: "118px",
      height: "118px",
    },
  },

  logoutprofileTitle: {
    width: "256px",
    height: "28px",
    color: CONSTANTS.color.lightBrown,
    fontWeight:  theme.typography.fontWeightMedium,
  },
  logoutcompanyLogo: {
    width: "156px",
    height: "156px",
    marginTop: "35px",
  },
  logouteditBadge: {
    width: "18px",
    height: "18px",
    marginTop: "25%",
    marginLeft: "25%",
  },
  logouteditBadgeOval: {
    width: "40px",
    height: "40px",
    background: "#e9e9ff",
    backgroundBlendMode: "normal",
    borderRadius: "50%",
  },
  logoutsaveButton: {
    width: "200px",
    height: "67px",
    borderRadius: "10px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    textAlign: "end" as const,
  },
  logoutsaveButtonText: {
    width: "50px",
    height: "26px",
    color: theme.palette.common.white,
    fontWeight:theme.typography.fontWeightRegular,
    textAlign: "center" as const,
  },
  logouttextFielsFonts: {
    height: "21px",
    color: "#666666",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  ProfileSidebarboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  ProfileSidebarsideBarTitle: {
    padding: "0 0 0 2rem",
    marginLeft: "-0.2rem",
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
  },
});

const webStyle = {
  logoutgridItems: {
    marginTop: "1rem",
  },
  logoutcontactForm: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
  },
  logoutmenuItem: {
    fontFamily: "Rubik",
    fontSize: "1.3125rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  logouterrorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },
  logoutcontactUsDropDown: {
    width: "14px",
    height: "8px",
    cursor: "pointer",
  },
  logouttoggleModalContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "100%",
    maxWidth: "470px",
    minHeight: "125px",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
  },
  logouttoggleModalInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    flexWrap: "wrap" as const,
    padding: "1rem",
  },
  logoutEditIcon: {
    cursor: "pointer",
    height: "36px",
    width: "36px",
    transform: "translate(-58%, 0%)",
  },
  logoutPhoneContainer:{
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #dedede",
    display: "flex",
    minHeight: "56px",
  },
  logoutInputBase :{
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px",
    width: "100%",
    border: "0.5px solid lightgrey",
    borderRadius: "0 8px 8px 0",
  },
};
const LogoutGrid = styled(StyledDialog)({
  "& .MuiDialog-paper": {
    borderRadius : "15px"
  }, 
})

//@ts-ignore
const logoutRouter = withRouter(Logout);
export default withTranslation()(withStyles(styles)(withWidth()(logoutRouter)));
// Customizable Area End
