import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,Button,
    Grid ,Table, TableHead, TableRow, TableCell, styled , TableBody
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
export const configJSON = require("./config");
import PdfController, {
    PdfSiteDetails,
    Props,
} from "./PdfController.web";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { lamsaLogo } from "../../CustomisableUserProfiles/src/assets";
import { StyledDialog, styles } from "./RequestPdf.web";
import { CONSTANTS } from "../../../components/src/theme";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
// Customizable Area End



// Customizable Area Start
interface BoInformation {
    name: string;
    street_address: string;
    company_name: string;
    post_code: number;
    email: string;
    city: string;
    full_phone_number: string;
  }
  interface ProductItem {
    type: string;
    id: string;
    attributes: {
      product_id: number;
      service_id: null | number;
      quantity: number;
      currency: null | string;
      unit_price: string;
      job_id: number;
      total: string;
      description: string;
      quote_id: null | string;
      product_name: string;
      visit_id: null | string;
    };
  }
  interface InvoiceDeposit {
    id: number;
    payment_method: string;
    amount: string;
    pay_balance: boolean;
    transaction_date: string;
    collected: boolean;
    details: string;
  }
  interface ProductsData {
    data: ProductItem[];
  }
 const customerDetailsQuote = (
  
    classes: ClassNameMap<string>,
    requestPdfDetails: { custmer_name: string ,bo_information:BoInformation , start_date: string , 
        end_date: string, start_time: string , end_time: string , job_title: string ,sub_total: null | number,  total: null | number, discount_type: null | string , discount: null | number ,  tax: null | number,
        products : ProductsData , quote_date: string ,  quote_id: string , due_date: string , quote_title : string ,invoice_deposits :InvoiceDeposit[] },
    pdfSiteDetails: PdfSiteDetails,
    t: ((key: string) => string) | undefined) => {
    return (
        <>
            <div className={classes.requestUserDetails} style={webStyle.requestUserDetails}>
            <Grid container direction="row" spacing={3} >
                <Grid item xs={6}>
                    <Box>
                        <Typography className={classes.customerHeadingText}  variant="body1" 
                       
                        style={webStyle.fontDark}>{requestPdfDetails?.bo_information?.name}</Typography>
                        <Typography className={classes.sourceText}  variant="body1" >
                            {requestPdfDetails?.bo_information?.email}</Typography>
                        <Typography className={classes.sourceText}  variant="body1" >
                            {requestPdfDetails?.bo_information?.street_address}&nbsp;{requestPdfDetails?.bo_information?.post_code}</Typography>
                        <Typography className={classes.sourceText}  variant="body1" >
                            {requestPdfDetails?.bo_information?.full_phone_number}</Typography>
                    </Box>
                    <Box>
                        <Typography  className={classes.customerHeadingText} variant="body1" style={webStyle.fontDark}>{pdfSiteDetails.site_name}
                        </Typography>
                        <Typography  className={classes.sourceText} variant="body1" >{pdfSiteDetails.email}
                        </Typography>
                        <Typography  className={classes.sourceText} variant="body1" >{pdfSiteDetails.site_address}
                        </Typography>
                        <Typography  className={classes.sourceText} variant="body1" >{pdfSiteDetails.phone_number}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box style={webStyle.titleDe}>
                        <Box>
                            <Typography variant="body1" style={webStyle.fontDark} className={classes.customerHeadingText}  >Quote Issue Date 
                            </Typography>
                            <Typography className={classes.sourceText} variant="body1" >{requestPdfDetails.quote_date}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.customerHeadingText} variant="body1" style={webStyle.fontDark}>Quote Expiry Date
                            </Typography>
                            <Typography className={classes.sourceText} variant="body1">{requestPdfDetails.due_date}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography  className={classes.customerHeadingText} variant="body1" style={webStyle.fontDark}>Quote ID
                            </Typography>
                            <Typography className={classes.sourceText} variant="body1">{requestPdfDetails.quote_id}
                            </Typography>
                        </Box>
                        <Box>

                            <Typography className={classes.customerHeadingText} variant="body1" style={webStyle.fontDark}>Scheduled Date and Time
                            </Typography>
                            <Typography className={classes.sourceText} variant="body1" >{"Start:"} {requestPdfDetails.quote_date}&nbsp;{requestPdfDetails.start_time}
                            </Typography>
                            <Typography className={classes.sourceText} variant="body1" >{"End:"} {requestPdfDetails.due_date}&nbsp;{requestPdfDetails.end_time}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
        <div id="quotePdf-30">
                <Grid item xs={12} id="quotePdf-31" >
                    <Box id="quotePdf-32">
                        <Typography variant="body1" 
                        id="quotePdf-40" className={classes.customerHeadingText} style={webStyle.fontDarkspace}>Quote Title &nbsp;{requestPdfDetails.quote_title}</Typography>
                    </Box>
                    <Box id="quotePdf-33">
                        <Typography variant="body1" className={classes.customerHeadingText}
                        id="quotePdf-39" style={webStyle.fontDarkspace}>Product/Services</Typography>
                    </Box>
                    <TableStyle id="quotePdf-34">
                        <Table aria-label="simple table"
                         id="quotePdf-36">
                            <TableHead id="quotePdf-35">
                                <TableRow 
                                className="table-header" id="quotePdf-37">
                                    <TableCell id="quotePdf-8" > # </TableCell>
                                    <TableCell id="quotePdf-9" className="title-th-des" align="left">Description</TableCell>
                                    <TableCell id="quotePdf-10" align="center">Quantity</TableCell>
                                    <TableCell id="quotePdf-11" align="center">Unit Price</TableCell>
                                    <TableCell id="quotePdf-12" align="center">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body" id="quotePdf-44">
                                {requestPdfDetails?.products?.data.map((item: ProductItem, index:number) => 
                                (
                                        <TableRow 
                                        key={index}>
                                            <TableCell id="quotePdf-45" component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell id="quotePdf-46" align="left">{item.attributes.product_name}</TableCell>
                                            <TableCell id="quotePdf-47" align="center">{item.attributes.quantity}</TableCell>
                                            <TableCell id="quotePdf-48" align="center">{item.attributes.unit_price}</TableCell>
                                            <TableCell id="quotePdf-49" align="center">{item.attributes.total}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell id="quotePdf-21"  className="borderRemove" colSpan={4} align="right">Subtotal</TableCell>
                                        <TableCell id="quotePdf-22"className="borderRemove" align="center">{requestPdfDetails.sub_total}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell id="quotePdf-23" className="borderRemove" colSpan={4} align="right">Discount</TableCell>
                                        <TableCell id="quotePdf-24" className="borderRemove" align="center">{requestPdfDetails.discount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell id="quotePdf-7" className="borderRemove" colSpan={4} align="right">Deposit</TableCell>
                                        <TableCell id="quotePdf-6" className="borderRemove" align="center">{requestPdfDetails?.invoice_deposits?.[0].amount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell id="quotePdf-5" colSpan={4} align="right">Tax</TableCell>
                                        <TableCell  id="quotePdf-4" align="center">{requestPdfDetails.tax}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell id="quotePdf-3" className="colDark borderRemove" colSpan={4} align="right">Total</TableCell>
                                        <TableCell id="quotePdf-2" className="colDark borderRemove" align="center">{requestPdfDetails.total}</TableCell>
                                    </TableRow>
                                    
                            </TableBody>
                        </Table>
                    </TableStyle>
                </Grid>
            </div>
        </>
        
        
    )
}
export const formatDateString = (dateString: Date) => {
    if (!dateString) return '';

    const dateObject = new Date(dateString);
    return dateObject.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });
}
export const dateTimeScheduling = (classes:ClassNameMap<string>,t:((key: string) => string) | undefined,requestPdfDetails: { quote_date: Date; due_date: Date; }) => {
    return (
        <>

        </>
    )
}
export const serviceDetails = (
    classes: ClassNameMap<string>,
    t: ((key: string) => string) | undefined,
    requestPdfDetails: { service_detail: string }
) => {
    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={1}>
        </Grid>
    )
}
export const ProductService = (
    classes: ClassNameMap<string>,
    t: ((key: string) => string) | undefined,
    currency:string,
    requestPdfDetails: { products: { data: [] } }
) => {
    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={1}>
        
        </Grid>
    )
}
export  const priceSection = (
    classes: ClassNameMap<string>,
    t: ((key: string) => string) | undefined,
    requestPdfDetails: { sub_total: number; discount:number; tax: string ; tax_name: string ; total: number; invoice_deposits:[]; }
) => {
    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={1}>
          
        </Grid>
    )
}
// Customizable Area End

export class QuotePdf extends PdfController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { props } = this
        const { closePdf, classes,t } = props
        const { pdf_url } = this.state.requestPdfDetails;
        const pdfContent = () => {
            return (
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-start" spacing={1} >
                        <Grid item xs={12}>
                            {customerDetailsQuote(classes, this.state.requestPdfDetails, this.state.pdfSiteDetails, t)}
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "2rem" }}>
                            {dateTimeScheduling(classes, t, this.state.requestPdfDetails)}
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "2rem" }}>
                            {serviceDetails(classes, t, this.state.requestPdfDetails)}
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "2rem" }}>
                            {ProductService(classes, t, this.props.currency, this.state.requestPdfDetails)}
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "2rem" }}>
                            {priceSection(classes, t, this.state.requestPdfDetails)}
                        </Grid>

                    </Grid>

                </Grid>
            );
        }


        return (
            <StyledDialog
                maxWidth={"lg"}
                fullWidth={true}
                onClose={closePdf}
                open={this.props.openPdf}
            >
                <Box style={webStyle.downloadLayout}>
                    <Box style={webStyle.closeIconButton}>
                        <CloseIcon style={{ fontSize: 30 }} onClick={this.props.closePdf} />
                    </Box>
                </Box>
                <div style={{ padding: "3rem" }} className="requestPdf">
                    <Box className={classes.containerLayout} style={{ border: `5px solid ${pdf_url?.pdf_hex_colour || CONSTANTS.color.black}` }}>
                        <Box className={classes.requestInnerContainer} style={{ border: `1px solid ${pdf_url?.pdf_hex_colour || CONSTANTS.color.black}` }}>
                            <div style={webStyle.lamsaLogoContainer}>
                                  <img
                                    style={(!this.state.pdfLoading && this.state.requestPdfDetails?.company_logo?.url) ? webStyle.companyLogo : webStyle.lamsaLogo}
                                    src={(!this.state.pdfLoading && this.state.requestPdfDetails?.company_logo?.url) || lamsaLogo}
                                    alt=""
                                />
                            </div>
                            {pdfContent()}
                        </Box>
                    </Box>
                </div>
                <Box style={webStyle.quoteDownloadLayout}>
                    <CreateQuote data-test-id="downloadPdf">
                        <Typography component={'a'}
                        
                          href={this.state.requestPdfDetails?.pdf_url?.url}
                          download
                          target="_blank"
                            style={webStyle.downloadText}
                            variant="subtitle2"
                        >
                            {t ? t('request.button.download') : configJSON.downloadLabel}
                        </Typography>
                    </CreateQuote>

                </Box>
                <LoadingBackdrop
                    loading={this.state.pdfLoading}
                />
            </StyledDialog>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    requestUserDetails:{
        borderBottom:"1px solid black",
        paddingBottom:20,
        marginBottom:20
    },
    titleDe:{
        display:"flex", 
        flexDirection:"column" as const,
        alignItems: 'end' ,
        textAlign: 'end' as const,
        gap:20
    },
    spaces:{
        margin:"0 0 20px 0"
    },
    downloadLayout: {
        display: "flex",
        padding: "3rem 3rem 0 3rem",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    closeIconButton: {
        cursor: "pointer"
    },
    lamsaLogoContainer: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        margin:"0 0 20px 0"
    },
    lamsaLogo: {
        width: "125px",
        height: "40px"
    },
    companyLogo: {
        width: "100px",
        height: "100px"
    },
    quoteOrderIconContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1.5rem 0px 0rem",
        gap: "12px",
    },
    purchaseOrderIcon: {
        height: "20px",
        width: "20px",
    },
    shippingContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    hr: {
        height: "1px",
        display: "flex",
        border: "1px solid rgba(0,0,0,0.1)",
        margin: "1rem 0 2rem"
    },
    downloadText: {
        color: CONSTANTS.color.white,
        fontFamily: "Rubik",
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
        textAlign: "center" as const,
        textDecoration:'none'
        
    },
    appointmentLayout: {
        display: "flex",
        alignItems: "center",
    },
    quoteDownloadLayout: {
        display: "flex",
        justifyContent: "flex-end",
        padding: " 0 3rem 3rem",
        alignItems: "center"
    },
    dateTimeStyle: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
    },
    fontDark: {
        fontWeight: 500 ,
        color: "black",
    },
    fontDarkspace:{
        fontWeight: 500 ,
        color: "black",
        margin:"0 0 20px 0"
    },
}

const TableStyle = styled(Box)({
    "& .table-header":{
        "& .title-th-des":{
            display:"flex",
            justifyContent:"start",
            alignItems:"center",
            height:"45px !important",
        },
        "& .MuiTableCell-root":{
            border:"none",
            fontWeight: 500 ,
            color: "black",
        }, 
        "& th":{
            "&:first-child":{
                borderRadius: "6px 0 0 6px",
                minWidth: 30
            },
            "&:last-child":{
                borderRadius: "0 6px 6px 0",
            },
            background:"#f0f0f0",
        },
        height:"45px !important",
    },
    "& .borderRemove":{
        borderBottom:"none !important"
    },
    "& .colDark":{
        fontWeight: "500 !important" ,
        color: "black !important",
    },
    "& .table-body":{
        "& tr":{
            height:"50px !important",
        },
        "& th":{
            "&:first-child":{
                minWidth: 30
            },
        },
        "& .MuiTableCell-root":{
            padding:"0px !important"
        }
        
    },
    
})

const QuotePdfStyles = withStyles(styles)(QuotePdf);
export default withCommonStyles(QuotePdfStyles);
// Customizable Area End