import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { handleViewData } from "../../CustomisableUserProfiles/src/utility.web";
import { checkLoggedInUserDashboard , empvalidateFormDataProfileV1} from "./utility.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  profileActive: any;
  openToastHandler: any;
  history: any;

  t: (key: string) => string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: any;
  id: any;
  errorMsg: string;
  loading: boolean;
  employeepasswordData: {
    oldPassword: any;
    newPassword: any;
    confirmPassword: any;
  };
  employeeformDetails: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    streetAddress: string;
    city: string;
    country: string;
    postcode: string;
    language: string;
  };
  isErrorFormDetails: any;

  enablePasswordFieldOld: boolean;
  enablePasswordFieldNew: boolean;
  enablePasswordFieldConfirm: boolean;
  openLogOut: boolean;
  countryCode: any;
  countryCodeList: any;
  updateProfileLoading: boolean;
  countryCodeLoading: boolean;
  toggleLanguageModal: boolean;
  companyLogo: any;
  successMessage: any;
  errorMessage: any;
  success: boolean;
  error: any;
  logoChangeModal: boolean;
  isHovered: boolean;
  isHovered2: boolean;
  profileUpdateSuccessful: boolean;
  open: boolean;
  profile_image_employee: any;
  passErr: any;
  isErrorFormData: any;
  latitude: any;
  longitude: any;
  lang:string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class EmployeeProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wrapperRef: any;
  employeeProfileupdatePasswordCallApiId: any = "";
  employeefetchProfileDataCallApiId: any = "";
  employeeupdateProfileDataCallApiId: any = "";
  employeedeleteProfileImageCallApiId: any = "";
  autocomplete: any;
  inputRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.autocomplete = null;
    this.inputRef = React.createRef();
    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      id: "",
      loading: false,
      employeepasswordData: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      latitude: "",
      longitude: "",
      employeeformDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        streetAddress: "",
        city: "",
        country: "",
        postcode: "",
        language: "",
      },
      profile_image_employee: "",
      isErrorFormDetails: {},
      enablePasswordFieldOld: false,
      enablePasswordFieldNew: false,
      enablePasswordFieldConfirm: false,
      openLogOut: true,
      countryCode: "",
      countryCodeList: "",
      updateProfileLoading: false,
      countryCodeLoading: false,
      toggleLanguageModal: false,
      companyLogo: "",
      success: false,
      error: false,
      successMessage: "",
      errorMessage: "",
      profileUpdateSuccessful: false,
      logoChangeModal: false,
      isHovered: false,
      isHovered2: false,
      open: false,
      passErr: {
        oldPassErr: false,
        newPassErr: false,
        confirmPassErr: false,
        oldPassMsg: "",
        newPassMsg: "",
        confirmPassMsg: "",
      },
      isErrorFormData: {},
      lang:"en"
    };
    this.wrapperRef = React.createRef<any>();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const token = localStorage.getItem("authToken")
    this.checkAuthTokenEmpProfile(token)
    this.employeefetchAccountData();
    const lang = await getStorageData("lang")
    this.setState({ lang })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.employeeProfileupdatePasswordCallApiId) {
          this.employeehandleChangePassword(responseJson);

          this.parseApiCatchErrorResponse(errorResponse);
        } else if (
          apiRequestCallId === this.employeefetchProfileDataCallApiId
        ) {
          this.employeehandleFetchApi(responseJson);

          this.parseApiCatchErrorResponse(errorResponse);
        } else if (
          apiRequestCallId === this.employeeupdateProfileDataCallApiId
        ) {
          this.employeehandleUpdateProfileResponse(responseJson);
          this.parseApiCatchErrorResponse(errorResponse);
        } else if (
          apiRequestCallId === this.employeedeleteProfileImageCallApiId
        ) {
          this.employeehandleDeleteProfileImageResponse(message);
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkAuthTokenEmpProfile = (token:any)=>{
    if (!token) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }
  }
  employeehandleDeleteProfileImageResponse(responseJson: any) {
    if (!responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        this.props.openToastHandler("You are not authorised.", "error");
      }else{
        this.setState({
          companyLogo: "",
          logoChangeModal: false,
          profile_image_employee: "",
        });
        localStorage.setItem("profileDataFetched", "false");
      }
     
    } else {
      //Check Error Response
      this.handleEmployeeTokenExpireError(responseJson);
    }
  }

  employeehandleUpdateProfileResponse = (responseJson: any) => {
    if (!responseJson.errors) {
     this.employeehandleUpdateProfile(responseJson)
      
    } else {
      this.handleEmployeeTokenExpireError(responseJson);
    }
  };
  employeehandleUpdateProfile = (responseJson: any)=>{
    if (responseJson.message === "you are not authorized") {
      this.props.openToastHandler("You are not authorised.", "error");
    } else {
      const {
        first_name,
        last_name,
        email,
        phone_number,
        street_address,
        city,
        post_code,
        language,
        employee_image,
        country,
      } = responseJson?.data?.data?.attributes;
      this.setState({
        countryCode: phone_number.split(" ")[0] || "",
        profile_image_employee: employee_image && employee_image?.url,
        companyLogo: "",
        profileUpdateSuccessful: true,
        employeeformDetails: {
          ...this.state.employeeformDetails,

          lastName: last_name || "",
          email: email || "",
          firstName: first_name || "",
          phone: phone_number.split(" ")[1] || "",
          streetAddress: street_address || "",
          city: city || "",
          country: country || "",
          postcode: post_code || "",
          language: language || "",
        },
      });
      localStorage.setItem("profileDataFetched", "false");
    }
  }
  employeehandleFetchApiErr = (responseJson: any) => {
    if (responseJson.message === "you are not authorized") {
      this.props.openToastHandler("You are not authorised.", "error");
    } else {
      const {
        first_name,
        last_name,
        email,
        phone_number,
        street_address,
        city,
        post_code,
        language,
        employee_image,
        country,
      } = responseJson?.data?.attributes;
      this.setState({
        employeeformDetails: {
          ...this.state.employeeformDetails,
          firstName: first_name || "",
          lastName: last_name || "",
          email: email || "",
          phone: phone_number.split(" ")[1] || "",
          streetAddress: street_address || "",
          city: city || "",
          country: country || "",
          postcode: post_code || "",
          language: language || "",
        },
        countryCode: phone_number.split(" ")[0] || "",
        profile_image_employee: employee_image && employee_image?.url,
        companyLogo: "",
      });
    }
  };
  employeehandleFetchApi = (responseJson: any) => {
    if (!responseJson.errors) {
      this.employeehandleFetchApiErr(responseJson);
    } else {
      this.handleEmployeeTokenExpireError(responseJson);
    }
  };

  employeehandleChangePassword = (responseJson: any) => {
    if (!responseJson.errors) {
      if (!responseJson.error) {
        this.setState(
          { successMessage: "You have successfully changed your password" },
          () => {
            this.setState({
              success: true,
              error: false,
              errorMessage: "",
            });
          }
        );
      } else {
        if (
          responseJson.error ===
          "Your old password was entered incorrectly. Please enter it again."
        ) {
          this.setState(
            {
              errorMessage:
                "Your old password was entered incorrectly. Please enter it again.",
            },
            () => {
              this.setState({
                error: true,
                success: false,
                successMessage: "",
              });
            }
          );
        } else {
          this.setState(
            {
              errorMessage: responseJson.error,
            },
            () => {
              this.setState({
                error: true,
                success: false,
                successMessage: "",
              });
            }
          );
        }
      }
    } else {
      //Check Error Response
      this.handleEmployeeTokenExpireError(responseJson);
    }
  };

  handleEmployeeTokenExpireError = async (responseJson: any) => {
    if (
      responseJson.errors[0]?.token == "Token has Expired" ||
      responseJson.errors[0]?.token == "Invalid token"
    ) {
      this.props?.openToastHandler(responseJson?.errors[0].token, "error");
      this.employeetokenExpired();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  employeetokenExpired = async () => {
    const isEmployee = await checkLoggedInUserDashboard();
    this.employeetokenExpiredCallback(isEmployee);
  };

  employeetokenExpiredCallback = (isEmployee: any) => {
    const redirectUrl = isEmployee ? "/Employee/Login" : "/Login";
    setTimeout(
      this.employeetokenExpiredTimeCallback.bind(this, redirectUrl),
      1000
    );
  };

  employeetokenExpiredTimeCallback = (url: any) => {
    localStorage.clear();
    this.props.history.push(url);
  };
  employeefetchAccountData = () => {
    const token = localStorage.getItem("authToken");
    const _id = localStorage.getItem("employeeId");
    const headers = {
      "Content-Type": configJSON.employeefetchProfileDataContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.employeefetchProfileDataCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeefetchProfileDataApiEndPoint}/${_id}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.employeefetchProfileDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  employeeupdateProfileData = () => {
    const token = localStorage.getItem("authToken");
    const employeeId = localStorage.getItem("employeeId");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.employeeupdateProfileDataCallApiId = requestMessage.messageId;
    let formDataEmployee = new FormData();

    formDataEmployee.append(
      "data[first_name]",
      String(this.state.employeeformDetails.firstName).trim()
    );
    formDataEmployee.append(
      "data[last_name]",
      String(this.state.employeeformDetails.lastName).trim()
    );
    formDataEmployee.append(
      "data[email]",
      String(this.state.employeeformDetails.email).trim()
    );
    formDataEmployee.append(
      "data[phone_number]",
      this.state.countryCode + " " + String(this.state.employeeformDetails.phone).trim()
    );
    formDataEmployee.append("data[country_code]", this.state.countryCode);
    formDataEmployee.append(
      "data[street_address]",
      String(this.state.employeeformDetails.streetAddress).trim()
    );
    formDataEmployee.append(
      "data[language]",
      String(this.state.employeeformDetails.language).trim()
    );
    formDataEmployee.append(
      "data[country]",
      String(this.state.employeeformDetails.country).trim()
    );
    formDataEmployee.append("data[city]", String(this.state.employeeformDetails.city).trim());
    formDataEmployee.append(
      "data[post_code]",
      String(this.state.employeeformDetails.postcode).trim()
    );
    formDataEmployee.append(
      "data[latitude]",
      this.state.latitude
    );
    formDataEmployee.append(
      "data[longitude]",
      this.state.longitude
    );
    if (this.state.companyLogo) {
      formDataEmployee.append("data[employee_image]", this.state.companyLogo);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeeupdateProfileDataApiEndPoint}?id=${employeeId}&lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataEmployee
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.employeeupdateProfileDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  employeeupdatePassword = () => {
    const token = localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.employeeupdatePasswordContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.employeeProfileupdatePasswordCallApiId = requestMessage.messageId;
    const httpBody = {
      old_password: this.state.employeepasswordData.oldPassword,
      new_password: this.state.employeepasswordData.newPassword,
      confirm_password: this.state.employeepasswordData.confirmPassword,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeeupdatePasswordApiEndPoint}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.employeeupdatePasswordMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  employeehandleDeleteUploadedLogoPrecallback = async ()=>{
    const isAuthorized = await handleViewData("employee_data");
    this.handleDeleteImg(isAuthorized)
  }

  handleDeleteImg = (isAuthorized: any) => {
    if (isAuthorized.includes("EDIT")) {
     this.employeehandleDeleteUploadedLogo()
    } else {
      this.props.openToastHandler("You are not authorised.", "error")
    }
  };

  employeehandleDeleteUploadedLogo = () => {
  
    const token = localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.employeedeleteProfileImageContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.employeedeleteProfileImageCallApiId = requestMessage.messageId;

    const httpBody = {
      data: {},
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeedeleteProfileImageApiEndPoint}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.employeedeleteProfileImageMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //**********chnage  password block************ */

  employeevalidatePassword = (value: string, errors: any, name: any) => {
    const {
      specialCharRegex,
      atleastOneCapsRegex,
      atleastLowerCaseRegex,
      digitsRegex,
    } = configJSON.passwordValidators;
    const oneUppercase = "Atleast one upper case is required"!;
    const oneLowerCase = "Atleast one lower case is required"!;
    const oneSpecialChar = "Atleast one special character is required"!;
    const oneNumber = "Atleast one number is required"!;
    const eightCharac = "Password length must be 8 characters";
    if (!atleastOneCapsRegex.test(value)) {
      errors[name] = oneUppercase;
    } else if (!atleastLowerCaseRegex.test(value)) {
      errors[name] = oneLowerCase;
    } else if (!specialCharRegex.test(value)) {
      errors[name] = oneSpecialChar;
    } else if (!digitsRegex.test(value)) {
      errors[name] = oneNumber;
    } else if (value.length < 8) {
      errors[name] = eightCharac;
    }
  };

  employeevalidateBothPasswords = (errors: any) => {
    let { confirmPassword, newPassword } = this.state.employeepasswordData;
    if (newPassword !== confirmPassword) {
      errors.commonError = "New Password and Confirm Password should be same!";
    }
  };

  employeeResetPasswordSubmit = () => {
    const errors: any = {};
    let { newPassword, confirmPassword } = this.state.employeepasswordData;
    this.employeevalidatePassword(newPassword, errors, "newPassword");
    this.employeevalidatePassword(confirmPassword, errors, "confirmPassword");
    this.employeevalidateBothPasswords(errors);
    this.setState({ isErrorFormData: errors }, () => {
      if (Object.keys(this.state.isErrorFormData).length === 0) {
        this.employeeupdatePassword();
      }
    });
  };
  employeesetPassword = (e: any) => {
    const { name, value } = e.target;
    const errors: any = {};
    name === "newPassword" &&
      this.employeevalidatePassword(value, errors, "newPassword");
    name === "confirmPassword" &&
      this.employeevalidatePassword(value, errors, "confirmPassword");
    this.setState({
      employeepasswordData: {
        ...this.state.employeepasswordData,
        [e.target.name]: e.target.value,
      },
      errorMessage: "",
      success: false,
      successMessage: "",
      error: false,
      isErrorFormData: errors,
    });
  };

  employeecheckEnableNew = (check: any) => {
    if (check) {
      return "text";
    } else {
      return "password";
    }
    // }
  };

  employeehandleFile = (e: any) => {
    this.setState({
      companyLogo: e.target.files[0],
      profile_image_employee: "",
    });

    this.setState({ logoChangeModal: false });
  };
  employeehandleSuccessModal = () => {
    this.setState({ profileUpdateSuccessful: false });
  };

  employeehandleClickShowPasswordOld = () => {
    this.setState({
      enablePasswordFieldOld: !this.state.enablePasswordFieldOld,
    });
  };
  employeehandleClickShowPasswordNew = () => {
    this.setState({
      enablePasswordFieldNew: !this.state.enablePasswordFieldNew,
    });
  };
  employeehandleClickShowPasswordConfirm = () => {
    this.setState({
      enablePasswordFieldConfirm: !this.state.enablePasswordFieldConfirm,
    });
  };

  //******Logout module functions ********/

  employeehandleLogoutDialog = () => {
    this.setState({
      openLogOut: !this.state.openLogOut,
    });
  };
  employeehandleLogout = () => {
    this.employeehandleLogoutDialog();
    localStorage.clear();

    this.props.navigation.navigate("EmployeeLogin");
  };
  employeehandleCancelLogout = () => {
    this.employeehandleLogoutDialog();
    this.props.navigation.navigate("EmployeeProfile");
  };
  /**************************/
  /************profile functions**************/
  employeehandleChangeFormDetails = (e: any) => {
    this.setState({
      employeeformDetails: {
        ...this.state.employeeformDetails,
        [e.target.name]: e.target.value,
      },
    });
  };

  employeehandleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.setState({ toggleLanguageModal: false });
    }
  };

  emphandleLanguageModalOpen = () => {
    this.setState({ toggleLanguageModal: !this.state.toggleLanguageModal });
  };

  emphandleChangeCountryCode = (event: any, value: any) => {
    this.setState({ countryCode: value });
  };

  emphandleMouseEnterChangeLogo = () => {
    this.setState({
      isHovered: true,
    });
  };

  emphandleMouseLeaveChangeLogo = () => {
    this.setState({
      isHovered: false,
    });
  };

  emphandleMouseEnterDeleteLogo = () => {
    this.setState({
      isHovered2: true,
    });
  };

  emphandleMouseLeaveDeleteLogo = () => {
    this.setState({
      isHovered2: false,
    });
  };
  emphandleClickEditIcon = () => {
    this.setState({ logoChangeModal: !this.state.logoChangeModal });
  };

  emphandleLanguageDropdown = (name: any) => {
    this.setState({
      employeeformDetails: {
        ...this.state.employeeformDetails,
        language: name,
      },
    });
    this.setState({ toggleLanguageModal: false });
  };

  emphandleUpdateProfileCall = () => {
    if (Object.keys(this.state.isErrorFormDetails).length === 0) {
      this.employeeupdateProfileData();
    }
  };

  emphandleDetailsSubmit = async () => {
    const isAuthorized = await handleViewData("employee_data");
    this.handleSave(isAuthorized)

  };
  handleSave = (isAuthorized: any) => {
    const language = this.state.employeeformDetails.language
    if (isAuthorized.includes("EDIT")) {
      this.setState(
        {
          isErrorFormDetails: empvalidateFormDataProfileV1(
            this.state.employeeformDetails, this.props.t
          ),
        },
        () => {
          this.emphandleUpdateProfileCall();
          if (language === 'English') { localStorage.setItem("lang", 'en')} 
          else {
            localStorage.setItem("lang", 'ar');
          }
          window.location.reload();
        }
      );
    } else {
      this.props.openToastHandler("You are not authorised.", "error")
    }
  };

  emphandleTabValueChange = (event: any, value: any) => {
    if (value === 0) {
      this.props.navigation.navigate("EmployeeProfile");
    } else if (value === 1) {
      this.props.navigation.navigate("EmployeeChangePassword");
    } else if (value === 2) {
      this.props.navigation.navigate("EmployeeLogout");
    }
  };

  //autocomplete address fuction start
  onLoadEmp = (autocompleteV2: any) => {
    this.autocomplete = autocompleteV2;
  };

  handleGoogleFormDataEmp = (
    cityV2: string,
    zipcodeV2: any,
    countryV2: any,
    formattedAddressV2: string
  ) => {
    this.setState({
      employeeformDetails: {
        ...this.state.employeeformDetails,

        postcode: zipcodeV2,
        city: cityV2,
        streetAddress: formattedAddressV2,
        country: countryV2,
      },
    });
  };

  handleGeocodeCallbackEmp = (
    addressComponentsV2: any,
    formattedAddressV2: string
  ) => {
    let zipcodeV3 = "";
    let cityV3 = "";
    let countryV3 = "";
    addressComponentsV2.forEach((componentV3: any) => {
      if (componentV3.types.includes("postal_code")) {
        zipcodeV3 = componentV3.long_name;
      } else if (componentV3.types.includes("locality")) {
        cityV3 = componentV3.long_name;
      } else if (componentV3.types.includes("country")) {
        countryV3 = componentV3.long_name;
      }
    });
    this.handleGoogleFormDataEmp(
      cityV3,
      zipcodeV3,
      countryV3,
      formattedAddressV2
    );
  };
  handleGeocodeCallEmp = async (placeV2: any) => {
    const addressComponentsV3 = placeV2.address_components;
    const formattedAddressV3 = placeV2.formatted_address;
    this.setState({
      longitude: placeV2.geometry.location.lng(),
      latitude: placeV2.geometry.location.lat(),
    });
    await this.handleAdressGeocodeEmp(
      addressComponentsV3,
      formattedAddressV3,
      placeV2
    );
  };

  handleAdressGeocodeEmp = async (
    addressComponentsV3: any,
    formattedAddressV3: any,
    placeV2: any
  ) => {
    if (addressComponentsV3 && formattedAddressV3) {
      this.handleGeocodeCallbackEmp(addressComponentsV3, formattedAddressV3);
    } else {
      const placeV3 = this.autocomplete?.getPlace();
      const geocoderV3 = new window.google.maps.Geocoder();

      await geocoderV3.geocode(
        { placeId: placeV3 ? placeV3.place_id : placeV2.place_id },
        (results: any, status: any) => {
          this.handleGeocodeResponseEmp(status, results);
        }
      );
    }
  };

  handleGeocodeResponseEmp = (statusV3: string, resultsV3: any) => {
    if (statusV3 === "OK") {
      const addressComponentsV4 = resultsV3[0].address_components;
      const formattedAddressV4 = resultsV3[0].formatted_address;

      let zipcodeV4 = "";
      let cityV4 = "";
      let countryV4 = "";
      addressComponentsV4.forEach((componentV3: any) => {
        if (componentV3.types.includes("postal_code")) {
          
          zipcodeV4 = componentV3.long_name;
        } else if (componentV3.types.includes("locality")) {
          cityV4 = componentV3.long_name;
        } else if (componentV3.types.includes("country")) {
          countryV4 = componentV3.long_name;
        }
      });
      this.handleGoogleFormDataEmp(
        cityV4,
        zipcodeV4,
        countryV4,
        formattedAddressV4
      );
    }
  };

  fetchAddressEmp = async (placeV2: any) => {
    await this.handleGeocodeCallEmp(placeV2);
  };
  handleKeyPressEmp = async (eventV2: any) => {
    if (eventV2.key === "Enter") {
      const inputValue = eventV2.target.value;

      const geocoderV2 = new window.google.maps.Geocoder();

      await geocoderV2.geocode({ address: inputValue }, (results, status) => {
        this.handleKeyPressCallbackEmp(status, results);
      });
    }
  };
  handleKeyPressCallbackEmp = (statusV2: any, resultsV2: any) => {
    if (statusV2 === "OK" && resultsV2 && resultsV2.length > 0) {
      const placeV2 = resultsV2[0];
      this.fetchAddressEmp(placeV2);
    }
  };
  onPlaceChangedEmp = async () => {
    this.onPlaceChangedCallbackEmp(this.autocomplete);
  };
  onPlaceChangedCallbackEmp = (autoComplete: any) => {
    if (autoComplete != null) {
      const place = autoComplete?.getPlace();
      this.fetchAddressEmp(place);
    }
  };
  //autocomplete address fuction end

  handleBackIconEmployee = () => {
    this.props.navigation.history.goBack();
  }
  // Customizable Area End
}
