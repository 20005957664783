export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mainLogo=require("../assets/logo.png");
export const sidePage=require("../assets/sidePage.png");
export const sidePage1=require("../assets/sidePage610px.png");
export const sidePage2=require("../assets/sidePage710px.png");
export const sidePage3=require("../assets/sidePage790px.png");
export const firstSideImg=require("../assets/firstSideImg.png")
export const arabicBgImage=require("../assets/bgimage.png")
export const resendImage=require("../assets/resend.png")
export const information=require("../assets/image_Information.png");
export const mobileArrow=require("../assets/mobileArrow.png")