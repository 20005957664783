import React from "react";
// Customizable Area Start
import {
    Box,
    Dialog,
    Typography, Grid,Button
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import PdfController, {
    Props,PdfSiteDetails
} from "./PdfController.web";
import { lamsaLogo } from "../../CustomisableUserProfiles/src/assets";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { CONSTANTS } from "../../../components/src/theme";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles"
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
// Customizable Area End



// Customizable Area Start
interface RequestPdfDetails {
    request_id: number;
        service_detail: string;
        source: [];
        appointment_date: string;
        appointment_time: string;
        custmoer_name: string;
}
interface BoInformation {
    name: string;
    email: string;
    company_name: string;
    street_address: string;
    post_code: number;
    city: string;
    full_phone_number: string;
  }
  interface Employee {
    map(arg0: (employee: Employee, index: number) => JSX.Element): React.ReactNode;
    length: number;
    id: number;
    name: string;
    email: string;
}
export const StyledDialog = withStyles({
    root: {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1166px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
        }
    }
})(Dialog);
export const customerDetails = (
    classes: ClassNameMap<string>,
    t: ((key: string) => string) | undefined,
    pdfSiteDetails: PdfSiteDetails,
    requestPdfDetails: RequestPdfDetails
) => {
    return (
        <div className={classes.requestUserDetails}>
            <Grid container direction="row" spacing={3} >
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.customerHeadingText}>{t? t('request.requestFor'):"Request for"} &nbsp; {requestPdfDetails.custmoer_name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.customerHeadingText}>{t ? t('customerDetails.phoneNo') : "Phone Number"}</Typography>
                    <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.phone_number}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={7}>
                            <Typography variant="body1" className={classes.customerHeadingText}>{t ? t('customerDetails.address') : "Address"}</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.site_address}</Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography variant="body1" className={classes.customerHeadingText}>{t ? t('customerDetails.state') : "State"}</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.state}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={7}>
                            <Typography variant="body1" className={classes.customerHeadingText}>{t ? t('customerDetails.city') : "City"}</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.city}</Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography variant="body1" className={classes.customerHeadingText}>{t ? t('customerDetails.zipCode') : "Zip Code"}</Typography>
                            <Typography variant="body1" className={classes.sourceText}>{pdfSiteDetails.zip_code}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
export const addressData = (
    classes: ClassNameMap<string>,
    t: ((key: string) => string) | undefined,
    requestPdfDetails: {bo_information : BoInformation , request_id: string , request_title:string , service_detail: string , appointment_date: string
        source: string , on_site: boolean , employees:Employee
    },
    pdfSiteDetails: PdfSiteDetails
) => {
    return (
        <>
            <div 
            className={classes.requestUserDetails} 
            style={webStyle.requestUserDetails}>
            <Grid container direction="row" 
                spacing={3} >
                    <Grid item xs={6}>
                        <Box>
                            <Typography
                                variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark} >{requestPdfDetails?.bo_information?.name}
                            </Typography>
                            <Typography
                                variant="body1" className={classes.sourceText}>{requestPdfDetails?.bo_information?.email}
                            </Typography>
                            <Typography
                                variant="body1" className={classes.sourceText}>{requestPdfDetails?.bo_information?.street_address}&nbsp;{requestPdfDetails?.bo_information?.post_code}
                            </Typography>
                            <Typography
                                variant="body1" className={classes.sourceText}>{requestPdfDetails?.bo_information?.full_phone_number}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>{pdfSiteDetails.site_name}</Typography>
                            <Typography
                                variant="body1" className={classes.sourceText}>{pdfSiteDetails.email}</Typography>
                            <Typography
                                variant="body1" className={classes.sourceText}>{pdfSiteDetails.site_address}</Typography>
                            <Typography
                                variant="body1" className={classes.sourceText}>{pdfSiteDetails.phone_number}</Typography>
                        </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box style={webStyle.titleDe}>
                        <Box>
                            <Typography 
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Request Issue Date
                            </Typography>
                            <Typography 
                            variant="body1" className={classes.sourceText}>{requestPdfDetails.appointment_date}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography 
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Request ID
                            </Typography>
                            <Typography 
                            variant="body1" className={classes.sourceText}>{requestPdfDetails.request_id}</Typography>
                        </Box>
                        <Box>
                            <Typography 
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Scheduled Date and Time
                            </Typography>
                            <Typography 
                            variant="body1" className={classes.sourceText}>{"Start:"} April 18, 2024 5:00 PM</Typography>
                        </Box>
                    </Box>
                </Grid>
                
            </Grid>
        </div>
        <div>
            <Grid item xs={12}>
                    <Box style={webStyle.footerSpace}>
                        <Typography
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Request Title: {requestPdfDetails.request_title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDark}>Service Detail
                        </Typography>
                        <Typography
                            variant="body1" className={classes.sourceText}>
                                {requestPdfDetails.service_detail}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDarkspace}>Request Source
                        </Typography>
                        <Typography
                            variant="body1" className={classes.sourceText}>{requestPdfDetails.source}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDarkspace}>On Site Asessment
                        </Typography>
                        <Typography
                            variant="body1" className={classes.sourceText}> {requestPdfDetails.on_site ? "Yes" : "No"}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="body1" className={classes.customerHeadingText} style={webStyle.fontDarkspace}>Assigned Team Member
                        </Typography>
                        <Typography
                            variant="body1" className={classes.sourceText}>
                                {requestPdfDetails?.employees?.map((employee : Employee, index : number) => (
                                    <span key={index}>
                                    {employee.name}
                                    {index < requestPdfDetails.employees.length - 1 && ', '}
                                    </span>
                                ))}
                        </Typography>
                    </Box>
                </Grid> 
            </div>
        </>
    );
}
// Customizable Area End

export class RequestPdf extends PdfController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        // created date

        const { classes,t } = this.props
        const formattedDate = new Date(this.state?.requestPdfDetails?.created_at).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
        return (
            <StyledDialog
                fullWidth={true}
                maxWidth={"lg"}
                open={this.props.openPdf}
                onClose={this.props.closePdf}
            >
                <Box style={webStyle.requestDownloadLayout}>
                    <Box className={classes.cursor}>
                        <CloseIcon
                            style={{ fontSize: 30 }}
                            onClick={this.props.closePdf}
                        />
                    </Box>
                </Box>
                <div style={{ padding: "3rem" }} className="requestPdf">
                    <Box className={classes.containerLayout} style={{ border: `5px solid ${this.state.requestPdfDetails?.pdf_url?.pdf_hex_colour || CONSTANTS.color.black}` }}>
                        <Box className={classes.requestInnerContainer} style={{ border: `1px solid ${this.state.requestPdfDetails?.pdf_url?.pdf_hex_colour || CONSTANTS.color.black}` }}>
                            <div className={classes.flex_end} style={webStyle.lamsaLogoContainer}>
                                <img
                                    style={(!this.state.pdfLoading && this.state.requestPdfDetails?.company_logo?.url) ? webStyle.companyLogo : webStyle.lamsaLogo}
                                    src={(!this.state.pdfLoading && this.state.requestPdfDetails?.company_logo?.url) || lamsaLogo}
                                    alt=""
                                />
                            </div>
                            <Box style={webStyle.shippingContainerLayout}>
                                <Box>
                                    {addressData(classes,t,this.state.requestPdfDetails,this.state.pdfSiteDetails)}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </div>

                <Box className={classes.downloadButtonLayout}>
                    <CreateQuote data-test-id="downloadPdf">
                        <Typography component={'a'}
                            href={this.state.requestPdfDetails?.pdf_url?.url}
                            download
                            target="_blank"
                            className={classes.downloadPdfText}
                            variant="subtitle2"
                        >
                            {t? t('request.button.download'):"Download"}
                        </Typography>
                    </CreateQuote>
                </Box>


                <LoadingBackdrop
                    loading={this.state.pdfLoading}
                />
            </StyledDialog>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const styles = (theme: Theme) =>
    createStyles({
        sourceText: {
            fontWeight: theme.typography.fontWeightMedium,
            color: CONSTANTS.color.silverGrey,
            textTransform: "capitalize"
        },
        customerHeadingText: {
            fontWeight: theme.typography.fontWeightRegular,
            color: CONSTANTS.color.textLightGrey,
        },
        appointmentHeadings: {
            fontWeight: theme.typography.fontWeightRegular,
        },
        appointmentIcon: {
            width: "1rem",
            height: "1rem"
        },
        downloadButtonLayout: {
            display: "flex",
            justifyContent: "flex-end",
            padding: " 0 3rem 3rem",
            alignItems: "center"
        },
        downloadPdfText: {
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightRegular,
            textAlign: "center",
            textDecoration:'none'
        },
        requestPdfOrderText: {
            color: CONSTANTS.color.lightBrown,
            fontWeight: theme.typography.fontWeightMedium,
            marginLeft: "20px",
            fontStyle: "normal"
        },
        formattedDateText: {

            color: CONSTANTS.color.charcoalGrey,
            fontWeight: theme.typography.fontWeightRegular,
        },
        requestInnerContainer: {
            padding: "3rem 3rem 30rem",
            [theme.breakpoints.down('sm')]: {
                padding: "1rem"
            }
        },

        containerLayout: {
            padding: "8px",
            width: "100%",
            height: "100%"
        }


    });

const webStyle = {
    lamsaLogoContainer: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        margin:"0 0 20px 0"
    },
    requestUserDetails:{
        borderBottom:"1px solid black",
        paddingBottom:20,
        marginBottom:20
    },
    titleDe:{
        display:"flex", 
        flexDirection:"column" as const,
        alignItems: 'end' ,
        textAlign: 'end' as const,
        gap:20
    },
    footerSpace:{
        margin:"50px 0 20px 0"
    },
    requestDownloadLayout: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "3rem 3rem 0 3rem",
        alignItems: "center"
    },
    requestCloseIconButton: {
        cursor: "pointer"
    },

    logoLayout: {
        width: "125px",
        height: "40px"
    },
    lamsaLogo: {
        width: "125px",
        height: "40px"
    },
    companyLogo: {
        width: "100px",
        height: "100px"
    },
    pdfOrderLabel: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "12px",
        padding: "1.5rem 0 0rem"
    },
    pdfOrderIcon: {
        width: "20px",
        height: "20px"
    },
    shippingContainerLayout: {
        display: "flex",
        justifyContent: "space-between",
    },
    pdfDivider: {
        display: "flex",
        height: "1px",
        border: "1px solid rgba(0,0,0,0.1)",
        margin: "1rem 0 3rem"
    },
    vendorHeading: {
        color: "#4c4c4c",
        fontFamily: "Rubik",
        fontSize: "18px",
        fontWeight: 500
    },
    sechudleLayout: {
        display: "flex",
        alignItems: "center"
    },
    fontDark: {
        fontWeight: 500 ,
        color: "black",
    },
    fontDarkspace: {
        fontWeight: 500 ,
        color: "black",
        marginTop:"10px"
    },
}
const RequestPdfStyles = withStyles(styles)(RequestPdf);
export default withCommonStyles(RequestPdfStyles)
// Customizable Area End