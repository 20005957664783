import React from "react";
import { StyledDialog, StyledDialogTitle,StyledDialogContent, StyledDialogActions,StyledDialogContentText } from "../../blocks/dashboard/src/FreeTrialExpired.web"
import {
    Box,
    Typography,
} from "@material-ui/core";
import { CONSTANTS } from "./theme"
import CloseIcon from '@material-ui/icons/Close';
import { ContainedButton, ArcheiveButton } from "./CustomizableComponents.web"


const ConfirmationModal = (props: any) => {
    return (
        <StyledDialog open={props.open}>
            <CloseIcon
                style={props.languageSelected === "en" ?
                    props.isMobile ? webStyle.closeIconMobile : webStyle.closeIcon : props.isMobile ? webStyle.closeIconArMobile : webStyle.closeIconAr}
                onClick={props.handleCancel}
            />
            <StyledDialogTitle >
                {props.title}
            </StyledDialogTitle>
            <StyledDialogContent >
                <StyledDialogContentText>
                    {props.content}
                </StyledDialogContentText>
            </StyledDialogContent>
            <StyledDialogActions>
                <Box display={"flex"} width={"100%"} justifyContent={"space-between"} gridGap={"0.5rem"}>
                    <ArcheiveButton
                        onClick ={props.handleCancel}
                        style={{ maxWidth: "162px" }}
                       
                    >
                        <Typography
                          
                            style={webStyle.buttonTextCancel}
                            variant="subtitle2"
                        >
                           {props.cancel}
                        </Typography>
                    </ArcheiveButton>
                    <ContainedButton
                    style={{maxWidth: "162px" }}
                        onClick={props.handleLeave}
                    >
                        <Typography
                            style={{ ...webStyle.buttonTextCancel, color: "white" }}
                            variant="subtitle2"
                        >
                           {props.leave}
                        </Typography>
                    </ContainedButton>
                </Box>
            </StyledDialogActions>
        </StyledDialog>
    )
}
const webStyle = {
    buttonTextCancel: {
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        textAlign: "center" as const,
    },
    closeIconMobile: {
        position: "absolute" as const,
        left: "93%",
        top: "12%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer"
    },
    closeIcon: {
        position: "absolute" as const,
        left: "97%",
        top: "12%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer"
    },
    closeIconAr: {
        position: "absolute" as const,
        right: "88%",
        top: "12%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer"
    },
    closeIconArMobile: {
        position: "absolute" as const,
        right: "86%",
        top: "12%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer"
    },
}
export default ConfirmationModal