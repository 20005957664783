import React from "react";

// Customizable Area Start
import Select from "react-select";
import { CONSTANTS } from './theme';
// Customizable Area End

// Customizable Area Start
const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",
  
    ":before": {
      backgroundColor: color,
      borderRadius: 8,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 20,
    }
  });

 
// Customizable Area End

export class CustomSearchableSelect extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const customSelectStyles = {
      indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
      control: (styles: any,state:any) => ({ ...styles, borderRadius:this.props.borderRadius || "8px", padding: this.props.padding || "7px",fontSize:this.props.fontSize,
      borderColor: "hsl(0,0%,80%)",
      boxShadow:"none",
      '&:hover': {
        borderColor: "hsl(0,0%,80%)", // Remove border color when hovered
      },
    }),
      valueContainer: (styles: any) => ({ ...styles, padding: "0px 0px" }),
      placeholder: (styles: any) => ({ ...styles, ...dot("#ccc") }),
      menuList: (base: any) => ({
        ...base,
        height: this.props.menuHeight // your desired height
      }),
      singleValue:(styles:any)=>({...styles, color: CONSTANTS.color.lightGrey,fontSize:'19px'}),
      menu: (styles:any) => ({
        ...styles,
        maxHeight: this.props.maxHeight, // Set the maximum height of the dropdown menu
        zIndex: 99999
      }),
      
    };
    return (
      <>
      {this.props.type === 'job'?
        <Select
          isSearchable={true}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onBlur={this.props.onBlurFun}
          onChange={(selected: any) => this.props.onChangeFun(selected)}
          value={this.props.value}
          styles={customSelectStyles}
          options={this.props.options}
          isDisabled={this.props?.disabled}
          theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: CONSTANTS.color.darkBlue } })}
        /> :
        <Select
          isSearchable={true}
          name="Job_id"
          placeholder={this.props.placeholder}
          onChange={(selected: any) => this.props.onChangeFun(selected)}
          styles={customSelectStyles}
          options={this.props.options}
          menuPlacement={this.props.menuPlacement}
          defaultValue={this.props.defaultVal}
          value={this.props.value}
          theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: CONSTANTS.color.darkBlue } })}
        />
      }
      </>  
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default(CustomSearchableSelect);
// Customizable Area End