Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTaskTracking";
exports.labelBodyText = "ProjectTaskTracking Body";
exports.completedTasksLabel = "Completed Tasks";

exports.btnExampleTitle = "CLICK ME";

exports.authToken = "authToken";
exports.tokenExpired = "Token has Expired";
exports.invalidToken = "Invalid token";
exports.errorLabel = "error";

exports.taskAPiEndPoint = "bx_block_request_management/task_allocates";
exports.getAPiMethod = "GET";
exports.deleteAPiMethod = "DELETE";
exports.putAPiMethod = "PUT";
// Customizable Area End