import React from "react";
// Customizable Area Start
import {
  Grid, Typography, Button, withStyles, Box, Theme,Tooltip
} from "@material-ui/core";
import { uploadIcon,uploadButtonIcon } from "../../RequestManagement/src/assets";
import { StyledTextFieldQuote } from "../../RequestManagement/src/Utility.web"
import { FileUpload } from "../../../components/src/FileUpload.web";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import ClearIcon from '@material-ui/icons/Clear';
import { withSnackbar } from "../../../components/src/toastweb";
import { CONSTANTS } from "../../../components/src/theme"
export const configJSON = require("./config");
// Customizable Area End



export class InternalNotes extends React.Component {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

  showUploadedFiles = (file: any,extensionIcons:any,isEditable:any,removeUploadAttachmentFile:any,handleDownloadFile:any) => {
    const {classes}:any=this.props
    return ( <>
      <Grid item xs={10} lg={7} key={file?.name} className={classes.fileLayout}>
        <Box className={classes.imageBox}>
          <Box className={classes.imageBoxV1}>
            <img
              className={classes.imageUpload}
              src={
                file?.name && extensionIcons[file.name.split(".")[1]]
              }
              alt="upload"
            />
          </Box>
          <Box className={classes.textBox}>
            <Box className={classes.imageBoxV1}>
              <Typography
                gutterBottom
                variant="body2"
                className={classes.CreateCustTextTypo}
              >
                {file?.name && file.name.length > 20
                  ? file.name
                    .split(".")[0]
                    .concat(".")
                    .concat(file.name.split(".")[1])
                    .substring(0, 20) + "..."
                  : file?.name}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.CreateSizeText}>
              {Math.floor(file?.size / 1024)}kb
            </Typography>
          </Box>
          <Box className={classes.uploadFileBox}>
            {isEditable &&
              <ClearIcon
                style={{ cursor: "pointer" }}
                onClick={removeUploadAttachmentFile.bind(this, file)}
              />
            }
          </Box>
        </Box>
      </Grid>
      <Grid item xs={1} lg={4} className={classes.uploadIconGrid}>
      <Tooltip title="download">
        <img src={uploadButtonIcon} className={classes.uploadIconButton} data-test-id="downloadFiles" onClick={handleDownloadFile.bind(this, file)} />
        </Tooltip>
      </Grid>
      </>
    );
  };
  render() {
    // Customizable Area Start
    const {classes,languageSelected,t,internalNotes,files,isEditable,removeUploadAttachmentFile,handleDownloadFile,extensionIcons,updateInternalNotes,uploadAttachmentFile,completed,progress}:any=this.props
    
    return (
      <>
        <Grid container direction="row" spacing={1}>
          <Typography variant="subtitle1" className={`${classes.fontW600} ${classes.lightBrown} ${classes.text_marginTop}`}>{t('internalNote.title')}</Typography>
          <Grid item xs={12}>
            <StyledTextFieldQuote
              variant="outlined"
              multiline
              minRows={5}
              maxRows={5}
              className={classes.notesInput}
              placeholder={`${t('internalNote.placeholder.lorem')}`}
              name="message"
              value={internalNotes}
              data-test-id='internal_notes'
              disabled={!isEditable}
              onChange={(event: any) => updateInternalNotes(event.target.value)}
            />
          </Grid>
              <Grid item xs={12} className={classes.uploadSection_margin}>
                  <Button
                    variant="outlined"
                    component="label"
                    disableRipple
                    disableElevation
                    disableFocusRipple
                    className={classes.upload}
                  >
                    <img src={uploadIcon} className={classes.uploadIcon} />
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography variant="body2"
                        className={classes.subTitle18}
                      >
                        <input
                          hidden
                          type="file"
                          accept="image/*"
                          multiple
                          name="files"
                          data-test-id="upload-file"
                          onChange={(event) => uploadAttachmentFile(event)}
                          disabled={!isEditable}
                        />
                        {t('ProductService.uploadFile')}
                      </Typography>
                    </Box>
                  </Button>

                  {!completed && progress > 0 && (
                    <FileUpload
                      {...this.props}
                      progress={progress}
                      t={t}
                      languageSelected={languageSelected}
                    />
                  )}
                <Grid item lg={12} md={12}
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
              <Grid item xs={12} className={classes.uploadGrid}>
                {completed &&
                  files && files.length > 0 &&
                  <Grid container>
                    <Typography variant="body1" gutterBottom className={`${classes.fontWeight_500} ${classes.mt_20} ${classes.mb_20} ${classes.font_lightBrown}`} >
                      {t ? t('ProductService.uploadFile') : "Upload Files:"}:
                    </Typography>
                    <Box className={classes.fileUploadBox}>
                      <Grid container direction="row" className={classes.uploadBoxGrid}>
                        {files.map((file: object[]) => {
                          return this.showUploadedFiles(file, extensionIcons, isEditable, removeUploadAttachmentFile, handleDownloadFile);
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                }
              </Grid>
                </Grid>
              </Grid>
        </Grid>
        <Grid item xs={12} md={4}/>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const InternalNotesStyles:any = (theme: Theme) => ({
  fontW600: {
    fontWeight: CONSTANTS.fontWeight.fontWeight600
  },
  lightBrown: {
    color: CONSTANTS.color.lightBrown
  },
  imageUpload: {
    width: "38px",
    height: "40px",
  },
  uploadIcon:{
    marginLeft:"12px",
    marginRight:"12px",
    height:"20px",
    width:"20px"
  },
  text_marginTop: {
    marginTop: '25px',
    [theme.breakpoints.down("xs")]: {
      marginTop: '20px',
    }
  },
  uploadSection_margin: {
    marginTop: "20px"
  },
  mt_20: {
    marginTop: '20px',
  },
  mb_20: {
    marginBottom: "20px"
  },
  fontWeight_500: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  font_lightBrown: {
    color: CONSTANTS.color.lightBrown
  },
  fileUploadBox:{
    width:"100%",
    padding:"20px 25px",
    border:"1px solid #D5D5D5",
    borderRadius:"12px",
    [theme.breakpoints.down("xs")]: {
      padding:"18px 13px",
    },
  },
  imageBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "12px 9px 12px 9px",
  },
  textBox: {
    display: "flex",
    width:"100%"
  },
  notesInput: {
    width: "100%",
    height: "130px",
    borderRadius: "8px",
  },
  imageBoxV1: {
    marginRight: "9px",
  },
  upload: {
    border: `2px dashed ${CONSTANTS.color.darkBlue}`,
    height: "60px",
    background: CONSTANTS.color.blueLignt,
    color: CONSTANTS.color.darkBlue,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "capitalize",
    borderRadius: "8px",
    width: "100%",
    "&:hover": {
      background: CONSTANTS.color.blueLignt,
    }
  },
  fileLayout: {
    width:"100%",
    margin: "4px 0px",
    borderRadius: "8px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  CreateCustTextTypo: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: 0,
  },
  uploadIconButton:{
    width:"40px",
    height:"40px",
    cursor:"pointer",
    [theme.breakpoints.down("xs")]: {
      width: "32px",
      height: "32px",
      cursor:"pointer"
    },
  },
  attachmentFileBox: {
    width: "100%",
    margin: 0,
    display: "flex",
    justifyContent: "flex-end"
  },
  CreateCustUploadTypo: {
    color: "#34313a",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "right",
    marginBottom: 0,
    padding: "3px 5px 0 0",
    cursor: "pointer",
  },
  uploadIconGrid:{
    alignItems:"center",
    display:"flex",
    marginLeft:"15px",
    marginRight:"15px",
    [theme.breakpoints.down("xs")]: {
      marginLeft:"9px",
      marginRight:"9px",
    },
  },
  uploadFileBox: {
    width: "100%",
    margin: 0,
    display: "flex",
    justifyContent: "flex-end"
  },
  uploadGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
      overflowX:"scroll"
    },
  },
});
const InternalCommonStyles = withCommonStyles(InternalNotes)
const InternalNotesAttachmentsToaster = withSnackbar(InternalCommonStyles)
export default withStyles(InternalNotesStyles)(InternalNotesAttachmentsToaster);
// Customizable Area End