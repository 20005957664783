import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Container,
  Link,
  Typography,
  Hidden
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import NavBar from "./NavBar.web";
import Footer from "./Footer.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import TermsAndConditionsController, {
  Props,
} from "./TermsAndConditionsController.web";

// Customizable Area Start
interface TermsConditionsData {
  id: string;
  type: string;
  attributes: {
    id?: string | number;
    title: string;
    body: string;
  }
}
// Customizable Area End

export class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (
      <>
        <NavBar {...this.props} active={false} homePage={this.termsConditionsPage} oldProps={this} />
        {!this.state.termsLoading ?
          <>
            <Box
              style={webStyle.container}
              className={classes.container}
            >
              <Container maxWidth={"lg"} style={webStyle.containerStyle}>
                <Grid container spacing={2}>
                  <Hidden xsDown>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box style={webStyle.sideBar} className={classes.sideBar}>
                        {this.state.termsConditionsData?.map((item: TermsConditionsData) => {
                          return (
                            <Link href={`#${item?.attributes?.id}`} underline={"none"} key={item?.id}>
                              <Typography
                                data-test-id="handleSelectSection"
                                style={{
                                  ...(this.state.activeId === item?.attributes?.id ? 
                                    {...webStyle.item, ...webStyle.selectedStyle} : webStyle.item),
                                  ...(this.state.activeId === item?.attributes?.id && this.state.languageSelected === "ar") && 
                                    webStyle.borderStyle
                                }}
                                className={classes.item}
                                variant="body1"
                                onClick={(e: any) => this.handleSelectSection(e, item)}
                              >
                                {item?.attributes?.id}. {item?.attributes?.title?.replace(/<p>/g ,'').replace(/<\/p>/g ,'')}
                              </Typography>
                            </Link>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Hidden>
                  <Grid item lg={8} md={8} xs={12}>
                    <Box style={webStyle.rightContent} className={classes.rightContent}>
                      <Box style={webStyle.innerContent}>
                        {this.state.termsConditionsData?.map((item: TermsConditionsData) => (
                          <Box key={item?.id} id={item?.id}>
                            <Typography
                              style={webStyle.oneTitle}
                              className={classes.oneTitle}
                              variant="h5"
                            >
                              {item?.attributes?.id}. {item?.attributes?.title?.replace(/<p>/g ,'').replace(/<\/p>/g ,'')}
                            </Typography>
                            <Typography
                              style={webStyle.oneBody}
                              className={classes.oneBody}
                              variant="subtitle1"
                              dangerouslySetInnerHTML={{
                                __html: (
                                  item?.attributes?.body
                                )
                              }}
                            ></Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Footer {...this.props} active={false} homePage={this.termsConditionsPage} oldProps={this} />
          </>
        :
          <LoadingBackdrop loading={this.state.termsLoading} />
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  sideBar: {
    [theme.breakpoints.down("md")]: {
      position: "relative !important",
      maxWidth: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2rem !important",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10rem !important",
      padding: "0rem 0rem 1rem 0rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8rem !important",
    },
  },
  oneTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
      fontWeight: `${CONSTANTS.fontWeight.fontWeight500} !important`,
      marginTop: "1rem !important",
    }
  },
  oneBody: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "32px !important"
    }
  },
  rightContent: {
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none !important",
      padding: "0.5rem !important"
    }
  },
});

const webStyle = {
  container: {
    marginTop: "12rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0rem 2rem 5rem 2rem",
  },
  containerStyle: {
    maxWidth: "1380px"
  },
  sideBar: {
    position: "fixed" as const,
    overflow: "auto",
    height: "100vh",
    paddingBottom: "27.5rem",
    maxWidth: "390px",
    width: "100%"
  },
  item: {
    color: CONSTANTS.color.thickBlue,
    fontWeight: "bold" as const,
    padding: "1rem",
    textTransform: "uppercase" as const,
  },
  selectedStyle: {
    borderLeft: `6px solid ${CONSTANTS.color.darkBlue}`,
    borderRadius: "5px",
    backgroundColor: "rgba(5,3,122,0.08)",
    boxShadow: "0px 0px 21px -2px rgba(28,24,175,0.07)"
  },
  borderStyle: {
    borderRight: `6px solid ${CONSTANTS.color.darkBlue}`,
    borderLeft: 0
  },
  rightContent: {
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "-10px 6px 20px rgba(28,24,175,0.07)",
    padding: "2rem 0px 2rem 2rem"
  },
  innerContent: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  oneTitle: {
    color: CONSTANTS.color.thickBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "uppercase" as const,
    marginTop: "2rem"
  },
  oneBody: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "40px",
    textAlign:"justify" as const,
    marginTop: "1rem"
  },
}
const TermsAndConditionsStyles =  withStyles(styles)(TermsAndConditions);
export default withTranslation()(TermsAndConditionsStyles);
// Customizable Area End