import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Container,
  Link,
  Typography,
  Hidden
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import NavBar from "./NavBar.web";
import Footer from "./Footer.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import PrivacyPolicyController, {
  Props,
} from "./PrivacyPolicyController.web";

// Customizable Area Start
interface ItemTypes {
  id: number;
  title: string;
}

const selectionStyle = (activeId: number, item: ItemTypes, languageSelected: string) => {
  return  {...(activeId === item.id ?
    {...webStyle.item, ...webStyle.selectedStyle} : webStyle.item),
    ...(activeId === item.id && languageSelected === "ar") && 
      webStyle.borderStyle
  }
}

const selectionPointsStyle = (activeId2: number, item: ItemTypes, languageSelected: string) => {
  return  {...(activeId2 === item.id ?
    {...webStyle.item2, ...webStyle.selectedStyle} : webStyle.item2),
    ...(activeId2 === item.id && languageSelected === "ar") && 
      webStyle.borderStyle
  }
}

interface PrivacyPolicyData {
  id: number;
  title: string;
  body: string;
}
// Customizable Area End

export class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const privacyPolicyDataNotice = this.state.privacyPolicyData?.notice;
    const privacyPolicyDataSummary = this.state.privacyPolicyData?.summary;
    const mockData = [
      {
        id: privacyPolicyDataNotice && this.state.privacyPolicyData?.notice[0]?.id,
        title: privacyPolicyDataNotice && this.state.privacyPolicyData?.notice[0]?.title
      },
      {
        id: privacyPolicyDataSummary && this.state.privacyPolicyData?.summary[0]?.id,
        title: privacyPolicyDataSummary && this.state.privacyPolicyData?.summary[0]?.title
      },
    ]
    const privacyPolicyContentData = this.state.privacyPolicyData?.content;

    const dateString = this.state.privacyPolicyData?.notice?.[0]?.updated_at;
    const dateObject = new Date(dateString);

    const options: Record<string, string> = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = dateObject?.toLocaleDateString('en-US', options);
    const tdClass = `class="${classes.tdPadding}"`;
    const { t } = this.props;
    return (
      <>
        <NavBar {...this.props} active={false} homePage={this.privacyPolicyPage} oldProps={this} />
        {!this.state.privacyLoading ?
          <>
            <Box
              style={webStyle.container}
              className={classes.container}
            >
              <Container maxWidth={"lg"} style={webStyle.containerStyle}>
                <Grid container spacing={2}>
                  <Hidden xsDown>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box style={webStyle.sideBar} className={classes.sideBar}>
                        {mockData.map((item) => (
                          <Link href={`#${item.id}`} underline={"none"} key={item.id}>
                            <Typography
                              data-test-id="handleSelectSection"
                              style={selectionStyle(this.state.activeId as number, item, this.state.languageSelected)}
                              className={classes.item}
                              variant="body1"
                              onClick={(e: any) => this.handleSelectSection(e, item.id)}
                            >
                              {item.id}. {item.title}
                            </Typography>
                          </Link>
                        ))}
                        <Typography
                          style={webStyle.item}
                          className={classes.item}
                          variant="body1"
                        >
                          3. {t('landingPage.PrivacyPolicy.tableOfContent')}
                        </Typography>
                        <Box style={webStyle.marginLeftStyle}>
                          {privacyPolicyContentData?.map((item: PrivacyPolicyData, index: number) => (
                            <Link href={`#${item?.id}`} underline={"none"} key={item.id}>
                              <Typography
                                data-test-id="handleSelectSection"
                                style={selectionPointsStyle(this.state.activeId2 as number, item, this.state.languageSelected)}
                                className={classes.item}
                                variant="body2"
                                onClick={(e: any) => this.handleSelectSection2(e, item?.id)}
                              >
                                3.{index+1}. {item.title}
                              </Typography>
                            </Link>
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Hidden>
                  <Grid item lg={8} md={8} xs={12}>
                    <Box style={webStyle.rightContent} className={classes.rightContent}>
                      <Box style={webStyle.innerContent} id="1">
                        <Box style={webStyle.privacyContainer}>
                          <Typography
                            style={webStyle.privacyTitle}
                            className={classes.privacyTitle}
                            variant="h5"
                            dangerouslySetInnerHTML={{
                              __html: (
                                privacyPolicyDataNotice && this.state.privacyPolicyData?.notice[0]?.title
                              )
                            }}
                          ></Typography>
                          <Typography
                            style={webStyle.privacyBody}
                            className={classes.privacyBody}
                            variant="subtitle1"
                          >
                            {`${t('landingPage.PrivacyPolicy.lastUpdated')} ${formattedDate}`}
                          </Typography>
                        </Box>
                        <Typography
                          style={{...webStyle.oneBody, ...webStyle.marginTopStyle}}
                          className={`${classes.marginTopStyle} ${classes.tableContents}`}
                          variant="subtitle1"
                          dangerouslySetInnerHTML={{
                            __html: (
                              privacyPolicyDataNotice && this.state.privacyPolicyData?.notice[0]?.body
                            )
                          }}
                        ></Typography>
                      </Box>
                      <Box style={{...webStyle.innerContent, ...webStyle.marginTopStyle}} id="2">
                        <Typography
                          style={{...webStyle.privacyTitle, ...webStyle.titleColor}}
                          className={classes.privacyTitle}
                          variant="h5"
                          dangerouslySetInnerHTML={{
                            __html: (
                              privacyPolicyDataSummary && this.state.privacyPolicyData?.summary[0]?.title
                            )
                          }}
                        ></Typography>
                        <Typography
                          style={{...webStyle.oneBody, ...webStyle.marginTopStyle}}
                          className={classes.tableContents}
                          variant="subtitle1"
                          dangerouslySetInnerHTML={{
                            __html: (
                              privacyPolicyDataSummary && this.state.privacyPolicyData?.summary[0]?.body
                            )
                          }}
                        ></Typography>
                      </Box>
                      <Typography
                        style={{...webStyle.tableOfContents, ...webStyle.tableContentStyle}}
                        variant="h5"
                      >
                        {t('landingPage.PrivacyPolicy.tableOfContent')}
                      </Typography>
                      {privacyPolicyContentData?.map((item: PrivacyPolicyData, index: number) => (
                        <Typography
                          key={item.id}
                          style={webStyle.tableContents}
                          className={classes.tableContents}
                          variant="subtitle1"
                        >
                          {index+1}.
                          <span style={{marginLeft:"5px"}}>
                            {item.title.charAt(0).toUpperCase() + item.title.slice(1).toLowerCase()}
                          </span>  
                        </Typography>
                      ))}
                      {privacyPolicyContentData?.map((item: any, index: number) => (
                        <Box key={item?.id} id={item?.id}>
                          <Typography
                            style={{...webStyle.tableOfContents, ...webStyle.paddingTopStyle}}
                            className={`${classes.tableContents} ${classes.paddingTopStyle}`}
                            variant="h5"
                            dangerouslySetInnerHTML={{
                              __html: (`${index+1}. ${item?.title}`)
                            }}
                          ></Typography>
                          <Typography
                            style={webStyle.oneBody}
                            className={classes.oneBody}
                            variant="subtitle1"
                            dangerouslySetInnerHTML={{
                              __html: 
                              item&&item.body?.replace(/<td/g, `<td ${tdClass}`)
                            }}
                          ></Typography>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Footer {...this.props} active={false} homePage={this.privacyPolicyPage} oldProps={this} />
          </>
        :
          <LoadingBackdrop
            loading={this.state.privacyLoading}
          />
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  sideBar: {
    [theme.breakpoints.down("md")]: {
      position: "relative !important",
      maxWidth: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2rem !important",
    }
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10rem !important",
      padding: "0rem 0rem 1rem 0rem !important",
    }
  },
  rightContent: {
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none !important",
      padding: "0.5rem !important"
    }
  },
  oneBody: {
    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-all !important",
    },
    '& li::first-letter': {
      textTransform: 'capitalize',
    },
    '& ul': {
      listStyleType: 'none',
      paddingLeft: '0',
    },
    '& li': {
      position: 'relative',
      paddingLeft: '0.8em',
    },
    '& li::before': {
      content: '"-"',
      position: 'absolute',
      left: 0,
    },
  },
  marginTopStyle: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem !important",
    }
  },
  privacyTitle: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: `${CONSTANTS.fontWeight.fontWeight500} !important`,
      fontSize: "24px !important",
    }
  },
  privacyBody: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.5rem !important",
    }
  },
  tableContents: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "30px !important",
    }
  },
  paddingTopStyle: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "1rem !important",
    }
  },
  tdPadding: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

const webStyle = {
  container: {
    marginTop: "12rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0rem 2rem 5rem 2rem",
  },
  containerStyle: {
    maxWidth: "1380px"
  },
  sideBar: {
    position: "fixed" as const,
    overflow: "auto",
    height: "100vh",
    paddingBottom: "27rem",
    maxWidth: "388px",
    width: "100%"
  },
  item: {
    color: CONSTANTS.color.thickBlue,
    fontWeight: "bold" as const,
    padding: "1rem",
  },
  item2: {
    color: CONSTANTS.color.thickBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    padding: "1rem",
  },
  selectedStyle: {
    borderLeft: `6px solid ${CONSTANTS.color.darkBlue}`,
    borderRadius: "5px",
    backgroundColor: "rgba(5,3,122,0.08)",
    boxShadow: "0px 0px 21px -2px rgba(28,24,175,0.07)"
  },
  marginLeftStyle: {
    marginLeft: "1rem"
  },
  borderStyle: {
    borderRight: `6px solid ${CONSTANTS.color.darkBlue}`,
    borderLeft: 0
  },
  rightContent: {
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "-10px 6px 20px rgba(28,24,175,0.07)",
    padding: "2rem 0px 2rem 2rem"
  },
  innerContent: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  privacyContainer: {
    display: "flex",
    flexDirection: "column" as const,
  },
  privacyTitle: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
  },
  titleColor: {
    color: CONSTANTS.color.coolDarkGrey
  },
  alignSelfStyle: {
    alignSelf: "end"
  },
  privacyBody: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginTop: "1rem"
  },
  oneBody: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "40px",
    textAlign: "justify" as const,
    marginTop: "1rem"
  },
  marginTopStyle: {
    marginTop: "2rem"
  },
  tableOfContents: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  paddingTopStyle: {
    paddingTop: "2rem"
  },
  tableContentStyle: {
    paddingTop: "2rem",
    paddingBottom: "1rem"
  },
  tableContents: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "40px",
    textAlign: "justify" as const,
  }
}
const PrivacyPolicyStyles =  withStyles(styles)(PrivacyPolicy);
export default withTranslation()(PrivacyPolicyStyles);
// Customizable Area End