import {
    createTheme,
    responsiveFontSizes
} from '@material-ui/core/styles';

export const CONSTANTS = {
    color: {
      thickBlue: '#1c18af',
      darkBlue: '#1c18af',
      navyBlue: '#353494',
      blueViolet: '#6c5dd3',
      lightBlue: '#E4E3F4', // rgb(228, 227, 244)
      lightBrown: "#34313a",
      lightGreen: "#41cb41",
      forestGreen: "#2b4038",
      lightRed: "#fceef0",
      lightBlack: "#000000D9", // rgba(0,0,0,0.85)
      thickGrey: "#707070",
      lightGrey: "#999999",
      midGrey: "#666666",
      grey: "#626262",
      darkGrey: "#535353",
      beigeGrey: "#cdc7c7",
      charcoalGrey: "#606060",
      coolDarkGrey: "#414042",
      pebbleGrey: "#d8d8d8",
      oliveGrey: "#566560",
      slateGrey: "#4c4c4c",
      silverGrey: "#a3a3a3",
      borderGrey: "#bababa",
      cement: "#ececec",
      lightCement: "#e8e8e8",
      paleCement: "#dedede",
      paleGrey: "#e4e4e4",
      neutralGrey: "#8e8e8e",
      lightBeigeGrey: "#c3c0c0",
      paleBlueGrey: "#c1bbbb",
      mistyBlueGrey: "#f5f5fc",
      airyCloudBlue: "#f6f6fc",
      darkCement: "#909091",
      offWhiteGrey: "#f9f9f9",
      graphiteGrey: "#605f60",
      softBlueGrey: "#f3f3fb",
      crimsonRed: "#ff5454",
      deepRedHue: "#f55e5e",
      deepRed: "#af1818",
      brightRed: "#e02020",
      lavenderBlue: "#e9e9ff",
      periwinkleBlue: "#e1dffe",
      softBlue: "#e5f4ff",
      darkSteelBlue: "#292e34",
      invisibleBlue: "#1c18af0D", // rgba(28,24,175,0.05)
      lightTrasparentBlue: "#1c18af0F", // rgba(28,24,175,0.06)
      subtleGreyTint: "#7A7A7D13", // rgba(122,122,125,0.08)
      paleLavendar: "#ECECFC", // rgb(236 236 252)
      royalBlue: "#4d4aca",
      pastelPink: "#eedee4",
      limeGreen: "#50af18",
      mintGreen: "#f3fbf3",
      brightGreen: "#009f00",
      steelGrey: "#cccccc",
      steelBlueGrey: "#808191",
      goldenApricot: "#ffce72",
      softOrange: "#ffddbc",
      deepOrange: "#ed9134",
      lightPastelPink: "#ffd7e4",
      magentaColor: "#ff90b4",
      paleApricot: "#ffe4b2",
      vividOrange: "#ffaf1a",
      softPurple: "#dbd5ff",
      pearlWhite: "#fffefe",
      ashGrey: "#e5e5e5",
      gray:'#979797',
      platinumGrey: "#f2f2f2",
      concreteGrey: "#a0a0a0",
      veryLightGrey: "#fcfcfc",
      lightSilver: "#ededed",
      silverMist: "#e9e9e9",
      deepIndigo: "#2e1e9e",
      blueGrey: "#8e8e99",
      gunMetalGrey: "#5c5c5d",
      metallicGrey: "#cdcdcd",
      jetBlack: "#19181a",
      richBrown: "#d3935d",
      red: '#eb220a',
      black: '#000000',
      white: '#ffffff',
      pink:'#FFD6E4',
      starDust:"#96948f",
      textLightGrey:"#5c5c5d",
      softGreyGreen: "#d1dec0",
      darkBlack:"#262626",
      blueLignt:"#1c18af0d", //rgba(28, 24, 175, 0.05)
      blackDark:"#151515", //PopupText
      silverWhite:"#9a9a9a",
      greySilver:"#eaeaea",
      pogressGrey:"#E7E7E7",
      pogressGreen:"#18C350",
      borderGreyLight:"rgba(0,0,0,0.1)"
    },
    fontWeight: {
        fontWeight300: 300,
        fontWeight400: 400,
        fontWeight500: 500,
        fontWeight600: 600,
        fontWeight700: 700,
        fontWeight800: 800,
    }
}

const responsiveTheme = createTheme({
    palette: {
        common: {
            black: "#000000",
            white: "#ffffff",
        },
        error: {
            main: "#eb220a"
        },
        text: {
            primary: "#999999",
            secondary: "#707070"
        }
    },
    typography: {
        fontFamily: "Rubik",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontSize: 50,
        },
        h2: {
            fontSize: 40,
        },
        h3: {
            fontSize: 35,
        },
        h4: {
            fontSize: 32,
        },
        h5: {
            fontSize: 30,
        },
        h6: {
            fontSize: 28,
        },
        subtitle1: {
            fontSize: 24,
        },
        subtitle2: {
            fontSize: 22,
        },
        body1: {
            fontSize: 18,
        },
        body2: {
            fontSize: 16
        },
        caption: {
            fontSize: 14
        }
    },
});

export const theme = responsiveFontSizes(responsiveTheme);