import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { ReportsSideBar } from "./ReportsSideBar.web";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { StyledBox } from "../../../blocks/Settings/src/CompanyInformation.web";
import { ReportsData } from "./ReportsData.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ReportsController, {
  Props,
} from "./ReportsController.web";

// Customizable Area Start

// Customizable Area End

export class JobFinancialReport extends ReportsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.report')} active={false} active1={false} active2={0} />
        <ReportsSideBar {...this.props} active={2} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.jobFinancialReportContainer}
            className={classes.jobFinancialReportContainer}
          >
            <Container maxWidth={false}>
              <Typography
                style={webStyle.headerText}
                className={classes.headerText}
                variant="subtitle1"
              >
                {t('reports.jobFinancialReport')}
              </Typography>
              <Typography style={webStyle.descText} className={classes.descText} color="textPrimary">
                {t('reports.reportDesc')}
              </Typography>
              <ReportsData {...this.props} />
            </Container>
          </Box>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  jobFinancialReportContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem !important",
      marginTop: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.25rem !important"
    }
  },
  headerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    }
  },
  descText: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: "25px !important",
      fontSize: "18px !important",
    }
  },
  boxInnerContainer: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "0.5rem !important",
      paddingLeft: "0.5rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem !important",
      paddingLeft: "0rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "2rem !important",
    }
  },
  downloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    },
  },
  mobileDownloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important"
    }
  },
});

const webStyle = {
  jobFinancialReportContainer: {
    marginTop: "8.5rem",
    paddingBottom: "6rem"
  },
  headerText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  descText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    paddingTop: "1rem",
  }
}

const jobFinancialReportStyle = withStyles(styles)(JobFinancialReport);
export default withTranslation()(withSnackbar(jobFinancialReportStyle));
// Customizable Area End