Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";
exports.allCustomers="bx_block_request_management/customers_list"
exports.getAllRequest="/bx_block_request_management/requests"
exports.createRequest="/bx_block_request_management/requests"
exports.getSingleRequestDetails="/bx_block_request_management/requests"
exports.updateRequest="/bx_block_request_management/requests"
exports.deleteRequest="/bx_block_request_management/requests"
exports.createRequest="/bx_block_request_management/requests"
exports.getCustomerSites="bx_block_request_management/sites_list"
exports.searchRequest="bx_block_request_management/request_search"
exports.updateApiMethodtype="PATCH"
exports.deleteApiMethodType="DELETE"
exports.deleteRequest="bx_block_request_management/requests"
exports.getEmployeeApiEndPoint='bx_block_appointment_management/appointments/get_employees'
exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod= "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod= "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod= "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod= "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod= "PUT";


exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter"


exports.getCustomerDetails="bx_block_request_management/show_customer"
exports.storageToken="authToken"
exports.softDeleteType="Delete "
exports.hardDeleteType=""
exports.saveRequestText="Save Request"
exports.cancelText="Cancel"
exports.archeiveText="Archeive"

exports.titleFieldHeadings={
  requestTileText:"Request Title",
  serviceText:"Service Details",
  source:"Request Source",
  appointmentText:"Appointment Details",
  notesText:"Internal Notes & Attachments",
  location:"Location",
}

exports.subTitles={
  requestTileText:"Request Title",
  serviceText:"Please provide relative Service Information",
  source:"Source",
  dateText:"Appointment Date",
  timeText:"Appointment Time",
  AsentricSymbol:"*"

}
exports.errorText={
  titleEmptyText:"Request Title cannot be empty",
  titleCharactersText:"Title contains only characters",
  titleCharacterslengthText:"Request Title cannot be more than 40 characters",
  serviceCharactersText:"Service contains only characters",
  sourceErrorText:"Source cannot be empty",
  others:"Others cannot be empty",
  notesCharacterText:"Internal notes contains only characters", 
  serviceCharacterslengthText:"Service details contains only 200 characters"
}
exports.toasterText={
  created:"Request created",
  updated:"Request updated",
  deleted:"Request deleted",
  hardDeleteText:"Cancelled request deleted permanently"
}
exports.popupBtn={
  quote:"Provide Quote",
  job:"Convert To Job",
  download :"Download As PDF"
}

exports.requestStatus={
  created:"requestCreateStatus",
  deleted:"requestDeleteStatus"
}
exports.emptyDataMessage="No Data Found";

exports.errorsData={
  noData:"No requests found.",
  tokenExpired:"Token has Expired",
  invalidToken:"Invalid token"
}
exports.siteEndPoint="bx_block_location/site_informations"
// Customizable Area End