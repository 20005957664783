//@ts-nocheck
import React from 'react';
// Customizable Area Start
import { Grid, withStyles, Typography, Avatar,Button } from '@material-ui/core';
import { CONSTANTS } from '../../../components/src/theme';
import Select, { components } from 'react-select'
import ClearIcon from '@material-ui/icons/Clear';
import {StyledCheckbox} from './Calendar.web'
import { t } from "i18next";
export const configJSON = require("./config");
// Customizable Area End
// Customizable Area Start
export const selectStyles:any = (theme: any) => ({
    assignInput: {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down("xs")]: {
            width: '75%',
        }
    },
    teamBtn:{
        height: "33px",
        borderRadius: "4px",
        background: "#D8D8D8",
        margin:"4px",
        padding:"6px 5px",
        color:"#151515",
        textTransform: "capitalize",
        minWidth:"61px",
        "&:hover": {
            background: "#D8D8D8"
        },
        "& .MuiButton-endIcon": {
            marginLeft: "-4px",
            marginRight: "7px"
        }
    },
    teamBtn1:{
        height: "33px",
        margin:"4px",
        borderRadius: "4px",
        background: "#D8D8D8",
        color:"#151515",
        padding:"6px 5px",
        textTransform: "capitalize",
        minWidth:"61px",
        "&:hover": {
            background: "#D8D8D8"
        },
    },
    clearIcon:{
        width:"28px",
        height:"33px",
        background:"#CDCDCD",
        color:"#151515",
        padding:"3px",
        borderRadius: "4px 0px",
    },
    paddingLeft_9: {
        paddingLeft: '9px' 
    },
    paddingRight_9:{
        paddingRight:"9px"
    },
    fontWeight_500: {
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
    },
    lineHeight:{
        lineHeight:"initial"
    },
    font_14: {
        fontSize: '14px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '12px !important'
        },
    },
});
interface Props {
    navigation?: any;
    id?: string;
    history?: any
    getEmployeeData:any
    
}
export const customStyle = () => {
    return {
        placeholder: (styles) => ({ ...styles, width: "100%",fontSize:"18px" }),
        menuList: (base) => ({ ...base, height: '150px' }),
        control: (styles) => ({ ...styles, borderRadius: "8px", minHeight: '52px' }),
        indicatorsContainer: (styles) => ({ ...styles, display: "none" }),
        indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    }
}
   // Customizable Area End
export class EmpSelectInput extends React.Component {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    InputOption = ({ isFocused, isSelected, children, innerProps, selectedEmp,...rest }) => {
        const onMouseDown = () => !this.props.isActive;
        const onMouseUp = () => this.props.isActive;
        const onMouseLeave = () => this.props.isActive;
        const style = {
            alignItems: "center",
            backgroundColor: 'transparent',
            color: 'inherit',
            display: "flex "
        };
        const props = {...innerProps,onMouseDown,onMouseUp,onMouseLeave,style};
        const checkIsCheckedOrNot=()=>{
            if(selectedEmp.length>0){
                return typeof selectedEmp[0]==='number'?selectedEmp.includes(parseInt(rest.value)):selectedEmp.includes(rest.value)
            }else{
                return false
            }
        }

        return (
            <components.Option
                {...rest}
                isFocused={isFocused}
                isSelected={checkIsCheckedOrNot()}
                innerProps={props}
            >
                <StyledCheckbox checked={checkIsCheckedOrNot()} />
                {children}
            </components.Option>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const buttonClass = this.props.selectLanguage === 'ar' ? classes.teamBtn : classes.teamBtn1
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Select
                components={{
                     Option:({ isFocused, isSelected, children, innerProps, ...rest })=> 
                     this.InputOption({ isFocused, isSelected, children, innerProps,selectedEmp:this.props.values.employee_id, ...rest })}}
                name='employee_id'
                isMulti={this.props.type==='appointment'}
                closeMenuOnSelect={this.props.type!=='appointment'}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                styles={customStyle(this.props.selectLanguage)}
                theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: CONSTANTS.color.darkBlue } })}
                placeholder={t('Calendar.ErrorMessage.NewTeam')}
                isDisabled={this.props.isEmployee}
                options={this.props.getEmployeeData?.map((item) => {
                    return {
                        value: item.id,
                        label:
                            <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>
                                <Grid item className={this.props.selectLanguage==='ar'&& classes.paddingRight_9}>
                                    <Avatar src={item.attributes.employee_image && item.attributes.employee_image.url || ''} />
                                </Grid>
                                <Grid item className={this.props.selectLanguage==='ar'? classes.paddingRight_9 :classes.paddingLeft_9}>
                                    <Typography variant='body2' className={`${classes.fontWeight_500} ${classes.assignInput}`}>{item.attributes.full_name}</Typography>
                                    <Typography className={`${classes.font_14} ${classes.assignInput}`}>
                                        {item.attributes.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                    }
                })}
                onChange={this.props.onChange}
            />
                <Grid container style={{marginTop:"10px"}}>
                    <Grid item xs={12} sm={5}>
                       <Typography variant='body1'>{t('Calendar.ErrorMessage.NewTeam')}:</Typography>
                    </Grid>
                    {this.props.values?.employee_id && this.props.type==='appointment' && this.props.isEmployee === false ?
                        <Grid item xs={12} sm={7}>
                            {this.props.values?.employee_id.map((employeeId) => {
                                return <>
                                    <Button className={buttonClass}
                                        endIcon={
                                            <ClearIcon
                                                className={classes.clearIcon}
                                                onClick={()=>this.props.onClick(employeeId)}/>}>
                                        <Typography className={classes.lineHeight} variant='body2'>
                                            {this.props.getEmployeeData.find((item) =>
                                                item.id == employeeId)?.attributes.full_name}
                                        </Typography>
                                    </Button>
                                </>
                            })}
                        </Grid> : ""
                    }
                    {this.props.isEmployee === true && this.props.type === 'appointment' && 
                        <Grid item xs={12} sm={7}>
                            <Button
                                disabled={this.props.isEmployee === true}
                                className={buttonClass}
                                endIcon={<ClearIcon className={classes.clearIcon} />}
                            >
                                <Typography
                                    className={classes.lineHeight}
                                    variant='body2'>
                                    {this.props.getEmployeeData.find((item) =>
                                        item.id == this.props.values.employee_id)?.attributes.full_name}
                                </Typography>
                            </Button>
                        </Grid>
                    }
                    {this.props.values?.employee_id && this.props.type==="task" ?
                        <Grid item xs={12} sm={7}>
                            <Button className={buttonClass}
                                disabled={this.props.isEmployee===true}
                                endIcon={<ClearIcon className={classes.clearIcon}
                                onClick={()=>this.props.onClick(this.props.values?.employee_id)}
                                />}
                            >
                                <Typography className={classes.lineHeight}
                                    variant='body2'>
                                    {this.props.getEmployeeData.find((item) =>
                                        item.id == this.props.values.employee_id)?.attributes.full_name}
                                </Typography>
                            </Button>
                        </Grid> :""
                    }
                </Grid>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export default withStyles(selectStyles)(EmpSelectInput);
// Customizable Area End
