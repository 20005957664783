
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { t } from "i18next";

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    openToastHandler: any;
    t:(key:string)=>string
    // Customizable Area End
}

interface S {
    // Customizable Area Start

    otpcode: any
    isErrorotpData: any
    otpErrorStatus:boolean
    otpLoader:boolean
    comingFrom: any
    email: any
    simpleEmail:any,
    timer:number,
    isResendDisabled:boolean,
    resendEmail:any,
    otpCount:any,
    maxOTPRequests:number
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class OtpPasswordControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {


    // Customizable Area Start

    getForgotOtpApiCallId: string = "";
    getResendEmailApiCallId: string = "";
    timerInterval: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [

            getName(MessageEnum.RestAPIResponceMessage)

        ];

        this.state = {
            // Customizable Area Start
            otpcode: "",
            isErrorotpData: {},
            otpErrorStatus:false,
            otpLoader:false,
            comingFrom: "",
            email: "",
            simpleEmail:"",
            timer:60,
            isResendDisabled: false,
            resendEmail:"",
            otpCount:0,
            maxOTPRequests:5
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallID === this.getForgotOtpApiCallId) {
                this.checkResponseForgotOtp(responseJson)
            } else if (apiRequestCallID === this.getResendEmailApiCallId) {
                this.checkResponseResendEmail(responseJson)
            }
            // Customizable Area End
        }
    }
    // Customizable Area Start
    async componentDidMount() {
         // Start the timer when the component mounts
         this.startTimer();
        const comingFrom = localStorage.getItem("comingFrom");
        let count: any = localStorage.getItem("otpCount");
        const email = localStorage.getItem("email");
        const resendEmail = localStorage.getItem("Resend email");
        const convertedEmail = this.replaceStartWithAsterisks(email || "")
        this.setState({otpCount:count, comingFrom, email: convertedEmail  ,simpleEmail:email,resendEmail})
    }
    async componentWillUnmount() {
    // Clear the timer interval when the component unmounts
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  startTimer = () => {
    this.timerInterval = setInterval(this.startTimerCallback, 1000); // Update the timer every second
  };

  startTimerCallback = ()=>{
    if (this.state.timer > 0) {
        this.setState((prevState) => ({
          timer: prevState.timer - 1,
        }));
      } else {
        // Timer reached 0, stop the timer and enable the "Resend OTP" button
        clearInterval(this.timerInterval);
        this.setState({
          isResendDisabled: true,
        });
      }
  }
    replaceStartWithAsterisks = (inputString: any) => {
        // Check if the input string is at least four characters long
        if (inputString.length >= 4) {
            const asterisks = '*'.repeat(4); // Create a string of 4 asterisks
            const replacedString = asterisks + inputString.slice(4); // Replace the first four characters with asterisks
            return replacedString;
        } else {
            // If the input string is less than four characters, return it unchanged
            return inputString;
        }
    }
    checkResponseResendEmail=(responseJson:any)=>{

        if (responseJson && !responseJson?.errors) {      
                this.props.openToastHandler(responseJson?.message, "success")

            localStorage.removeItem("OtpToken")
            localStorage.setItem("OtpToken", responseJson?.meta?.token || responseJson?.token)
        } else {
            this.props.openToastHandler(responseJson.errors[0].otp, "error")
        }
    }
   
    saveLoggedInUserData(responseJson: any) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );
            msg.addData(
                getName(MessageEnum.SessionResponseToken),
                responseJson.meta.token
            );
            this.send(msg);
        }
    }
    handleNavigate = (path: any) => {
        this.props.navigation.navigate(path)
    }
    checkResponseLogin = (responseJson: any) => {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            this.setState({
                otpLoader: true
            })
            this.props.openToastHandler(this.props.t('OtpScreen.loginSuccess'), "success",)
            this.saveLoggedInUserData(responseJson);
            localStorage.setItem("account_id", responseJson.data.id);
            localStorage.setItem("isEmployee", JSON.stringify(false))
            localStorage.removeItem("profileDataFetched");
            localStorage.removeItem("otpCount");
            localStorage.removeItem("Resend email")
            localStorage.removeItem("email")
            localStorage.removeItem("comingFrom");
            localStorage.removeItem("OtpToken")
            setTimeout(this.handleNavigate.bind(this, "Dashboard"), 500)
        } else {
            //Check Error Response
            const { otp, failed_login ,pin} = responseJson.errors[0]
            this.props.openToastHandler(otp || failed_login || pin, "error")
        }

    }
    checkResponseForgotOtpCallback = (responseJson: any) => {
        if (responseJson && !responseJson?.errors) {
            this.setState({
                otpLoader: true
            })
            this.props.openToastHandler("OTP  validation sucess", "success")
            localStorage.removeItem("email")
            localStorage.removeItem("comingFrom")
            localStorage.removeItem("Resend email")
            localStorage.removeItem("otpCount")
            setTimeout(this.handleNavigate.bind(this, "ResetPassword"), 2000);
        } else {
            //Check Error Response
            if (responseJson.errors) {
                const { otp ,pin} = responseJson.errors[0]
                this.props.openToastHandler(otp || pin, "error")
                
            }
        }
    }
    checkResponseForgotOtp = (responseJson: any) => {
        const comingFrom = this.state.comingFrom
        if (comingFrom === "Login" || comingFrom === "Signup") {
            this.checkResponseLogin(responseJson)
        } else {
            this.checkResponseForgotOtpCallback(responseJson)
        }

    }
    handleChange = (code: any) => {
        this.setState({
            otpcode: code,
        })
    };

    validateForm = (values: any) => {
        const errors: any = {};
        if (values.length !== 6) {
            errors.otpcode = this.props.t('OtpScreen.length');
        }
        return errors;
    };

    handleSubmitotp = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        this.setState({ isErrorotpData: this.validateForm(this.state.otpcode), otpErrorStatus: false },
            () => {
                if (Object.keys(this.state.isErrorotpData).length === 0) {
                    this.otpEmail();
                }
            }
        );
    }
    handleSendOTP = () => {
        if(this.state.isResendDisabled){
           
            this.setState({
                otpcode: "",
                otpErrorStatus: false
            })
            this.handleSendOTPCallback(this.state.otpCount)
        }
       
    };

    handleSendOTPCallback = (count: any) => {
        if (JSON.parse(count) < 5) {
            // Send OTP request to the backend API
            // Increment the OTP request count
            this.resendOtp(JSON.parse(count), this.state.comingFrom,true)
            this.setState({
                isResendDisabled: false,
                timer: 60, // Reset the timer
              });
          
            this.startTimer();
        } else {
            // Display an error message or disable the "Send OTP" button
            this.setState({
                isResendDisabled: false,
                timer: 300, // Reset the timer
              });
          
            this.startTimer();
            setTimeout(this.initializeOtpCount, 300000)
            this.resendOtp(JSON.parse(count), this.state.comingFrom,false)
        }
    }


      initializeOtpCount = ()=>{
        localStorage.setItem("otpCount",JSON.stringify(0));
        this.setState({otpCount:0})
      }
      
      getResendOtpData = (comingFrom: any, email: any, role: any) => {
       
        return comingFrom === "Login" || comingFrom === "Signup" ?
            {
                email: this.state.simpleEmail,
            } : {
                data:
                {
                    "type": "email_account",
                    "role": role,
                    "attributes":
                    {
                        "email": email
                    }
                },
                
            }
    }
    getResendOtpEndpoint = (comingFrom: any) => {
        return comingFrom === "Login" || comingFrom === "Signup" ?
            {
                 reqUrl: configJSON.resendOtpEndPoint
            } : {
               
                 reqUrl: configJSON.forgotPassword
            }
    }
    
    resendOtp = async (count: any, comingFrom: any,incrementCount:boolean) => {
        if (incrementCount) {
            localStorage.setItem("otpCount", count + 1)
            this.setState({ otpCount: count + 1 })
        }

        const headers: any = {
            "content-Type": configJSON.validationApiContentType
        }
        const lang=await getStorageData('lang')
        const email = localStorage.getItem("Resend email")
        const role = localStorage.getItem("role");
        const getData = this.getResendOtpData(comingFrom, email, role);
        const getReqUrl = this.getResendOtpEndpoint(comingFrom)
     
        const data = getData
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getResendEmailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${getReqUrl.reqUrl}?lang=${lang}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    getConfirmationUrl = (comingFrom: any) => {
        return comingFrom === "Login" || comingFrom === "Signup" ? configJSON.confirmEmailotp : configJSON.confirmotp
    };
    getPayload = (comingFrom: any, token: any) => {
        return comingFrom === "Login" || comingFrom === "Signup"? {
            "token": token,
            "otp_code": this.state.otpcode
        } : {
            data: {
                "token": token,
                "otp_code": this.state.otpcode
            }
        }
    }
    otpEmail = async() => {
        const headers: any = {
            "content-Type": configJSON.validationApiContentType
        }
        const lang= await getStorageData('lang')
        const token = localStorage.getItem("OtpToken")
        const data = this.getPayload(this.state.comingFrom, token)
        const url = this.getConfirmationUrl(this.state.comingFrom);
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getForgotOtpApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${url}?lang=${lang}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    // Customizable Area End
}
