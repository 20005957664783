//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    Typography,
    Container,
    Grid, Hidden
} from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles"
import { withRouter } from "react-router";
import { mainLogo, firstSideImg, sidePage2, sidePage1, sidePage3, mobileArrow } from "./assets";
import ForgotPasswordControllerWeb, { Props } from "./ForgotPassword.web.controller";
import { withSnackbar } from "../../../components/src/toastweb";
import { withTranslation } from "react-i18next";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { ContainedButton, CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
import { CONSTANTS } from "../../../components/src/theme";
import { mobileLogo } from "../../LanguageSupport/src/assets";
import { goBack } from "../../../components/src/CommonFunction";
// Customizable Area End

export class PasswordForgot extends ForgotPasswordControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        //@ts-ignore
        const { t,classes } = this.props
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <form onSubmit={this.handleSubmitEmail}>
                    <Grid container direction="row" alignItems="center" style={{ height: '100%' }}>
                         <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.layoutImage} />
                        </Hidden>
                         <Hidden only={'xs'}>
                            <Grid item xs={12} sm={1} lg={2} xl={2} className={classes.secondLogo}>
                                <img style={{ width: "80px" }} src={firstSideImg} alt="firstSideImg" />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={6}>
                            <Grid container direction="row" justifyContent="center" spacing={3}  className={classes.forgotPasswordLayout} >

                                 <Hidden only={['sm', 'md', 'lg', 'xl']}> 
                                    <Grid item xs={12}>
                                        <img className={classes.passwordBackIcon} src={mobileArrow}
                                        data-test-id="back_navigation"
                                        onClick={() => goBack(this.props.navigation)}
                                            width={12} height={24} alt="mobile arrow" />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12}>
                                    <img src={mainLogo} className={classes.logoImg} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className={classes.userTitle}>{t('forgotPassword.heading')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textPrimary" className={classes.subtext}>{t('forgotPassword.subHeading')}
                                        <Typography display="inline" variant="caption" color="error">*</Typography>
                                    </Typography>
                                    <CustomizedTextField
                                        variant="outlined"
                                        fullWidth
                                        placeholder={t('forgotPassword.placeHolder')}
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        error={Boolean(this.state.isErrorEmailData.email)}
                                        helperText={this.state.isErrorEmailData.email}
                                        data-test-id='emailAddress'
                                    />
                                </Grid>
                                <Hidden only={'xs'}>

                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <ContainedButton style={webStyle.forgotBtn} className={classes.sendOtpBtn} data-test-id="forgotBtn" type="submit">{t('forgotPassword.btn')}</ContainedButton>
                                    </Grid>
                                </Hidden>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={1} md={2} lg={2} />
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                <Grid item xs={12}  className={classes.mobileButton}>
                                    <ContainedButton className={classes.sendOtpBtn} data-test-id="mobileforgotBtn" type="submit">{t('forgotPassword.btn')}</ContainedButton>
                                </Grid>
                                <Grid item xs={12}  className={classes.secondLogo} >
                                    <img className={classes.firstSideImg} src={firstSideImg} alt="firstSideImg" />
                                </Grid>
                        </Hidden>

                        <Hidden only={'xs'}>
                            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.layoutImage} />
                        </Hidden>
                    </Grid>
                </form>
                <LoadingBackdrop
                    loading={this.state.forgotPasswordLoader}
                />

            </>
        // Customizable Area End
        );
    }
}

// Customizable Area Start
//@ts-ignore
const PasswordForgotRouter = withRouter(PasswordForgot)
const PasswordToster = withSnackbar(PasswordForgotRouter)
export const PasswordForgotStyles = (theme: Theme) => ({
    layoutImage: {
        backgroundImage: `url(${sidePage1})`,
        height: "100vh",
        backgroundSize: "100% 100%",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${mobileLogo})`,
            maxHeight: "86px",
            // height: "100%"
        },
        [theme.breakpoints.up("sm")]: {
            backgroundImage: `url(${sidePage1})`,
        },
        [theme.breakpoints.between("sm", 'md')]: {
            backgroundImage: `url(${sidePage2})`,
        },
        [theme.breakpoints.between('md', "lg")]: {
            backgroundImage: `url(${sidePage3})`,
        },
    },
    userTitle: {
        color: theme.palette.common.black,
        fontWeight: CONSTANTS.fontWeight.fontWeight600,
        paddingTop: "1rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: 30
        },
    },
    logoImg: {
        width: "150px",
        [theme.breakpoints.between("xs", "sm")]: {
            width: "100px",
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "120px",
        },
    },
    subtext: {
        fontWeight: theme.typography.fontWeightRegular,
        paddingTop: "10px",
        paddingBottom: "1rem"
    },
    sendOtpBtn: {
        width: "fit-content",
        height: '3rem',
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            // marginTop: '300px'
        },
    },
    secondLogo: {
        display: 'flex',
        alignItems: 'end',
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            position: 'absolute',
            bottom: 0,
            zIndex:-99
        },
    },
    passwordBackIcon: {
        cursor: 'pointer'
    },
    firstSideImg: {
        width: "80px",
        [theme.breakpoints.down("xs")]: {
            width: "47px",
            height: '47px'
        },
    },
    forgotPasswordLayout: {
        padding: 20,
        marginTop: 20
    },
    mobileButton:{
        [theme.breakpoints.down("xs")]: {
            position: 'absolute',
            bottom: '10%',
            width:'100%',
            padding:'0 20px'
        },
    },
})
const webStyle = {
    forgotBtn: {
        padding: "10px 35px",
        height: "100%"
    }
}
const PasswordForgotComponent = withStyles(PasswordForgotStyles)(PasswordToster)
export default withTranslation()(PasswordForgotComponent);

// Customizable Area End