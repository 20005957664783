import React from 'react';
// Customizable Area Start


import {
    Dialog, DialogTitle, DialogContent, withStyles, Button, Typography, Grid, DialogActions, Divider, Theme
    , Box,InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { StyledTextFieldRequest } from '../../RequestManagement/src/Utility.web';
import EmailModalController, { Props } from './EmailModalController.web';
import { StyledEmailTextField } from './JobsUtility.web';
import withCommonStyles from '../../../components/src/withCommonStyles.web';
import ClearIcon from '@material-ui/icons/Clear';
import { withRouter } from "react-router-dom"
import { withSnackbar } from '../../../components/src/toastweb';
import { downArrowIcon } from './assets';
import { CONSTANTS } from "../../../components/src/theme"

export const EmailStyles = (theme: Theme) => ({
    popupCancelBtn: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#000000',
        width: "15px",
        height: "16px",
    },
    emailTitle: {
        width: "100%",
        color: "#34313a",
        fontFamily: "Rubik",
        fontSize: "30px",
        fontWeight: 500
    },
    sendEmailBtn: {
        borderRadius: "8px",
        background: CONSTANTS.color.darkBlue,
        width: "166px",
        height: "56px",
        color: CONSTANTS.color.white,
        textTransform: "capitalize",
        transition: 'background-color 0.3s, color 0.3s',
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            height:'48px'
        },
        '&:hover': {
            backgroundColor: CONSTANTS.color.blueViolet,
            border:"none",
            color: theme.palette.common.white,
            cursor: "pointer",
        },
    },
    flexDirection:{
        [theme.breakpoints.down("xs")]: {
            flexDirection:'column-reverse'
         },
    },
    emailCancelBtn: {
        borderRadius: "8px",
        background: CONSTANTS.color.white,
        width: "166px",
        height: "56px",
        textTransform: "capitalize",
        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
        '&:hover': {
          backgroundColor: '#999999',
          border:"none",
          color: theme.palette.common.white,
          cursor: "pointer"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            height:'48px'
        },
    },
    adornment: {
        padding: "0.5rem",
        background: "rgba(153,153,153,0.1)",
        marginLeft: 0,
        borderRadius: "8px 8px 0px 0px",
        width: "120px",
        height: "56px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    subjectInputArea: {
        "& .MuiInputBase-root": {
            borderRadius: "8px 8px 0px 0px",
            fontFamily: "Rubik",
        }
    },
    textareaInput: {
        "& .MuiInputBase-root": {
            borderRadius: " 0px 0px 8px 8px",
            fontFamily: "Rubik",
        }
    },
    fontW_400:{
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
    },
    mt_1:{
        [theme.breakpoints.down("xs")]: {
            marginTop:'1rem'
        },
    },
    mt_12:{
        marginTop:'12px'
    },
    emailFilesLayout: {
        width: "100%",
        height: "100%",
        minHeight: "129px",
        borderRadius: "8px",
        border: "1px solid rgba(0,0,0,0.1)",
        paddingRight:'10px',
        paddingLeft:'12px'
    },
    pr_10:{
        paddingRight:'10px',
        [theme.breakpoints.down("xs")]: {
            paddingRight:'0px',
        },
    },
    pl_10:{
        paddingLeft:'10px',
        [theme.breakpoints.down("xs")]: {
            paddingLeft:'0px',
        },
    },
    emailUploadButton: {
        border: "2px dashed #1c18af",
        background: "rgba(28, 24, 175, 0.05)",
        color: "#1c18af",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 400,
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: "12px",
        width:'100%',
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        "&:hover": {
            background: "rgba(28, 24, 175, 0.05)",
        }
    },
    emailUploadDragFilesText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "#4c4c4c"
    },
    emailUploadGrid: {
        display: "flex",
        justifyContent: "center"
    },
    uploadFileBox: {
        width: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "flex-end"
    },
    imageBoxEmail: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0 2px 0 9px",
    },
    imageBoxV1Email: {
        marginRight: "9px",
    },
    imageUploadSize: {
        width: "100px",
        height: "100px",
    },
    fileNameTextBox: {
        display: "flex",
        flexDirection: "column",
    },
    fileNameTypo: {
        color: "#34313a",
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: 400,
        marginBottom: 0,
    }
});

// Customizable Area End

export class EmailModal extends EmailModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showUploadedFilesCreate = (file: any) => {
        const { classes }: any = this.props
        return (
            <Box key={file?.name}>
                <Box className={classes.uploadFileBox}>
                    <ClearIcon
                        onClick={this.handleEmailFileRemove.bind(this, file)}
                    />
                </Box>
                {/* image section */}
                <Box className={classes.imageBoxEmail}>
                    <Box className={classes.imageBoxV1Email}>
                        <img
                            className={classes.imageUploadSize}
                            src={
                                file?.name && this.state.extensionIcons[file.name.split(".")[1]]
                            }
                            alt="upload"
                        />
                    </Box>
                    {/* text section */}
                    <Box className={classes.fileNameTextBox}>
                        <Box>
                            <Typography
                                gutterBottom
                                component="div"
                                className={classes.fileNameTypo}
                            >
                                {file?.name && file.name.length > 10
                                    ? file.name
                                        .split(".")[0]
                                        .concat(".")
                                        .concat(file.name.split(".")[1])
                                        .substring(0, 10) + "..."
                                    : file?.name}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                gutterBottom
                                className={{ ...classes.fileNameTypo, fontSize: "14px" }}
                            >
                                {Math.floor(file?.size / 1024)}kb
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes,t } = this.props
        return (
            <Dialog
                maxWidth="lg"
                open={this.props.open}
                onClose={this.handleEmailModalClose}
                PaperProps={{
                    style: {
                        width: "880px",
                        backgroundColor: "#ffffff",
                        boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
                        borderRadius: 10,
                    },
                }}>
                <DialogTitle>
                     <Grid item xs={11}>
                    <Typography variant='h6'className={classes.emailTitle}>{t ? t('request.sourceMenu.email'):"Email"} {this.props.type}#{this.state.emailJobDetails?.id} {t ? t('emailModal.to1'):"to"} {this.props.type === "Quote" ? this.state.emailJobDetails?.attributes?.custmer_name : this.state.emailJobDetails?.attributes?.custmoer_name}</Typography>
                    </Grid> 
                    <Grid item xs={1}>
                    <IconButton aria-label="close" className={classes.popupCancelBtn} data-test-id="close_dailog" onClick={this.handleEmailModalClose}>
                        <CloseIcon />
                    </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item xs={12} sm={7} className={classes.pr_10}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={12}>
                                        <StyledEmailTextField
                                            disabled={false}
                                            onChange={this.handleEmailChange}
                                            value={this.state.emailForm.receptient}
                                            placeholder={t? t('BoAuth.emailPlaceHolder'):"Enter the email address"}
                                            InputProps={{
                                                startAdornment: <div className={classes.adornment}>{t ? t('emailModal.to'):"To"}</div>
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            name="receptient"
                                            data-test-id="email_input"
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component={'div'} className={`${classes.flex_end} ${classes.errorText}`}>{this.state.isErrorFormData.receptient}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StyledEmailTextField
                                            InputProps={{
                                                startAdornment: <div className={classes.adornment}>{t ? t('emailModal.subject'):"Subject"}</div>
                                            }}
                                            name="subject"
                                            value={this.state.emailForm.subject}
                                            variant="outlined"
                                            fullWidth
                                            className={classes.subjectInputArea}
                                            onChange={this.handleEmailChange}
                                            data-test-id="handle_change"
                                        />
                                        <StyledTextFieldRequest
                                            value={this.state.emailForm.messageDescription}
                                            name="messageDescription"
                                            className={classes.textareaInput}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            minRows={5}
                                            maxRows={5}
                                            onChange={this.handleEmailChange}

                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={5} className={classes.pl_10}>
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                    <Divider orientation="vertical"/>
                                    </Grid>
                                    <Grid item style={{ width: '-webkit-calc(100% - 20px)'}}>
                                    <Grid item xs={12}>
                                        <div className={classes.emailFilesLayout}>
                                            <Grid container direction="row" alignItems='center' style={{ marginTop: "15px" }} spacing={3}>
                                                <Grid item xs={12} md={6} className={classes.emailUploadGrid}>
                                                    <Typography component={'div'} className={classes.emailUploadDragFilesText}>{t? t('emailModal.dragFileText'):"Drag your files here or"}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6} className={classes.emailUploadGrid}>
                                                    <Button
                                                        variant="outlined"
                                                        component="label"
                                                        disableRipple
                                                        disableElevation
                                                        disableFocusRipple
                                                        className={classes.emailUploadButton}
                                                        onDragOver={this.handleOnEmailDragOver}
                                                        onDrop={this.handleOnEmailDrop}
                                                        data-test-id="dragFiles"
                                                       
                                                    >
                                                        <input
                                                            hidden
                                                            multiple
                                                            type="file"
                                                            data-test-id="upload_files"
                                                            onChange={this.uploadFiles}
                                                        /> {t ? t('emailModal.uploadFile'):"Upload files"}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {this.state.completed &&
                                                        this.state.attachments.length > 0 &&
                                                        this.state.attachments.map((file: any) => {
                                                            return this.showUploadedFilesCreate(file);
                                                        })}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className={classes.mt_12}>
                                        <StyledTextFieldRequest
                                            variant="outlined"
                                            fullWidth
                                            className={classes.attachment}
                                            value={`${t? t('emailModal.attachments'):"Attachments"} (${this.state.attachments.length})`}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                     <img src={downArrowIcon} width={"20px"} height={"10px"}/>
                                                    </InputAdornment>
                                                  ),
                                            }}
                                            
                                        />
                                    </Grid>
                                    </Grid>
                                </Grid>
                          
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '16px 24px' }}>
                        <Grid container direction='row' className={classes.flexDirection}>
                            <Grid item xs={12} sm={6} className={classes.mt_1}>
                                <Button className={classes.emailCancelBtn} variant='outlined' onClick={this.handleEmailModalClose}>
                                   <Typography variant='subtitle1' className={classes.fontW_400}>{t ? t('invoice.button.cancel'):"Cancel"}</Typography> 
                                </Button>
                            </Grid>
                            <Grid item container xs={12} sm={6} justifyContent='flex-end'>
                                <Button variant='outlined' data-test-id="validate_email" onClick={this.sendEmailBtn} className={classes.sendEmailBtn}>
                                    <Typography variant='subtitle2' style={{ lineHeight: "initial" }} className={classes.fontW_400}>{t? t('emailModal.button.sendEmail'):"Send Email"}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                </DialogActions>
            </Dialog>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const EmailCommonStyles = withCommonStyles(EmailModal)
//@ts-ignore
const EmailWithRouter = withRouter(EmailCommonStyles)
const EmailWithToaster = withSnackbar(EmailWithRouter)
//@ts-ignore
export default withStyles(EmailStyles)(EmailWithToaster);
// Customizable Area End
