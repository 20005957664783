import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start 
import React from "react";   
import { checkLoggedInUserDashboard } from "../../dashboard/src/utility.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { convertToArabicNumerals } from "../../CustomisableUserProfiles/src/utility.web";
enum CSSClass {
  dashMoreIcon = "dashMoreIcon",
  dashMoreIconxs = "dashMoreIconxs",
  dashReadLess = "dashReadLess",
  dashCheckIcon = "dashCheckIcon",
  dashDeleteIcon = "dashDeleteIcon",
  boxContainer = "boxContainer",
  dialogBoxIcon = "dialogBoxIcon",
  imgStyles = "imgStyles",
  popoverItems = "popoverItems",
  smallPopup = "smallPopup",
  createPopup = "createPopup",
  notifyHeadingBox = "notifyHeadingBox",
  notifyheading = "notifyheading",
  markRead = "markRead",
  deleteText = "deleteText",
  MoreIcon = "MoreIcon",
  MoreIconxs = "MoreIconxs",
  ReadLess = "ReadLess",
  CheckIcon = "CheckIcon",
  DeleteIcon = "DeleteIcon",
  MarkReadColor = "MarkReadColor",
  DeleteColor = "DeleteColor",
  Hr = "Hr",
  todayDate = "todayDate",
  notifyBody = "notifyBody",
  notifyTitleWidth = "notifyTitleWidth",
  noNotifyText = "noNotifyText",
  notifyTitle = "notifyTitle",
  hideLarge = "hideLarge",
  hideSmall = "hideSmall",
  notifyContainer = "notifyContainer",
  badgeIcon = "badgeIcon",
  notifyImageParent = "notifyImageParent",
  notifyBodyContainer = "notifyBodyContainer",
  notifyBodyParent = "notifyBodyParent",
  dashhideLarge = "dashhideLarge",
  dashhideSmall = "dashhideSmall",
  dashdialogBoxIcon = "dashdialogBoxIcon",
  dashnotifyHeadingBox = "dashnotifyHeadingBox",
  dashcreatePopup = "dashcreatePopup",
  dashimgStyles = "dashimgStyles",
  dashnotifyheading = "dashnotifyheading",
  dashmarkRead = "dashmarkRead",  
  dashMarkReadColor = "dashMarkReadColor",
  dashDeleteColor = "dashDeleteColor",
  dashHr = "dashHr",
  dashtodayDate = "dashtodayDate",
  dashnotifyBody = "dashnotifyBody",
  dashnotifyTitleWidth = "dashnotifyTitleWidth",
  dashnoNotifyText = "dashnoNotifyText",
  dashdeleteText = "dashdeleteText",
  dashnotifyTitle = "dashnotifyTitle",
  dashnotifyContainer = "dashnotifyContainer",
  dashnotifyImageParent = "dashnotifyImageParent",
  dashbadgeIcon = "dashbadgeIcon",
  dashnotifyBodyContainer = "dashnotifyBodyContainer",
  dashnotifyBodyParent = "dashnotifyBodyParent",
  dashpopoverItems = "dashpopoverItems",
  dashsmallPopup = "dashsmallPopup",
}
export interface ComponentClasses {
  [key: string]: CSSClass;
}
export interface NotifyDataItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_at: string;
    updated_at: string;
    account_detail: {
      name: string;
      profile_image: string;
    };
    is_read:boolean;
    notificationDetails: {
      heading: string | null;
      title: string;
      type: string;
      id: number;
      site_id:string
    };
  };
}

export interface GroupedItems {
  [date: string]: NotifyDataItem[];
}

interface NotificationResponse {
  data: NotifyDataItem[];
  meta: {
    notification_count: number;
    message: string;
  };
  errors:[]
}
interface ErrorResponse {
  errors: {
    token?: string;
    message?: string;
  }[];
}

interface RequestData {
  headers: { [key: string]: string };
  bodyFormData: FormData;
  error?: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler?:any
  history: any
  sliceString:number
  diaLogBox:boolean
  infiniteScroll:boolean
  per_page:number
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | undefined;
  notifyData:NotifyDataItem[];
  loading:boolean;
  isExpanded:boolean;
  expandedTitles:number[];
  anchorEl: Element | null;
  anchorId: string;
  notifyIds:number[];
  markAsReadId:number;
  areSelected:boolean;
  per_page: number ;
  hasMore:boolean,
  is_read:boolean,
  page:number,
  notification_count:number,
  groupedItems:GroupedItems
  offSet:number,
  markStatus:string,
  lang:string,
  confirmationModal:{
    open:boolean,
    title:string,
    content:string,
    cancel:string,
    leave:string,
    handleLeave:Function
  },

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllNotificationsApiCallId: string = "";
  updateAllNotificationsApiCallId: string = "";
  deleteAllNotificationsApiCallId: string = "";
  throttledHandleScroll: (...args: any[]) => void;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
   
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading:false,
      notifyData:[],
      isExpanded:false,
      expandedTitles:[],
      anchorEl:null,
      anchorId:"",
      notifyIds:[],
      markAsReadId:0 ,
      areSelected:false,
      per_page:4,
      hasMore:false,
      is_read:false,
      page:1,
      notification_count:0,
      groupedItems:{},
      offSet: 0,
      markStatus: "",
      lang: "en",
      confirmationModal: {
        open: false,
        title: "",
        content: "",
        cancel: "",
        leave: "",
        handleLeave: ()=>{}
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.throttledHandleScroll = this.throttle(this.handleScroll, 500);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getToken();
    const per_page = this.props.per_page
    this.setState({loading:true,per_page});
    const lang = await getStorageData("lang")
    this.setState({ lang });
    this.setState({per_page},()=>this.getAllNotifications())
    window.addEventListener("scroll", this.throttledHandleScroll);
    // Customizable Area End
  }

 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getAllNotificationsApiCallId) {
        this.getNotificationResponse(responseJson)
      }else if(apiRequestCallId === this.updateAllNotificationsApiCallId) {
        this.updateNotificationResponse(responseJson)
      }else if(apiRequestCallId === this.deleteAllNotificationsApiCallId) {
        this.deleteNotificationResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll);
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  //api responses 
  throttle = (func: { (): void; apply?: any; }, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout> | null;
    return (...args: any) => {
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          func.apply(this, args);
          timeoutId = null;
        }, delay);
      }
    };
  };


  handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const currentHeight = window.scrollY + window.innerHeight;
    this.setState({ offSet: currentHeight })
    if (currentHeight == scrollHeight) {
      if (this.props.infiniteScroll && this.state.notification_count > this.state.notifyData.length) this.getAllNotifications();
    }
  };
  updateGroupedItems = ()=>{
    const groupedItems = this.state.notifyData?.reduce((groups: GroupedItems, item: NotifyDataItem) => {
      const createdAt = new Date(item.attributes?.created_at).toLocaleDateString();
  
      if (!groups[createdAt]) {
        groups[createdAt] = [];
      }
      groups[createdAt].push(item);
      return groups;
    }, {});
   this.removeEvent()
     this.setState({ groupedItems, markStatus: "", markAsReadId: 0, notifyIds: [] })
  
  
   }
  
  
 removeEvent=()=>{
  window.scrollTo(0, 0)
  if (this.state.notifyData?.length === this.state.notification_count) {
    this.setState({ hasMore: false });
    window.removeEventListener("scroll", this.handleScroll);
  }
 
 }
  getNotificationResponse = (responseJson: NotificationResponse) => {
    if (responseJson && !responseJson.errors) {
      if (responseJson.meta.notification_count < this.state.per_page || responseJson.meta.notification_count == this.state.per_page) {

        this.setState({
          notifyData: [...responseJson.data],
          loading: false,
          notification_count: responseJson.meta.notification_count,
          hasMore: false
        }, this.updateGroupedItems)
      } else if (responseJson.meta.notification_count > this.state.per_page) {
        this.setStateGetApi(responseJson)
      }


    } else if (responseJson && responseJson.errors) {
      this.setState({
        notifyData: [...this.state.notifyData],
        loading: false
      }, this.updateGroupedItems)
      this.checkTokenExpired(responseJson,)
    }
  }

  setStateGetApi = (responseJson:NotificationResponse)=>{
    this.setState({
      hasMore: true,
      page: this.props.infiniteScroll ? this.state.page + 1 : 1,
      notifyData: this.props.infiniteScroll ?
       this.setNotifyData(responseJson) : responseJson.data,
      loading: false,
      notification_count: responseJson.meta.notification_count,
    }, this.updateGroupedItems)
  }

  setNotifyData = (responseJson:NotificationResponse)=>{
   return this.state.markStatus === "SELECTEDDELETE" || this.state.markStatus === "SINGLEDELETE" ?
    responseJson.data : [...this.state.notifyData, ...responseJson.data]
  }

  deleteNotificationResponse = (responseJson: NotificationResponse) => {
    this.handleCloseConfirmationModal();
    if (responseJson && !responseJson.errors) {
      this.setState({ loading: false })
      this.handleClose();
     
      this.setState({ page: 1 }, this.getAllNotifications);
    } else if (responseJson && responseJson.errors) {
      this.checkTokenExpired(responseJson)
    }
  }
  updateIsRead = (notifyIds: number[], markStatus: string, markAsReadId: string | number, item: NotifyDataItem) => {
    if (markStatus === "MARK_ALL" && notifyIds.length === 0) {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          is_read: true // Always set is_read to true for MARK_ALL
        }
      };

    } else if (markStatus === "MARK_ALL" && notifyIds.length > 0) {
      if (notifyIds.includes(item.attributes.id)) {
        item.attributes.is_read = true;
      }
      return item
    } else if ((notifyIds.length === 1 || notifyIds.length === 0) && markStatus === "SINGLEMARK" && markAsReadId === item.attributes.id) {

      return {
        ...item,
        attributes: {
          ...item.attributes,
          is_read: !this.state.is_read // Toggle is_read for SINGLEMARK
        }
      };
    }
    return item
  }
  updateNotificationResponse = (responseJson: NotificationResponse) => {
    this.handleCloseConfirmationModal();
    if (responseJson && !responseJson.errors) {
      this.handleClose();
      this.setState({
        areSelected:false,
        loading: false,
        notifyData: this.state.notifyData.map(this.updateIsRead.bind(this,this.state.notifyIds,this.state.markStatus,this.state.markAsReadId))
      }, this.updateGroupedItems);
    } else if (responseJson && responseJson.errors) {
      this.checkTokenExpired(responseJson);
    }
  };

  checkTokenExpired = (responseJson: ErrorResponse) => {
    if (
      responseJson.errors[0].token == "Token has Expired" ||
      responseJson.errors[0].token == "Invalid token"
    ) {
      localStorage.clear();
      this.redirectToLogin()

    }else if(responseJson.errors[0].message){
      this.setState({ loading:false})
    }
  }
  redirectToLogin = async() => {
    const isEmployee = await checkLoggedInUserDashboard()
    setTimeout(this.redirectCall.bind(isEmployee), 1000);
  }

  redirectCall = async (isEmployee: boolean) => {
    this.props.navigation.history.push(isEmployee ? "/Employee/Login" : "/Login");
  }
  //api calls
  //all notifications
  getAllNotifications = async () => {
   
    this.setState({loading:true})
    const token = await getStorageData("authToken");
    const headers = {
      "token": token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotificationsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotificationsAPiEndPoint}?page=${this.state.page}&per_page=${this.state.per_page}&lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getMarkUrl = () => {

    return `${configJSON.markAllAPiEndPoint}?lang=${this.state.lang}`

  };
  getDeleteHeaders = async (markStatus: string, notifyIds: number[]) => {
    const token = await getStorageData("authToken");
    const header = {
      "token": token,
    };
  
    const bodyFormData = new FormData();
  
    if (markStatus === "SINGLEDELETE") {
      if (notifyIds.length === 0) {
        bodyFormData.append("ids[]", String(this.state.markAsReadId));
      } else if (notifyIds.length === 1) {
        bodyFormData.append("ids[]", String(notifyIds[0]));
      } else {
        // handle error condition
        return { headers: { ...header }, bodyFormData, error: true };
      }
    } else if (markStatus === "SELECTEDDELETE" && notifyIds.length === 0) {
      bodyFormData.append("delete_all", "true")
    } else if (markStatus === "SELECTEDDELETE" && notifyIds.length > 0) {
      notifyIds.forEach((id)=> bodyFormData.append("ids[]", String(id)));
    } 
  
    return { headers: { ...header }, bodyFormData };
  };

  getHeaders = async (markStatus: string, notifyIds: number[], is_read: boolean) => {
    const token = await getStorageData("authToken");
    const header = {
      "token": token,
    };
    let isRead;
    let readAll;
    const bodyFormData = new FormData();
    if (notifyIds.length === 0 && markStatus === "MARK_ALL") {
      isRead = "true";
      readAll = "true";
      bodyFormData.append("is_read", isRead);
      bodyFormData.append("read_all", readAll)
      return { headers: { ...header }, bodyFormData }
    } else if (notifyIds.length === 0 && markStatus === "SINGLEMARK") {

      isRead = is_read === true ? "false" : "true";
      bodyFormData.append("is_read", isRead);
      bodyFormData.append("ids[]", String(this.state.markAsReadId));
      return { headers: { ...header }, bodyFormData }
    } else if (notifyIds.length > 0 && markStatus === "MARK_ALL") {

      isRead = "true";
      bodyFormData.append("is_read", isRead);
      this.state.notifyIds.forEach(id => {
        bodyFormData.append("ids[]", String(id));
      });
      return { headers: { ...header }, bodyFormData }
    }
    else if (notifyIds.length === 1 && markStatus === "SINGLEMARK") {
      isRead = is_read === true ? "false" : "true";
      bodyFormData.append("is_read", isRead);
      this.state.notifyIds.forEach(id => {
        bodyFormData.append("ids[]", String(id));
      });
      return { headers: { ...header }, bodyFormData }
    }
    else if (notifyIds.length > 1 && markStatus === "SINGLEMARK") {
      return { headers: { ...header }, bodyFormData, error: true }
    }
    return { headers: { ...header }, bodyFormData, error: false };

  }
  getCount = (lang: string) => {
    return lang === "en" ? this.state.notification_count : convertToArabicNumerals(this.state.notification_count);
  };

  handleLeaveCallback = (status: string, returnFunction: Function) => {
    if (status === "") {
      return this.handleEmptyFunction
    } else {
      return () => returnFunction(status)
    }

  };


  handleEmptyFunction = ()=>{};

  markNotification = (markStatus: string) => {
    const { t } = this.props;
    const count = this.getCount(this.state.lang)
    if (markStatus === "MARK_ALL" && this.state.notifyIds.length === 0) {
      this.setState({
        confirmationModal: {
          open: true,
          title: t("notifications.markAllRead"),
          content: `${t("notifications.contentOne")} ${count} ${t("notifications.contentTwo")}`,
          cancel: t("Calendar.button.cancel"),
          leave: t("customers.confirmationModal.confirm"),
          handleLeave: this.handleLeaveCallback(markStatus, this.markAsReadAllNotifications)
        }
      })
    } else {
      this.markAsReadAllNotifications(markStatus)
    }
  };

  markAsReadAllNotifications = async (markStatus: string) => {
    this.setState({markStatus})
    const requestData: RequestData  = await this.getHeaders(markStatus, this.state.notifyIds, this.state.is_read)
 
    if (requestData.error) {
      this.handleClose()
      return this.props.openToastHandler("You can not mark, if multiple notification are selected!", "error")
    }
    this.setState({ loading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAllNotificationsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getMarkUrl()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestData.headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestData.bodyFormData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAll = async (deleteStatus:string) => {
    const { headers, bodyFormData, error }: RequestData = await this.getDeleteHeaders(deleteStatus, this.state.notifyIds)
 
    if (error) {
      this.handleClose()
      return this.props.openToastHandler("You can not delete, if multiple notification are selected!", "error")
    }
    this.setState({ loading: true ,markStatus:deleteStatus})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAllNotificationsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAllAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  toggleReadMore = (id: number,event:React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({
      expandedTitles: [...this.state.expandedTitles, id],
      isExpanded: true
    });
  };

  toggleReadLess = (id: number,event:React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({
      expandedTitles: this.state.expandedTitles.filter((item) => item != id)
    });
  };

  handleClick = (item: NotifyDataItem, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
   
    this.setState({ anchorEl: event.currentTarget },
      () => this.setState({
        anchorId: "simple-popover",
        markAsReadId: item.attributes.id, 
        is_read: item.attributes.is_read// false = unread hai read karna hai  , true = read hai unread karna hai
      }));
  };

   handleClose = () => {
    this.setState({anchorEl:null},()=>this.setState({anchorId:""}));
  };



  viewAllNotifications = (deleteStatus: string) => {
    const { t } = this.props;
    const count = this.getCount(this.state.lang);
    if (deleteStatus === "SELECTEDDELETE" && this.state.notifyIds.length === 0) {
      this.setState({
        confirmationModal: {
          open: true,
          title: t("notifications.deleteAll"),
          content: `${t("notifications.contentThree")} ${count} ${t("notifications.contentFour")}`,
          cancel: t("Calendar.button.cancel"),
          leave: t("customers.confirmationModal.confirm"),
          handleLeave: this.handleLeaveCallback(deleteStatus, this.deleteAll)
        }
      })
    } else {
      this.deleteAll(deleteStatus)
    }
  }

  handleCloseConfirmationModal = ()=>{
    this.setState({
      confirmationModal:{
        open:false,
        title: "",
        content: "",
        cancel: "",
        leave: "",
        handleLeave: this.handleLeaveCallback("", () => { })
      }
    })
  }

  handleNavigation = () => {
    this.handleNavigate("Notifications")
  }

  addNotifyIds = (item: NotifyDataItem,event:React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.setState({ 
        notifyIds: [...this.state.notifyIds, item.attributes.id],
       
      })
    } else {
      this.setState({ notifyIds: this.state.notifyIds.filter((subItem) => item.attributes.id != subItem) })
    }
  }

  selectAll = () => {
    if (this.state.areSelected) {
      this.setState({
        notifyIds:
          [],
        areSelected: !this.state.areSelected
      },
      )
    } else {
      this.setState({
        notifyIds:
        Array.from(new Set(this.state.notifyData.map(item => item.attributes.id))),
        areSelected: !this.state.areSelected
      },
      )
    }
   
}

handleNavigate = (value: string) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), value);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
  }

  goToRedirect = async (item: NotifyDataItem) => {
    const { type, id , site_id} = item.attributes.notificationDetails;

    switch (type) {
      case "Job":
        this.props.navigation.history.push("/Job/Edit", { jobId: id, type: "edit" });
        break;
      case "Task":
        this.handleNavigate("TaskAllocator");
        break;
      case "Request":
        this.props.navigation.history.push("/Request/edit", { requestId: id });
        break;
      case "Invoice":
        this.props.history.push("/Invoice", { invoiceId: id, siteId: site_id || "" });
        break;
      case "Quote":
        await setStorageData("quote_id", id);
        this.handleNavigate("QuoteCreate");
        break;
      default:
        break;
    }
  }

  // Customizable Area End
}
