import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  lamsaFeaturesData: any;
  compareLamsaFeaturesLoading: boolean;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PricingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  compareLamsaFeaturesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      lamsaFeaturesData: [],
      compareLamsaFeaturesLoading: false,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.compareLamsaFeaturesPage();
    this.setState({ compareLamsaFeaturesLoading: true });

    const selectedLanguage = await getStorageData("lang");
    
    this.setState({ languageSelected: selectedLanguage });
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.compareLamsaFeaturesApiCallId) {
          if (!responseJson.errors) {
            this.setState({ lamsaFeaturesData: responseJson.data });
            this.setState({ compareLamsaFeaturesLoading: false });
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.setState({ compareLamsaFeaturesLoading: false });
          }

          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  

  compareLamsaFeaturesPage = async () => {
    const selectedLanguage = await getStorageData("lang");
    const header = {
      "Content-Type": configJSON.comparelamsaFeaturesAPIContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.compareLamsaFeaturesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.comparelamsaFeaturesAPIEndPoint}?lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.comparelamsaFeaturesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End