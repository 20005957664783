import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const { format } = require('date-fns')
import { WithStyles } from "@material-ui/core/styles";
export const configJSON = require("./config");
import { emptyImage } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { debounce } from "../../../components/src/CommonFunction";


const extractDataFromMessage = (message: Message) => {
  return {
    apiRequestCallId: message.getData(getName(MessageEnum.RestAPIResponceDataMessage)),
    responseJson: message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
    errorResponse: message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
  };
};

export type EmployeeData = {
  id: string;
  icon: string;
  name: string;
  email: string;
}
type CustomerData = {
  id: string,
  icon: string,
  name: string,
  email: string,
}

type ItemResponse = {
  id: string,
  attributes: {
    employee_image: {
      url: string
    },
    full_name: string,
    email: string
  }
}
type Responsecustomer = {
  id: string,
  attributes: {
    employee_image: {
      url: string
    },
    name: string,
    email: string
  }
}

interface ResponseData {
  url: string
}
interface MyError {
  token?: string;
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  active: number;
  history: any;
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openPopup: boolean;
  requestDate: {
    fromDate: Date;
    toDate: Date;
  };
  mobileSideBar: boolean;
  enable: boolean;
  employeeData: EmployeeData[];
  employeeAssigneeModal: boolean;
  selectedEmployees: EmployeeData[];
  selectedEmployeeName: string;
  getEmployeesListLoading: boolean;
  employeesLength: boolean;
  languageSelected: string;
  formatFile:string;
  selectedCustomerName: string;
  customerModal: boolean;
  getcustomerListLoading: boolean;
  selectedCustomer: CustomerData[];
  cutomerData: CustomerData[];
  customerLength: boolean;
  customerId:string;
  employeeId: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ReportsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllEmployeesListApiCallId: string = "";
  getDownloadPdfApiCallId:string = "";
  getCustomerSearchApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      openPopup: false,
      requestDate: {
        fromDate: new Date(),
        toDate: new Date(),
      },
      mobileSideBar: true,
      enable: true,
      employeeData: [],
      employeeAssigneeModal: false,
      selectedEmployees: [],
      selectedEmployeeName: "",
      getEmployeesListLoading: false,
      employeesLength: false,
      languageSelected: "",
      formatFile:'',
      selectedCustomerName: "",
      customerModal: false,
      selectedCustomer: [],
      cutomerData: [],
      getcustomerListLoading: false,
      customerLength: false,
      customerId:"",
      employeeId: "",
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getEmployeesList();
    this.handleEmployeeLengthMobile();
    this.handleCustomerLengthMobile()
    
    const selectedLanguage = await getStorageData("lang")
    this.setState({ languageSelected: selectedLanguage });
    
    window.scrollTo(0, 0);
    this.handleSideBarMobile();
    // Customizable Area End
  }

  // Customizable Area Start
  tokenExpired = () => {
    setTimeout(this.setTimeOutHandler, 2000);
  }

  setTimeOutHandler = () => {
    localStorage.clear();
    this.props.navigation.history?.push("/Login");
  }
  
  errorHandlerMessage = (error: string) => {
    const { token } = (error as MyError) || {};
    if (token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      this.tokenExpired();
    }
  }

  errorResponseMessage = (error: string) => {
    error && this.errorHandlerMessage(error);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start    
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllEmployeesListApiCallId) {        
        if (!responseJson.errors) {
          this.setState({ getEmployeesListLoading: false });
          const employeeData = responseJson?.data;
          const data = employeeData?.map((item: ItemResponse) => ({
            id: item?.id,
            icon: item?.attributes?.employee_image?.url ?? emptyImage,
            name: item?.attributes?.full_name,
            email: item?.attributes?.email
          }));
          this.setState({ employeeData: data });
        } else if (responseJson.errors) {
          this.setState({ getEmployeesListLoading: false })
          const error = responseJson?.errors?.[0]
          this.errorResponseMessage(error)
        } else {
          //Check Error Response
          this.setState({ getEmployeesListLoading: false })
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
      this.customerSearch(message);
      if(apiRequestCallId === this.getDownloadPdfApiCallId){
       
        this.handleDownload(responseJson);
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getEmployeesList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllEmployeesListApiCallId = requestMessage.messageId;
    const apiUrl = `${configJSON.getAllEmployesAPiEndPoint}?query=${this.state.selectedEmployeeName }`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  customerSearch = (message: Message) => {
    const { responseJson, errorResponse } = extractDataFromMessage(message);

    if (!responseJson.errors) {
      this.setState({ getcustomerListLoading: false });
      const customerData = responseJson?.data;
      const data = customerData?.map((item: Responsecustomer) => ({
        id: item?.id,
        icon: item?.attributes?.employee_image?.url ?? emptyImage,
        name: item?.attributes?.name,
        email: item?.attributes?.email
      }));
      this.setState({ cutomerData: data });
    } else if (responseJson.errors) {
      this.setState({ getcustomerListLoading: false })
      const error = responseJson?.errors?.[0]
      this.errorResponseMessage(error)
    } else {
      this.setState({ getcustomerListLoading: false })
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorResponse);
  
}
  handleTabChange = async (event: React.ChangeEvent<HTMLInputElement>, value: number) => {
    if(value === 0) {
      this.handleNavigate(configJSON.RequestReport);
    } else if(value === 1) {
      this.handleNavigate(configJSON.JobReport);
    } else if(value === 2) {
      this.handleNavigate(configJSON.JobFinancialReport);
    } else if(value === 3) {
      this.handleNavigate(configJSON.TimesheetReport);
    }
  }

  handleDownloadButton = () => {
    this.setState({ openPopup: !this.state.openPopup });
  }

  handleClickAway = () => {
    this.setState({ openPopup: false });
  }

  handleFromDateChange = (date: Date) => {
    this.setState({
      requestDate: {...this.state.requestDate, fromDate: date}
    });
  }

  handleToDateChange = (date: Date) => {
    this.setState({
      requestDate: {...this.state.requestDate, toDate: date}
    });
  }

  handleClickGoBack = () => {
    this.props.navigation.history?.goBack();
  }

  handleSideBarMobile = () => {
    if(window.innerWidth < 960) {
      this.setState({ mobileSideBar: false });
    } else {
      this.setState({ mobileSideBar: true });
    }
  }

  handleEmployeeLengthMobile = () => {
    if(window.innerWidth < 600) {
      this.setState({ employeesLength: false });
    } else {
      this.setState({ employeesLength: true });
    }
  }

  handleCustomerLengthMobile = () => {
    if (window.innerWidth < 600) {
      this.setState({ customerLength: false });
    } else {
      this.setState({ customerLength: true });
    }
  }

  handleRadioChange = () => {
    this.setState({ enable: !this.state.enable });
  }

  handleClickOutCustomerSide = () => {
    this.setState({ customerModal: false });
  }
  handleClickOutSide = () => {
    this.setState({ employeeAssigneeModal: false });
  }

  handleClickOpenEmployeePopup = () => {
    this.setState({ employeeAssigneeModal: true }, () => {
      this.getEmployeesList();
      if(this.state.selectedEmployees.length === 0) {
        this.setState({ getEmployeesListLoading: true });
      }
    });
  }

  handleClickOpenCustomerPopup = () => {
    this.setState({ customerModal: true }, () => {
      this.getCustomerList();
      if (this.state.selectedCustomer.length === 0) {
        this.setState({ getcustomerListLoading: true });
      }
    });
  }

  handleRemoveCustomer = (customerId: string) => {
    const filteredItem = this.state.selectedCustomer.filter((item) => {
      return item.id!== customerId;
    });
    this.setState({ selectedCustomer: filteredItem });
  }
  
  handleRemoveEmployee = (id: string) => {
    const filteredItem = this.state.selectedEmployees.filter((item) => {
      return item.id !== id;
    });
    this.setState({ selectedEmployees: filteredItem });
  }

  debounceFunction = debounce(this.getEmployeesList, 700);
  handleChangeEmployeeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedEmployeeName: e?.target.value, employeeAssigneeModal: true }, () => {
      this.debounceFunction();
    });
  }

  getCustomerList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerSearchApiCallId = requestMessage.messageId;
    const apiUrl = `${configJSON.getCustomerSerachAPiEndPoint}?query=${this.state.selectedCustomerName}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  debounceFunction1 = debounce(this.getCustomerList, 700);

  handleChangeCustomerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedCustomerName: event?.target.value, customerModal: true }, () => {
      this.debounceFunction1();
    });
  }

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, item: EmployeeData) => {
    this.setState({employeeId: item.id})
    const checked = event.target.checked;
    const selectedList = this.state.selectedEmployees;
    const index = selectedList.findIndex((selectedItem: EmployeeData) => selectedItem.id === item.id);
    
    if (checked && index === -1) {
      selectedList.push(item);
    } else if (!checked && index !== -1) {
      selectedList.splice(index, 1);
    }

    this.setState({ selectedEmployees: selectedList }, () => {
      this.setState({ selectedEmployeeName: "" });
      this.getEmployeesList();
    });
  }

  handleCheckboxCustomer = (event: React.ChangeEvent<HTMLInputElement>, item: CustomerData) => {
    this.setState({customerId: item.id})
    const checked = event.target.checked;
    const selectedList = this.state.selectedCustomer;
    const index = selectedList.findIndex((selectedItem: CustomerData) => selectedItem.id === item.id);

    if (checked && index === -1) {
      selectedList.push(item);
    } else if (!checked && index !== -1) {
      selectedList.splice(index, 1);
    }

    this.setState({ selectedCustomer: selectedList }, () => {
      this.setState({ selectedCustomerName: "" });
      this.getCustomerList();
    });
  }


  pdfDownlod = async (fileType: string, fromDate: Date, todate: Date) => {
    this.setState({ formatFile: fileType })
    const fromDateString = format(new Date(fromDate), 'yyyy-MM-dd');
    const toDateString = format(new Date(todate), 'yyyy-MM-dd');
    const header = {
      "Content-Type": 'application/json',
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDownloadPdfApiCallId = requestMessage.messageId;

    const downloadEndPoint = configJSON.getDownloadPdfEndPoint
      ? configJSON.getDownloadPdfEndPoint
      : configJSON.getDownloadpdfJobreportEndPoint;

    const apiUrl = `${downloadEndPoint}?from_date=${fromDateString}&to_date=${toDateString}&customer_ids=${this.state.customerId}&employee_ids=${this.state.employeeId}&format=${this.state.formatFile}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleDownload = (response: ResponseData) => {
    const timeStamp = new Date().toISOString().replace(/[-T:\.Z]/g, '');
    const fileName = "Requests_Report";
    const updatedFileName = `${fileName}_${timeStamp}.pdf`;
    const updatedFileNamecsv = `${fileName}_${timeStamp}.csv`;

    const fileUrl = response && response.url;

    const link = document.createElement('a');
    link.href = fileUrl;

    if (this.state.formatFile === 'pdf') {
        link.setAttribute('download', updatedFileName);
    } else {
        link.setAttribute('download', updatedFileNamecsv);
    }
    
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
};

}
// Customizable Area End