export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const companyInfoIcon = require("../assets/companyInfoIcon.png");
export const productServicesIcon = require("../assets/productServicesIcon.png");
export const billingIcon = require("../assets/billingIcon.png");
export const notificationsIcon = require("../assets/notificationsIcon.png");
export const manageTaxRatesImage = require("../assets/manageTaxRatesImage.png");
export const galleryIcon = require("../assets/galleryIcon.png");
export const deleteLogoIcon = require("../assets/deleteLogoIcon.png");
export const deleteTaxIcon = require("../assets/deleteTaxIcon.png");
export const editProductsServicesIcon = require("../assets/editProductsServicesIcon.png");
export const dialogBoxIcon = require("../assets/dialogBoxIcon.png");
export const productImage = require("../assets/productImage.png");
export const servicesImage = require("../assets/servicesImage.png");
export const payImage = require("../assets/payImage.png");
export const noInvoicesImage = require("../assets/noInvoicesImage.png");
export const visaImage = require("../assets/visaImage.png");
export const mastercardImage = require("../assets/mastercardImage.png");
export const payPalImage = require("../assets/payPalImage.png");
export const chipIcon = require("../assets/chipIcon.png");
export const visaIcon = require("../assets/visaIcon.png");
export const wifiIcon = require("../assets/wifiIcon.png");
export const blueBackgroundCard = require("../assets/blueBackgroundCard.png");
export const violetBackgroundCard = require("../assets/violetBackgroundCard.png");
export const infoIcon = require("../assets/infoIcon.png");
export const deleteCardIcon = require("../assets/deleteCardIcon.png");
export const mastercardIcon = require("../assets/mastercardIcon.png");
export const paypalIcon = require("../assets/paypalIcon.png");
export const managePlan1 = require("../assets/managePlan1.png");
export const managePlan2 = require("../assets/managePlan2.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const dropDownSelectIcon = require("../assets/dropDownSelectIcon.png");
export const iIcon = require("../assets/iIcon.png");
export const afterHoverIcon = require("../assets/afterHoverIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const clockIcon = require("../assets/clockIcon.png");
export const rightArrow = require("../assets/rightArrow.png");
export const leftArrow = require("../assets/leftArrow.png");
export const backIconMobile = require("../assets/backIconMobile.png");
export const cross = require("../assets/cross.png");