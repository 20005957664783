//@ts-nocheck
import React from 'react';
import { Button, Dialog, DialogTitle, withStyles, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DeleteModalController from './DeleteModalController.web';
import { CONSTANTS } from "../../../components/src/theme"
import { t } from "i18next";
export const DeleteModelStyles = (theme: any) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: CONSTANTS.color.lightGrey,
        width: "15px",
        height: "16px",
    },
   marginBottom_1:{
    marginBottom:'0.5em'
   },
    text_lightBrown: {
        color: CONSTANTS.color.lightBrown
    },
    BtnSize: {
        width: "150px",
        height: "56px",
        borderRadius: "10px",
        textTransform: "capitalize",
    },
    darkBlue_btn:{
        color: CONSTANTS.color.darkBlue,
        border: "1px solid #1c18af",
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
            border: "none",
            cursor: "pointer",
            backgroundColor: CONSTANTS.color.blueViolet,
            color: theme.palette.common.white
        },
    },
    fontW_600:{
        fontWeight:CONSTANTS.fontWeight.fontWeight600
    },
    lightGrey_btn: {
        '&:hover': {
            backgroundColor: '#999999',
            color: theme.palette.common.white,
            cursor: "pointer"
        },
        color: CONSTANTS.color.lightGrey,
        border: "1px solid #999999",
        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s'
    },
    ml_15:{
        marginLeft:"15px"
    }
});

export class DeleteModel extends DeleteModalController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        const { classes } = this.props
        return (
            // Customizable Area Start
            <Dialog
                open={this.props.handleArchivedDialogue}
                onClose={this.props.handleCloseDeleteDialog}
                PaperProps={{
                    style: {
                        width: "634px",
                        borderRadius: '8px',
                    },
                }}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.handleCloseDeleteDialog}>
                        <CloseIcon style={{color:"#161616"}} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h6' className={`${classes.fontW_600} ${classes.text_lightBrown} ${classes.marginBottom_1}`}>{this.props.deleteType}</Typography>
                    <DialogContentText>
                        <Typography variant='subtitle1' className={classes.text_lightBrown}>{this.props.subType}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding:"21px"}}>
                    <Button variant='outlined' onClick={this.props.handleCloseDeleteDialog} className={this.props.selectLan==='ar'? `${classes.BtnSize} ${classes.lightGrey_btn} ${classes.ml_15}`:`${classes.BtnSize} ${classes.lightGrey_btn}`}>
                        <Typography variant='subtitle2'>{t('invoice.button.cancel')}</Typography>
                    </Button>
                    <Button variant='outlined' className={`${classes.BtnSize} ${classes.darkBlue_btn}`}
                        onClick={this.props.deleteAPI}
                    >
                       <Typography variant='subtitle2'>{t('invoice.button.proceed')}</Typography> 
                    </Button>
                </DialogActions>

            </Dialog>
            // Customizable Area End
        );
    }
}
export default withStyles(DeleteModelStyles)(DeleteModel);
