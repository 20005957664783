import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, emptyImage } from "./assets";
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { checkLoggedInUser, handleViewData } from "../../CustomisableUserProfiles/src/utility.web";
import { checkLoggedInUserDashboard } from "../../dashboard/src/utility.web";
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";
import { navigateTo } from '../../../components/src/CommonFunction'


const mockUrgencyData = [
  { id: 1, title: "High" },
  { id: 2, title: "Medium" },
  { id: 3, title: "Low" },
]

const mockUrgencyDataArabic = [
  { id: 1, title: "عالي" },
  { id: 2, title: "واسطة" },
  { id: 3, title: "قليل" },
];

const extractDataFromMessage = (message: Message) => {
  return {
    apiRequestCallId: message.getData(getName(MessageEnum.RestAPIResponceDataMessage)),
    responseJson: message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
    errorResponse: message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
  };
};

interface UpcomingTasks {
  id: string;
  todo: string;
  assignedTo: {
    id: number;
    icon: string;
    name: string;
    email: string;
  }[]
  dueDate: string;
  urgency: string;
}

interface UrgencyData {
  id: number;
  title: string;
}

interface EmployeeDetails {
  id: string;
  icon: string;
  name: string;
  email: string;
}

interface MyError {
  token?: string;
}
const dueDate = localStorage.getItem("startDate");
const dueDate1 = () => {
  if (dueDate) {
      return new Date(dueDate)
  } else {
      return null
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: { push : Function };
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showUpcomingTasks: boolean;
  showCompletedTasks: boolean;
  upcomingTasks: UpcomingTasks[];
  completedTasks: UpcomingTasks[];
  selectedId: number | null;
  selectedCompletedId: number | null;
  completedToUpcomingLoading: boolean;
  createTask: boolean;
  showAddTodo: boolean;
  showAssignee: boolean;
  showDueDate: boolean;
  showUrgency: boolean;
  showUrgencyModal: boolean;
  todo: string;
  dueDate: any;
  urgency: string;
  showDatePicker: boolean;
  urgencyData: UrgencyData[];
  showAssigneeModal: boolean;
  employeeDetails: EmployeeDetails[];
  selectedEmployeeId: number | null;
  selectedEmployee: Record<string, string>;
  selectedEmployeeName: string;
  todoError: string;
  editTodo: string;
  editSelectedId: number | null;
  todoEditError: string;
  editDueDate: any;
  showDatePickerEdit: boolean;
  editSelectedDueDateId: number | null;
  showEditUrgency: boolean;
  editSelectedUrgency: number | null;
  editUrgency?: string;
  showAssigneeEdit: boolean;
  editSelectedAssignee: boolean;
  showAssigneeModalEdit: boolean;
  editSelectedIdEmployee: number | null;
  editSelectedIdEmployeeId: number | null;
  selectedEmployeeNameEdit?: string;
  selectedEmployeeEdit: Record<string, string>;
  completedTask: boolean;
  upcoming: boolean;
  getAllTasksLoading: boolean;
  addTaskLoading: boolean;
  deleteTaskLoading: boolean;
  completedTaskLoading: boolean;
  editTaskLoading: boolean;
  getEmployeesListLoading: boolean;
  loggedInUser:boolean;
  empId:any
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskAllocatorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  datePickerRef: any;
  editDatePickerRef: any;
  addTaskApiCallId: string = "";
  getAllEmployeesApiCallId: string = "";
  getAllTasksApiCallId: string = "";
  deleteTaskApiCallId: string = "";
  completeTaskApiCallId: string = "";
  editTaskApiCallId: string = "";
  completedToUpcomingTaskApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showUpcomingTasks: true,
      showCompletedTasks: false,
      upcomingTasks: [],
      completedTasks: [],
      selectedId: null,
      selectedCompletedId: null,
      completedToUpcomingLoading: false,
      createTask: false,
      showAddTodo: false,
      showAssignee: false,
      showDueDate: false,
      showUrgency: false,
      showUrgencyModal: false,
      todo: "",
      dueDate: dueDate1(),
      urgency: "",
      showDatePicker: false,
      urgencyData: mockUrgencyData,
      showAssigneeModal: false,
      employeeDetails: [],
      selectedEmployeeId: null,
      selectedEmployee: {},
      selectedEmployeeName: "",
      todoError: "",
      editTodo: "",
      editSelectedId: null,
      todoEditError: "",
      editDueDate: null,
      showDatePickerEdit: false,
      editSelectedDueDateId: null,
      showEditUrgency: false,
      editSelectedUrgency: null,
      editUrgency: "",
      showAssigneeEdit: false,
      editSelectedAssignee: false,
      showAssigneeModalEdit: false,
      editSelectedIdEmployee: null,
      editSelectedIdEmployeeId: null,
      selectedEmployeeNameEdit: "",
      selectedEmployeeEdit: {},
      completedTask: false,
      upcoming: false,
      getAllTasksLoading: false,
      addTaskLoading: false,
      deleteTaskLoading: false,
      completedTaskLoading: false,
      editTaskLoading: false,
      getEmployeesListLoading: false,
      loggedInUser:false,
      empId:"",
      languageSelected: "en",
      // Customizable Area End
    };
    // Customizable Area Start
    this.datePickerRef = React.createRef<any>();
    this.editDatePickerRef = React.createRef<any>();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedlanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedlanguage });
    const fromRoute = await getStorageData('calendarRedirection')
    if (fromRoute) {
      const params = new URLSearchParams(window.location.search);
      params.set('from', 'Calendar');
      window.history.pushState({}, '', `?${params.toString()}`);
    }
    this.setState({
      urgencyData: selectedlanguage === "ar" ? mockUrgencyDataArabic : mockUrgencyData
    });
    const user = await checkLoggedInUserDashboard();//true = employee
    const empId = await getStorageData("employeeId")

    this.getAllTasks();
    this.setState({ getAllTasksLoading: true,  });
    this.setState({  loggedInUser: user, empId },()=>{
      this.getEmployeesList();
    })

    window.scrollTo(0, 0);
    if (!await getStorageData(configJSON.authToken)) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.addTaskApiCallId) {
        if (!responseJson.errors) {
          this.setState({ addTaskLoading: false });
          this.getAllTasks();
          this.checkRedirect();
        } else if (responseJson.errors) {
          this.setState({ addTaskLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ addTaskLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    this.handleGetAllEmployees(message);
    this.handleGetAllTasks(message);
    this.handleDeleteTask(message);
    this.handleCompletedTask(message);
    this.handleEditTask(message);
    this.handleCompletedToUpcomingTask(message);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text })
    },
    secureTextEntry: false,
  }

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
    }
  }

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess))
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    )
    this.send(msg)
  }

  // Customizable Area Start 
  tokenExpired = (isEmployee: boolean) => {
    setTimeout(this.setTimeOutHandler.bind(this, isEmployee), 2000);
  }
  checkRedirect = async () => {
    const params = new URLSearchParams(window.location.search || '');
    const paramValue = params.get('from');
    if (paramValue) {
      this.redirectUrl(paramValue);
    }
  }
  redirectUrl = (url: string) => {
    if (url) {
      this.clearRedirectUrl()
      navigateTo({ props: this.props, screenName: "Calendar" })
    }
  };
  clearRedirectUrl = async () => {
    await removeStorageData("type");
    await removeStorageData("calendarRedirection");
    await removeStorageData("selectedEventId");
};
  setTimeOutHandler = (isEmployee: boolean) => {
    localStorage.clear();
    this.props.navigation.history?.push(isEmployee ? "/Employee/Login" : "/Login");
  }

  errorHandlerMessage = async (error: string) => {
    const { token } = (error as MyError) || {};
    if (token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  errorResponseMessage = (error: string) => {
    error && this.errorHandlerMessage(error);
  }
  setEmpData = (loggedInUser: any, responseJson: any) => {
    return loggedInUser ? [responseJson.data] : responseJson.data;
  }
  handleGetAllEmployees = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllEmployeesApiCallId) {
        if (!responseJson.errors) {
          this.setState({ getEmployeesListLoading: false });
          const employeesData = this.setEmpData(this.state.loggedInUser, responseJson)
          if (Array.isArray(employeesData)) {
            const data = employeesData?.map((item: any) => ({
              id: item?.id,
              icon: item?.attributes?.employee_image?.url ?? emptyImage,
              name: item?.attributes?.full_name,
              email: item?.attributes?.email
            }));
            this.setState({ employeeDetails: data });
          }
        } else if (responseJson.errors) {
          this.setState({ getEmployeesListLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ getEmployeesListLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleGetAllTasks = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllTasksApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.showOpenToastHandler()
          } else{
            this.setState({ getAllTasksLoading: false });
            const upcomingTasksData = responseJson.pending?.data;
            const completedTasksData = responseJson.completed?.data;
            this.handleUpcomingTasks(upcomingTasksData, completedTasksData);
          }
        } else if (responseJson.errors) {
          this.setState({ getAllTasksLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ getAllTasksLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleUpcomingTasks = (upcomingTasksData: UpcomingTasks, completedTasksData: UpcomingTasks) => {
    if (Array.isArray(upcomingTasksData)) {
      const data = upcomingTasksData.map((item) => ({
        id: item?.id,
        todo: item.attributes?.task_name,
        assignedTo: [{
          id: item?.attributes?.employee?.id,
          icon: item?.attributes?.employee_image?.url ?? emptyImage,
          name: item?.attributes?.employee?.name,
          email: item?.attributes?.employee?.email
        }],
        dueDate: item?.attributes?.due_date,
        urgency: `${item?.attributes?.urgency.charAt(0).toUpperCase()}${item?.attributes?.urgency.slice(1).toLowerCase()}`
      }));
      this.setState({ upcomingTasks: data });
    }
    if (Array.isArray(completedTasksData)) {
      const data = completedTasksData.map((item) => ({
        id: item.id,
        todo: item.attributes.task_name,
        assignedTo: [{
          id: item.attributes.employee.id,
          icon: item.attributes.employee_image?.url ?? emptyImage,
          name: item.attributes.employee.name,
          email: item.attributes.employee.email
        }],
        dueDate: item.attributes.due_date,
        urgency: `${item.attributes.urgency.charAt(0).toUpperCase()}${item.attributes.urgency.slice(1).toLowerCase()}`
      }));
      this.setState({ completedTasks: data });
    }
  }

  handleDeleteTask = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteTaskApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            deleteTaskLoading: false,
            selectedId: null
          });
          this.getAllTasks();
        } else if (responseJson.errors) {
          this.setState({ deleteTaskLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ deleteTaskLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleCompletedTask = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.completeTaskApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            completedTaskLoading: false,
            selectedId: null,
            completedTask: true,
          });
          this.getAllTasks();
        } else if (responseJson.errors) {
          this.setState({ completedTaskLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ completedTaskLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleEditTask = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.editTaskApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            editTaskLoading: false,
            editSelectedId: null,
            editTodo: "",
            selectedEmployeeEdit: {},
            editSelectedIdEmployeeId: null,
            editDueDate: null,
            editUrgency: "",
          });
          this.getAllTasks();
          this.checkRedirect();
        } else if (responseJson.errors) {
          this.setState({ editTaskLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ editTaskLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleCompletedToUpcomingTask = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.completedToUpcomingTaskApiCallId) {
        if (!responseJson.errors) {
          this.setState({ selectedCompletedId: null });
          this.getAllTasks();
          this.setState({ completedToUpcomingLoading: false });
        } else if (responseJson.errors) {
          this.setState({ completedToUpcomingLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ completedToUpcomingLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  showOpenToastHandler = ()=>{
    const { t } = this.props;
    this.props.openToastHandler(t('taskAllocator.notAuthorized'), configJSON.errorLabel)
  }

  handleCreateATask = async () => {
    const isAuthorized = await handleViewData("tasks")
    this.handleCreateTaskCallback(isAuthorized)
  }

  handleGoBack = () => {
    this.props.navigation.history.goBack();
  }

  handleCreateTaskCallback = (isAuthorized:any)=>{
    if(isAuthorized.includes("CREATE")){
      if(!this.state.todoEditError) {
        this.setState({ createTask: true });
      }
    } else {
     this.showOpenToastHandler()
    }
  }
  
  handleViewAllUpcomingTasks = () => {
    this.setState({ showUpcomingTasks: !this.state.showUpcomingTasks });
  }

  handleDeleteUpcomingTask = async (id: number) => {
    const isAuthorized = await handleViewData("tasks")
    this.handleDeleteUpcomingTaskCallback(id,isAuthorized)
  }

  handleDeleteUpcomingTaskCallback = (id:number, isAuthorized:any)=>{
    if(isAuthorized.includes("DELETE")){
      this.deleteTask(id);
      this.setState({ deleteTaskLoading: true });
    } else {
      this.showOpenToastHandler()
    }
  }

  handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { selectedId } = this.state;
    const newSelectedId = selectedId === id ? null : id;
    this.setState({
      selectedId: newSelectedId
    }, () => this.handleCompleteTask(e, newSelectedId as number));
  }

  handleCompleteTask = async (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const isAuthorized = await handleViewData("tasks")
    this.handleCompleteTaskCallback(isAuthorized,id)
  }

  handleCompleteTaskCallback = (isAuthorized:any,id:number)=>{
    if(isAuthorized.includes("COMPLETE")){
      this.completeTask(id);
      this.setState({ completedTaskLoading: true }, () => {
        this.setState({ completedTask: false });
      });
    } else {
     this.showOpenToastHandler()
    }
  }

  handleClickAddTodo = () => {
    this.setState({ showAddTodo: true });
  }

  handleClickAddAssignee = () => {
    const { todo } = this.state;
    const { t } = this.props;
    if(todo) {
      this.setState({
        showAssignee: true,
        showAssigneeModal: true,
        selectedEmployeeNameEdit: ""
      }, () => {
        this.getEmployeesList();
        this.setState({ getEmployeesListLoading: true });
      });
    } else {
      this.setState({ showAddTodo: true, todoError: t('taskAllocator.todoRequired') });
    }
  }

  handleClickDueDate = () => {
    const { todo } = this.state;
    const { t } = this.props;
    if(todo) {
      this.setState({ showDueDate: true, showDatePicker: true });
    } else {
      this.setState({ showAddTodo: true, todoError: t('taskAllocator.todoRequired') });
    }
  }

  handleClickAddUrgency = () => {
    const { todo } = this.state;
    const { t } = this.props;
    if(todo) {
      this.setState({ showUrgency: true, showUrgencyModal: true });
    } else {
      this.setState({ showAddTodo: true, todoError: t('taskAllocator.todoRequired') });
    }
  }

  handleSelectDate = (date: Date) => {
    this.setState({ dueDate: date }, () => {
      this.setState({ showDatePicker: false });
      this.handleAddTask();
    });
  };

  handleHideCalendar = () => {
    this.setState({ showDatePicker: false });
  }

  handleClickUrgencyOutside = () => {
    this.setState({ showUrgency: true, showUrgencyModal: false });
  }

  handleSelectUrgency = (title: string) => {
    this.setState({ urgency: title }, () => {
      this.setState({ showUrgencyModal: false });
      this.handleAddTask();
    });
  }

  handleChangeTodo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e?.target.value;
    const { t } = this.props;
    let todoError = '';
    if (inputValue?.length > 23) {
      todoError += t('taskAllocator.todoLength');
    }
    this.setState({ todo: inputValue, todoError });
  }

  handleAddTask = async () => {
    const { todo, dueDate, urgency, selectedEmployee, selectedEmployeeName, todoError } = this.state;
    if(todo && dueDate && urgency && selectedEmployee && selectedEmployeeName && !todoError) {
      this.addTask();
      this.setState({ addTaskLoading: true });
      this.setState({
        todo: "",
        dueDate: null,
        urgency: "",
        showAddTodo: false,
        showDueDate: false,
        showUrgency: false,
        showAssignee: false,
        selectedEmployeeId: null,
        selectedEmployeeName: "",
        selectedEmployee: {}
      });
    }
  }

  handleClickAssigneeOutside = () => {
    this.setState({ showAssigneeModal: false,  });
  }

  handleSelectEmployee = (item: any) => {
    this.setState({
      selectedEmployeeId: item.id,
      selectedEmployee: item,
      selectedEmployeeName: item.name
    }, () => {
      this.setState({ showAssigneeModal: false });
      this.handleAddTask();
      this.getEmployeesList();
    });
  }

  handleChangeEmployeeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedEmployeeName: e?.target.value }, () => {
      this.getEmployeesList();
    });
  }

  handleShowEditTodo = async (id: number) => {
   const isAuthorized = await handleViewData("tasks")
   this.handleShowEditTodoCallback(id,isAuthorized)
  }

  handleShowEditTodoCallback = (id:number, isAuthorized:any)=>{
    if(isAuthorized.includes("EDIT")){
      const { todoEditError } = this.state;
      const todoFilter = this.state.upcomingTasks.find((item: any) => item.id === id);
      if(!todoEditError) {
        this.setState({
          editSelectedId: id,
          editTodo: todoFilter?.todo as string
        });
      }
    } else {
      this.showOpenToastHandler()
     }
  }

  handleChangeEditTodo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e?.target.value;
    const { t } = this.props;
    let todoEditError = '';
    if (!inputValue) {
      todoEditError += t('taskAllocator.todoRequired');
    }
    if (inputValue?.length > 23) {
      todoEditError += t('taskAllocator.todoLength');
    }
    this.setState({ editTodo: inputValue, todoEditError });
  }

  handleClickTodoOutside = async (id: number) => {
    const { todoEditError } = this.state;
    if(!todoEditError) {
      this.editTask(id);
      this.setState({ editTaskLoading: true });
    }
  }

  handleShowEditDueDate = async (id: number) => {
    const isAuthorized = await handleViewData("tasks")
    this.handleShowEditDueDateCallback(id,isAuthorized)
  }

  handleShowEditDueDateCallback = (id:number, isAuthorized:any)=>{
    if(isAuthorized.includes("EDIT")){
      const todoFilter = this.state.upcomingTasks.find((item: any) => item.id === id);
      const inputDateString = todoFilter?.dueDate;
      const parts = inputDateString?.split('/');
      const year = parseInt(parts?.[2] as string);
      const month = parseInt(parts?.[1] as string) - 1;
      const day = parseInt(parts?.[0] as string);
      const date = new Date(year, month, day + 1);
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      const monthName = months[date.getUTCMonth()];
      const formattedDate = `${dayOfWeek} ${monthName} ${date.getUTCDate()} ${date.getUTCFullYear()} 00:00:00 GMT+0000 (Coordinated Universal Time)`;
      if(!this.state.todoEditError) {
        this.setState({
          showDatePickerEdit: true,
          editDueDate: new Date(formattedDate.toString() + ' UTC'),
          editSelectedDueDateId: id
        });
      }
    } else {
      this.showOpenToastHandler()
     }
  }

  handleSelectDateEdit = (date: Date, id: number) => {
    this.setState({ editDueDate: date }, async () => {
      this.editTask(id);
      this.setState({ editTaskLoading: true });
      this.handleHideCalendarEdit();
    });
  }

  handleHideCalendarEdit = () => {
    this.setState({
      editSelectedDueDateId: null,
      showDatePickerEdit: false,
      editDueDate: null,
    });
  }

  handleShowEditUrgency = async (id: number) => {
    const isAuthorized = await handleViewData("tasks")
    this.handleShowEditUrgencyCallback(id,isAuthorized)
  }

  handleShowEditUrgencyCallback = (id:number, isAuthorized:any)=>{
    if(isAuthorized.includes("EDIT")){
      const todoFilter = this.state.upcomingTasks.find((item: any) => item.id === id);
      if(!this.state.todoEditError) {
      this.setState({
        showEditUrgency: true,
        editSelectedUrgency: id,
        editUrgency: todoFilter?.urgency
      });
    }
    } else {
      this.showOpenToastHandler()
     }
  }

  handleSelectEditUrgency = (data: UrgencyData, id: number) => {
    this.setState({
      editUrgency: data.title,
    }, async () => {
      this.editTask(id);
      this.setState({ editTaskLoading: true });
      this.handleClickEditUrgencyOutside();
    });
  }

  handleClickEditUrgencyOutside = () => {
    this.setState({
      editSelectedUrgency: null,
      showEditUrgency: false
    });
  }

  handleShowEditAssignee = async (id: number) => {
    const isAuthorized = await handleViewData("tasks")
    this.handleShowEditAssigneeCallback(id,isAuthorized)
  }

  handleShowEditAssigneeCallback = (id:number, isAuthorized:any)=>{
    if(isAuthorized.includes("EDIT")){
      const todoFilter = this.state.upcomingTasks.find((item: any) => item.id === id);
     if(!this.state.todoEditError) {
      this.setState({
        selectedEmployeeNameEdit: todoFilter?.assignedTo?.[0].name,
        editSelectedIdEmployeeId: todoFilter?.assignedTo?.[0].id as number,
        editSelectedIdEmployee: id,
        selectedEmployeeName: ""
      }, () => {
        this.setState({
          showAssigneeEdit: true,
          showAssigneeModalEdit: true,
        });
        this.getEmployeesList();
        this.setState({ getEmployeesListLoading: true });
      });
    }
    } else {
      this.showOpenToastHandler()
     }
  }

  handleChangeEmployeeNameEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedEmployeeNameEdit: e?.target.value }, () => {
      this.getEmployeesList();
    });
  }

  handleClickOutsideEmployeeEdit = () => {
    this.setState({
      showAssigneeEdit: false,
      showAssigneeModalEdit: false,
      editSelectedIdEmployee: null,
    });
  }

  handleSelectEmployeeEdit = (item: any) => {
    this.setState({
      selectedEmployeeEdit: item,
    }, () => {
      this.handleUpdateSelectedEmployee(item);
    });
  }

  handleUpdateSelectedEmployee = (item: Record<string, string>) => {
    setTimeout(async () => {
      this.editTask(this.state.editSelectedIdEmployee as number);
      this.setState({ editTaskLoading: true });
      this.setState({
        selectedEmployeeNameEdit: item.name,
        showAssigneeEdit: false,
        showAssigneeModalEdit: false,
        editSelectedIdEmployee: null,
      });
    }, 10);
  }

  handleViewAllCompletedTasks = () => {
    this.setState({ showCompletedTasks: !this.state.showCompletedTasks });
  }

  handleCheckboxCompleted = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { selectedCompletedId } = this.state;
    const isChecked = selectedCompletedId === id;

    const newSelectedId = isChecked ? null : id;
    this.setState({
      selectedCompletedId: newSelectedId
    }, () => this.handleEditTaskCallBack(e, newSelectedId as number));
  }

  handleEditTaskCallBack = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    this.completedToUpcomingTask(id);
    this.setState({ completedToUpcomingLoading: true });
  }

  handleArabicUrgency = (urgency: string) => {
    const { languageSelected } = this.state;
    let convertedUrgency;
    
    if(urgency === "عالي") {
      convertedUrgency = "High";
    } else if(urgency === "واسطة") {
      convertedUrgency = "Medium";
    } else if(urgency === "قليل") {
      convertedUrgency = "Low";
    }
    
    const urgencyData = languageSelected === "ar" ? convertedUrgency as string : urgency;
    return urgencyData;
  }

  completedToUpcomingTask = async (id: number) => {
    const header = {
      "token": await getStorageData(configJSON.authToken),
    }

    let formdata = new FormData();
    if(this.state.selectedCompletedId) {
      formdata.append("data[status]", "pending");
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completedToUpcomingTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskAPiEndPoint}/${id}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  findEmpUrl = (isEmployee: any,empId:any) => {
    const { selectedEmployeeName, selectedEmployeeNameEdit } = this.state;
    return isEmployee ? `${configJSON.getEmployee}/${empId}?lang=${this.state.languageSelected}` : `${configJSON.getAllEmployesAPiEndPoint}?query=${selectedEmployeeName || selectedEmployeeNameEdit}&lang=${this.state.languageSelected}`
  }

  getEmployeesList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllEmployeesApiCallId = requestMessage.messageId;
    const apiUrl =  this.findEmpUrl(this.state.loggedInUser,this.state.empId)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  addTask = async () => {
    const { todo, dueDate, urgency, selectedEmployee } = this.state;
    
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    formdata.append("data[task_name]", todo);
    formdata.append("data[employee_id]", selectedEmployee.id);
    formdata.append("data[due_date]", dueDate);
    formdata.append("data[urgency]", this.handleArabicUrgency(urgency).toLowerCase());

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskAPiEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addTaskAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getAllTasks = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskAPiEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteTask = async (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskAPiEndPoint}/${id}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  completeTask = async (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completeTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskAPiEndPoint}/${id}/mark_completed?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  editTask = async (id: number) => {
    const { editTodo, editDueDate, editUrgency, selectedEmployeeEdit } = this.state;
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    let formdata = new FormData();
    if(editTodo) {
      formdata.append("data[task_name]", editTodo);
    }
    if(selectedEmployeeEdit?.id) {
      formdata.append("data[employee_id]", selectedEmployeeEdit.id);
    }
    if(editDueDate) {
      formdata.append("data[due_date]", editDueDate);
    }
    if(editUrgency) {
      formdata.append("data[urgency]", this.handleArabicUrgency(editUrgency).toLowerCase());
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskAPiEndPoint}/${id}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End
}
