import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Container,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import {
  Theme,
  styled,
  withStyles
} from "@material-ui/core/styles";
import {
  settingsIcon,
  userIcon,
  likeIcon
} from "./assets";
import "../../landingpage/src/Style.css";
import { FreeTrialExpiredSideBar } from "./FreeTrialExpiredSideBar.web";
import Monthly from "../../landingpage/src/Monthly.web";
import Yearly from "../../landingpage/src/Yearly.web";
import StyleDialog from "../../../components/src/StyledDialog";
import { withSnackbar } from "../../../components/src/toastweb";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import DashboardSideBarController, {
  Props,
} from "./DashboardSideBarController.web";

// Customizable Area Start
export const StyledBox = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.up('lg')]: {
    marginRight: (innerProps) => innerProps.languageselected === "ar" ? "95px" : undefined,
    marginLeft: (innerProps) => innerProps.languageselected === "en" ? "60px" :undefined  
  }
}));

export const StyledDiv = styled('div')(({ theme }: any) => ({
  paddingBottom: "5rem",
  [theme.breakpoints.up('lg')]: {
    marginRight: (innerProps) => innerProps.languageselected === "ar" ? "0rem" : undefined,
    marginLeft: (innerProps) => innerProps.languageselected === "en" ? "3rem" :undefined  
  },
}));

export const StyledDialog = withStyles({
  root: {
    "& .MuiPaper-root": {
      padding: "1rem",
      width: "100%",
      maxWidth: "752px",
      minHeight: "251px",
      borderRadius: "8px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    }
  }
})(Dialog);


export const StyledDialogTitle = withStyles((theme) => ({
  root: {
     padding:"16px",
    "& .MuiTypography-h6": {
      color: CONSTANTS.color.lightBrown,
      fontSize: "1.8741rem",
      fontWeight: CONSTANTS.fontWeight.fontWeight500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.6867rem !important"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.4375rem !important",
       
      },
      
    }
  }
}))(DialogTitle);
export const StyledDialogContent = withStyles((theme) => ({
  root: {
     padding:"8px 16px",
  }
}))(DialogContent);

export const StyledDialogActions = withStyles((theme) => ({
  root: {
     padding:"8px 16px",
  }
}))(DialogActions);

export const StyledDialogContentText = withStyles((theme) => ({
  root: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    fontSize: "1.52rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4286rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem !important"
    },
  }
}))(DialogContentText);
// Customizable Area End

export class FreeTrialExpired extends DashboardSideBarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes, t } = this.props;
    return (
      <>
        <FreeTrialExpiredSideBar {...this.props} navMenuItem={this.props.t('dashboardNavbar.pricingPlans')} active={false} active1={false} active2={false} />
        <StyledBox languageselected={this.state.languageSelected}>
          <Box style={webStyle.boxContainer}>
            <Box style={webStyle.alertContainer} className={classes?.alertContainer}>
              <Typography style={webStyle.alertText} variant="body1">
              {t("dashboard.Yourfreetrialhasexpired")}
              </Typography>
            </Box>
            <Box style={webStyle.userDetailsOuterContainer} className={classes?.userDetailsOuterContainer}>
              <Box style={webStyle.userDetailsContainer}>
                <Typography style={webStyle.userName} variant="body1">
                {t("dashboard.Mr.ABC")}
                </Typography>
                <span style={webStyle.verticalHr}></span>
                <Typography style={{...webStyle.userName, color: CONSTANTS.color.charcoalGrey}} variant="body1">
                {t("dashboard.ChooseaPlan")}
                </Typography>
              </Box>
              <Box style={{cursor: "pointer"}} onClick={this.handleModalSettings}>
                <img
                  style={webStyle.settingsIcon}
                  src={settingsIcon}
                  alt=""
                />
              </Box>
            </Box>
            {this.state.showSettingsModal ? (
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Box style={{...webStyle.settingsModal, [this.state.languageSelected === 'ar' ? 'marginLeft' : 'marginRight']: "2.5rem",[this.state.languageSelected === 'ar' ? 'left' : 'right']: "0px"}}>
                  <Box style={webStyle.settingsUserDetails}>
                    <Box style={webStyle.userProfileContainer}>
                      <img
                        style={webStyle.userIcon}
                        src={userIcon}
                        alt=""
                      />
                      <Box>
                        <Typography style={webStyle.userNameSettings} variant="body1">
                        {t("dashboard.ABC")}
                        </Typography>
                        <Typography style={{...webStyle.userNameSettings, fontSize: "14px"}} component={"div"}>
                          abclamsa@gmail.com
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={webStyle.paddingStyle}>
                      <Typography style={webStyle.settingsList} variant="body2">
                      {t("dashboard.CompanyInformation")}
                      </Typography>
                      <span style={{...webStyle.horizontalHr, ...webStyle.marginStyle}}></span>
                      <>
                        <Typography style={webStyle.settingsList} variant="body2">
                        {t("dashboard.ProductsandServices")}
                        </Typography>
                        <span style={{...webStyle.horizontalHr, ...webStyle.marginStyle}}></span>
                        <Typography style={webStyle.settingsList} variant="body2">
                        {t("dashboard.Billing")}
                        </Typography>
                        <span style={{...webStyle.horizontalHr, ...webStyle.marginStyle}}></span>
                        <Typography style={webStyle.settingsList} variant="body2">
                        {t("dashboard.Notification")}
                        </Typography>
                      </>
                      <span style={{...webStyle.horizontalHr, margin: "20px 0px 15px"}}></span>
                      <Typography style={webStyle.settingsList} variant="body2">
                      {t("dashboard.LogOut")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ClickAwayListener>
            ) : null}
          </Box>
          <Container maxWidth={false}>
            <Grid container spacing={2} style={webStyle.pricingPlanContainer}>
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="h2" style={webStyle.pricingPlan} className={`pricingPlan ${classes?.pricingPlan}`}>
                   {t("dashboardNavbar.pricingPlans")}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="body1" style={webStyle.pricingBody}>
                {t("dashboard.LoremIpsumissimplydummiedtext")}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Box style={webStyle.planContainer} className={classes?.planContainer}>
                  <Box
                    style={
                      this.state.activeTab === "Monthly" ?
                      {...webStyle.btnContainer, backgroundColor: CONSTANTS.color.thickBlue} :
                      webStyle.btnContainer
                    }
                    className={classes?.btnContainer}
                    onClick={this.handleClickMonthly}
                  >
                    <Typography
                      style={
                        this.state.activeTab === "Yearly" ?
                        {...webStyle.btnText, color: CONSTANTS.color.thickBlue} :
                        webStyle.btnText
                      }
                      variant="subtitle1"
                      className="buttonText"
                    >
                      {t("dashboard.monthly")}
                    </Typography>
                  </Box>
                  <Box
                    style={
                      this.state.activeTab === "Yearly" ?
                      {...webStyle.btnContainer, backgroundColor: CONSTANTS.color.thickBlue} :
                      webStyle.btnContainer
                    }
                    className={classes?.btnContainer}
                    onClick={this.handleClickYearly}
                  >
                    <Typography
                      style={
                        this.state.activeTab === "Monthly" ?
                        {...webStyle.btnText, color: CONSTANTS.color.coolDarkGrey} :
                        webStyle.btnText
                      }
                      variant="subtitle1"
                      className="buttonText"
                    >
                      {t("dashboard.yearly")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Box style={webStyle.likeOuterContainer} className={classes?.likeOuterContainer}>
                  <Box style={webStyle.likeIconContainer}>
                    <img style={webStyle.likeIconImg} src={likeIcon} alt="" />
                    <Typography component={"div"} style={webStyle.getFreeText}>
                      {t("dashboard.Get2MonthsFreeonYearlyPlans")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <StyledDiv languageselected={this.state.languageSelected}>
              {this.state.activeTab === "Monthly" ? (
                <Monthly
                location={undefined} {...this.props}
                disabled={true}
                  route={"FreeTrial"}
                  planModal={this.state.planModal}
                  activePlan={this.state.activePlan}
                  planType={this.state.planType}
                  oldProps={this} t={(key: string) => key} />
              ) : (
                <Yearly
                  location={undefined} {...this.props}
                  disabled={true}
                  route={"FreeTrial"}
                  planModal={this.state.planModal}
                  activePlan={this.state.activePlan}
                  planType={this.state.planType}
                  oldProps={this} t={(key: string) => key} />
              )}
            </StyledDiv>
            <StyleDialog 
           planModal={this.state.planModal}
           handleClosePlanModal={this.handleClosePlanModal}
           planType={this.state.planType}
           handleClickProceedPlan={this.handleClickProceedPlan}/>
          </Container>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  alertContainer: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem !important",
      paddingRight: "1rem !important",
    }
  },
  userDetailsOuterContainer: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem !important",
    }
  },
  pricingPlan : {
    "@media(max-width:412px)": {
      fontSize: "24px"
    }
  },
  planContainer: {
    "@media(max-width:576px)": {
      height: "56px !important",
    },
    "@media(max-width:414px)": {
      height: "53px !important",
      width: "327px !important"
    }
  }, 
  btnContainer : {
    "@media(max-width:414px)": {
      minHeight: "53px !important"
    }
  },
  likeOuterContainer : {
    "@media(max-width:412px)": {
      width: "328px !important",
      height: "25px !important",
      marginTop: "0rem !important"
    }
  }
});

const webStyle = {
  boxContainer: {
    marginTop: "6rem",
  },
  alertContainer: {
    width: "100%",
    minHeight: "89px",
    backgroundColor: CONSTANTS.color.darkBlue,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "4rem",
  },
  alertText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  userDetailsOuterContainer: {
    width: "100%",
    minHeight: "54px",
    backgroundColor: CONSTANTS.color.silverMist,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "4rem",
    paddingRight: "2rem",
  },
  userDetailsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2rem"
  },
  userName: {
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  verticalHr: {
    width: "1px",
    height: "40px",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  settingsIcon: {
    width: "18px",
    height: "20px",
  },
  settingsModal: {
    width: "100%",
    maxWidth: "251px",
    minHeight: "350px",
    borderRadius: "7px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(0,0,0,0.05)",
    float: "right" as const,
    marginTop: "-0.8rem",
    position: "absolute" as const,
    zIndex: 1,
  },
  settingsUserDetails: {
    width: "251px",
    height: "101px",
    borderRadius: "7px 7px 0 0",
    backgroundColor: CONSTANTS.color.pebbleGrey,
  },
  userIcon: {
    width: "41px",
    height: "41px",
  },
  userProfileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.5rem",
    gap: "10px"
  },
  userNameSettings: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  paddingStyle: {
    padding: "2rem"
  },
  marginStyle: {
    margin: "10px 0px 15px"
  },
  settingsList: {
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    cursor: "pointer",
  },
  horizontalHr: {
    display: "flex",
    margin: "10px 0",
    height: "1px",
    border: "1px solid rgba(0,0,0,0.1)",
  },
  pricingPlanContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1.5rem"
  },
  pricingPlan: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    textAlign: "center" as const,
  },
  pricingBody:{
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    textAlign: "center" as const,
    maxWidth: "500px",
    width: "100%"
  },
  planContainer: {
    width: "440px",
    maxWidth: "100%",
    height: "70px",
    borderRadius: "35px",
    background: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(163,177,198,0.25)",
    marginTop: "1rem",
    display: "flex",
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.white,
    cursor: 'pointer',
    borderRadius: "35px",
    minHeight: "70px",
    maxWidth: "220px",
    width: "100%",
  },
  btnText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  likeOuterContainer: {
    width: "434px",
    height: "34px",
    borderRadius: "12px",
    backgroundColor: "rgba(28,24,175,0.15)",
    maxWidth: "100%",
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  likeIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  likeIconImg: {
    width: "20px",
    height: "20px",
  },
  getFreeText: {
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
    marginLeft: "5px",
  },

  buttonContainerCancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "200px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.lightGrey}`,
    marginLeft: "1.5rem"
  },
  buttonTextCancel: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  closeIcon: {
    position: "absolute" as const,
    left: "95%",
    top: "12%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer"
  },
}

export default withTranslation()(withStyles(styles)(withSnackbar(FreeTrialExpired)));
// Customizable Area End