export const lamsaLogo = require("../assets/lamsaLogo.png");
export const navbarStyle = require("../assets/navbarStyle.png");
export const heroImage = require("../assets/heroImage.png");
export const dashboardImage = require("../assets/dashboardImage.png");
export const backIcon = require("../assets/backIcon.png");
export const frontIcon = require("../assets/frontIcon.png");
export const personImage = require("../assets/personImage.png");
export const profileBg = require("../assets/profileBg.png");
export const quoteIcon = require("../assets/quoteIcon.png");
export const person1 = require("../assets/person1.png");
export const person2 = require("../assets/person2.png");
export const person3 = require("../assets/person3.png");
export const icon1 = require("../assets/icon1.png");
export const icon2 = require("../assets/icon2.png");
export const icon3 = require("../assets/icon3.png");
export const icon4 = require("../assets/icon4.png");
export const icon5 = require("../assets/icon5.png");
export const icon6 = require("../assets/icon6.png");
export const icon7 = require("../assets/icon7.png");
export const instagramIcon = require("../assets/instagramIcon.png");
export const linkedinIcon = require("../assets/linkedinIcon.png");
export const twitterIcon = require("../assets/twitterIcon.png");
export const xLogo = require("../assets/xLogo.png");
export const playIcon = require("../assets/playIcon.png");
export const playBg = require("../assets/playBg.png");
export const playRootBg = require("../assets/playRootBg.png");
export const bg1 = require("../assets/bg1.png");
export const bg2 = require("../assets/bg2.png");
export const bg3 = require("../assets/bg3.png");
export const bg4 = require("../assets/bg4.png");
export const lamsaLogoWhite = require("../assets/lamsaLogoWhite.png");
export const likeIcon = require("../assets/likeIcon.png");
export const activeCheck = require("../assets/activeCheck.png");
export const inactiveCheck = require("../assets/inactiveCheck.png");
export const tickIcon = require("../assets/tickIcon.png");
export const tickFilled = require("../assets/tickFilled.png");
export const tickEmpty = require("../assets/tickEmpty.png");
export const contactUsImage = require("../assets/contactUsImage.png");
export const contactUsDropDown = require("../assets/contactUsDropDown.png");
export const contactUsEmptyBox = require("../assets/contactUsEmptyBox.png");
export const contactUsMail = require("../assets/contactUsMail.png");
export const contactUsMap = require("../assets/contactUsMap.png");
export const contactUsPhone = require("../assets/contactUsPhone.png");
export const contactUsTick = require("../assets/contactUsTick.png");
export const whyLamsaHeroImage = require("../assets/whyLamsaHeroImage.png");
export const questionMarkIcon = require("../assets/questionMarkIcon.png");
export const quoteIconLarge = require("../assets/quoteIconLarge.png");
export const scheduleJobIcon = require("../assets/scheduleJobIcon.png");
export const invoiceIcon = require("../assets/invoiceIcon.png");
export const whyLamsaCalendarImage = require("../assets/whyLamsaCalendarImage.png");
export const whyLamsaDashboardImage = require("../assets/whyLamsaDashboardImage.png");
export const whyLamsaSignupImage = require("../assets/whyLamsaSignupImage.png");
export const hvacIcon = require("../assets/hvacIcon.png");
export const plumbingIcon = require("../assets/plumbingIcon.png");
export const electricalIcon = require("../assets/electricalIcon.png");
export const handyManIcon = require("../assets/handyManIcon.png");
export const paintingIcon = require("../assets/paintingIcon.png");
export const homeCleaningIcon = require("../assets/homeCleaningIcon.png");
export const mobileCarCleaningIcon = require("../assets/mobileCarCleaningIcon.png");
export const windowCleaningIcon = require("../assets/windowCleaningIcon.png");
export const flooringIcon = require("../assets/flooringIcon.png");
export const applianceRepairIcon = require("../assets/applianceRepairIcon.png");
export const pestControlIcon = require("../assets/pestControlIcon.png");
export const landScapingLawnIcon = require("../assets/landScapingLawnIcon.png");
export const poolSpaServicesIcon = require("../assets/poolSpaServicesIcon.png");
export const muchMoreIcon = require("../assets/muchMoreIcon.png");
export const globeIcon = require("../assets/globeIcon.png");
export const selectedIcon = require("../assets/selectedIcon.png");
export const userIcon = require("../assets/userIcon.png");
export const navbarImage = require("../assets/navbarImage.png");