import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography,
  Dialog,
  CircularProgress,
  Backdrop,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,InputAdornment,IconButton
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import { searchIcon, dropDownSelectIcon } from "./assets";
import "./CustomerSites.css";
import { GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";
import {
  StyledGrid,
  StyledTypography,
  StyledTextFieldAutoComplete,
  StyledTextFields,
} from "./CreateCustomer.web";
import { DeleteDialog } from "./DeleteDialog.web";
import { deleteTaxIcon, editProductsServicesIcon } from "../../Settings/src/assets";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { CustStyledTableContainer, StyledTableRow } from "./Customers.web";
import { CONSTANTS, } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// Customizable Area End

import CustomerSitesController, { Props } from "./CustomerSitesController.web";
import { getAlignment } from "./utility.web";

// Customizable Area Start

export const SitesStyledDialog = withStyles({
  root: {
    "& .MuiPaper-root": {
      padding: "1rem",
      width: "100%",
      maxWidth: "1120px",
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
      position: "relative",
    },
  },
})(Dialog);

export const SitesStyledBox = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "2.5rem 0.5rem 0rem !important",
  },
}));

export const SitesStyledButtons = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("md")]: {
    padding: "4rem 0px 2rem 0.5rem !important",
  },
}));

export const SitesStyledGridLocation = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0.5rem !important",
    paddingRight: "0.5rem !important",
  },
}));

// Customizable Area End

export class CustomerSites extends CustomerSitesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  handleSelectSite = (item: any) => this.handleSelectedSite(item);

  sortData = (prop: any) => this.sortByProperty(prop);

  renderActionButton = (webStyle: any) => {
    const { classes ,t } = this.props;
    return !this.state.showActionBtn ? (
      <Button
        className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerSave}`}
        onClick={this.handleSaveSiteInformation}
      >
        <Typography variant="subtitle2" style={webStyle.SitesbuttonTextSave}>{t("Calendar.button.save")}</Typography>
      </Button>
    ) : (
      <>
        {!this.state.showUpdateButton ? (
          <Button
            className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerSave}`}
            onClick={this.handleEditButton}
          >
            <Typography variant="subtitle2" style={webStyle.SitesbuttonTextSave}>{t("customers.formData.edit")}</Typography>
          </Button>
        ) : (
          <Button
            className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerSave}`}
            onClick={this.handleUpdateButton}
          >
            <Typography variant="subtitle2" style={webStyle.SitesbuttonTextSave}>{t("customers.formData.update")}</Typography>
          </Button>
        )}
      </>
    );
  };

  renderCircularProgress = () => {
    return this.state.getCustomerSiteLoading ? (
      <Backdrop
        style={{
          zIndex: 1000 + 1,
          color: "#05037a",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={this.state.getCustomerSiteLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : null;
  };
  deleteDialogue() {
    const { t } = this.props;
    return <>
      {this.state.deleteDialogOpen &&
        <DeleteDialog
          {...this.props}
          data-test-id="delete-dialog"
          title={`${t('customers.deleteModal.title')}`}
          description={`${t('customers.deleteModal.subtitle')}`}
          deleteDialogOpen={this.state.deleteDialogOpen}
          handleClickOpenDialog={this.handleClickOpenDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleClickProceedDelete={this.handleClickProceedDelete}
          status='site'
        />
      }
    </>
  }
  renderTableHeadings = (classes: any) => {
    const {t} = this.props
    return (
      <Table style={{ minWidth: 650 }}>
        <TableHead>
          <TableRow style={{ width: "100%" }}>
            {[
              { key: "site_name", title: "Site Name" },
              { key: "site_address", title: "Site Address" },
            ].map((rowItem: any, index: any) => {
              return (
                <>
                  <TableCell
                    key={rowItem.key}
                    align="right"
                    style={webStyle.sitestableCellHead}
                    className={classes.sitestableCellHead}
                    onClick={this.sortData.bind(this, rowItem.key)}
                    data-test-id="handleSort"
                  >
                    <Box
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "2px",
                      }}
                    >
                      <Box
                        style={
                          this.state.sortingProperty === rowItem.key
                            ? {
                              ...webStyle.sitestableHeadSpan,
                              backgroundColor: "rgba(232,232,232,0.4)",
                              marginLeft: index === 0 ? 0 : "auto",
                            }
                            : {
                              ...webStyle.sitestableHeadSpan,
                              marginLeft: index === 0 ? 0 : "auto",
                            }
                        }
                      >
                        <Typography
                          component={"span"}
                          variant="body1"
                        >
                            {t(`customers.siteHeadings.${index}.title`)}
                        </Typography>
                        <img
                          style={
                            this.state.sortingProperty === rowItem.key &&
                              this.state.sortingOrder === "asc"
                              ? {
                                ...webStyle.sitesdropDownSelectIconTable,
                                transform: "rotate(180deg)",
                              }
                              : webStyle.sitesdropDownSelectIconTable
                          }
                          src={dropDownSelectIcon}
                          className={classes.RequestRequestdropDownSelectIcon}
                          alt=""
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </>
              );
            })}
          </TableRow>
        </TableHead>
      </Table>
    );
  };

  renderTableBody = () => {
    const {classes,t}=this.props
    return (
      <Table style={{ minWidth: 650 }}>
        <div
          style={
            this.state.siteInformationData === 0
              ? {
                height: "400px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }
              : { height: "400px", overflow: "auto" }
          }
        >
          {" "}
          <Table style={{ tableLayout: "fixed" }}>
            <TableBody>
              {this.state.siteInformationData&&this.state.siteInformationData.length > 0 &&
                this.state.siteInformationData.map((rowItem: any) => {
                  return (
                    <>
                      <StyledTableRow
                        style={{
                          cursor: "pointer",
                          width: "20%",
                        }}
                        data-testid={`handleSelect_${rowItem.id}`}
                        key={rowItem.id}
                        onClick={this.handleSelectSite.bind(this, rowItem)}
                      >
                        <TableCell
                         align={getAlignment(this.state.lang)}
                          style={webStyle.sitestableCellBody}
                          className={classes.sitestableCellHead}
                        >
                          {rowItem?.site_name}
                        </TableCell>

                        <TableCell
                           align={getAlignment(this.state.lang)}
                          style={rowItem.id === this.state.selectedSite ?
                            webStyle.sitestableCellBody1 : webStyle.sitestableCellBody
                          }
                          className={classes.sitestableCellHead}
                        >
                          <Box display={"flex"} gridGap={"1rem"} alignItems={"center"} marginRight={"70px"} justifyContent={this.state.lang=="en"  ?"flex-end":"flex-start"}>
                          {rowItem?.site_address}
                          {rowItem.id === this.state.selectedSite ? (
                              <Box style={webStyle.editDeleteContainer}>
                                <Box
                                  style={webStyle.editBoxContainer}
                                  className={`${classes.deleteBoxStyle} ${classes.paddingStyle}`}
                                  onClick={this.handleEdit.bind(this, rowItem)}
                                >
                                  <img
                                    style={webStyle.deleteTaxIcon}
                                    className={classes.deleteTaxIcon}
                                    src={editProductsServicesIcon}
                                    alt=""
                                  />
                                </Box>
                                <Box
                                  style={webStyle.deleteBoxContainer}
                                  className={`${classes.deleteBoxStyle} ${classes.paddingStyle}`}
                                  onClick={this.handleClickOpenDialog}
                                >
                                  <img
                                    style={webStyle.deleteTaxIcon}
                                    className={classes.deleteTaxIcon}
                                    src={deleteTaxIcon}
                                    alt=""
                                  />
                                </Box>
                              </Box>
                        ) : null}
                            
                             { this.deleteDialogue()}
                          
                          </Box>
                        </TableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
              {!this.state.getCustomerSiteLoading &&
                this.state.siteInformationData &&
                this.state.siteInformationData.length === 0 && (
                  <Box style={webStyle.sitesnoRequestContainer}>
                    <Typography style={webStyle.noSitesText} variant="subtitle1">
                    {t("customers.noDataFound")}
                    </Typography>
                  </Box>
                )}
            </TableBody>
          </Table>
        </div>
      </Table>
    );
  };

  handleKeyPress = (e: any) => this.handleKeyPressSite(e);

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const disabledFieldStyle = !this.state.showFields ? classes.fieldDisable : ""
    const { site_name, email, phone, state, city, zip, site_address, country } =
      this.state.siteInformationFormData;
    return (
      <>
        <Box>
            <Box className={classes.SitessearchContainer}>
              <Box>
                <img style={{...webStyle.SitessearchIcon,marginTop:"6px"}} src={searchIcon} alt="" />
              </Box>
              <InputBase
                className={classes.sitestableCellHead}
                data-test-id="handleChangeSearchCustomerSite"
                style={webStyle.SitesinputBase}
                placeholder={t("customers.searchSite")}
                inputProps={{ "aria-label": "search" }}
                value={this.state.searchQuery}
                onChange={this.handleChangeSearchCustomerSite}
              />
            </Box>
          <Box style={webStyle.SitescustomersContainer}>
            <CustStyledTableContainer style={{ position: "sticky", top: 0 }}>
              {this.renderTableHeadings(classes)}
              <span></span>
              {this.renderTableBody()}
            </CustStyledTableContainer>
          </Box>
          <Box style={webStyle.SitesbuttonsContainer}>
            <CreateQuote onClick={this.handleAddNewSite}>
              <Typography
                style={webStyle.SitesbuttonTextCreateSupplier}

                variant="subtitle2"
              >
                {t("customers.addSite")}
              </Typography>
            </CreateQuote>
          </Box>
          <SitesStyledDialog
            open={this.state.siteDialogOpen}
            onClose={this.handleCloseSiteDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <Grid container>
              <Grid item lg={8} md={12} xs={12} >
                <SitesStyledBox style={webStyle.SitesformContainer}>
                  <StyledTypography style={webStyle.SitespersonalInfoText}>
                  {t("customers.siteDialog.siteInformation")}
                  </StyledTypography>
                  <Grid item lg={12} md={12} xs={12}>
                    <StyledGrid container spacing={2}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={webStyle.SitesgridItem}
                      >
                        <Typography
                          style={webStyle.SitescontactForm}
                          variant="body2"
                          className={classes.customerInputSubTitles}
                          gutterBottom
                        >
                            {t("customers.siteDialog.name")} <span style={webStyle.redColorSites}>*</span>
                        </Typography>
                        <StyledTextFields
                          disabled={!this.state.showFields}
                          className={disabledFieldStyle}
                          data-test-id="form-data"
                          data-testid='siteName'
                          fullWidth
                          variant="outlined"
                          name="site_name"
                          value={site_name}
                          onChange={this.handleChangeFormData}
                          error={Boolean(this.state.isErrorFormData.name)}
                        />
                        <Typography
                          variant="body2"
                          style={webStyle.SiteserrorMessage}
                        >
                          {this.state.isErrorFormData.name}
                        </Typography>
                      </Grid>
                    </StyledGrid>
                    <StyledGrid container spacing={3}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={webStyle.SitesgridItems}
                      >
                        <Typography
                          className={classes.customerInputSubTitles}
                          style={webStyle.SitescontactForm}
                          variant="body2"
                          gutterBottom
                        >
                          {t("BoAuth.emailLabel")}
                        </Typography>
                        <StyledTextFields
                          disabled
                          className={classes.fieldDisable }
                          data-test-id="form-data"
                          fullWidth
                          variant="outlined"
                          name="email"
                          value={email}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={webStyle.SitesgridItems}
                      >
                        <Typography
                          style={webStyle.SitescontactForm}
                          variant="body2"
                          gutterBottom
                          className={classes.customerInputSubTitles}

                        >
                         {t("landingPage.ContactUs.phoneNumber")}
                        </Typography>
                        <StyledTextFields
                          disabled
                          className={classes.fieldDisable }
                          data-test-id="form-data"
                          fullWidth
                          variant="outlined"
                          name="phone"
                          value={phone}
                        />
                      </Grid>
                    </StyledGrid>
                  </Grid>
                </SitesStyledBox>
                <SitesStyledBox style={webStyle.SitesaddressFormContainer}>
                  <Grid item lg={12} md={12} xs={12}>
                    <StyledGrid container spacing={3}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={{
                          ...webStyle.SitesgridItem,
                          overflow: "auto",
                          position: "relative",
                        }}
                      >
                        <Typography
                          className={classes.customerInputSubTitles}

                          style={webStyle.SitescontactForm}
                          variant="body2"
                          gutterBottom
                        >
                         {t("customers.siteDialog.address")}<span style={webStyle.redColorSites}>*</span>
                        </Typography>

                       
                          <StyledTextFieldAutoComplete
                            disabled={!this.state.showFields}
                            fullWidth
                            className={disabledFieldStyle}
                            variant="outlined"
                            name="site_address"
                            value={site_address}
                            onChange={this.handleChangeFormData}
                            error={Boolean(
                              this.state.isErrorFormData.site_address
                            )}
                           
                          />
                      
                        <Typography
                          variant="body2"
                          style={webStyle.SiteserrorMessage}
                        >
                          {this.state.isErrorFormData.site_address}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={webStyle.SitesgridItem}
                      >
                        <Typography
                          className={classes.customerInputSubTitles}

                          style={webStyle.SitescontactForm}
                          variant="body2"
                          gutterBottom
                        >
                           {t("customers.siteDialog.state")}
                        </Typography>
                        <StyledTextFields
                          disabled={!this.state.showFields}
                          data-test-id="form-data"
                          fullWidth
                          variant="outlined"
                          className={disabledFieldStyle}
                          name="state"
                          value={state}
                          error={Boolean(this.state.isErrorFormData.state)}
                          onChange={this.handleChangeFormData}
                        />
                        <Typography
                          variant="body2"
                          style={webStyle.SiteserrorMessage}
                        >
                          {this.state.isErrorFormData.state}
                        </Typography>
                      </Grid>
                    </StyledGrid>
                    <StyledGrid container spacing={3}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={webStyle.SitesgridItems}
                      >
                        <Typography
                          className={classes.customerInputSubTitles}

                          style={webStyle.SitescontactForm}
                          variant="body2"
                          gutterBottom
                        >
                           {t("customers.siteDialog.city")}
                        </Typography>
                        <StyledTextFields
                          disabled={!this.state.showFields}
                          data-test-id="form-data"
                          fullWidth
                          variant="outlined"
                          name="city"
                          className={disabledFieldStyle}
                          error={Boolean(this.state.isErrorFormData.city)}
                          value={city}
                          onChange={this.handleChangeFormData}
                        />
                        <Typography
                          variant="body2"
                          style={webStyle.SiteserrorMessage}
                        >
                          {this.state.isErrorFormData.city}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={webStyle.SitesgridItems}
                      >
                        <Typography
                          className={classes.customerInputSubTitles}

                          style={webStyle.SitescontactForm}
                          variant="body2"
                          gutterBottom
                        >
                           {t("profileSetup.country")}
                        </Typography>
                        <StyledTextFields
                          disabled={!this.state.showFields}
                          data-test-id="form-data"
                          fullWidth
                          variant="outlined"
                          name="country"
                          error={Boolean(this.state.isErrorFormData.country)}
                          value={country}
                          className={disabledFieldStyle}
                          onChange={this.handleChangeFormData}
                        />
                        <Typography
                          variant="body2"
                          style={webStyle.SiteserrorMessage}
                        >
                          {this.state.isErrorFormData.country}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        style={webStyle.SitesgridItems}
                      >
                        <Typography
                          className={classes.customerInputSubTitles}

                          style={webStyle.SitescontactForm}
                          variant="body2"
                          gutterBottom
                        >
                        {t("customers.siteDialog.zipCode")}
                        </Typography>
                        <StyledTextFields
                          disabled={!this.state.showFields}
                          data-test-id="form-data"
                          fullWidth
                          variant="outlined"
                          name="zip"
                          error={Boolean(this.state.isErrorFormData.zip)}
                          value={zip}
                          onChange={this.handleChangeFormData}
                          className={disabledFieldStyle}
                        />
                        <Typography
                          variant="body2"
                          style={webStyle.SiteserrorMessage}
                        >
                          {this.state.isErrorFormData.zip}
                        </Typography>
                      </Grid>
                    </StyledGrid>
                  </Grid>
                </SitesStyledBox>
              </Grid>
              <SitesStyledGridLocation
                item
                lg={4}
                md={12}
                xs={12}
                style={{...webStyle.SitesrightContent,direction:localStorage.getItem('lang')==='ar'?'ltr':'rtl'}}
              >
                <StyledTypography
                  style={{
                    ...webStyle.SitespersonalInfoText,
                    lineHeight: "normal",
                    margin: "-2rem 0 1rem 0",
                  }}
                >
                 {t("customers.siteDialog.location")}
                </StyledTypography>
              
                <GoogleMap
                  mapContainerStyle={webStyle.SitesmapContainerStyle}
                  center={this.state.currentPosition1 || this.state.center}
                  zoom={5}
                  onLoad={(map: any) => {
                    this.mapRef.current = map;
                  }}
                  options={{
                    disableDefaultUI: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    draggable: this.state.showFields ? true :false,
                    zoomControl: this.state.showFields ? true :false,
                  }}
                >
                  <div className={!this.state.showFields ? `
                     ${classes.fieldDisable} ${classes.autoComplete} ${classes.autoCompletedetails}`:
                     `${classes.autoComplete} ${classes.autoCompletedetails} ${classes.whiteBg}`
                  }>
                    <Box>
                      <img
                        style={webStyle.sitessearchIcon}
                        src={searchIcon}
                        alt=""
                      />
                    </Box>
                    <Autocomplete
                      onPlaceChanged={this.onPlaceChangedSite}
                      onLoad={this.onLoadSite}
                    >
                      <Box>
                        <InputStyle
            
                          fullWidth
                          name="autoCompleteInput"
                          disabled={!this.state.showFields}
                          className={!this.state.showFields ? `
                            ${classes.fieldDisable} ${classes.autoCompleteInput}`:
                            `${classes.autoCompleteInput} ${classes.whiteBg}`
                          }
                          value={this.state.autoCompleteInput}
                          onChange={this.handleChangeFormDataInput}
                          onKeyPress={this.handleKeyPress}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton  className="buttonArrow" edge="end">
                              {localStorage.getItem("lang")==='ar'?<ArrowBackIcon />:<ArrowForwardIcon />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        
                      </Box>

                    </Autocomplete>
                  </div>
                  {this.state.selectedPlace && (
                    <Marker
                      position={this.state.selectedPlace}
                      draggable={this.state.showFields ? true:false} 
                      onDragEnd={this.handleMarkerDragSites}
                    />
                  )}
                  {!this.state.selectedPlace && this.state.currentPosition1 && (
                    <Marker 
                      position={this.state.currentPosition1}
                      draggable={this.state.showFields ? true:false} 
                      onDragEnd={this.handleMarkerDragSites}
                    />
                  )}
                </GoogleMap>
              
              </SitesStyledGridLocation>
            </Grid>

            <SitesStyledButtons
              style={{
                ...webStyle.SitesbuttonsContainer,
                padding: "2rem 0px 0rem 1.5rem",
                marginBottom: "1rem",
              }}
            >
              <Box style={{ display: "flex", width: "100%" }} className={classes.siteCancelBtnLayout} >
                <Button
                  className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerCancel}`}
                  onClick={this.handleCloseSiteDialog}
                >
                  <Typography variant="subtitle2" style={webStyle.SitesbuttonTextCancel}>
                  {t("Calendar.button.cancel")}
                  </Typography>
                </Button>
              </Box>
              {this.renderActionButton(webStyle)}
            </SitesStyledButtons>
          </SitesStyledDialog>
        </Box>
        {this.renderCircularProgress()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
 
});

const webStyle = {
  editDeleteContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  editBoxContainer: {
    maxWidth: "87px",
    minHeight: "103px",
    backgroundColor: CONSTANTS.color.softBlue,
    alignContent:"center",
    padding:"20px"
  },
  deleteBoxContainer: {
    maxWidth: "87px",
    minHeight: "103px",
    backgroundColor: CONSTANTS.color.pastelPink,
    alignContent:"center",
    padding:"20px"
  },
  deleteTaxIcon: {
    width: "39px",
    height: "39px",
  },
  SitesmapContainerStyle: {
    width: '100%',
    maxWidth: "370px",
    height: "370px",
    borderRadius: "10px",
    position:"relative" as const
  },
  SitessearchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  SitesinputBase: {
    width: "100%",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "16px",
    color: CONSTANTS.color.grey
  },
  SitescustomersContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "400px",
  },
  SitesdropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
  },
  Siteshr: {
    display: "flex",
    height: "1px",
    border: "0.5px solid rgb(222 212 212)",
    margin: "0 2.6rem 0rem 3.8rem",
  },
  SitestableContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2rem 4rem 1.5rem",
  },
  SitestableContainerBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 4rem",
    minHeight: "61px",
    cursor: "pointer",
  },
  SitesdeleteBoxContainer: {
    float: "right" as "right",
    marginTop: "-3.8rem",
  },
  SitesdeleteIconContainer: {
    maxWidth: "79px",
    width:"100%",
    height: "61px",
    backgroundColor: "rgb(253 188 188)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },
  SitesdeleteIcon: {
    width: "24px",
    height: "24px",
  },
  SitestableHeadText: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  SitesbuttonsContainer: {
    padding: "4rem 0px 4rem 1.5rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  SitesbuttonTextCreateSupplier: {
    color: "#ffffff",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  SitesformContainer: {
    padding: "2.5rem 1.5rem 2rem",
  },
  SitesaddressFormContainer: {
    padding: "0.5rem 1.5rem 2rem",
  },
  SitespersonalInfoText: {
    color: "#34313a",
    fontWeight: 500,
    lineHeight: "20px",
  },
  SitesgridItem: {
    marginTop: "1.5rem",
  },
  SitesgridItems: {
    marginTop: "1rem",
  },
  SitescontactForm: {
    color: "#999999",
    fontWeight: 400,
    marginBottom: "0.8rem",
    lineHeight: "16px",
  },
  SiteserrorMessage: {
    marginTop: "5px",
    color: "red",

    fontWeight: 500,
  },
  SitesrightContent: {
    paddingTop: "4rem",
  },
  SitesbuttonTextCancel: {
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  SitesbuttonTextSave: {
    color: "#ffffff",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  SitesdropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)",
  },
  noSitesText: {
    color: "#535353",
    fontWeight: 500,
    textAlign: "center" as const,
    marginTop: "170px",
  },
  sitestableCellHead: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: 400,
    width: "20%",
  },
  sitestableCellBody: {
    fontSize: "1.1667rem",
    fontWeight: 400,
    borderBottom: "none",
    paddingTop: 0,
    width: "20%",
    padding:"0.8rem"
  },
  sitestableCellBody1: {
    fontSize: "1.1667rem",
    fontWeight: 500,
    borderBottom: "none",
    paddingTop: 0,
    width: "20%",
    padding:"0px"
  },
  sitestableHeadSpan: {
    padding: "0.8rem",
    display: "flex",
    alignItems: "center",
    gap:"2rem"
  },
  sitestableHeadText: {
    fontSize: "18px",
  },
  sitesdropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)",
  },
  sitesnoRequestContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
  },
  redColorSites: {
    color: "red"
  },
  sitessearchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  googleIcon : {
    backgroundColor: "red", 
    border: "none", 
    width: "100px", 
    height: '30px', 
    right:"250px",
    position: "absolute", 
    top: '315px' 
} as const
};

const InputStyle = styled(InputBase)({
  "& .buttonArrow":{
    backgroundColor:"#1c18af",
    width: "56px",
    height: "56px",
    color: 'white',
    marginRight:"1px",
    cursor:"pointer"
  },
  "& .MuiIconButton-root":{
    borderRadius:"8px"
  }
})


const sitesWithStyles = withTranslation()(withStyles(styles)(CustomerSites));

const sitesWithSnackbar = withSnackbar(sitesWithStyles);
export default withPermission(sitesWithSnackbar);
// Customizable Area End
