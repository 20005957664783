//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    Grid, Typography, Divider, Button, withStyles, Box, FormHelperText, Tooltip, Hidden
} from "@material-ui/core";
import { StyledTextFieldQuote } from '../../RequestManagement/src/Utility.web';
import { deleteIcon } from "../../CustomisableUserProfiles/src/assets";
import ProductServiceController from "./ProductServiceController.web";
import Select from "react-select";
export const configJSON = require("../../InvoiceBilling/src/config.js");
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { CONSTANTS } from "../../../components/src/theme";
import { t } from "i18next";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class ProductServices extends ProductServiceController {
    constructor(props: any) {
        super(props);
    }

    renderHelperText = (index:number) => {
        return this.props.isErrorFormData && this.props.isErrorFormData.unitPrice && this.props.isErrorFormData.unitPrice.length > 0 && this.props.isErrorFormData.unitPrice[index]
    }
    totalCost({ field, classes }) {
        return <>
            <Grid item
                xs={12}
                sm={3}
                md={3}
            >
                <Hidden smUp>
                    <Typography variant="body1" className={`${classes.productLabelColor} ${classes.mb_10}`}>
                        {t('ProductService.total')}
                        {this.renderCurrency(this.props.type,field,this.props.currency,classes)}
                    </Typography>
                </Hidden>
                <Tooltip title={field && field.quantity * field.unitPrice || field && field.totalCost} arrow placement="top">
                    <StyledTextFieldQuote
                        variant="outlined"
                        name="totalCost"
                        disabled
                        value={field && field.quantity * field.unitPrice || field && field.totalCost}
                        className={classes.quantityInput}
                    />
                </Tooltip>
            </Grid>
        </>
    }
    quantityInput = (classes, field, index) => {
        return <Grid item xs={12} sm={2} md={2}>
            <Hidden smUp>
                <Typography variant="body1" className={`${classes.productLabelColor} ${classes.mb_10}`}>{t('ProductService.quantity')}</Typography>
            </Hidden>
            <StyledTextFieldQuote
                variant="outlined"
                type="number"
                className={classes.quantityInput}
                name="quantity"
                InputProps={{ inputProps: { min: 1 } }}
                isErrorFormData
                value={field.quantity}
                onKeyPress={this.preventMinus}
                disabled={!this.props.isEditable || field.service_id}
                data-test-id={`change-product-quantity`}
                onChange={(event) => this.props.handleProductChange(index, event)}
            />

            <FormHelperText id="standard-weight-helper-text" className={classes.errorText}>
                {this.props.isErrorFormData && this.props.isErrorFormData.quantity && this.props.isErrorFormData.quantity.length > 0 && this.props.isErrorFormData.quantity[index]}
            </FormHelperText>

        </Grid>
    };
    renderDescription = (classes,field,index)=>{
        return (
            <StyledTextFieldQuote
            variant="outlined"
            multiline
            placeholder={`${t('ProductService.placeholder.description')}`}
            rows={2}
            fullWidth
            className={classes.nameProductInput}
            value={field.description}
            name="description"
            data-test-id={`change-product-description`}
            onChange={(event) => this.props.handleProductChange(index, event)}
            disabled={!this.props.isEditable}
        />
        )
    };
    renderDeleteIcon = (classes,index)=>{
        return (
           
            <img
                src={deleteIcon}
                className={classes.deleteIcon}
                data-test-id={`remove-field`}
                onClick={() => this.props.removeField(index)}
                width={25}
                height={25}
            />
        
        )
    }
    renderError (field: { product_id: number, service_id: number | null },index:number) {
        return field.product_id || field.service_id ? '' : this.props.isErrorFormData && this.props.isErrorFormData.product_id && this.props.isErrorFormData.product_id.length > 0 && this.props.isErrorFormData.product_id[index];
    }
    renderCurrency(field,type,currency,classes){
        return <span className={classes.ml_05}>
        ({type === 'edit' ? field.currency : currency})
    </span>
    }
    renderServices = ({ field, index, classes, NoOptionsMessage, filterOption, customSelectStyles, groupedOptions }) => {
        return <Grid item xs={12} className={classes.productLayout}>
            <Box width={"100%"}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} sm={4} >
                        <Hidden smUp>
                            <Typography variant="subtitle1" className={`${classes.font_lightGrey} ${classes.mb_10}`}><span className={classes.font_20}>#</span>
                                <Typography display="inline" variant="subtitle2" className={`${classes.productLabelColor} ${classes.fontWeight_400}`}>{t('ProductService.subTitle')}</Typography>
                            </Typography>
                        </Hidden>
                   
                        <Select
                            isSearchable
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, NoOptionsMessage }}
                            name="name"
                            className={classes.nameProductInput}
                            theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: CONSTANTS.color.darkBlue } })}
                            data-test-id={`change-product-name`}
                            onBlur={this.props.productOnBlur}
                            onChange={(selected: any, event: any) => this.props.handleProductChange(index, selected, event)
                            }
                            value={{
                                label:
                                    Object.keys(this.props.productData).map((list_key: any) => {
                                        return this.props.productData[field.product_id ? 'products' : 'services'].find((item: any) =>
                                            item.id == (field.product_id || field.service_id))?.name
                                    }
                                    ).filter((remove: any) => remove !== undefined)[0] ||
                                    <span className={classes.font_lightGrey}>{t('ProductService.placeholder.name')}</span>,
                                    
                                value: field.product_id || field.service_id
                            }}
                            isDisabled={!this.props.isEditable}
                            filterOption={filterOption}
                            styles={customSelectStyles}
                            options={groupedOptions}
                        />
                        <FormHelperText style={{ marginBottom: '10px' }} id="standard-weight-helper-text" className={`${classes.errorText} ${classes.display_flex}`}>
                           { this.renderError(field,index)}
                        </FormHelperText>
                        <Hidden smUp>
                            {this.renderDescription(classes, field, index)}
                        </Hidden>

                    </Grid>
                    {this.quantityInput(classes, field, index)}
                    <Grid item xs={12} sm={3} md={3}>
                        <Hidden smUp>
                            <Typography variant="body1" className={`${classes.productLabelColor} ${classes.mb_10}`}>
                                {t('ProductService.unitPrice')}
                                {this.renderCurrency(this.props.type,field,this.props.currency,classes)}
                            </Typography>
                        </Hidden>
                        <Tooltip title={field && field.unitPrice} arrow placement="top">
                            <StyledTextFieldQuote
                                variant="outlined"
                                name="unitPrice"
                                type="text"
                                value={field.unitPrice || 0.00}
                                className={classes.quantityInput}
                                disabled
                                data-test-id={`change-product-unitPrice`}
                                onChange={(event) => this.props.handleProductChange(index, event)}
                            />
                        </Tooltip>
                        <FormHelperText id="standard-weight-helper-text" className={classes.errorText}>
                            {this.renderHelperText(index)}
                        </FormHelperText>
                    </Grid>
                    {this.totalCost({ field, classes })}
                   
                    {!(!this.props.isEditable || this.props.productServices.length === 1) &&
                        <Hidden smUp>
                            <Grid item sm={1} xs={12} style={{ alignItems: 'center', display: 'flex', justifyContent: "center" }}>
                                {this.renderDeleteIcon(classes, index)}
                            </Grid>
                        </Hidden>
                    }


                </Grid>
                <Hidden xsDown>
                    {this.renderDescription(classes, field, index)}
                </Hidden>
            </Box>


            {!(!this.props.isEditable || this.props.productServices.length === 1) &&
                <Hidden xsDown>
                    <Grid item sm={1} style={{ alignItems: 'end', display: 'flex', justifyContent: "flex-end" }}>
                        {this.renderDeleteIcon(classes, index)}
                    </Grid>
                </Hidden>
            }
        </Grid>
    }
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const customSelectStyles = {
            indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
            control: (styles) => ({ ...styles, borderRadius: "8px", padding: "13px" }),
            valueContainer: (styles) => ({ ...styles, padding: "0px 0px" }),
            singleValue:(styles)=>({...styles, color: CONSTANTS.color.lightGrey,fontSize:'18px'}),
            groupHeading: (styles) => ({
                ...styles,
                fontFamily: "Rubik",
                fontWeight: 500,
                fontStyle: "normal",
                color: "black",
                fontSize: '16px',
                textTransform: 'capitalize',
                borderBottom: '1px solid rgb(197,197,197)',
            }),
            menu: (styles) => ({ ...styles, marginTop: '0px' }),
            menuList: (base) => ({ ...base, height: "200px" }),
            option: (styles, state) => {
                return {
                    ...styles,
                    backgroundColor: "#fff",
                    borderBottom: 'none',
                    fontWeight: 400,
                    fontStyle: "normal",
                    color: CONSTANTS.color.lightGrey,
                    fontSize: '14px',
                    margin: '0px',
                    padding: '0px',
                    textTransform: 'capitalize',
                    '&:hover': {
                        backgroundColor: '#fff',
                    },
                    '&:focus': {
                        backgroundColor: '#fff',
                    },
                };
            },
        };
        const groupedOptions: any = [
            {
                label: 'Products'
                ,
                options: this.props.productData?.products?.map((item, index) => {
                    return {
                        label:
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.addProductServiceBtn2}>
                                    <Typography component={'div'} className={classes.subHeaderTitle}> {item.name}</Typography>
                                    <Typography component={'div'} className={classes.subHeaderTitle}> {item.total_price || item.cost}</Typography>
                                </div>
                                    {
                                        index === this.props.productData.products.length - 1 && this.props.productData.services.length === 0 &&
                                        <Grid container style={{gridRowGap:'3.5em',marginTop:'25px'}} spacing={12} justifyContent="space-around">
                                            <Button variant="outlined" onClick={this.handleAddProductService} className={classes.addProductServiceBtn} disabled={!this.props.isEditable}>{t('ProductService.button.productService')}</Button>
                                        </Grid>                                    }
                            </>
                        , value: { ...item, 'type': 'product' }, data: item.name
                    }
                }),
            },
            {
                label: 'Services'
                ,
                options: this.props.productData?.services?.map((item, index) => {
                    return {
                        label:
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.addProductServiceBtn2}>
                                    <Typography component={'div'} className={classes.subHeaderTitle}> {item.name}</Typography>
                                    <Typography component={'div'} className={classes.subHeaderTitle}> {item.unit_price || item.total_price}</Typography>
                                </div>
                                    {
                                        index === this.props.productData.services.length - 1 &&
                                        <Grid container style={{gridRowGap:'3.5em',marginTop:'25px'}} spacing={12} justifyContent="space-around">
                                            <Button variant="outlined" onClick={this.handleAddProductService} className={classes.addProductServiceBtn} disabled={!this.props.isEditable}>{t('ProductService.button.productService')}</Button>
                                        </Grid>
                                    }
                            </>
                        , value: { ...item, 'type': 'service' }, data: item.name
                    }
                }),
            },
        ];
        const filterOption = ({ value, data }, string) => {
            // default search
            if (data?.data?.includes(string) || value?.name.includes(string)) return true;

            // check if a group as the filter string as data?.data?
            const groupOptions = groupedOptions.filter(group =>
                group.data?.data?.toLocaleLowerCase().includes(string)
            );

            if (groupOptions) {
                for (const groupOption of groupOptions) {
                    // Check if current option is in group
                    const option = groupOption.options.find(opt => opt.value === value?.name);
                    if (option) {
                        return true;
                    }
                }
            }
            return false;
        };

        const NoOptionsMessage = () => {
            return (
                <>
                    <Grid container style={{gridRowGap:'3.5em',marginTop:'25px'}} spacing={12} justifyContent="space-around">
                        <Typography className={classes.productLabel} style={{width: "100%", margin: "auto", textAlign: "center"}}>{t('ProductService.noDataFound')}</Typography>
                        <Button variant="outlined" onClick={this.handleAddProductService} className={classes.addProductServiceBtn} disabled={!this.props.isEditable}>{t('ProductService.button.productService')}</Button>
                    </Grid>
                </>
            );
        };

        return (
           
            <>
            
                <Grid container direction="row">
                    <Grid item xs={12} >
                        <Typography variant="subtitle1" className={`${classes.fontWeight_600} ${classes.font_lightBrown} ${classes.headingText}`}>
                         {t('ProductService.title')}
                            <span className={`${classes.errorText} ${classes.font_20}`}>*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.addItemsDivider} />
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container direction="row" alignItems="center" className={classes.priceTable}>
                            <Hidden xsDown>
                                <Grid item xs={3} sm={4} className={this.getClassStyles(classes,"product")}>
                                    <Typography variant="subtitle1" className={classes.font_lightGrey}><span className={classes.font_20}>#</span>
                                        <Typography display="inline" variant="body1" className={classes.productLabelColor}>{t('ProductService.subTitle')}</Typography>
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Hidden xsDown>
                                <Grid item xs={2} sm={2} className={this.getClassStyles(classes,"qty")}>
                                    <Typography style={{ paddingTop: '0.5em' ,paddingLeft:"10px"}} variant="body1" className={classes.productLabelColor}>
                                        {t('ProductService.quantity')}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Hidden xsDown>
                                <Grid item xs={2} sm={3} className={this.getClassStyles(classes,"price")}>
                                    <Typography
                                        style={{ paddingTop: '0.5em',paddingLeft:"8px" }} variant="body1"
                                        className={classes.productLabelColor}>
                                        {t('ProductService.unitPrice')}
                                        {this.renderCurrency(this.props.type,this.props.field,this.props.currency,classes)}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Hidden xsDown>
                                <Grid item xs={2} sm={3} className={this.getClassStyles(classes,"total")}>
                                    <Typography
                                        style={{ paddingTop: '0.5em' }}
                                        variant="body1"
                                        className={classes.productLabelColor}
                                    >
                                        {t('ProductService.total')}
                                        {this.renderCurrency(this.props.type,this.props.field,this.props.currency,classes)}
                                    </Typography>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>

                    {this.props.productServices && this.props.productServices.map((field, index) => (
                        <>
                            <Grid container style={{
                                border: "1px solid  rgba(0,0,0,0.1)",
                                borderRadius: "8px",
                            }}>
                                {this.renderServices({ field, index, classes, NoOptionsMessage, filterOption, customSelectStyles, groupedOptions })}
                            </Grid>
                        </>
                    ))}

                    <Grid item xs={12} className={classes.gutterTop2}>
                        <Button variant="outlined" className={classes.addItemBtn} onClick={this.props.addField} disabled={!this.props.isEditable}>
                           <Typography variant="body1" style={{lineHeight:"initial"}}>{t('ProductService.button.addItem')}</Typography> 
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const ProductServicesStyles = (theme: any) => ({
    headingText: {
        height: "33px",
        marginBottom: "10px"
    },
    font_lightBrown:{
        color:CONSTANTS.color.lightBrown
    },
    font_20:{
        fontSize:'20px'
    },
    fontWeight_400:{
        fontWeight:CONSTANTS.fontWeight.fontWeight400
    },
    fontWeight_500:{
        fontWeight:CONSTANTS.fontWeight.fontWeight500
    },
    fontWeight_600:{
        fontWeight:CONSTANTS.fontWeight.fontWeight600
    },
    ml_05:{
        marginLeft:"5px"
    },
    deleteIcon: {
        width: "30px",
        height: "30px",
        cursor: "pointer",
    },
    addItemsDivider: {
        [theme.breakpoints.down("sm")]: {
            width: "100% !important",
        },
    },
    uploadFileLayout: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'end',
        [theme.breakpoints.down("xs")]: {
            display: "block",
            flexDirection: "row",
            justifyContent: 'start',
        },
    },
    endAdornment: {
        padding: "0.5rem",
        background: CONSTANTS.color.pebbleGrey,
        marginLeft: 0,
        borderRadius: "8px",
        width: "-webkit-fill-available",
        height: "57px",
        display: "flex",
        alignItems: "center",
        justifyContent:'center',
        [theme.breakpoints.down("xs")]: {
            width: "30%",
        },
    },
    addProductServiceBtn: {
        width: "140px",
        height: "50px",
        borderRadius: "8px",
        fontSize:'13px',
        border: "1px solid rgba(0,0,0,0.1)",
        background: CONSTANTS.color.darkBlue,
        color: CONSTANTS.color.white,
        textTransform: "none" as const,
        '&:hover': {
            cursor: "pointer",
            backgroundColor: CONSTANTS.color.blueViolet,
            color: theme.palette.common.white,
            border:"none",
        },
        "&.MuiButton-root.Mui-disabled": {
            background: "mediumslateblue",
            color: CONSTANTS.color.white,
        }
    },
    addItemBtn: {
        width: "150px",
        height: "48px",
        borderRadius: "8px",
        border: "1px solid rgba(0,0,0,0.1)",
        background: CONSTANTS.color.darkBlue,
        color: CONSTANTS.color.white,
        textTransform: "capitalize",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: CONSTANTS.color.blueViolet,
            color: theme.palette.common.white,
            border:"none",
        },
        "&.MuiButton-root.Mui-disabled": {
            background: "mediumslateblue",
            color: CONSTANTS.color.white,
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    priceTable: {
        padding: "0.5rem",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        }
    },
    mb_10:{
        marginBottom:'10px'
    },
    mt_12: {
        [theme.breakpoints.down("xs")]: {
            marginTop: '12px'
        },
    },
    productQtyHidden: {
        padding:"0 0 0 1rem",
      
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productPriceHidden: {
        padding:"0 0 0 1.5rem",
        [theme.breakpoints.down("md")]: {
            padding:"0 0 0 1.5rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding:"0 0 0 1rem",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productTotalHidden: {
        padding:"0 0 0 1.5rem",
        [theme.breakpoints.down("md")]: {
            padding:"0 0 0 1.5rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding:"0 0 0 1rem",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productTitleAr: {
        padding:"0.5em 1rem 0 0",
        [theme.breakpoints.down("sm")]: {
            padding:"0.5em 1rem 0 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0.5em 0 0 0",
        },
    },
    productQtyAr: {
        padding:"0 2.5rem 0 0",
        [theme.breakpoints.down("md")]: {
            padding:"0 2rem 0 0",
        },
        [theme.breakpoints.down("sm")]: {
            padding:"0 1.5rem 0 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productPriceAr: {
        padding:"0 4rem 0 0",
        [theme.breakpoints.down("md")]: {
            padding:"0 3.5rem 0 0",
        },
        [theme.breakpoints.down("sm")]: {
            padding:"0 2.5rem 0 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productTotalAr: {
        padding:"0 5.4rem 0 0",
        [theme.breakpoints.down("md")]: {
            padding:"0 4.5rem 0 0",
        },
        [theme.breakpoints.down("sm")]: {
            padding:"0 3.3rem 0 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productQtyHiddenAr: {
        padding:"0 1rem 0 0",
       
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productPriceHiddenAr: {
        padding:"0 1.5rem 0 0",
        [theme.breakpoints.down("md")]: {
            padding:"0 1.5rem 0 0",
        },
        [theme.breakpoints.down("sm")]: {
            padding:"0 1rem 0 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productTotalHiddenAr: {
        padding:"0 1.5rem 0 0",
        [theme.breakpoints.down("md")]: {
            padding:"0 1.5rem 0 0",
        },
        [theme.breakpoints.down("sm")]: {
            padding:"0 1rem 0 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding:"0",
        },
    },
    productLabelColor: {
        color: CONSTANTS.color.grey,
    },
    font_lightGrey: {
        color: CONSTANTS.color.lightGrey,
    },
    errorText: {
        marginTop: "5px",
        color: CONSTANTS.color.crimsonRed,
    },
    display_flex:{
        display:"flex"
    },
    PriceUpload: {
        border: "2px dashed #1c18af",
        height: "56px",
        width: "100%",
        background: "rgba(28, 24, 175, 0.05)",
        color: CONSTANTS.color.darkBlue,
        fontSize: "12px",
        textTransform: "capitalize",
        borderRadius: "8px",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        "&:hover": {
            background: "rgba(28, 24, 175, 0.05)",
        }
    },
    addProductServiceBtn2: {
        margin: "0px",
        padding: "10px",
        borderBottom: '1px solid rgb(228, 228, 228)',
        "&:hover": {
            background: "#E7EDF3",
        }
    },
    productLayout: {
        padding: "1.5rem",
        display:"flex"
    },
    nameProductInput: {
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    quantityInput: {
        width:"100%",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    productsOptionDivider: {
        height: "1px",
        backgroundColor: 'rgba(151,151,151,0.8)'
    },
    subHeaderTitle: {
        color: CONSTANTS.color.lightGrey,
        fontSize: '14px',
        textTransform: 'capitalize'
    },

    fileLayout: {
        width: "130px",
        marginRight: "5px",
        marginBottom: "5px",
        borderRadius: "8px",
        background: CONSTANTS.color.white,
        backgroundBlendMode: "normal",
        boxShadow: "0px 6px 17px rgba(28,24,175,0.2)",
        [theme.breakpoints.down('sm')]: {
            width: "100%",

        }
    },
    uploadFileBox: {
        width: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "flex-end"
    },
    imageBox: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0 2px 0 9px",
        [theme.breakpoints.down('xs')]: {
           padding:'0px'
        }

    },
    imageBoxV1: {
        marginRight: "9px",
    },
    imageUpload: {
        width: "30px",
        height: "35px",
    },
    textBox: {
        display: "flex",
        flexDirection: "column",
    },
    createCustTextTypo: {
        fontSize: "14px",
        marginBottom: 0,
    }
});

const GetCommonStyles = withCommonStyles(ProductServices)
export default withStyles(ProductServicesStyles)(GetCommonStyles);
// Customizable Area End