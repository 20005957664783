import React from "react";
import {
    // Customizable Area Start
    Box,
    Grid,
    Typography, Hidden,
    MenuItem, Container, ListItemIcon, InputAdornment, ClickAwayListener, FormHelperText
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { convertToJob, convertToQuote, downloadPdf, dropDownSelectIcon } from "./assets"
import { backIconMobile } from "../../CustomisableUserProfiles/src/assets";
import RequestEditController, { Props, configJSON } from "./RequestEditController.web";
import CustomerDetails from "./CustomerDetails.web";
import CreationProcess from "./CreationProcess.web";
import InternalNotesAttachements from "./InternalNotesAttachements.web";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { goBack } from "../../../components/src/CommonFunction"
import CustomTeamMember from "../../../components/src/CustomTeamMember.web"
import { StyledCheckbox } from '../../appointmentmanagement/src/Calendar.web'
import DeleteModel from "./DeleteModal.web";
import { GoogleMap, Marker } from "@react-google-maps/api";
import RequestPdf from "../../saveaspdf/src/RequestPdf.web";
import ClearIcon from '@material-ui/icons/Clear';
import CustomerModal from "./CustomerModal.web"
import withRequestStyles from "./RequestStyles.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import TimePicker from "../../../components/src/TimePicker";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web";
import { JobsStyledMenu } from "../../JobListing2/src/JobsUtility.web";
import { ArcheiveButton, CancelButton, ContainedButton, CustomizedTextField, OutlinedButton } from "../../../components/src/CustomizableComponents.web";
import CustomDatePicker from "../../../components/src/DatePicker";
import { AntSwitch } from "../../../components/src/CustomSwichStyled.web"
import { withTranslation } from "react-i18next";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import { dropDownArrow } from "../../ProjectReporting/src/assets";
import { CONSTANTS } from "../../../components/src/theme"
import moment from "moment";
const selectedLanguage =  localStorage.getItem("lang");
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export class RequestEdit extends RequestEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    requestSourceDropDown = () => {
        return (
            <ClickAwayListener data-test-id="close_source" onClickAway={this.handleStatusRequestModalClose}>
                <Typography>
                    <Box style={webStyle.statusModalOutline}>
                        <Box style={webStyle.sourceLayout}>

                            {this.state.requestSource.map((item: any) => (
                                <MenuItem
                                    data-test-id="handleSelectStatus"
                                    style={this.state.selectedRequestSource === item.sourceName ? { ...webStyle.menuItemStyleRequest, backgroundColor: "#ececec" } : webStyle.menuItemStyleRequest}
                                    key={item.id}
                                    value={item.sourceValue}
                                    onClick={() => this.handleSelectSource(item)}
                                >
                                    {item.sourceName}
                                </MenuItem>
                            ))}
                        </Box>
                    </Box>
                </Typography>
            </ClickAwayListener>
        );
    }

    editBottomButtons = () => {
        const { classes,t } = this.props
        return (
            <Grid container className={`${classes.flexDirection} ${classes.margin} ${classes.mt_1}`}>
                <Grid item xs={12} sm={6}>
                    <Box style={webStyle.cancelBtnContainer} className={classes.direction}>
                        {this.state.requestEditStatus &&
                            <>
                                <CancelButton
                                    variant="outlined"
                                    data-test-id="cancelButton"
                                    onClick={this.cancelRequest}>
                                    <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{t('invoice.button.cancel')}</Typography>
                                </CancelButton>
                                {this.state.singleRequestDetails?.attributes?.status != "cancelled" &&
                                    <ArcheiveButton
                                        variant="outlined"
                                        data-test-id="ArcheiveButton"
                                        onClick={this.handleDeleteDialog}>
                                        <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{t('request.button.cancelRequest')}</Typography>
                                    </ArcheiveButton>
                                }
                            </>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.flexDirection}>
                        {this.state.requestEditStatus ?
                            <OutlinedButton
                                variant="outlined"
                                className={`${classes.mr_1} ${classes.ml_1} ${classes.mt_1}`}
                                data-test-id="updateButton"
                                onClick={this.handleUpdateRequest}>
                                <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{t('request.button.updateRequest')}</Typography>
                            </OutlinedButton>
                            :
                            <>
                                {this.state.singleRequestDetails?.attributes?.status != "cancelled" &&
                                    <OutlinedButton data-test-id="editButton"
                                        variant="outlined"
                                        className={`${classes.mr_1} ${classes.ml_1} ${classes.mt_1}`}
                                        onClick={this.handleRequestEdit}>
                                        <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{t('invoice.button.edit')}</Typography>
                                    </OutlinedButton>
                                }
                            </>
                        }
                        <ContainedButton
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            onClick={this.handleButton}
                            data-test-id="openMoreInfo"
                        >
                            <Typography variant='subtitle2' className={`${classes.buttonText} ${classes.lineHeight}`}>{t('invoice.button.moreAction')}</Typography>
                            <img style={this.state.anchorEl ?
                                { ...webStyle.dropDownArrowIcon, transform: "rotate(180deg)" } :
                                webStyle.dropDownArrowIcon}
                                src={dropDownArrow} alt=""
                            />
                        </ContainedButton>
                        <JobsStyledMenu
                            id="customized-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleButtonClose}
                            data-test-id="moreinfo"
                        >
                            <div className={classes.iconLayout}>
                                <ClearIcon onClick={this.handleButtonClose} />
                            </div>
                            <div>

                                {this.state.singleRequestDetails?.attributes?.status != "cancelled" &&
                                    <>
                                        {!this.state.singleRequestDetails?.attributes?.converted_to_quote && <MenuItem>
                                            <ListItemIcon style={webStyle.width35}>
                                                <img src={convertToQuote} width="20px" height="20px" />
                                            </ListItemIcon>
                                            <Typography variant="subtitle2" data-test-id="actionQuote" className={classes.popupBtnText} onClick={() => this.handleMoreActions('actionQuote')}>{t('request.popupBtn.quote')}</Typography>
                                        </MenuItem>}
                                        {!this.state.singleRequestDetails?.attributes?.converted_to_job && <MenuItem>
                                            <ListItemIcon style={webStyle.width35}>
                                                <img src={convertToJob} width="20px" height="20px" />
                                            </ListItemIcon>
                                            <Typography variant="subtitle2" data-test-id="actionJob" className={classes.popupBtnText} onClick={() => this.handleMoreActions('actionJob')} >{t('request.popupBtn.job')}</Typography>
                                        </MenuItem>}
                                    </>}
                                <MenuItem>
                                    <ListItemIcon style={webStyle.width35}>
                                        <img src={downloadPdf} width="20px" height="20px" />
                                    </ListItemIcon>
                                    <Typography variant="subtitle2" data-test-id='actionPdf' className={classes.popupBtnText} onClick={() => this.handleMoreActions('actionPdf')}>{t('request.popupBtn.download')}</Typography>
                                </MenuItem>
                            </div>
                        </JobsStyledMenu>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    modals = () => {
        const { t } = this.props
        return (
            <>
                {
                    this.state.openDeleteDailog &&
                    <DeleteModel
                        deleteType={`${t('request.deleteRequest')}`}
                        subType={`${t('request.deleteRequestDes')}`}
                        open={this.state.openDeleteDailog}
                        onClose={this.handleCloseDeleteDialog}
                        handleDelete={this.handleDeleteRequest}
                        data-test-id={"preview_close_pdf"}
                        t={this.props.t}
                    />
                }
                {
                    this.state.openPdfStatus &&
                    <RequestPdf
                        type="Request"
                        requestId={this.state.singleRequestDetails?.id}
                        openPdf={this.state.openPdfStatus}
                        closePdf={this.closePdf}
                        t={this.props.t}
                    />
                }
                {
                    this.state.changeCustomerDailog &&
                    <CustomerModal
                        t={this.props.t}
                        type='editRequest'
                        languageSelected={this.state.selectLang}
                        requestId={this.state.singleRequestDetails?.id || ""}
                        open={this.state.changeCustomerDailog}
                        saveMapData={this.locationData}
                        getCustomerDetails={this.getEditCustomerDetails}
                        getCustomerSelectedStatus={this.getCustomerSelectedStatus}
                        selectedCustomerButtonStatus={this.getCustomerDailogStatus1}
                        getSiteDetails={this.getSiteDetails}
                        customerDetails={this.state.selectedCustomerDetails.attributes}
                        siteDetails={this.state.siteDetails}
                        data-test-id="searchCustomer"
                        navigation={this.props.navigation} id={""}
                        history={this.props.navigation.history}
                        location={this.props.navigation.history.location}
                        crudStatus={""} />
                }
            </>
        )
    }
    disableFieldStyle = () => {
        const { classes } = this.props
        return !this.state.requestEditStatus ?
            `${classes.requestInputCreate} ${classes.fieldDisableBg}` :
            classes.requestInputCreate
    }
    titleErrorMsg = () => {
      return <ErrorMessage 
            message={this.state.requestEditForm.title === '' || 
            (this.state.requestEditForm.title && this.state.requestEditForm.title.length > 40) ? 
            this.state.isErrorFormData.title: ''} 
        />
    }
    mobileBackIcon = () => {
        return selectedLanguage === "ar" ? {...webStyle.backIconMobile, ...webStyle.transformStyle} : webStyle.backIconMobile
    }
    // Customizable Area End

    render() {
        const { classes,t } = this.props;
        return (
            <>
                <DashboardSideBar oldProps={undefined} permissions={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.requests')} active={3} active1={false} active2={false} />
                <StyledRoot languageSelected={this.state.selectLang}>
                    <Box sx={{ marginTop: "8rem" }} >
                        <Container maxWidth={false}>
                            <Grid item xs={12} className={classes.pogressBar}>
                                <CreationProcess active={0} t={this.props.t} selectLanguage={this.state.selectLang}/>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: '10px' }}>
                                <Grid item style={webStyle.backIconContainer}>
                                    <Hidden smUp>
                                        <img style={this.mobileBackIcon()} src={backIconMobile} onClick={() => goBack(this.props.navigation)} alt="" />
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                            <Grid item xs={12} md={8}>
                                                <Grid container direction="row" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1"
                                                            className={classes.titleHeading26}>
                                                                {t('request.title')}
                                                                <span style={webStyle.errorText}>
                                                                    *
                                                                </span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CustomizedTextField
                                                            disabled={!this.state.requestEditStatus}
                                                            data-test-id="edit_request"
                                                            variant="outlined"
                                                            className={this.disableFieldStyle()}
                                                            onChange={this.handleRequestEditChange}
                                                            name={"title"}
                                                            value={this.state.requestEditForm.title}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {this.titleErrorMsg()}
                                                <Grid item xs={12} md={4} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className={classes.mt_15}>
                                            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                <Grid item xs={12} md={8} xl={9}>
                                                    <CustomerDetails
                                                        t={this.props.t}
                                                        crudStatus={'edit'}
                                                        modeType={`${t('request.requestFor')}`}
                                                        editStatus={this.state.requestEditStatus}
                                                        details={this.state.selectedCustomerDetails?.attributes}
                                                        siteDetails={this.state.siteDetails}
                                                        dailogOpen={this.changeCutomerDailog}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4} xl={3} container className={classes.mt_40} style={{direction:localStorage.getItem('lang')==='ar'?'ltr':'rtl'}}>
                                                    <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.mb_15}`}>{t('invoice.location')}</Typography>
                                                    <GoogleMap
                                                        mapContainerStyle={webStyle.CreateCustmapContainerStyle}
                                                        center={this.state.defaultCustomerLocation}
                                                        zoom={5}
                                                        data-test-id="mapComponent"
                                                        onLoad={(map) => {
                                                            this.onMapLoad(map);
                                                        }}
                                                        options={{
                                                            disableDefaultUI: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false,
                                                            zoomControl:false,
                                                            fullscreenControl: false
                                                        }}
                                                    >
                                                        {this.state.getCustomerPlace && (
                                                            <Marker position={this.state.getCustomerPlace} />
                                                        )}
                                                    </GoogleMap>


                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.marginTop_10}`}>{t('request.serviceText')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography color="textPrimary" variant="body1" className={classes.subTitle20}>{t('request.subTitles.serviceText')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomizedTextField
                                                        disabled={!this.state.requestEditStatus}
                                                        multiline
                                                        minRows={3}
                                                        maxRows={3}
                                                        variant="outlined"
                                                        className={!this.state.requestEditStatus ?
                                                            `${classes.serviceInputCreate} ${classes.fieldDisableBg}` :
                                                            classes.serviceInputCreate
                                                        }
                                                        onChange={this.handleRequestEditChange}
                                                        name={"serviceDetails"}
                                                        value={this.state.requestEditForm.serviceDetails}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={5} className={classes.gutterTop2}>
                                            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={classes.titleHeading26}>{t('request.source')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.source')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomizedTextField
                                                        disabled={!this.state.requestEditStatus}
                                                        variant="outlined"
                                                        className={this.disableFieldStyle()}                                                        
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <img style={!this.state.toggleModal ? webStyle.sourcedropDownSelectIcon : { ...webStyle.sourcedropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        data-test-id="openSource"
                                                        onClick={this.handleToggleRequestModalOpen}
                                                        value={this.state.selectedRequestSource}
                                                    >
                                                    </CustomizedTextField>
                                                    {this.state.toggleModal ? (
                                                        this.requestSourceDropDown()
                                                    ) : null}
                                                </Grid>
                                                {this.state.selectedSourceValue === "others" && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>Others
                                                                <Typography display="inline" variant="caption" color="error">
                                                                    {configJSON.subTitles.AsentricSymbol}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                disabled={!this.state.requestEditStatus}
                                                                className={this.disableFieldStyle()}
                                                                variant="outlined"
                                                                onChange={this.handleRequestEditChange}
                                                                name={"others"}
                                                                value={this.state.requestEditForm.others}
                                                                data-test-id="others"
                                                            />
                                                            <FormHelperText id="standard-weight-helper-text" className={classes.errorText}>{this.state.othersError}</FormHelperText>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={9} className={classes.gutterTop2}>
                                            <Grid container direction="row" spacing={2} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={`${classes.titleHeading26}`}>{t('request.scheduling')}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container direction="row" justifyContent="flex-start" spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.dateText')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.state.requestEditStatus ?
                                                                <CustomDatePicker
                                                                    disabled={!this.state.requestEditStatus}
                                                                    selectedDate={this.state.requestEditForm.appointmentDate}
                                                                    dateChange={this.handleEditDateChange}
                                                                />
                                                                :
                                                                <CustomizedTextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className={
                                                                        !this.state.requestEditStatus ?
                                                                            `${classes.appointmentInput} ${classes.fieldDisableBg}` :
                                                                            classes.appointmentInput
                                                                    }
                                                                    value={moment(this.state.requestEditForm.appointmentDate).format('DD/MM/YYYY')}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                />}

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container direction="row" justifyContent="flex-start" spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>{t('request.subTitles.timeText')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.state.requestEditStatus ?
                                                                <TimePicker
                                                                    data-test-id="editTime"
                                                                    time={this.state.requestEditForm.appointmentTime}
                                                                    onChange={(timeValue: any) => this.handleEditChangeHours(timeValue)}
                                                                    timeFormat={this.state.timeFormat}
                                                                    t={t}
                                                                    languageSelected={this.state.selectLang}
                                                                />
                                                                :
                                                                <CustomizedTextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className={
                                                                        !this.state.requestEditStatus ?
                                                                            `${classes.appointmentInput} ${classes.fieldDisableBg}` :
                                                                            classes.appointmentInput
                                                                    }
                                                                    value={this.state.requestEditForm.appointmentTime}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                />}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" spacing={2}>
                                                <Hidden xsDown><Grid item xs={12} sm={6}/></Hidden>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container className={classes.ml_04}>
                                                        <StyledCheckbox
                                                            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                                                            name='all_day'
                                                            onChange={this.handleEditCheckAllDay}
                                                            checked={this.state.all_day}
                                                            disabled={!this.state.requestEditStatus}
                                                            data-test-id='editAllDay'
                                                        />
                                                        <Typography style={{ marginTop: "-3px" }}
                                                            variant="body1" color="textPrimary"
                                                            className={`${classes.subTitle20} ${classes.ml_10}`}
                                                        >
                                                            {t('Calendar.label.allDay')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.mt_2}>
                                                <AntSwitch 
                                                    onChange={this.handleEditCheckOnSite}
                                                    checked={this.state.onSite}
                                                    disabled={!this.state.requestEditStatus}
                                                    data-test-id='editOnSite'
                                                />
                                                <Grid item className={`${classes.text_align} ${classes.ml_10}`}>
                                                    <Typography variant="body1" className={this.state.onSite === true ? classes.switchText : classes.switchText1}>
                                                        {t('request.onSite')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.mt_2}>
                                                <Typography variant="subtitle1" className={classes.titleHeading26}>
                                                    {t('Calendar.label.assignTeamMember')}
                                                </Typography>
                                            </Grid>
                                            <CustomTeamMember
                                                getEmployeeData={this.state.getEmployeeData}
                                                values={this.state.editTeamMember}
                                                disabled={!this.state.requestEditStatus}
                                                name="employee_id"
                                                selectLanguage={this.state.selectLang}
                                                onChange={this.handleChangeEditTeam}
                                                t={t}
                                                onClick={(employeeId: string) => this.handleEditRemoveTeam(employeeId)}
                                                data-test-id='editTeamMember'
                                            />
                                        
                                        </Grid>
                                        <Grid item xs={12} md={9} className={classes.gutterTop3}>
                                            <InternalNotesAttachements
                                                type="edit"
                                                t={this.props.t}
                                                editStatus={this.state.requestEditStatus}
                                                internalNotes={this.state.internalNotes}
                                                editedinternalAttachements={this.state.editedInternalAttachments}
                                                internalAttachments={this.state.internalAttachments}
                                                convertedFiles={this.state.convertedFiles}
                                                updateInternalNotes={this.updateInternalNotes}
                                                updateInternalAttachments={this.updateInternalAttachments}
                                                getConvertedFiles={this.getConvertedFiles}
                                                getEditedFiles={this.getEditedFile}
                                                languageSelected={this.state.selectLang}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            {this.editBottomButtons()}
                        </Container>
                    </Box>
                </StyledRoot>
                {this.modals()}
                <LoadingBackdrop
                    loading={this.state.editRequestLoading}
                />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyle = {
    errorText:{
        fontSize: "20px",
        color: CONSTANTS.color.crimsonRed,
        fontWeight: 400
    },
    CreateCustmapContainerStyle: {
        width: "100%",
        minHeight: "320px",
        borderRadius: "10px",
    },
    width35:{
        minWidth:"35px"
    },
    dropDownArrowIcon: {
        width: "16px",
        height: "8px",
        marginLeft:"15px"
    },
    cancelBtnContainer: {
        display: "flex",
        gap: "1rem"
    },
    sourcedropDownSelectIcon: {
        width: "13px",
        height: "9px",
        cursor: "pointer",
        marginLeft: "10px",
        marginRight: "6px"
    },
    statusModalOutline: {
        position: "absolute" as const,
        zIndex: 9,
        width: "100%",
        maxWidth: "286px",
        minHeight: "182px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    },
    sourceLayout: {
        padding: "1.5rem 0 0 0",
        color: "#999999",
        fontFamily: "Rubik",
        fontSize: "22px",
        fontWeight: 500,
        textAlign: "center" as const,
    },
    flexItem: {
        display: 'flex',
        jsutifyContent: "center",
        alignItems: 'center'
    },
    menuItemStyleRequest: {
        fontFamily: "Rubik",
        fontSize: "22px",
        fontWeight: 400,
        fontStyle: "normal",
        color: "#34313a"
    },
    backIconMobile: {
        width: "12px",
        height: "24px",
    },
    transformStyle: {
        transform: "rotate(180deg)"
    },
    backIconContainer:{
        display:"flex",
        alignItems:"center",
        gap:"1rem",
        marginBottom:"10px"
    },

}
const RequestEditSnackBar = withSnackbar(RequestEdit);
const requestEditStyles = withRequestStyles(RequestEditSnackBar)
const permission = withCommonStyles(requestEditStyles);
export default withTranslation()(withPermission(permission));
// Customizable Area End