import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Hidden,
  Typography,
  Container,
} from "@material-ui/core";
import {
  Theme,
  styled,
  withStyles
} from "@material-ui/core/styles";
import {
  payImage,
  noInvoicesImage
} from "./assets";
import { withSnackbar } from "../../../components/src/toastweb";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { SettingsSideBar } from "./SettingsSideBar.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { CONSTANTS } from "../../../components/src/theme";
import { withRouter } from 'react-router-dom';
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
import { StyledBox } from "./CompanyInformation.web";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
// Customizable Area End
import BillingController, {
  Props,
} from "./BillingController.web";

// Customizable Area Start
export const StyledBoxContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "100% !important",
  }
}));

export const StyledBoxInvoice = styled(Box)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "100% !important",
  },
}));
// Customizable Area End

export class Billing extends BillingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const showButtonManagePlan = (classes: ClassNameMap) => {
      const { t } = this.props;
      return (
        <Button
          className={`${classes.buttonContainer} ${classes.buttonManagePlan}`}
          onClick={this.handleManagePlan}
        >
          <Typography style={webStyle.buttonText} className={classes.buttonText} variant="caption">
            {t('settings.managePlan')}
          </Typography>
        </Button>
      );
    }

    const showButtonAddCard = () => {
      const { t } = this.props;
      return (
        <Button
          className={`${classes.buttonContainer} ${classes.buttonAddCard}`}
          onClick={this.handleAddCard}
        >
          <Typography
            style={webStyle.buttonText}
            className={classes.buttonText}
            variant="caption"
          >
            {t('settings.addCard')}
          </Typography>
        </Button>
      );
    }
    const { classes }: any = this.props;
    const { t } = this.props;
    const mockInvoices = [
      {
        id: 1,
        date: t('settings.dateLabel'),
        invoiceTitle: t('settings.lamsaSubscription'),
        amount: "$0",
        status: t('settings.trial')
      },
      {
        id: 2,
        date: t('settings.dateLabel'),
        invoiceTitle: t('settings.lamsaSubscription'),
        amount: "$28",
        status: t('settings.failed')
      },
      {
        id: 3,
        date: t('settings.dateLabel'),
        invoiceTitle: t('settings.lamsaSubscription'),
        amount: "$28",
        status: t('settings.paid')
      },
    ]
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.settings')} active={false} active1={false} active2={1} />
        <SettingsSideBar progress={100} {...this.props} active={2} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            style={webStyle.boxContainerBilling}
            className={classes.boxContainerBilling}
          >
            <Container maxWidth={false}>
              <Box>
                <Typography style={webStyle.mainHeaderText} className={classes.mainHeaderText} variant="subtitle1">
                  {t('settings.billing')}
                </Typography>
                <Typography style={webStyle.headingText} className={classes.headingText} variant="body1" color="textPrimary">
                  {t('settings.accountInfo')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={5} sm={5} xs={6}>
                    <StyledBoxContainer style={webStyle.accountStatusContainer} className={classes.accountStatusContainer}>
                      <Typography style={webStyle.planText} className={classes.planText} variant="body1">
                        {t('settings.accountStatus')}
                      </Typography>
                      <Typography style={{...webStyle.activeText, ...webStyle.activeContainer}} className={classes.headingText} variant="subtitle1">
                        <span style={webStyle.activeDot}></span>
                        {this.state.status}
                      </Typography>
                    </StyledBoxContainer>
                  </Grid>
                  <Grid item lg={4} md={5} sm={5} xs={6}>
                    <StyledBoxContainer style={webStyle.accountStatusContainer} className={classes.accountStatusContainer}>
                      <Typography style={webStyle.planText} className={classes.planText} variant="body1">
                        {t('settings.activeSince')}
                      </Typography>
                      <Typography style={{...webStyle.activeText, ...webStyle.darkBlueStyle}} className={classes.headingText} variant="subtitle1">
                        {this.state.activeFromDate}
                      </Typography>
                    </StyledBoxContainer>
                  </Grid>
                </Grid>
                <Typography style={{...webStyle.headingText, ...webStyle.paddingStyle}} className={classes.headingText} variant="body1" color="textPrimary">
                  {t('settings.subscriptionDetails')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xs={12}>
                    <StyledBoxContainer style={{...webStyle.accountStatusContainer, ...webStyle.styledBox}} className={classes.planContainer}>
                      <Typography style={webStyle.planText} className={classes.headingText} variant="body1">
                        {t('settings.plan')}
                      </Typography>
                      <Typography style={{...webStyle.activeText, ...webStyle.darkBlueStyle,textTransform: 'capitalize'}} className={classes.goldText} variant="subtitle1">
                        {this.state.currentPlan}
                      </Typography>
                      <Box style={webStyle.managePlanContainer}>
                        <Typography
                          style={webStyle.planText}
                          className={classes.planText}
                          variant="body1"
                        >
                          {t('settings.manageAndCancelPlan')}
                        </Typography>
                        <Hidden xsDown>
                          {showButtonManagePlan(classes)}
                        </Hidden>
                      </Box>
                      <Hidden smUp>
                        {showButtonManagePlan(classes)}
                      </Hidden>
                    </StyledBoxContainer>
                  </Grid>
                </Grid>
                <Typography style={{...webStyle.headingText, ...webStyle.paddingStyle}} className={classes.headingText} variant="body1" color="textPrimary">
                  {t('settings.paymentMethodDetails')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xs={12}>
                    <StyledBoxContainer style={{...webStyle.accountStatusContainer, ...webStyle.accountStatusPadding}} className={classes.paymentMethodContainer}>
                      <Box style={webStyle.managePlanContainer}>
                        <img style={webStyle.payImage} className={classes.payImage} src={payImage} alt="" />
                        <Box>
                          <Typography style={{...webStyle.activeText, ...webStyle.paymentMethodStyle}} className={classes.goldText} variant="subtitle1">
                            {t('settings.paymentMethodDetails')}
                          </Typography>
                          <Typography style={{...webStyle.planText, ...webStyle.lightGreyStyle}} className={classes.plantext} variant="body1">
                            {t('settings.noCardInfoAvailable')}
                          </Typography>
                        </Box>
                        <Hidden xsDown>
                          {showButtonAddCard()}
                        </Hidden>
                      </Box>
                      <Hidden smUp>
                        {showButtonAddCard()}
                      </Hidden>
                    </StyledBoxContainer>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </StyledBox>
        <LoadingBackdrop loading={this.state.loading} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainerBilling: {
    [theme.breakpoints.down("md")]: {
      padding: "0 1rem 8rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0rem 8rem !important",
      marginTop: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0rem 4rem !important"
    },
  },
  mainHeaderText: {
    [theme.breakpoints.down("xs")]: {
     fontSize: "24px !important"
    },
  },
  goldText: {
    [theme.breakpoints.down("xs")]: {
     fontSize: "20px !important"
    },
  },
  headingText: {
    [theme.breakpoints.down("xs")]: {
     fontSize: "18px !important"
    },
  },
  planText: {
    [theme.breakpoints.down("xs")]: {
     fontSize: "16px !important"
    },
  },
  accountStatusContainer: {
    [theme.breakpoints.down("xs")]: {
     padding: "0.6rem !important",
     minHeight: "91px !important"
    },
  },
  planContainer: {
    [theme.breakpoints.down("xs")]: {
      gap: "0.8rem !important"
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "106px",
    width: "100%",
    minHeight: "40px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.darkBlue,
    textTransform: "none" as const,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important"
    },
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        cursor: "pointer",
    },
  },
  buttonManagePlan: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem !important"
    },
  },
  buttonAddCard: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.5rem !important"
    },
  },
  buttonText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important"
    },
  },
  payImage: {
    [theme.breakpoints.down("xs")]: {
      width: "89px !important",
      height: "122px !important"
    },
  },
  paymentMethodContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem !important",
    },
  },
  invoiceHr: {
    [theme.breakpoints.down("xs")]: {
      margin: "1.5rem 0 !important",
    },
  },
  invoiceTextHeading: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      paddingBottom: "8px !important"
    },
  },
  invoiceDescHeading: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },
  invoiceTitleContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 0 !important",
    },
  },
});

const webStyle = {
  boxContainerBilling: {
    marginTop: "8.5rem",
    paddingBottom: "10rem"
  },
  mainHeaderText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    paddingBottom: "1.5rem"
  },
  invoiceLabelStyle: {
    padding: "2rem 0 1rem"
  },
  paddingStyle: {
    paddingTop: "2rem"
  },
  headingText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    paddingBottom: "10px"
  },
  textAlignCenter: {
    textAlign: "center" as const
  },
  accountStatusContainer: {
    width: "100%",
    height: "100%",
    minHeight: "153px",
    borderRadius: "8px",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    padding: "1rem 1rem 1rem"
  },
  styledBox: {
    padding: "1rem 1rem"
  },
  styledInvoiceBox: {
    height: "auto",
    padding: "1.2rem 1.5rem 1.5rem",
    justifyContent: "flex-start",
    marginTop: "1rem"
  },
  invoiceBoxStyle: {
    flexDirection: 'row' as const,
    justifyContent: 'flex-start',
    gap: "1rem",
    padding: "1rem 1rem",
  },
  invoiceBoxInnerStyle: {
    flexDirection: "column" as const,
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: "0.5rem"
  },
  accountStatusPadding: {
    padding: "0.5rem 1rem 0.5rem 1rem"
  },
  payImage: {
    width: "101px",
    height: "138px",
  },
  planText: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  lightGreyStyle: {
    color: CONSTANTS.color.lightGrey
  },
  activeText: {
    color: CONSTANTS.color.limeGreen,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "24px",
  },
  activeContainer: {
    display: "flex",
    alignItems: "center",
    gap: '1rem',
  },
  darkBlueStyle: {
    color: CONSTANTS.color.darkBlue
  },
  lightBrownStyle: {
    color: CONSTANTS.color.lightBrown
  },
  paymentMethodStyle: {
    color: CONSTANTS.color.lightBrown,
    paddingBottom: "0.5rem"
  },
  activeDot: {
    width: "16px",
    height: "16px",
    backgroundColor: CONSTANTS.color.limeGreen,
    borderRadius: "50%",
  },
  managePlanContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  buttonText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  hr: {
    display: "flex",
    height: "1px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "1rem 0 2rem"
  },
  invoiceHr: {
    display: "flex",
    height: "1px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "2rem 0 2rem"
  },
  invoiceBody: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    lineHeight: "24px",
  },
  noInvoicesImage: {
    width: "114px",
    height: "106px",
  }
}
const BillingStyles =  withStyles(styles)(Billing);
// @ts-ignore
const BillingSnackbar = withSnackbar(BillingStyles)
export default withTranslation()(withRouter(BillingSnackbar))
// Customizable Area End