Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.getNotificationsAPiEndPoint = "bx_block_notifications/notifications";
exports.updateNotificationsAPiEndPoint = "bx_block_notifications/mark_as_read";
exports.deleteNotificationsAPiEndPoint = "bx_block_notifications/notifications";
exports.markAllAPiEndPoint = "bx_block_notifications/mark_as_completed"
exports.deleteAllAPiEndPoint = "bx_block_notifications/delete_all"
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PATCH";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
// Customizable Area End