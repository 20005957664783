import React from "react";

// Customizable Area Start
import {
  Box,
  Container
} from "@material-ui/core";
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { ReportsSideBar } from "./ReportsSideBar.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ReportsController, {
  Props,
} from "./ReportsController.web";

// Customizable Area Start
// Customizable Area End

export class Reports extends ReportsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.report')} active={false} active1={false} active2={0} />
        <StyledRoot>
          <Box
            sx={webStyle.reportsContainer}
            className={classes.reportsContainer}
          >
            <Container maxWidth={false}>
              <ReportsSideBar {...this.props} />
            </Container>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  reportsContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important"
    }
  },
});

const webStyle = {
  reportsContainer: {
    marginTop: "8rem",
  }
}

const ReportsSnackbar = withSnackbar(Reports)
export default withTranslation()(withStyles(styles)(ReportsSnackbar));
// Customizable Area End