import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core/styles";
import { getStorageData } from "../../../framework/src/Utilities";
import { checkTokenExpired,debounce } from "../../../components/src/CommonFunction";
export const configJSON = require("./config");
interface SelectTabItem{
  id:number
  title:string
  tabName:string
}
interface SearchResultArray {
  "type": string,
  "name": string,
  "email": string,
  "id": number,
  "title":string
  "start_date": string,
  "end_date": string,
  "customer_name"?: string,
  "code": string,
  "employee_image_url":string
  "employee_email":string
  "employee_name":string
  "appointment_date":string
  "appointment_time":string
  "status":string
}
interface MockResponse{
  "results": SearchResultArray[]
  "length":number,
  "errors":string
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  languageSelected: string;
  selectTab:string;
  loading:boolean;
  searchListData: MockResponse | null;
  advanceSearch:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSearchApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      languageSelected: "",
      selectTab:'',
      loading:false,
      searchListData:null,
      advanceSearch:''
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedlanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedlanguage });
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getSearchApiCallId) {
      this.searchApiResponse(responseJson);

    }
    // Customizable Area End
  }

  // Customizable Area Start
  selectTab = (item: SelectTabItem) => {
    this.setState({ selectTab: item.tabName }, () => {
      this.debounceSearch()
    })
  }
  handleSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ advanceSearch: event.target.value }, () => {
      this.debounceSearch();
    });
  }
  handleClear = () => {
    this.setState({ advanceSearch:'' })
  }
  searchApiResponse = (responseJson:MockResponse) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ searchListData: responseJson },
       () => this.setState({ loading: false }))
    } else if (responseJson.errors) {
      this.setState({ searchListData: null,loading: false });
    }
  }
  getSearch = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.getAdvancedSearchApiEndPoint
    let getSearchUrl
    if (!this.state.selectTab) {
      getSearchUrl = `${apiEndPoint}?query=${this.state.advanceSearch}`
    } else{
      getSearchUrl = `${apiEndPoint}?query=${this.state.advanceSearch}&tab=${this.state.selectTab}`
    }
    this.setState({ loading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getSearchUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  debounceSearch = debounce(this.getSearch, 700);
}
// Customizable Area End
