Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.passtext="Kumar@123"
exports.validationApiMethodType = "GET";
exports.signUpUrl="/account_block/accounts"
exports.forgotPassword="/bx_block_forgot_password/otps"
exports.confirmotp="/bx_block_forgot_password/otp_confirmations"
exports.emailRegex=/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
exports.charactersregex=/^[\u0600-\u06FFa-zA-Z]+$/
exports.numRegex=/^\d+$/
exports.passRegex=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{2,}/
exports.countryList="bx_block_settings/region_selection"
exports.signup="/account_block/accounts"
exports.industries="/account_block/industries"
exports.profileAnswes="/account_block/profile_questions"
exports.countryCodeList="/account_block/contry_code_listing";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";

// Customizable Area End
