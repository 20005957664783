import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  ClickAwayListener,
  InputAdornment,
} from "@material-ui/core";
import {
  withStyles
} from "@material-ui/core/styles";
import { dropDownIcon } from "../assets/assets";
import { DeleteDialog } from "./DeleteDialog.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import {
  StyledDepositDiv,
  StyledDiscountDiv,
  StyledInputBase,
  StyledTaxDiv,
  StyledTextFields
} from "./CreatePurchase.web";
export const configJSON = require("./config");
import CustomDatePicker from "../../../components/src/DatePicker";
import PriceSectionWeb from "../../QuoteManagement/src/PriceSection.web";
import { CONSTANTS } from "../../../components/src/theme";
import { StyleGrid } from "./CreateSupplier.web";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
// Customizable Area End

import PurchasesController, {
  Props,
} from "./PurchasesController.web";

// Customizable Area Start
const getConditionalStyleModal = (isError: any) => {
  return isError && webStyle.errorModalStyle;
};

const getConditionalStyle = (isError: any) => {
  return isError && webStyle.errorStyle;
};

const renderErrorMessage = (errorText: any) => {
  return (
    <Typography style={webStyle.errorText} variant="body2">
      {errorText}
    </Typography>
  );
};

interface MockDeposit {
  id: number;
  value: string;
}

const purchaseFormContainerStyle = (languageSelected: string, classes: ClassNameMap) => {
  return languageSelected === "ar" ? classes?.purchaseFormContainerAr : classes?.purchaseFormContainer
}

const rateContainerStyle = (languageSelected: string, classes: ClassNameMap) => {
  return languageSelected === "ar" ? classes?.rateLabelContainerAr : classes?.rateLabelContainer
}

const depositStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.discountDialog, ...webStyle.depositDialogDiv, left: "24%"} : {...webStyle.discountDialog, ...webStyle.depositDialogDiv}
}

const depositTextStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.modalTexts, ...webStyle.modalTextDepositBackDrop, right: "45%"} : {...webStyle.modalTexts, ...webStyle.modalTextDepositBackDrop}
}

const selectedMethodStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? {transform: "translate(-74.3%, 5%)"} : {}
}

const transactionDateStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? { ...webStyle.depositContainer, ...webStyle.transactionDateStyle, gap: "2.5rem" } : { ...webStyle.depositContainer, ...webStyle.transactionDateStyle }
}
// Customizable Area End

export class PurchaseData extends PurchasesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  currencies = () => {
    const { classes }: any = this.props;
    return (
      <Box style={webStyle.selectContainerBox}>
        <Typography
          className={classes?.selectCurrencyContainer}
          value={this.props.currency}
          disabled
          component={"select"}
          variant="body1"
        >
            <Typography
              className={classes?.selectCurrency}
              component={"option"}
              variant="body1"
              value={this.props.currency}
            >
              {this.props.currency}
            </Typography>
        </Typography>
      </Box>
    );
  }

  renderSubTotal = () => {
    const { price, quantity } = this.state.formData;
    const { currency } = this.state;
    const { t } = this.props;
    return (
      <Box style={webStyle.subTotal}>
        <Typography variant="body2" style={webStyle.rateHeader} color="textPrimary">
          {t('suppliers.subtotal')}
          </Typography>
        <Typography variant="body2" style={webStyle.total}>
          {(price && quantity) ? `${currency}${(price * quantity).toFixed(2)}` : "0"}
        </Typography>
      </Box>
    );
  }

  discountDialog = () => {
    const { classes }: any = this.props;
    const { t } = this.props;
    return (
      <>
        <Box style={webStyle.subTotal}>
          <Typography variant="body2" style={webStyle.rateHeader} color="textPrimary">{t('suppliers.discount')}</Typography>
          <Typography variant="body2" style={webStyle.modalTexts} onClick={this.handleDiscountDialogOpen}>
            {!this.state.data.discount ? `+ ${t('suppliers.discount')}` : `${this.props.currency}${this.state.data.discount}%`}
          </Typography>
        </Box>

        {this.state.discountDialog && (
          <div>
            <div className={classes?.backDropStyles} />
            <StyledDiscountDiv languageSelected={this.state.languageSelected} style={this.state.languageSelected === "ar" ? {...webStyle.discountDialog, left: "78%"} : webStyle.discountDialog} ref={this.wrapperRef}>
              <Typography variant="body2" style={{...webStyle.modalTexts, ...webStyle.modalTextBackDrop}}>
                {`+ ${t('suppliers.discount')}`}
              </Typography>
              <Typography component={'div'} className={classes?.discountText}>{t('suppliers.discount')}</Typography>
              <span className={classes?.horizontalHr}></span>
              <Box style={this.state.isErrorDiscountFormData.discount ? {...webStyle.currencyContainer, margin: "22px 0px 5px"} : webStyle.currencyContainer}>
                {this.currencies()}
                <StyledInputBase
                  data-test-id="formDataDiscount"
                  style={{...getConditionalStyleModal(this.state.isErrorDiscountFormData.discount),
                    ...webStyle.inputField}}
                  placeholder="0.00"
                  value={this.state.discount}
                  onChange={this.handleChangeDiscount}
                  error={Boolean(this.state.isErrorDiscountFormData.discount)}
                  disabled={!this.state.isEdit}
                />
              </Box>
              <Box style={{paddingBottom: "5px"}}>
                {renderErrorMessage(this.state.isErrorDiscountFormData.discount)}
              </Box>
              <Box style={webStyle.dialogButtonsContainer} className={classes?.dialogButtonsContainer}>
                <Button
                  className={`${classes?.buttonStyle} ${classes?.buttonStylePurchases}`}
                  onClick={this.handleCancelDiscountDialog}
                >
                  <Typography variant="caption">
                    {t('suppliers.cancelUpper')} 
                  </Typography>
                </Button>
                <Button
                  className={`${classes?.buttonStyle} ${classes.addBtnContainer} ${classes?.buttonStylePurchase}`}
                >
                  <Typography variant="caption">
                    {t('suppliers.addUpper')}
                  </Typography>
                </Button>
              </Box>
            </StyledDiscountDiv>
          </div>
        )}
      </>
    );
  }

  taxDialog = () => {
    const { t } = this.props;
    const { taxName, taxRate } = this.state.taxFormData;
    const { classes }: any = this.props;
    return (
      <>
        <Box style={webStyle.subTotal}>
          <Typography variant="body2" style={webStyle.rateHeader} color="textPrimary">{t('suppliers.tax')}</Typography>
          <Typography variant="body2" style={webStyle.modalTexts} onClick={this.handleTaxDialogOpen}>
            {!this.state.data.taxName && !this.state.data.taxRate ? `+ ${t('suppliers.tax')}` : `${this.state.data.taxName || ""} ${this.state.data.taxRate}%`}
          </Typography>
        </Box>

        {this.state.taxDialog && (
          <div>
            <div className={classes?.backDropStyles} />
            <StyledTaxDiv style={{...webStyle.discountDialog, ...webStyle.taxDialogDiv}} ref={this.wrapperRef}>
              <Typography variant="body2" style={{...webStyle.modalTexts, ...webStyle.modalTextTaxBackDrop}}>
                {`+ ${t('suppliers.tax')}`}
              </Typography>
              <Typography component={'div'} className={classes?.discountText}>{t('suppliers.createTaxRate')}</Typography>
              <span className={classes?.horizontalHr}></span>
              <Box
                style={(this.state.isErrorTaxFormData.taxName || this.state.isErrorTaxFormData.taxRate) ? {...webStyle.taxInputContainer, margin: "22px 0px 5px"} : webStyle.taxInputContainer}
              >
                <Box
                  style={{
                    ...getConditionalStyleModal(this.state.isErrorTaxFormData.taxName),
                    ...{display: "flex", border: !this.state.isErrorTaxFormData.taxName ? `1px solid ${CONSTANTS.color.beigeGrey}` : `1px solid ${CONSTANTS.color.red}`},
                    ...webStyle.taxNameInput
                  }}
                >
                  <StyledInputBase
                    data-test-id="formDataTax"
                    style={webStyle.inputField}
                    placeholder="Tax name"
                    name="taxName"
                    value={taxName}
                    onChange={this.handleChangeTax}
                    error={Boolean(this.state.isErrorTaxFormData.taxName)}
                    disabled={!this.state.isEdit}
                  />
                </Box>
                <StyledInputBase
                  data-test-id="formDataTax"
                  style={{
                    ...getConditionalStyleModal(this.state.isErrorTaxFormData.taxRate),
                    ...{border: !this.state.isErrorTaxFormData.taxRate ? `1px solid ${CONSTANTS.color.beigeGrey}` : `1px solid ${CONSTANTS.color.red}`},
                    ...webStyle.inputField
                  }}
                  placeholder="Tax rate (%)"
                  name="taxRate"
                  value={taxRate}
                  onChange={this.handleChangeTax}
                  error={Boolean(this.state.isErrorTaxFormData.taxRate)}
                  disabled={!this.state.isEdit}
                />
              </Box>
              <Box style={{display: "flex", paddingBottom: "5px"}}>
                {renderErrorMessage(this.state.isErrorTaxFormData.taxName)}
                {renderErrorMessage(this.state.isErrorTaxFormData.taxRate)}
              </Box>
              <Box style={webStyle.dialogButtonsContainer}>
                <Button
                  className={`${classes?.buttonStyleCancel} ${classes?.buttonStylePurchases}`}
                  onClick={this.handleCancelTaxDialog}
                >
                  <Typography variant="caption">
                    {t('suppliers.cancelUpper')}
                  </Typography>
                </Button>
                <Button
                  style={{maxWidth: "147px"}}
                  className={`${classes?.buttonStyle} ${classes.addBtnContainer} ${classes?.buttonStylePurchase}`}
                >
                  <Typography variant="caption">
                    {t('suppliers.createTaxRate')}
                  </Typography>
                </Button>
              </Box>
            </StyledTaxDiv>
          </div>
        )}
      </>
    );
  }

  value = (method: any) => method === 'bank_transfar' ?
    'Bank Transfer' :
    method?.split('_')?.map((word: string) => word?.charAt(0).toUpperCase() + word?.slice(1))?.join(' ')

  valueAr = (method: string) => {
    if(method === "cash") {
      return "نقدي";
    } else if(method === "cheque") {
      return "يفحص";
    } else if(method === "credit_card") {
      return "بطاقة إئتمان";
    } else if(method === "bank_transfar") {
      return "التحويل المصرفي";
    } else if(method === "money_order") {
      return "حوالة مالية";
    } else if(method === "other") {
      return "آخر";
    }
    return method;
  }
    
  depositMethodValue = (item: Record<string, string>) => item?.value === 'bank_transfar' ?
  'Bank Transfer' :
  item?.value?.split('_')?.map((word: string) => word?.charAt(0).toUpperCase() + word?.slice(1))?.join(' ')

  depositMethodValueAr = (item: MockDeposit) => {
    if(item?.value === "cash") {
      return "نقدي";
    } else if(item?.value === "cheque") {
      return "يفحص";
    } else if(item?.value === "credit_card") {
      return "بطاقة إئتمان";
    } else if(item?.value === "bank_transfar") {
      return "التحويل المصرفي";
    } else if(item?.value === "money_order") {
      return "حوالة مالية";
    } else if(item?.value === "other") {
      return "آخر";
    }
    return item;
  }

  depositMethodValueCallback = (languageSelected: string, method: string) => {
    return languageSelected === "ar" ? this.valueAr(method) : this.value(method);
  }

  depositMethodCallback = (languageSelected: string, item: Record<string, string>) => {
    return languageSelected === "ar" ? this.depositMethodValueAr(item as unknown as MockDeposit) : this.depositMethodValue(item);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const { languageSelected } = this.state;
    const { t } = this.props;
    const depositMock = [
      { id: 1, value: "cash" },
      { id: 2, value: "cheque" },
      { id: 3, value: "credit_card" },
      { id: 4, value: "bank_transfar" },
      { id: 5, value: "money_order" },
      { id: 6, value: "other" },
    ]
    const { purchaseDate, price, quantity, itemDescription } = this.state.formData;
    const { method, amount, transactionDate, details } = this.state.depositFormData;
    const { classes }: any = this.props;
    return (
      <>
        <Box className={classes?.purchasesContainer}>
          <Typography style={webStyle.personalInfoTextPurchaseData} className={classes?.headingText} variant="subtitle1">
            {t('suppliers.purchaseOrder')}
          </Typography>
          <Box className={purchaseFormContainerStyle(languageSelected, classes)}>
            <Grid item lg={9} md={12} xs={12}>
              <StyleGrid container spacing={10}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className={classes?.formDataText} variant="body1" color="textPrimary" gutterBottom>
                    {t('suppliers.item')}
                  </Typography>
                  <StyledTextFields
                    style={{backgroundColor: CONSTANTS.color.offWhiteGrey}}
                    fullWidth
                    variant="outlined"
                    disabled
                    value={this.state.singlePurchaseData?.attributes?.item}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className={classes?.formDataText} variant="body1" color="textPrimary" gutterBottom>
                    {t('suppliers.purchaseDate')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                  </Typography>
                  <CustomDatePicker
                    disabled
                    selectedDate={purchaseDate}
                  />
                </Grid>
              </StyleGrid>
            </Grid>
            <Box className={classes?.gridItems}>
              <Grid item lg={9} md={12} xs={12}>
                <StyleGrid container spacing={10}>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes?.formDataText} variant="body1" color="textPrimary" gutterBottom>
                      {t('suppliers.price')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.price)}
                      fullWidth
                      variant="outlined"
                      name="price"
                      value={price}
                      onChange={this.handleChangeFormData}
                      disabled={!this.state.isEdit}
                      error={Boolean(this.state.isErrorFormData.price)}
                    />
                    {renderErrorMessage(this.state.isErrorFormData.price)}
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes?.formDataText} variant="body1" color="textPrimary" gutterBottom>
                      {t('suppliers.quantity')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.quantity)}
                      fullWidth
                      variant="outlined"
                      name="quantity"
                      value={quantity}
                      onChange={this.handleChangeFormData}
                      disabled={!this.state.isEdit}
                      error={Boolean(this.state.isErrorFormData.quantity)}
                    />
                    {renderErrorMessage(this.state.isErrorFormData.quantity)}
                  </Grid>
                </StyleGrid>
              </Grid>
            </Box>
            <Box className={classes?.gridItems}>
              <Grid item lg={9} md={12} xs={12}>
                <StyleGrid container spacing={10}>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography className={classes?.formDataText} variant="body1" color="textPrimary" gutterBottom>
                      {t('suppliers.itemDescription')}
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.itemDescription)}
                      fullWidth
                      multiline
                      minRows={4}
                      variant="outlined"
                      helperText={
                        <div className={classes?.helperText}>
                          {renderErrorMessage(this.state.isErrorFormData.itemDescription)}
                          <span style={itemDescription?.length > 500 ? { color: CONSTANTS.color.red } : {}}>
                            {`${itemDescription?.length}/500`}
                          </span>
                        </div>
                      }
                      name="itemDescription"
                      value={itemDescription}
                      onChange={this.handleChangeFormData}
                      disabled={!this.state.isEdit}
                      error={Boolean(this.state.isErrorFormData?.itemDescription || itemDescription?.length > 500)}
                    />
                  </Grid>
                </StyleGrid>
              </Grid>
            </Box>
          </Box>

          <Box className={rateContainerStyle(languageSelected, classes)}>
            <Typography style={{...webStyle.personalInfoTextPurchaseData, padding: "1rem 0rem 0rem"}} className={classes?.rateLabelMobile} variant="subtitle1">
              {t('suppliers.rate')}
            </Typography>
              <PriceSectionWeb
                data={this.state.formData}
                priceSectionData={this.state.priceSectionData}
                handlePriceSection={this.handlePriceSection}
                emptyPriceSectionData={this.emptyPriceSectionData}
                selectTaxRate={this.selectTaxRate}
                isEditable={this.state.isEdit}
                calculateTotal={this.calculateTotal}
                currency={this.state.currency}
                getSubTotal={this.getSubTotal}
                invoicePayment={this.getSubTotal}
                selectCurrency={this.selectCurrency}
                selectedCurrency={this.state.selectCurrency}
                handleCollectionPaymentSave={this.handleCollectionPaymentSave}
                handleChange={this.handleChange}
                onClose={this.handlePaymentModalClose}
                discountDialog={this.state.discountDialog}
                handleDiscountDialogOpen={this.handleDiscountDialogOpen}
                collectedPaymentForm={this.state.collectedPaymentForm}
                handleChangePaymentMethodRef={this.handleChangePaymentMethodRef}
                handleCollectionPaymentModal={this.handleCollectionPaymentModal}
                handleCollectionPaymentClose={this.handleCollectionPaymentClose}
                handleTransactionDate={this.handleTransactionDate}
                handlePay_balence={this.handlePayBalance}
                handleCollected={this.handleCollected}
                  />
               
          </Box>
        </Box>
        
        <Box style={webStyle.buttonsContainerPurchaseData} className={classes?.buttonContainer}>
          {!this.state.isEdit ? 
            <Button
              className={`${classes?.buttonStyleEdit} ${classes.buttonSaveSuppliers} ${classes?.buttonStyleCommon}`}
              onClick={this.handleClickEditPurchase}
            >
              <Typography
                style={webStyle.btnTextSave}
                className={classes?.buttonTextMobile}
                variant="subtitle2"
              >
                {t('suppliers.edit')}
              </Typography>
            </Button>
          :
            <Button
              className={`${classes?.buttonStyleEdit} ${classes.buttonSaveSuppliers} ${classes?.buttonStyleCommon}`}
              onClick={this.handleUpdatePurchase}
            >
              <Typography
                style={webStyle.btnTextSave}
                className={classes?.buttonTextMobile}
                variant="subtitle2"
              >
                {t('suppliers.update')}
              </Typography>
            </Button>
          }
          
          <Box className={classes?.buttonsInnerContainer}>
            <Button
              className={`${classes.buttonStyleMobile}  ${classes.btnBoxCancel} ${classes.buttonStyleCommon}`}
              onClick={this.handleCancelPurchase}
            >
              <Typography
                style={webStyle.btnTextCancel}
                className={classes?.buttonTextMobile}
                variant="subtitle2"
              >
                {t('suppliers.cancel')}
              </Typography>
            </Button>

            <DeleteDialog
              {...this.props}
              title={t('suppliers.deletePurchase')}
              description={t('suppliers.deletePurchaseConfirmation')}
              deleteDialogOpen={this.state.deleteDialogOpen}
              handleClickOpenDialog={this.handleClickOpenDialog}
              handleCloseDialog={this.handleCloseDialog}
              handleClickProceedDelete={this.handleClickProceedDelete}
              loader={this.state.deletePurchaseLoading && (
                <Box>
                  <LoadingBackdrop
                    loading={this.state.deletePurchaseLoading}
                  />
                </Box>
              )}
            />
          </Box>
        </Box>
        <LoadingBackdrop
          loading={this.state.getSinglePurchasesLoading || this.state.editPurchaseLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});


const webStyle = {
  personalInfoTextPurchaseData: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    padding: "2.5rem 4rem 0rem"
  },
  buttonsContainerPurchaseData: {
    padding: "3rem 0",
    display: "flex",
    flexDirection: "row-reverse" as const,
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  btnTextCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  btnTextSave: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  errorMsg: {
    marginTop: "5px",
    color: CONSTANTS.color.red
  },
  rateInnContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center" as const,
    height: "100%"
  },
  subTotal: {
  },
  rateHeader: {
  },
  total: {
  },
  modalTexts: {
  },
  discountDialog: {
  },
  modalTextBackDrop: {
   
  },
  modalTextTaxBackDrop: {
  },
  modalTextDepositBackDrop: {
 
  },
  depositContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative" as const,
  },
  textAlignEnd: {
    textAlign: "end" as const
  },
  depositTexts: {
    color: CONSTANTS.color.neutralGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  dropDownIcon: {
    width: "12px",
    height: "7px"
  },
  dialogButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px"
  },
  currencyContainer: {
    margin: "22px 0 22px",
    width: "100%",
    maxWidth: "194px",
    minHeight: "48px",
    borderRadius: "6px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex"
  },
  taxInputContainer: {
    maxWidth: "305px",
    minHeight: "56px",
    margin: "22px 0 22px",
    width: "100%",
    borderRadius: "6px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex"
  },
  selectContainerBox: {
    borderRight: `1px solid ${CONSTANTS.color.paleCement}`,
    width: "96px"
  },
  selectMethodOptn: {
    color: CONSTANTS.color.neutralGrey,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "justify" as const,
    padding: "2px 15px",
    cursor: "pointer"
  },
  selectedMethodOptn: {
    padding: "5px 15px",
    backgroundColor: "rgba(28,24,175,0.16)",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 10%)",
    color: CONSTANTS.color.darkBlue,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  inputField: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px"
  },
  taxNameInput: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px"
  },
  taxDialogDiv: {
    maxWidth: "351px",
    minHeight: "214px",
    top: "173%",
    left: "61%"
  },
  subTotalDialogDiv: {
    left: "14.5%"
  },
  errorText: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },
  errorStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "10px"
  },
  errorModalStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "5px",
    border: `1px solid ${CONSTANTS.color.lightRed}`
  },
  transactionDateStyle: {
   
  },
  depositDialogDiv: {
 
  },
  redColor: {
    color: CONSTANTS.color.red,
  }
}

export default withTranslation()(withStyles(styles)(PurchaseData));
// Customizable Area End