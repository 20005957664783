import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { extensionDoc, extensionJpg, extensionPdf, extensionPng, extensionPpt, extensionTxt, extensionXls } from "./assets";
// Customizable Area Start
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    closeEmailModal: Function
    open: boolean
    jobDetails: any
    type: string
    history: any
    openToastHandler: Function,
    customerEmail:string
    classes:ClassNameMap<string>
    t?: (key: string) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    emailModalStatus: boolean
    emailForm: {
        receptient: string,
        subject: string,
        messageDescription: string
    },
    emailJobDetails: any,
    isErrorFormData: any,
    clienAttachments: any
    attachments: any,
    extensionIcons: any
    progress: any;
    completed: boolean;
    count: number;
    is_email: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EmailModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIDSendEmail: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            emailModalStatus: false,
            emailForm: {
                receptient: "",
                subject: "",
                messageDescription: ""
            },
            emailJobDetails: {},
            isErrorFormData: {},
            clienAttachments: [],
            attachments: [],
            extensionIcons: {
                jpg: extensionJpg,
                png: extensionPng,
                jpeg: extensionJpg,
                doc: extensionDoc,
                pdf: extensionPdf,
                xls: extensionXls,
                txt: extensionTxt,
                ppt: extensionPpt,
                docx: extensionDoc,
                xlsx: extensionXls,
                pptx: extensionPpt,
            },
            progress: 0,
            completed: false,
            count: 0,
            is_email: true
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount(): Promise<void> {
        if (this.props.open) {
            this.setState({
                
                emailModalStatus: this.props.open,
                emailJobDetails: this.props.jobDetails,
                emailForm:{...this.state.emailForm,receptient:this.props.customerEmail}
                
            })
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.apiCallIDSendEmail) {
                if (responseJson.message) {
                    this.setState({
                        emailModalStatus: false
                    },
                        () => {
                            this.props.closeEmailModal(this.state.emailModalStatus,"success")
                            if(this.props.type !== "Quote") {
                                this.props.history.push("/Job/Edit", {
                                    jobId: this.state.emailJobDetails.id
                                })
                            }
                        })


                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start



    handleEmailModalClose = () => {
        this.setState({
            emailModalStatus: false
        },
            () => {
                this.props.closeEmailModal(this.state.emailModalStatus)
            })
    }
    handleEmailChange = (event: any) => {
        this.setState({
            emailForm: { ...this.state.emailForm, [event.target.name]: event.target.value }
        })

    }

    validationEmailForm = (emailForm: any) => {
        let errors: any = {}
        if (!emailForm.receptient) {
            errors.receptient = "Email address is required"
        }else if (!configJSON.emailValidation.test(emailForm.receptient)) {
            errors.receptient = "Please enter valid email address";
          }
        return errors

    }

    uploadFiles = (event: any) => {
        const files = event.target.files;
        this.proceedEmailUpload(files);
    };
    proceedEmailUpload = (files: any) => {
        if (files.length > 0) {
            // Update state with new unique files
            this.setState({ attachments: [...this.state.attachments, ...files] }, () => {
                this.emailPogressFileUpload(this.state.attachments);
            });
        }
    };

    handleEmailFileRemove=(file:any)=>{
        const { attachments } = this.state;
        const attachmentsFiltered = attachments.filter((f: any) => {
          return f.name !== file.name;
        });
        this.setState(
            {
              attachments:attachmentsFiltered
            })
    }

    emailPogressFileUpload = (files: any) => {
        if (files) {
            for (const file of files) {
                const lastindexDot = file.name?.lastIndexOf(".");
                const ext = lastindexDot !== -1 ? file.name?.substring(lastindexDot + 1) : "";
                const fileSize = 10 * 1024 * 1024; // 10MB file size limit

                if (file.size > fileSize) {
                    this.setState((prevState) => ({
                        attachments: prevState.attachments.filter((existingFile: any) => existingFile.name !== file.name),
                    }));
                    continue; // Skip the file and proceed to the next iteration
                }

                if (!this.state.extensionIcons[ext]) {
                    this.setState((prevStateV1) => ({
                        attachments: prevStateV1.attachments.filter((existingFile: any) => existingFile.name?.substring(existingFile.name?.lastIndexOf(".") + 1) !== ext),
                    }));
                    continue; // Skip the file and proceed to the next iteration
                }
                const fileReader = new FileReader();
                fileReader.onloadstart = this.fileUploadLoadStart;
                fileReader.onprogress = (event: any) => this.fileUploadOnPogress(event);
                fileReader.onload = this.fileUploadOnLoad;
                fileReader.readAsDataURL(file);
            }
        }
    };

    fileUploadLoadStart = () => {
        this.setState({ progress: 0 });
    };

    fileUploadOnPogress = (event: any) => {
        if (event.lengthComputable) {
            let lodedFile = 0;
            let totalFileSize = 0;
            for (let file of this.state.attachments) {
                lodedFile += file.size * (event.loaded / event.total);
                totalFileSize += file.size;
            }

            const completedPercentage = (lodedFile / totalFileSize) * 100;

            this.setState({ progress: completedPercentage });
        }
    };
    onLoadReadCallbackTimeout = () => {
        this.setState({ completed: true });
    };

    onLoadReadCallBack = () => {
        if (this.state.count === this.state.attachments.length) {
            this.setState({ progress: 100 });
            setTimeout(this.onLoadReadCallbackTimeout, 500);
        }
    };
    fileUploadOnLoad = () => {
        this.setState(
            (prevState) => ({
                count: prevState.count + 1,
            }),
            this.onLoadReadCallBack
        );
    };

    sendEmailBtn = () => {
        this.setState({ isErrorFormData: this.validationEmailForm(this.state.emailForm) },
            () => {
                if (Object.keys(this.state.isErrorFormData).length === 0) {
                    this.sendEmail();
                }
            }
        );
    }
    sendEmail = () => {
        const header = {
            "token": localStorage.getItem(configJSON.storageToken)
        };
        let sendEmailBody = new FormData();
        if (this.props.type === "invoice") {
            sendEmailBody.append("is_email", this.state.is_email);
        }
        sendEmailBody.append("to", this.state.emailForm.receptient);
        sendEmailBody.append("subject", this.state.emailForm.subject);
        sendEmailBody.append("body", this.state.emailForm.messageDescription);
        for (let file of this.state.attachments) {
            sendEmailBody.append("attachments[]", file);
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiCallIDSendEmail = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), sendEmailBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.props.type === "Quote" ? `${configJSON.sendQuoteEmailEndPoint}?quote_id=${this.props.jobDetails.id}` : configJSON.sendEmailEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOnEmailDragOver = (event: any) => {
        event.preventDefault();
    }

    handleOnEmailDrop = (event: any) => {
        event.preventDefault();
        const file = event.dataTransfer.files;
        this.proceedEmailUpload(file);

    }
    // Customizable Area End
}
