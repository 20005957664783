import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface IResponceNotes {
  "data": [
    {
      id: string,
      type: string,
      attributes: {
        id: number,
        note: string,
        user_id: number,
        is_deleted: boolean,
        is_updated: boolean,
        update_on: null,
        created_at: string,
        updated_at: string,
        created_time: string,
        created_date: string
      }
    }
  ],
  errors: null,
}

interface INotes {
  id: string,
  type: string,
  attributes: {
    id: number,
    note: string,
    user_id: number,
    is_deleted: boolean,
    is_updated: boolean,
    update_on: null,
    created_at: string,
    updated_at: string,
    created_time: string,
    created_date: string
  }
}

interface DeleteNotes {
  message: string,
  errors: null,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isShowNotesModal: boolean;
  isChecktext: boolean;
  notesTitle: string;
  notes: string;
  checkUpdateNotes: boolean;
  notesList: Array<INotes>,
  isUpdate: boolean;
  isCreate: boolean;
  notesId: string | number;
  isDelete: boolean;
  note: string;
  isDeleteModalOpen: boolean;
  deleteItem: null | number | string;
  token: string


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateNotesCallId: string = "";
  createNotesCallId: string = "";
  getNotesCallId: string = "";
  deleteNotesCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isShowNotesModal: false,
      isChecktext: false,
      notesTitle: '',
      notes: '',
      checkUpdateNotes: false,
      notesList: [],
      isUpdate: false,
      isCreate: false,
      notesId: '',
      isDelete: false,
      note: '',
      token: '',
      isDeleteModalOpen: false,
      deleteItem: null,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token) {
        this.setState({ token: token }, () => {
          this.getNotes()
        });
      } else {
        this.showAlert("Alert", configJSON.loginAlertMessage);
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (apiRequestCallId === this.getNotesCallId) {
          this.notesResponse(responseJson, errorReponse)
        }
        if (apiRequestCallId === this.updateNotesCallId) {
          this.updateNotesResponse(responseJson, errorReponse)
        }
        if (apiRequestCallId === this.createNotesCallId) {
          this.createNotesResponse(responseJson, errorReponse)
        }
        if (apiRequestCallId === this.deleteNotesCallId) {
          this.deleteNotesResponse(responseJson, errorReponse)
        }

      }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getToken()
  }

  apiCall = async (data: { contentType: string, method: string, endPoint: string, body?: object }) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  notesResponse = (responseJson: IResponceNotes, errorReponse: {}) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.getNotesCallIdCallBack(responseJson)
      } else {
        this.getNotesCallIdFailureCallBack(errorReponse);
      }
    }
  }

  updateNotesResponse = (responseJson: IResponceNotes, errorReponse: {}) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.updateNotesCallIdCallBack(responseJson)
      } else {
        this.getNotesCallIdFailureCallBack(responseJson);
      }
    }
  }

  createNotesResponse = (responseJson: IResponceNotes, errorReponse: {}) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.createNotesCallIdCallBack(responseJson)
      } else {
        this.createNotesCallIdFailureCallBack(responseJson);
      }
    }
  }

  deleteNotesResponse = (responseJson: DeleteNotes, errorReponse: {}) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.deleteNotesCallIdCallBack(responseJson)
      } else {
        this.getNotesCallIdFailureCallBack(responseJson);
      }
    }
  }

  updateNotesCallIdCallBack = async (responseJson: object) => {
    this.setState({ isShowNotesModal: false, isUpdate: false, note: '', notes:""}, () => {
      this.getNotes()
    })
  }

  updateNotesCallIdFailureCallBack = async (errors: object) => {
    this.parseApiErrorResponse(errors)
  }

  createNotesCallIdCallBack = async (responseJson: object) => {
    this.setState({ isShowNotesModal: false, isCreate: false, note: '', notes:"" }, () => {
      this.getNotes()
    })
  }

  createNotesCallIdFailureCallBack = async (errors: object) => {
    this.parseApiErrorResponse(errors)
  }

  getNotesCallIdCallBack = async (responseJson: IResponceNotes) => {
    this.setState({ notesList: responseJson.data })
  }

  deleteNotesCallIdCallBack = async (responseJson: object) => {
    this.getNotes()
  }

  getNotesCallIdFailureCallBack = async (errors: object) => {
    this.parseApiErrorResponse(errors)
  }


  deleteNotesCallIdFailureCallBack = async (errors: object) => {
    this.parseApiErrorResponse(errors)
  }

  checkNotesText = () => {
    if (this.state.isCreate) {
      this.createNotes()
    }
    else {
      this.updatesNotes()
    }
  }

  updatesNotes = async () => {
    let apiData;
    apiData = {
      note: this.state.notes
    }
    this.updateNotesCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.putApiMethodType,
      endPoint: `${configJSON.updateNoteEndPoint}${this.state.notesId}`,
      body: apiData,
    });
  }

  createNotes = async () => {
    let apiData;
    apiData = {
      note: this.state.notes
    }
    this.createNotesCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postApiMethodType,
      endPoint: configJSON.createNoteEndPoint,
      body: apiData,
    });
  }

  getNotes = async () => {
    this.getNotesCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getNoteEndPoint,
    });
  }

  deleteNotes = async () => {
    this.deleteNotesCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethodType,
      endPoint: `${configJSON.deleteNoteEndPoint}${this.state.notesId}`,
    });
  }

  isShowNotesModalFasleCell = () => {
    this.setState({ isShowNotesModal: false, isChecktext: false }, () => { this.getNotes() })
  }

  setNotesCell = (text: string) => {
    this.setState({ notes: text }, () => {
      this.setState({ isChecktext: true })
    })
  }

  isShowNotesModalSetState = () => {
    this.setState({ isShowNotesModal: false, note: '' })
  }

  ShowNotesModalSetState = () => {
    this.setState({ isShowNotesModal: true, isCreate: true, })
  }

  ShowNotesModalUpdateState = (item: { id: string, attributes: { note: string } }) => {
    this.setState({ isShowNotesModal: true, isUpdate:true, notesId: item.id, notes: item.attributes.note })
  }

  showNotesModalDeleteNotes = (item: { id: string }) => {
    this.setState({ notesId: item.id }, () => {
      this.deleteNotes()
      this.deleteDailogueClose();
    })
  }

  deleteDailogueClose = () => {
    this.setState((prevState) => ({ isDeleteModalOpen: !prevState.isDeleteModalOpen }))
  }

  deleteModalOpen = (item: { id: number }) => {
    this.setState({ isDeleteModalOpen: true, notesId: item.id })
  }

  notesDailougModalClose = () => {
    this.setState({ isShowNotesModal: false, notes: '' })
  }

  showNotesModalDeleteNotesWeb = () => {
    this.deleteDailogueClose();
    this.deleteNotes();
  }

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };


  // Customizable Area End
}
