// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End
// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {WithStyles} from"@material-ui/core"
enum CSSClass {
  freqPopoverContainer = "freqPopoverContainer",
    freqPopoverMapped = "freqPopoverMapped",
    durationPopoverContainer = "durationPopoverContainer",
    durationPopoverMapped = "durationPopoverMapped",
    secondPopoverContainer = "secondPopoverContainer",
    secondPopoverMapped = "secondPopoverMapped",
    monthlyPopoverContainer = "monthlyPopoverContainer",
    formText = "formText",
    grayText = "grayText",
    minW30 = "minW30",
    blackText = "blackText",
    justifyCenter = "justifyCenter",
    justifyEnd = "justifyEnd",
    requestUserDetails = "requestUserDetails",
    primaryButton = "primaryButton",
    secondaryButton = "secondaryButton",
    mainHeader = "mainHeader",
    subHeader = "subHeader",
    notActiveMainHeader = "notActiveMainHeader",
    notActiveSubHeader = "notActiveSubHeader",
    closeIcon = "closeIcon"
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  getOneOffJobDetails: Function
  getActiveJobs: Function
  getRecurringJobDetail:Function
  type: string
  oneOffJobDetails: any
  editStatus: boolean
  getJobType:string
  startDate:Date
  endDate:Date
  isErrorFormData:{
    jobStartDate:string
    jobEndDate:string
  },
  recurringJobData:{
    frequency:string,
    duration:{
      day:string,
      frequency:string,
    },
    summary:string,
    day:number,//Every
    monthlyDay:string,//Monthly string
    weekDays:string,//which day S , M
  }
  classes: ClassNameMap<string>
  t: (key: string) => string;
  languageSelected: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCalenderVisible: boolean,
  selectedFrequency: string,
  isActiveOneOffJob: boolean,
  anchorEl: boolean,
  isModalOpen: boolean,
  isMenuOpen: boolean,
  frequencyOptions: any,
  monthlySubOptions: any,
  selectedDay: any
  oneOffJob: {
    jobStartDate: any,
    jobEndDate: any,
    jobStartTime: string,
    jobEndTime: string,

  }
  oneOfJobActive: string,
  re_occuringJobActive: string
  timeFormat: any,
  mockWeekDays:string[],
  mockFullWeekDays:string[],
  weekDayIndex:number,
  recurringJobData:{
    frequency:string,
    duration:{
      day:string,
      frequency:string,
    },
    summary:string,
    day:number,//Every
    monthlyDay:string,//Monthly string
    weekDays:string,//which day S , M
  
  },
  everyDetail:string[],
  freqAnchorEl: Element | null;
  freqAnchorId:string
  isModal:string;
  selectedDayForRequiringJobIndex:number
  selectedDays: []
  durationAnchorEl: Element | null;
  durationAnchorId:string;
  secondAnchorEl: Element | null;
  secondAnchorId:string;
  monthlyAnchorEl: Element | null;
  monthlyAnchorId:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DateTimeSchedulingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const startDate = () => {
      if (this.props.startDate) {
          return new Date(this.props.startDate)
      } else {
          return new Date()
      }
  }
  const calendarView = localStorage.getItem("calendarView");
  
  const startTime = () => {
    if (this.props.startDate&&!calendarView) {
      return moment(this.props.startDate).format('HH:mm A')
    } else if (calendarView) {
      return "09:00 AM"
    } else {
      return "09:00 AM"
    }
  }
  const endDate = () => {
      if (this.props.endDate) {
          return new Date(this.props.endDate)
      } else {
          return new Date()
      }
  }
  const endTime = () => {
    if (this.props.endDate&&!calendarView) {
      return moment(this.props.endDate).format('HH:mm A')
    } else if (calendarView) {
      return "09:00 AM"
    } else {
      return "09:00 AM"
    }
  }
    const frequencyOption = [
      `${this.props.t('jobs.scheduling.frequency.daily')}`,
      `${this.props.t('jobs.scheduling.frequency.weekly')}`,
      `${this.props.t('jobs.scheduling.frequency.monthly')}`,
      `${this.props.t('jobs.scheduling.frequency.yearly')}`
    ]
    const everyDetails=[
      `${this.props.t('jobs.scheduling.frequency1.day')}`,
      `${this.props.t('jobs.scheduling.frequency1.week')}`,
      `${this.props.t('jobs.scheduling.frequency1.month')}`,
      `${this.props.t('jobs.scheduling.frequency1.year')}`
    ]
    const weekDay=[
      `${this.props.t('jobs.scheduling.weekDays.sunday')}`,
      `${this.props.t('jobs.scheduling.weekDays.monday')}`,
      `${this.props.t('jobs.scheduling.weekDays.tuesday')}`,
      `${this.props.t('jobs.scheduling.weekDays.wednesday')}`,
      `${this.props.t('jobs.scheduling.weekDays.thursday')}`,
      `${this.props.t('jobs.scheduling.weekDays.friday')}`,
      `${this.props.t('jobs.scheduling.weekDays.saturday')}`,
    ]
    const mockWeekDay=[
      `${this.props.t('jobs.scheduling.mockWeekDays.s')}`,
      `${this.props.t('jobs.scheduling.mockWeekDays.m')}`,
      `${this.props.t('jobs.scheduling.mockWeekDays.t')}`,
      `${this.props.t('jobs.scheduling.mockWeekDays.w')}`,
      `${this.props.t('jobs.scheduling.mockWeekDays.t')}`,
      `${this.props.t('jobs.scheduling.mockWeekDays.f')}`,
      `${this.props.t('jobs.scheduling.mockWeekDays.s')}`,
    ]
    const monthlySubOption=[
      `${this.props.t('jobs.scheduling.monthlySubOptions.monthlyOnThirdWednesday')}`,
      `${this.props.t('jobs.scheduling.monthlySubOptions.monthlyOnDay16')}`,
      `${this.props.t('jobs.scheduling.monthlySubOptions.monthlyOnThirdThursday')}`,
    ]
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isCalenderVisible: true,
      selectedFrequency: '',
      isActiveOneOffJob: true,
      anchorEl: false,
      isModalOpen: false,
      isMenuOpen: false,
      frequencyOptions:frequencyOption,
      monthlySubOptions: monthlySubOption,
      selectedDayForRequiringJobIndex:5,
      selectedDays: [],
      selectedDay: 'M',
      oneOffJob: {
        jobStartDate: startDate(),
        jobEndDate: endDate(),
        jobStartTime: startTime(),
        jobEndTime: endTime(),
      },
      oneOfJobActive: "one_of_job",
      re_occuringJobActive: "reoccuring_job",
      timeFormat: "",
      everyDetail:everyDetails,
      mockWeekDays:mockWeekDay,
      mockFullWeekDays:weekDay,
      weekDayIndex:new Date().getDay(),
      recurringJobData: {
        frequency: "",
        duration: {
          day: "",
          frequency: "",
        },
        day: 1,
        weekDays: "",
        monthlyDay: "",
        summary: "",
       
      },
      freqAnchorEl: null,
      freqAnchorId: "",
      isModal: "",
      durationAnchorEl:  null,
      durationAnchorId: "",
      secondAnchorEl:  null,
      secondAnchorId: "",
      monthlyAnchorEl: null,
      monthlyAnchorId: ""
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    this.props.getOneOffJobDetails(this.state.oneOffJob)
    this.setState({
      timeFormat: await getStorageData('timeFormat'),
    })
    
    
    // Customizable Area End

  }

  async componentWillReceiveProps(nextProps: Readonly<Props>) {
    if (this.props.oneOffJobDetails) {
      this.setState({
        oneOffJob: nextProps.oneOffJobDetails
      })
    }

  }
  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (this.props.getJobType !== prevProps.getJobType) {
      this.props.getActiveJobs(this.props.getJobType === "recurring_job" ? "recurring_job" :
        "one_of_job")
      this.setState({
        isActiveOneOffJob:
          this.props.getJobType === "recurring_job" ? false : true,
        weekDayIndex: new Date(this.state.oneOffJob.jobStartDate).getDay()
      })
      if (this.props.recurringJobData) {
        let { frequency, duration, day, monthlyDay } = this.props.recurringJobData;
        this.setState({
          recurringJobData: {
            ...this.state.recurringJobData,
            frequency: frequency ,
            duration: {
              day:duration.day,
              frequency: duration.frequency,
            },
            summary: this.getSummary(),
            day,
            monthlyDay,
            weekDays: this.state.mockWeekDays[new Date(this.state.oneOffJob.jobStartDate).getDay()]
          },
        })
      }
    }
  }
  handleActiveOneOff = () => {
    this.setState({ isActiveOneOffJob: true }, () => {
      this.handleActiveJobs()
    })
  }
  handleActiveReoccuring = () => {
    this.setState({ isActiveOneOffJob: false }, () => {
      this.handleActiveJobs()
    })
  }
  handleActiveJobs = () => {
    if (this.state.isActiveOneOffJob) {
      this.props.getActiveJobs(this.state.oneOfJobActive)
    } else {
      this.props.getActiveJobs("recurring_job")
    }
  }

  convertToArabicTime(englishTime: string | undefined) {
    if (!englishTime) return '';

    const arabicToEnglish: { [key: string]: string } = {
        '0': '٠', '1': '١', '2': '٢', '3': '٣', '4': '٤',
        '5': '٥', '6': '٦', '7': '٧', '8': '٨', '9': '٩'
    };
if(this.props.languageSelected === 'ar')
{
    return englishTime.split('').map(char => arabicToEnglish[char] || char).join('');
}
else{
return englishTime;
}
}
  handleJobChangeHours = (startTime: any) => {
    this.setState({
      oneOffJob: { ...this.state.oneOffJob, jobStartTime: startTime }
    }, () => {
      this.props.getOneOffJobDetails(this.state.oneOffJob)
    }
    )

  }

  handleJobChangeEndTime = (endTime: any) => {
    this.setState({
      oneOffJob: { ...this.state.oneOffJob, jobEndTime: endTime }
    }, () => {
      this.props.getOneOffJobDetails(this.state.oneOffJob)
    })

  }

  handleStartDate = (date: any) => {
    this.setState(
      prevState => ({
        oneOffJob: {
          ...prevState.oneOffJob,
          jobStartDate: date,
          jobEndDate: prevState.oneOffJob&&prevState.oneOffJob.jobEndDate < date ? date : 
          prevState.oneOffJob&&prevState.oneOffJob.jobEndDate
        },
        recurringJobData: {
          ...prevState.recurringJobData,
          weekDays: prevState.mockWeekDays[new Date(date).getDay()]
        },
        weekDayIndex: new Date(date).getDay()
      }),
      () => {
        this.props.getOneOffJobDetails(this.state.oneOffJob);
      }
    );
  }
  handleEndDate = (date: any) => {
    const startDate = this.state.oneOffJob&&this.state.oneOffJob.jobStartDate || 
    this.props.oneOffJobDetails&&this.props.oneOffJobDetails.jobStartDate;

    if (date < startDate) {
      this.setState(
        prevState => ({
          oneOffJob: { ...prevState.oneOffJob, jobEndDate: startDate }
        }), () => {
          this.props.getOneOffJobDetails(this.state.oneOffJob);
        }
      );
    } else {
      this.setState(prevState => ({
          oneOffJob: { ...prevState.oneOffJob, jobEndDate: date }
        }), () => {
          this.props.getOneOffJobDetails(this.state.oneOffJob);
        }
      );
    }
  };

  openFreqModal = (value: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.props.editStatus) {
      this.setState({
        freqAnchorEl: event.currentTarget,
        freqAnchorId: "simple-popover",
        isModal: value,

      })
    }

  };

  openDurationModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.props.editStatus) {
      this.setState({
        durationAnchorEl: event.currentTarget,
        durationAnchorId: "duration-popover",
      })
    }
  };

  closeFreqModal = () => {
    this.props.getRecurringJobDetail(this.state.recurringJobData)
    this.setState({freqAnchorEl:null},()=>this.setState({freqAnchorId:""}));
  };

  closeDurationModal = () => {
    this.props.getRecurringJobDetail(this.state.recurringJobData)
    this.setState({durationAnchorEl:null},()=>this.setState({durationAnchorId:""}));
  };

  closeSecondModal = (btn: string) => {
    this.setState({ secondAnchorEl: null }, () => {
      if (btn !== "OKAY") {
        this.setState({
          secondAnchorId: "", recurringJobData: {
            ...this.state.recurringJobData,
            frequency: "",
            day: 0,
            weekDays: "",
            monthlyDay: "",
            summary: "",
          }
        })
      } else {
        const summary = this.getSummary();
        this.setState({
          recurringJobData: {
            ...this.state.recurringJobData,
            summary,
           
          },
        },()=>this.props.getRecurringJobDetail(this.state.recurringJobData))
      }
    },);
  };
  handleSelectDay = (item: string,index:number) => {
    let selectedDays = this.state.recurringJobData.weekDays
    .split(",")
    .map(day => parseInt(day.trim()))  
    .filter(day => !isNaN(day));       
    if (selectedDays.includes(index)) {
      selectedDays = selectedDays.filter(day => day !== index);
    } else {
    selectedDays.push(index);
    }
      this.setState({
        selectedDayForRequiringJobIndex:index,
        weekDayIndex: index,
        recurringJobData: {
          ...this.state.recurringJobData,
          weekDays: selectedDays.join(", ")
        },
      },()=>this.props.getRecurringJobDetail(this.state.recurringJobData));
  };
  setDurationDay = (value:string)=>{
    this.setState({
      recurringJobData: {
        ...this.state.recurringJobData,
        duration:{
          ...this.state.recurringJobData.duration,
         day:value
        }
      },

    },this.closeDurationModal)
  }

 
  setFrequency = (value:string)=>{
    if (this.state.isModal === "FREQUENCY") {
      this.setState({
        secondAnchorEl: this.state.freqAnchorEl,
        secondAnchorId: "second-popover",

      }, this.closeFreqModal)
    }

    if (this.state.isModal === "DURATION") {
      this.setState({
        recurringJobData: {
          ...this.state.recurringJobData,
          duration: {
            ...this.state.recurringJobData.duration,
            frequency: value
          }
        },

      },this.closeFreqModal)
    }else{
      this.setState({
        recurringJobData: {
          ...this.state.recurringJobData,
          frequency: value
        },
  
      },this.props.getRecurringJobDetail(this.state.recurringJobData))
    }
  };

  setMonthlyDay = (value: string) => {
    this.setState({
      recurringJobData: {
        ...this.state.recurringJobData,
        monthlyDay: value
      },
    }, this.closeMonthlyModal)
  }

  openMonthlyModal = (event:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    this.setState({
      monthlyAnchorEl: event.currentTarget,
      monthlyAnchorId: "monthly-popover",
    })
  };

  closeMonthlyModal = ()=>{

    this.setState({monthlyAnchorEl:null},()=>this.setState({monthlyAnchorId:""}));
  }
  getSummary = () => {
    const { frequency, day, monthlyDay } = this.state.recurringJobData;

    switch (frequency) {
      case `${this.props.t('jobs.scheduling.frequency.daily')}`:
        return day === 1 || day === 0 ? `${this.props.t('jobs.scheduling.everyDay')}` : `${this.props.t('jobs.scheduling.every')} ${day} ${this.props.t('jobs.scheduling.day')}`;
      case `${this.props.t('jobs.scheduling.frequency.weekly')}`:
        return day === 0 ? `${this.props.t('jobs.scheduling.everyWeekOn')} ${this.state.mockFullWeekDays[this.state.weekDayIndex]}` : `${this.props.t('jobs.scheduling.every')} ${day} ${this.props.t('jobs.scheduling.weekOn')} ${this.state.mockFullWeekDays[this.state.weekDayIndex]}`;
      case `${this.props.t('jobs.scheduling.frequency.monthly')}`:
        return monthlyDay;
      case `${this.props.t('jobs.scheduling.frequency.yearly')}`:
        return day === 0 ? `${this.props.t('jobs.scheduling.everyYear')}` : `${this.props.t('jobs.scheduling.every')} ${day} ${this.props.t('jobs.scheduling.year')}`;
      default:
        return "";
    }
  };
  incrementDay = ()=>{
    this.setState({
      recurringJobData:{
        ...this.state.recurringJobData,
        day:this.state.recurringJobData.day + 1
      }
    })
  };

  decrementDay = () => {
    if (this.state.recurringJobData.day > 0) {
      this.setState({
        recurringJobData: {
          ...this.state.recurringJobData,
          day: this.state.recurringJobData.day - 1
        }
      })
    }
  }

 
  // Customizable Area End
}
