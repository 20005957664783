import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Divider,
    Typography,
} from "@material-ui/core";
import moment from "moment";
import { CONSTANTS } from "../../components/src/theme";
import ListPopover from "../../components/src/CommonPopover";
import { getDividerTextStyle, getStatusTextStyle ,filterItems} from "../../blocks/CustomisableUserProfiles/src/utility.web";
import { CombinedProps} from "../../blocks/CustomisableUserProfiles/src/CustomerRequestsController.web";
import { StyledGrid } from "../../blocks/RequestManagement/src/Requests.web"
// Customizable Area End

// Customizable Area Start

enum CSSClass {
    textTableCell="textTableCell",
    padding_16="padding_16",
    RequestRequestdropDownSelectIcon="RequestRequestdropDownSelectIcon",
    customersHeadLayoutMargin="customersHeadLayoutMargin",
    marginTopHeadings="marginTopHeadings"
}
 interface ComponentClasses {
    [key: string]: CSSClass;
  }
 interface MockTableRow {
    id:number,
    key:string,
    title:string
 }

// Customizable Area End

export class CommonListPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
       
        const getStatus = (status: string) => {

            if (this.props.type === "QUOTES") {

                if (status === "converted" || status === "تحويل") {

                    return this.props.t('quote.converted')
                } else if (status === "overdue" || status === "متأخر") {

                    return this.props.t('quote.overdue')
                } else {

                    return status.replace(/_/g, ' ')
                }
            } else {

                return status.replace(/_/g, ' ')
            }
        }

        const renderTypo = (rowItem:any, subItem: MockTableRow) => {
            let valueToShow = rowItem.attributes[subItem.key];
            
            if (subItem.key === "appointment_date" || subItem.key === "start_date") {
                valueToShow = moment(valueToShow).format("DD/MM/YYYY").toString();
            } else if (subItem.key === "customer_name") {
                valueToShow = rowItem.attributes["custmoer_name"] || rowItem.attributes["customer_name"] || rowItem.attributes["custmer_name"];
            } else if (subItem.key === "team") {
                valueToShow = rowItem.attributes["employees"][0]?.name;
            } else if (subItem.key === "product_name" || subItem.key === "service_name") {
                const filterKey = subItem.key === "product_name" ? "product_id" : "service_id";
                valueToShow = filterItems(rowItem.attributes.products.data, filterKey);
                
                const tooltipType = subItem.key === "product_name" ? this.props.t("settings.products") : this.props.t("settings.services");
                return (
                    <Typography
                        onClick={this.props.handleTooltipOpen.bind(this, rowItem.attributes.products.data ? rowItem.attributes.products.data : [], filterKey, tooltipType)}
                        onMouseEnter={this.props.handleTooltipOpen.bind(this, rowItem.attributes.products.data ? rowItem.attributes.products.data : [], filterKey, tooltipType)}
                        variant="body1"
                        className={this.props.classes.textTableCell}
                    >
                        {valueToShow}
                    </Typography>
                );
            }
        
            return (
                <Typography variant="body1" className={this.props.classes.textTableCell}>
                    {valueToShow}
                </Typography>
            );
        }
        
        return (
        
            <>
            
                <ListPopover
                    anchorEl={this.props.anchorEl}
                    anchorId={this.props.anchorId}
                    siteData={this.props.siteData}
                    listData={this.props.listData}
                    popoverHeading={this.props.popoverHeading}
                    handleTooltipClose={this.props.handleTooltipClose}
                />
                <Grid style={webStyle.RequestcustomersRequestsContainer}>
                    <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                        <Grid container
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexWrap: 'nowrap',

                            }}
                        >
                            {this.props.requestMockTableRow.map((rowItem:MockTableRow) => {
                                return (
                                    <>

                                        <Grid item
                                            className={this.props.classes.padding_16}
                                            key={rowItem.id}
                                            onClick={this.props.sortRequestsData.bind(this, rowItem)}
                                            style={
                                                this.props.sortingProperty === rowItem.key
                                                    ? {
                                                        backgroundColor: "rgba(232,232,232,0.4)",
                                                        width: this.props.type === "REQUESTS" ?
                                                            `${(100 / this.props.requestMockTableRow.length)}%` : "275px"
                                                    }
                                                    : {
                                                        width: this.props.type === "REQUESTS" ?
                                                            `${(100 / this.props.requestMockTableRow.length)}%` : "275px"
                                                    }
                                            }

                                        >
                                            <Box
                                                style={webStyle.RequesttableHeadSpan}
                                            >
                                                <Typography

                                                    variant="body1"
                                                    align="left"
                                                >
                                                   {rowItem.title}
                                                </Typography>
                                                <img
                                                    style={
                                                        this.props.sortingProperty === rowItem.key &&
                                                            this.props.sortingOrder === "asc"
                                                            ? {
                                                                ...webStyle.RequestdropDownSelectIconTable,
                                                                transform: "rotate(180deg)",
                                                            }
                                                            : webStyle.RequestdropDownSelectIconTable
                                                    }
                                                    src={this.props.dropDownSelectIcon}
                                                    className={
                                                        this.props.classes.RequestRequestdropDownSelectIcon
                                                    }
                                                    alt=""
                                                />

                                            </Box>

                                        </Grid>

                                    </>
                                );
                            })}
                        </Grid>
                        <Divider className={this.props.classes.customersHeadLayoutMargin} />


                        {
                            this.props.statusOrder.map((status: string) => {
                                return (
                                    <>

                                        <Grid className={this.props.classes.padding_16} container>
                                            <Typography variant="body2" style={getStatusTextStyle(status)}>
                                             
                                               {getStatus(status)}
                                               
                                            </Typography>
                                        </Grid>
                                        <Divider style={getDividerTextStyle(status)} className={this.props.classes.marginTopHeadings} />
                                        {this.props.statusData[status].length === 0 &&
                                            <Grid container justifyContent="center"
                                                alignItems="center"
                                                style={{ height: '150px' }}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1"
                                                        style={this.props.type === "REQUESTS" ? webStyle.RequestnoRequestText :
                                                            { ...webStyle.RequestnoRequestText, marginLeft: this.props.lang === "en" ? "-50%" : "0", marginRight: this.props.lang === "en" ? "0" : "-50%" }}
                                                        align="center">{this.props.t('ProductService.noDataFound')}.</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Box style={{
                                            maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                                        }}>
                                            {this.props.statusData[status].map((rowItem: CombinedProps
                                            ) => {
                                                return (
                                                    <Grid container style={{
                                                        maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                                                    }}>
                                                        <Grid item xs={12}>
                                                            <StyledGrid container
                                                                style={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    flexWrap: 'nowrap',
                                                                    gridAutoFlow: 'column',
                                                                    zIndex: 0,
                                                                    position: 'relative',
                                                                    justifyContent: 'end',
                                                                    color:"#626262"
                                                                }}
                                                                data-test-id="moveHover"
                                                                key={rowItem.id}
                                                                onClick={() => this.props.handleViewData(rowItem)}
                                                            >
                                                                {this.props.requestMockTableRow.map((subItem:MockTableRow) => {

                                                                    return (
                                                                        <Grid item data-test-id="viewData"
                                                                            style={{ width: `${(100 / this.props.requestMockTableRow.length)}%` }}
                                                                            className={this.props.classes.tableCellLayout}

                                                                        >
                                                                           {renderTypo(rowItem,subItem)}
                                                                        </Grid>
                                                                    )
                                                                })}
                                                            </StyledGrid>
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>

                                                )
                                            })}
                                        </Box>
                                    </>
                                )
                            })
                        }


                    </Grid>
                </Grid>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    RequestsearchContainer: {
      display: "flex",
      alignItems: "center",
      minHeight: "56px",
      width: "100%",
      borderRadius: "8px",
      border: "1.5px solid rgba(0,0,0,0.1)",
    },
    RequestsearchIcon: {
      height: "20px",
      width: "20px",
      margin: "0 0.6rem 0 1.3rem",
    },
    RequestinputBase: {
      width: "100%",
      fontFamily: "Rubik",
      fontSize: "1.1667rem",
      fontWeight: 400,
      lineHeight: "16px",
      color:CONSTANTS.color.grey
    },
    RequestcustomersRequestsContainer: {
      marginTop: "2rem",
      maxWidth: "100%",
      backgroundColor: "#ffffff",
      boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
      minHeight: "300px",
      overflow: 'scroll',
    },
    RequesttableCellHead: {
      color: "#626262",
      fontFamily: "Rubik",
      fontSize: "1.1167rem",
      fontWeight: 400,
      width:"20%"
    },
    RequesttableCellBody: {
      color: "black",
      fontFamily: "Rubik",
      fontSize: "1.1167rem",
      fontWeight: 500,
      borderBottom: "none",
      paddingTop:0,
      width:"20%",
      padding:"0.8rem"
    },
    RequesttableHeadSpan: {
      cursor: "pointer",
      borderRadius: "2px",
      display: "flex",
      color:"#626262",
      justifyContent: "space-between",
      alignItems: "center",
    },
    RequesttableHeadText: {
      fontSize: "18px",
    },
    RequestnoRequestContainer: {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",
    },
    RequestnoRequestText: {
      color: "#34313a",
      fontWeight: 500,
      textAlign: "center" as const,
    },
    RequestnoRequestSubText: {
      color: "#999999",
      fontFamily: "Rubik",
      fontSize: "18px",
      fontWeight: 400,
      textAlign: "center" as "center",
    },
    RequestbuttonsContainer: {
      padding: "4rem 0",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    RequestbuttonContainerCreateNewRequest: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      maxWidth: "257px",
      width: "100%",
      minHeight: "67px",
      borderRadius: "10px",
      backgroundColor: "#1c18af",
    },
    RequestbuttonTextCreateNewRequest: {
      color: "#ffffff",
  
      fontWeight: 400,
      textAlign: "center" as "center",
    },
    RequestRequestdropDownSelectIcon: {
      width: "13px",
      height: "9px",
      cursor: "pointer",
    },
    RequestdropDownSelectIconTable: {
      width: "13px",
      height: "9px",
      cursor: "pointer",
      marginLeft: "2rem",
      transform: "rotate(0deg)",
    },
    RequestdeleteIconContainer: {
      maxWidth: "79px",
      height: "61px",
      width:"100%",
      backgroundColor: "rgb(253 188 188)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
      
    },
    RequestdeleteIcon: {
      width: "24px",
      height: "24px",
    },
    Requesttoaster: {
      position: "relative" as "relative",
      display: "flex",
      alignItems: "center",
      paddingLeft: "2rem",
      width: "100%",
      maxWidth: "689px",
      minHeight: "80px",
      borderRadius: "8px",
      backgroundColor: "#1c18af",
      marginTop: "5px",
    },
    RequestcloseIcon: {
      position: "absolute" as "absolute",
      left: "96%",
      color: " #fff",
      top: "29%",
      transform: "translate(-50%, -50%)",
      cursor: "pointer",
    },
    RequesttoasterText: {
      color: "#ffffff",
      fontWeight: 400,
    },
  };
export default (CommonListPage);
// Customizable Area End