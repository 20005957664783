import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Container,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  Dialog,
  Radio,
  Hidden,
  ClickAwayListener,
} from "@material-ui/core";
import {
  styled,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import {
  uploadIcon,
  manageTaxRatesImage,
  editIcon,
  galleryIcon,
  deleteLogoIcon,
  dialogBoxIcon,
  deleteTaxIcon
} from "./assets";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { countryCodes } from "../../dashboard/src/utility.web";
import { SettingsSideBar } from "./SettingsSideBar.web";
import { FileUpload } from "../../../components/src/FileUpload.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import TimePicker from "../../../components/src/TimePicker";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import SettingsController, {
  Props,
} from "./SettingsController.web";

// Customizable Area Start
interface StyledBoxProps {
  languageSelected: string;
}
type ItemType = {
  currency: string;
};


export const StyledBox = styled(Box)(({ theme, languageSelected }: { theme: Theme, languageSelected: string }) => ({
  marginLeft: languageSelected !== "ar" ? "36rem" : "0rem",
  marginRight: languageSelected === "ar" ? "36rem" : "0rem",
  [theme.breakpoints.down("md")]: {
    marginLeft: (props: StyledBoxProps) => props.languageSelected !== "ar" ? "17.5rem" : "0rem",
    marginRight: (props: StyledBoxProps) => props.languageSelected === "ar" ? "17.5rem" : "0rem",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: (props: StyledBoxProps) => props.languageSelected !== "ar" ? "0" : "",
    marginRight: (props: StyledBoxProps) => props.languageSelected === "ar" ? "0" : "",
    overflowY: "scroll",
    position: "relative"
  },
}));

interface StyledPropTypes {
  languageSelected: string;
}

export const StyledBoxModal = styled(Box)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down("xs")]: {
    maxWidth: "234px !important",
    transform: (props: StyledPropTypes) => props.languageSelected === "ar" ? "translate(-42%, 10%) !important" : "translate(42%, 10%) !important",
  },
}));

export const StyledCheckbox = withStyles({
  root: {
    height: "24px",
    width: "24px",
    color: "rgba(0,0,0,0.1)",
    "&.Mui-checked": {
      color: CONSTANTS.color.darkBlue
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem"
    }
  }
})(Checkbox);

export const StyledGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  maxWidth: "90% !important",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100% !important",
  }
}));

export const StyledGridTaxRate = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column !important",
      paddingTop: "0 !important",
    },
  }
}))(Grid);

export const StyledImg = styled('img')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxWidth: "188px !important",
    height: "176px !important"
  },
  [theme.breakpoints.down(410)]: {
    maxWidth: "100% !important",
    height: "auto !important"
  },
}));

export const StyledButtons = styled(Box)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "4rem 0px 2rem 0.5rem !important",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "1.5rem 0px 0rem 0rem !important",
    gap: "1rem !important"
  },
}));

export const StyledTextFields = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      fontSize: "18px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      }
    },
    "& .MuiOutlinedInput-input":{
      color: CONSTANTS.color.midGrey,
    },
  }
}))(TextField);

export const StyledTextFieldsTax = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      fontSize: "18px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      }
    },
    "& .MuiOutlinedInput-input":{
      color: CONSTANTS.color.midGrey,
    },
  }
}))(TextField);

export const StyledTextField = withStyles((theme: Theme) => ({
  root: {
    padding: "0 0.2rem",
    "& .MuiInputBase-root": {
      height: "55px",
      borderRadius: "10px",
      color: CONSTANTS.color.lightBrown,
      fontSize: "18px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      },
    },
    "& .MuiOutlinedInput-input": {
      cursor: "pointer",
      color: CONSTANTS.color.midGrey,
    },
    "& .MuiSelect-icon": {
      fontSize: "2rem",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: (props:StyledPropTypes) => props.languageSelected === "ar" ? "95%" : "16px",
      [theme.breakpoints.down("sm")]: {
        right: (props:StyledPropTypes) => props.languageSelected === "ar" ? "94%" : "14px",
      },
      [theme.breakpoints.down("xs")]: {
        right: (props:StyledPropTypes) => props.languageSelected === "ar" ? "90%" : "14px",
      }
    },
  },
}))(TextField);

interface TextfieldProps {
  languageSelected: string;
}

export const StyledTextFieldAutoComplete = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      height: "59px",
      display: "flex",
      justifyContent: (props: TextfieldProps) => props.languageSelected === "ar" ? "center" : "flex-start",
      borderRadius: "10px",
      color: CONSTANTS.color.midGrey,
      fontSize: "18px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      }
    },
    "& .MuiOutlinedInput-input":{
      cursor: "pointer",
      padding:"15px 12px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor: CONSTANTS.color.thickBlue,
      borderWidth: "2px"
    },
    "& .MuiSvgIcon-root":{
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none"
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "95px"
    }
  }
}))(TextField);

export const StyledDialog = withStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: "2rem",
      width: "100%",
      maxWidth: "657px",
      borderRadius: "20px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
      [theme.breakpoints.down("xs")]: {
        padding: "1.5rem 1.5rem 1.7rem !important",
      }
    }
  }
}))(Dialog);

export const StyledRadio = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
      width: "26px",
      height: "26px",
      border: "1px solid  rgba(0,0,0,0.2)",
      borderRadius: "50%",
      fill: "none"
    },
    "&$checked .MuiSvgIcon-root": {
      border: `1px solid ${CONSTANTS.color.royalBlue}`,
      backgroundColor: "rgba(77, 74, 202, 0.15)"
    },
    "&$checked:focus .MuiSvgIcon-root": {
      boxShadow: "none"
    }
  },
  checked: {}
})(Radio);

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: "Rubik",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    },
    color: CONSTANTS.color.midGrey,
    "& .MuiMenuItem-root": {
    }
  }
}))(MenuItem);

const getMenuItemStyle = (item: string, value: string) => {
  const isSelected = item === value;
  return {
    ...webStyle.menuItemCurrency,
    ...(isSelected ? { backgroundColor: CONSTANTS.color.lightTrasparentBlue } : {})
  };
};

const renderImage = (item: File) => {
  let objectUrl = '';

  if (typeof window.URL.createObjectURL === 'function') {
    objectUrl = window.URL.createObjectURL(item);
  } else if (typeof window.webkitURL?.createObjectURL === 'function') {
    objectUrl = window.webkitURL.createObjectURL(item);
  } else {
    console.error('createObjectURL is not supported in this environment.');
  }

  return objectUrl;
}

const getDynamicStyle = (isHovered: boolean, backgroundColor: string) => {
  return isHovered ? {...webStyle.changeLogoInnerContainer, backgroundColor} : webStyle.changeLogoInnerContainer;
}

export type MockBusiness = {
  id: number;
  title: string;
  closed: boolean;
  opensAt: string;
  closedAt: string;
};

export type TaxRates = {
  id: number;
  name: string;
  rate: string;
  description: string;
}

const getConditionalStyle = (isError: string | undefined) => {
  return isError ? {backgroundColor: CONSTANTS.color.lightRed, borderRadius: "10px"} : {};
};

const renderErrorMessage = (errorMessage: string | undefined) => {
  return (
    <Typography variant="body2" style={webStyle.errorMessage}>
      {errorMessage}
    </Typography>
  );
};
// Customizable Area End

export class CompanyInformation extends SettingsController {
  constructor(props: Props) {
    super(props);
  }
   // Customizable Area Start
   renderImageLogo = ()=>{
    const { languageSelected } = this.state;
    return this.state.companyLogoEditLocal || this.state.companyLogoEdit ? (
      <Box>
        <Grid item>
          {!this.state.companyLogoEdit ?
            <img
              style={{borderRadius: "8px"}}
              src={renderImage(this.state.companyLogoEditLocal)}
              height="200"
              width="200"
            />
          :
            <img
              style={{borderRadius: "8px"}}
              src={this.state.companyLogoEditLocal ? renderImage(this.state.companyLogoEditLocal) : this.state.companyLogoEdit}
              height="200"
              width="200"
            />
          }
          {!this.state.isEmployee &&
            <img
              data-test-id="handleClickEditIcon"
              style={
                languageSelected === "ar" ?
                {...webStyle.editIcon, ...webStyle.editIconAr} :
                webStyle.editIcon
              }
              src={editIcon}
              alt=""
              onClick={this.handleClickEditIcon}
            />
          }
          
        </Grid>
      </Box>
    ) : (
      <Button
        data-test-id="handleDragAndDrop"
        style={webStyle.uploadFiles}
        variant="text"
        component="label"
        disableRipple
        disableElevation
        disableFocusRipple
        onDragOver={this.handleOnDragOver}
        onDrop={this.handleOnDrop}
      >
        <img
          style={webStyle.uploadIcon}
          src={uploadIcon}
          alt=""
        />&nbsp;&nbsp;&nbsp;
        <Typography style={webStyle.uploadText} variant="caption">
          <input
            data-test-id="handleSelectFiles"
            hidden
            disabled={this.state.isEmployee}
            type="file"
            accept="image/*"
            onChange={this.handleSelectFiles}
          />
          {this.props.t('settings.uploadFiles')}
        </Typography>
      </Button>
    )
   }

   renderLogoChangeModal = ()=>{
    const { classes }: any = this.props;
    return this.state.logoChangeModal ?
      <ClickAwayListener onClickAway={this.handleClickEditIcon}>
        <StyledBoxModal
          style={
            this.state.languageSelected !== "ar" ?
            webStyle.changeLogoContainer :
            {...webStyle.changeLogoContainer, transform: "translate(-54%, 10%)"}
          }
          languageSelected={this.state.languageSelected}
        >
          <img style={webStyle.dialogBoxIcon} className={classes.dialogBoxIcon} src={dialogBoxIcon} alt="" />
          <Box
            data-test-id="handleMouse"
            style={getDynamicStyle(this.state.isHovered, CONSTANTS.color.lightTrasparentBlue)}
            component="label"
            onMouseEnter={this.handleMouseEnterChangeLogo}
            onMouseLeave={this.handleMouseLeaveChangeLogo}
          >
            <input
              data-test-id="handleSelectFiles"
              hidden
              type="file"
              accept="image/*"
              onChange={this.handleSelectFiles}
            />
            <img style={webStyle.galleryIcon} src={galleryIcon} alt="" />
            <Typography style={webStyle.changeLogoText} className={classes.changeLogoMobile} variant="body1">
              {this.props.t('settings.changeLogoImage')}
            </Typography>
          </Box>
          <Box
            data-test-id="handleDeleteUploadedLogo"
            style={getDynamicStyle(this.state.isHovered2, CONSTANTS.color.lightTrasparentBlue)}
            onClick={this.handleDeleteUploadedLogo}
            onMouseEnter={this.handleMouseEnterDeleteLogo}
            onMouseLeave={this.handleMouseLeaveDeleteLogo}
          >
            <img style={webStyle.galleryIcon} src={deleteLogoIcon} alt="" />
            <Typography style={webStyle.removeImageText} className={classes.changeLogoMobile} variant="body1">
              {this.props.t('settings.removeImage')}
            </Typography>
          </Box>
        </StyledBoxModal>
      </ClickAwayListener>
    : null
   }

  renderAutoComplete = () => {
    const { classes }: any = this.props;
    return !this.state.isEmployee ? (
      <Autocomplete
        data-test-id="autocomplete"
        classes={{option: classes.customOption}}
        options={countryCodes}
        value={this.state.countryCode}                         
        onChange={this.handleChangeCountryCode}
        autoHighlight
        renderInput={(params: AutocompleteRenderInputParams) => (
          <StyledTextFieldAutoComplete
            {...params}
            style={getConditionalStyle(this.state.isErrorFormData.countryCode)}
            fullWidth
            variant="outlined"
            error={Boolean(this.state.isErrorFormData.countryCode)}
            disabled = { this.state.isEmployee }
            languageSelected={this.state.languageSelected}
          ></StyledTextFieldAutoComplete>
        )}
      />
    ) : (
      <StyledTextFieldAutoComplete
        style={getConditionalStyle(this.state.isErrorFormData.countryCode)}
        fullWidth
        error={Boolean(this.state.isErrorFormData.countryCode)}
        variant="outlined"                          
        disabled = { this.state.isEmployee }
        value={this.state.countryCode}   
        languageSelected={this.state.languageSelected}
      ></StyledTextFieldAutoComplete>
    );
  }

  renderManageTaxrates = (classes: any) => {
    const { t } = this.props;
    const showManageTaxRatesButton = () => {
      return (
        <>
          {!this.state.isEmployee && 
            <Button
              data-test-id="handleManageTaxRates"
              className={`${classes.buttonContainer} ${classes.buttonContainer1} ${classes.manageTaxRatesButtonMobile}`}
              onClick={this.handleManageTaxRates}
            >
              <Typography
                style={webStyle.buttonText}
                className={classes.buttonTextMobile}
                variant="subtitle2"
              >
                {t('settings.manageTaxRatesLabel')}
              </Typography>
            </Button>
          }
        </>
      );
    }
    return  this.state.manageTaxRatesData?.length === 0 ? (
      <>
        <Hidden smUp>
          <Typography style={webStyle.manageTaxText} className={classes.manageTaxText} variant="subtitle2">
            {t('settings.manageYourTaxRatesLabel')}
          </Typography>
        </Hidden>
        <Grid container style={webStyle.manageTaxGrid} className={classes.manageTaxGrid} spacing={2}>
          <Grid item lg={7} md={7} sm={8} xs={6}>
            <Box style={webStyle.manageTaxContainer}>
              <Hidden xsDown>
                <Typography style={webStyle.manageTaxText} className={classes.manageTaxText} variant="subtitle2">
                  {t('settings.manageYourTaxRatesLabel')}
                </Typography>
              </Hidden>
              <Typography
                style={webStyle.manageTaxSubText}
                className={classes.manageTaxSubText}
                variant="body1"
              >
                {t('settings.manageYourTaxRatesDesc')}
              </Typography>
              <Hidden xsDown>
                {showManageTaxRatesButton()}
              </Hidden>
            </Box>
          </Grid>
          <Grid item lg={5} md={5} sm={4} xs={6}>
            <StyledImg
              style={webStyle.manageTaxRatesImage}
              src={manageTaxRatesImage}
              alt=""
            />
          </Grid>
        </Grid>
        <Hidden smUp>
          {showManageTaxRatesButton()}
        </Hidden>
      </>
    ) : (
      <Box>
        <Typography
          style={{...webStyle.manageTaxText, paddingBottom: "1rem"}}
          variant="body1"
          className={classes.manageTaxText}
        >
          {t('settings.manageYourTaxRatesLabel')}
        </Typography>
        {this.state.manageTaxRatesData?.map((item: TaxRates, index: number) => {
          const { name, rate, description } = item;
          const nameError = this.state.isErrorTaxFormData[index]?.name;
          const rateError = this.state.isErrorTaxFormData[index]?.rate;
          const descriptionError = this.state.isErrorTaxFormData[index]?.description;
          return (
            <StyledGridTaxRate container spacing={2} key={index} style={webStyle.manageTaxRatesGrid}>
              <Grid item lg={3} md={5} xs={6}>
                <Typography style={webStyle.taxText} className={classes.changeLogoMobile} variant="body1" gutterBottom color="textPrimary">
                  {t('settings.taxName')}
                </Typography>
                <StyledTextFieldsTax
                  disabled={this.state.isEmployee}
                  data-test-id="formdata"
                  style={getConditionalStyle(nameError)}
                  fullWidth
                  variant="outlined"
                  placeholder={t('settings.forEgGST')}
                  name="name"
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChangeTaxRate(e, index)}
                  error={Boolean(nameError)}
                />
                {renderErrorMessage(nameError)}
              </Grid>
              <Grid item lg={3} md={5} xs={6}>
                <Typography style={webStyle.taxText} className={classes.changeLogoMobile} variant="body1" gutterBottom color="textPrimary">
                  {t('settings.taxRate')}
                </Typography>
                <StyledTextFieldsTax
                  disabled={this.state.isEmployee}
                  data-test-id="formdata"
                  style={getConditionalStyle(rateError)}
                  fullWidth
                  variant="outlined"
                  name="rate"
                  value={rate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChangeTaxRate(e, index)}
                  error={Boolean(rateError)}
                />
                {renderErrorMessage(rateError)}
              </Grid>
              <Grid item lg={5} md={10} xs={11}>
                <Typography style={webStyle.taxText} className={classes.changeLogoMobile} variant="body1" gutterBottom color="textPrimary">
                  {t('settings.taxDescription')}
                </Typography>
                <StyledTextFieldsTax
                  disabled={this.state.isEmployee}
                  data-test-id="formdata"
                  style={getConditionalStyle(descriptionError)}
                  fullWidth
                  variant="outlined"
                  name="description"
                  value={description}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChangeTaxRate(e, index)}
                  error={Boolean(descriptionError)}
                />
                {renderErrorMessage(descriptionError)}
              </Grid>
              {!this.state.isEmployee &&
                <Grid item lg={1} md={2} xs={2} style={webStyle.deleteTaxRatesIcon} className={classes.deleteTaxRatesIcon}>
                  <img
                    data-test-id="handleDeleteTaxRate"
                    style={webStyle.deleteTaxIcon}
                    src={deleteTaxIcon}
                    alt=""
                    onClick={() => this.handleDeleteTaxRate(index)}
                  />
                </Grid>
              }
              {index !== this.state.manageTaxRatesData.length - 1 && <span className={classes.taxRatesHr}></span>}
            </StyledGridTaxRate>
          );
        })}
        {!this.state.isEmployee &&
          <Button
            data-test-id="handleManageTaxRates"
            style={{marginTop: "2rem"}}
            className={`${classes.buttonContainer} ${classes.buttonContainer1}`}
            onClick={this.handleManageTaxRates}
          >
            <Typography
              style={webStyle.buttonText}
              className={classes.buttonTextMobile}
              variant="subtitle2"
            >
              {t('settings.manageTaxRatesLabelPlus')}
            </Typography>
          </Button>
        }
      </Box>
    );
  }

  renderMenuItemCountry = (countriesData: string[], country: string) => {
    return (
      !this.state.isEmployee && countriesData.map((item: any) => (
        <StyledMenuItem value={item.country} key={item.id} style={getMenuItemStyle(item.country, country)}>
          {item.country}
        </StyledMenuItem>
      ))
    );
  }

  renderMenuItemCurrency = (countriesData: { currency: string }[], currency: string) => {
    return (
      !this.state.isEmployee && 
      Array.from(new Set(countriesData.map((item: ItemType) => item.currency)))
        .map((currency: string) => (
          <StyledMenuItem value={currency} key={currency} style={getMenuItemStyle(currency, currency)}>
            {currency}
          </StyledMenuItem>
        ))
    )
  }
  
   // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { companyName, phone, email, websiteUrl } = this.state.companyDetails;
    const { country, currency, timezone, dateFormat, timeFormat, firstDayOfTheWeek } = this.state.regionalSettings;
    const { taxIdName, taxIdNumber } = this.state.taxSettings;
    const { name, rate, description } = this.state.manageTaxRates;
    const { countriesData, hexColor, languageSelected } = this.state;
    const { t } = this.props;

    const dateFormatMock = [
      t('settings.mm/dd/yyyy'),
      t('settings.dd/mm/yyyy'),
      t('settings.yyyy/mm/dd'),
      t('settings.dd/mmm/yyyy'),
      t('settings.monthddyyyy'),
      t('settings.dayddmmmyyyy'),
      t('settings.ddthOfmonthyyyy'),
      t('settings.yyyyddd')
    ]

    const timeFormatMock = [
      t('settings.12HourClock'),
      t('settings.24HourClock')
    ]

    const firstDayOfTheWeekMock = [
      t('settings.sunday'),
      t('settings.monday')
    ]
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.settings')} active={false} active1={false} active2={1} />
        <SettingsSideBar {...this.props} active={0} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.boxContainerCompany}
            className={classes.boxContainerCompany}
          >
            <Container maxWidth={false}>
              <Box>
                <Typography style={webStyle.headerText} className={classes.headerText} variant="subtitle1">
                  {t('settings.companyDetails')}
                </Typography>
                <Grid container>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formTextCompanyLogo} variant="body1" color="textPrimary">
                      {t('settings.companyLogo')}
                    </Typography>
                    {this.renderImageLogo()}
                    {this.renderLogoChangeModal()}
                    {this.state.logoCompleted && (
                      <FileUpload
                        progress={this.state.progress}
                        languageSelected={this.state.languageSelected}
                      />
                    )}
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={`${classes.formText} ${classes.hexColor}`} variant="body1" color="textPrimary">
                      {t('settings.hexColor')}
                    </Typography>
                    <input
                      data-test-id="handleChangeHexColor"
                      style={webStyle.colorPicker}
                      type="color"
                      disabled={this.state.isEmployee}
                      value={hexColor}
                      onChange={this.handleChangeHexColor}
                    />
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.companyName')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      disabled={this.state.isEmployee}
                      data-test-id="formdataCompanyDetails"
                      style={getConditionalStyle(this.state.isErrorFormData.companyName)}
                      fullWidth
                      variant="outlined"
                      name="companyName"
                      value={companyName}
                      onChange={this.handleChangeCompanyDetails}
                      error={Boolean(this.state.isErrorFormData.companyName)}
                    />
                    {renderErrorMessage(this.state.isErrorFormData.companyName)}
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.phoneNumber')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xs={4}>
                        {this.renderAutoComplete()}
                        {renderErrorMessage(this.state.isErrorFormData.countryCode)}
                      </Grid>
                      <Grid item lg={9} md={9} xs={8}>
                        <StyledTextFields
                          disabled={this.state.isEmployee}
                          data-test-id="formdataCompanyDetails"
                          style={getConditionalStyle(this.state.isErrorFormData.phone)}
                          fullWidth
                          variant="outlined"
                          name="phone"
                          value={phone}
                          onChange={this.handleChangeCompanyDetails}
                          error={Boolean(this.state.isErrorFormData.phone)}
                        />
                        {renderErrorMessage(this.state.isErrorFormData.phone)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.emailAddress')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      disabled={this.state.isEmployee}
                      data-test-id="formdataCompanyDetails"
                      style={getConditionalStyle(this.state.isErrorFormData.email)}
                      fullWidth
                      variant="outlined"
                      name="email"
                      value={email}
                      onChange={this.handleChangeCompanyDetails}
                      error={Boolean(this.state.isErrorFormData.email)}
                    />
                    {renderErrorMessage(this.state.isErrorFormData.email)}
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.webSiteUrl')}
                    </Typography>
                    <StyledTextFields
                      disabled={this.state.isEmployee}
                      data-test-id="formdataCompanyDetails"
                      style={getConditionalStyle(this.state.isErrorFormData.websiteUrl)}
                      fullWidth
                      variant="outlined"
                      name="websiteUrl"
                      value={websiteUrl}
                      onChange={this.handleChangeCompanyDetails}
                      error={Boolean(this.state.isErrorFormData.websiteUrl)}
                    />
                    {renderErrorMessage(this.state.isErrorFormData.websiteUrl)}
                  </Grid>
                </Grid>
                <span style={webStyle.hr} className={classes.hr}></span>
                <Typography style={webStyle.headerText} className={classes.headerText} variant="subtitle1">
                  {t('settings.regionalSettings')}
                </Typography>
                <Grid container>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.country')}
                    </Typography>
                    <StyledTextField
                      data-test-id="handleChangeCountry"
                      style={getConditionalStyle(this.state.isErrorFormData.country)}
                      select
                      fullWidth
                      variant="outlined"
                      name="country"
                      value={country}
                      onChange={this.handleChangeCountry}
                      error={Boolean(this.state.isErrorFormData.country)}
                      disabled = {this.state.isEmployee}
                      languageSelected={languageSelected}
                    >
                      <StyledMenuItem value="Select Country" disabled>{t('settings.selectCountry')}</StyledMenuItem>
                      {this.state.isEmployee && 
                        <StyledMenuItem value={country} disabled>
                          {country}
                        </StyledMenuItem>
                      }
                      {this.renderMenuItemCountry(countriesData,country)}
                    </StyledTextField>
                    {renderErrorMessage(this.state.isErrorFormData.country)}
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.country')}
                    </Typography>
                    <StyledTextField
                      data-test-id="handleChangeCountry"
                      select
                      fullWidth
                      variant="outlined"
                      name="currency"
                      value={currency}
                      disabled = {this.state.isEmployee}
                      onChange={this.handleChangeCountry}
                      languageSelected={languageSelected}
                    >
                      <StyledMenuItem value="Select Currency" disabled>{t('settings.selectCurrency')}</StyledMenuItem>
                      {this.state.isEmployee && 
                        <StyledMenuItem value={currency} disabled>
                          {currency}
                        </StyledMenuItem>
                      }
                      {this.renderMenuItemCurrency(countriesData,currency)}
                    </StyledTextField>
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.timezone')}
                    </Typography>
                    <StyledTextField
                      data-test-id="handleChangeCountry"
                      select
                      fullWidth
                      variant="outlined"
                      name="timezone"
                      value={timezone}
                      disabled = {this.state.isEmployee}
                      onChange={this.handleChangeCountry}
                      languageSelected={languageSelected}
                    >
                      <StyledMenuItem value="Select Timezone" disabled>{t('settings.selectTimezone')}</StyledMenuItem>
                      {this.state.isEmployee && 
                      <StyledMenuItem value={timezone} disabled>
                        {timezone}
                      </StyledMenuItem>}
                      {!this.state.isEmployee && countriesData.map((item: any) => (
                        <StyledMenuItem value={item.timezone} key={item} style={getMenuItemStyle(item.timezone, timezone)}>
                          {item.timezone}
                        </StyledMenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.dateFormat')}
                    </Typography>
                    <StyledTextField
                      data-test-id="regionalSettingsFormData"
                      select
                      fullWidth
                      variant="outlined"
                      name="dateFormat"
                      value={dateFormat}
                      disabled = {this.state.isEmployee}
                      onChange={this.handleChangeRegionalSettings}
                      languageSelected={languageSelected}
                    >
                      <StyledMenuItem value="Select Date Format" disabled>{t('settings.selectDateFormat')}</StyledMenuItem>
                      {this.state.isEmployee && 
                      <StyledMenuItem value={dateFormat} disabled>
                         {dateFormat}
                      </StyledMenuItem>}
                      {!this.state.isEmployee && dateFormatMock.map((item: string) => (
                        <StyledMenuItem value={item} key={item} style={getMenuItemStyle(item, dateFormat)}>
                          {item}
                        </StyledMenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.timeFormat')}
                    </Typography>
                    <StyledTextField
                      data-test-id="regionalSettingsFormData"
                      select
                      fullWidth
                      variant="outlined"
                      name="timeFormat"
                      value={timeFormat}
                      onChange={this.handleChangeRegionalSettings}
                      disabled={this.state.isEmployee}
                      languageSelected={languageSelected}
                    >
                      <StyledMenuItem value="Select Time Format" disabled>{t('settings.selectTimeFormat')}</StyledMenuItem>
                      {this.state.isEmployee && 
                        <StyledMenuItem value={timeFormat} disabled>
                          {timeFormat}
                        </StyledMenuItem>
                      }
                      {!this.state.isEmployee && timeFormatMock.map((item: string) => (
                        <StyledMenuItem value={item} key={item} style={getMenuItemStyle(item, timeFormat)}>
                          {item}
                        </StyledMenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.firstDayOfWeek')}
                    </Typography>
                    <StyledTextField
                      data-test-id="regionalSettingsFormData"
                      select
                      fullWidth
                      variant="outlined"
                      name="firstDayOfTheWeek"
                      value={firstDayOfTheWeek}
                      onChange={this.handleChangeRegionalSettings}
                      disabled={this.state.isEmployee}
                      languageSelected={languageSelected}
                    >
                      <StyledMenuItem value="Select First Day Of The Week" disabled>{t('settings.selectFirstDayOfWeek')}</StyledMenuItem>
                      {this.state.isEmployee && 
                      <StyledMenuItem value={firstDayOfTheWeek} disabled>
                        {firstDayOfTheWeek}
                      </StyledMenuItem>}
                      {!this.state.isEmployee && firstDayOfTheWeekMock.map((item: string) => (
                        <StyledMenuItem value={item} key={item} style={getMenuItemStyle(item, firstDayOfTheWeek)}>
                          {item}
                        </StyledMenuItem>
                      ))}
                    </StyledTextField>
                  </Grid>
                </Grid>
                <Typography style={{...webStyle.formText, padding: "2rem 0 1.2rem 0"}} className={classes.formText} variant="body1" color="textPrimary">
                  {t('settings.businessHours')}
                </Typography>
                <StyledGrid item lg={10} md={9} xs={12}>
                  <Box>
                    {this.state.mockBuisinessHours.map((item: MockBusiness) => (
                      <Grid container spacing={3} key={item.id} style={{paddingBottom: "1rem",}}>
                        <Grid item lg={2} md={2} sm={3} xs={12}>
                          <Typography style={webStyle.businessHours} className={classes.changeLogoMobile} variant="body1">
                            {item.title}
                          </Typography>
                          <Box style={webStyle.checkBoxContainer}>
                            {!this.state.isEmployee && 
                              <StyledCheckbox
                                data-test-id="handleCheckClosed"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                checked={item.closed}
                                onChange={(e) => this.handleCheckClosed(e, item.id)}
                              />
                            }
                            <Typography
                              style={(this.state.isEmployee && item.closed) ?
                                {...webStyle.businessHours, color: CONSTANTS.color.darkBlue} :
                                webStyle.businessHours
                              }
                              className={classes.changeLogoMobile}
                              variant="body1"
                            >
                              {t('settings.closed')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={4} xs={6}>
                          <Box style={webStyle.timepickerContainer}>
                            <Typography
                              style={{...webStyle.businessHours, color: CONSTANTS.color.lightGrey, paddingBottom: "0.5rem"}}
                              className={classes.changeLogoMobile}
                              variant="body1"
                            >
                              {t('settings.opensAt')}
                            </Typography>
                            <TimePicker
                              isEmployee = {this.state.isEmployee}
                              time={item.opensAt}
                              onChange={(timeValue: any) =>this.handleChangeHours(item.id, "opensAt", timeValue)}
                              timeFormat={this.handleTimeFormat(this.state.regionalSettings.timeFormat)}
                              t={t}
                              languageSelected={this.state.languageSelected}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={4} xs={6}>
                          <Box style={webStyle.timepickerContainer}>
                            <Typography
                              style={{...webStyle.businessHours, color: CONSTANTS.color.lightGrey, paddingBottom: "0.5rem"}}
                              variant="body1"
                              className={classes.changeLogoMobile}
                            >
                              {t('settings.closesAt')}
                            </Typography>
                            <TimePicker
                              isEmployee = {this.state.isEmployee}
                              time={item.closedAt}
                              onChange={(timeValue: any) =>this.handleChangeHours(item.id, "closedAt", timeValue)}
                              timeFormat={this.handleTimeFormat(this.state.regionalSettings.timeFormat)}
                              t={t}
                              languageSelected={this.state.languageSelected}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </StyledGrid>
                <span style={{...webStyle.hr, margin: "1rem 0px 2rem 0"}}></span>
                <Typography style={webStyle.headerText} className={classes.headerText} variant="subtitle1">
                  {t('settings.taxSettings')}
                </Typography>
                <Grid container>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.taxIdName')}
                    </Typography>
                    <StyledTextFields
                      disabled={this.state.isEmployee}
                      data-test-id="taxSettingsFormdata"
                      style={getConditionalStyle(this.state.isErrorFormData.taxIdName)}
                      fullWidth
                      variant="outlined"
                      name="taxIdName"
                      value={taxIdName}
                      onChange={this.handleChangeTaxSettings}
                      error={Boolean(this.state.isErrorFormData.taxIdName)}
                    />
                    {renderErrorMessage(this.state.isErrorFormData.taxIdName)}
                  </Grid>
                  <Grid item lg={10} md={12} xs={12}>
                    <Typography style={webStyle.formText} className={classes.formText} variant="body1" color="textPrimary">
                      {t('settings.taxIdNumber')}
                    </Typography>
                    <StyledTextFields
                      disabled={this.state.isEmployee}
                      data-test-id="taxSettingsFormdata"
                      style={getConditionalStyle(this.state.isErrorFormData.taxIdNumber)}
                      fullWidth
                      variant="outlined"
                      name="taxIdNumber"
                      value={taxIdNumber}
                      onChange={this.handleChangeTaxSettings}
                      error={Boolean(this.state.isErrorFormData.taxIdNumber)}
                    />
                    {renderErrorMessage(this.state.isErrorFormData.taxIdNumber)}
                  </Grid>
                </Grid>
               <span style={webStyle.hr}></span>
               {this.renderManageTaxrates(classes)}
                <StyledDialog
                  open={this.state.manageTaxDialogOpen}
                  onClose={this.handleCloseManageTaxDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <Typography style={{...webStyle.headerText, paddingBottom: "1rem"}} className={classes.manageTaxText} variant="subtitle1">
                    {t('settings.manageTaxRatesLabel')}
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography style={webStyle.taxText} variant="body1" gutterBottom color="textPrimary">
                        {t('settings.taxName')}
                      </Typography>
                      <StyledTextFields
                        disabled={this.state.isEmployee}
                        data-test-id="formdataTaxRates"
                        style={getConditionalStyle(this.state.isErrorTaxFormData.name)}
                        fullWidth
                        variant="outlined"
                        placeholder={t('settings.forEgGST')}
                        name="name"
                        value={name}
                        onChange={this.handleChangeTaxRates}
                        error={Boolean(this.state.isErrorTaxFormData.name)}
                      />
                      {renderErrorMessage(this.state.isErrorTaxFormData.name)}
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <Typography style={webStyle.taxText} variant="body1" gutterBottom color="textPrimary">
                        {t('settings.taxRate')}
                      </Typography>
                      <StyledTextFields
                        disabled={this.state.isEmployee}
                        data-test-id="formdataTaxRates"
                        style={getConditionalStyle(this.state.isErrorTaxFormData.rate)}
                        fullWidth
                        variant="outlined"
                        name="rate"
                        value={rate}
                        onChange={this.handleChangeTaxRates}
                        error={Boolean(this.state.isErrorTaxFormData.rate)}
                      />
                      {renderErrorMessage(this.state.isErrorTaxFormData.rate)}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography style={webStyle.taxText} variant="body1" gutterBottom color="textPrimary">
                        {t('settings.taxDescription')}
                      </Typography>
                      <StyledTextFields
                        disabled={this.state.isEmployee}
                        data-test-id="formdataTaxRates"
                        style={getConditionalStyle(this.state.isErrorTaxFormData.description)}
                        fullWidth
                        variant="outlined"
                        name="description"
                        value={description}
                        onChange={this.handleChangeTaxRates}
                        error={Boolean(this.state.isErrorTaxFormData.description)}
                      />
                      {renderErrorMessage(this.state.isErrorTaxFormData.description)}
                    </Grid>
                  </Grid>

                  <StyledButtons style={webStyle.buttonsContainer}>
                    <Box style={webStyle.cancelButtonContainer}>
                      <Button
                        data-test-id="handleCloseManageTaxDialog"
                        className={`${classes.buttonMobile} ${classes.commonButtonCancel}`}
                        onClick={this.handleCloseManageTaxDialog}
                      >
                        <Typography style={webStyle.buttonTextCancel} className={classes.buttonTextMobile} variant="subtitle2">
                          {t('settings.cancel')}
                        </Typography>
                      </Button>
                    </Box>
                    <Button
                      data-test-id="handleSaveTaxRates"
                      className={`${classes.buttonMobile} ${classes.commonButtonSave}`}
                      onClick={this.handleSaveTaxRates}
                    >
                      <Typography style={webStyle.buttonTextSave} className={classes.buttonTextMobile} variant="subtitle2">
                        {t('settings.save')}
                      </Typography>
                    </Button>
                  </StyledButtons>
                </StyledDialog>
                {!this.state.isEmployee &&  <span style={webStyle.hr}></span>}
                {!this.state.isEmployee && 
                  <Button
                    data-test-id="handleUpdateSettings"
                    className={`${classes.buttonUpdateSettings} ${classes.buttonContainer1}`}
                    style={{ maxWidth: "232px", float: "right"}}
                    onClick={this.handleUpdateSettings}
                  >
                    <Typography
                      style={webStyle.buttonText}
                      className={classes.buttonTextMobile}
                      variant="subtitle2"
                    >
                      {t('settings.updateSettings')}
                    </Typography>
                  </Button>
                }
              </Box>
            </Container>
          </Box>
        </StyledBox>
        <LoadingBackdrop
          loading={
            this.state.getCompanyInformationLoading ||
            this.state.updateCompanyInformationLoading ||
            this.state.countriesLoading
          }
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainerCompany: {
    [theme.breakpoints.down("md")]: {
      padding: "0 1rem 8rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.25rem 8rem !important",
      marginTop: "0rem !important"
    },
  },
  manageTaxSubText: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    },
  },
  buttonMobile: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important",
      maxWidth: "100% !important",
    },
  },
  commonButtonSave:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    minHeight: "56px",
    borderRadius: "10px",
    maxWidth: "156px",
    textTransform: "none" as const,
    backgroundColor: CONSTANTS.color.darkBlue,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  commonButtonCancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    minHeight: "56px",
    borderRadius: "10px",
    maxWidth: "156px",
    textTransform: "none" as const,
    color: CONSTANTS.color.crimsonRed,
    border: `1px solid ${CONSTANTS.color.crimsonRed}`,
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: '#ff5454',
      color: theme.palette.common.white,
      cursor: "pointer"
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important"
    },
    [theme.breakpoints.down(480)]: {
      maxWidth: "100% !important",
    },
  },
  buttonContainer1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    maxWidth: "256px",
    backgroundColor: CONSTANTS.color.darkBlue,
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  buttonUpdateSettings: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "4rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important"
    },
    [theme.breakpoints.down(480)]: {
      maxWidth: "100% !important",
    },
  },
  manageTaxRatesButtonMobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem !important"
    },
  },
  buttonTextMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
      fontWeight: `${CONSTANTS.fontWeight.fontWeight400} !important`,
    },
  },
  customOption: {
    color: CONSTANTS.color.midGrey
  },
  formText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      paddingTop: "1.5rem !important",
    }
  },
  hexColor: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: "25px !important",
    }
  },
  formTextCompanyLogo: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
      paddingTop: "1rem !important",
      paddingBottom: "1rem !important",
    }
  },
  headerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    }
  },
  hr: {
    [theme.breakpoints.down("xs")]: {
      margin: "1.5rem 0px !important",
    }
  },
  dialogBoxIcon: {
    [theme.breakpoints.down("xs")]: {
      left: "42% !important",
    }
  },
  changeLogoMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    }
  },
  manageTaxText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
    }
  },
  deleteTaxRatesIcon: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0.5rem !important",
    }
  },
  taxRatesHr: {
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      height: "2px !important",
      backgroundColor: `${CONSTANTS.color.paleGrey} !important`,
      margin: "1rem 0 !important"
    }
  },
  manageTaxGrid: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center !important",
    }
  }
});

const webStyle = {
  boxContainerCompany: {
    marginTop: "8.5rem",
    paddingBottom: "10rem"
  },
  headerText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  menuItemCurrency: {
    padding: "1rem",
  },
  colorPicker: {
    height: "50px",
    width: "100%",
  },
  formText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    paddingTop: "2rem",
    paddingBottom: "0.5rem"
  },
  uploadFiles: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    width: "100%",
    minHeight: "130px",
    borderRadius: "8px",
    border: `2px dashed ${CONSTANTS.color.darkBlue}`,
    backgroundColor: CONSTANTS.color.invisibleBlue,
  },
  editIcon: {
    cursor: "pointer",
    height: "36px",
    width: "36px",
    transform: "translate(-58%, 0%)",
  },
  editIconAr: {
    transform: "translate(58%, 0%) rotate(0deg)"
  },
  uploadIcon: {
    width: "21px",
    height: "20px",
  },
  uploadText: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
    textAlign: "center" as const,
    textTransform: "capitalize" as const
  },
  changeLogoContainer: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    width: "100%",
    maxWidth: "354px",
    minHeight: "125px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    transform: "translate(54%, 10%)",
    zIndex: 1,
  },
  dialogBoxIcon: { 
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "8%",
    transform: "translate(-50%, -50%)",
  },
  changeLogoInnerContainer: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "1rem"
  },
  galleryIcon: {
    width: "24px",
    height: "24px",
  },
  changeLogoText: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  removeImageText: {
    color: CONSTANTS.color.crimsonRed,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  phoneContainer: {
    width: "100%",
    borderRadius: "8px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex",
    minHeight: "56px"
  },
  countryCodeContainer: {
    display: "flex",
    borderRadius: "10px 0 0 10px",
    maxWidth: "68px",
    width: "100%",
  },
  hr: {
    display: "flex",
    height: "2px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "2rem 0"
  },
  businessHours: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  timepickerContainer: {
    display: "flex",
    flexDirection: "column" as const
  },
  clockIconStyle: {
    border: "1px solid rgb(221 216 216)",
    position: "absolute" as const,
    height: "100%",
    right: "50px",
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingTop: "1.5rem"
  },
  manageTaxGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  manageTaxContainer: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "20px",
  },
  manageTaxText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  manageTaxRatesGrid: {
    display: "flex",
    flexWrap: "nowrap" as const,
    paddingRight: "1rem",
    paddingTop: "1rem",
  },
  manageTaxSubText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "27px",
    maxWidth: "368px",
    width: "100%",
    color: CONSTANTS.color.midGrey
  },
  manageTaxRatesImage: {
    width: "100%",
    maxWidth: "256px",
    height: "240px",
  },
  taxText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  deleteTaxRatesIcon: {
    paddingTop: "2.8rem"
  },
  deleteTaxIcon: {
    width: "52px",
    height: "52px",
    cursor: 'pointer',
  },
  buttonText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  buttonsContainer: {
    padding: "2rem 0rem 0.5rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelButtonContainer: {
    display: 'flex',
    width: "100%"
  },
 
  buttonTextCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  buttonTextSave: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  errorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },
  redColor: {
    color: CONSTANTS.color.red,
  }
}

const companyInformationStyle = withStyles(styles)(CompanyInformation);
export default withTranslation()(withSnackbar(companyInformationStyle));
// Customizable Area End