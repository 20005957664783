import React from "react";

// Customizable Area Start
import { Box, List, Typography, Tabs, Tab,Grid ,Hidden} from "@material-ui/core";
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { changePassword, logOut, profile } from "./assets";
import { withRouter } from "react-router-dom";
import { CONSTANTS  } from "../../../components/src/theme";
import {backIconMobile} from "../../CustomisableUserProfiles/src/assets"
import { withTranslation } from "react-i18next";
// Customizable Area End

import ProfileSubSideBarControllerWeb, {
  Props,
} from "./ProfileSubSideBarController.web";

// Customizable Area Start

export const StyledTabs = withStyles((theme:Theme)=>({
  root: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
     padding:0
    },
    "& .MuiButtonBase-root": {
      minHeight: "56px",
      [theme.breakpoints.down("xs")]: {
        minHeight: "43px",
      },
    },
   
    "& .MuiTab-textColorInherit": {
      color: CONSTANTS.color.lightBlack
    },
    "& .Mui-selected": {
      borderRadius: "10px",
      backgroundColor: CONSTANTS.color.darkBlue,
      color: CONSTANTS.color.white
    },
  
  },
}))((props: any) => (
  <Tabs {...props} TabIndicatorProps={{ style: { display: "none" } }} />
));

// Customizable Area End

export class ProfileSubSideBar extends ProfileSubSideBarControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
   const StyledTab = withStyles((theme:Theme)=>({
      root: {
        fontFamily: "Rubik",
        fontSize: "1.1667rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.9rem !important",
          width:"33%"
        },
        "& .MuiTab-wrapper": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: this.state.lang === "ar" ? "0" : "1rem",
          textTransform: "capitalize" as const,
          lineHeight: "20px",
          gap:"0.8rem",
          fontWeight: CONSTANTS.fontWeight.fontWeight400,
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            marginLeft: "0 !important"
          
          },
        },
        "& .MuiSvgIcon-root": {
          marginRight: "1rem",
          
        },
        "&:hover": {
          borderRadius: "10px",
          backgroundColor: CONSTANTS.color.darkBlue,
          color: CONSTANTS.color.white,
          "& img": {
            filter: "brightness(0) invert(1)"
          }
        },
        "& .Mui-selected": {
          color: "#ffffff",
        },
      },
    }))(Tab);

    const { classes, width, t}: any = this.props;
    const sideBarData = [
      {
        id: 1,
        title: t("boProfile.profileInformation"),
        icon: profile,
        width: "22px",
        height: "22px",
      },
      {
        id: 2,
        title: t("boProfile.changePassword"),
        icon: changePassword,
        width: "23px",
        height: "21px",
      },
      {
        id: 3,
        title: t("boProfile.logout"),
        icon: logOut,
        width: "21px",
        height: "20px",
      },
    ];
    const isMobile = ['sm','xs'].includes(width);
    const renderContent = (isMob:any)=>{
    
      return (
        <Grid container>
        <Grid item xs={12}>
          <List disablePadding style={{ padding: 1 }}>
              <Box style={webStyle.backIconContainerProfile}>
                <Hidden smUp>
                  <img width={"12px"} height={"24px"} data-test-id="backIcon" src={backIconMobile} onClick={this.handleBackIconProfile} alt="" />
                </Hidden>
                <Typography variant="subtitle1"
                  className={this.state.lang === "en"
                    ? `${classes.ProfileSidebarsideBarTitle} ${classes.ChangeProfileSidebarsideBarTitle}` : 
                    `${classes.ChangeProfileSidebarsideBarTitleAr} ${classes.ProfileSidebarsideBarTitleAr}`}>
                  {t("boProfile.profileSettings")}
                </Typography>
            </Box>
            <StyledTabs
              data-test-id="handleTabValueChange"
              orientation={isMob ? 'horizontal' : 'vertical'} 
              value={this.props.profileActive}
              onChange={this.handleTabValueChange}
              aria-label="Vertical tabs example"
            >
              {sideBarData.map((item: any) => {
                return (
                  <StyledTab
                    icon={
                      <img
                        style={
                          this.props.profileActive === item.id - 1
                            ? {
                                width: item.width,
                                height: item.height,
                             
                                marginBottom: "0px",
                                filter: "brightness(0) invert(1)",
                              }
                            : {
                                width: item.width,
                                height: item.height,
                             
                                marginBottom: "0px",
                              }
                        }
                        src={item.icon}
                        alt=""
                      />
                    }
                    label={item.title}
                    key={item.id}
                    lang={this.state.lang}
                  />
                );
              })}
            </StyledTabs>
          </List>
        </Grid>
      </Grid>
      )
    };
    return (
      <>
        <Box >{renderContent(isMobile)}</Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
 
});
const webStyle = {
  backIconContainerProfile: {
    display: "flex",
    alignItems: "center",
    marginBottom:"2rem",
    gap:"1rem"
  },
};

// @ts-ignore
const loginRouter = withTranslation()(withStyles(styles)(withRouter(ProfileSubSideBar)));
export default loginRouter;
// Customizable Area End
