import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography,
} from "@material-ui/core";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { withStyles } from "@material-ui/core/styles";
import { searchIcon, dropDownSelectIcon } from "./assets";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "../../../components/src/toastweb";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import SiteModal from "../../../components/src/CommonSiteModal.web";
import {CommonListPage} from "../../../components/src/CommonListPage"
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
// Customizable Area End

import CustomersInvoiceController, { Props } from "./CustomersInvoiceController.web";

// Customizable Area Start
// Customizable Area End

export class CustomerInvoices extends CustomersInvoiceController {
  constructor(props: Props) {
    super(props);
  }
  

  render() {
    // Customizable Area Start
    
    const { classes , t}: any = this.props;
    const CustInvoiceMockRow = [
      { id: 1, key: "title", title: `${t('invoice.menuLabel.invoiceTitle')}` },
      { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
      { id: 3, key: "issue_date", title: `${t('invoice.menuLabel.issueDate')}`},
      { id: 4, key: "payment_due", title: `${t('invoice.menuLabel.paymentDue')}`},
      { id: 5, key: "product_name", title: `${t('invoice.menuLabel.productName')}`},
      { id: 6, key: "service_name", title: `${t('invoice.menuLabel.serviceName')}`},
      { id: 7, key: "job_title", title: `${t('invoice.menuLabel.jobReference')}`},
      { id: 8, key: "product_quantity", title: `${t('invoice.menuLabel.productQuantity')}`},
    ]
    return (
      <>
       <SiteModal
          siteModal={this.state.siteDailog}
          classes={classes}
          handleSiteCloseModal={this.handleSiteCloseModal}
          t={t}          
          handleChangeSearchCustomerSite={this.handleChangeSearchInvoice}
          siteInformationData={this.state.customerSitesData}
          siteDetails={this.state.siteDetails}
          selectedSiteCustomer={this.selectedSiteCustomer}
          createSite={this.createSite}
          />
        <Box>
          <Box
            className={classes.SitessearchContainer}
          >
            <Box>
              <img style={{...webStyle.searchIconImg, marginTop:"5px"}} src={searchIcon} alt="" />
            </Box>
            <InputBase
              style={webStyle.inputBaseField}
              className={classes.RequestinputBase}
              placeholder={t("customers.searchInvoice")}
              inputProps={{ "aria-label": "search" }}
              onChange={this.handleChangeSearchInvoice.bind(this, "invoice")}
            />
          </Box>
          <CommonListPage 
            classes={classes}
            requestMockTableRow={CustInvoiceMockRow}
            sortingProperty={this.state.sortColumn}
            sortingOrder={this.state.sortDirection}
            statusOrder={this.state.statusOrder}
            statusData={this.state.statusData}
            sortRequestsData={this.sortRequestsData}
            t={t}
            anchorEl={this.state.anchorEl}
            anchorId={this.state.anchorId}
            siteData={undefined}
            listData={this.state.listData}
            popoverHeading={this.state.popoverHeading}
            handleTooltipClose={this.handleTooltipCloseInvoice}
            dropDownSelectIcon={dropDownSelectIcon}
            handleTooltipOpen={this.handleTooltipOpenInvoice}
            handleViewData={this.handleInvoiceViewData}
            type="INVOICES"
            lang = {this.state.lang}
          />
        
          <Box style={webStyle.buttonsContainerCreateInvoice}>
            <CreateQuote>
              <Typography
                style={webStyle.buttonTextCreateNewInvoice}
                variant="subtitle2"
                onClick={this.handleCreateCustomerInvoice}
              >
                 {t("customers.createInvoice")}
              </Typography>
            </CreateQuote>
          </Box>
        </Box>
        <LoadingBackdrop
          loading={this.state.tableLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const styles:any = (theme: any) => ({

});

const webStyle = {
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    transform: "rotate(0deg)"
  },
  InvoiceCustomerTableHeadSpan: {
    cursor: "pointer",
    padding: "0.8rem",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 10px"
  },
  NoDataText: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    paddingTop: "10rem",

  },
  noRequestText: {
    color: "#34313a",
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  searchIconImg: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  inputBaseField: {
    width: "100%",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    lineHeight: "16px",
    color:CONSTANTS.color.grey
  },
  customersInvoicesContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    minHeight: "400px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  },
  tableCellHeadInvoice: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
  },
  tableCellBodyInvoice: {
    color: "black",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 500,
    cursor: "pointer",
    borderBottom: "none",
    paddingTop: 0,
    width:"20%",
    padding:"0.8rem"
  },
  tableHeadSpanInvoice: {
    cursor: "pointer",
    padding: "0.8rem",
    borderRadius: "2px",
    backgroundColor: "rgba(Blue2,Blue2,Blue2,0.4)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 5px",
  },
  tableHeadTextInvoice: {
    fontSize: "18px",
  },
  buttonsContainerCreateInvoice: {
    padding: "4rem 0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonTextCreateNewInvoice: {
    color: "#ffffff",

    fontWeight: 400,
    textAlign: "center" as "center",
  },
  dropDownIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
  },
};
const invoiceCustomerRouter:any = withTranslation()(withStyles(styles)(CustomerInvoices));
const customerComponent = withRouter(invoiceCustomerRouter);
export default withSnackbar(customerComponent);

// export default withStyles(styles)(CustomerInvoices);
// Customizable Area End