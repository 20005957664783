import React from 'react';
// Customizable Area Start
import { WithStyles, Button, Dialog, DialogTitle, withStyles, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { createStyles,Theme } from '@material-ui/core/styles';
import { CONSTANTS } from '../../../components/src/theme';
// Customizable Area End


// Customizable Area Start
export const DeleteModelStyles = (theme: Theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: CONSTANTS.color.lightGrey,
            width: "15px",
            height: "16px",
        },

        deleteText: {
            color:CONSTANTS.color.lightBrown,
            fontWeight: theme.typography.fontWeightMedium,
            marginTop: "0.5rem",
            marginBottom: "1rem"
        },
        contentText: {
            color:CONSTANTS.color.lightBrown,
            fontWeight: theme.typography.fontWeightRegular,
            marginTop: "0.5rem",

        },
        proceedBtn: {
            width: "150px",
            height: "56px",
            borderRadius: "10px",
            border: `1px solid ${CONSTANTS.color.darkBlue}`,
            color:CONSTANTS.color.darkBlue,
            textTransform: "capitalize",
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white,
                cursor: "pointer",
                border: "none"
            },
        },
        padding:{
            padding:'8px 24px'
        },
        cancelBtn: {
            width: "150px",
            height: "56px",
            borderRadius: "10px",
            color: CONSTANTS.color.lightGrey,
            textTransform: "capitalize",
            border: `1px solid ${CONSTANTS.color.lightGrey}`,
            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
            '&:hover': {
                cursor: "pointer",
                border:"none",
                backgroundColor: CONSTANTS.color.lightGrey,
                color: theme.palette.common.white
            },
        },
        ml_13:{
            marginLeft:"13px"
        }


    });
interface Props extends WithStyles {
    open: boolean;
    onClose: any;
    deleteType: string;
    subType: string;
    handleDelete: any
    t?: (key: string) => string;
}
// Customizable Area End



export class DeleteModel extends React.Component<Props> {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes,t } = this.props
        return (
            // Customizable Area Start
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                PaperProps={{
                    style: {
                        width: "634px",
                        borderRadius: "8px",
                    },
                }}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
                        <CloseIcon style={{ color: "#161616" }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h5' className={classes.deleteText}>{this.props.deleteType}</Typography>
                    <DialogContentText variant='subtitle2' className={classes.contentText}>{this.props.subType}</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.padding} style={{ marginBottom: "10px" }}>
                    <Button variant='outlined' onClick={this.props.onClose} className={`${classes.cancelBtn} ${classes.ml_13}`}>
                        <Typography variant='subtitle2'>{t ? t('invoice.button.cancel') : "Cancel"}</Typography> 
                    </Button>
                    <Button variant='outlined'
                        className={classes.proceedBtn}
                        data-testid='handleDelete'
                        onClick={this.props.handleDelete}
                    >
                        <Typography variant='subtitle2'>{t ? t('invoice.button.proceed') : "Proceed"}</Typography> 
                    </Button>
                </DialogActions>
            </Dialog>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export default withStyles(DeleteModelStyles)(DeleteModel);
// Customizable Area End