import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Container,
  Hidden,
  Button,
  ClickAwayListener
} from "@material-ui/core";
import {
  profileBg,
  quoteIcon,
  playIcon,
  playRootBg,
  playBg,
  bg1,
  bg2,
  bg3,
  bg4,
  globeIcon,
  selectedIcon,
  contactUsDropDown,
  backIcon,
  frontIcon
} from "./assets";
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import NavBar from "./NavBar.web";
import Footer from "./Footer.web";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Navigation } from "swiper";
import "./Swiper.css";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
// Customizable Area End

import HomeController, {
  Props,
} from "./HomeController.web";

// Customizable Area Start
interface LanguageArray {
  id: number,
  name: string,
  value: string
}

const languages = [
  { id: 1, name: "English", value: "en" },
  { id: 2, name: "Arabic", value: "ar" },
]

const languageChange = (
  languageArray: LanguageArray[],
  languageModal: boolean,
  handleClickCloselanguageModal: () => void,
  languageSelected: string,
  language: string,
  classes: ClassNameMap,
  handleClickSelectlanguage: Function
  ) => {
  return (
    <Box style={webStyle.languageModalContainer}>
      {languageModal ? (
        <ClickAwayListener data-test-id="handleClickCloselanguageModal" onClickAway={handleClickCloselanguageModal}>
          <Box
            style={languageSelected === "ar" ? {...webStyle.languageModal, ...webStyle.arabicRightStyle} : webStyle.languageModal}
            className={languageSelected === "ar" ? classes.languageModal : classes.languageModalRight}
          >
            {languageArray.map((lng) => (
              <Box
                data-test-id="handleSelectlanguage"
                key={lng.id}
                style={
                  lng.name === language ?
                  {...webStyle.languageContainer, ...webStyle.languageContainerColor} :
                  webStyle.languageContainer
                }
                onClick={() => handleClickSelectlanguage(lng)}
              >
                {lng.name === language ?
                  <img
                    style={webStyle.selectedIcon}
                    src={selectedIcon}
                    alt=""
                  /> : null
                }
                <Typography
                  style={
                    lng.name === language ?
                    {...webStyle.language, ...webStyle.colorStyle} :
                    webStyle.language
                  }
                  variant="body1"
                >
                  {lng.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </ClickAwayListener>
      ) : null}
    </Box>
  );
}

const personImageFunction = (item: { attributes: { profile_image: string } }, languageSelected: string, classes: ClassNameMap) => {
  return (
    <Box>
      <img
        style={webStyle.personImage}
        className={classes.personImage}
        src={profileBg}
        alt="Image"
      />
      <img
        style={languageSelected === "ar" ? {...webStyle.personImageRelative, ...webStyle.rightStyle} : webStyle.personImageRelative}
        className={languageSelected === "ar" ? `${classes.personImageRelative} ${classes.personImageRelativeAr} ${classes.personImageRelativeArabic}` : classes.personImageRelative}
        src={item?.attributes?.profile_image}
        alt="Image"
      />
    </Box>
  );
}

const swiperDirection = (languageSelected: string) => {
  return languageSelected === "ar" ? "rtl" : "ltr"
}

const swiperKey = (languageSelected: string) => {
  return languageSelected === "ar" ? "ar" : "en"
}

const playImg = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.playBg, ...webStyle.playBgAr} : webStyle.playBg
}

const playButton = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.playIcon, ...webStyle.playIconAr} : webStyle.playIcon
}
// Customizable Area End

export class Home extends HomeController {
  constructor(props: Props) {
    super(props);
  }

  languageData = () => {
    const { classes }: any = this.props;
    return (
      <Box style={{position: "relative"}}>
        {this.state.languageModal ? (
          <Box style={webStyle.languageModal} className={classes.languageModal}>
            {languages.map((language) => (
              <Box data-test-id="handleClickSelectlanguage" key={language.id} style={language.name === this.state.language ? {...webStyle.languageContainer, backgroundColor: "rgba(28,24,175,0.06)", padding: "1rem 1rem 1rem"} : webStyle.languageContainer} onClick={() => this.handleClickSelectlanguage(language)}>
                {language.name === this.state.language ?
                  <img
                    style={webStyle.selectedIcon}
                    src={selectedIcon}
                    alt=""
                  /> : null}
                <Typography style={language.name === this.state.language ? {...webStyle.language, color: "#1c18af"} : webStyle.language} component={"div"}>
                  {language.name}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
    );
  }

  render() {
    // Customizable Area Start
    const { t } = this.props;

    const languageArray = [
      { id: 1, name: t("landingPage.Home.english"), value: "en" },
      { id: 2, name: t("landingPage.Home.arabic"), value: "ar" },
    ]
    
    const renderButtonGetStarted = (value: string, style: string) => {
      return (
        <Button
          data-test-id="handleGetStartedForFree"
          style={{ marginTop: style }}
          className={classes.buttonContainerGetStarted}
          type="submit"
        >
          {!this.state.homeLoading &&
            <Typography
              style={webStyle.buttonTextGetStarted}
              className={classes.buttonTextGetStarted}
              variant="caption"
              dangerouslySetInnerHTML={{__html: (`${value}`)}}
            ></Typography>
          }
        </Button>
      );
    }
    const { classes }: any = this.props;
    window.addEventListener('resize', this.handleQuoteNameShow);
    return (
      <>
        <NavBar {...this.props} active={0} homePage={this.homePage} oldProps={this} />
        <Box
          sx={webStyle.boxContainer}
          className={classes.boxContainer}
        >
          <Container maxWidth={"lg"}>
            <Hidden smDown>
             
              {languageChange(
                languageArray,
                this.state.languageModal,
                this.handleClickCloselanguageModal,
                this.state.languageSelected,
                this.state.language,
                classes,
                this.handleClickSelectlanguage
              )}
            </Hidden>
            <Grid container spacing={2} className={classes.columnReverse}>
              <Grid item lg={6} md={6} xs={12} style={webStyle.leftContent}>
                <Grid container>
                  <Grid item lg={10} md={10} xs={12}>
                    {!this.state.homeLoading &&
                      <Typography
                        style={webStyle.heroHeader}
                        className={classes.heroHeader}
                        variant="h3"
                        dangerouslySetInnerHTML={{
                          __html: (
                            `${this.state.homeData?.intelligent_business_management_software?.data?.attributes?.title}`
                          )
                        }}
                      ></Typography>
                    }
                  </Grid>
                  <Grid item lg={10} md={10} xs={12}>
                    {!this.state.homeLoading &&
                      <Typography
                        style={webStyle.heroSubHeader}
                        className={classes.heroSubHeader}
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: (
                            `${this.state.homeData?.intelligent_business_management_software?.data?.attributes?.discription}`
                          )
                        }}
                      ></Typography>
                    }
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <form onSubmit={this.handleGetStartedForFree}>
                      <div style={webStyle.emailSection} className={classes.emailSection}>
                        <input
                          data-test-id="handleChangeEmail"
                          style={webStyle.input}
                          className={classes.input}
                          type="text"
                          placeholder={t("landingPage.Home.email")}
                          value={this.state.email}
                          onChange={this.handleChangeEmail}
                        />
                        <Hidden xsDown>
                          {renderButtonGetStarted(
                            this.state.homeData?.intelligent_business_management_software?.data?.attributes?.button_text,
                            "0rem"
                          )}
                        </Hidden>
                      </div>
                      <Typography
                        style={webStyle.errorMessage}
                        variant="body1"
                        className={classes.input}
                      >
                        {this.state.emailError}
                      </Typography>
                      <Hidden smUp>
                        {renderButtonGetStarted(
                          this.state.homeData?.intelligent_business_management_software?.data?.attributes?.button_text,
                          "1.5rem"
                        )}
                      </Hidden>
                    </form>
                  </Grid>
                  <Grid item lg={10} md={10} xs={12}>
                    {!this.state.homeLoading &&
                      <Typography
                        style={webStyle.tryLamsa}
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: (
                            `${this.state.homeData?.intelligent_business_management_software?.data?.attributes?.sub_discription}`
                          )
                        }}
                      ></Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} xs={12} className={classes.rightContent}>
                <img
                  style={webStyle.heroImage}
                  className={classes.heroImage}
                  src={this.state.homeData?.intelligent_business_management_software?.data?.attributes?.images[0]?.url}
                  alt="Image"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer2}
          className={classes.boxContainer2}
        >
          <Container maxWidth={"lg"}>
            <Box style={webStyle.flexColumnStyle}>
              <div style={webStyle.positionRelativeStyle}>
                <img style={webStyle.bg1} className={classes.bg1} src={bg1} alt="" />
              </div>
              <Grid item lg={12} md={12} xs={12}>
                {!this.state.homeLoading &&
                  <Typography
                    style={webStyle.keepYourText}
                    className={classes.keepYourText}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.homeData?.Keep_your_business_organized?.data?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                }
              </Grid>
              {!this.state.homeLoading &&
                <Typography
                  style={webStyle.withLamsaText}
                  className={classes.withLamsaText}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: (
                      `${this.state.homeData?.Keep_your_business_organized?.data?.attributes?.discription}`
                    )
                  }}
                ></Typography>
              }
              <div style={webStyle.positionRelativeStyle}>
                <img style={webStyle.bg4} className={classes.bg4} src={bg4} alt="" />
              </div>
              <Button
                className={classes.buttonContainerBookDemo}
                onClick={this.handleButtonBookADemo}
              >
                {!this.state.homeLoading &&
                  <Typography
                    style={webStyle.buttonTextBookDemo}
                    className={classes.buttonTextBookDemo}
                    variant="caption"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.homeData?.Keep_your_business_organized?.data?.attributes?.button_text}`
                      )
                    }}
                  ></Typography>
                }
              </Button>
              <div style={webStyle.positionRelativeStyle}>
                <img style={webStyle.bg2} className={classes.bg2} src={bg2} alt="" />
              </div>
              <div style={webStyle.positionRelativeStyle}>
                <img style={webStyle.bg3} className={classes.bg3} src={bg3} alt="" />
              </div>
              <div style={webStyle.positionRelativeStyle}>
                <img style={webStyle.bg2nd} className={classes.bg2nd} src={bg2} alt="" />
              </div>
              <Grid item lg={12} md={12} xs={12}>
                <img
                  style={webStyle.dashboardImage}
                  src={this.state.homeData?.Keep_your_business_organized?.data?.attributes?.images[0]?.url}
                  alt="Image"
                />
              </Grid>
              <div style={webStyle.playButton} className={classes.playButton}>
                <img style={webStyle.playRootBg} src={playRootBg} alt="Image" />
                <img style={playImg(this.state.languageSelected)} src={playBg} alt="Image" />
                <img style={playButton(this.state.languageSelected)} src={playIcon} alt="Image" />
              </div>
            </Box>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer3}
          className={classes.boxContainer3}
        >
          <Container maxWidth={"lg"}>
            <Box style={webStyle.flexColumnStyle}>
              <Grid item lg={12} md={12} xs={12}>
                {!this.state.homeLoading &&
                  <Typography
                    style={webStyle.hearText}
                    className={classes.hearText}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `<p>${this.state.homeData?.feedback?.data[0]?.attributes?.title}</p>`
                      )
                    }}
                  ></Typography>
                }
              </Grid>
              <Swiper
                className="mySwiper"
                ref={this.swiper}
                navigation={true}
                rewind={true}
                modules={[Navigation]}
                loop={true}
                dir={swiperDirection(this.state.languageSelected)}
                key={swiperKey(this.state.languageSelected)}
              >
                {this.state.homeData?.feedback?.data?.map((item: any) => (
                  <SwiperSlide key={item.id}>
                    <Box style={webStyle.customerSection} className={classes.customerSection}>
                      <Grid container spacing={1}
                        style={webStyle.leftContent}
                      >
                        <Hidden xsDown>
                          <img
                            style={{transform: this.state.languageSelected === "ar" ? "rotate(180deg)" : "none", marginTop: "4rem"}}
                            onClick={this.handleSliderPrevious}
                            className={classes.swiperArrow}
                            height="45"
                            width="45"
                            src={backIcon}
                            alt=""
                          />
                        </Hidden>
                        <Grid item lg={1} md={false} xs={12}>
                        </Grid>
                        <Hidden smUp>
                          <Grid item lg={2} md={12} xs={12} style={{...webStyle.positionRelativeStyle, ...webStyle.swiperContainer}} className={classes.personImageGrid}>
                            <img
                              data-test-id="handleSliderPrevious"
                              style={{transform: this.state.languageSelected === "ar" ? "rotate(180deg)" : "none", marginTop: "4rem"}}
                              onClick={this.handleSliderPrevious}
                              className={`${classes.swiperArrow} ${classes.arrowStyle}`}
                              height="45"
                              width="45"
                              src={backIcon}
                              alt=""
                            />
                            {personImageFunction(item, this.state.languageSelected, classes)}
                            <img
                              data-test-id="handleSliderNext"
                              style={{transform: this.state.languageSelected === "ar" ? "rotate(180deg)" : "none", marginTop: "4rem"}}
                              onClick={this.handleSliderNext}
                              className={`${classes.swiperArrow} ${classes.arrowStyle}`}
                              height="45"
                              width="45"
                              src={frontIcon}
                              alt=""
                            />
                          </Grid>
                        </Hidden>
                        <Hidden xsDown>
                          <Grid item lg={2} md={12} xs={12} style={webStyle.positionRelativeStyle} className={classes.personImageGrid}>
                            {personImageFunction(item, this.state.languageSelected, classes)}
                          </Grid>
                        </Hidden>
                        {this.state.quoteNameShow && (
                          <div style={webStyle.quoteNameShow}>
                            {!this.state.homeLoading &&
                              <Typography
                                style={webStyle.nameTextShow}
                                className={classes.quoteNameShow}
                                variant="h3"
                                dangerouslySetInnerHTML={{
                                  __html: (
                                    `<p>${item?.attributes?.name}</p>`
                                  )
                                }}
                              ></Typography>
                            }
                            {!this.state.homeLoading &&
                              <Typography
                                style={webStyle.nameSubTextShow}
                                className={classes.nameSubTextShow}
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                  __html: (
                                    `${item?.attributes?.role}`
                                  )
                                }}
                              ></Typography>
                            }
                          </div>
                        )}
                        <Grid item lg={1} md={false} xs={12}>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12} className={classes.quotationGrid}>
                          <Typography
                            style={this.state.languageSelected === "ar" ? {...webStyle.quotation, ...webStyle.quotationArabicStyle} : webStyle.quotation}
                            className={classes.quotation}
                            variant="subtitle2"
                          >
                            <img style={this.state.languageSelected === "ar" ? {...webStyle.quoteIcon, ...webStyle.quoteIconArabicStyle} : webStyle.quoteIcon} className={classes.quoteIcon} src={quoteIcon} alt="Image" />&nbsp;
                              {item?.attributes?.discription?.replace(/<p>/g ,'').replace(/<\/p>/g ,'')}
                            <img style={this.state.languageSelected === "ar" ? {...webStyle.quoteIcon, ...webStyle.quoteIconRightStyle} : {...webStyle.quoteIcon, ...webStyle.quoteIconArabic}} className={classes.quoteIcon2} src={quoteIcon} alt="Image" />
                          </Typography>
                        </Grid>
                        <Grid item lg={1} md={false} xs={12}>
                        </Grid>
                        <Hidden xsDown>
                          <img
                            style={{transform: this.state.languageSelected === "ar" ? "rotate(180deg)" : "none", marginTop: "4rem"}}
                            onClick={this.handleSliderNext}
                            className={classes.swiperArrow}
                            height="45"
                            width="45"
                            src={frontIcon}
                            alt=""
                          />
                        </Hidden>
                      </Grid>
                      {!this.state.homeLoading &&
                        <div
                          style={
                            this.state.languageSelected === "ar" ?
                            {...webStyle.quoteNameShow2, ...webStyle.quoteNameShowAr} :
                            {...webStyle.quoteNameShow2, ...webStyle.quoteNameArStyle}
                          }
                          className={classes.quoteNameShow2}
                        >
                          <Typography
                            style={webStyle.nameText}
                            variant="h3"
                            dangerouslySetInnerHTML={{
                              __html: (
                                `<p>${item?.attributes?.name}</p>`
                              )
                            }}
                          ></Typography>
                          <Typography
                            style={webStyle.nameSubText}
                            variant="body2"
                            dangerouslySetInnerHTML={{
                              __html: (
                                `${item?.attributes?.role}`
                              )
                            }}
                          ></Typography>
                        </div>
                      }
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer4}
          className={classes.boxContainer4}
        >
          <Container maxWidth={"lg"}>
            {!this.state.homeLoading &&
              <Box style={webStyle.flexColumnStyle}>
                <Grid item lg={12} md={12} xs={12}>
                  <div style={webStyle.positionRelativeStyle}>
                    <img style={{...webStyle.bg1, ...webStyle.transformStyle}} className={classes.bg12} src={bg1} alt="" />
                  </div>
                  <Typography
                    style={webStyle.whyLamsa}
                    className={classes.whyLamsa}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.homeData?.why_lamsa?.data?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                </Grid>
                <Typography
                  style={webStyle.whetherText}
                  className={classes.whetherText}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: (
                      `${this.state.homeData?.why_lamsa?.data?.attributes?.discription}`
                    )
                  }}
                ></Typography>
                <div style={webStyle.positionRelativeStyle}>
                  <img style={{...webStyle.bg4, ...webStyle.transformBgStyle}} className={classes.bg4} src={bg4} alt="" />
                </div>
                <div style={webStyle.positionRelativeStyle}>
                  <img style={{...webStyle.bg2, ...webStyle.transformBg2Style}} className={classes.bg22} src={bg2} alt="" />
                </div>
                <div style={webStyle.positionRelativeStyle}>
                  <img style={{...webStyle.bg3, ...webStyle.transformBg3Style}} className={classes.bg3} src={bg3} alt="" />
                </div>
                <div style={webStyle.positionRelativeStyle}>
                  <img style={{...webStyle.bg2nd, ...webStyle.transformBg2ndStyle}} className={classes.bg2nd} src={bg2} alt="" />
                </div>
              </Box>
            }
            <Box style={webStyle.whyLamsaSection} className={classes.whyLamsaSection}>
              <Grid container spacing={3} style={webStyle.leftContent}>
                <Grid item lg={5} sm={12} md={6} xs={12} className={classes.personImageGrid}>
                  {!this.state.homeLoading &&
                    <Grid container>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography
                          style={webStyle.withLamsa}
                          className={classes.withLamsa}
                          variant="h5"
                          dangerouslySetInnerHTML={{
                            __html: (
                              `${this.state.homeData?.with_lamsa_you_can?.data[0]?.attributes?.title}`
                            )
                          }}
                        ></Typography>
                        {this.state.homeData?.with_lamsa_you_can?.data?.map((item: any) => {
                          return (
                            <Typography
                              style={this.state.languageSelected === "ar" ? {...webStyle.withLamsaList, ...webStyle.withLamsaListAr} : webStyle.withLamsaList}
                              className={classes.withLamsaList}
                              variant="subtitle2"
                              key={item?.id}
                            >
                              <img
                                style={webStyle.icons}
                                className={classes.icons}
                                src={item?.attributes.icon[0]?.url}
                                alt=""
                              />
                              {item?.attributes?.discription?.replace(/<p>/g ,'').replace(/<\/p>/g ,'')}
                            </Typography>
                          );
                        })}
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Button
                          className={classes.buttonContainerlearnMore}
                          onClick={this.handleButtonLearnMore}
                        >
                          <Typography
                            style={webStyle.buttonTextlearnMore}
                            className={classes.buttonTextlearnMore}
                            variant="caption"
                            dangerouslySetInnerHTML={{
                              __html: (
                                `${this.state.homeData?.why_lamsa?.data?.attributes?.button_text}`
                              )
                            }}
                          ></Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  }
                </Grid>
                <Grid item lg={4} sm={6} md={6} xs={6} className={classes.personImageGrid}>
                  <img
                    style={webStyle.person1}
                    src={this.state.homeData?.why_lamsa?.data?.attributes?.images[0]?.url}
                    alt=""
                  />
                </Grid>
                <Grid item lg={3} sm={6} md={12} xs={6} className={classes.personImageGrid}>
                  <div style={webStyle.columnImages} className={classes.columnImages}>
                    <img
                      style={webStyle.person2}
                      className={classes.person2}
                      src={this.state.homeData?.why_lamsa?.data?.attributes?.images[1]?.url}
                      alt=""
                    />
                    <img
                      style={webStyle.person3}
                      src={this.state.homeData?.why_lamsa?.data?.attributes?.images[2]?.url}
                      alt=""
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box
          style={webStyle.boxContainer5}
          className={classes.boxContainer5}
        >
          <Container maxWidth={"lg"}>
            <Box style={webStyle.flexColumnStyle}>
              <Grid item lg={12} md={12} xs={12}>
                {!this.state.homeLoading &&
                  <Typography
                    style={webStyle.startLamsa}
                    className={classes.startLamsa}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: (
                        `${this.state.homeData?.ready_to_start_with_lamsa?.data[0]?.attributes?.title}`
                      )
                    }}
                  ></Typography>
                }
              </Grid>
              {!this.state.homeLoading &&
                <Typography
                  style={webStyle.noCardText}
                  className={classes.noCardText}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: (
                      `${this.state.homeData?.ready_to_start_with_lamsa?.data[0]?.attributes?.discription}`
                    )
                  }}
                ></Typography>
              }
            </Box>
            <form onSubmit={this.handleGetStartedForFree2}>
              <Grid item lg={12} md={12} xs={12} style={webStyle.flexColumnStyle} className={classes.flexColumnStyle}>
                <div style={webStyle.emailSection} className={classes.emailSection}>
                  <input
                    data-test-id="handleChangeEmail2"
                    style={webStyle.input}
                    className={classes.input}
                    type="text"
                    placeholder={t("landingPage.Home.email")}
                    value={this.state.email2}
                    onChange={this.handleChangeEmail2}
                  />
                  <Hidden xsDown>
                    {renderButtonGetStarted(
                      this.state.homeData?.ready_to_start_with_lamsa?.data[0]?.attributes?.button_text,
                      "0rem"
                    )}
                  </Hidden>
                </div>
                <Typography
                  style={webStyle.errorMessage}
                  variant="body1"
                  className={classes.input}
                >
                  {this.state.emailError2}
                </Typography>
                <Hidden smUp>
                  {renderButtonGetStarted(
                    this.state.homeData?.ready_to_start_with_lamsa?.data[0]?.attributes?.button_text,
                    "1.5rem"
                  )}
                </Hidden>
              </Grid>
            </form>
          </Container>
        </Box>
        <Footer {...this.props} active={0} homePage={this.homePage} oldProps={this} />
        <LoadingBackdrop loading={this.state.homeLoading} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainer: {
    [theme.breakpoints.down("sm")]: {
      height: "auto !important",
      marginTop: "8rem !important",
      padding: "0px 0.5rem !important"
    }
  },
  emailSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 1rem !important",
      maxWidth: "100% !important",
      height: "48px !important"
    }
  },
  heroHeader: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
      lineHeight: "35px !important",
    }
  },
  heroSubHeader: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: "18px !important",
      fontSize: "14px !important",
      maxWidth: "100% !important",
    }
  },
  columnReverse: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column-reverse !important",
    }
  },
  rightContent: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start !important",
    }
  },
  heroImage: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem !important",
      paddingBottom: "1rem !important",
    }
  },
  personImageGrid: {
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
      justifyContent: "center !important",
      marginTop: "1rem !important",
      marginBottom: "1rem !important",
    }
  },
  person2: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2rem !important",
    },
  },
  whyLamsaSection: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem !important",
    }
  },
  withLamsa: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
      marginBottom: "1rem !important",
    }
  },
  withLamsaList: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    }
  },
  icons: {
    [theme.breakpoints.down("xs")]: {
      width: "15px !important",
      height: "15px !important"
    }
  },
  buttonContainerBookDemo: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important",
      maxWidth: "100% !important",
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.thickBlue,
    borderRadius: "10px",
    minHeight: "56px",
    maxWidth: "166px",
    width: "100%",
    marginTop: "2.5rem",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  buttonTextBookDemo: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    }
  },
  buttonContainerlearnMore: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important",
      maxWidth: "100% !important",
      marginTop: "2rem !important",
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.thickBlue,
    borderRadius: "10px",
    minHeight: "56px",
    maxWidth: "166px",
    width: "100%",
    marginTop: "3rem",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  buttonTextlearnMore: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    }
  },
  columnImages: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0rem !important",
    }
  },
  personImage: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "150px !important",
    }
  },
  personImageRelative: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "288px !important",
      left: "auto !important",
      transform: "translate(-290px, 11px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "0 !important",
      maxWidth: "145px !important",
      transform: "translate(-145px, 9px) !important",
    },
  },
  personImageRelativeArabic: {
    [theme.breakpoints.down("md")]: {
      transform: "translate(290px, 11px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translate(145px, 9px) !important",
    },
  },
  personImageRelativeAr: {
    [theme.breakpoints.down("md")]: {
      right: "auto !important",
    },
  },
  boxContainer2: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem !important",
      padding: "3rem 0rem 0rem 0rem !important",
    },
  },
  bg1: {
    [theme.breakpoints.down("sm")]: {
      width: "22px !important",
      height: "20px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(-10rem, -15px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg12: {
    [theme.breakpoints.down("sm")]: {
      width: "22px !important",
      height: "20px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(-2rem, -15px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg2: {
    [theme.breakpoints.down("md")]: {
      width: "17px !important",
      height: "17px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(-10rem, -45px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg22: {
    [theme.breakpoints.down("md")]: {
      width: "17px !important",
      height: "17px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(-10rem, 27px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  bg3: {
    [theme.breakpoints.down("md")]: {
      display: "none !important"
    },
  },
  bg2nd: {
    [theme.breakpoints.down("md")]: {
      display: "none !important"
    },
  },
  bg4: {
    [theme.breakpoints.down("md")]: {
      width: "18px !important",
      height: "20px !important",
      position: "absolute" as "absolute !important",
      transform: "translate(8rem, 30px) !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    }
  },
  quotation: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "0rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
      lineHeight: "24px !important",
    },
  },
  quoteIcon: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "9px !important",
      height: "auto !important",
      transform: "translate(0px, -10px) !important",
    }
  },
  quoteIcon2: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "9px !important",
      height: "auto !important",
    }
  },
  keepYourText: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem !important",
      fontSize: "24px !important",
      lineHeight: "32px !important",
    }
  },
  withLamsaText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px !important",
      lineHeight: "18px !important",
    }
  },
  hearText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px !important",
      lineHeight: "28px !important",
    }
  },
  quoteNameShow2: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    }
  },
  playButton: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    }
  },
  boxContainer3: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0.5rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "4rem !important",
    },
  },
  boxContainer4: {
    [theme.breakpoints.down("sm")]: {
      padding: "6rem 1rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0.5rem !important",
    },
  },
  boxContainer5: {
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0.5rem 2rem !important",
    },
  },
  startLamsa: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
      lineHeight: "30px !important",
    },
  },
  noCardText: {
    [theme.breakpoints.down("sm")]: {
      margin: "1rem 0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: "30px !important",
      fontSize: "18px !important",
    },
  },
  quotationGrid: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "2rem !important",
    },
  },
  languageModal: {
    [theme.breakpoints.down("md")]: {
      left: "63% !important",
    },
  },
  languageModalRight: {
    [theme.breakpoints.down("md")]: {
      right: "63% !important",
    },
  },
  buttonContainerGetStarted: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important",
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.thickBlue,
    borderRadius: "10px",
    minHeight: "56px",
    maxWidth: "166px",
    width: "100%",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  buttonTextGetStarted: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },
  input: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px !important",
    },
  },
  flexColumnStyle: {
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
    },
  },
  quoteNameShow: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
    },
  },
  nameSubTextShow: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px !important",
    },
  },
  whyLamsa: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    },
  },
  whetherText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  swiperArrow: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30px !important",
      width: "30px !important",
    },
  },
  arrowStyle: {
    [theme.breakpoints.down("xs")]: {
      height: "20px !important",
      width: "20px !important",
    }
  }
});

const webStyle = {
  boxContainer: {
    marginTop: "10rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 2rem"
  },
  leftContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heroHeader: {
    fontWeight: CONSTANTS.fontWeight.fontWeight800,
    lineHeight: "45px"
  },
  heroSubHeader: {
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "22px",
    marginTop: "1.5rem",
    opacity: 0.7,
  },
  emailSection: {
    marginTop: "10px",
    padding: "0 1.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "560px",
    width: "100%",
    height: "96px",
    borderRadius: "10px",
    background: CONSTANTS.color.white,
    boxShadow: "0px 5px 24px 10px rgba(28,24,175,0.05)",
  },
  input: {
    border: 0,
    color: CONSTANTS.color.coolDarkGrey,
    fontSize: "18px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    outline: "none",
    width: "100%"
  },
  buttonTextGetStarted: {
    color: CONSTANTS.color.white,
    fontFamily: 'Poppins',
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "capitalize" as const,
  },
  tryLamsa: {
    marginTop: "1rem",
    color: CONSTANTS.color.thickBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "26px",
    opacity: 0.7,
  },
  heroImage: {
    maxWidth: "656px",
    width: "100%",
    height: "auto"
  },
  boxContainer2: {
    backgroundColor: CONSTANTS.color.paleLavendar,
    marginTop: "6rem",
    padding: "8rem 0rem 0rem 0rem",
  },
  boxContainer3: {
    marginTop: "6rem",
    padding: "0px 2rem"
  },
  boxContainer4: {
    backgroundColor: CONSTANTS.color.paleLavendar,
    marginTop: "1rem",
    padding: "6rem 2rem"
  },
  flexColumnStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  boxContainer5: {
    padding: "6rem 2rem"
  },
  positionRelativeStyle: {
    position: "relative" as const
  },
  swiperContainer: {
    display: "flex",
    alignItems: "center",
    gap: "3rem"
  },
  bg1: {
    position: "absolute" as const,
    width: "27px",
    transform: "translate(-26rem, -40px)",
    height: "25px",
  },
  transformStyle: {
    transform: "translate(-18rem, -5px)"
  },
  bg2: {
    position: "absolute" as const,
    width: "17px",
    transform: "translate(-37rem, -2px)",
    height: "17px",
  },
  transformBg2Style: {
    transform: "translate(-38rem, 175px)"
  },
  bg2nd: {
    width: "17px",
    height: "17px",
    position: "absolute" as const,
    transform: "translate(35rem, 15rem)",
  },
  transformBg2ndStyle: {
    transform: "translate(18rem, 39rem)"
  },
  bg3: {
    width: "33px",
    position: "absolute" as const,
    height: "34px",
    transform: "translate(-37rem, 20rem)"
  },
  transformBg3Style: {
    transform: "translate(-24rem, 42rem)"
  },
  bg4: {
    height: "34px",
    width: "33px",
    position: "absolute" as const,
    transform: "translate(35rem, -8px)"
  },
  transformBgStyle: {
    transform: "translate(33rem, -43px)"
  },
  keepYourText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "80px",
    textAlign: "center" as const,
  },
  withLamsaText: {
    color: "#414042",
    opacity: 0.7,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    textAlign: "center" as const,
    maxWidth: "591px",
    width: "100%",
    marginTop: "1rem"
  },
  buttonTextBookDemo: {
    color: CONSTANTS.color.white,
    fontFamily: 'Poppins',
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "none" as const,
  },
  dashboardImage: {
    marginTop: "3rem",
    maxWidth: "1112px",
    width: "100%",
    height: "auto"
  },
  playIcon: {
    height: "27px",
    width: "21px",
    position: "absolute" as const,
    transform: "translate(-44px, 25px)",
  },
  playIconAr: {
    transform: "translate(52px, 25px)"
  },
  playButton: {
    position: "relative" as const,
    transform: "translate(5px, -130px)"
  },
  playRootBg: {
    opacity: 0.3,
    height: "75px",
    width: "75px"
  },
  playBg: {
    height: "55px",
    width: "55px",
    position: "absolute" as const,
    transform: "translate(-64px, 12px)",
  },
  playBgAr: {
    transform: "translate(65px, 12px)"
  },
  hearText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "60px",
    textAlign: "center" as const,
    maxWidth: "714px",
    width: "100%"
  },
  customerSection: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  personImage: {
    maxWidth: "298px",
    height: "auto"
  },
  personImageRelative: {
    width: "100%",
    minWidth: "288px",
    height: "auto",
    position: "absolute" as const,
    top: 0,
    left: 0,
    transform: "translate(3px, 12px)",
  },
  rightStyle: {
    right: "15px"
  },
  quotation: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    textAlign: "justify" as const,
    marginLeft: "1rem",
    padding: "0 1.5rem"
  },
  quotationArabicStyle: {
    padding: "0rem 1.5rem"
  },
  quoteIcon: {
    width: "100%",
    maxWidth: "32px",
    height: "30px",
  },
  quoteIconArabic: {
    float: "right" as const,
    marginTop: "5px"
  },
  quoteIconRightStyle: {
    float: "left" as const,
    marginTop: "5px"
  },
  quoteIconArabicStyle: {
    transform: "rotate(180deg)",
  },
  nameText: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  nameSubText: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontStyle: "italic",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  quoteNameShow: {
    display: "flex",
    flexDirection: "column" as const,
  },
  quoteNameShow2: {
    display: "flex",
    flexDirection: "column" as const,
    position: "relative" as const,
    left: "78%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  quoteNameShowAr: {
    left: "50px",
    alignItems: "flex-end",
    transform: "translate(50%, -50%)"
  },
  quoteNameArStyle: {
    left: "50px",
    alignItems: "flex-end"
  },
  nameTextShow: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  nameSubTextShow: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontStyle: "italic",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "end" as const
  },
  whyLamsa: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    textAlign: "center" as const,
  },
  whetherText: {
    marginTop: "1rem",
    opacity: 0.7,
    maxWidth: "605px",
    width: "100%",
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    textAlign: "center" as const,
  },
  whyLamsaSection: {
    marginTop: "4rem",
  },
  withLamsa: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "30px",
    marginBottom: "3rem"
  },
  withLamsaList: {
    listStyleType: "disc",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginTop: "0.5rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  withLamsaListAr: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  icons: {
    width: "20px",
    height: "17px",
  },
  buttonTextlearnMore: {
    color: CONSTANTS.color.white,
    fontFamily: 'Poppins',
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "none" as const,
  },
  person1: {
    width: "100%",
    height: "auto",
  },
  person2: {
    width: "100%",
    height: "auto",
    marginBottom: "4rem"
  },
  person3: {
    width: "100%",
    height: "auto",
  },
  columnImages: {
    display: "flex",
    flexDirection: "column" as const,
    marginLeft: "2rem"
  },
  startLamsa: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    textAlign: "center" as const,
  },
  noCardText: {
    marginTop: "2rem",
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    textAlign: "center" as const,
  },
  errorMessage: {
    marginTop: "10px",
    color: CONSTANTS.color.red,
  },
  globeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "5px",
    marginTop: "-2rem",
    marginBottom: "2rem",
  },
  globeIcon: {
    width: "25px",
    height: "25px",
  },
  languageText: {
    color: CONSTANTS.color.borderGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  dropDownContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer"
  },
  selectedLanguage: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  contactUsDropDown: {
    width: "16px",
    height: "8px",
    marginLeft: "5px"
  },
  languageModalContainer: {
    position: "relative" as const
  },
  languageModal: {
    width: "100%",
    maxWidth: "354px",
    height: "125px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    position: "absolute" as const,
    left: "72%",
    top: "-28px",
  },
  arabicRightStyle: {
    right: "72%"
  },
  languageContainer: {
    padding: "1rem 3rem 1rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer"
  },
  languageContainerColor: {
    backgroundColor: CONSTANTS.color.lightTrasparentBlue,
    padding: "1rem 1rem 1rem"
  },
  selectedIcon: {
    height: "26px",
    width: "26px",
  },
  language: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  colorStyle: {
    color: CONSTANTS.color.darkBlue,
  },
}

const HomeStyles = withStyles(styles)(Home);
export default withTranslation()(HomeStyles);
// Customizable Area End