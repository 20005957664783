import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export interface PdfSiteDetails {
  id: number;
  site_name: string;
  email: string;
  phone_number?: any;
  site_address: string;
  state: string;
  city: string;
  zip_code: string;
  customer_id: number;
  longitude: string;
  latitude: string;
  created_at: string;
  updated_at: string;
  country?: any;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  openPdf: boolean
  closePdf: any
  classes: any
  type: string
  onClose: any
  jobId: any
  quoteId: any
  requestId: string;
  invoicePdfId?:string
  currency:string
  t?: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  requestPdfDetails: any
  pdfLoading: boolean;
  pdfDownLoadStatus:boolean;
  pdfSiteDetails:PdfSiteDetails
  selectLanguage:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PdfController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wrapperRef: any;
  getRequestPdfDetailsApiCallId: string = ""
  downloadPdfApiCallId:string="";
  apiCallIdPdfSiteDetails:string="'"
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      requestPdfDetails: {},
      pdfLoading: false,
      pdfDownLoadStatus:false,
      selectLanguage:"",
      pdfSiteDetails:{
        id: 0,
        site_name: "",
        email: "",
        site_address: "",
        state: "",
        city: "",
        zip_code: "",
        customer_id: 0,
        longitude: "",
        latitude: "",
        created_at: "",
        updated_at: ""
      }
    };
    this.wrapperRef = React.createRef<any>();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectLanguage: selectedLanguage })
    this.setState({ pdfLoading: true })
    if (this.props.type === "Request") {
      this.getRequestPurchasesPdf()
    } else if (this.props.type === "Job") {
      this.getJobPurchasesPdf()
    } else if (this.props.type === "Quote") {
      this.getQuotePurchasesPdf()
    }else if (this.props.type === "Invoice") {
      this.getInvoicePurchasesPdf()
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getRequestPdfDetailsApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.setState({
          requestPdfDetails: responseJson?.data?.attributes,
          pdfLoading: false
        },()=>{
          this.pdfSiteDetails()
        })
      } else {
        this.setState({
          pdfLoading: true
        })
      }
    }
    if(apiRequestCallId===this.apiCallIdPdfSiteDetails){
      if (responseJson && !responseJson.errors) {
        this.setState({pdfSiteDetails: responseJson.data})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getRequestPurchasesPdf = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem(configJSON.storageToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRequestPdfDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestPdfDetailsEndPoint}${this.props.requestId}&lang=${this.state.selectLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobPurchasesPdf = () => {
    const Job_id = this.props.jobId

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem(configJSON.storageToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRequestPdfDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobPdfDetailsEndPoint}${Job_id}&lang=${this.state.selectLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getQuotePurchasesPdf = () => {
    const quoteId = this.props.quoteId

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem(configJSON.storageToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRequestPdfDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuotePdfDetailsEndPoint}${quoteId}&lang=${this.state.selectLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInvoicePurchasesPdf = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem(configJSON.storageToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRequestPdfDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoicePdfendPoint}${this.props.invoicePdfId}&lang=${this.state.selectLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  pdfSiteDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem(configJSON.storageToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdPdfSiteDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apiCallIDSiteDetails}/${this.state.requestPdfDetails?.site_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End