Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions";
exports.labelBodyText = "TermsAndConditions Body";

exports.btnExampleTitle = "CLICK ME";

exports.termsConditionAPIContentType = "application/json";
exports.termsConditionAPIEndPoint = "terms_and_condition";
exports.termsConditionAPIMethod = "GET";

// Customizable Area End