import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from 'i18next';
import React from 'react';
import { setStorageData } from "../../../framework/src/Utilities";
//@ts-ignore
import CryptoJS from "crypto-js";
declare const window: { document: { querySelector: (arg0: string) => { (): any; new(): any; setAttribute: { (arg0: string, arg1: string): void; new(): any; }; }; }; };
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setPermissions: (permissions: any) => {};
  openToastHandler: (message: string, error: string) => {};
  t:(key: string) => string;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean;
  loginFormData: {
    employeeEmail: any;
    password: any;
    language: any;
  };
  isErrorFormData: any;
  loginLoader: boolean;
  apiErr: any;
  toasterText: string;
  toaster: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  employeeLoginApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loginFormData: {
        employeeEmail: "",
        password: "",
        language: "",
      },
      loginLoader: false,
      showPassword: false,
      isErrorFormData: {},
      apiErr: {
        error: false,
        message: "",
        emailErr: false,
        emailMsg: "",
      },
      toasterText: "",
      toaster: false,
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.employeeLoginApiId) {
          if (!responseJson.errors) {
            this.handleEmployeeLoginCallback(responseJson);
          } else {
            //Check Error Response
            this.setState({
              loginLoader: false,
            });
            this.parseApiErrorResponse(responseJson);
          }
          this.setState({
            loginLoader: false,
          });
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEmployeeLoginCallback = async (responseJson: any) => {
    if (responseJson.message === "Password entered is incorrect") {
      this.setState({
        apiErr: {
          error: true,
          message: this.props.t('employeeLogin.passwordInvalid'),
          emailMsg: "",
          emailErr: "",
        },
        loginLoader: false,
      });
    } else if (responseJson.message === "Email ID entered is incorrect") {
      this.setState({
        apiErr: {
          error: false,
          message: "",
          emailMsg:this.props.t('employeeLogin.emailInvalid'),
          emailErr: true,
        },
        loginLoader: false,
      });
    } else if (responseJson.status === 500) {
      this.setState({
        loginLoader: false,
      });
      this.props.openToastHandler("Something went wrong.", "error");
    } else {
      this.setState({
        toaster: true,
        toasterText: this.props.t('employeeLogin.success'),
        loginLoader: false,
      });
      this.saveLoggedInUserData(responseJson);
      localStorage.setItem("employeeId", responseJson.data.id);
      localStorage.setItem("isEmployee", JSON.stringify(true));
      localStorage.removeItem("profileDataFetched");
      await this.formatAndSetPermissions(
        responseJson.data.attributes.staff_permissions.data
      );
      setTimeout(this.setTimeoutCallback, 500);
    }
  };

  formatAndSetPermissions = async (permissions: any) => {
    interface Permission {
      id: string;
      type: string;
      attributes: {
        category: string;
        action: string;
        active: boolean;
        employee_id: any;
      };
    }
    let simplePermissionsArray: Permission[] = permissions;

    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(simplePermissionsArray),
      configJSON.secretKey
    ).toString();
    await setStorageData("permissions", ciphertext);
    this.props.setPermissions(simplePermissionsArray);
  };

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      this.send(msg);
    }
  }
  setTimeoutCallback = () => {
    this.props.navigation.navigate("EmployeeDashboard");
  };
  employeeLogin = () => {
    const header = {
      "Content-Type": configJSON.employeeApis.contentType,
    };
    const { employeeEmail, password, language } = this.state.loginFormData;
    const httpBody = {
      email: employeeEmail,
      password,
      language,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employeeLoginApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.employeeApis.loginApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.employeeApis.loginApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  validateEmployeeLoginFormData = (values: any) => {
    const errors: any = {};
    const { employeeEmail, password, language } = values;
    if (!employeeEmail) {
      errors.employeeEmail = this.props.t('BoAuthErrors.login.empty');
    } else if (employeeEmail && !configJSON.regex.email.test(employeeEmail)) {
      errors.employeeEmail = this.props.t('BoAuthErrors.login.validEmail')
    }
    if (!password) {
      errors.password = this.props.t('BoAuthErrors.password.empty');
    }
    return errors;
  };

  handleEmployeeLoginSaveCall = () => {
    if (Object.keys(this.state.isErrorFormData).length === 0) {
      this.setState({ loginLoader: true });

      this.employeeLogin();
    } else {
      this.setState({
        apiErr: {
          error: false,
          emailErr: false,
          message: "",
          emailMsg: "",
        },
      });
    }
  };

  handleEmployeeLoginInformation = (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    this.setState(
      {
        isErrorFormData: this.validateEmployeeLoginFormData(
          this.state.loginFormData
        ),
      },
      this.handleEmployeeLoginSaveCall
    );
    localStorage.setItem("lang" , 'en') 
  };

  handleEmployeeLogin = (e: any) => {
    if (e.currentTarget.name === "textPassword") {
      this.setState({
        loginFormData: {
          ...this.state.loginFormData,
          password: e.currentTarget.value,
        },
      });
    } else if (e.currentTarget.name === "email") {
      this.setState({
        loginFormData: {
          ...this.state.loginFormData,
          employeeEmail: e.currentTarget.value,
        },
      });
    }
  };


  forgotPassword = () => {
    localStorage.setItem("role", "EMP");
    this.props.navigation.navigate("PasswordForgot");
  };

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleCloseToaster = () => {
    this.setState({ toaster: false });
  };
}
// Customizable Area End
