import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { CONSTANTS } from "./theme";

interface Props {
    loading: boolean;
}

const LoadingBackdrop = (props: Props) => {
    return (
        
        <>
            {props?.loading ? (
                <Backdrop style={webStyle.backDropStyle} open={props?.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : null}
        </>
    );
};

const webStyle = {
    backDropStyle: {
        zIndex: 1000 + 1,
        color: CONSTANTS.color.thickBlue,
        justifyContent: 'center',
        alignItems: 'center',
    }
}

export default LoadingBackdrop;
