//@ts-nocheck
import React from 'react';
// Customizable Area Start
import { Button, Dialog,Grid, withStyles,Typography } from '@material-ui/core';
export const configJSON = require("./config");
import { withSnackbar } from "../../../components/src/toastweb";
import { deleteModalImg } from "../../appointmentmanagement/src/assets"
import { CONSTANTS } from '../../../components/src/theme';
export const DeleteModelStyles:any = (theme: any) => ({
    deleteBtn: {
        width: "135px",
        height: "50px",
        borderRadius: "10px",
        border: "1px solid #1c18af",
        color: CONSTANTS.color.white,
        textTransform: "capitalize",
        background: CONSTANTS.color.darkBlue,
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
            backgroundColor: CONSTANTS.color.blueViolet,
            color: theme.palette.common.white,
            cursor: "pointer",
            border:"none"
        },
        [theme.breakpoints.down("xs")]: {
            width: "120px",
        }
    },
    bgImg: {
        marginTop: '-6px',
        width: '100%',
    },
    text_lightBrown:{
        color:CONSTANTS.color.lightBrown
    },
    text_lightGrey:{
        color:CONSTANTS.color.lightGrey
    },
    marginTop_20:{
        marginTop:"20px"
    },
    marginBottom_1: {
        marginBottom: "0.5rem"
    },
    cancelBtn: {
        width: "135px",
        height: "50px",
        borderRadius: "10px",
        textTransform: "capitalize",
        border: "1px solid #ff5454",
        color: CONSTANTS.color.crimsonRed,
        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
        '&:hover': {
            backgroundColor: '#ff5454',
            color: theme.palette.common.white,
            cursor: "pointer"
        },
        [theme.breakpoints.down("xs")]: {
            width: "120px",
        }
    }
});
interface Props {
    navigation?: any;
    id?: string;
    history?: any
    handleOpenDeleteAppointMentModal:Function
    handleCloseDeleteAppointMentModal:Function
    selectedEventData:any
  }
// Customizable Area End
export class DeleteModel extends React.Component {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        const { classes } = this.props
        return (
            // Customizable Area Start
            <Dialog
                open={this.props.handleOpenDeleteAppointMentModal}
                onClose={this.props.handleCloseDeleteAppointMentModal}
                PaperProps={{
                    style: {
                        width: "600px",
                        borderRadius: '20px',
                        padding:'20px'
                    },
                }}>
                <Typography variant='h6' className={`${classes.marginBottom_1} ${classes.text_lightBrown}`}>
                   {this.props.openDeleteTaskModal && `${this.props.t('Calendar.deleteAppointment')}`}
                   {this.props.isDeleteAppointment && `${this.props.t('Calendar.deleteTask')}`}
                   {this.props.isDeleteJob && `${this.props.t('Calendar.deleteJob')}`}
                </Typography>
                <Grid container>
                    <Grid item xs={7} sm={8}>
                        <Typography variant='body1' className={classes.text_lightGrey}>
                            {this.props.openDeleteTaskModal && `${this.props.t('Calendar.deleteDescription.appointment')}`}
                            {this.props.isDeleteAppointment && `${this.props.t('Calendar.deleteDescription.task')}`}
                            {this.props.isDeleteJob && `${this.props.t('Calendar.deleteDescription.job')}`}
                        </Typography>
                        </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={4} sm={3} style={{alignSelf:'center'}}>
                    <img src={deleteModalImg} className={classes.bgImg}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" className={classes.marginTop_20}>
                    <Button variant="outlined"
                        onClick={this.props.handleCloseDeleteAppointMentModal}
                        className={classes.cancelBtn}
                    >
                        <Typography variant='body1'>{this.props.t('Calendar.button.cancel')}</Typography>
                    </Button>
                    <Button variant="outlined"
                        className={classes.deleteBtn}
                        onClick={this.props.handleDelete}
                    >
                        <Typography variant='body1'>{this.props.t('Calendar.button.delete')}</Typography>
                    </Button>
                </Grid>

            </Dialog>
            // Customizable Area End
        );
    }
}
const CalendarSnackbar = withSnackbar(DeleteModel)
export default withStyles(DeleteModelStyles)(CalendarSnackbar);
