Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuoteManagement";
exports.labelBodyText = "QuoteManagement Body";
exports.btnExampleTitle = "CLICK ME";


exports.updateApiMethod = "PATCH"
exports.editApiMethod = "PUT"
exports.deleteApiMethod = "DELETE"
exports.storageToken = "authToken"


exports.quotesEndPoint = "bx_block_quotemanagement/quotes"
exports.quoteProductsAndServices = "bx_block_quotemanagement/product_and_services"
exports.getCurrency='bx_block_languagesupport/languagesupports/currency_symbol'
exports.getRequest = "bx_block_request_management/requests"
exports.siteEndPoint = "bx_block_location/site_informations"
exports.JobsEndPoint = "bx_block_request_management/jobs"
exports.getTaxRates = "bx_block_settings/tax_rates"


// Buttons
exports.buttons = {
  create: "Create Quote",
  select: "Select Customer",
  cancel: "Cancel",
  save: "Save Quote",
  saveMore: "Save And...",
}
exports.noDataText = {
  emptyData: "No Data Found"
}

exports.quoteToasterText = {
  create: "Quote created",
  updated: "Quote updated",
  deleted: "Quote celeted",
  permenentDeleted: "Cancelled quote deleted permanently"
}

exports.quotes = {
  title: "Quote Title",
  location: "Location",
  serviceText: "Service Details",
  quoteText: "Quote Details",

}

exports.quoteSubTitles = {
  serviceText: "Please provide relative Service Information",
  dateText: "Quote Date",
  dueText: "Quote Expiration Date",
}
exports.quoteSaveMoreBtn = {
  email: "Send As Email",
  pdf: "Download As PDF",
  jobconvert: "Convert To Job",

}

exports.error = {
  quoteTitle: { errorText: 'Title cannot be empty' },
  siteDetails: { errorText: 'Please select site' },
  product_id: { errorText: 'Please select the Product/Service' },
}
exports.products_and_servicesEndPoint = "bx_block_request_management/product_and_services"
exports.quoteEndPoint = "bx_block_quotemanagement/quotes"
exports.getQuoteEndPoint = "bx_block_quotemanagement/quotes"
exports.customersEndPoint = "account_block/customers"
exports.updateQuoteEndPoint = "bx_block_quotemanagement/quotes/"

exports.errorsJobData = {
  noData: "No requests found.",
  tokenExpired: "Token has Expired",
  invalidToken: "Invalid token"
}

// Customizable Area End