import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Container,
  Tabs,
  Tab,
  Button,
  ClickAwayListener
} from "@material-ui/core";
import {
  lamsaLogo,
  navbarStyle,
  navbarImage,
  userIcon,
  contactUsDropDown,
  selectedIcon,
  globeIcon
} from "./assets";
import {
  withStyles,createStyles,Theme
} from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import "./NavBar.css";
import { CONSTANTS } from "../../../components/src/theme";
// Customizable Area End

import NavBarFooterController, {
  Props,
} from "./NavBarFooterController.web";

// Customizable Area Start
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    textTransform: 'none',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: CONSTANTS.color.thickBlue,
      borderRadius: "10px"
    },
  },
  root: {
    "& .Mui-selected": {
      fontWeight: 'bolder',
      '& > span': {
        color: CONSTANTS.color.royalBlue,
      },
    },
    "& .Component-indicator-33": {
      height: "3.5px"
    },
    "& .Component-indicator-4": {
      height: "3.5px"
    },
    "& .MuiTabs-indicator": {
      height: "3.5px"
    }
  }
})((props: any) => <Tabs {...props} disableripple="true" TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles({
  root: {
    fontSize: "1.2rem",
    "& .MuiTab-wrapper": {
      textTransform: "capitalize" as "capitalize",
      color: CONSTANTS.color.coolDarkGrey,
      paddingBottom: "2rem",
      paddingTop: "2rem",
    }
  }
})(Tab);

interface LanguageArray {
  id: number,
  name: string,
  value: string
}

const languageData = (
  mockLanguages: LanguageArray[],
  languageSelectModal: boolean,
  language: string,
  handleSelectlanguage: Function,
) => {
  return (
    <Box style={language !== "English" ? webStyle.relativeStyleAr : webStyle.relativeStyle}>
      {languageSelectModal ? (
        <Box style={webStyle.languageModalStyle}>
          {mockLanguages.map((lng, index) => (
            <Box
              data-test-id={"handleSelectlanguage"+index}
              key={lng.id}
              style={lng.name === language ? {...webStyle.languageSelectContainer, ...webStyle.backgroundStyle} : webStyle.languageSelectContainer}
              onClick={() => handleSelectlanguage(lng)}
            >
              {lng.name === language ?
                <img
                  style={webStyle.selectedIcon}
                  src={selectedIcon}
                  alt=""
                /> : null}
              <Typography style={lng.name === language ? {...webStyle.language, ...webStyle.colorStyle} : webStyle.language} component={"div"}>
                {lng.name}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
}
// Customizable Area End

export default class NavBar extends NavBarFooterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    window.addEventListener('resize', this.showButton);
    window.addEventListener('resize', this.navBarResize);
    const languageSelected = localStorage.getItem("lang");
    const { t } = this.props;
    
    const mockLanguages = [
      { id: 1, name: t("landingPage.Home.english"), value: "en" },
      { id: 2, name: t("landingPage.Home.arabic"), value: "ar" },
    ]
    return (
      <Box className="navbar" style={this.state.navbar ? webStyle.backgroundEffectStyle : {}}>
        <Container maxWidth={"lg"}>
          <Box className="navbar-container">
            <span className="navbar-logo">
              <img className="navbar-logo" style={this.state.languageSelected === "ar" ? webStyle.lamsaLogo : {}} src={lamsaLogo} alt="Lamsa" />
            </span>
            <Box
              data-test-id="handleMenuClick"
              className="menu-icon"
              style={this.state.navbar ? webStyle.navbarMenu : {}}
              onClick={this.handleMenuClick}
            >
              {this.state.navbar ? (
                <CloseIcon fontSize="large" className="fas fa-times" />
              ) : (
                <MenuIcon fontSize="large" className="fas fa-bars" />
              )}
            </Box>
            <ul className="nav-menu">
              {!this.state.navbar && (
                <Box style={webStyle.tabsContainer}>
                  <StyledTabs
                    data-test-id="handleTabValueChange"
                    value={this.props.active}
                    onChange={this.handleTabValueChange}
                  >
                    <StyledTab className="navItems" label={t("landingPage.NavBar.home")} />
                    <StyledTab className="navItems" label={t("landingPage.NavBar.whyLamsa")} />
                    <StyledTab className="navItems" label={t("landingPage.NavBar.pricing")} />
                    <StyledTab className="navItems" label={t("landingPage.NavBar.contactUs")} />
                  </StyledTabs>
                </Box>
              )}
            </ul>
            {this.state.navbar && (
              <ClickAwayListener data-test-id="handleClickAway" onClickAway={this.handleClickAway}>
                <Box style={webStyle.navbarModal}>
                  <Box style={webStyle.navbarUserDetails}>
                    <Box style={webStyle.navbarProfileContainer}>
                      <img
                        style={webStyle.userIcon}
                        src={userIcon}
                        alt=""
                      />
                      <Box>
                        <Typography
                          style={{...webStyle.userNameSettings, ...webStyle.fontStyle}}
                          variant="body1"
                        >
                          {t('landingPage.NavBar.hello')}
                        </Typography>
                        <Typography
                          data-test-id="handleNavigateLogin"
                          style={webStyle.userNameSettings}
                          variant="caption"
                          onClick={this.handleNavigateLogin}
                        >
                          {t('landingPage.NavBar.login')}/
                        </Typography>
                        <Typography
                          data-test-id="handleNavigateSignUp"
                          style={webStyle.userNameSettings}
                          variant="caption"
                          onClick={this.handleNavigateSignUp}
                        >
                          {t('landingPage.NavBar.signUp')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={webStyle.menuContainer}>
                      <Typography
                        data-test-id="handleNavigateHome"
                        style={webStyle.settingsList}
                        variant="body1"
                        onClick={this.handleNavigateHome}
                      >
                        {t('landingPage.NavBar.home')}
                      </Typography>
                      <span style={webStyle.horizontalHr}></span>
                      <Typography
                        data-test-id="handleNavigateWhyLamsa"
                        style={webStyle.settingsList}
                        variant="body1"
                        onClick={this.handleNavigateWhyLamsa}
                      >
                        {t('landingPage.NavBar.whyLamsa')}
                      </Typography>
                      <span style={webStyle.horizontalHr}></span>
                      <Typography
                        data-test-id="handleNavigatePricing"
                        style={webStyle.settingsList}
                        variant="body1"
                        onClick={this.handleNavigatePricing}
                      >
                        {t('landingPage.NavBar.pricing')}
                      </Typography>
                      <span style={webStyle.horizontalHr}></span>
                      <Typography
                        data-test-id="handleNavigateContactUs"
                        style={webStyle.settingsList}
                        variant="body1"
                        onClick={this.handleNavigateContactUs}
                      >
                        {t('landingPage.NavBar.contactUs')}
                      </Typography>
                      <span style={webStyle.horizontalHr}></span>
                      <Box style={webStyle.languageContainer}>
                        <Typography style={{...webStyle.settingsList, ...webStyle.lineHeightStyle}} variant="body1">
                          {t('landingPage.NavBar.language')}:
                        </Typography>
                        <Box
                          data-test-id="handleClickOpenlanguageDialog"
                          style={webStyle.dropDownContainer}
                          onClick={this.handleClickOpenlanguageDialog}
                        >
                          <Typography style={webStyle.selectedLanguage} variant="body1">
                            {this.state.language}
                          </Typography>
                          <img
                            style={webStyle.contactUsDropDown}
                            src={contactUsDropDown}
                            alt=""
                          />
                        </Box>
                      </Box>
                      {languageData(
                        mockLanguages,
                        this.state.languageSelectModal,
                        this.state.language,
                        this.handleSelectlanguage
                      )}
                      <span style={{...webStyle.horizontalHr, ...webStyle.hrMargin}}></span>
                      <Typography style={webStyle.settingsList} variant="body1">
                        {t('landingPage.NavBar.logOut')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ClickAwayListener>
            )}
            
            {this.state.buttons && (
              <Box style={webStyle.buttons} className="buttons">
                <Typography
                  data-test-id="handleNavigateLogin"
                  style={languageSelected === "ar" ? {...webStyle.loginText, ...webStyle.marginStyle} : webStyle.loginText}
                  variant="body2"
                  onClick={this.handleNavigateLogin}
                >
                  {t("landingPage.NavBar.login")}
                </Typography>
                <Grid item xs={12} sm={12} md={12}>
                  <SignUpButton
                    data-test-id="handleNavigateSignUp"
                    onClick={this.handleNavigateSignUp}
                  >
                    <Typography
                      style={webStyle.buttonSignUp}
                      variant="caption"
                    >
                      {t("landingPage.NavBar.signUp")}
                    </Typography>
                  </SignUpButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Box style={webStyle.languageContainer}>
                <img
                  style={webStyle.globeIcon}
                  src={globeIcon}
                  alt=""
                />
                  <Typography style={{ ...webStyle.settingsList, ...webStyle.lineHeightStyle }} variant="body1">
                    {t('landingPage.NavBar.language')}:
                  </Typography>
                  <Box
                    data-test-id="handleClickOpenlanguageDialog"
                    style={webStyle.dropDownContainer}
                    onClick={this.handleClickOpenlanguageDialog}
                  >
                    <Typography style={webStyle.selectedLanguage} variant="body1">
                      {this.state.language}
                    </Typography>
                    <img
                      style={webStyle.contactUsDropDown}
                      src={contactUsDropDown}
                      alt=""
                    />
                  </Box>
                </Box>
                </Grid>
                {languageData(
                  mockLanguages,
                  this.state.languageSelectModal,
                  this.state.language,
                  this.handleSelectlanguage
                )}
              </Box>
            )}
          </Box>
        </Container>
        <img style={languageSelected === "ar" ? webStyle.navbarStyle : webStyle.navbarImage} src={languageSelected === "ar" ? navbarImage : navbarStyle} alt="" />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SignUpButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: CONSTANTS.color.thickBlue,
      borderRadius: "10px",
      minHeight: "56px",
      width: "166px",
      maxWidth: "100%",
      marginLeft: "5px",
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        cursor: "pointer",
      },
    }
  }))(Button)
const webStyle = {
  backgroundEffectStyle: {
    height: "100%",
    backgroundColor: "rgba(142, 138, 138, 0.5)",
    backdropFilter: "blur(2px)",
  },
  lamsaLogo: {
    transform: "translate(5%, 50%)"
  },
  navbarMenu: {
    top: "36px"
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginText: {
    color: CONSTANTS.color.deepRedHue,
    textDecoration: "underline",
    marginRight: "2rem",
    cursor: "pointer"
  },
  marginStyle: {
    marginLeft: "2rem"
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonSignUp: {
    color: CONSTANTS.color.white,
    fontFamily: 'Poppins',
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "capitalize" as const,
  },
  navbarImage: {
    height: "158px",
    width: "165px",
    position: "absolute" as "absolute",
    right: 0,
    top: 0,
    transform: "translate(60px, -58px)",
  },
  navbarStyle: {
    height: "116px",
    width: "116px",
    position: "absolute" as "absolute",
    left: "0px",
    top: "0px",
  },
  navbarModal: {
    width: "100%",
    maxWidth: "265px",
    minHeight: "385px",
    borderRadius: "7px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(0,0,0,0.05)",
    float: "right" as const,
    marginRight: "2.5rem",
    marginTop: "-0.8rem",
    position: "fixed" as const,
    top: "85px",
    right: "-30px",
    zIndex: 1000,
  },
  navbarUserDetails: {
    width: "265px",
    height: "101px",
    borderRadius: "7px 7px 0 0",
    backgroundColor: "rgb(245, 245, 252)",
  },
  userIcon: {
    width: "45px",
    height: "45px",
  },
  navbarProfileContainer: {
    display: "flex",
    alignItems: "center",
    padding: "1.5rem",
    gap: "10px"
  },
  userNameSettings: {
    color: CONSTANTS.color.lightBrown,
    fontSize: "15px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "unset"
  },
  fontStyle: {
    fontSize:"1.2rem"
  },
  menuContainer: {
    padding: "1.5rem"
  },
  settingsList: {
    color: CONSTANTS.color.grey,
    fontSize: "1.2rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    cursor: "pointer",
  },
  lineHeightStyle: {
    lineHeight: "-1px"
  },
  horizontalHr: {
    display: "flex",
    margin: "10px 0px 15px",
    height: "1px",
    border: "1px solid rgba(0,0,0,0.1)",
  },
  hrMargin: {
    margin: "15px 0px 15px"
  },
  languageContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding:"0 10px 0 10px",
  },
  dropDownContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer"
  },
  globeIcon: {
    width: "25px",
    height: "25px",
  },
  selectedLanguage: {
    fontSize: "1.2rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  contactUsDropDown: {
    width: "16px",
    height: "8px",
    marginLeft: "5px"
  },
  relativeStyle: {
    position: "relative" as const,
     left:"-30%"
  },
  relativeStyleAr : {
    position: "relative" as const,
    left:"30%"
  },
  languageModalStyle: {
    width: "100%",
    maxWidth: "254px",
    height: "125px",
    backgroundColor: CONSTANTS.color.white,
    borderRadius: "5px",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    position: "fixed" as const,
    marginTop:"27px"
  },
  languageSelectContainer: {
    padding: "1rem 3rem 1rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer"
  },
  backgroundStyle: {
    backgroundColor: CONSTANTS.color.lightTrasparentBlue,
    padding: "1rem 1rem 1rem"
  },
  selectedIcon: {
    height: "26px",
    width: "26px",
  },
  language: {
    color: CONSTANTS.color.midGrey,
    fontSize: "18px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  colorStyle: {
    color: CONSTANTS.color.darkBlue
  }
}

// Customizable Area End