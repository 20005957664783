import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { checkLoggedInUser, performSupplierAuthorizationCheck } from "./utility.web";

// Customizable Area Start
import { apiCall } from "../../../components/src/CommonFunction";
interface CurrencyData {
    "id": number,
    "country": string,
    "currency": string|undefined,
    "timezone": string,
    "created_at": string,
    "updated_at": string,
    "active": boolean
  }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  oldProps: any;
  text: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  active: any;
  formData: {
    supplierName: string;
    contactName: string;
    supplierOf: string;
    mobile: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    currency: string;
    companyInformation:string
  }
  isErrorFormData: any;
  isFormSubmitted: boolean;
  deleteDialogOpen: boolean;
  suppliersData: any;
  createSuppliersLoading: boolean;
  getSingleSupplierLoading: boolean;
  editSuppliersLoading: boolean;
  archiveSuppliersLoading: boolean;
  deleteSuppliersLoading: boolean;
  getCurrency:any
  formDataEdit: {
    id: string;
    supplierName: string;
    contactName: string;
    supplierOf: string;
    mobile: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    currency: string;
    companyInformation:string  
  };
  countryCode: any;
  countryCodeEdit: any;
  singleSupplierData: any;
  languageSelected: string;
  status: string;
  openUnarchiveDialog:boolean
  getCurrencyData: CurrencyData[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateSupplierController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createSuppliersApiCallId: string = "";
  getSingleSupplierApiCallId: string = "";
  editSuppliersApiCallId: string = "";
  archiveSuppliersApiCallId: string = "";
  deleteSuppliersApiCallId: string = "";
  currencyApiCallId: string = "";
  unArchiveSupplierApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      active: 0,
      getCurrency:'',
      formData: {
        supplierName: "",
        contactName: "",
        supplierOf: "",
        mobile: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        currency: "",
        companyInformation:""
      },
      isErrorFormData: {},
      isFormSubmitted: false,
      deleteDialogOpen: false,
      suppliersData: [],
      createSuppliersLoading: false,
      getSingleSupplierLoading: false,
      editSuppliersLoading: false,
      archiveSuppliersLoading: false,
      deleteSuppliersLoading: false,
      formDataEdit: {
        id: "",
        supplierName: "",
        contactName: "",
        supplierOf: "",
        mobile: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        currency: "",
        companyInformation:""      
      },
      countryCode: "",
      countryCodeEdit: "",
      singleSupplierData: {},
      languageSelected: "",
      status: "",
      openUnarchiveDialog:false,
      getCurrencyData:[]
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const supplier_id = localStorage.getItem("supplier_id");
    if(supplier_id) {
      this.getSingleSupplier();
    }
    this.getCurrency()
    if(!await getStorageData(configJSON.authToken)) {
      this.handleNavigate(configJSON.Login);
    }

    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });
    
   
    this.setState({ getSingleSupplierLoading: true });
    const pathName = this.props.navigation.history?.location?.pathname;
    if (pathName == configJSON.PurchaseOrders) {
      this.setState({ active: 1 });
      this.purchaseId();
      this.noSupplierId();
    } else if(pathName == configJSON.CreatePurchase) {
      this.setState({ active: 2 });
      this.purchaseId();
      this.noSupplierId();
    } else if(pathName == configJSON.SupplierData){
      this.setState({ active: 3 });
      this.noSupplierId();
    } else if(pathName == configJSON.EditSupplier) {
      this.setState({ active: 4 });
      this.noSupplierId();
    } else if(pathName == configJSON.PurchaseData) {
      this.setState({ active: 5 });
      this.noPurchaseId();
      this.noSupplierId();
    } else {
      this.setState({ active: 0 });
      this.supplierId();
    }

    const createSupplierToaster = await getStorageData(configJSON.createSupplierToaster);
    const updateSupplierToaster = await getStorageData(configJSON.updateSupplierToaster);
    await removeStorageData(configJSON.createSupplierToaster);
    await removeStorageData(configJSON.updateSupplierToaster);

    const { t } = this.props;
    if(createSupplierToaster) {
      this.props.openToastHandler(t('suppliers.supplierCreated'), "success");
    } else if(updateSupplierToaster) {
      this.props.openToastHandler(t('suppliers.supplierUpdated'), "success");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  unArchiveSupplierResponse = async (responseJson:{message:string,errors:string}) =>{
    if (!responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        this.setState({ archiveSuppliersLoading: false });
        this.showOpenToastHandler();
      } else {
        await removeStorageData(configJSON.supplier_id);
        this.setState({ archiveSuppliersLoading: false ,openUnarchiveDialog: false });
        await setStorageData(configJSON.UnarchiveSupplierToaster, configJSON.unarchiveSupplierValue);
        this.handleNavigate(configJSON.Suppliers);
      }
    } else if (responseJson?.errors) {
      this.setState({ archiveSuppliersLoading: false });
    } else {
      this.setState({ archiveSuppliersLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  } 
  handleChangeCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCountryObject = this.state.getCurrencyData.find(
      (item: CurrencyData) => item[event.target.name as keyof CurrencyData] === event.target.value);
    if (selectedCountryObject) {
      this.setState({getCurrency: selectedCountryObject.currency});
    }
  }
  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleOpenUnarchiveDialog = () => {
    this.setState({ openUnarchiveDialog: true })
  }
  handleCloseUnarchiveDialog = () => {
    this.setState({ openUnarchiveDialog: false })
  }
  unArchiveSupplier = async () => {
    const { id } = this.state.formDataEdit;
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };
    const httpBody = {
      "id": id,
      "data": {"status": "active"}
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unArchiveSupplierApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editSuppliersAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editSuppliersAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  noSupplierId = async () => {
    const supplier_id = await getStorageData(configJSON.supplier_id);
    if(!supplier_id) {
      this.handleNavigate(configJSON.Suppliers);
    }
  }

  supplierId = async () => {
    await removeStorageData(configJSON.supplier_id);
    await removeStorageData(configJSON.purchase_id);
  }

  noPurchaseId = async () => {
    const purchase_id = await getStorageData(configJSON.purchase_id);
    if(!purchase_id) {
      this.handleNavigate(configJSON.PurchaseOrder);
    }
  }
  
  purchaseId = async () => {
    await removeStorageData(configJSON.purchase_id);
  }

  tokenExpired = (isEmployee: boolean) => {
    setTimeout(() => {
      localStorage.clear();
      this.props.navigation.history?.push(isEmployee ? "/Employee/Login" : "/Login");
    }, 2000);
  }

  errorMessageHandler = async (error: any) => {
    const { token } = error;
    if(token == configJSON.tokenExpired || token == configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  getErrorMessage = (error: any) => {
    if (error) {
      this.errorMessageHandler(error);
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createSuppliersApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({ createSuppliersLoading: false });
            this.showOpenToastHandler();
          } else {
            this.setState({ createSuppliersLoading: false });
            await setStorageData(configJSON.supplier_id, responseJson?.supplier?.id);
            await setStorageData(configJSON.createSupplierToaster, configJSON.createSupplierValue);
            this.handleNavigate(configJSON.SuppliersData);
          }
        } else if (responseJson?.errors) {
          this.setState({ createSuppliersLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ createSuppliersLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      } else if(apiRequestCallId === this.unArchiveSupplierApiCallId){
        this.unArchiveSupplierResponse(responseJson)
      }
    }
    this.handleGetSingleSupplierData(message);
    this.handleEditSuppliersData(message);
    this.handleArchiveSupplier(message);
    this.handleDeleteSuppliersData(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetSingleSupplierData = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getSingleSupplierApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({ getSingleSupplierLoading: false });
            this.showOpenToastHandler();
          } else {
            this.setState({ singleSupplierData: responseJson?.data ,getCurrency:responseJson?.data?.currency});
            const formDataEdit = {
              id: responseJson?.data?.id,
              supplierName: responseJson?.data?.supplier_name,
              contactName: responseJson?.data?.contact_name,
              supplierOf: responseJson?.data?.supplier_of,
              mobile: responseJson?.data?.mobile_number?.split(" ")[1],
              email: responseJson?.data?.email,
              address: responseJson?.data?.address,
              city: responseJson?.data?.city,
              state: responseJson?.data?.state,
              zip: responseJson?.data?.zip_code,
              currency: responseJson?.data?.currency,
              companyInformation:responseJson.data&&responseJson.data.company_information
            }
            const countryCode = responseJson?.data?.mobile_number?.split(" ")[0];
            this.setState({
              countryCodeEdit: countryCode,
              formDataEdit: formDataEdit,
              getSingleSupplierLoading: false,
              status: responseJson?.data?.status
            });
          }
        } else if (responseJson?.errors) {
          this.setState({ getSingleSupplierLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ getSingleSupplierLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleEditSuppliersData = async (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.editSuppliersApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({ editSuppliersLoading: false });
            this.showOpenToastHandler();
          } else {
            this.setState({ editSuppliersLoading: false });
            await setStorageData(configJSON.updateSupplierToaster, configJSON.updateSupplierValue);
            this.handleNavigate(configJSON.SuppliersData);
          }
        } else if (responseJson?.errors) {
          this.setState({ editSuppliersLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ editSuppliersLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }else if(apiRequestCallId === this.currencyApiCallId){
        this.currencyApiResponse(responseJson)
      }
    }
  }

  handleArchiveSupplier = async (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.archiveSuppliersApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({ archiveSuppliersLoading: false });
            this.showOpenToastHandler();
          } else {
            await removeStorageData(configJSON.supplier_id);
            this.setState({ archiveSuppliersLoading: false });
            await setStorageData(configJSON.archiveSupplierToaster, configJSON.archiveSupplierValue);
            this.handleNavigate(configJSON.Suppliers);
          }
        } else if (responseJson?.errors) {
          this.setState({ archiveSuppliersLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ archiveSuppliersLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  
  currencyApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ getCurrencyData:responseJson,createSuppliersLoading: false })
    } else {
      if (responseJson&&responseJson.errors) {
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
          this.setState({ createSuppliersLoading: false })
      }
    }
  }
  handleDeleteSuppliersData = async (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteSuppliersApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({
              deleteDialogOpen: false,
              deleteSuppliersLoading: false,
            });
            this.showOpenToastHandler();
          } else {
            await removeStorageData(configJSON.supplier_id);
            this.setState({ deleteSuppliersLoading: false });
            await setStorageData(configJSON.deleteSupplierToaster, configJSON.deleteSupplierValue);
            this.handleNavigate(configJSON.Suppliers);
          }
        } else if (responseJson?.errors) {
          this.setState({
            deleteSuppliersLoading: false,
            deleteDialogOpen: false
          });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ deleteSuppliersLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  showOpenToastHandler = () => {
    const { t } = this.props;
    this.props.openToastHandler(t('suppliers.notAuthorized'), configJSON.errorLabel)
  }
  
  createSuppliers = async () => {
    const { supplierName, contactName, supplierOf, mobile, email, address, city, state, zip,companyInformation } = this.state.formData;
    const { countryCode } = this.state;
    const { languageSelected } = this.state;
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const httpBody = {
      "data": {
        "supplier_name": supplierName.trim(),
        "contact_name": contactName.trim(),
        "supplier_of": supplierOf.trim(),
        "mobile_number": `${countryCode} ${mobile.trim()}`,
        "email": email.trim(),
        "address": address.trim(),
        "city": city.trim(),
        "state": state.trim(),
        "zip_code": zip.trim(),
        "currency": this.state.getCurrency.trim(),
        "company_information":companyInformation.trim()
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSuppliersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.suppliersAPIEndPoint}?lang=${languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSuppliersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getSingleSupplier = async () => {
    const id = localStorage.getItem("supplier_id");
    const languageSelected = localStorage.getItem("lang");
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleSupplierApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.suppliersAPIEndPoint}${id}&lang=${languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSuppliersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  archiveSupplier = async () => {
    const { id } = this.state.formDataEdit;
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const httpBody = {
      "id": id,
      "data": {
        "status": "archived"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.archiveSuppliersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editSuppliersAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editSuppliersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  editSupplier = async () => {
    const { languageSelected } = this.state;
    const { id, supplierName, contactName, supplierOf, mobile, email, address, city, state, zip, currency,companyInformation } = this.state.formDataEdit;
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const httpBody = {
      "id": id,
      "data": {
        "supplier_name": supplierName.trim(),
        "contact_name": contactName.trim(),
        "supplier_of": supplierOf.trim(),
        "mobile_number": `${this.state.countryCodeEdit} ${mobile.trim()}`,
        "email": email.trim(),
        "address": address.trim(),
        "city": city.trim(),
        "state": state.trim(),
        "zip_code": zip.trim(),
        "currency": this.state.getCurrency.trim(),
        "company_information":companyInformation.trim()
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editSuppliersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editSuppliersAPIEndPoint}?lang=${languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editSuppliersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteSupplier = async () => {
    const { languageSelected } = this.state;
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSuppliersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSuppliersAPIEndPoint}${this.state.formDataEdit?.id}&lang=${languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSuppliersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getCurrency = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({ createSuppliersLoading: true })
    const getCurrencyApiCall = apiCall({
      header: header,
      httpBody: null,
      url: configJSON.getCurrency,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.currencyApiCallId = getCurrencyApiCall.messageId;
    runEngine.sendMessage(getCurrencyApiCall.id, getCurrencyApiCall);
  }
  handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    const urlMap: any = {
      0: configJSON.CreateSupplier,
      1: configJSON.PurchaseOrders,
      2: configJSON.CreatePurchase,
      3: configJSON.SupplierData,
      4: configJSON.EditSupplier,
      5: configJSON.PurchaseData,
    };
    
    const url = urlMap[newValue];
    const pathName = this.props.navigation.history?.location?.pathname;
    if (url) {
      if(pathName === configJSON.PurchaseOrders || pathName === configJSON.CreatePurchase || pathName === configJSON.PurchaseData) {
        this.props.navigation.history?.push(configJSON.SupplierData);
      } else {
        this.props.navigation.history?.push(url);
      }
    }
  }

  handleChangeCountryCode = (event: React.ChangeEvent<{}>, value: number | string) => {
    this.setState({ countryCode: value });
  };

  handleChangeCountryCodeEdit = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ countryCodeEdit: value });
  };
  
  handleChangeFormData = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formData: {...this.state.formData, [e?.target.name]: e?.target.value}
    });
  }

  handleChangeFormDataEdit = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formDataEdit: {...this.state.formDataEdit, [e.target.name]: e.target.value}
    });
  }
  
  handleCancelButton = async () => {
    this.handleNavigate(configJSON.Suppliers);
    await removeStorageData(configJSON.supplier_id);
  }

  handleEditButton = async () => {
    const isAuthorized = await performSupplierAuthorizationCheck("supplier_data"); //true = not authorized , false = authorized
    this.handleEditButtonCallback(isAuthorized);
  }

  handleEditButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.handleNavigate(configJSON.EditSuppliers);
    } else {
      this.showOpenToastHandler();
    }
  }
  validateFormData = (values: any) => {
    const errors: any = {};
    const regexName = this.state.languageSelected === "ar" ?
    /^\s*[a-zA-Z\u0621-\u064A]+(?:\s[a-zA-Z\u0621-\u064A]+)*\s*$/ :
    /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
    const regexMobile = /^\s*\d+\s*$/

    const { t } = this.props;
    if (!values.supplierName) {
      errors.supplierName = t('suppliers.supplierNameError')
    } else if (!regexName.test(values.supplierName)) {
      errors.supplierName = t('suppliers.alphabeticalError')
    } else if (values.supplierName.length > 23) {
      errors.supplierName = t('suppliers.supplierNamelengthError')
    }

    if (values.contactName && !regexName.test(values.contactName)) {
      errors.contactName = t('suppliers.alphabeticalError')
    } else if (values.contactName && values.contactName.length > 23) {
      errors.contactName = t('suppliers.contactNamelengthError')
    }

    if (values.supplierOf && !regexName.test(values.supplierOf)) {
      errors.supplierOf = t('suppliers.alphabeticalError')
    } else if (values.supplierOf && values.supplierOf.length > 23) {
      errors.supplierOf = t('suppliers.supplierOflengthError')
    }

    if (values.mobile && !regexMobile.test(values.mobile)) {
      errors.mobile = t('suppliers.numericalError')
    }

    this.validateAddress(values, errors);

    return errors;
  }

  validateAddress = (values: any, errors: any) => {
    const { t } = this.props;
    const regexAddressField = configJSON.regexAddressField
    const zipCodeRegex = /^\s*\d+\s*$/;
    const regexAddress = this.state.languageSelected === "ar" ?
    /^\s*[a-zA-Z\u0621-\u064A]+(?:\s[a-zA-Z\u0621-\u064A]+)*\s*$/ :
    /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
    const regexEmail = /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;

    if (values.email && !regexEmail.test(values.email)) {
      errors.email = t('suppliers.invalidEmail')
    }
    
    if (values.address && !regexAddressField.test(values.address)) {
      errors.address = t('suppliers.alphabeticalError')
    }

    if (values.city && !regexAddress.test(values.city)) {
      errors.city = t('suppliers.alphabeticalError')
    }

    if (values.state && !regexAddress.test(values.state)) {
      errors.state = t('suppliers.alphabeticalError')
    }

    if (values.zip && !zipCodeRegex.test(values.zip)) {
      errors.zip = t('suppliers.alphaNumericalError')
    }
  }

  handleClickOpenDialog = async () => {
    const isAuthorized = await checkLoggedInUser();
    this.handleClickOpenDialogCallBack(isAuthorized);
  };

  handleClickOpenDialogCallBack = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ deleteDialogOpen: true });
    } else {
      this.showOpenToastHandler();
    }
  }

  handleCloseDialog = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleSaveButton = async () => {
    const isAuthorized = await performSupplierAuthorizationCheck("supplier_data"); // true = not authorized, false = authorized
    this.handleSaveButtonCallback(isAuthorized);
  }
  
  handleSaveButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ 
          isErrorFormData: this.validateFormData(this.state.formData) 
        },
        () => {
          if (Object.keys(this.state.isErrorFormData).length === 0) {
            this.createSuppliers();
            this.setState({ createSuppliersLoading: true });
          } else {
            const firstErrorField = Object.keys(this.state.isErrorFormData)[0];
            const element = document.querySelector(`[name="${firstErrorField}"]`);
  
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        }
      );
    } else {
      this.showOpenToastHandler();
    }
  }

  handleSaveButtonEdit = () => {
    this.setState({ 
        isErrorFormData: this.validateFormData(this.state.formDataEdit) 
      }, 
      () => {
        if (Object.keys(this.state.isErrorFormData).length === 0) {
          this.editSupplier();
          this.setState({ editSuppliersLoading: true });
        } else {
          const firstErrorField = Object.keys(this.state.isErrorFormData)[0];
          const element = document.querySelector(`[name="${firstErrorField}"]`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
    );
  }
  

  handleClickProceedDelete = () => {
    this.deleteSupplier();
    this.setState({ deleteSuppliersLoading: true });
  }

  handleClickProceedArchive = () => {
    this.archiveSupplier();
    this.setState({ archiveSuppliersLoading: true });
  }

  handleBackIcon = () => {
    this.props.navigation.history?.goBack();
  }

}
// Customizable Area End