import { withStyles, createStyles, Theme, StyleRulesCallback } from "@material-ui/core";
import { CONSTANTS } from '../../../components/src/theme'
const selectedLanguage = localStorage.getItem("lang");
const calendarStyles: StyleRulesCallback<Theme, {}, string> = (theme: Theme) =>
    createStyles({
        customBigCalender: {
            '& .rbc-day-bg': {
                borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
            },
            '& .rbc-month-view .rbc-month-header .rbc-header': {
                borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
            },
            '& .rbc-month-view': {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
                marginTop: '20px',
                border: 'none',
                '& .rbc-month-header': {
                    '& .rbc-header': {
                        [theme.breakpoints.down("xs")]: {
                            padding: "10px !important"
                        },
                        padding: '25px',
                        fontWeight: 400,
                        color: 'rgb(153, 153, 153)'
                    },
                },
                '& .rbc-month-row': {
                    '& .rbc-row': {
                        '& .rbc-date-cell': {
                            padding: '10px 10px 0px 0px',
                            fontWeight: 600,
                        },
                        '& .rbc-off-range': {
                            color: 'white !important'
                        },
                        '& .rbc-row-segment': {
                            '& .rbc-event': {
                                background: 'none',
                            },
                            '& .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus': {
                                outline: 'none !important'
                            },
                            '& .rbc-event-content': {
                                color: '#E25085',
                                fontWeight: 600,
                            }
                        }
                    },
                    '& .rbc-row-bg': {
                        '& .rbc-off-range-bg': {
                            background: 'none',
                        },
                    }
                },
            },
            '& .rbc-time-view': {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
                marginTop: '20px',
                border: 'none',
                zIndex:0,
                '& .rbc-header': {
                    borderBottom: 'none',
                    padding: '16px',
                },
            },
            '& .rbc-time-header.rbc-overflowing': {
                borderRight: 'none',
                marginRight:"0px !important"
            },
            '& .rbc-timeslot-group': {
                minHeight: '100px'
            },
            '& .rbc-time-slot': {
                color: 'rgb(153, 153, 153)',
                FontWeight: CONSTANTS.fontWeight.fontWeight500
            },
            '& .rbc-time-view .rbc-row': {
                minHeight: '12px',
                color: 'rgb(153, 153, 153)'
            },
            '& .rbc-event, .rbc-day-slot .rbc-background-event': {
                background: 'none',
                fontWeight: 600,
                color: '#E25085',
                paddingLeft: '7px',
            },
            '& .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-event': {
                border: 'none'
            },
        },
        customMonthPicker: {
            color: 'rgb(98, 98, 98)',
            fontWeight: 500,
            fontSize: '18px',
            border: 'none',
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
            marginLeft: "-15em !important",
            [theme.breakpoints.down('xs')]: {
                marginLeft: "-11em !important",
            },
            "& .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text": {
                width: "5rem !important",
                padding: "18px",
            },
            "& .react-datepicker__header:not(.react-datepicker__header--has-time-select)": {
                background: 'white !important',
                fontSize: "19px",
                color: 'rgb(98, 98, 98)',
                border: "1px solid rgb(229,229,229)",
            },
            "& .react-datepicker__navigation": {
                top: "20px"
            },
            "& .react-datepicker-popper": {
                // zIndex: 9999
            },
            "& .react-datepicker__month-text--selected": {
                background: "none !important",
                color: "#1c18af"
            },
            "& .react-datepicker__month-text--keyboard-selected": {
                background: "none !important",
                color: "#1c18af"
            },
            "& .react-datepicker__month-text--today": {
                fontWeight: 500
            }
        },
    })

export default withStyles(calendarStyles);