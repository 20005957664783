import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { performAuthorizationCheck , getLang, generateStatusMapping, handleTooltipOpenProducts, handleTooltipClose} from "./utility.web";
import { apiCall,checkTokenExpired,debounce,customerFilterListData,navigateTo } from "../../../components/src/CommonFunction";
import {ProductItem, SiteProp} from "./CustomerRequestsController.web"
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchInvoice:any
  invoiceCustomerDataList:any
  allRequests:any
  tableLoading:boolean
  errorMessage:any
  sortDirection: string
  sortColumn: string
  lang:string
  siteDailog:boolean
  siteDetails:SiteProp
  customerSitesData:SiteProp[]
  type:string
  statusData:any
  statusOrder:string[]
  anchorEl:Element | null,
  anchorId:string;
  listData:ProductItem[] | [],
  popoverHeading:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomersRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchInvoiceApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
     
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      statusOrder: [],
      statusData: {},
      popoverHeading: "",
      listData: [],
      anchorEl: null,
      anchorId: "",
      type: "invoice",
      searchInvoice: '',
      invoiceCustomerDataList: [],
      allRequests: [],
      tableLoading: true,
      errorMessage: null,
      sortDirection: "asc",
      sortColumn: "",
      lang: "en",
      siteDailog: false,
      customerSitesData: [],
      siteDetails: {
        id: null,
        site_name: "",
        email: "",
        phone_number: "",
        site_address: "",
        state: "",
        city: "",
        zip_code: "",
        customer_id: 2,
        longitude: "",
        latitude: "",
        created_at: "",
        updated_at: "",
        country: "",
      },
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const lang = await getLang();
    this.setState({ lang });
    this.searchCustomerInvoice()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
     if (apiRequestCallId === this.searchInvoiceApiCallId) {
        this.handleSearchInvoiceApiResponse(responseJson);
      }
   
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleSearchInvoiceApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      if (this.state.siteDailog) {
        this.setState({ tableLoading: false })
        return this.setState({ customerSitesData: responseJson.data })
      }
      const setStatusData = (statusOrder: string[], data: any) => {
        this.setState({
            statusOrder,
            statusData: data
        });
    };
    let statusOrder;
      let data;
      statusOrder = this.state.lang === "en" ?
        ["awaiting_payment", "payment_overdue", "paid", "bad_debt", "archived"] :
        ['انتظار الدفع','التأخر في الدفع','مدفوع','الديون المعدومة','مؤرشف'];
      data = generateStatusMapping(responseJson.data, statusOrder);
      setStatusData(statusOrder, data);
      this.setState({ invoiceCustomerDataList: customerFilterListData(responseJson) }, () => { this.setState({ tableLoading: false }) })
    } else if (responseJson.errors) {
      checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history)
      this.setState({ invoiceCustomerDataList: [], tableLoading: false, errorMessage: responseJson?.errors[0]?.message });
    }
  }
  handleChangeSearchInvoice = (type:string,event: any) => {
    this.setState({ searchInvoice: event.target?.value, type }, () => {
      this.debounceSearchCustomerInvoice()
    });
  }
  handleViewInvoiceData = async (item:any) => {
    const isAuthorized = await performAuthorizationCheck("customers_data");
    this.handleViewInvoiceDataCall(isAuthorized,item);
  };
  handleViewInvoiceDataCall = (isAuthorized: any,item:any) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      this.props.history.push("/Invoice", {
            customerId: item.attributes.customer_id,
            invoiceId: item.id,
            siteId: item.attributes.site_id
          });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  handleSortCustomerInvoice = (columnKey: any) => {
    let newSortDirection: "asc" | "desc" = "asc";
    if (
      !(
        columnKey === "team" ||
        columnKey === "product_name" ||
        columnKey === "service_name" ||
        columnKey === "product_quantity" ||
        columnKey === "job_title"
      )
    ) {
      if (columnKey === this.state.sortColumn) {
        newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
      }
      this.setState({
        invoiceCustomerDataList: this.state.invoiceCustomerDataList,
        sortDirection: newSortDirection,
        sortColumn: columnKey,
        type: "invoice"
      }, () => {
        this.debounceSearchCustomerInvoice()
      })
    }

  };
 
  handleCreateCustomerInvoice = async () => {
    const isAuthorized = await performAuthorizationCheck("customers_data");
    this.handleCreateCustomerInvoiceCall(isAuthorized);
  };
  handleCreateCustomerInvoiceCall = (isAuthorized: any) => {
    const customerId = localStorage.getItem("customer_id")
    const siteId = localStorage.getItem("site_id")
    if (!isAuthorized) {
      if (this.state.siteDetails.id) {
        this.props.history.push("/Invoice", {
          customerId: customerId,
          siteId:siteId
        });
      } else {
        this.setState({ siteDailog: true, type: "sites" },
          ()=>this.searchCustomerInvoice());
      }

    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  searchCustomerInvoice = () => {
    this.setState({invoiceCustomerDataList:[]})
    const apiEndPoint = configJSON.getInvoices
    const token = localStorage.getItem(configJSON.storageToken)
    const customer_id = localStorage.getItem("customer_id");
    const header = {
      "token": token
    }; 
    let customerInvoiceUrl 
    if(this.state.searchInvoice && this.state.sortColumn){
      customerInvoiceUrl= `${apiEndPoint}?lang=${this.state.lang}&type=${this.state.type}&customer_id=${customer_id}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&query=${this.state.searchInvoice}`
    } else if(this.state.searchInvoice){
      customerInvoiceUrl= `${apiEndPoint}?lang=${this.state.lang}&type=${this.state.type}&customer_id=${customer_id}&query=${this.state.searchInvoice}`
    } else if(this.state.sortColumn){
      customerInvoiceUrl=`${apiEndPoint}?lang=${this.state.lang}&type=${this.state.type}&customer_id=${customer_id}&field=${this.state.sortColumn}&order=${this.state.sortDirection}`
    } else{
      customerInvoiceUrl=`${apiEndPoint}?lang=${this.state.lang}&type=${this.state.type}&customer_id=${customer_id}`
    }

    this.setState({tableLoading:true})
    const getTestAPI = apiCall({
      header: header,
      httpBody: '',
      url:customerInvoiceUrl,
      httpMethod:configJSON.validationApiMethodType,
    });
    this.searchInvoiceApiCallId = getTestAPI.messageId;
    runEngine.sendMessage(getTestAPI.id, getTestAPI);
  }
  debounceSearchCustomerInvoice = debounce(this.searchCustomerInvoice, 700);
  
  handleSiteCloseModal = ()=>{
    this.setState({
      siteDailog:false
    })
  }

  selectedSiteCustomer = (data: SiteProp) => {
    this.setState({
        siteDetails: data,
        siteDailog: false,
    })
    localStorage.setItem('invoiceCusId',localStorage.getItem("customer_id")||'')
    localStorage.setItem("invoiceSiteId1",JSON.stringify(data.id));localStorage.setItem("createFromCustomer",'true')
    localStorage.setItem("site_id",JSON.stringify(data.id))
    this.props.history.push("/Invoice", {customerId: localStorage.getItem("customer_id"),siteId:data.id});
  }
  handleNavigation = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  createSite = () => {
    this.handleNavigation("CustomerSites")
  }
  handleTooltipOpenInvoice = (item: any, product_id: string, popoverHead: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { anchorEl, anchorId, listData, popoverHeading } = handleTooltipOpenProducts(item, product_id, popoverHead, event)
    this.setState({
      anchorEl, anchorId, listData, popoverHeading
    })
  }
  handleInvoiceViewData = (rowItem:any) => {
    localStorage.setItem("customerTabId", rowItem.id)
    localStorage.setItem("invoiceCusId", rowItem.attributes.customer_id)
    localStorage.setItem("invoiceSiteId1", rowItem.attributes.site_id)
    navigateTo({props: this.props,screenName: "Invoice"});
  }
  handleTooltipCloseInvoice = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { anchorEl, anchorId, listData, popoverHeading } = handleTooltipClose();
    this.setState({
      anchorEl, anchorId, listData, popoverHeading
    })
  }

  sortRequestsData = (rowItem: any) => this.handleSortCustomerInvoice(rowItem.key);
  // Customizable Area End
}
