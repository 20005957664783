import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Hidden,
  Dialog,
  Button,
  Typography
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import {
  lamsaLogo,
  purchaseOrderIcon
} from "../assets/assets";
import CloseIcon from '@material-ui/icons/Close';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import PurchasesController, {
  Props,
} from "./PurchasesController.web";

// Customizable Area Start
const StyledDialog = withStyles((theme: Theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "1166px",
      borderRadius: "8px",
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    }
  }
}))(Dialog);
// Customizable Area End

export class PurchaseOrderPDF extends PurchasesController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    // Customizable Area Start
    const renderAddressDetails = () => {
      return (
        <>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorText} variant="body2">
              {t('suppliers.company')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorAddress} variant="body2" color="textPrimary">
              {purchasePdfData?.attributes?.vender_details?.company || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorText} variant="body2">
              {t('suppliers.address')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorAddress} variant="body2" color="textPrimary">
              {purchasePdfData?.attributes?.vender_details?.address || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorText} variant="body2">
              {t('suppliers.city')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorAddress} variant="body2" color="textPrimary">
              {purchasePdfData?.attributes?.vender_details?.city || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorText} variant="body2">
              {t('suppliers.state')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorAddress} variant="body2" color="textPrimary">
              {purchasePdfData?.attributes?.vender_details?.state || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorText} variant="body2">
              {t('suppliers.zipCode')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography style={webStyle.vendorAddress} variant="body2" color="textPrimary">
              {purchasePdfData?.attributes?.vender_details?.zip_code || "-"}
            </Typography>
          </Grid>
        </>
      );
    }

    const { t } = this.props;
    const { purchasePdfData } = this.state;
    const dateString = purchasePdfData?.attributes?.purchase_date;
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    const formattedDateAr = dateObject.toLocaleDateString('ar', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).replace(/\d/g, digit => '٠١٢٣٤٥٦٧٨٩'[parseInt(digit)]);
    const { classes }: any = this.props;
    return (
      <StyledDialog
        fullWidth={true}
        maxWidth={"lg"}
        open={this.props.openPdf}
        onClose={this.props.handleCloseDownloadPdf}
      >
        <Box style={webStyle.closeIcon} className={classes.closeIcon}>
          <CloseIcon
            style={{ fontSize: 30 }}
            onClick={this.props.handleCloseDownloadPdf}
          />
        </Box>
        <div style={webStyle.pdfContainer} className={`purchasePdf ${classes.pdfContainer}`}>
          <Box style={{ ...webStyle.container, border: `5px solid ${purchasePdfData?.attributes?.pdf_url?.pdf_hex_colour || CONSTANTS.color.black}` }}>
            <Box
              style={{
                ...webStyle.innerContainer,
                border: `1px solid ${purchasePdfData?.attributes?.pdf_url?.pdf_hex_colour || CONSTANTS.color.black}`
              }}
              className={classes.innerContainer}
            >
              <img
                style={(!this.state.purchasePdfLoading && purchasePdfData?.attributes?.company_logo?.url) ? webStyle.companyLogo : webStyle.lamsaLogo}
                src={(!this.state.purchasePdfLoading && purchasePdfData?.attributes?.company_logo?.url) || lamsaLogo}
                alt="logo"
              />
              <Box style={webStyle.purchaseOrderIconContainer}>
                <img style={webStyle.purchaseOrderIcon} src={purchaseOrderIcon} alt="" />
                <Typography style={webStyle.purchaseOrderText} variant="subtitle1">
                  {t('suppliers.purchaseOrder')}
                </Typography>
              </Box>
              <Box style={webStyle.addressDateContainer} className={classes.addressDateContainer}>
                <Box>
                  <Typography style={webStyle.lamsaText} variant="body1">
                    {purchasePdfData?.attributes?.admin_address?.company_name}
                  </Typography>
                  <Typography style={webStyle.lamsaTextAddress} variant="body1">
                    {purchasePdfData?.attributes?.admin_address?.street_address}
                  </Typography>
                  <Typography style={webStyle.lamsaTextAddress} variant="body1">
                    {purchasePdfData?.attributes?.admin_address?.city} {purchasePdfData?.attributes?.admin_address?.post_code}
                  </Typography>
                  <Typography style={webStyle.lamsaTextAddress} variant="body1">
                    {purchasePdfData?.attributes?.admin_address?.full_phone_number}
                  </Typography>
                </Box>
                <Box className={classes.dateContainer}>
                  <Typography style={{ ...webStyle.lamsaText, color: CONSTANTS.color.charcoalGrey, fontWeight: CONSTANTS.fontWeight.fontWeight400 }} variant="body1">
                    {this.props.languageSelected === "ar" ? formattedDateAr : formattedDate}
                  </Typography>
                  <Typography style={{ ...webStyle.lamsaText, color: CONSTANTS.color.charcoalGrey, fontWeight: CONSTANTS.fontWeight.fontWeight700 }} variant="body1">
                    {t('suppliers.po')} {purchasePdfData?.id}
                  </Typography>
                </Box>
              </Box>
              <span style={webStyle.hr}></span>

              {/* address details */}

              <Grid container spacing={1} className={classes.addressContainer}>

                {/* vendor details */}
                <Grid item xs={12} sm={5}>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Typography style={webStyle.vendorTextHeading} gutterBottom variant="body1">
                        {t('suppliers.vendorInformation')}
                      </Typography>
                    </Grid>
                    {renderAddressDetails()}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2}></Grid>

                {/* shipping address */}
                <Grid item xs={12} sm={5}>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Typography style={webStyle.vendorTextHeading} gutterBottom variant="body1">
                        {t('suppliers.shippingTo')}
                      </Typography>
                    </Grid>
                    {renderAddressDetails()}
                  </Grid>
                </Grid>
              </Grid>

              {/* purchase section */}
              <Grid container spacing={1} className={classes.purchaseSection}>
                <Grid item xs={12} sm={2}>
                  <Typography style={webStyle.purchaseDataTableRow} variant="subtitle1">
                    {t('suppliers.item#')}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={12} sm={2}>
                    <Typography style={webStyle.purchaseDataTableBody} variant="body1">
                      {purchasePdfData?.attributes?.item}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={3}>
                  <Typography style={webStyle.purchaseDataTableRow} variant="subtitle1">
                    {t('suppliers.description')}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={12} sm={3} style={webStyle.paddingRightStyle}>
                    <Typography style={{...webStyle.purchaseDataTableBody, wordBreak: "break-word"}} variant="body1">
                      {purchasePdfData?.attributes?.item_discription}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={3}>
                  <Typography style={webStyle.purchaseDataTableRow} variant="subtitle1">
                    {t('suppliers.quantity')}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={12} sm={3}>
                    <Typography style={webStyle.purchaseDataTableBody} variant="body1">
                      {purchasePdfData?.attributes?.quantity}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={2}>
                  <Typography style={webStyle.purchaseDataTableRow} variant="subtitle1">
                    {t('suppliers.price')}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={12} sm={2}>
                    <Typography style={webStyle.purchaseDataTableBody} variant="body1">
                      {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.price}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={12} sm={2}>
                    <Typography className={classes.textAlignStart} style={{ ...webStyle.purchaseDataTableRow, textAlign: "end" }} variant="subtitle1">
                      {t('suppliers.total')}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <Box style={webStyle.mobileTotalStyle}>
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <Typography className={classes.textAlignStart} style={{ ...webStyle.purchaseDataTableRow, textAlign: "end" }} variant="subtitle1">
                          {t('suppliers.total')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Typography className={classes.textAlignStart} style={{ ...webStyle.purchaseDataTableBody, textAlign: "end" }} variant="body1">
                        {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.sub_total}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <Typography style={{ ...webStyle.purchaseDataTableBody, lineHeight: 1.75 }} variant="body1">
                          {t('suppliers.subtotal')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Typography className={classes.textAlignStart} style={{ ...webStyle.purchaseDataTableBody, textAlign: "end" }} variant="body1">
                          {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.sub_total}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={2}>
                        <Typography style={{ ...webStyle.purchaseDataTableBody, color: CONSTANTS.color.forestGreen, lineHeight: 1.75 }} variant="body1">
                          {t('suppliers.total')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Typography
                          className={classes.textAlignStart}
                          style={{
                            ...webStyle.purchaseDataTableBody,
                            color: CONSTANTS.color.forestGreen,
                            textAlign: "end"
                          }}
                          variant="body1"
                        >
                          {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.total}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Hidden>
              </Grid>
              <Hidden xsDown>
                <span style={webStyle.hrBold}></span>
              </Hidden>
              <Grid container spacing={1} className={classes.addressContainer}>
                <Hidden xsDown>
                  <Grid item xs={12} sm={2}>
                    <Typography style={webStyle.purchaseDataTableBody} variant="body1">
                      {purchasePdfData?.attributes?.item}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={12} sm={3} style={webStyle.paddingRightStyle}>
                    <Typography style={{...webStyle.purchaseDataTableBody, wordBreak: "break-word"}} variant="body1">
                      {purchasePdfData?.attributes?.item_discription}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={12} sm={3}>
                    <Typography style={webStyle.purchaseDataTableBody} variant="body1">
                      {purchasePdfData?.attributes?.quantity}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={12} sm={2}>
                    <Typography style={webStyle.purchaseDataTableBody} variant="body1">
                    {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.price}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={12} sm={2}>
                    <Typography className={classes.textAlignStart} style={{ ...webStyle.purchaseDataTableBody, textAlign: "end" }} variant="body1">
                    {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.sub_total}
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
              <Hidden xsDown>
                <span style={{ ...webStyle.hr, margin: "1.5rem 0px" }}></span>
              </Hidden>
              <Hidden xsDown>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}></Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography style={webStyle.purchaseDataTableBody} variant="body1">
                      {t('suppliers.subtotal')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography className={classes.textAlignStart} style={{ ...webStyle.purchaseDataTableBody, textAlign: "end" }} variant="body1">
                    {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.sub_total}
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden xsDown>
                <span style={{ ...webStyle.hr, margin: "1rem 0px" }}></span>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>

                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography style={{ ...webStyle.purchaseDataTableBody, color: CONSTANTS.color.forestGreen }} variant="body1">
                      {t('suppliers.total')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography
                      className={classes.textAlignStart}
                      style={{
                        ...webStyle.purchaseDataTableBody,
                        color: CONSTANTS.color.forestGreen,
                        textAlign: "end"
                      }}
                      variant="body1"
                    >
                      {purchasePdfData?.attributes?.currency}{purchasePdfData?.attributes?.sub_total}
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <span style={{ ...webStyle.hr, margin: "4rem 0px 1rem" }} className={classes.hrTag}></span>
              <Grid container spacing={1} style={webStyle.alignItemStyle}>
                <Grid item xs={12} sm={8}>
                  <Typography style={webStyle.paymentAdvice} variant="subtitle1">
                    {t('suppliers.paymentAdvice')}
                  </Typography>
                </Grid>
                <Grid item xs={7} sm={2}>
                  <Typography style={webStyle.paymentText} variant="body2">
                    {t('suppliers.discount')}
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={2}>
                  <Typography className={classes.textAlignStart} style={{ ...webStyle.paymentSubText, textAlign: "end" }} variant="body2" color="textPrimary">
                    {purchasePdfData?.attributes?.payment_advice?.discount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8}></Grid>
                <Grid item xs={7} sm={2}>
                  <Typography
                    style={webStyle.paymentText}
                    variant="body2"
                  >
                    {t('suppliers.tax')}
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={2}>
                  <Typography
                    className={classes.textAlignStart}
                    style={{
                      ...webStyle.paymentSubText, textAlign: "end"
                    }}
                    variant="body2"
                    color="textPrimary"
                  >
                    {purchasePdfData?.attributes?.payment_advice?.tax}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8}>
                  
                </Grid>
                <Grid item xs={7} sm={2}>
                  <Typography
                    style={webStyle.paymentText}
                    variant="body2"
                  >
                    {t('suppliers.grandTotal')}
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={2}>
                  <Typography className={classes.textAlignStart} style={{ ...webStyle.paymentSubText, textAlign: "end" }} variant="body2" color="textPrimary">
                    {purchasePdfData?.attributes?.payment_advice?.grand_total}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
        <Box style={webStyle.downloadContainer} className={classes.downloadContainer}>
          <CreateQuote data-test-id="downloadPdf">
            <Typography
              component={'a'}
              href={purchasePdfData?.attributes?.pdf_url?.url}
              target="_blank"
              style={webStyle.buttonTextDownload}
              className={classes.buttonText}
              variant="subtitle2"
            >
              {t('suppliers.download')}
            </Typography>
          </CreateQuote>
        </Box>
        <LoadingBackdrop
          loading={this.state.purchasePdfLoading}
        />
      </StyledDialog>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  closeIcon: {
    padding: "3rem 3rem 0",
    cursor: "pointer",
    textAlignLast: "end" as const
  },
  pdfContainer: {
    padding: "3rem 3rem 3rem"
  },
  container: {
    padding: "8px",
    width: "100%",
    height: "100%",
  },
  innerContainer: {
    padding: "3rem 4rem 30rem",
  },
  lamsaLogo: {
    width: "125px",
    height: "40px"
  },
  companyLogo: {
    width: "100px",
    height: "100px"
  },
  purchaseOrderIconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "12px",
    padding: "1.5rem 0 2rem"
  },
  purchaseOrderIcon: {
    width: "20px",
    height: "20px"
  },
  purchaseOrderText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
  },
  lamsaText: {
    color: CONSTANTS.color.slateGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  lamsaTextAddress: {
    color: CONSTANTS.color.silverGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  addressDateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  vendorShippingContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  hr: {
    display: "flex",
    height: "1px",
    border: "1px solid rgba(0,0,0,0.1)",
    margin: "1rem 0 3rem"
  },
  hrBold: {
    display: "flex",
    height: "1px",
    border: "1px solid rgba(0,0,0,0.53)",
    margin: "1rem 0 2rem"
  },
  vendorDateContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "0.5rem"
  },
  vendorTextHeading: {
    color: CONSTANTS.color.slateGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  vendorText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    color: CONSTANTS.color.charcoalGrey,
    wordBreak: "break-all" as const
  },
  vendorAddress: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  paymentAdvice: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  alignItemStyle: {
    alignItems: "end"
  },
  purchaseDataTableRow: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    wordBreak: "break-all" as const
  },
  mobileTotalStyle: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    paddingTop: "1rem"
  },
  purchaseDataTableBody: {
    color: CONSTANTS.color.silverGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "16px",
    wordBreak: "break-all" as const
  },
  paddingRightStyle: {
    paddingRight: "15px"
  },
  maxWidthStyle: {
    maxWidth: "25%",
    width: "100%"
  },
  textAlign: {
    maxWidth: "25%",
    width: "100%",
    textAlign: "center" as const
  },
  textdateContainer: {
    maxWidth: "25%",
    width: "100%",
    textAlign: "end" as const
  },
  paymentText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    wordBreak: "break-all" as const
  },
  paymentSubText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    wordBreak: "break-all" as const
  },
  downloadContainer: {
    padding: "0rem 3rem 3rem"
  },
  buttonTextDownload: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    textDecoration: 'none',
  },
}

export default withTranslation()(withStyles(styles)(PurchaseOrderPDF));
// Customizable Area End