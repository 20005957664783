//ts-nocheck
import React from "react";
// Customizable Area Start
import { Typography, Grid, MenuItem } from "@material-ui/core";
import BeforeController from "./BeforeController.web";
// Customizable Area End
// Customizable Area Start
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CONSTANTS } from "../../../components/src/theme";
import { CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
import { StepsButton } from "./ProfileSetup.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

export class BeforeWeStart extends BeforeController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    //@ts-ignore
    const { t,classes, activeStep, getSteps, referbefore, handleChangeReferBefore, SignUp, progressbarclass,showOtherField,handleOther,othertext } = this.props
    return (
      <>
        <form onSubmit={SignUp}>
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item xs={12} className={classes.gridAlingment}>
              <Typography variant="h5" className={classes.titletext}>
                {t('beforeScreen.heading')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                className={classes.subtext}
                color="textPrimary"
              >
               {t('beforeScreen.subHeading')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomizedTextField
                data-test-id="handleChangeReferBefore"
                variant="outlined"
                fullWidth
                select
                value={referbefore}
                onChange={handleChangeReferBefore}
                onKeyDown={(e) => this.handleKeyDown(e, SignUp)}
              >
                {this.state.profileAnswers
                  ? this.state.profileAnswers.map((option: any, index: any) => (
                      <MenuItem
                        key={index}
                        value={option.answer}
                        className={classes.menuItemText}
                      >
                        {option.answer}
                      </MenuItem>
                    ))
                  : null}
              </CustomizedTextField>
           
              {showOtherField && (
          <CustomizedTextField
          style={webStyle.otherField}
          data-test-id="handleOther"
            variant="outlined"
            fullWidth
            placeholder="Please specify"
            value={othertext}
            onChange={handleOther}
         
           
          />
        )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={4}
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={12} sm={6} className={classes.progress}>
                {getSteps &&
                  getSteps.map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={progressbarclass(index, activeStep, classes)}
                      />
                    );
                  })}
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <StepsButton data-test-id="btnEmailLogin" type="submit">
                  {getSteps && activeStep === getSteps.length - 1 ? (
                    <Typography
                      variant="body1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t('beforeScreen.btnSubmit')}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1">Next</Typography>
                  )}
                </StepsButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  otherField:{
    marginTop:"20px"
  }
}
export const BeforeStyles = (theme: Theme) =>
  createStyles({
    titletext: {
      fontWeight: CONSTANTS.fontWeight.fontWeight600,
      paddingTop: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    logoImg: {
      width: "150px",
    },
    btntext: {
      fontSize: "18px",
      fontWeight: 400,
      textTransform: "none",
    },
    subtext: {
      fontSize: "15px",
      fontWeight: theme.typography.fontWeightRegular,
      paddingTop: "10px",
      paddingBottom: "1rem",
    },
    inActive: {
      width: "70px",
      height: "10px",
      background: CONSTANTS.color.pogressGrey,
      borderRadius: "25px",
    },
    finish: {
      width: "70px",
      height: "10px",
      background: CONSTANTS.color.darkBlue,
      borderRadius: "25px",
    },
    progress: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "end",
    },
    active: {
      color: CONSTANTS.color.darkBlue,
      width: "70px",
      height: "10px",
      background: CONSTANTS.color.darkBlue,
      borderRadius: "25px",
    },
    menuItemText: {
      color: theme.palette.common.black,
    },
  });
  const beforeComponent=withStyles(BeforeStyles)(BeforeWeStart);
export default withTranslation()(beforeComponent)
// Customizable Area End
