import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Typography,
  InputAdornment,
  Dialog,
  DialogContent,
  InputBase,
  MenuItem,
  TextField,
  Button,
  withWidth
} from "@material-ui/core";
import { styled, withStyles, Theme, } from "@material-ui/core/styles";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { EmployeeProfileSubSideBar } from "./EmployeeProfileSubSideBar.web";
import { StyledRoot, countryCodes } from "./utility.web";
import {
  userIcon,
  profileDropDown,
  successModalImage,
  successModalCross,
} from "./assets";
import {
  editIcon,
  galleryIcon,
  deleteLogoIcon,
  dialogBoxIcon,
} from "../../Settings/src/assets";
import { ProfileStyledTextFields, renderImageUrl } from "./Profile.web";
import AutocompleteV1 from "@material-ui/lab/Autocomplete";
import { withSnackbar } from "../../../components/src/toastweb";
import { withRouter } from "react-router-dom";
import withPermission from "../../../components/src/withPermission.web"
import { Autocomplete } from "@react-google-maps/api";
import {  CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import EmployeeProfileController, {
  Props,
} from "./EmployeeProfileController.web";

// Customizable Area Start
const language = localStorage.getItem("lang")
export const EmployeeStyledMenuItem = withStyles((theme)=>({
  root: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#41cb41",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiInputBase-root": {
      fontSize: "1.3125rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.1953rem !important"
      },
     
    },
  },
}))(MenuItem);

export const EmployeeStyledTextField:any = withStyles((theme)=>({
  root: {
    padding: "0 0.2rem",
    "& .MuiInputBase-root": {
      height: "55px",
      borderRadius: "10px",
     fontSize: "1.1667rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "1.0625rem !important"
        },
      fontFamily: "Rubik",
    },
    "& .MuiOutlinedInput-input": {
      cursor: "pointer",
      padding: "15px 14px",
    },
    "& .MuiInputAdornment-positionEnd":{
      marginLeft: (props: EmployeeTextfieldProps) => props.languageSelected === "ar" ? "15px !important" : "8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#05037a",
      borderWidth: "2px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
  },
}))(TextField);

interface EmployeeTextfieldProps {
  languageSelected?: string;
}
export const EmployeeStyledTextFieldsAutoComplete = withStyles((theme)=>({
  root: {
    "& .MuiInputBase-root": {
      justifyContent: (props: EmployeeTextfieldProps) => props.languageSelected === "ar" ? "center" : "flex-start",
      height: "55px",
      fontSize: "1.1667rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.0625rem !important"
      },
      borderRadius: (props: EmployeeTextfieldProps) => props.languageSelected !== "ar" ? "7px 0 0 7px" : "0 7px 7px 0",
      fontFamily: "Rubik",
      color:CONSTANTS.color.midGrey
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "90px",
    },
    "& .MuiOutlinedInput-input": {
      cursor: "pointer",
      padding: "15px 12px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#05037a",
      borderWidth: "2px",
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none",
    },
  },
}))(TextField);

export const EmployeeProfileStyledButton = withStyles((theme)=>({
  root: {
    backgroundColor: "#1C18AF",
    color: theme.palette.common.white,
    fontSize: "1.6042",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4609rem !important"
    },
    textTransform:"none" as const,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "Rubik",
    textAlign: "center",
    borderRadius: "8px",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        border: "none",
        cursor: "pointer"
    },
  },
}))(Button);

export const EmployeeStyledBoxModal = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    
    [theme.breakpoints.down("xs")]: {
      width: "75% !important"
    },
  })
);
// Customizable Area End

export class EmployeeProfile extends EmployeeProfileController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  renderLogoContainerStyle = () => {
    return this.state.lang === "en" ?
      webStyle.employeeprofilechangeLogoContainer : webStyle.employeeprofilechangeLogoContainerAr
  }

  renderLogoInnerContainerStyle = () => {
    return this.state.isHovered
      ? {
        ...webStyle.employeeprofilechangeLogoInnerContainer,
        backgroundColor: "rgba(28,24,175,0.06)",
      }
      : webStyle.employeeprofilechangeLogoInnerContainer
  }

  renderHoveredInnerContainerStyle = () => {
    return this.state.isHovered2
      ? {
        ...webStyle.employeeprofilechangeLogoInnerContainer,
        backgroundColor: "rgba(28,24,175,0.06)",
      }
      : webStyle.employeeprofilechangeLogoInnerContainer
  }

  renderImage = () => {
    return (
      <>
        {!this.state.companyLogo &&
          !this.state.profile_image_employee && (
            <img
              style={{ borderRadius: "50%", marginTop: "20px" }}
              src={userIcon}
              height="100"
              width="100"
            />
          )}
        {this.state.companyLogo && (
          <img
            style={{ borderRadius: "50%", marginTop: "20px" }}
            src={renderImageUrl(this.state.companyLogo)}
            height="100"
            width="100"
          />
        )}
        {this.state.profile_image_employee && (
          <img
            style={{ borderRadius: "50%", marginTop: "20px" }}
            src={this.state.profile_image_employee}
            height="100"
            width="100"
          />
        )}
        <img
          style={webStyle.employeeprofileeditIcon}
          src={editIcon}
          alt=""
          onClick={this.emphandleClickEditIcon}
        />
      </>
    )
  }
  renderEmployeeForm = (classes: any) => {
    const {t} = this.props
    const {
      firstName,
      lastName,
      email,
      phone,
      streetAddress,
      city,
      country,
      postcode,
    } = this.state.employeeformDetails;
    return (
      <>
        <Grid xs className={classes.employeemainContV1} style={{ paddingTop: "55px" }}>
          <EmployeeProfileSubSideBar
            {...this.props}
           
            profileActive={0}
            
          />
        </Grid>
        <Grid
           className={classes.employeemainCont}
        >
          <Container maxWidth={"lg"} style={{ padding: 0 }}>
            <Typography
              variant="subtitle1"
             
              className={classes.employeeprofileTitle}
            >
             {t("boProfile.profileInformation")}
            </Typography>
          </Container>

          <Grid container direction="column">
            <Grid item xs>
              <Box>
                <Grid item>
                 {this.renderImage()}
                </Grid>
                {this.state.logoChangeModal ? (
                  <EmployeeStyledBoxModal
                    className={this.state.lang === "en" ?
                      classes.employeeprofilechangeLogoContainer : classes.employeeprofilechangeLogoContainerAr
                    }
                    style={this.renderLogoContainerStyle()}
                  >
                    <img
                      className={
                        this.state.lang === "en" ?
                          classes.employeeprofiledialogBoxIcon : classes.employeeprofiledialogBoxIconAr}
                      src={dialogBoxIcon}
                      
                      alt=""
                    />
                    <Box
                      style={
                       this.renderLogoInnerContainerStyle()
                      }
                      onMouseEnter={this.emphandleMouseEnterChangeLogo}
                      component="label"
                      onMouseLeave={this.emphandleMouseLeaveChangeLogo}
                    >
                      <input
                        data-test-id="handleSelectFiles"
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={this.employeehandleFile}
                      />
                      <img
                        style={webStyle.galleryIcon}
                        src={galleryIcon}
                        alt=""
                      />
                      <Typography
                        style={webStyle.changeLogoText}
                        variant="body1"
                      >
                       {t("boProfile.changeLogo")} 
                      </Typography>
                    </Box>
                    <Box
                      data-test-id="removeImage"
                      style={
                       this.renderLogoInnerContainerStyle()
                      }
                      onClick={this.employeehandleDeleteUploadedLogoPrecallback}
                      onMouseEnter={this.emphandleMouseEnterDeleteLogo}
                      onMouseLeave={this.emphandleMouseLeaveDeleteLogo}
                    >
                      <img
                        style={webStyle.galleryIcon}
                        src={deleteLogoIcon}
                        alt=""
                      />
                      <Typography
                        style={webStyle.removeImageText}
                        variant="body1"
                      >
                        {t("boProfile.removeImage")} 
                      </Typography>
                    </Box>
                  </EmployeeStyledBoxModal>
                ) : null}
              </Box>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                {t("landingPage.ContactUs.firstName")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  name="firstName"
                  value={firstName}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.firstName)}
                  inputProps={{ className: classes.textFielsFonts }}
                />
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.firstName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                 {t("landingPage.ContactUs.lastName")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="lastName"
                  value={lastName}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.lastName)}
                  inputProps={{ className: classes.textFielsFonts }}
                />
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.lastName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                  {t("landingPage.ContactUs.email")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your email address"
                  name="email"
                  value={email}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.email)}
                  inputProps={{ className: classes.textFielsFonts }}
                  type="email"
                />
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.email}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                 {t("landingPage.ContactUs.phoneNumber")}
                </Typography>

                <Box style={webStyle.profilePhoneContainer}>
                  <AutocompleteV1
                    id="country-select-demo"
                    options={countryCodes}
                    style={{ width: "34%" }}
                    value={
                      this.state.countryCode && this.state.countryCode !== "" &&
                        this.state.countryCode?.toString().charAt(0) != "+"
                        ? "+" + this.state.countryCode?.toString()
                        : this.state.countryCode?.toString()
                    }
                    onChange={this.emphandleChangeCountryCode}
                    autoHighlight
                    renderInput={this.renderAutoCompleteInputEmployeeProfile.bind(
                      this
                    )}
                  />

                  <InputBase
                    data-test-id="formDetails"
                    fullWidth
                    
                    className={
                      this.state.lang === "ar" ?
                      `${classes?.EmpprofileInputBase} ${classes?.EmpprofileInputBaseAr}` :
                      classes?.EmpprofileInputBase
                    }
                    style={webStyle.profileInputBase}
                    name="phone"
                    
                    value={phone}
                    onChange={this.employeehandleChangeFormDetails}
                    error={Boolean(this.state.isErrorFormDetails.phone)}
                  />
                </Box>
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.phone}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                 {t("boProfile.streetAddress")}
                </Typography>

                <Autocomplete
                  onPlaceChanged={this.onPlaceChangedEmp}
                  onLoad={this.onLoadEmp}
                >
                  <ProfileStyledTextFields
                    data-test-id="formDetails"
                    fullWidth
                    variant="outlined"
                    name="streetAddress"
                    value={streetAddress}
                    error={Boolean(this.state.isErrorFormDetails.streetAddress)}
                    onChange={this.employeehandleChangeFormDetails}
                    inputProps={{ className: classes.textFielsFonts }}
                  />
                </Autocomplete>
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.streetAddress}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                  {t("customers.siteDialog.city")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="city"
                  error={Boolean(this.state.isErrorFormDetails.city)}
                  value={city}
                  onChange={this.employeehandleChangeFormDetails}
                  inputProps={{ className: classes.textFielsFonts }}
                />
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.city}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                   {t("customers.siteDialog.country")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="country"
                  value={country}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.country)}
                  inputProps={{ className: classes.textFielsFonts }}
                />
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.country}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                  {t("customers.siteDialog.postCode")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="postcode"
                  value={postcode}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.postcode)}
                  inputProps={{ className: classes.textFielsFonts }}
                />
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.postcode}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.contactForm}
                  variant="body1"
                  gutterBottom
                >
                 {t("customers.siteDialog.language")}
                </Typography>
                <EmployeeStyledTextField
                  fullWidth
                  variant="outlined"
                  defaultValue="english"
                  languageSelected={this.state.lang}
                  error={Boolean(this.state.isErrorFormDetails.language)}
                  value={this.state.employeeformDetails.language}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          style={
                            !this.state.toggleLanguageModal
                              ? webStyle.contactUsDropDown
                              : {
                                ...webStyle.contactUsDropDown,
                                transform: "rotate(180deg)",
                              }
                          }
                          src={profileDropDown}
                          alt=""
                        />
                      </InputAdornment>
                    ),
                    className: classes.textFielsFonts,
                  }}
                  onClick={this.emphandleLanguageModalOpen}
                />
                <Typography variant="body2" style={webStyle.errorMessage}>
                  {this.state.isErrorFormDetails.language}
                </Typography>
                {this.state.toggleLanguageModal ? (
                  <Typography ref={this.wrapperRef}>
                    <Box style={webStyle.toggleModalContainer}>
                      <Box style={webStyle.toggleModalInnerContainer}>
                        {["English", "Arabic"].map((name, index) => (
                          <Box key={name}>
                            <EmployeeStyledMenuItem
                              disableRipple
                              style={webStyle.menuItem}
                              value={name}
                            >
                              <Typography
                                onClick={this.handleEmployeeLanguage.bind(
                                  this,
                                  name
                                )}
                              >
                                 {t(`boProfile.language.${index}`)}
                              </Typography>
                            </EmployeeStyledMenuItem>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.employeerenderSave}>
              <Grid
                container
                className={classes.employeebtnProfile}
                spacing={2}
              >

                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "end", marginTop: "1rem",
                    maxWidth: "100%",
                  }}
                >
                  <EmployeeProfileStyledButton
                    data-test-id="btnEmailLogin"
                    onClick={this.emphandleDetailsSubmit}
                    className={classes.saveButton}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.saveButtonText}
                    >
                     {t("boProfile.save")}
                    </Typography>
                  </EmployeeProfileStyledButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  renderAutoCompleteInputEmployeeProfile = (params: any) => (
    <EmployeeStyledTextFieldsAutoComplete
      {...params}
      languageSelected={this.state.lang}
      fullWidth
      variant="outlined"
    />
  );

  handleEmployeeLanguage = (name: any) => {
    this.emphandleLanguageDropdown(name);
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,t }: any = this.props;

    return (
      <>
        <DashboardSideBar
          permissions={undefined} oldProps={undefined}
          active={false}
          active1={false}
          active2={false}
          navMenuItem={t('dashboardNavbar.profile')}
          {...this.props}        />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "5rem",
            }}
            className={classes.boxContainer}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className={classes.employeerenderForm}
            >
              
              <Dialog
                open={this.state.profileUpdateSuccessful}
                maxWidth="lg"
                className={classes.paperRounded}
              >
                <DialogContent
                  className={classes.employeeprofileprofileUpdateSuccessfulBox}
                 
                >
                  <Grid container direction="column">
                  <Grid item  xs={12} className={classes.employeeProfileUpdateSuccessfulCross}>
                      <img
                        src={successModalCross}
                        alt="cross_icon"
                        onClick={this.employeehandleSuccessModal}
                        
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.employeeprofileUpdateSuccessfulTitle}
                      >
                         {t("boProfile.profileInformation")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.employeeprofileImageParent}>
                      <Grid item xs={7} className={classes.employeeprofileUpdateSuccessfulMessageParent}>
                        <Typography
                          variant="subtitle1"
                          className={classes.employeeprofileUpdateSuccessfulMessage}
                        >
                          {t("boProfile.successMsg")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}   
                      className={
                        this.state.lang === "en" ?
                        classes.employeeProfileUpdateSuccessfulSideImageBackground
                        : classes.employeeProfileUpdateSuccessfulSideImageBackgroundAr
                        }>
                        
                       
                        <img
                          src={successModalImage}
                          alt="Success_icon"
                          className={
                            this.state.lang === "en" ? 
                            classes.employeeProfileUpdateSuccessfulSideImage: 
                            classes.employeeProfileUpdateSuccessfulSideImageAr
                          }
                        />
                      
                    </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>
              {this.renderEmployeeForm(classes)}
            </Grid>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  employeerenderSave:{
    maxWidth: "100%",
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  
  backIconMobile: {
    width: "12px",
    height: "24px",
  },
  employeebtnProfile:{
    display:"flex",
    flexDirection:"column" as const,
    alignItems:"flex-end" as const,
    [theme.breakpoints.down("xs")]: {
      alignItems:"normal",
    },

  },
  employeeprofileImageParent:{
    display:"flex",
    flexDirection:"row" as const,
    justifyContent:"space-between",
    alignItems:"baseline" as const
    },
  employeerenderForm: {
    flexWrap: "nowrap" as const,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap" as const
    },
  },
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  EmpprofileInputBase:{
    fontSize: "1.1667rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
  },
  EmpprofileInputBaseAr:{
    borderRadius: "8px 0 0 8px !important",
  },
  employeemainContV1:{
  
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  employeemainCont:{
    width: "75%",
    padding: "20px",
    borderLeft: "1px solid #e4e4e4",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  employeeprofileTitle: {
    height: "28px",
    color: CONSTANTS.color.lightBrown,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: "30px"
  },
  companyLogo: {
    width: "156px",
    height: "156px",
    marginTop: "35px",
    //background:  url(image_Bitmap Copy.png)  no-repeat;
  },
  editBadge: {
    width: "18px",
    height: "18px",
    marginTop: "25%",
    marginLeft: "25%",
    //background:  url(image_Pencil.png)  no-repeat;
  },
  editBadgeOval: {
    width: "40px",
    height: "40px",
    background: "#e9e9ff",
    backgroundBlendMode: "normal",
    borderRadius: "50%",
  },
  saveButton: {
    width: "200px",
    height: "67px",
    borderRadius: "10px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    textAlign: "end" as const,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "327px",
      maxHeight:"48px",
    },
  },
  saveButtonText: {
    width: "75px",
    height: "32px",
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
  },
  textFielsFonts: {
    height: "21px",
    color: "#666666",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  employeeprofileprofileUpdateSuccessfulBox: {
    width: "600px",
    height: "262px",
    borderRadius: "20px",
    background: "#eeffe4",
    backgroundBlendMode: "normal",
    boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
    padding:"20px !important",
    [theme.breakpoints.down("xs")]: {
      width: "256px",
    },
  },
  employeeprofileprofileUpdateSuccessfulTitle: {
    color: "#0b9500",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "33px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem !important"
    },
  },
  employeeprofileUpdateSuccessfulMessageParent:{
    alignSelf:"center"
  },
  employeeprofileUpdateSuccessfulMessage: {
    color: "#3e3e3e",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "33px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem !important"
    },
  },
  employeeProfileUpdateSuccessfulSideImageBackground: {
    width: "100%",
    height: "150px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 20px 56px rgba(24,55,5,0.07)",
    borderRadius: "50%",
    marginLeft: "20%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      height: "95px",
      marginTop:"0.5rem"
    },
  },
  employeeProfileUpdateSuccessfulSideImageBackgroundAr: {
    width: "100%",
    height: "150px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 20px 56px rgba(24,55,5,0.07)",
    borderRadius: "50%",
    marginRight: "20%",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      height: "95px",
      marginTop:"0.5rem"
    },
  },
  employeeProfileUpdateSuccessfulSideImage: {
    width: "80px",
    height: "80px",
    marginLeft: "29%",
    marginTop: "20%",
    [theme.breakpoints.down("xs")]: {
      width: "58px",
      height: "58px",
      marginLeft: "19%",
      marginTop: "19%",
    },
  },

  employeeProfileUpdateSuccessfulSideImageAr: {
    width: "80px",
    height: "80px",
    marginRight: "29%",
    marginTop: "20%",
    [theme.breakpoints.down("xs")]: {
      width: "58px",
      height: "58px",
      marginRight: "14%",
      marginTop: "19%",
    },
  },
  employeeProfileUpdateSuccessfulCross: {
    alignSelf: "flex-end"
  },
  employeeprofileUpdateSuccessfulTitle: {
    color: "#0b9500",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "33px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem !important"
    },
  },
  paperRounded: {
    "& .MuiPaper-rounded": {
      borderRadius: "20px",
    },
  },
  empsideBarTitle: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 0 2rem",
      marginLeft: "-0.2rem",
    },
  },
  empsideBarTitleAr: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 2rem 0 0 !important",
      marginRight: "-0.2rem !important",
    },
  },
  employeeprofiledialogBoxIcon: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "8%",
    transform: "translate(-50%, -50%)",
  },
  employeeprofiledialogBoxIconAr: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "80%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("xs")]: {
      left: "70% !important",
    },
  },
  employeeprofilechangeLogoContainer: {

    transform: "translate(20%, 10%)",

  },
  employeeprofilechangeLogoContainerAr: {

    transform: "translate(-20%, 10%)",

  },
});

const webStyle = {

  employeeprofileeditIcon: {
    cursor: "pointer",
    height: "36px",
    width: "36px",
    transform: language === 'ar' ? "translate(54%, 0%)" : "translate(-58%, 0%)",
  },
  profileInputBase: {
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight:CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px",
    width: "100%",
    border: "0.5px solid lightgrey",
    borderRadius: "0 8px 8px 0",
    color:CONSTANTS.color.midGrey
  },
  profilePhoneContainer: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #dedede",
    display: "flex",
    minHeight: "56px",
  },
  gridItems: {
    marginTop: "1rem",
  },
  employeeprofilechangeLogoContainer: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    width: "85%",
    maxWidth: "270px",
    minHeight: "125px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    
    zIndex: 1,
  },
  employeeprofilechangeLogoContainerAr: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    width: "85%",
    maxWidth: "270px",
    minHeight: "125px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
   
    zIndex: 1,
  },
  employeeprofiledialogBoxIcon: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "8%",
    transform: "translate(-50%, -50%)",
  },
  employeeprofiledialogBoxIconAr: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "80%",
    transform: "translate(-50%, -50%)",
  },
  employeeprofilechangeLogoInnerContainer: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "1rem",
  },
  galleryIcon: {
    width: "24px",
    height: "24px",
  },
  changeLogoText: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  removeImageText: {
    color: CONSTANTS.color.crimsonRed,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  contactForm: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
  },
  menuItem: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  errorMessage: {
    marginTop: "5px",
    color:  CONSTANTS.color.red,
  },
  contactUsDropDown: {
    width: "14px",
    height: "8px",
    cursor: "pointer",
  },
  toggleModalContainer: {
    position: "absolute" as "absolute",
    zIndex: 9,
    width: "85%",
    maxWidth: "470px",
    minHeight: "125px",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
  },
  toggleModalInnerContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    flexWrap: "wrap" as "wrap",
    padding: "1rem",
  },
};

const EmployeeProfileRouter = withTranslation()(withStyles(styles)(withWidth()(EmployeeProfile)));
// @ts-ignore
const EmployeeProfileOpenToast = withRouter(EmployeeProfileRouter);
const EmpProfileSnackBar = withSnackbar(EmployeeProfileOpenToast);
export default withPermission(EmpProfileSnackBar)
// Customizable Area End
