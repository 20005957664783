// Customizable Area Start
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import { setStorageData } from "framework/src/Utilities";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
}

interface SS {
  id: any;
}

export default class TimesheetManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  authenticateApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (this.authenticateApiCallId === apiRequestCallId) {
        if (
          responseJson &&
          responseJson.meta &&
          !responseJson.error &&
          !responseJson.errors
        ) {
          this.setState({
            token: responseJson.meta.token,
          });
          setStorageData("TOKEN", responseJson.meta.token);
          setStorageData("account_Id", responseJson.meta.id.toString());
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        } else {
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage),
          );
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  openTimeSheets = async () => {
    if (this.state.token !== "") {
      this.props.navigation.navigate("TimeSheetTimeManagementList");
    }
  };

  openTasks = async () => {
    if (this.state.token !== "") {
      this.props.navigation.navigate("TimeSheetTaskList");
    }
  };

  async componentDidMount() {
    await super.componentDidMount();
    NetInfo.addEventListener((state: NetInfoState) => {
      if (state.isConnected && this.state.token === "") {
        this.login();
      }
    });
  }

  login = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.email,
          password: configJSON.securedText,
        },
        type: "email_account",
      },
    };

    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.authenticateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins",
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

// Customizable Area End
