import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData,setStorageData } from "../../../framework/src/Utilities";
import { performInvoiceAuthorizationCheck, performInvoiceDeleteAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
import { checkTokenExpired, filterListData } from "../../../components/src/CommonFunction";
import { WithStyles } from "@material-ui/core"
import React from "react";
import DatePicker from "react-datepicker";
export interface ToggleDetails {
    id?: number,
    title?: string,
    hidden?: boolean
}

interface QuoteStatusData {
    id:number,
    title:string,
    statusName:string
}
export interface ProductItem {
    id: string;
    type: string;
    attributes: {
      product_id: number | null;
      service_id: number | null;
      quantity: number;
      unit_price: string;
      currency: string | null;
      total: string;
      description: string;
      job_id: string | null;
      quote_id: number;
      visit_id: string | null;
      product_name: string;
      files: any[] | null;
    };
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    location: any;
    openToastHandler: any;
    t: (key: string) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    deleteToaster: boolean;
    statusModal: boolean;
    toggleModal: boolean;
    status: string;
    getQuotesLoading: boolean;
    sortDirection: any;
    sortColumn: any;
    quoteToggleData: any;
    searchQuote: any;
    quotesList: any;
    statusName: string;
    checkedItems:string[],
    deleteRowItem: boolean
    checkStatus: string
    hardDeleteStatus: boolean
    allQuotesList: any,
    deleteIconStatus: boolean
    selectRowId: string,
    deleteToasterStatus: boolean;
    toaster: boolean;
    toasterText: string,
    enableDeleteIcon: boolean
    deleteQuoteToasterStatus: boolean,
    hoverRow: number,
    selectLanguage:string
    anchorEl:any,
    anchorId:any;
    productsData:any,
    popoverHeading:string
    selectedDate: {
        startDate: Date,
        endDate: null | Date
    },
    dateStatus: string,
    dateModal: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    datePickerRef: React.RefObject<DatePicker>
    // Customizable Area End
}

export default class QuotesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdGetAllStatusQuotes: string = "";
    apiCallIdDeleteQuote: string = "";
    datePickerRef: React.RefObject<DatePicker>
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        const QuoteToggleData = [
            { id: 1, title: `${this.props.t('quote.title')}`, hidden: true },
            { id: 2, title: `${this.props.t('invoice.menuLabel.cusName')}`, hidden: true },
            { id: 3, title: `${this.props.t('quote.expirationDate')}`, hidden: true },
            { id: 4, title: `${this.props.t('quote.productName')}`, hidden: false },
            { id: 5, title: `${this.props.t('quote.serviceName')}`, hidden: false },
            { id: 6, title: `${this.props.t('quote.quoteDate')}`, hidden: true },
            { id: 7, title: `${this.props.t('quote.amount')}`, hidden: true }
        ]
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            popoverHeading:"",
            productsData:[],
            anchorEl:null,
            anchorId:"",
            deleteToaster: true,
            statusModal: false,
            toggleModal: false,
            status: "all",
            checkedItems:["all","pending","converted","sent","overdue","cancelled"],
            getQuotesLoading: false,
            sortDirection: "asc",
            sortColumn: "",
            quoteToggleData: QuoteToggleData,
            searchQuote: "",
            quotesList: [],
            statusName: "All",
            deleteRowItem: false,
            checkStatus: "All",
            hardDeleteStatus: false,
            allQuotesList: [],
            deleteIconStatus: false,
            selectRowId: "",
            deleteToasterStatus: false,
            toaster: false,
            toasterText: "",
            enableDeleteIcon: false,
            deleteQuoteToasterStatus: false,
            hoverRow: 0,
            selectLanguage:'',
            selectedDate: {
                startDate: new Date(),
                endDate: null
            },
            dateStatus: `${this.props.t('dashboard.today')}`,
            dateModal:false
        };
        this.datePickerRef = React.createRef<DatePicker>()
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        window.scrollTo(0, 0);
        const selectedLanguage = await getStorageData("lang");
        this.setState({ selectLanguage: selectedLanguage })
        if (!localStorage.getItem("authToken")) {
            this.props.history.push("Login");
        }
        this.sortQuotesSection()
        this.setState({ getQuotesLoading: true });
        const archiveQuoteToaster = await getStorageData("archiveQuoteToaster");
        await removeStorageData("archiveQuoteToaster");
        if (archiveQuoteToaster) {
            this.setState({ toaster: true, toasterText: `${this.props.t('quote.toasterMsg.delete')}` },()=>{
                this.props.openToastHandler(this.state.toasterText,'success')
            });
        }
        const statuse = localStorage.getItem("status")
        if(statuse){
            this.setState({ dateStatus: statuse })
        }else{
            this.setState({ dateStatus: `${this.props.t('dashboard.today')}` })
        }
        this.sortQuotesSection()
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.apiCallIdGetAllStatusQuotes) {
            this.handleStatusQuotesApiResponce(responseJson);
        }
        if (apiRequestCallId === this.apiCallIdDeleteQuote) {
            this.handleDeleteQuoteApiResponce(responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start 


    handleStatusQuotesApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            if (responseJson.message === "you are not authorized") {
                return this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
            }
            this.setState({ getQuotesLoading: false })
            this.setState({ allQuotesList:responseJson }, () => {
                this.handleStatusRequestModalClose()
            })

        } else if (responseJson.errors[0].message === "No Quotes found.") {
            this.setState({ allQuotesList: [] });
        } else {
            checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        }
    }

    handleDeleteQuoteApiResponce = (responceJson: any) => {
        if (responceJson && responceJson.message) {
            this.setState({ getQuotesLoading: false })

            this.setState({
                toaster: true,
                toasterText: `${this.props.t('quote.toasterMsg.permanentDeleted')}`,
                hardDeleteStatus: false
            })
            this.props.openToastHandler(this.state.toasterText,'success')

            this.sortQuotesSection()
        }
    }

    deleteQuote = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const apiEndPoint = configJSON.quoteEndPoint
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        this.setState({ getQuotesLoading: true })
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.apiCallIdDeleteQuote = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${apiEndPoint}/${this.state.selectRowId}?lang=${this.state.selectLanguage}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Api calls Ended
    handleChangeSearchRequest = (event: any) => {
        this.setState({ searchQuote: event.target.value }, () => {
            this.sortQuotesSection();
        });
    }

    handleStatusRequestModalOpen = () => {
        this.setState({ statusModal: !this.state.statusModal });
        this.setState({ toggleModal: false });
    }

    handleStatusRequestModalClose = () => {
        this.setState({ statusModal: false });
    }

    handleToggleRequestModalOpen = () => {
        this.setState({ toggleModal: !this.state.toggleModal });
        this.setState({ statusModal: false });
    }

    handleToggleRequestModalClose = () => {
        this.setState({ toggleModal: false });
    }

    handleSelectStatus = (item: QuoteStatusData, quoteStatusData: QuoteStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.checked) {
            if (item.statusName === "all") {
                this.setState({
                    checkedItems: quoteStatusData.map((subItem: QuoteStatusData) => subItem.statusName),
                    status: "all"
                }, this.sortQuotesSection)
            } else {
                this.setState({
                    checkedItems: [...this.state.checkedItems,
                    item.statusName
                    ],
                    status: [...this.state.checkedItems,
                    item.statusName
                    ].join(",")
                }, this.sortQuotesSection)
            }
        } else {
            if (item.statusName === "all") {
                this.setState({
                    checkedItems: [],
                    status: ""
                }, this.sortQuotesSection)
            } else {
                this.setState({
                    checkedItems: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
                    status: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
                }, this.sortQuotesSection)
            }
        }
    }
    handleDateModalOpen = () => {
        this.setState({ dateModal: !this.state.dateModal });
    }
    handleStatusModalClose = () => {
        const { t } = this.props;
        if (this.state.dateStatus !== t('dashboard.custom')) {
            this.setState({ dateModal: false });
        }
    }
    handleDateChange = (dates: [Date, null]) => {
        const [startDate, endDate] = dates;
        this.setState({
            selectedDate: { ...this.state.selectedDate, startDate: startDate, endDate: endDate },
        }, () => {
            if (startDate && endDate) {
                this.sortQuotesSection();
                this.setState({ getQuotesLoading: true });
                this.setState({ dateModal: false });
            }
        });
    };
    handleSelectDateStatus = (item: { title: string; }) => {
        const { t } = this.props;
        const { startDate, endDate } = this.state.selectedDate;
        setStorageData("status",item.title)
        this.setState({ dateStatus: item.title }, () => {
            if (item.title === t('dashboard.custom')) {
                if (startDate && endDate) {
                    this.sortQuotesSection();
                    this.setState({ getQuotesLoading: true });
                }
            } else {
                this.sortQuotesSection();
                this.setState({ getQuotesLoading: true });
            }
        });
        if (item.title !== t('dashboard.custom')) {
            this.handleStatusModalClose()
        }
    }

    handleSortRequest = (columnKey: any) => {
        if (!(columnKey === "product_quantity" || columnKey === "product_name")) {
            let newSortDirection: "asc" | "desc" = "asc";
            if (columnKey === this.state.sortColumn) {
                newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
            }
            this.setState({
                sortDirection: newSortDirection,
                sortColumn: columnKey
            }, () => {
                this.sortQuotesSection()
            });
        }
    };
    statusAr = (status: string) => {
        let statusAr;
        if (status === "اليوم") {
            statusAr = "today";
        } else if (status === "هذا الاسبوع") {
            statusAr = "this_week";
        } else if (status === "هذا الشهر") {
            statusAr = "this_month";
        } else if (status === "هذا العام") {
            statusAr = "this_year";
        } else if (status === "مخصص") {
            statusAr = "custom";
        }
        return statusAr;
    }
    sortQuotesSection = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const language = localStorage.getItem("lang");
        const { startDate, endDate } = this.state.selectedDate;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        const dateStatus = language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus&&this.state.dateStatus.toLowerCase().replace(/ /g, "_")
        // this.setState({ getQuotesLoading: true })
        const apiEndPoint = configJSON.getQuoteEndPoint
        let quoteListUrl
        if (this.state.searchQuote && this.state.sortColumn) {
            quoteListUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchQuote}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${dateStatus}`
        } else if (this.state.searchQuote) {
            quoteListUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchQuote}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${dateStatus}`
        } else if (this.state.sortColumn) {
            quoteListUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${dateStatus}`
        } else {
            quoteListUrl = `${apiEndPoint}?status=${this.state.status}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${dateStatus}`
        }
        this.setState(
            { getQuotesLoading: true }
        )

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.apiCallIdGetAllStatusQuotes = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), quoteListUrl);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleToggleColumnRequest = (id: any) => {
        const newQuoteToggleData = this.state.quoteToggleData.map((item: any) => {
            if (item.id === id) {
                return { ...item, hidden: !item.hidden };
            }
            return item
        })

        this.setState({
            quoteToggleData: newQuoteToggleData,
        });
    }
    handleCloseToaster = () => {
        this.setState({ toaster: false });
    }

    handleViewQuoteData = (quoteDetails: { id: string }) => {
        if (this.state.statusName === "Cancelled") {
            this.handleSelectIcon(quoteDetails)
          } else {
            this.props.history.push("/Quote/create");
            localStorage.setItem("quote_id", quoteDetails.id);
        } 
    }
    handleSelectIcon = (quoteDetails: any) => {
        this.setState({
            enableDeleteIcon: true,
            selectRowId: quoteDetails.id,
        })

    }
    handleDeleteDailog = async (quoteId: string) => {
        const isAuthorized = await performInvoiceDeleteAuthorizationCheck("quote");
        this.handleDeleteQuoteButtonCallback(isAuthorized, quoteId);
    };

    handleDeleteQuoteButtonCallback = (isAuthorized: boolean, quoteId: string) => {
        if (!isAuthorized) {
            this.setState({
                hardDeleteStatus: true, selectRowId: quoteId,
            })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error")
        }
    }
    handleDeleteCloseDailog = () => {
        this.setState({
            hardDeleteStatus: false,
        })
    }

    handleCreateQuote = async () => {
        const isAuthorized = await performInvoiceAuthorizationCheck("quote");
        this.handleCreateQuoteCallback(isAuthorized)
    }

    handleCreateQuoteCallback = (isAuthorized: any) => {
        if (!isAuthorized) {
            localStorage.removeItem("quote_id");
            this.props.history.push("/Quote/create")
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }

    handleCloseToasterDelete = () => {
        this.setState({
            deleteQuoteToasterStatus: false
        })
    }
    handleBack = () => {
        this.props.navigation.history.goBack();
    }


    filterItems = (item:ProductItem[] | [], filterKey:string) => {
       
        const filteredData = item.filter((subItem:any) => subItem.attributes[filterKey]);
          return filteredData.length > 1 ?
        `${filteredData[0]?.attributes.product_name}..` : filteredData[0]?.attributes.product_name
    };
    
    hoverItems = (item:ProductItem[] | [], hoverKey:string) => {
       
        return item.filter((subItem:any) => subItem.attributes[hoverKey]);
    };


    handleTooltipOpen = (item: ProductItem[] | [], event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({
            anchorEl: this.hoverItems(item, "product_id").length > 1 ? event.currentTarget : null,
            anchorId: this.hoverItems(item, "product_id").length > 1 ? "product-popover" : "",
            productsData: this.hoverItems(item, "product_id"),
            popoverHeading: this.props.t("quote.productName")
        },
        )
    };
    handleTooltipOpenService = (item: ProductItem[] | [], event:React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({
            anchorEl: this.hoverItems(item, "service_id").length > 1 ? event.currentTarget : null,
            anchorId: this.hoverItems(item, "service_id").length > 1 ? "product-popover" : "",
            productsData: this.hoverItems(item, "service_id"),
            popoverHeading:this.props.t("quote.serviceName")
        },
        )
    };
    handleTooltipClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({ anchorId: "", anchorEl: null, popoverHeading:"",productsData:[] })
    }

   
}
// ?field=quote_number&order=ASC


// Customizable Area End