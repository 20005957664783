import React from "react";

// Customizable Area Start
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { CONSTANTS } from "../../../components/src/theme";
import { ArcheiveButton, CancelButton, OutlinedButton } from "../../../components/src/CustomizableComponents.web";

// Customizable Area End

// Customizable Area Start
export const StyledDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialogTitle-root": {
      padding: "16px 24px 12px",
      [theme.breakpoints.down("xs")]: {
        padding: "16px 8px 8px !important",
      }
    },
    "& .MuiDialogContent-root": {
      padding: "0px 24px 8px",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 8px 8px !important",
      }
    },
    "& .MuiPaper-root": {
      padding: "1.5rem",
      [theme.breakpoints.down("xs")]: {
        padding: "1rem !important",
      },
      width: "100%",
      maxWidth: "640px",
      minHeight: "263px",
      [theme.breakpoints.down("xs")]: {
        minHeight: "230px !important",
      },
      borderRadius: "8px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    }
  }
}))(Dialog);

const dialogTitleStyles = (theme: Theme) => ({
  root: {
    "& .MuiTypography-h6": {
      color: CONSTANTS.color.lightBrown,
      fontSize: "1.8741rem",
      fontWeight: CONSTANTS.fontWeight.fontWeight500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.6867rem !important"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.4375rem !important"
      },
    }
  }
});
export const StyledDialogTitle = withStyles(dialogTitleStyles)(DialogTitle);
interface Props {
  title: string;
  description: string;
  deleteDialogOpen: boolean;
  handleClickOpenDialog: boolean;
  handleCloseDialog: boolean;
  handleClickProceedDelete: Function;
}
// Customizable Area End

export class DeleteDialog extends React.Component<any, Props, any> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes, t }: any = this.props;
    return (
      <>
      {this.props.status !== 'site' &&
        <ArcheiveButton
          style={{ maxWidth: "200px" }}
          className={`${classes.buttonStyleMobile} ${classes.buttonContainerArchive}`}
          onClick={this.props.handleClickOpenDialog}
        >
          <Typography
            style={webStyle.buttonTextCancel}
            className={classes.buttonText}
            variant="subtitle2"
          >
           {this.props.status === "active" ? t("suppliers.archive") : t("Calendar.button.delete")}
          </Typography>
        </ArcheiveButton>
  }
        <StyledDialog
          open={this.props.deleteDialogOpen}
          onClose={this.props.handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <CloseIcon
            style={webStyle.closeIcon}
            onClick={this.props.handleCloseDialog}
          />
          <StyledDialogTitle id="responsive-dialog-title">
            {this.props.title}
          </StyledDialogTitle>
          <DialogContent>
              <Typography style={webStyle.dialogContentStyles} variant="subtitle2">
                {this.props.description}
              </Typography>
          </DialogContent>
          <DialogActions style={{gap:"1rem"}}>
            <CancelButton
              style={{maxWidth: "162px"}}
              className={`${classes?.buttonMobile} ${classes.btnBoxCancel} ${classes?.buttonStyleCommon}`}
              onClick={this.props.handleCloseDialog}
            >
              <Typography
                style={webStyle.buttonTextCancel}
                className={classes.buttonTextDialog}
                variant="subtitle2"
              >
               {t("Calendar.button.cancel")}
              </Typography>
            </CancelButton>
            <OutlinedButton
              style={{maxWidth: "162px"}}
              className={`${classes?.buttonMobile} ${classes.buttonSaveSuppliers} ${classes?.buttonStyleCommon}`}
              onClick={this.props.handleClickProceedDelete}
            >
              <Typography
                style={webStyle.buttonTextCancel}
                className={classes.buttonTextDialog}
                variant="subtitle2"
              >
                {t("landingPage.StyleDialog.proceed")} {this.props.loader}
              </Typography>
            </OutlinedButton>
          </DialogActions>
        </StyledDialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  buttonContainerCancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "200px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.lightGrey}`,
  
    textTransform: "none" as const
  },
  dialogContentStyles:{
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  buttonTextCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  closeIcon: {
    position: "absolute" as const,
    right: "10px",
    top: "30px",
    transform: "translate(-50%, -50%)",
    cursor: "pointer"
  },
}

export default DeleteDialog;
// Customizable Area End