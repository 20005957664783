import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  openToastHandler: any;
  t:(key: string) => string;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  industries: any;
  companySize: any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BussinessController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  industriesListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      industries: [],
      companySize: [
  
        {
          id: 1,
          name: this.props.t('Bussiness.employess.1_5'),
          value: "1-5 employees"

          
        },
        {
          id: 2,
          name: this.props.t('Bussiness.employess.6_20'),
          value: "6-20 employees"

        },
        {
          id: 3,
          name:this.props.t('Bussiness.employess.21_100'),
          value: "21-100 employees"

        },
        {
          id: 4,
          name: this.props.t('Bussiness.employess.101_500'),
          value: "101-500 employees"

        },
        {
          id: 5,
          name: this.props.t('Bussiness.employess.more'),
          value: "500+ employees"

        },
        {
          id:6,
          name:this.props.t('Bussiness.employess.self'),
          value: "Self-employed"

        }
      ],
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getIndustriesList()

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.industriesListApiCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({ industries: responseJson?.data })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleKeyDownTextfield = (e: React.KeyboardEvent<HTMLDivElement>, handlebussinessForm: any, bussiness: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const inputElement = document.activeElement as HTMLInputElement;
      if (inputElement && bussiness?.industy_type) {
        handlebussinessForm(e);
        inputElement.blur();
      }
    }
  }
  
  getIndustriesList = async() => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const lang=await getStorageData('lang')

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.industriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.industries}?lang=${lang}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End