import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Typography,
  Radio,
  Tabs,
  Tab,
  Grid, Hidden,Theme
} from "@material-ui/core";
import {
  withStyles,
  
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withRouter } from "react-router-dom";
import { CreateCustomer } from "./CreateCustomer.web";
import { CustomerSites } from "./CustomerSites.web";
import { CustomerRequests } from "./CustomerRequests.web";
import { CustomerQuotes } from "./CustomerQuotes.web";
import { CustomerJobs } from "./CustomerJobs.web";
import { CustomerInvoices } from "./CustomerInvoices.web";
import { EditCustomer } from "./EditCustomer.web";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web"
import {CONSTANTS } from "../../../components/src/theme";
import { goBack } from "../../../components/src/CommonFunction";
import { mobileArrow } from "../../email-account-login/src/assets";
import { withTranslation } from "react-i18next";
const selectedLanguage =  localStorage.getItem("lang");
// Customizable Area End

import CustomersController, {
  Props,
} from "./CustomersController.web";


// Customizable Area Start
interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles((theme:Theme)=>({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      flexWrap: "wrap",
      gap: "12px",
      [theme.breakpoints.down('md')]:{
      flexWrap: "nowrap"
      }
    }
  },
  indicator: {
    display: 'none',
  },
}))((Tabs))

const StyledTab = withStyles((theme)=>({
  root: {
    minWidth: "0px",
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    position: "relative" as "relative",
    minHeight: "100%",
    borderRadius: "8px",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "46px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      textTransform: "capitalize" as "capitalize",
    },
    "&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
      marginBottom: "0px",
      color: "#05037a"
    },
  }
}))(Tab);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
export function mobileBackIcon(){
  return selectedLanguage === "ar" ? {...webStyle.backIconMobile, ...webStyle.transformStyle} : webStyle.backIconMobile
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// Customizable Area End

export class CustomerTabs extends CustomersController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes,t }  = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined}  {...this.props} navMenuItem={t("landingPage.Pricing.customers")} active={false} active1={1} active2={false} />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "9rem",
            }}
            className={classes.boxContainer}
          >
            <Container maxWidth={false}>

              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2} style={{paddingLeft:'1rem'}} >
                    <Grid item style={{marginRight:"10px",marginTop:"2px"}}>
                      <img src={mobileArrow}
                        data-test-id="tabs_back"
                        onClick={() => goBack(this.props.navigation)}
                        style={mobileBackIcon()} alt="mobile arrow" />
                    </Grid>
                    <Grid>
                      <Typography display="inline" style={webStyle.supplierText} variant="subtitle1">
                        {t("customers.customer")}
                        <span 
                          style={webStyle.customerNameText}
                        >
                          {localStorage.getItem("customerName") ? localStorage.getItem("customerName") : ""}
                          </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={'xs'}>
                <Typography style={webStyle.customerText} variant="subtitle1">
                  {t("customers.customer")}
                  <span style={webStyle.customerNameText}>
                    {localStorage.getItem("customerName") ? localStorage.getItem("customerName") : ""}
                  </span>
                </Typography>
              </Hidden>
               
              <Grid container style={webStyle.suppliersContainer}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                <StyledTabs
                  data-test-id="tabValues"
                  value={this.state.active}
                  variant="scrollable"
                  scrollButtons="off"
                  onChange={this.handleChange}
                  aria-label="simple tabs example"
                >
                  <StyledTab
                    style={{ minWidth: "174px" }}
                    label={t("customers.information")}
                    {...a11yProps(0)}
                    icon={<Radio color="primary" checked={this.state.active === 0 || this.state.active === 6} />}
                  />
                  <StyledTab
                    disabled={this.state.active == 0 && this.props?.location?.pathname === "/CreateCustomer"}
                    style={{ minWidth: "124px" }}
                    label={t("customers.sites")}
                    {...a11yProps(1)}
                    icon={<Radio color="primary" checked={this.state.active === 1} />}
                  />
                  <StyledTab
                    disabled={this.state.active == 0 && this.props?.location?.pathname === "/CreateCustomer"}
                    style={{ minWidth: "156px" }}
                    label={t("customers.requests")}
                    {...a11yProps(2)}
                    icon={<Radio color="primary" checked={this.state.active === 2} />}
                  />
                  <StyledTab
                    disabled={this.state.active == 0 && this.props?.location?.pathname === "/CreateCustomer"}
                    style={{ minWidth: "140px" }}
                    label={t("customers.quotes")}
                    {...a11yProps(3)}
                    icon={<Radio color="primary" checked={this.state.active === 3} />}
                  />
                  <StyledTab
                    disabled={this.state.active == 0 && this.props?.location?.pathname === "/CreateCustomer"}
                    style={{ minWidth: "124px" }}
                    label={t("customers.jobs")}
                    {...a11yProps(4)}
                    icon={<Radio color="primary" checked={this.state.active === 4} />}
                  />
                  <StyledTab
                    disabled={this.state.active == 0 && this.props?.location?.pathname === "/CreateCustomer"}
                    style={{ minWidth: "151px" }}
                    label={t("customers.invoices")}
                    {...a11yProps(5)}
                    icon={<Radio color="primary" checked={this.state.active === 5} />}
                  />
                </StyledTabs>
                </Grid>
              </Grid>
              <TabPanel value={this.state.active} index={0}>
                <CreateCustomer  width={"xs"} {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={1}>
                <CustomerSites  {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={2}>
                <CustomerRequests {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={3}>
                <CustomerQuotes {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={4}>
                <CustomerJobs {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={5}>
                <CustomerInvoices {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={6}>
                <EditCustomer  width={"xs"} {...this.props} oldProps={this} />
              </TabPanel>
            </Container>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  marginTopHeadings:{marginTop:"8px", backgroundColor: "#74ab39"},
  tableCellLayout:{
  
    padding: '28px 16px 28px 24px !important'
  },
  padding_16: {
    padding: '16px'
  },
  customersHeadLayoutMargin: {
    marginTop: '0.5rem'
  },
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important"
    }
  },
  createCustboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
    },
  },
  CreateCustinputBase:{
    fontSize: "1.1667rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
  },
  CreateCustinputBaseAr: {
    borderRadius: "8px 0 0 8px !important",
  },
  radioButtonWidth:{
    width:"73px"
  },
  radioButtonWidthAr:{
    width:"86px"
  },
  SitesboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
    },
  },
  SitesdropDownSelectIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    },
  },
  sitestableCellHead: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0625rem !important",
    },
  },
  RequesttableCellHead: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0625rem !important",
    },
  },
  tableCellHead: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0625rem !important",
    },
  },
  editBoxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
    },
  },
  editCustinputBase: {
    fontSize: "1.1667rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
  },
  editCustinputBaseAr: {
    borderRadius: "8px 0 0 8px !important",
  },
  quotesinputBase: {
    fontSize: "1.1667rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
  },
  boxContainerJobs: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "145px !important",
      minHeight: "52px !important",
    },
  },

  dropDownIconSelect: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    },
  },
  tableHeadTextJob: {
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },
  tableHeadSpanJob: {
    [theme.breakpoints.down("md")]: {
      margin: "0 3px !important",
    },
  },

  tableCellHeadJob: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
  },
  RequestboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
    },
  },
  RequestContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "145px !important",
      minHeight: "52px !important",
    },
  },

  RequestRequestdropDownSelectIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    },
  },

  RequesttableHeadText: {
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },

  RequesttableHeadSpan: {
    [theme.breakpoints.down("md")]: {
      margin: "0 3px !important",
    },
  },
  RequestinputBase: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    }
  },
  boxContainerQuotes: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
    },
  },
  tableCellHeadInvoice: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0625rem !important"
    },
  },
  dropDownSelectIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    },
  },
  CreateCustquoteContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  CreateCusthr: {
    height: "1px",
    border: "1px solid #e6e6e6",
    display: "flex",
    marginTop: "1rem",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  tableHeadTextQuotes: {
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },

  tableHeadSpan: {
    [theme.breakpoints.down("md")]: {
      margin: "0 3px !important",
    },
  },
  buttonTextInvoice: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
  },
  dropDownIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    },
  },

  tableHeadSpanInvoice: {
    [theme.breakpoints.down("md")]: {
      margin: "0 3px !important",
    },
  },
  tableHeadTextInvoice: {
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },
  mobileDivider: {
    marginTop: 20,
    marginBotom: 20
  },
  fieldDisable: {
    backgroundColor: "#F2F2F2",
    cursor: "default",
    pointerEvents: "none" as const
  },
  customerInputSubTitles: {
    color: "#999999",
    fontWeight: 400,
    marginBottom: "0.8rem",
    lineHeight: "16px",
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important'
    }
  },
  siteCancelBtnLayout: {
    marginRight: 30
  },
  fullwidthBtn: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',

    }
  },
  SitessearchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "930px",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  CreateCustbuttonContainerCancel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "10px",
    color: "#999999",
    border: "1px solid #999999",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: '#999999',
      color: theme.palette.common.white,
      cursor: "pointer"
    },
  },
  CreateCustbuttonContainerSave: {
    transition: 'background-color 0.3s, color 0.3s',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: "#1c18af",
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  siteCancelBtn: {
    maxWidth: "200px",
    width: "100%",
    minHeight: "67px",
    textTransform: "none" as const,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '129px',
      minHeight: 48
    }
  },
  whiteBg:{
    backgroundColor:CONSTANTS.color.white,
  },
  autoCompleteInput:{
    borderRadius: "10px",
    fontSize: "1.1667rem",
    fontFamily: "Rubik",
    fontWeight: 400,
    lineHeight: "16px",
    color:CONSTANTS.color.grey,
    width: "100%",
  },
  autoComplete:{
    width:"95%",
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    borderRadius: "8px",
  },
  paddingStyle: {
    [theme.breakpoints.down("xs")]: {
      padding: "5px !important"
    }
  },
  deleteTaxIcon: {
    [theme.breakpoints.down("xs")]: {
      width: "24px !important",
      height: "24px !important",
    }
  },
  deleteBoxStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "40px !important"
    }
  },
  remainderTextHeading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  remainderText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  bg:{
    background: "#d8d8d8",
  },
  lightBlue_bg:{
    backgroundColor: "#e6e6ff",
  },
   CreateCustradioButtonContainer: {
    width: "73px",
    height: "35px",
    borderRadius: "18px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      width: "88px",
      height: "40px",
    }
  },
  CreateCustradioButton: {
    width: "25px",
    height: "25px",
    [theme.breakpoints.down('xs')]: {
      width: "19px",
      height: "19px",
    }
  },
  buttonWidthMobile:{
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
    }
  },
  // supplier styles 
  editCustomerButtonLayout: {
    display: 'flex',
    width: "100%",
    gap:"1rem"
  },
  buttonStyleEditCommon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    textTransform: "none" as const,
  },
  buttonEditText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  buttonStyleMobileEdit: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important"
    }
  },
  buttonStyleEdit: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important",
      marginBottom: "1rem !important",
    }
  },
  buttonContainerEdit: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 !important",
      display: "block !important",
    }
  },
  autoCompletedetails:{
    position: "absolute" as const, 
    top: 10, 
    left: 9, 
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
     left:7
    }
  },
  closeButton: {
    position: 'absolute' as const,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#999999',
    width: "15px",
    height: "16px",
},
display: {
    display: "flex"
},
createBtn: {
    borderRadius: "8px",
    backgroundBlendMode: "normal",
    background: "#1c18af",
    height: "56px",
    color: "#ffffff",
    fontFamily: "Rubik",
    fontSize: "17px",
    fontWeight: 600,
    "&:hover": {
        background: "#1c18af",
        color: "#ffffff",
    }

},
searchLayout: {
  paddingTop: "2rem",
  paddingBottom: "2rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  flexBasis: 0
},
searchLayoutChild: {
  [theme.breakpoints.down("sm")]: {
      gap: "1rem"
  }
},
activeText: {
  color: "#1c18af",
  fontFamily: "Rubik",
  fontSize: "17px",
  fontWeight: 400,
},
activeDivider: {
  background: "#1c18af"
},
paper: {
  width: "530px",
  height: "600px",
  borderRadius: "10px",
  [theme.breakpoints.up("xl")]: {
      width: "634px",
      height: "678px",
      borderRadius: "10px",
  }
},
siteText: {
  fontfamily: "rubik",
  fontSize: "18px"
},
siteNameLayout: {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "15px",
  cursor: "pointer"
},
customerName: {
  textTransform: "capitalize" as const,
  fontSize: "18px",
  fontFamily: "Rubik"
},
titleText: {
  minHeight: "28px",
  color: "#34313a",
  fontFamily: "Rubik",
  fontSize: "24px",
  fontWeight: 500
}

});

const webStyle = {
  customerText: {
    color: "#2b4038",
    fontWeight: 500,
  },
  backIconMobile: {
    width: "12px",
    height: "24px",
  },
  transformStyle: {
    transform: "rotate(180deg)"
  },
  customerNameText:{
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    marginLeft:"5px",
    fontSize:"revert"
  },
  navHeading: {
    display: "flex",
    alignItems: "center",
  },
  navText: {
    color: "#909091",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  supplierText: {
    color: "#535353",
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 500,
  },
  suppliersContainer: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
  },
  radioIconFilled: {
    height: "14px",
    width: "14px",
    position: "absolute" as "absolute",
    transform: "translate(-50%, -50%)",
    left: "16.5%",
    top: "50%",
  },
  informationText: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  }
}

const customerRouter = withStyles(styles)(CustomerTabs);
const customerSnackBar = withSnackbar(customerRouter)
const customerPermission = withPermission(customerSnackBar)
const customerTranslation = withRouter(customerPermission);
export default withTranslation()(customerTranslation)

// Customizable Area End