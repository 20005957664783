// index.js - WEB
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { PermissionsProvider } from '../../components/src/Permissioncontext';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import './i18n.js';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '../../components/src/theme';
ReactDOM.render(
  <Suspense fallback={<div>Loading translations...</div>}>
    <ThemeProvider theme={theme}>
      <PermissionsProvider>
        <Router>
          <App />
        </Router>
      </PermissionsProvider>
    </ThemeProvider>
  </Suspense>,
  document.getElementById('root')
);
registerServiceWorker();
