export const lamsaLogo = require("../assets/lamsaLogo.png");
export const createIcon = require("../assets/createIcon.png");
export const dashboardIcon = require("../assets/dashboardIcon.png");
export const tasksIcon = require("../assets/tasksIcon.png");
export const requestsIcon = require("../assets/requestsIcon.png");
export const jobsIcon = require("../assets/jobsIcon.png");
export const mapsIcon = require("../assets/mapsIcon.png");
export const invoicesIcon = require("../assets/invoicesIcon.png");
export const quotesIcon = require("../assets/quotesIcon.png");
export const calendarIcon = require("../assets/calendarIcon.png");
export const timesheetIcon = require("../assets/timesheetIcon.png");
export const suppliersIcon = require("../assets/suppliersIcon.png");
export const customersIcon = require("../assets/customersIcon.png");
export const employeesIcon = require("../assets/employeesIcon.png");
export const reportsIcon = require("../assets/reportsIcon.png");
export const settingsIcon = require("../assets/settingsIcon.png");
export const dropDownIcon = require("../assets/dropDownIcon.png");
export const searchIcon = require("../assets/searchIcon.png");
export const notificationIcon = require("../assets/notificationIcon.png");
export const questionMarkIcon = require("../assets/questionMarkIcon.png");
export const userIcon = require("../assets/userIcon.png");
export const notificationLength = require("../assets/notificationLength.png");
export const dropDownSelectIcon = require("../assets/dropDownSelectIcon.png");
export const threeDotsIcon = require("../assets/threeDotsIcon.png");
export const checkBoxBlue = require("../assets/checkBoxBlue.png");
export const googleMaps = require("../assets/googleMaps.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const zoomIn = require("../assets/zoomIn.png");
export const zoomOut = require("../assets/zoomOut.png");
export const googleMapIcon = require("../assets/googleMapIcon.png");
export const locationIcon = require("../assets/locationIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const arrowRight = require("../assets/arrowRight.png");
export const arrowLeft = require("../assets/arrowLeft.png");
export const arrowUp = require("../assets/arrowUp.png");
export const completedIcon = require("../assets/completedIcon.png");
export const logo = require("../assets/logo.png");
export const radioButton = require("../assets/radioButton.png");
export const radioButtonOff = require("../assets/radioButtonOff.png");
export const likeIcon = require("../assets/likeIcon.png");
export const selectedIcon = require("../assets/selectedIcon.png");
export const purchaseOrderIcon = require("../assets/purchaseOrderIcon.png");
export const profilePicEdit = require("../assets/profilePicEdit.png");
export const profileDropDown = require("../assets/profileDropDown.png");
export const successModalImage = require("../assets/successModalProfile.png");
export const successModalCross = require("../assets/image_X-mark(Circle).png");
export const changePassword = require("../assets/changePassword_n.png");
export const profile = require("../assets/profile.png");
export const logOut = require("../assets/logOut.png");
export const logOutContent = require("../assets/logOutContent.png");
export const openIcon = require("../assets/openIcon.png");
export const closeIcon = require("../assets/closeIcon.png");
export const dialogBoxIcon = require("../assets/dialogBoxIcon.png");
export const whiteBackArrowIcon=require('../assets/whiteBackArrow.png')
export const close = require("../assets/close.png");
export const dropdownRequests = require("../assets/dropdownRequests.png");
export const dropdownQuotes = require("../assets/dropdownQuotes.png");
export const dropdownJobs = require("../assets/dropdownJobs.png");
export const dropdownInvoices = require("../assets/dropdownInvoices.png");
export const imagebutton_left = require("../assets/imagebutton_left.png");
export const imagebutton_right = require("../assets/imagebutton_right.png");
