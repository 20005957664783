import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { ReportsSideBar } from "./ReportsSideBar.web";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { StyledBox } from "../../../blocks/Settings/src/CompanyInformation.web";
import { ReportsData } from "./ReportsData.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ReportsController, {
  Props,
} from "./ReportsController.web";

// Customizable Area Start

// Customizable Area End

export class TimesheetReport extends ReportsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.report')} active={false} active1={false} active2={0} />
        <ReportsSideBar {...this.props} active={3} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.timesheetContainer}
            className={classes.timesheetContainer}
          >
            <Container maxWidth={false}>
              <Typography style={webStyle.headerTextTimesheet} className={classes.headerTextTimesheet} variant="subtitle1">
                {t('reports.timesheet')}
              </Typography>
              <Typography style={webStyle.descTextTimesheet} className={classes.descTextTimesheet} color="textPrimary">
                {t('reports.reportDesc')}
              </Typography>
              <ReportsData {...this.props} />
            </Container>
          </Box>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  timesheetContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem !important",
      marginTop: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.25rem !important",
    }
  },
  headerTextTimesheet: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    }
  },
  descTextTimesheet: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: "25px !important",
      fontSize: "18px !important",
    }
  },
  boxInnerContainer: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "0.5rem !important",
      paddingLeft: "0.5rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem !important",
      paddingLeft: "0rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "2rem !important",
    }
  },
  mobileDownloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important"
    },
  },
  downloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    },
  }
});

const webStyle = {
  timesheetContainer: {
    paddingBottom: "6rem",
    marginTop: "8.5rem"
  },
  headerTextTimesheet: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500
  },
  descTextTimesheet: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    fontSize: "14px",
    lineHeight: "20px",
    paddingTop: "1rem",
  }
}

const timesheetReportStyle = withStyles(styles)(TimesheetReport);
export default withTranslation()(withSnackbar(timesheetReportStyle));
// Customizable Area End