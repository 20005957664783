import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { ReportsSideBar } from "./ReportsSideBar.web";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { StyledBox } from "../../../blocks/Settings/src/CompanyInformation.web";
import { ReportsData } from "./ReportsData.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ReportsController, {
  Props,
} from "./ReportsController.web";

// Customizable Area Start

// Customizable Area End

export class JobReport extends ReportsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.report')} active={false} active1={false} active2={0} />
        <ReportsSideBar {...this.props} active={1} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.jobReportContainer}
            className={classes.jobReportContainer}
          >
            <Container maxWidth={false}>
              <Typography style={webStyle.headerTextJob} className={classes.headerTextJob} variant="subtitle1">
                {t('reports.jobReport')}
              </Typography>
              <Typography
                style={webStyle.descTextJob}
                className={classes.descTextJob}
                color="textPrimary"
              >
                {t('reports.reportDesc')}
              </Typography>
              <ReportsData {...this.props} />
            </Container>
          </Box>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  jobReportContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem !important",
      marginTop: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.25rem !important",
    },
  },
  headerTextJob: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    }
  },
  mobileDownloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important"
    }
  },
  descTextJob: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: "25px !important",
      fontSize: "18px !important",
    }
  },
  boxInnerContainer: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0.5rem !important",
      paddingRight: "0.5rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem !important",
      paddingRight: "0rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "2rem !important"
    },
  },
  downloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important"
    },
  }
});

const webStyle = {
  jobReportContainer: {
    paddingBottom: "6rem",
    marginTop: "8.5rem",
  },
  headerTextJob: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    color: CONSTANTS.color.lightBrown
  },
  descTextJob: {
    fontSize: "14px",
    lineHeight: "20px",
    paddingTop: "1rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  }
}

const jobReportStyle = withStyles(styles)(JobReport);
export default withTranslation()(withSnackbar(jobReportStyle));
// Customizable Area End