import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Typography,
  Radio,
  Tabs,
  Tab,
  Hidden
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import {
  backIconMobile
} from "./assets";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { CreateSupplier } from "./CreateSupplier.web";
import { PurchaseOrders } from "./PurchaseOrders.web";
import { CreatePurchase } from "./CreatePurchase.web";
import { SupplierData } from "./SupplierData.web";
import { EditSupplier } from "./EditSupplier.web";
import { PurchaseData } from "./PurchaseData.web";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import withPermission from "../../../components/src/withPermission.web";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

import CreateSupplierController, {
  Props,
} from "./CreateSupplierController.web";

// Customizable Area Start
const StyledTabs = withStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      gap: "1rem",
      [theme.breakpoints.down("xs")]: {
        gap: "0.8rem",
      }
    }
  },
  indicator: {
    display: 'none',
  },
}))((props: any) => <Tabs {...props} disableripple="true" TabIndicatorProps={{ children: <span /> }} />);

const tabStyles = (theme: Theme) => ({
  root: {
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    position: "relative" as const,
    minHeight: "46px",
    borderRadius: "8px",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        justifyContent: async () => await getStorageData("lang") === "ar" ? "flex-start" : "flex-end"
      },
      textTransform: "capitalize" as const,
    },
    "&.MuiTab-textColorInherit.Mui-disabled": {
      opacity: 1
    },
    "&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
      marginBottom: "0px",
      color: CONSTANTS.color.thickBlue
    },
    "&.MuiTab-root" : {
      padding:"6px 0px"
    }
  }
});

const StyledTab = withStyles(tabStyles)(Tab);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// Customizable Area End

export class SupplierTabs extends CreateSupplierController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { languageSelected } = this.state;
    const { t } = this.props;
    const { classes }: any = this.props;
    const supplier_id = localStorage.getItem(configJSON.supplier_id);
    return (
      <>
        <DashboardSideBar permissions={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.supplier')} active={false} active1={0} active2={false} />
        <StyledRoot languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.boxContainer}
            className={classes?.boxContainer}
          >
            <Container maxWidth={false}>
              <Box style={webStyle.navHeading}>
                <Hidden smUp>
                  <img data-test-id="handleBackIcon" style={languageSelected === "ar" ? {...webStyle.backIconMobile, transform: "rotate(180deg)"} : webStyle.backIconMobile} src={backIconMobile} onClick={this.handleBackIcon} alt="" />
                </Hidden>
                <Box style={webStyle.navTextContainer}>
                  <Typography className={classes?.navText} style={webStyle.navText} variant="body1">
                    {t('suppliers.home')}
                  </Typography>
                  <Typography className={classes?.navText} style={{...webStyle.navText, ...webStyle.marginLeftStyle}} variant="body1">
                    &gt; {t('suppliers.supplier')}
                  </Typography>
                </Box>
              </Box>
              <Typography className={classes?.supplierText} style={webStyle.supplierText} variant="subtitle1">
                {t('suppliers.supplier')}
              </Typography>
              <Box style={webStyle.suppliersContainer} className={classes?.suppliersContainer}>
                <StyledTabs
                  data-test-id="tabValues"
                  value={this.state.active}
                  onChange={this.handleChange}
                  aria-label="simple tabs example"
                >
                  <StyledTab
                    style={webStyle.informationTab}
                    className={classes?.informationTab}
                    label={t('suppliers.information')}
                    {...a11yProps(0)}
                    disabled={this.state.active === 3 || this.state.active === 4}
                    icon={<Radio color="primary" checked={this.state.active === 0 || this.state.active === 3 || this.state.active === 4} />}
                  />
                  <StyledTab
                    style={{...webStyle.purchasesTab,backgroundColor: !supplier_id ? "#ebebeb":''}}
                    className={`${classes?.informationTab} ${classes?.purchaseTab}`}
                    label={t('suppliers.purchases')}
                    {...a11yProps(1)}
                    disabled={this.state.active === 2 || this.state.active === 5 || !supplier_id}
                    icon={<Radio color="primary" checked={this.state.active === 1 || this.state.active === 2 || this.state.active === 5} />}
                  />
                </StyledTabs>
              </Box>
              <TabPanel value={this.state.active} index={0}>
                <CreateSupplier {...this.props} oldProps={this} />
              </TabPanel>
              <TabPanel value={this.state.active} index={1}>
                <PurchaseOrders languageSelected={""} currency={this.state.getCurrency} purchasePdfData={undefined} purchasePdfLoading={undefined} openPdf={undefined} handleCloseDownloadPdf={undefined} {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={2}>
                <CreatePurchase languageSelected={""} currency={this.state.getCurrency} purchasePdfData={undefined} purchasePdfLoading={undefined} openPdf={undefined} handleCloseDownloadPdf={undefined} {...this.props} />
              </TabPanel>
              <TabPanel value={this.state.active} index={3}>
                <SupplierData {...this.props} oldProps={this} />
              </TabPanel>
              <TabPanel value={this.state.active} index={4}>
                <EditSupplier {...this.props} oldProps={this} />
              </TabPanel>
              <TabPanel value={this.state.active} index={5}>
                <PurchaseData languageSelected={""} currency={this.state.formDataEdit.currency} purchasePdfData={undefined} purchasePdfLoading={undefined} openPdf={undefined} handleCloseDownloadPdf={undefined} {...this.props} />
              </TabPanel>
            </Container>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem !important",
    },
  },
  customOption: {
    color: CONSTANTS.color.lightBrown,
  },
  supplierData: {
    fontSize: "1.35rem !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2739rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px !important",
    },
  },
  formDataText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
    lineHeight: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  navText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  supplierText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
      marginTop: "1.5rem !important"
    }
  },
  suppliersDataContainer: {
    marginTop: "0.5rem",
    padding: "1rem 1rem 0px",
    maxWidth: "100%",
    minHeight: "835px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "6rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1rem 2rem !important",
      marginTop: "0rem !important",
    },
  },
  formData: {
    padding: "1.5rem 1rem 0rem 3rem",
  },
  formDataAr: {
    padding: "1.5rem 3rem 0rem 1rem",
  },
  contactForm: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
    lineHeight: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    }
  },
  contactFormTitle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
    lineHeight: "16px",
    color: CONSTANTS.color.lightBrown,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  headerText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    padding: "2rem 3rem 0rem",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 1rem 1rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 0rem 1.5rem !important",
      fontSize: "24px",
    },
  },
  styledGridContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 0rem 2.5rem !important",
    }
  },
  suppliersContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem !important",
    }
  },
  informationTab: {
    [theme.breakpoints.down("xs")]: {
      width: "152px !important",
      fontSize: "18px !important"
    }
  },
  purchaseTab: {
    [theme.breakpoints.down(370)]: {
      marginLeft: "0.5rem !important",
    }
  },
  inputBase: {
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px",
    width: "100%",
    border: "0.5px solid lightgrey",
    borderRadius: "0 8px 8px 0 !important",
    color: CONSTANTS.color.lightBrown,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    },
  },
  inputBaseAr: {
    borderRadius: "8px 0 0 8px!important",
  },
  hr: {
    margin: "0 0rem 0 3rem",
    marginTop: "2rem",
    height: "1px",
    backgroundColor: CONSTANTS.color.paleGrey,
    [theme.breakpoints.down('md')]: {
      margin: "2rem 1rem 0 3rem !important"
    },
    [theme.breakpoints.down('sm')]: {
      margin: "4rem 1rem 0 1rem !important"
    },
    [theme.breakpoints.down('xs')]: {
      margin: "2rem 0rem 2rem 0rem !important"
    },
  },
  buttonsInnerContainer: {
    display: 'flex',
    gap: "1rem",
    width: "100%"
  },
  buttonStyleCommon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    textTransform: "none" as const,
  },
  buttonContainerDownloadPdf: {
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer"
    },
    maxWidth: "262px",
    backgroundColor: CONSTANTS.color.darkBlue,
    color: CONSTANTS.color.white,
  },
  buttonSaveSuppliers: {
    maxWidth: "200px",
    color: CONSTANTS.color.white,
    backgroundColor: CONSTANTS.color.darkBlue,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer"
    },
  },
  btnBoxCancel: {
    maxWidth: "200px",
    color: CONSTANTS.color.lightGrey,
    backgroundColor: CONSTANTS.color.white,
    border: `1px solid ${CONSTANTS.color.lightGrey}`,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.lightGrey,
      color: theme.palette.common.white,
      cursor: "pointer"
    },
  },
  buttonContainerArchive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "200px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.crimsonRed}`,
    textTransform: "none" as const,
    color: CONSTANTS.color.crimsonRed,
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.crimsonRed,
      color: theme.palette.common.white,
      cursor: "pointer"
    },
  },
  buttonMobile: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important"
    }
  },
  buttonTextMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  buttonText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  buttonTextDialog: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
      fontWeight: `${CONSTANTS.fontWeight.fontWeight400} !important`,
    }
  },
  buttonStyleMobile: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important"
    }
  },
  buttonStyleEdit: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important",
      marginBottom: "1rem !important",
    }
  },
  buttonContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 !important",
      display: "block !important",
    }
  },
  buttonsContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 !important",
      gap: "1rem !important"
    }
  },
  purchaseOrderText: {
    [theme.breakpoints.down("sm")]: {
      padding: "1.8rem 1.8rem 1.6rem 1rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    }
  },
  purchaseOrder: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.5rem !important",
      backgroundColor: `${CONSTANTS.color.white} !important`
    }
  },
  purchaseOrderContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem !important",
    }
  },
  purchaseId: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  buttonsContainerCancel: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 !important",
      display: "block !important"
    }
  },
  closeIcon: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0px !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0rem 0px !important",
    },
  },
  addressDateContainer: {
    [theme.breakpoints.down(480)]: {
      flexDirection: "column !important",
      gap: "2rem !important"
    },
  },
  addressContainer: {
    [theme.breakpoints.down("xs")]: {
      gap: "1rem !important"
    },
  },
  dateContainer: {
    [theme.breakpoints.down(480)]: {
      textAlign: "end !important",
    },
  },
  textAlignStart: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "start !important",
    },
  },
  purchaseSection: {
    paddingTop: "4rem",
    [theme.breakpoints.down(480)]: {
      paddingTop: "2.5rem !important",
    },
  },
  hrTag: {
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0px 1rem !important",
    },
  },
  pdfContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem !important",
    },
  },
  downloadContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 1rem 2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem !important",
    },
  },
  innerContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 1rem 20rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1rem 2rem !important",
    },
  },
  addBtnContainer: {
    maxWidth: "60px",
    backgroundColor: CONSTANTS.color.darkBlue,
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        cursor: "pointer",
        border:'none'
    },
  },
  buttonContainerDownload: {
    [theme.breakpoints.down("sm")]: {
      margin: "1rem 1rem 3rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important",
      margin: "1rem 1rem 2rem !important",
    },
  },
  headingText: {
    [theme.breakpoints.down("sm")]: {
      padding: "2.5rem 2rem 0rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem 1rem 0rem !important",
      fontSize: "24px !important",
    },
  },
  rateLabelMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    },
  },
  purchasesContainer: {
    marginTop: "0.5rem",
    paddingBottom: "3rem",
    maxWidth: "100%",
    minHeight: "765px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2rem !important",
    },
  },
  rateContainer: {
    position: "relative" as const,
    marginTop: "1rem",
    width: "100%",
   padding:"2rem 1rem 2rem 1rem",
    minHeight: "118px",
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    [theme.breakpoints.down('xs')]: {
      marginTop: "0rem",
    }
  },
  quantityInput: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    padding: '0px !important'
  },
  endAdornment: {
    borderLeft: "1px solid #979797",
    marginLeft: 0,
    color: CONSTANTS.color.black,
    width: "-webkit-fill-available",
  },
  selectTaxRate:{
    backgroundColor:"#ececec",
  },
  taxDivider: {
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
    transform: "rotate(180deg)",
    width: "90%",
    margin: "0 auto",
  },
  taxLabel:{
    width:"50%",
    [theme.breakpoints.down("xs")]: {
     width:"unset"
     },
  },
  taxPopoverContainer: {
    display: "flex",
    flexDirection: "column" as const,
    borderRadius: "10px",
    width: "251px",
    height: "222px",
  },
  subTotalBox:{
    display:"flex",
    padding:"0 5rem 0 0",
    justifyContent:"space-between",
    alignItems:"center",
    [theme.breakpoints.down("xs")]: {
     flexDirection:"column",
     padding:0,
     gap:"1rem"
    },
  },
  divider: {
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
    transform: "rotate(180deg)",
    margin: "2rem 0 2rem 0",
    [theme.breakpoints.down('xs')]: {
      width: "50%",
      margin: "2rem auto",

    },
  },
  total: {
    fontWeight: theme.typography.fontWeightMedium,
    color:theme.palette.common.black
  },
  subTitle20: {
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  rateLabelContainer: {
    padding: "0rem 2rem 2rem 4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 1rem 2rem 2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 1rem 2rem 1rem !important",
    },
  },
  rateLabelContainerAr: {
    padding: "0rem 4rem 2rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 2rem 2rem rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 1rem 2rem 1rem !important",
    },
  },
  depositModalAr: {
    [theme.breakpoints.down(410)]: {
      right: "35% !important",
    },
    [theme.breakpoints.down(360)]: {
      right: "30% !important",
    },
    [theme.breakpoints.down(340)]: {
      right: "24% !important",
    },
  },
  gridItems: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "4.5rem !important",
    },
  },
  ratesContainer: {
    position: "relative" as const,
    marginTop: "2rem",
    width: "100%",
    height: "118px",
    minHeight: "100%",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    [theme.breakpoints.down(700)]: {
      padding: "1.5rem 0 !important",
      height: "auto !important",
    },
  },
  ratesInnerContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center" as const,
    height: "100%",
    [theme.breakpoints.down(700)]: {
      display: "block !important",
    },
  },
  dialogButtonsContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between !important",
      gap: "1rem !important",
    },
  },
  buttonStyle: {
    [theme.breakpoints.down("xs")]: {
      textTransform: "capitalize !important",
      maxWidth: "none !important",
      minHeight: "40px !important",
      borderRadius: "10px !important",
    },
  },
  buttonStylePurchase: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "41px",
    borderRadius: "6px",
    cursor: "pointer",
    textTransform: "none" as const,
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
  },
  buttonStylePurchases: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "41px",
    borderRadius: "6px",
    cursor: "pointer",
    textTransform: "none" as const,
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    maxWidth: "74px",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        cursor: "pointer",
        border:"none"
    },
  },
  selectMethodDialog: {
    position: "absolute" as const,
    width: "100%",
    maxWidth: "160px",
    minHeight: "132px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    top: "50%",
    left: "50%",
    transform: "translate(-27.3%, 5%)",
    zIndex: 9,
  },
  backDropStyles: {
    position: "fixed" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(142 138 138 / 50%)",
    zIndex: 1,
    backdropFilter: "blur(2px)"
  },
  buttonStyleCancel: {
    [theme.breakpoints.down("xs")]: {
      textTransform: "capitalize !important",
      maxWidth: "130px !important",
      minHeight: "40px !important",
      borderRadius: "10px !important",
    },
    [theme.breakpoints.down(390)]: {
      maxWidth: "0 !important",
    },
  },
  depositContainer: {
    [theme.breakpoints.down(420)]: {
      gap: "1rem !important",
    },
  },
  transactionDateMobile: {
    [theme.breakpoints.down(420)]: {
      display: "block !important",
    },
  },
  transactionDateLabelMobile: {
    [theme.breakpoints.down(420)]: {
      display: "flex !important",
      padding: "3px 0 !important"
    },
  },
  buttons: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 !important"
    },
  },
  inputDepositMethod: {
    width: "100%",
    maxWidth: "196px",
    minHeight: "48px",
    borderRadius: "6px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    color: CONSTANTS.color.lightBlack,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 5px 0 10px",
    cursor: "pointer"
  },
  selectCurrencyContainer: {
    height: "100%",
    border: 0,
    paddingLeft: "3px",
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer",
    backgroundColor: CONSTANTS.color.white
  },
  selectCurrency: {
    width: "19px",
    height: "24px",
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  horizontalHr: {
    display: "flex",
    border: "0.5px solid rgb(216 216 216)"
  },
  discountText: {
    color: CONSTANTS.color.neutralGrey,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "start" as const,
    paddingBottom: "3px"
  },
  errorDepositStyle: {
    paddingBottom: "5px",
    textAlign: "end" as const
  },
  verticalHr: {
    width: "1px",
    height: "58px",
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
    [theme.breakpoints.down(700)]: {
      display: "flex !important",
      width: "100% !important",
      height: "1px !important",
      margin: "1rem 0 !important",
    },
  },
  helperText: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: CONSTANTS.color.white
  },
  purchaseFormContainer: {
    padding: "1.5rem 2rem 0rem 4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "3.5rem 3rem 2rem 4rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3.5rem 3.5rem 2rem 3rem !important",
    },
  },
  purchaseFormContainerAr: {
    padding: "1.5rem 4rem 0rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "3.5rem 4rem 2rem 3rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3.5rem 3rem 2rem 3.5rem !important",
    },
  },
  inputBaseDepositDetails: {
    width: "100%",
    maxWidth: "196px",
    borderRadius: "6px",
    color: CONSTANTS.color.lightBlack,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px",
    minHeight: "67px",
  },
  inputBaseDeposit: {
    width: "100%",
    maxWidth: "196px",
    minHeight: "48px",
    borderRadius: "6px",
    color: CONSTANTS.color.lightBlack,
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px"
  },
});

const webStyle = {
  boxContainer: {
    marginTop: "9rem",
  },
  navHeading: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  backIconMobile: {
    width: "12px",
    height: "24px",
  },
  navTextContainer: {
    display: "flex",
  },
  navText: {
    color: CONSTANTS.color.darkCement,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  marginLeftStyle: {
    marginLeft: "5px"
  },
  supplierText: {
    color: CONSTANTS.color.darkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    marginTop: "1rem"
  },
  informationTab: {
    height: "46px",
    width: "175px"
  },
  purchasesTab: {
    height: "46px",
    width: "175px"
  },
  suppliersContainer: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
  },
}

const supplierTabStyles = withStyles(styles)(SupplierTabs);
const supplierTabsToast =  withSnackbar(supplierTabStyles);
const supplierTabsPermission = withPermission(supplierTabsToast);
export default withTranslation()(supplierTabsPermission);
// Customizable Area End