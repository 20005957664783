//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  withStyles, createStyles
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Hidden from "@material-ui/core/Hidden"
import { withRouter } from "react-router";
import { mainLogo, firstSideImg, sidePage2, sidePage1, sidePage3, mobileArrow } from "./assets";
import ResetPasswordController, { Props } from "./ResetPasswordController.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { StyledButton } from "../../email-account-registration/src/utility.web";
import { CONSTANTS} from "../../../components/src/theme";
import { CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
import { mobileLogo } from "../../LanguageSupport/src/assets";
import { goBack } from "../../../components/src/CommonFunction";
import { withTranslation } from "react-i18next";
// Customizable Area End


export class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
     // Customizable Area Start
    //@ts-ignore
    const { t,classes } = this.props
    const { newPassword, confirmPassword } = this.state.formData;
    return (
      // Customizable Area Start
      <>

          <form onSubmit={this.handleResetPasswordSubmit}>
            <Grid container direction="row" alignItems="center" style={{ height: '100%' }}>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.layoutImage} />
              </Hidden>
              <Hidden only={'xs'}>
                <Grid item xs={12} sm={1} lg={2} xl={3} className={classes.resetlogoSecond}>
                  <img className={classes.resetSideImg} src={firstSideImg} alt="firstSideImg" />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                <Grid container direction="row" spacing={2} style={{ padding: 20 }}>
                  <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <Grid item xs={12} >
                      <img className={classes.backIcon} src={mobileArrow}
                        data-test-id="navigation"
                        width={12} height={24} alt="mobile arrow" 
                        onClick={() => goBack(this.props.navigation)}
                        />
                    </Grid>
                  </Hidden>
                  <Grid item xs={12}>
                    <img src={mainLogo} className={classes.logoImg} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.titletext}>{t("ResetPasswordScreen.Btn")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-start" spacing={3} style={{ marginTop: "20px" }}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom color="textPrimary" >
                          {t('ResetPasswordScreen.newPassword')}
                          <Typography display="inline" variant="caption" color="error">*</Typography>
                        </Typography>
                        <CustomizedTextField
                        data-test-id='newPassword'
                          fullWidth
                          variant="outlined"
                          name="newPassword"
                          value={newPassword}
                          onChange={this.handlePasswordChange}
                          error={Boolean(this.state.isErrorFormData.newPassword || this.state.isErrorFormData.commonError)}
                          type={this.state.showNewPassword ? 'text' : 'password'}
                          helperText={this.state.isErrorFormData.newPassword}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleShowNewPassword}
                                  edge="end"
                                >
                                  {this.state.showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom color="textPrimary">
                        {t('ResetPasswordScreen.confirmPassword')}
                          
                          <Typography display="inline" variant="caption" color="error">*</Typography>
                        </Typography>
                        <CustomizedTextField
                        data-test-id="confirmPassword"
                          fullWidth
                          variant="outlined"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={this.handlePasswordChange}
                          error={Boolean(this.state.isErrorFormData.confirmPassword || this.state.isErrorFormData.commonError)}
                          type={this.state.showConfirmPassword ? 'text' : 'password'}
                          helperText={this.state.isErrorFormData.confirmPassword || this.state.isErrorFormData.commonError}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleShowConfirmPassword}
                                  edge="end"
                                >
                                  {this.state.showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Hidden only={'xs'}>

                        <Grid item xs={12} container justifyContent="flex-end">
                          <StyledButton className={classes.resetPasswordBtn}
                            data-test-id="btnResetPassword"
                            variant="contained"
                            color="primary"
                            type="submit">
                            <Typography variant="caption" className={classes.btntext}>{t('ResetPasswordScreen.Btn')}</Typography>
                          </StyledButton>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={1} md={2} lg={2} />
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Grid item xs={12} className={classes.resetMobileButton}>
                  <StyledButton className={classes.resetPasswordBtn}
                    data-test-id="mobilebtnResetPassword"
                    variant="contained"
                    color="primary"
                    type="submit">
                    <Typography variant="caption" className={classes.btntext}>{t('ResetPasswordScreen.Btn')}</Typography>
                  </StyledButton>

                </Grid>
                <Grid item xs={12} className={classes.resetlogoSecond}>
                  <img className={classes.resetSideImg} src={firstSideImg} alt="firstSideImg" />
                </Grid>
              </Hidden>
              <Hidden only={'xs'}>
                <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.layoutImage} />
              </Hidden>
            </Grid>
          </form>
      </>
     
    );
     // Customizable Area End
  }
}

// Customizable Area Start
//@ts-ignore
const resetPasswordRouter = withRouter(ResetPassword)
const ResetPasswordToaster = withSnackbar(resetPasswordRouter)
const ResetPasswordTransalation=withTranslation()(ResetPasswordToaster)
export default withStyles((theme) =>
  createStyles({
    layoutImage: {
      backgroundImage: `url(${sidePage1})`,
      height: "100vh",
      backgroundSize: "100% 100%",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        backgroundImage: `url(${mobileLogo})`,
        maxHeight: "86px"
      },
      [theme.breakpoints.between("sm", 'md')]: {
        backgroundImage: `url(${sidePage2})`,
      },
      [theme.breakpoints.between('md', "lg")]: {
        backgroundImage: `url(${sidePage3})`,
      },
    },
    titletext: {
      fontWeight: CONSTANTS.fontWeight.fontWeight600,
      paddingTop: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
      },
    },
    logoImg: {
      width: "150px",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "120px",
      },
    },
    resetPasswordBtn: {
      width: "12rem ",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    resetlogoSecond: {
      display: 'flex',
      alignItems: 'end',
      height: '100%',
      [theme.breakpoints.down("xs")]: {
        position: 'absolute',
        bottom: 0,
        zIndex: -99
      },

    },
    resetMobileButton: {
      [theme.breakpoints.down("xs")]: {
        position: 'absolute',
        bottom: '10%',
        width: '100%',
        padding: '0 20px'
      },
    },
    resetSideImg: {
      width: "80px",
      [theme.breakpoints.down("xs")]: {
        width: "47px",
        height: '47px'
      },
    },
  })
)(ResetPasswordTransalation);
// Customizable Area End