import React from "react";

// Customizable Area Start
import {
  Box,
  Typography
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import { completedIcon } from "../../blocks/dashboard/src/assets";
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { CONSTANTS } from "./theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
const StyledTypography = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    }
  }
}))(Typography);

const BorderLinearProgress = withStyles({
  root: {
    height: "64px",
    borderRadius: "8px",
    border: "2px solid rgba(0,0,0,0.1)"
  },
  colorPrimary: {
    backgroundColor: CONSTANTS.color.white,
  },
  bar: {
    backgroundColor: CONSTANTS.color.softBlueGrey,
  },
})(LinearProgress);

function LinearProgressWithLabel(props: LinearProgressProps & { value: number ,t: (key: string) => string, languageSelected: string}) {
  return (
    <Box display={"flex"} alignItems="center" flexWrap="wrap">
      <Box minWidth={35} style={webStyle.positionStyle} width={props.languageSelected === "ar" && "50%"}>
        <Typography variant="body2" style={props.value < 100 ? webStyle.percentage : webStyle.displayStyle}>
          <StyledTypography style={webStyle.uploading} variant="body1" color="textPrimary">
            {props.languageSelected === "ar" ? "جارٍ التحميل..." : "Uploading..."}
          </StyledTypography>
          <span style={webStyle.percentageStyle}>
            {`${Math.round(props.value)}% ${props.languageSelected === "ar" ? "مكتمل" : "completed"}`}
          </span>
        </Typography>
      </Box>
      <Box width="100%" mt={3}>
        {props.value === 100 ?
          <Box style={webStyle.completedContainer}>
            <StyledTypography variant="body2" style={webStyle.completed}>
              {props.languageSelected === "ar" ? "اكتمل التحميل" : "Upload Completed"}
            </StyledTypography>
            <img style={webStyle.completedIcon} src={completedIcon} alt="" />
          </Box>
        :
          <BorderLinearProgress variant="determinate" {...props} />
        }
      </Box>
    </Box>
  );
}
// Customizable Area End

export class FileUpload extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    // Customizable Area Start
    return (
      <Box>
        <LinearProgressWithLabel value={this.props.progress} t={this.props.t} languageSelected={this.props.languageSelected} />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  
});

const webStyle = {
  displayStyle:{
    display: "none"
  },
  percentage: {
    position: "absolute" as const,
    top: "50%",
    zIndex: 1,
    left: "50%",
    transform: "translate(50px, 41px)",
    color: CONSTANTS.color.royalBlue,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
  },
  positionStyle: {
    position: "relative" as const
  },
  percentageStyle: {
    marginLeft: "-34px"
  },
  uploading: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    marginLeft: "-34px",
    marginBottom: "6px",
  },
  completedContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minHeight: "64px",
    borderRadius: "8px",
    backgroundColor: CONSTANTS.color.mintGreen,
    padding: "0 1.5rem"
  },
  completed: {
    color: CONSTANTS.color.brightGreen,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
  },
  completedIcon: {
    width: "24px",
    height: "24px",
  }
}

export default withTranslation()(withStyles(styles)(FileUpload));
// Customizable Area End