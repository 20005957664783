import React from "react";
// Customizable Area Start
import { Dialog, DialogTitle,Checkbox,ClickAwayListener, Grid, Typography, Divider, DialogContent,InputBase,Box,InputAdornment } from '@material-ui/core';
import { withStyles, Theme, StyleRulesCallback } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { CONSTANTS } from './theme';
import CustomDatePicker from "./CustomDatePicker";
import { searchIcon } from "../../blocks/dashboard/src/assets";
import { dropDownSelectIcon } from "../../blocks/RequestManagement/src/assets"
import { StyledTextField } from "../../blocks/RequestManagement/src/Requests.web"
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import LoadingBackdrop from "./LoadingBackdrop";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const styled: StyleRulesCallback<Theme, {}, string> = (theme: Theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: CONSTANTS.color.lightGrey,
        width: "15px",
        height: "16px",
    },
    text_lightBrown: {
        color: CONSTANTS.color.lightBrown
    },
    fontW_600: {
        fontWeight: CONSTANTS.fontWeight.fontWeight600
    },
    textMargin:{
        marginTop:"10px",
        marginBottom:"5px"
    },
    jobSearchContainer: {
        display: "flex",
        alignItems: "center",
        minHeight: "56px",
        width: "100%",
        borderRadius: "8px",
        border: "1.5px solid rgba(0,0,0,0.1)",
    },
    jobSearchIcon: {
        height: "20px",
        width: "20px",
        margin: "0 0.6rem 0 1.3rem",
        marginTop:"3px"
    },
    dropDownSelectIcon: {
        width: "13px",
        height: "9px",
        cursor: "pointer"
    },
    transform: {
        transform: "rotate(180deg)"
    },
    toggleJobModalContainer: {
        position: "absolute" as const,
        padding: "1rem",
        zIndex: 9,
        width: "200px",
        minHeight: "194px",
        borderRadius: "5px",
        backgroundColor: CONSTANTS.color.white,
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    },
    toggleModalStyleJob: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
    },
    toggleIconsContainerJob: {
        display: "flex",
        alignItems: "center"
    },
    jobContainer: {
        marginTop: "2rem",
        maxWidth: "100%",
        backgroundColor: CONSTANTS.color.white,
        boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
        height: "300px",
        overflow: 'scroll',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#1c18af",
        },
        "&::-webkit-scrollbar": {
            width: '1em'
        }
    },
    padding_16: {
        padding: '16px'
    },
    jobTableHeadSpan: {
        cursor: "pointer",
        borderRadius: "2px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    dropDownSelectIconTable: {
        width: "13px",
        height: "9px",
        cursor: "pointer",
        marginLeft: "2rem",
    },
    tableText: {
        fontWeight: theme.typography.fontWeightRegular,
    },
    statusKeysTextPending: {
        color: "#FF5D65",
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 10,
        marginLeft: 16,
        marginRight:16,
        textTransform: "capitalize"
    },
    statusKeysText: {
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 10,
        marginLeft: 10,
        marginRight:16,
        textTransform: "capitalize",
        color: "#FF9718"
    },
    jobCellLayout: {
        padding: '16px 16px 0px 16px'
    },
    statusKeysTextCancelled: {
        color: "#BDBDBD",
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 10,
        marginLeft: 10,
        textTransform: "capitalize",
        marginRight:16,
    },
    statusKeysTextQuoted: {
        color: "#4B7206",
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 10,
        marginLeft: 10,
        marginRight:16,
        textTransform: "capitalize"
    },
    statusKeysTextConverted: {
        color: "#FF6FB8",
        marginRight:16,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 10,
        marginLeft: 10,
        textTransform: "capitalize"
    },
    statusKeysTextOverdue: {
        color: "#902744",
        fontWeight: theme.typography.fontWeightMedium,
        marginRight:16,
        marginTop: 10,
        marginLeft: 10,
        textTransform: "capitalize"
    },
    dividerLine: {
        marginTop: "8px",
        backgroundColor: "#FF9718"
    },
    dividerColorPending: {
        marginTop: "8px",
        backgroundColor: "#FF5D65"
    },
    dividerColorConverted: {
        marginTop: "8px",
        backgroundColor: "#FF6FB8"
    },
    dividerColorQuoted: {
        marginTop: "8px",
        backgroundColor: "#4B7206"
    },
    dividerColorOverdue: {
        marginTop: "8px",
        backgroundColor: "#902744",
    },
    dividerColorCancelled: {
        marginTop: "8px",
        backgroundColor: "#BDBDBD"
    },
    tableText1: {
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        color: CONSTANTS.color.lightBrown
    },
    emptyData: {
        color: CONSTANTS.color.darkGrey,
        fontWeight: theme.typography.fontWeightMedium,
    },
    jobViewRow: {
        display: 'flex',
        width: '100%',
        flexWrap: 'nowrap',
        gridAutoFlow: 'column',
        zIndex: 0,
        position: 'relative',
        justifyContent: 'end'
    },
    tableLayout:{
        maxHeight: '300px', 
        overflow: 'scroll', 
        minWidth: "fit-content"
    },
    tableRowLayout:{
        display: 'flex',
        width: '100%',
        flexWrap: 'nowrap',
        gridAutoFlow: 'column',
    }
});
interface Props {
}
interface JobsTableRow {
    statusKey: string
    id: number
    title: string
}
interface JobsStatusData {
    statusName: string
    id: number
    title: string
}
// Customizable Area End

export class JobReference extends React.Component<any, Props, any> {
  constructor(props: Props) {
    super(props);
     // Customizable Area Start
     // Customizable Area End
  }
  // Customizable Area Start
    statusModalJob = (jobStatusData: any[]) => {
        const { classes, handleStatusJobModalClose, handleSelectedStatus, checkedItems } = this.props
        return (
            <ClickAwayListener onClickAway={handleStatusJobModalClose}>
                <Typography>
                    <Box className={classes.toggleJobModalContainer}>
                        <Box className={classes.toggleModalStyleJob}>
                            {jobStatusData.map((item: { id: React.Key | undefined; title: string, statusName: string }) => (
                                <Box key={item.id} display={"flex"} mb={1} gridGap={"0.5rem"}>
                                    <Checkbox
                                        data-testid={`handleSelectStatus_${item.id}`}
                                        checked={checkedItems.some((subItem: string) => item.statusName === subItem)}
                                        onChange={handleSelectedStatus.bind(this, item, jobStatusData)}
                                        style={{ padding: 0, color: "#1c18af" }} />
                                    <Typography className={classes.menuItemStyle} variant="body1">
                                        {item.title}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Typography>
            </ClickAwayListener>
        );
    }
    jobsTableRow = (mockJobsTableRow: JobsTableRow[]) => {
        const { classes, handleSort,sortColumn,sortDirection } = this.props;
        return <>
            {mockJobsTableRow.map((item) => (
                <>
                    <Grid item xs={12} 
                        key={item.id}
                        className={classes.padding_16}
                        onClick={() => handleSort(item.statusKey)}
                        data-testid={`handleSort_${item.id}`}
                        style={sortColumn === item.statusKey
                            ? { backgroundColor: "rgba(232,232,232,0.4)", width: `${(100 / 3)}%`, minWidth: "185px" }
                            : { width: `${(100 / 3)}%`, minWidth: "185px" }}
                    >
                        <span className={classes.jobTableHeadSpan}>
                            <Typography variant="body1"
                                className={classes.tableText}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {item.title}
                            </Typography>
                            <img
                                className={(sortColumn === item.statusKey) && sortDirection === 'asc' ? `${classes.dropDownSelectIconTable} ${classes.transform}` :`${classes.dropDownSelectIconTable} ${classes.dropDownSelectIcon}`}
                                src={dropDownSelectIcon}
                                alt=""
                            />
                        </span>
                    </Grid>
                </>
            ))}
        </>
    }
    findClassesJobs = (statusKey: string, classes: ClassNameMap<string>) => {
        return statusKey === "in_progress" ?
            classes.statusKeysText : this.findJobsClasses(statusKey, classes)
    }
    findJobsClasses = (statusKey: string, classes: ClassNameMap<string>) => {
        return statusKey === "completed"
            ? classes.statusKeysTextQuoted : this.findJobsClassesv1(statusKey, classes)

    }
    findJobsClassesv1 = (statusKey: string, classes: ClassNameMap<string>) => {
        return statusKey === "invoiced"|| statusKey === "to_be_invoiced"  ? classes.statusKeysTextConverted : this.findJobsClassesv2(statusKey, classes)
    }
    findJobsClassesv2 = (statusKey: string, classes: ClassNameMap<string>) => {
        return statusKey === "late" ? classes.statusKeysTextOverdue : classes.statusKeysTextCancelled
    }
    findDividerColorJobs = (statusKey: string,classes:ClassNameMap<string>) => {
        return statusKey === "in_progress" ?
            classes.dividerLine : this.findDividerColorJobsV1(statusKey,classes)
    }
    findDividerColorJobsV1 = (statusKey: string, classes: ClassNameMap<string>) => {
        return statusKey === "completed" ?
            classes.dividerColorQuoted : this.findDividerColorJobsV2(statusKey, classes)
    }
    findDividerColorJobsV2 = (statusKey: string,classes:ClassNameMap<string>) => {
        return statusKey === "invoiced" || statusKey === "to_be_invoiced"  ? 
        classes.dividerColorConverted : this.findDividerColorJobsV3(statusKey,classes)
    }
    findDividerColorJobsV3 = (statusKey: string,classes:ClassNameMap<string>) => {
        return statusKey === "late" ? 
        classes.dividerColorOverdue : classes.dividerColorCancelled
    }
    jobHeadings = (statusKey: string, classes: ClassNameMap<string>, jobStatusData: JobsStatusData[]) => {
        return (
            <>
                <Grid container>
                    <Typography variant="body2" className={statusKey === "upcoming" ?
                        classes.statusKeysTextPending : this.findClassesJobs(statusKey, classes)}>
                        {statusKey === 'in_progress' && `${this.props.t('jobs.statusMenu.inProgress')}` || statusKey === 'late' && `${this.props.t('jobs.statusMenu.overdue')}` || statusKey === "to_be_invoiced" && `${this.props.t('jobs.statusMenu.invoiced')}` ||
                            jobStatusData?.find((item) => item.statusName === statusKey)?.title
                        }
                    </Typography>
                </Grid>
                <Divider className={statusKey === "upcoming" ? classes.dividerColorPending :
                    this.findDividerColorJobs(statusKey, classes)}
                />
            </>
        )
    }
    jobsViewData = ( classes:ClassNameMap<string>, item: any) => {
       
        return <>
            <Grid item
                className={classes.jobCellLayout}
                style={{ width: `${(100 / 3)}%`, minWidth: "185px" }}
            >
                <Typography variant="body1" className={classes.tableText1}>
                    {item.attributes.job_title}
                </Typography>
            </Grid>
            <Grid item
                className={classes.jobCellLayout}
                style={{ width: `${(100 / 3)}%`, minWidth: "185px" }}
            >
                <Typography variant="body1" className={classes.tableText1} >
                    {item.attributes.custmoer_name}
                </Typography>
            </Grid>
            <Grid item
                className={classes.jobCellLayout}
                style={{ width: `${(100 / 3)}%`, minWidth: "185px" }}
            >
                <Typography variant="body1" className={classes.tableText1} >
                    {moment(item.attributes.start_date).format("DD/MM/YYYY").toString()} - {moment(item.attributes.end_date).format("DD/MM/YYYY").toString()}
                </Typography>
            </Grid>

        </>
    }
  // Customizable Area End
  render() {
    // Customizable Area Start
      const { t, classes, open,status, searchJobs,handleSearchJobs,loading, close, allJobsData, statusModal, datePickerRef, handleStatusModalClose, handleDateModalOpen, languageSelected, handleSelectStatus, handleDateChange, dateModal, selectedDate, handleStatusJobModalOpen } = this.props
      const jobStatusData = [
          { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
          { id: 2, title: `${t('jobs.statusMenu.upcoming')}`, statusName: "upcoming" },
          { id: 3, title: `${t('jobs.statusMenu.inProgress')}`, statusName: "in_progress" },
          { id: 4, title: `${t('jobs.statusMenu.invoiced')}`, statusName: "invoiced" },
          { id: 5, title: `${t('jobs.statusMenu.completed')}`, statusName: "completed" },
          { id: 6, title: `${t('jobs.statusMenu.overdue')}`, statusName: "late" },
          { id: 7, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
      ]
      const mockJobsTableRow = [
          { id: 1, statusKey: "job_title", title: `${t('jobs.tableRow.title')}` },
          { id: 2, statusKey: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
          { id: 3, statusKey: "start_date,end_date", title: `${t('jobs.tableRow.startEndDate')}` },
      ]
      return (
          <Dialog
              open={true}
              onClose={close}
              maxWidth="md"
              >
              <DialogTitle>
                  <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                      <CloseIcon style={{ color: "#161616" }} />
                  </IconButton>
                  <Grid container>
                      <Typography variant="subtitle1"
                          className={`${classes.fontW_600} ${classes.text_lightBrown} ${classes.textMargin}`}
                      >
                          {t('invoice.jobReference')}
                      </Typography>
                      <Grid item xs={12}>
                          <Divider style={{ height: "2px" }} />
                      </Grid>
                  </Grid>
              </DialogTitle>
              <DialogContent>
                  <Grid container direction="row" spacing={2} >
                      <Grid item sm={6} xs={4}>
                          <Box className={classes.jobSearchContainer}>
                              <Box>
                                  <img className={classes.jobSearchIcon} src={searchIcon} alt="" />
                              </Box>
                              <InputBase
                                  placeholder={`${t('jobs.placeholder.searchJob')}`}
                                  inputProps={{  'aria-label': 'search','data-testid': 'searchJob' }}
                                  value={searchJobs}
                                  onChange={handleSearchJobs}
                              />
                          </Box>
                      </Grid>
                      <Grid item sm={3}  xs={4}>
                          <CustomDatePicker
                              type='job'
                              selectedDate={selectedDate}
                              status={status}
                              dateModal={dateModal}
                              datePickerRef={datePickerRef}
                              languageSelected={languageSelected}
                              handleDateChange={handleDateChange}
                              handleSelectStatus={handleSelectStatus}
                              handleDateModalOpen={handleDateModalOpen}
                              handleStatusModalClose={handleStatusModalClose}
                          />
                      </Grid>
                      <Grid item sm={3} xs={4}>
                          <StyledTextField
                              data-testid="statusMenuOpen"
                              fullWidth
                              defaultValue={`${t('invoice.placeholder.status')}`}
                              variant="outlined"
                              className={languageSelected === 'ar' ? 'language-ar' : ''}
                              InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <img className={!statusModal ? classes.dropDownSelectIcon : `${classes.dropDownSelectIcon} ${classes.transform}`} src={dropDownSelectIcon} alt="" />
                                      </InputAdornment>
                                  ),
                              }}
                              onClick={handleStatusJobModalOpen}
                          >
                          </StyledTextField>
                          {statusModal ? (
                              this.statusModalJob(jobStatusData)
                          ) : null}
                      </Grid>
                  </Grid>
                  <Grid container className={classes.jobContainer}>
                      <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                          <Grid container className={classes.tableRowLayout}>
                              {this.jobsTableRow(mockJobsTableRow)}
                          </Grid>
                          <Divider className={classes.marginTop_1} />
                          
                          {Object.keys(allJobsData)?.map((statusKey: string) => {
                              return (
                                  <>
                                      {this.jobHeadings(statusKey, classes, jobStatusData)}
                                      {allJobsData[statusKey]?.data?.length === 0 &&
                                          <Grid container justifyContent="center" alignItems="center"
                                              style={{ height: '150px' }}>
                                              <Grid item xs={12}>
                                                  <Typography variant="subtitle1" className={classes.emptyData} align="center">
                                                      {t('ProductService.noDataFound')}.
                                                  </Typography>
                                              </Grid>
                                          </Grid>
                                      }
                                      {allJobsData[statusKey]?.data?.length !== 0 &&
                                          <Grid container className={classes.tableLayout}>
                                              <Grid item xs={12}>
                                                  {allJobsData[statusKey]?.data?.map((item: {
                                                      id?: string; attributes: {
                                                          job_title: string; custmoer_name: string; start_date: moment.MomentInput; end_date: moment.MomentInput;
                                                      };
                                                  }) => (
                                                      <Grid container className={classes.jobViewRow} 
                                                      data-testid={`handleView_${item.id}`}
                                                      onClick={() => this.props.handleViewJob(item)}>
                                                          {this.jobsViewData(classes, item)}
                                                      </Grid>
                                                  ))}
                                              </Grid>
                                          </Grid>
                                      }
                                  </>
                              )
                          })}
                          <Grid>
                              <LoadingBackdrop
                                  loading={loading}
                              />
                          </Grid>
                      </Grid>
                  </Grid>
              </DialogContent>
          </Dialog>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styled)(JobReference);
// Customizable Area End