// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");

import {
  getStorageData,
  setStorageData
} from '../../../framework/src/Utilities';
export interface Props {
  navigation: any;
  id: string;
}

export interface JobListDataType {
    attributes: {
      address: string, 
      addresses: [], 
      company_headquarter: string, 
      company_name: string, 
      company_page_id: number, 
      company_photo: string,
        company_photo_url: string, 
        country: string, 
        created_at: string, 
        employment_type: null, 
        employment_type_id: number,
        industry_id: number,
        industry_name: string, 
        job_description: string, 
        job_function: string, 
        job_title: string, 
        job_video: string, 
        job_video_url: string, 
        location: string, 
        other_skills: Array<string>, 
        preffered_location: Array<string>, 
        profile_id: number, 
        question_answer_id: Array<string>, 
        question_answers: Array<object>, 
        remote_job: boolean, 
        salary: string, 
        seniority_level: string, 
        skill_id: Array<number>, 
        skill_name: Array<string>, 
        sub_emplotyment_type: null, 
        total_inteview_rounds: number,
        image_url: string,
        followers_count: number
    }, 
      id: string, 
      type: string
}

export interface JobListIOSDataType {
  item: {
    attributes: {
      address: string, 
      addresses: [], 
      company_headquarter: string, 
      company_name: string, 
      company_page_id: number, 
      company_photo: string,
        company_photo_url: string, 
        country: string, 
        created_at: string, 
        employment_type: null, 
        employment_type_id: number,
        industry_id: number,
        industry_name: string, 
        job_description: string, 
        job_function: string, 
        job_title: string, 
        job_video: string, 
        job_video_url: string, 
        location: string, 
        other_skills: Array<string>, 
        preffered_location: Array<string>, 
        profile_id: number, 
        question_answer_id: Array<string>, 
        question_answers: Array<object>, 
        remote_job: boolean, 
        salary: string, 
        seniority_level: string, 
        skill_id: Array<number>, 
        skill_name: Array<string>, 
        sub_emplotyment_type: null, 
        total_inteview_rounds: number,
        image_url: string,
        followers_count: number
    }, 
      id: string, 
      type: string
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  first: boolean;
  searchText: string;
  jobArray: JobListDataType[];
  recent: Array<string>;
  lastSearch: string;
  loading: boolean
  followIndex: number,
  token: string
}

interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingController extends BlockComponent<Props, S, SS> {
  searchJobApiCallId: string = ""
  searchCompanyApiCallId: string = "";
  searchPeopleApiCallId: string = "";
  followCompanyApiCallId: string = "";
  //willFocusSubscription: object;
  addConnectionApiCallId: string = "";
  lastVisitedJob: string = "";
  lastVisitedCompany: string = "";
  lastVisitedPeople: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: '',
      loading: false,
      txtSavedValue: 'A',
      enableField: false,
      first: true,
      searchText: '',
      jobArray: [],
      recent: [],
      lastSearch: '',
      followIndex: -1,
      token:''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  getJobSearchSuccessCallBack = (responseJson: JobListDataType[], apiRequestCallId:string) => {
    if (apiRequestCallId === this.searchJobApiCallId) {
      try {
          this.lastVisitedJob = this.state.searchText?.trim();
          this.setState({ jobArray: responseJson || [], loading: false });
      }
      catch (error) {
        alert(error)
      }
    }
  }

  getJobSearchFailedCallBack = (message:string, apiRequestCallId:string) => {
     if (message.includes('Record not found')) {
          if (apiRequestCallId === this.searchJobApiCallId) {
            this.lastVisitedJob = this.state.searchText?.trim()
            this.setState({ jobArray: [], loading: false });
          }
        } else if (apiRequestCallId === this.addConnectionApiCallId && message.includes("Connection created successfully")) {
          alert('Request sent successfully')
        }
  }

  getJobSearchErrorCallBack = (errorReponse:object, apiRequestCallId:string) => {
    if (
      apiRequestCallId === this.searchJobApiCallId ||
      apiRequestCallId === this.searchCompanyApiCallId ||
      apiRequestCallId === this.searchPeopleApiCallId
    ) {
      this.parseApiErrorResponse(errorReponse);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {

      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
      });
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        'Change Value',
        'From: ' + this.state.txtSavedValue + ' To: ' + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getJobSearchSuccessCallBack(responseJson.data, apiRequestCallId)
      }
      if (responseJson?.meta?.message) {
        this.getJobSearchFailedCallBack(responseJson.meta.message, apiRequestCallId);
      }
      if (responseJson && responseJson.errors && responseJson.errors?.length != 0 && responseJson.errors[0].token) {
        alert('Session Expired')
        this.props.navigation.navigate('Auth')
      }
      if (errorReponse) {
        this.getJobSearchErrorCallBack(errorReponse, apiRequestCallId)
      }
    }
  }
  
  getToken = () => {
    const getAuthToken: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(getAuthToken);
  };

  async componentDidMount() {
    this.getToken();
  }
  
  async getRecents() {
    const recentData = await getStorageData('recent');
    this.setState({ recent: recentData });
  }
  handler = (inputValue: string) => {
    if (inputValue.trim()) {
      this.setSearchText(inputValue)
    }
    this.setState({ lastSearch: inputValue.trim(), searchText: inputValue, loading: true });
  };

  setSearchText = (inputValue: string) => {
    this.searchJob(inputValue);
  };

  addDataToRecent() {
    let recent = this.state.recent;
    this.setState({ recent }, () => {
      setStorageData('recent', JSON.stringify(this.state.recent));
    });
  }


  goToJobDetailPage = (jobId: string, companyName: string, companyLocation: string, companyLogo: string) => {
    const jobMessage = new Message(getName(MessageEnum.NavigationMessage));
    jobMessage.addData(getName(MessageEnum.NavigationTargetMessage), "JobDetailsPage");

    jobMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      companyName,
      companyLocation,
      companyLogo,
      jobId
    });
    jobMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(jobMessage);
  };
  
  searchJob = async (inputValue: string) => {
    this.setState({ loading: true });
    let token = this.state.token;
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchJobApiEndPoint + '?search=' + inputValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUrl = (imageUrl: string) => {
    if (imageUrl.includes("https://") || imageUrl.includes("http://")) {
      return imageUrl;
    } else {
      return config.baseURL + imageUrl;
    }
  };
}

// Customizable Area End
