import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Button,
  InputAdornment,
  InputBase,
  withWidth
} from "@material-ui/core";
import { withStyles, } from "@material-ui/core/styles";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { EmployeeProfileSubSideBar } from "./EmployeeProfileSubSideBar.web";
import { StyledRoot, countryCodes } from "./utility.web";
import {
  userIcon,
  profileDropDown,
  logOutContent,
} from "../../dashboard/src/assets";
import { editIcon } from "../../Settings/src/assets";
import {
  EmployeeProfileStyledButton,
  EmployeeStyledTextField,
  EmployeeStyledTextFieldsAutoComplete,
} from "./EmployeeProfile.web";
import { ProfileStyledTextFields, renderImageUrl } from "./Profile.web";
import AutocompleteV3 from "@material-ui/lab/Autocomplete";
import {  CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import EmployeeProfileController, {
  Props,
} from "./EmployeeProfileController.web";

// Customizable Area Start
export const StyledDialog = withStyles((theme)=>({
root:{
  "& .MuiDialog-paper":{
    margin:0
  }
}
}))(Dialog)
// Customizable Area End

export class EmployeeLogout extends EmployeeProfileController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  renderAutoCompleteInputEmployeeLogout = (params: any) => (
    <EmployeeStyledTextFieldsAutoComplete
      {...params}
      fullWidth
      languageSelected={this.state.lang} 
      variant="outlined"
    />
  );
  renderEmployeeImg = (webStyle: any) => {
    return (
      <Box>
        <Grid item>
          {this.state.companyLogo && (
            <img
              style={{ borderRadius: "50%", marginTop: "20px" }}
              height="100"
              src={renderImageUrl(this.state.companyLogo)}
              width="100"
            />
          )}
          {!this.state.companyLogo && !this.state.profile_image_employee && (
            <img
              width="100"
              style={{ borderRadius: "50%", marginTop: "20px" }}
              src={userIcon}
              height="100"
            />
          )}

          {this.state.profile_image_employee && (
            <img
              height="100"
              style={{ borderRadius: "50%", marginTop: "20px" }}
              src={this.state.profile_image_employee}
              width="100"
            />
          )}
          <img
            style={webStyle.employeeLogouteditIcon}
            src={editIcon}
            alt=""
            onClick={this.emphandleClickEditIcon}
          />
        </Grid>
      </Box>
    );
  };
  renderEmployeeFormLogout = (classes: any) => {
    const {t} =this.props
    const {
      lastName,
      firstName,
      email,
      phone,
      city,
      streetAddress,
      country,
      postcode,
    } = this.state.employeeformDetails;
    return (
      <>
        <Grid className={classes.empLogoutmainContV1} xs style={{ paddingTop: "55px" }}>
          <EmployeeProfileSubSideBar
            {...this.props}
            profileActive={0}
            
          />
        </Grid>
        <Grid
          className={classes.emLogoutmainCont}
        >
          <Container style={{ padding: 0 }} maxWidth={"lg"}>
            <Typography
              className={classes.profileTitle}
              style={{ marginTop: "30px" }}
              variant="subtitle1"
            >
             {t("boProfile.profileInformation")}
            </Typography>
          </Container>

          <Grid container direction="column">
            <Grid item xs>
              {this.renderEmployeeImg(webStyle)}
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  variant = "body1"
                  style={webStyle.employeeLogoutcontactForm}
                  gutterBottom
                >
                    {t("landingPage.ContactUs.firstName")}
                </Typography>
                <ProfileStyledTextFields
                  fullWidth
                  data-test-id="formDetails"
                  variant="outlined"
                  name="firstName"
                  value={firstName}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.firstName)}
                  inputProps={{ className: classes.textFielsFonts }}
                />
                <Typography
                  variant="body2"
                  style={webStyle.employeeLogouterrorMessage}
                >
                  {this.state.isErrorFormDetails.firstName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  variant = "body1"
                  style={webStyle.employeeLogoutcontactForm}
                  gutterBottom
                >
                    {t("landingPage.ContactUs.lastName")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  name="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.lastName)}
                  inputProps={{ className: classes.textFielsFonts }}
                />
                <Typography
                  variant="body2"
                  style={webStyle.employeeLogouterrorMessage}
                >
                  {this.state.isErrorFormDetails.lastName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.employeeLogoutcontactForm}
                  gutterBottom
                  variant = "body1"
                >
                 {t("landingPage.ContactUs.email")}
                </Typography>
                <ProfileStyledTextFields
                  fullWidth
                  variant="outlined"
                  data-test-id="formDetails"
                  name="email"
                  value={email}
                  onChange={this.employeehandleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.email)}
                  inputProps={{ className: classes.textFielsFonts }}
                  type="email"
                />
                <Typography
                  variant="body2"
                  style={webStyle.employeeLogouterrorMessage}
                >
                  {this.state.isErrorFormDetails.email}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.employeeLogoutcontactForm}
                  variant = "body1"
                  gutterBottom
                >
                  {t("landingPage.ContactUs.phoneNumber")}
                </Typography>
                <Box style={webStyle.empPhoneContainer}>
                  <AutocompleteV3
                    value={
                      this.state.countryCode !== "" &&
                      this.state.countryCode.toString().charAt(0) != "+"
                        ? "+" + this.state.countryCode.toString()
                        : this.state.countryCode.toString()
                    }
                    id="country-select-demo"
                    options={countryCodes}
                    style={{ width: "34%" }}
                    onChange={this.emphandleChangeCountryCode}
                    autoHighlight
                    renderInput={this.renderAutoCompleteInputEmployeeLogout.bind(
                      this
                    )}
                  />

                  <InputBase
                    onChange={this.employeehandleChangeFormDetails}
                    error={Boolean(this.state.isErrorFormDetails.phone)}
                    data-test-id="formDetails"
                    fullWidth
                    style={webStyle.empInputBase}
                    name="phone"
                    value={phone}
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.employeeLogoutcontactForm}
                  variant = "body1"
                  gutterBottom
                >
                   {t("boProfile.streetAddress")}
                </Typography>
                <ProfileStyledTextFields
                  onChange={this.employeehandleChangeFormDetails}
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  name="streetAddress"
                  value={streetAddress}
                  inputProps={{ className: classes.textFielsFonts }}
                />
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.employeeLogoutcontactForm}
                  variant = "body1"
                  gutterBottom
                >
                 {t("customers.siteDialog.city")}
                </Typography>
                <ProfileStyledTextFields
                  inputProps={{ className: classes.textFielsFonts }}
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  name="city"
                  value={city}
                  onChange={this.employeehandleChangeFormDetails}
                />
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.employeeLogoutcontactForm}
                  variant = "body1"
                  gutterBottom
                >
                 {t("customers.siteDialog.country")}
                </Typography>
                <ProfileStyledTextFields
                  variant="outlined"
                  data-test-id="formDetails"
                  fullWidth
                  name="country"
                  value={country}
                  onChange={this.employeehandleChangeFormDetails}
                  inputProps={{ className: classes.textFielsFonts }}
                />
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.employeeLogoutcontactForm}
                  variant = "body1"
                  gutterBottom
                >
                {t("customers.siteDialog.postCode")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  value={postcode}
                  onChange={this.employeehandleChangeFormDetails}
                  inputProps={{ className: classes.textFielsFonts }}
                  name="postCode"
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12} style={webStyle.gridItems}>
                <Typography
                  style={webStyle.employeeLogoutcontactForm}
                  variant = "body1"
                  gutterBottom
                >
                {t("customers.siteDialog.language")}
                </Typography>
                <EmployeeStyledTextField
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          style={
                            !this.state.toggleLanguageModal
                              ? webStyle.employeeLogoutcontactUsDropDown
                              : {
                                  ...webStyle.employeeLogoutcontactUsDropDown,
                                  transform: "rotate(180deg)",
                                }
                          }
                          src={profileDropDown}
                          alt=""
                        />
                      </InputAdornment>
                    ),
                    className: classes.textFielsFonts,
                  }}
                  fullWidth
                  variant="outlined"
                  defaultValue={t(`boProfile.english`)}
                  languageSelected={this.state.lang}
                  value={this.state.employeeformDetails.language}
                  onClick={this.emphandleLanguageModalOpen}
                />
              </Grid>
            </Grid>

            <Grid
              style={{ textAlign: "end", margin: "127px 0 25px 0" }}
              item
              xs={12}
            >
              <EmployeeProfileStyledButton
                onClick={this.emphandleDetailsSubmit}
                className={classes.saveButton}
                data-test-id="btnEmailLogin"
              >
                <Typography
                  className={classes.saveButtonText}
                  variant="subtitle2"
                >
                       {t("boProfile.save")}
                </Typography>
              </EmployeeProfileStyledButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t }: any = this.props;

    return (
      <>
        <DashboardSideBar
          permissions={undefined} active={false}
          active1={false}
          oldProps={undefined}
          {...this.props}
          navMenuItem={this.props.t('dashboardNavbar.profile')}
          active2={false}        />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "5rem",
            }}
            className={classes.boxContainer}
          >
            <Grid
              style={{padding:"10px"}}
              direction="row"
              container
              justifyContent="space-between"
              
              alignItems="flex-start"
            >
              <Dialog  
                PaperProps={{
                    style: {
                        width: "600px",
                        borderRadius: '20px',
                        paddingBottom:"16px"
                    },
                }}
                open={this.state.openLogOut}>
                <DialogContent
                  className={classes.dialogContentStyle}
                 
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    className={classes.employeelogoutContainer}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        direction="row"
                       
                      >
                        <Grid item xs={8} className={classes.empLogoutlogoutContent}>
                          <Typography variant="h6" className={classes.titleText}>
                          {t("boProfile.logout")}
                          </Typography>
                          <Typography variant="subtitle2" className={classes.titleBody}>
                          {t("boProfile.confirmMsg")}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "end" }}>
                          <img
                            src={logOutContent}
                            alt="logout contents"
                            className={classes.titleBodyImage}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        justifyContent="space-between"
                        container
                        direction="row"
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <Button
                            size="large"
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={this.employeehandleCancelLogout}
                          >
                            <Typography variant="subtitle2" className={classes.cancelText}>
                            {t("landingPage.StyleDialog.cancel")}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid
                          style={{  textAlign: "end" }}
                          item
                          xs={6}
                        >
                          <Button
                            onClick={this.employeehandleLogout}
                            variant="outlined"
                            size="large"
                            className={classes.logoutButton}
                          >
                            <Typography variant="subtitle2" className={classes.logoutButtonText}>
                            {t("boProfile.logout")}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>
              {this.renderEmployeeFormLogout(classes)}
            </Grid>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  renderForm: {
    flexWrap: "nowrap" as const,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap" as const
    },
  },
  emLogoutmainCont:{
    width: "75%",
    padding: "20px",
    borderLeft: "1px solid #e4e4e4",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  empLogoutmainContV1:{
  
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  empLogoutlogoutContent:{
    display:"flex",
    flexDirection:"column" as const,
    justifyContent:"start",
    [theme.breakpoints.down("xs")]: {
      justifyContent:"center",
    },
  },
  saveButton: {
    width: "200px",
    height: "67px",
    borderRadius: "10px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    textAlign: "end" as const,
  },
  employeelogoutContainer:{
    gap:"2rem"
  },
  saveButtonText: {
    width: "50px",
    height: "26px",
    color: theme.palette.common.white,
    fontWeight:theme.typography.fontWeightRegular,
    textAlign: "center" as const,
  },
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  profileTitle: {
    width: "256px",
    height: "28px",
    color: CONSTANTS.color.lightBrown,
    fontWeight:  theme.typography.fontWeightMedium,
  },
  countryCodeField: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 0rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem !important",
        marginTop: "0px !important",
        marginRight: "-10px !important",
      },
    },
  },
  cancelButton: {
    width: "182px",
    height: "56px",
    borderRadius: "10px",
    border: "1px solid #e02020",
    color: CONSTANTS.color.crimsonRed,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.crimsonRed,
        color: theme.palette.common.white,
        border: "none",
        cursor: "pointer"
    },
    [theme.breakpoints.down("xs")]: {
      width:"100%",
      height: "50px",
  }},
  cancelText: {
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
    textTransform: "none" as const,
  },
  logoutButton: {
    width: "182px",
    height: "56px",
    borderRadius: "10px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: theme.palette.common.white,
        border: "none",
        cursor: "pointer"
    },
    [theme.breakpoints.down("xs")]: {
      height: "50px",
      width:"100%"
    
    },
  },
  logoutButtonText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
    textTransform: "none" as const,
    lineHeight:"1.0"
  },
  titleText: {
   
    color: CONSTANTS.color.lightBrown,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "20px",
    marginBottom: "12px",
  },
  titleBody: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "25px",
    marginTop:"15px"
  },
  titleBodyImage: {
    width: "117px",
    height: "110px",
    [theme.breakpoints.down("xs")]: {
      width: "118px",
      height: "118px",
    },
  },
  empsideBarTitle: {
    padding: "0 0 0 2rem",
    marginLeft: "-0.2rem",
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
  }
});

const webStyle = {
  changeLogoContainer: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    width: "100%",
    maxWidth: "354px",
    minHeight: "125px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    transform: "translate(20%, 10%)",
    zIndex: 1,
  },
  dialogBoxIcon: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "8%",
    transform: "translate(-50%, -50%)",
  },
  changeLogoInnerContainer: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "1rem",
  },
  galleryIcon: {
    width: "24px",
    height: "24px",
  },
  changeLogoText: {
    color: "#666666",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  removeImageText: {
    color: "#ff5454",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  gridItems: {
    marginTop: "1rem",
  },
  employeeLogoutcontactForm: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
  },

  employeeLogouterrorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
    fontFamily: "Rubik",
  },
  employeeLogoutcontactUsDropDown: {
    width: "14px",
    height: "8px",
    cursor: "pointer",
  },
  empInputBase: {
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px",
    width: "100%",
    border: "0.5px solid lightgrey",
    borderRadius: "0 8px 8px 0",
  },
  empPhoneContainer: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #dedede",
    display: "flex",
    minHeight: "56px",
  },
  employeeLogouteditIcon :{
    cursor: "pointer",
    height: "36px",
    width: "36px",
    transform: "translate(-58%, 0%)",
  }
};
export default withTranslation()(withStyles(styles)(withWidth()(EmployeeLogout)));
// Customizable Area End
