import React from "react";

// Customizable Area Start
import Select, { components ,ValueType,ActionMeta} from 'react-select'
import ClearIcon from '@material-ui/icons/Clear';
import { CONSTANTS } from './theme';
import _ from 'lodash'
import { Grid, withStyles, Typography, Avatar,Button } from '@material-ui/core';
import { selectStyles } from "../../blocks/appointmentmanagement/src/EmpSelectInput.web"
import { StyledCheckbox } from '../../blocks/appointmentmanagement/src/Calendar.web'
interface CustomStyle{
    width:string
    fontSize:string
}
// Customizable Area End

// Customizable Area Start
interface Props {
    getEmployeeData:Employee[]
    values:string[]
}
interface StaffPermission {
    id: string;
    type: string;
    attributes: {
      action: string;
      active: boolean;
      employee_id: number;
      category: string;
    };
  }
  
  interface Employee {
    id: string;
    type: string;
    attributes: {
      full_name: string;
      phone_number: string;
      email: string;
      reset_password_token: string;
      status: string;
      first_name: string;
      last_name: string | null;
      country_code: string | null;
      country: string | null;
      city: string | null;
      street_address: string | null;
      language: string | null;
      post_code: string | null;
      latitude: number | null;
      longitude: number | null;
      terms: boolean;
      local: string | null;
      use_email_as_username: boolean;
      resend_email_invitation: boolean;
      online: boolean;
      reset_password_link: {
        link: string;
      };
      location:object
      default_billing_rate: string;
      employee_image?: string;
      billing_rates?: object
      staff_permissions: {
        data: StaffPermission[];
      };
    };
    value: string;
  }
  
export const customStyle = () => {
    return {
        placeholder: (styles: CustomStyle) => ({ ...styles, width: "100%",fontSize:"18px" }),
        menuList: (base: CustomStyle) => ({ ...base, height: '150px' }),
        control: (styles: CustomStyle) => ({ ...styles, borderRadius: "8px", minHeight: '52px' }),
        indicatorsContainer: (styles: CustomStyle) => ({ ...styles, display: "none" }),
        indicatorSeparator: (styles: CustomStyle) => ({ ...styles, display: "none" }),
    }
}
// Customizable Area End
interface IState {
    selectedOptions:Employee[];
}
export class CustomTeamMember extends React.Component<Props|any, IState> {
  constructor(props: Props|any) {
    super(props);
     // Customizable Area Start
     this.state ={
        selectedOptions:this.props.values
     }
     // Customizable Area End
  }
  // Customizable Area Start
    componentDidUpdate(prevProps:[]) {
        if (prevProps.values !== this.props.values) {
            const selectedOptions = this.props.getEmployeeData.filter((item: { id: string; }) => this.arrayContainsValue(this.props.values, item.id)) || [];
            selectedOptions.forEach((element:{value:string,id:string}) => {
                element.value=element.id
            });
            this.setState({ selectedOptions });
          } 
    } 
  arrayContainsValue(array: string[], value:string) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] == value) {
        return true;
      }
    }
    return false;
  }

    handleChange = (selectedEmp: ValueType<Employee, true>, actionMeta: ActionMeta<Employee>) => {
        if (Array.isArray(selectedEmp)) {
            this.setState({ selectedOptions: selectedEmp });
            if (this.props.onChange) {
                const selectD = selectedEmp.length > 0 && selectedEmp.map((item: { value: string }) => item.value) || [];
                this.props.onChange(selectD);
            }
        }
    };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t,classes } = this.props;
      const InputOption = ({ isFocused, isSelected, children, innerProps, selectedEmp,index, ...rest }:any) => {
          const onMouseDown = () => !this.props.isActive;
          const onMouseUp = () => this.props.isActive;
          const onMouseLeave = () => this.props.isActive;
          const style = {
              alignItems: "center",
              backgroundColor: 'transparent',
              color: 'inherit',
              display: "flex "
          };
          const props = { ...innerProps, onMouseDown, onMouseUp, onMouseLeave, style };
          const selectD =this.state.selectedOptions.length > 0 && this.state.selectedOptions?.map((item: { value: string }) => item.value)||[]
          const checkIsCheckedOrNot = () => {
              if (selectD?.length > 0) {
                   return  this.arrayContainsValue(selectD, rest.value)
              } else {
                  return false
              }
          }
          return (
              <components.Option
                  {...rest}
                  isFocused={isFocused}
                  isSelected={checkIsCheckedOrNot()}
                  innerProps={props}
              >
                  <StyledCheckbox checked={checkIsCheckedOrNot()} />
                  {children}
              </components.Option>
          );
      };
    return (
        <>
            <Select
                components={{
                     Option:({ isFocused, isSelected, children, innerProps, ...rest })=> 
                     InputOption({ isFocused, isSelected, children, innerProps,selectedEmp:this.props.values, ...rest })}}
                name='employee_id'
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isDisabled={this.props?.disabled}
                controlShouldRenderValue={false}
                styles={customStyle()}
                theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: CONSTANTS.color.darkBlue } })}
                placeholder={t('Calendar.ErrorMessage.NewTeam')}
                options={this.props.getEmployeeData?.map((item: { id: string, attributes: { full_name:string,  email: string,employee_image:{url:string} }}) => {
                    return {
                        value: item.id,
                        label:
                            <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>
                                <Grid item className={this.props.selectLanguage === 'ar' && classes.paddingRight_9}>
                                    <Avatar
                                    src={item.attributes.employee_image && item.attributes.employee_image.url || ''}
                                    />
                                </Grid>
                                <Grid item className={this.props.selectLanguage === 'ar' ? classes.paddingRight_9 : classes.paddingLeft_9}>
                                    <Typography variant='body2' className={`${classes.fontWeight_500} ${classes.assignInput}`}>{item.attributes.full_name}</Typography>
                                    <Typography className={`${classes.font_14} ${classes.assignInput}`}>
                                        {item.attributes.email}
                                    </Typography>
                                </Grid>
                            </Grid>,
                    }
                })}
                value={this.state.selectedOptions}
                onChange={this.handleChange}
                onMenuClose={this.props.onClose}
            />
            <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={4} md={3}>
                    <Typography variant='body1' color="textPrimary">{t('Calendar.ErrorMessage.NewTeam')}:</Typography>
                </Grid>
                {this.props.values ?
                    <Grid item xs={12} sm={8} md={9}>
                        {this.props.values.map((employeeId: string) => {
                            return <>
                                <Button className={this.props.selectLanguage === 'ar' ? classes.teamBtn : classes.teamBtn1} disabled={this.props?.disabled}
                                    endIcon={
                                        <ClearIcon
                                            className={classes.clearIcon}
                                            onClick={() => this.props.onClick(employeeId)}
                                        />}>
                                    <Typography className={classes.lineHeight} variant='body2'>
                                        {this.props.getEmployeeData.find((item: { id: string }) =>
                                            item.id == employeeId)?.attributes.full_name}
                                    </Typography>
                                </Button>
                            </>
                        })}
                    </Grid> : ""
                }

            </Grid>
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(selectStyles)(CustomTeamMember);
// Customizable Area End