import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
  Divider,
} from "@material-ui/core";

import "date-fns";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AddAppointmentController, {
  Props,
  configJSON,
} from "./AddAppointmentController";

export default class AddAppointment extends AddAppointmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "1rem 0",
            }}
          >
            <Button
              data-test-id="btnNavigateToAppointments"
              variant="contained"
              color="primary"
              onClick={() => this.navigateToAppointments()}
            >
              {configJSON.viewAllAppointments}
            </Button>
          </Box>
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography noWrap variant="h6">
              {configJSON.availableDate}:{" "}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                data-test-id="txtInputAvailableDate"
                disableToolbar
                fullWidth
                variant="inline"
                placeholder={configJSON.datePickerPlaceholderFormat}
                format={configJSON.webFormat}
                minDate={configJSON.minDate}
                maxDate={configJSON.maxDate}
                value={this.state.available_date}
                onChange={(value) =>
                  this.update({ available_date: this.toMomentDate(value) })
                }
              />
            </MuiPickersUtilsProvider>

            <Divider
              orientation="horizontal"
              flexItem
              style={{ margin: "1rem 0" }}
            />

            <Typography noWrap variant="h6">
              {configJSON.availableFrom}:
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                data-test-id="txtInputAvaialblefrom"
                ampm={false}
                fullWidth
                placeholder={configJSON.timePickerPlaceholder}
                value={this.state.start_time}
                onChange={(value) =>
                  this.update({ start_time: this.toMomentDate(value) })
                }
              />
            </MuiPickersUtilsProvider>

            <Divider
              orientation="horizontal"
              flexItem
              style={{ margin: "1rem 0" }}
            />

            <Typography noWrap variant="h6">
              {configJSON.availableTo}:
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                data-test-id="txtInputAvaialbleto"
                ampm={false}
                fullWidth
                placeholder={configJSON.timePickerPlaceholder}
                value={this.state.end_time}
                onChange={(value) =>
                  this.update({ end_time: this.toMomentDate(value) })
                }
              />
            </MuiPickersUtilsProvider>

            <Divider
              orientation="horizontal"
              flexItem
              style={{ margin: "1rem 0" }}
            />

            <Button
              data-test-id="btnAddAppointment"
              variant="contained"
              color="primary"
              onClick={() => this.addAppointment()}
              disabled={!this.state.token}
            >
              {configJSON.addAppointment}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
