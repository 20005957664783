import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
  InputBase
} from "@material-ui/core";
import {
  withStyles
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  StyledBox,
  StyledGrid,
  StyledGridItem,
  StyledGridItem2,
  StyledTextFields,
  StyledTextFieldsAutoComplete,
} from "./CreateSupplier.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { countryCodes } from "../../dashboard/src/utility.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { DeleteDialog } from "./DeleteDialog.web";
import { withTranslation } from "react-i18next";
import { StyledMenuItem } from "../../Settings/src/CompanyInformation.web";
// Customizable Area End

import CreateSupplierController, {
  Props,
} from "./CreateSupplierController.web";

// Customizable Area Start
interface CurrencyItem {
  "id": number,
  "country": string,
  "created_at": string,
  "updated_at": string,
  "active": boolean,
  "currency": string|undefined,
  "timezone": string
}
// Customizable Area End

export class EditSupplier extends CreateSupplierController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const getConditionalStyle = (isError: any) => {
      return isError ? webStyle.errorStyle : {};
    };
    const {
      supplierName,
      contactName,
      supplierOf,
      mobile,
      email,
      address,
      city,
      state,
      companyInformation,
      zip,
      currency
    } = this.state.formDataEdit;
    const { t } = this.props;
    const renderMessageError = (errorMessage: string) => {
      return (
        <Typography variant="body2" style={webStyle.errorMessage}>
          {errorMessage}
        </Typography>
      )
    };
    const { classes }: any = this.props
    return (
      <>
        <Box className={classes?.suppliersDataContainer}>
          <Typography className={classes?.headerText} variant="subtitle1">
            {t('suppliers.personalInformation')}
          </Typography>
          <StyledBox className={this.state.languageSelected !=="ar" ? `${classes?.formData}` : `${classes?.formDataAr}`}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10} className={classes?.styledGridContainer}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    color="textPrimary"
                    variant="body1"
                    gutterBottom
                  >
                    {t('suppliers.supplierName')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.supplierName)}
                    variant="outlined"
                    fullWidth
                    name="supplierName"
                    value={supplierName}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.supplierName)}
                  />
                  {renderMessageError(this.state.isErrorFormData.supplierName)}
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.contactName')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.contactName)}
                    fullWidth
                    variant="outlined"
                    name="contactName"
                    value={contactName}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.contactName)}
                  />
                  {renderMessageError(this.state.isErrorFormData.contactName)}
                </Grid>
              </StyledGrid>
            </Grid>

            
          </StyledBox>
          <Grid item lg={9} md={12} xs={12}>
            <div className={classes?.hr}>
            </div>
          </Grid>
          <Typography variant="subtitle1" className={classes?.headerText}>
            {t('suppliers.contactInformation')}
          </Typography>
          <StyledBox className={this.state.languageSelected !=="ar" ? `${classes?.formData}` : `${classes?.formDataAr}`}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    gutterBottom
                    color="textPrimary"
                    variant="body1"
                  >
                    {t('suppliers.mobileNumber')}
                  </Typography>
                  <Box style={webStyle.countryCodesContainer}>
                    <Autocomplete
                      data-test-id="handleChangeCountryCode"
                      style={{width: "34%"}}
                      classes={{option: classes?.customOption}}
                      options={countryCodes}
                      value={this.state.countryCodeEdit}
                      onChange={this.handleChangeCountryCodeEdit}
                      autoHighlight
                      renderInput={(params: any) => (
                        <StyledTextFieldsAutoComplete
                          {...params}
                          fullWidth
                          variant="outlined"
                          languageSelected={this.state.languageSelected}
                        >
                        </StyledTextFieldsAutoComplete>
                      )}
                    />
                    <InputBase
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.mobile)}
                      className={
                        this.state.languageSelected === "ar" ?
                        `${classes?.inputBase} ${classes?.inputBaseAr}` :
                        classes?.inputBase
                      }
                      name="mobile"
                      value={mobile}
                      onChange={this.handleChangeFormDataEdit}
                      error={Boolean(this.state.isErrorFormData.mobile)}
                    />
                  </Box>
                  {renderMessageError(this.state.isErrorFormData.mobile)}
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.emailAddress')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.email)}
                    fullWidth
                    variant="outlined"
                    name="email"
                    value={email}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.email)}
                  />
                  {renderMessageError(this.state.isErrorFormData.email)} </Grid>
              </StyledGrid>
            </Grid></StyledBox>
          <Grid 
          item lg={9} md={12} xs={12}>
            <div className={classes?.hr}></div>
          </Grid>
          <Typography 
          className={classes?.headerText} variant="subtitle1">
            {t('suppliers.companyInformation')}
          </Typography>
          <StyledBox 
          style={this.state.languageSelected !== "ar" ? webStyle.addressContainer : webStyle.addressContainerAr}>
            <Grid 
            item lg={9} md={12} xs={12}
            >
              <StyledGrid 
              container spacing={10}
              >
                <Grid 
                item lg={12} md={12} xs={12}>
                  <Typography
                    variant="body1"
                    className={classes?.contactForm}
                    gutterBottom
                    color="textPrimary"
                    dangerouslySetInnerHTML={{ __html: t('suppliers.supplierof') }}
                  >
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.address)}
                    fullWidth
                    variant="outlined"
                    name="companyInformation"
                    value={companyInformation}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.address)}
                  />
                  {renderMessageError(this.state.isErrorFormData.address)} </Grid>
              </StyledGrid>
            </Grid>
            </StyledBox>
          <Grid item lg={9} md={12} xs={12}>  <div className={classes?.hr}></div>
          </Grid>
          <Typography className={classes?.headerText} variant="subtitle1">
            {t('suppliers.address')}</Typography>
          <StyledBox style={this.state.languageSelected !== "ar" ? webStyle.addressContainer : webStyle.addressContainerAr}>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    gutterBottom
                    variant="body1"
                    color="textPrimary"
                  >
                    {t('suppliers.address')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.address)}
                    fullWidth
                    variant="outlined"
                    name="address"
                    value={address}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.address)}
                  />
                  {renderMessageError(this.state.isErrorFormData.address)}
                </Grid>
              </StyledGrid>
            </Grid>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <StyledGridItem item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.city')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.city)}
                    fullWidth
                    variant="outlined"
                    name="city"
                    value={city}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.city)}
                  />
                  {renderMessageError(this.state.isErrorFormData.city)}
                </StyledGridItem>
                <StyledGridItem2 item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.state')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.state)}
                    fullWidth
                    variant="outlined"
                    name="state"
                    value={state}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.state)}
                  />
                  {renderMessageError(this.state.isErrorFormData.state)}
                </StyledGridItem2>
              </StyledGrid>
            </Grid>
            <Grid item lg={9} md={12} xs={12}>
              <StyledGrid container spacing={10}>
                <StyledGridItem item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.zipCode')}
                  </Typography>
                  <StyledTextFields
                    data-test-id="formData"
                    style={getConditionalStyle(this.state.isErrorFormData.zip)}
                    fullWidth
                    variant="outlined"
                    name="zip"
                    value={zip}
                    onChange={this.handleChangeFormDataEdit}
                    error={Boolean(this.state.isErrorFormData.zip)}
                  />
                  {renderMessageError(this.state.isErrorFormData.zip)}
                </StyledGridItem>
                <StyledGridItem2 item lg={6} md={6} xs={12}>
                  <Typography
                    className={classes?.contactForm}
                    variant="body1"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('suppliers.currency')}
                  </Typography>
                  <StyledTextFields
                      select
                      fullWidth
                      variant="outlined"
                      name="currency"
                      inputProps={{ 'data-testid': 'handleChangeCurrency' }}
                      value={this.state.getCurrency}
                      onChange={this.handleChangeCurrency}
                    >
                      <StyledMenuItem value="Select Currency">{t('settings.selectCurrency')}</StyledMenuItem>
                        <StyledMenuItem value={this.state.getCurrency}>
                          {this.state.getCurrency}
                        </StyledMenuItem>
                        {this.state.getCurrencyData &&
                      Array.from(
                        new Set(this.state.getCurrencyData.map((item: CurrencyItem) => item.currency))
                      ).map((currency) => (
                        <StyledMenuItem key={currency} value={currency}>
                          {currency}
                        </StyledMenuItem>
                      ))}
                    </StyledTextFields>
                  {renderMessageError(this.state.isErrorFormData.currency)}
                </StyledGridItem2>
              </StyledGrid>
            </Grid>
          </StyledBox>
        </Box>
        
        <Box 
          style={webStyle.buttonsContainerEditSupplier} className={classes?.buttonsContainer}
        >
           <Box className={classes.buttonsInnerContainer}>
            <Button
              className={`${classes.buttonStyleMobile}  ${classes.btnBoxCancel} ${classes.buttonStyleCommon}`}
              onClick={this.handleCancelButton}
            >
              <Typography
                style={webStyle.buttonTextCancelEditSupplier}
                className={classes.buttonText}
                data-testid='cancelBtn'
                variant="subtitle2"
              >
                {t('suppliers.cancel')}
              </Typography>
            </Button>
            {this.state.status !== "archived" ? 
              <DeleteDialog
                {...this.props}
                title={t('suppliers.archiveSupplier')}
                description={t('suppliers.archiveSupplierConfirmation')}
                status={this.state.status}
                deleteDialogOpen={this.state.deleteDialogOpen}
                handleClickProceedDelete={this.handleClickProceedArchive}
                handleClickOpenDialog={this.handleClickOpenDialog}
                handleCloseDialog={this.handleCloseDialog}
                loader={this.state.archiveSuppliersLoading && (
                  <Box>
                    <LoadingBackdrop
                      loading={this.state.archiveSuppliersLoading}
                    />
                  </Box>
                )}
              />
            :
              <DeleteDialog
                {...this.props}
                title={t('suppliers.deleteSupplier')}
                description={t('suppliers.deleteSupplierConfirmation')}
                handleClickOpenDialog={this.handleClickOpenDialog}
                handleClickProceedDelete={this.handleClickProceedDelete}
                deleteDialogOpen={this.state.deleteDialogOpen}
                handleCloseDialog={this.handleCloseDialog}
                loader={this.state.deleteSuppliersLoading && (
                  <Box>
                    <LoadingBackdrop loading={this.state.deleteSuppliersLoading}/>
                  </Box>
                )}
              />
            }
          </Box>
          
          <Button
            className={`${classes.buttonStyleEdit} ${classes.buttonSaveSuppliers} ${classes.buttonStyleCommon}`}
            onClick={this.handleSaveButtonEdit}
          >
            <Typography
              style={webStyle.buttonTextUpdate}
              className={classes?.buttonTextMobile}
              variant="subtitle2"
            >
              {t('suppliers.update')}
            </Typography>
          </Button>
        </Box>
        <LoadingBackdrop
          loading={this.state.getSingleSupplierLoading || this.state.editSuppliersLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  addressContainer: {
    padding: "1.5rem 1rem 2rem 3rem",
  },
  addressContainerAr: {
    padding: "1.5rem 3rem 2rem 1rem",
  },
  buttonsContainerEditSupplier: {
    padding: "3rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonContainerCancelEditSupplier: {
    maxWidth: "200px",
    border: `1px solid ${CONSTANTS.color.lightGrey}`
  },
  buttonTextCancelEditSupplier: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  buttonTextUpdate: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  errorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },
  countryCodesContainer: {
    width: "100%",
    borderRadius: "8px",
    border: `1px solid ${CONSTANTS.color.paleCement}`,
    display: "flex",
    minHeight: "56px"
  },
  errorStyle: {
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.lightRed,
  },
  redColor: {
    color: CONSTANTS.color.red,
  }
}

const editSupplierStyles: any = withStyles(styles)(EditSupplier);
export default withTranslation()(withRouter(editSupplierStyles));
// Customizable Area End