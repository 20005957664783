import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { searchIcon, dropDownSelectIcon } from "./assets";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import SiteModal from "../../../components/src/CommonSiteModal.web";
import {CommonListPage} from "../../../components/src/CommonListPage"
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
// Customizable Area End

import CustomersRequestController, {
  Props,
} from "./CustomerRequestsController.web";

// Customizable Area Start

// Customizable Area End

export class CustomerJobs extends CustomersRequestController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  sortRequestsData = (rowItem: any) => this.sortByProperty(rowItem.key);

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,t }: any = this.props;
    const CustJobMockRow = [
      { id: 1, key: "job_title", title: `${t('jobs.tableRow.title')}` },
      { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
      { id: 3, key: "scheduling", title: `${t('jobs.tableRow.scheduling')}` },
      { id: 4, key: "team", title: `${t('Calendar.label.team')}` },
      { id: 5, key: "product_name", title: `${t('invoice.menuLabel.productName')}` },
      { id: 6, key: "service_name", title: `${t('invoice.menuLabel.serviceName')}` },
      { id: 7, key: "start_time", title: `${t('jobs.tableRow.startTime')}` },
      { id: 8, key: "start_date", title: `${t('jobs.tableRow.startDate')}` },
      { id: 9, key: "end_time", title: `${t('jobs.tableRow.endTime')}` },
    ];
    return (
      <>
       <SiteModal
          siteModal={this.state.siteDailog}
          classes={classes}
          handleSiteCloseModal={this.handleReqCloseModal}
          t={t}          
          handleChangeSearchCustomerSite={this.handleChangeSearchCustomerRequest}
          siteInformationData={this.state.customerSitesData}
          siteDetails={this.state.siteDetails}
          selectedSiteCustomer={this.selectedReqCustomer}
          createSite={this.goToSitePage}
          />
        <Box>
            <Box 
              className={classes.SitessearchContainer}>
              <Box>
                <img style={{...webStyle.searchIconImage,marginTop:"5px"}} src={searchIcon} alt="" />
              </Box>
              <InputBase
                style={webStyle.inputBaseText}
                placeholder={t("customers.searchJobs")}
                inputProps={{ "aria-label": "search" }}
                className={classes.quotesinputBase}
                value={this.state.searchQuery}
                onChange={this.handleChangeSearchCustomerRequest.bind(this,"")}
              />
            </Box>
          <CommonListPage 
            classes={classes}
            requestMockTableRow={CustJobMockRow}
            sortingProperty={this.state.sortingProperty}
            sortingOrder={this.state.sortingOrder}
            statusOrder={this.state.statusOrder}
            statusData={this.state.statusData}
            sortRequestsData={this.sortRequestsData}
            t={t}
            anchorEl={this.state.anchorEl}
            anchorId={this.state.anchorId}
            siteData={undefined}
            listData={this.state.listData}
            popoverHeading={this.state.popoverHeading}
            handleTooltipClose={this.handleTooltipClose}
            dropDownSelectIcon={dropDownSelectIcon}
            handleTooltipOpen={this.handleTooltipOpen}
            type="JOBS"
            lang = {this.state.lang}
            handleViewData={this.handleViewData}
          />
          <Box
            style={webStyle.buttonsContainerNewJob}
            onClick={this.handleNavigate.bind(this, "/Job/create",localStorage.getItem("customer_id")||"")}
          >
            <CreateQuote>
              <Typography
                style={webStyle.buttonTextCreateNewJob}
               
                variant="subtitle2"
              >
               {t("customers.createJob")}
              </Typography>
            </CreateQuote>
          </Box>
        </Box>
        {this.state.getCustomersRequestLoading ? (
          <Backdrop
            open={this.state.getCustomersRequestLoading}
            style={webStyle.jobsBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  jobsBackdrop: {
    zIndex: 1000 + 1,
    color: "#05037a",
    justifyContent: "center",
    alignItems: "center",
  },
  inputBaseText: {
    width: "100%",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    lineHeight: "16px",
    color:CONSTANTS.color.grey
  },
  searchIconImage: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  customersJobsContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "400px",
  },
  tableCellHeadJob: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    width: "20%",
  },
  tableCellBodyJob: {
    color: "black",
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 500,
   
    cursor: "pointer",
    borderBottom: "none",
    paddingTop: 0,
    width: "20%",
    padding: "0.8rem",
  },
  tableHeadSpanJob: {
    cursor: "pointer",
    padding: "0.8rem",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "0 5px",
  },
  noJobContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "3rem",
  },
  noJobText: {
    color: "#34313a",
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  tableHeadTextJob: {
    fontSize: "18px",
  },
  noJobSubText: {
    color: "#999999",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  buttonsContainerNewJob: {
    padding: "4rem 0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonTextCreateNewJob: {
    color: "#ffffff",
    fontFamily: "Rubik",
    fontSize: "22px",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  dropDownIconSelect: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)",
  },
};

export default withTranslation()(withStyles(styles)(CustomerJobs));
// Customizable Area End
