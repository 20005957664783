import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  Hidden
} from "@material-ui/core";
import {
  tickIcon
} from "./assets";
import "./Style.css";
import CompareLamsaFeatures from "./CompareLamsaFeatures.web";
import { CONSTANTS } from "../../../components/src/theme";
import StyleDialog from "../../../components/src/StyledDialog";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "./Swiper.css";
// Customizable Area End

import PricingController, {
  Props,
} from "./PricingController.web";

// Customizable Area Start
interface YearlyData {
  id: string;
  activeIcon: string;
  icon: string;
  title: string;
  planPrice: string;
  currency: string;
  body: string;
  planTerms: { id: number; plan_type: string; discription: string; }[];
  buttonBody: string;
  buttonText: string;
  choosen: boolean
}

interface IData {
  id: number;
  plan_type: string;
  discription: string;
}
interface ButtonType {
  display: string;
  justifyContent: string;
  alignItems: string;
  backgroundColor: string;
  borderRadius: string;
  border: string;
  padding: string;
  maxWidth: string;
  width: string;
  marginTop: string;
}
// Customizable Area End

class Yearly extends PricingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const buttonStyleYearly = (item: YearlyData) => {
      const buttonText = "ابدأ النسخة التجريبية المجانية";
      const inactiveStyle: Record<string, string> = {};
      if (this.props.disabled && item.planPrice === "0") {
        inactiveStyle.border = `1px solid ${CONSTANTS.color.metallicGrey}`;
        inactiveStyle.backgroundColor = CONSTANTS.color.metallicGrey;
      }
      const backgroundStyle: Record<string, string> = {}
      if(this.props.route === "FreeTrial" && this.props.disabled && item?.planPrice === "0") {
        backgroundStyle.backgroundColor = CONSTANTS.color.metallicGrey;
      }
      const activeStyle = (this.state.activePlan === item?.planPrice) ? { backgroundColor: CONSTANTS.color.thickBlue } : {};
      let buttonStyle: ButtonType = { ...webStyle.buttonContainer, ...inactiveStyle, ...activeStyle, ...backgroundStyle };
      if (this.state.languageSelected === "ar" && item.buttonText === buttonText) {
        buttonStyle = { ...buttonStyle, maxWidth: "248px" };
      }
      return buttonStyle;
    }

    const textStyleYearly = (item: YearlyData) => {
      const { route } = this.props;
      const { activePlan } = this.state;
  
      const isActive = activePlan === item?.planPrice;
      const isFreeTrial = route === "FreeTrial" && item?.planPrice === "0";

      const style = {
        ...webStyle.buttonText,
        color: isActive ? CONSTANTS.color.white : CONSTANTS.color.thickBlue,
      };

      if (isFreeTrial) {
        style.color = CONSTANTS.color.coolDarkGrey;
      }
      return style;
    }

    const yearlyData = (item: YearlyData) => {
      return (
        <Grid item lg={3} md={4} sm={6} xs={12} key={item.id} className="gridContainer">
          <Box
            data-test-id="handleSelectPlan"
            style={
              this.state.activePlan === item.planPrice ?
              {...webStyle.planContainer, border: `1.5px solid ${CONSTANTS.color.thickBlue}`} :
              webStyle.planContainer
            }
            onClick={() => this.handleSelectPlan(item.planPrice)}
            >
            <img style={webStyle.inactiveCheck} src={this.state.activePlan === item.planPrice ? item.activeIcon : item.icon} alt="" />
            <Box style={webStyle.planInnerContainer}>
              <Typography variant="subtitle1" style={webStyle.tryUs} className="tryUs">
                {item.title}
              </Typography>
              <Typography variant="h3" style={webStyle.planPrice} className="planPrice">
                {item.currency} {item.planPrice}
              </Typography>
              <Typography style={webStyle.planPriceBody}>
                {item.body}
              </Typography>
              <Box style={webStyle.termsContainer}>
                {item?.planTerms?.map((i: IData) => (
                  <Box style={this.state.languageSelected === "ar" ? {...webStyle.tickContainer, gap: "0.5rem"} : webStyle.tickContainer} key={i?.id}>
                    <img style={webStyle.tickIcon} src={tickIcon} alt="" />
                    <Typography
                      style={webStyle.tickText}
                      variant="caption"
                      dangerouslySetInnerHTML={{
                        __html: (`${i?.discription}`)
                      }}
                    ></Typography>
                  </Box>
                ))}
              </Box>
              <Button
                data-test-id="handleButtonStartUsing"
                style={buttonStyleYearly(item)}
                onClick={() => this.handleButtonStartUsing(item)}
              >
                <Typography
                  style={textStyleYearly(item)}
                  variant="body1"
                >
                  {item.buttonText}
                </Typography>
              </Button>
              <Typography
                style={
                  (this.props.route === "FreeTrial" && item.planPrice === "0") ?
                  {...webStyle.freeTrial, color: CONSTANTS.color.coolDarkGrey} :
                  webStyle.freeTrial
                }
              >
                {item.buttonBody}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );
    }
    
    return (
      <Grid container style={webStyle.container}>
        <Hidden smUp>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {this.state.pricingPlanYearlyData?.map((item: YearlyData) => {
              return (
                <SwiperSlide style={webStyle.swiperStyle}>
                  {yearlyData(item)}
                </SwiperSlide>
              );
            })}
            </Swiper>
        </Hidden>
        <Hidden xsDown>
          {this.state.pricingPlanYearlyData?.map((item: YearlyData) => {
            return (
              <>
                {yearlyData(item)}
              </>
            );
          })}
        </Hidden>

        <CompareLamsaFeatures {...this.props} />

        <StyleDialog
          planModal={this.state.planModal}
          handleClosePlanModal={this.handleClosePlanModal}
          planType={this.state.planType}
          handleClickProceedPlan={this.handleClickProceedPlan}
          languageSelected={this.state.languageSelected}
        />
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    marginTop: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  swiperStyle: {
    paddingBottom: "1rem"
  },
  planContainer: {
    minHeight: "457px",
    maxWidth: "288px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 10px #ECECEC",
    padding: "1rem",
    cursor: "pointer",
    marginTop: "1rem",
    filter:"drop-shadow(0px 0px 10px rgba(0,0,0,0.05000000074505806))"
  },
  inactiveCheck: {
    width: "24px",
    height: "24px",
  },
  planInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  tryUs: {
    color: CONSTANTS.color.oliveGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  planPrice: {
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    textAlign: "center" as const,
    margin: "5px 0px"
  },
  planPriceBody: {
    opacity: 0.7,
    color: CONSTANTS.color.oliveGrey,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  termsContainer: {
    display: "flex",
    flexDirection: "column" as const,
    marginTop: "1rem"
  },
  tickContainer: {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    marginTop: "1rem"
  },
  tickIcon: {
    width: "11px",
    height: "8px",
  },
  tickText: {
    color: CONSTANTS.color.oliveGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginLeft: "0.75rem"
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.white,
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.thickBlue}`,
    padding: "1rem 0",
    maxWidth: "227px",
    width: "100%",
    marginTop: "1.5rem"
  },
  buttonText: {
    color: CONSTANTS.color.thickBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textTransform: "none" as const
  },
  freeTrial: {
    color: CONSTANTS.color.darkBlue,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
    marginTop: "1rem"
  },
}
export default Yearly;
// Customizable Area End