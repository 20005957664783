import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  taxForm: {
    fullName: string;
    address: string;
    productName: string;
    amount: string;
    SGST: string;
    CGST: string;
  }
  finalTaxData: any;
  isErrorFormData: any


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaxCalculatorWebController extends BlockComponent<
  Props,
  S,
  SS
> {


  // Customizable Area Start

  getTaxatationApicallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [

      getName(MessageEnum.RestAPIResponceMessage)

    ];

    this.state = {
      // Customizable Area Start
      taxForm: {
        fullName: "",
        address: "",
        productName: "",
        amount: "",
        SGST: "",
        CGST: ""
      },
      finalTaxData: {},
      isErrorFormData: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiReCquestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiReCquestCallID === this.getTaxatationApicallId) {
        if (responseJson) {
          this.setState({ finalTaxData: responseJson.data[0] })
        }
      }
      // Customizable Area End
    }
  }


  // Customizable Area Start

  handleChange = (e: any) => {
    let el = e.target;
    let { type, min, max } = el.dataset;
    if (type === 'number') {
      let isNum = configJSON.isNum.test(el.value);
      if (isNum) {
        if (parseInt(el.value) < parseInt(min) || parseInt(el.value) > parseInt(max)) {
          this.setState({
            taxForm: { ...this.state.taxForm }
          })
        }
        else {
          this.setState({
            taxForm: { ...this.state.taxForm, [el.name]: el.value }
          })
        }
      }
    } else {
      this.setState({
        taxForm: { ...this.state.taxForm, [e.target.name]: e.target.value }
      })
    }

  }

  validateForm = (values: any) => {
    const errors: any = {};
    const regexCharacters = /^[A-Za-z]+$/;
    const productCharacter=/^[0-9a-zA-Z]+$/
   
    if (!values.fullName) {
      errors.fullName = "FullName should not be empty";
    } else if (!regexCharacters.test(values.fullName)) {
      errors.fullName = "FullName should be only characters";
    }
    if (!values.address) {
      errors.address = "Address should not be empty";
    }
    if (!values.productName) {
      errors.productName = "Product name should not be empty";
    } else if (!productCharacter.test(values.productName)) {
      errors.productName = "Product name should be only alpha numeric";
    }
    if (!values.amount) {
      errors.amount = "Amount should not be empty";
    }
    if (!values.CGST) {
      errors.CGST = "CGST should not be empty";
    }
    if (!values.SGST) {
      errors.SGST = "SGST should not be empty";
    }

    return errors;
  };

  submitButton = async () => {
    this.setState({ isErrorFormData: this.validateForm(this.state.taxForm) },
      () => {
        if (Object.keys(this.state.isErrorFormData).length === 0) {
          this.calculateTax();
        }
      }
    );
  }

  calculateTax = () => {
    const headers: any = {
      "content-Type": configJSON.validationApiContentType
    }
    const data = {
      "data": {
        "name": this.state.taxForm.fullName,
        "address": this.state.taxForm.address,
        "product_name": this.state.taxForm.productName,
        "product_price": this.state.taxForm.amount,
        "state_gst": this.state.taxForm.SGST,
        "central_gst": this.state.taxForm.CGST
      }

    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTaxatationApicallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taxastionApicall
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  // Customizable Area End
}
