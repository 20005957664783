import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { checkLoggedInUser } from "../../CustomisableUserProfiles/src/utility.web";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  show: boolean;
  selectedFaq: any;
  faqsData: any;
  getFaqsLoading: boolean;
  searchFaq: string;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFaqsApiCallId: string = "";
  searchFaqsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      show: false,
      selectedFaq: null,
      faqsData: [],
      getFaqsLoading: false,
      searchFaq: "",
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    if (!localStorage.getItem("authToken")) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }

    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });
    
    this.getFaqs();
    this.setState({ getFaqsLoading: true });
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessage = (error: string) => {
    if (error) {
      this.errorMessageHandler(error);
    }
  }
  
  errorMessageHandler = async (error: any) => {
    const { token } = error;
    if(token == configJSON.tokenExpired || token == configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  tokenExpired = (isEmployee: boolean) => {
    setTimeout(this.setTimeOutHandler.bind(this, isEmployee), 2000);
  }

  setTimeOutHandler = (isEmployee: boolean) => {
    localStorage.clear();
    this.props.navigation.history?.push(isEmployee ? "/Employee/Login" : "/Login");
  }

  extractDataFromMessage = (message: Message) => {
    return {
      apiRequestCallId: message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ),
      responseJson: message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ),
      errorResponse: message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ),
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getFaqsApiCallId) {
        if (!responseJson.errors) {
          this.setState({ getFaqsLoading: false });
          this.setState({ faqsData: responseJson.faqs?.data });
        } else if (responseJson.errors) {
          this.setState({ getFaqsLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ getFaqsLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    this.handleSearchFaqs(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleSearchFaqs = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.searchFaqsApiCallId) {
        if (!responseJson.errors) {
          this.setState({ faqsData: responseJson.faqs?.data });
        } else if (responseJson.errors) {
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  
  handleShowAnswer = (id: number) => {
    if (this.state.selectedFaq === id) {
      this.setState((prevState) => ({
        show: !prevState.show,
      }));
    } else {
      this.setState({
        selectedFaq: id,
        show: true,
      });
    }
  };

  handleChangeSearchFaqs = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchFaq: e?.target.value }, () => {
      this.searchFaqs();
    });
  }

  getFaqs = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFaqsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.faqsAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.faqsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  searchFaqs = async () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchFaqsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchFaqsAPIEndPoint}${this.state.searchFaq}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.faqsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}
// Customizable Area End
