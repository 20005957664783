//@ts-nocheck
import React from 'react';
// Customizable Area Start
import { Button, Dialog, Grid,DialogTitle, Typography,DialogContent,DialogActions,Avatar } from '@material-ui/core';
import { withSnackbar } from "../../../components/src/toastweb";
import {  StyledTextFieldRequest } from "../../RequestManagement/src/Utility.web";
import EmpSelectInput from './EmpSelectInput.web'
import { StyledCheckbox } from './Calendar.web'
import ClearIcon from '@material-ui/icons/Clear';
import CustomSearchableSelect from '../../../components/src/CustomSearchableSelect.web';
import withCalendarStyles from "../../appointmentmanagement/src/CommonStyle.web";
import CustomDatePicker from "../../../components/src/DatePicker";
import { dateMinusOne } from "../../../components/src/CommonFunction";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from 'yup'
export const configJSON = require("./config");
// Customizable Area End
// Customizable Area Start

interface Props {
    navigation?: any;
    id?: string;
    history?: any
    isOpen: Function
    onClose: Function
    isEmployee:any
    getEmployeeData:any
    appointmentForm:any
    selectedEventData:any
    updateTask:Function
}
// Customizable Area End
export class Task extends React.Component {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    finalErrorMessage(error: any, touch: any,classes:any) {
        if (error) {
            if (touch) {
                return <div className={`${classes.errorText} ${classes.text_red}`}>
                    {error}
                </div>
            }
        }
    }
    dueDate(){
        const date = moment(this.props.selectedEventData.due_date, 'DD-MM-YYYY')
        return  moment.utc(date).toDate()
    }
    taskInitialValues = () => ({
        title: this.props.openEditTaskModal ? this.props.selectedEventData.title : '',
        due_date: this.props.openEditTaskModal ? this.dueDate(): this.props.appointmentForm.start,
        employee_id: this.props.openEditTaskModal ? this.props.selectedEventData.employee_id : '',
        employee_name: this.props.openEditTaskModal ? this.props.selectedEventData.employee_name : '',
        employee_email: this.props.openEditTaskModal ? this.props.selectedEventData.employee_email : '',
        employee_image: this.props.openEditTaskModal ? this.props.selectedEventData.employee_image||'' : '',
        urgency: this.props.openEditTaskModal ? this.props.selectedEventData.urgency : '',
        email_team: this.props.openEditTaskModal ? this.props.selectedEventData.email_team : ''
    })
    formTitle() {
        const { classes,t } = this.props
        return <>
            <DialogTitle>
            {this.props.viewTaskDetails &&
                    <Grid container style={{ justifyContent: 'end' }}>
                        <ClearIcon className={classes.text_lightBrown} onClick={this.props.onClose} />
                    </Grid>
                }
                <Typography variant='h6' className={`${classes.text_lightBrown} ${classes.paddingTop_15}`}>
                    {this.props.isOpenNewTask && `${t('Calendar.newTask')}`}
                    {this.props.openEditTaskModal && `${t('Calendar.editTask')}`}
                    {this.props.viewTaskDetails && <span>{t('Calendar.task')} - {this.props.selectedEventData.title}</span>}
                </Typography>
            </DialogTitle>
        </>
    }
    viewEmpData(classes){
        return <Grid container alignItems="center">
           <Grid item>
               <Avatar style={{ marginBottom: '12px', marginTop: '15px' }} src={this.props.selectedEventData.employee_image || ''} />
           </Grid>
            <Grid item style={{ paddingLeft: '9px' }}>
                <Typography variant="body1" className={`${classes.font_gray} ${classes.fontWeight_500}`}>
                    {this.props.selectedEventData.employee_name}
                </Typography>
                <Typography className={`${classes.font_gray} ${classes.font_14}`}>
                    {this.props.selectedEventData.employee_email}
                </Typography>
            </Grid>
       </Grid>
       }
    emailTeam({ values, handleChange }) {
        const { classes,t } = this.props
        return <Grid container alignItems="center"
            className={`${classes.pl_4} ${classes.mt_10}`}
        >
            {!this.props.viewTaskDetails && 
                <StyledCheckbox
                    inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                    onChange={handleChange}
                    name='email_team'
                    checked={values.email_team}
                />
             } 
            {this.props.viewTaskDetails && 
                <StyledCheckbox
                    disabled
                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                    name='email_team'
                    checked={this.props.selectedEventData.email_team}
                />
            }
            <Typography variant='body1' className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.pr_9}` : classes.font_gray}>{t('Calendar.label.emailTeam')}</Typography>
        </Grid>
    }
    viewUrgency(classes) {
        return <> {this.props.viewTaskDetails &&
            <Typography variant='body1' className={classes.font_gray}>{this.props.selectedEventData.urgency}</Typography>
        }
        </>
    }
    formBtn(){
        const { classes,t } = this.props 
        return <>
            {!this.props.viewTaskDetails &&
                 <DialogActions className={classes.p_25}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <Button variant="outlined"
                                data-test-id='close-modal'
                                className={this.props.isOpenNewTask ? `${classes.cancelBtn} ${classes.text_border_red} ${classes.text_red}` : `${classes.deleteBtn} ${classes.text_border_gray}`}
                                onClick={this.props.onClose}
                            >
                                <Typography variant='body1'>{t('Calendar.button.cancel')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item sm={5} className={classes.btn_hide_smallScreen}>
                            {!this.props.isOpenNewTask &&
                                <Button variant="outlined"
                                    onClick={this.props.deleteTask}
                                    className={ this.props.selectLanguage ==='ar' ? `${classes.deleteBtn} ${classes.mr_15} ${classes.text_border_red} ${classes.text_red}` : `${classes.deleteBtn} ${classes.marginLeft_25} ${classes.text_border_red} ${classes.text_red}`}
                                >
                                    <Typography variant='body1'>{t('Calendar.button.delete')}</Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid xs={6} item sm={4} style={{ textAlign: 'end' }}>
                            <Button variant="outlined"
                                data-test-id='submit-btn'
                                type='submit'
                                className={classes.saveBtn}
                            >
                                <Typography variant='body1'>{t('Calendar.button.save')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.btn_hide_largeScreen}>
                            {!this.props.isOpenNewTask &&
                                <Button variant="outlined"
                                    className={`${classes.deleteBtn} ${classes.mt_10} ${classes.text_border_red} ${classes.text_red}`}
                                >
                                    <Typography variant='body1'>{t('Calendar.button.delete')}</Typography>
                                </Button>
                            }
                        </Grid>
                    </Grid>
             </DialogActions>
            } 
        </>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes,t } = this.props;
        const validationSchema = Yup.object().shape({
            title: Yup.string().required(`${t('Calendar.ErrorMessage.title')}`),
            employee_id: Yup.string().required(`${t('Calendar.ErrorMessage.team')}`),
            due_date: Yup.date().min(dateMinusOne(new Date()), `${t('Calendar.ErrorMessage.minDate')}`).required(`${t('Calendar.ErrorMessage.dueDate')}`),
            urgency: Yup.string().required(`${t('Calendar.ErrorMessage.urgency')}`)
        })
        const names = [
            { value: 'high', label: `${t('Calendar.menuItem.name.high')}` },
            { value: 'medium', label: `${t('Calendar.menuItem.name.medium')}` },
            { value: 'low', label: `${t('Calendar.menuItem.name.low')}` },
        ];
        // Customizable Area End
        return (
            // Customizable Area Start
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.onClose}
                PaperProps={{
                    style: {
                        width: "500px",
                        borderRadius: '20px',
                    },

                }}>
                {this.formTitle()}
                <Formik
                    initialValues={this.taskInitialValues()}
                    validationSchema={validationSchema}
                    data-test-id='formikTaskForm'
                    onSubmit={(values) => {this.props.openEditTaskModal ?
                            this.props.updateTask(values) : this.props.createTask(values)
                        this.props.onClose()
                    }}
                >
                    {({ errors, values, touched, handleSubmit, handleChange, setFieldValue }) => (
                        <form onSubmit={handleSubmit} data-test-id='submit-btn' style={{ overflow: 'auto' }}>
                            <DialogContent style={{ overflow:'hidden', paddingTop: '0px' }}>
                                <Typography variant='body1' className={`${classes.mb_5} ${classes.text_lightGrey}`}>
                                {t('Calendar.label.titleLabel')}{!this.props.viewTaskDetails &&<span className={`${classes.text_red}`}>*</span>}
                                </Typography>
                                {!this.props.viewTaskDetails ?
                                    <StyledTextFieldRequest
                                        variant="outlined"
                                        inputProps={{ 'aria-label': 'title' }}
                                        fullWidth
                                        name='title'
                                        onChange={handleChange}
                                        value={values.title}
                                    /> : <Typography variant='body1' className={classes.font_gray}>
                                        <div className={classes.titleText}>
                                            {this.props.selectedEventData.title}
                                        </div>
                                    </Typography>
                                }
                                {this.finalErrorMessage(errors.title, touched.title, classes)}
                                 <Typography variant='body1' className={`${classes.mb_5} ${classes.mt_20} ${classes.text_lightGrey}`}>
                                 {t('Calendar.label.assignTeamMember')}{!this.props.viewTaskDetails &&<span className={`${classes.text_red}`}>*</span>}
                                </Typography>
                                {!this.props.viewTaskDetails &&
                                    <EmpSelectInput
                                        getEmployeeData={this.props.getEmployeeData}
                                        values={values}
                                        isEmployee={this.props.isEmployee}
                                        selectLanguage={this.props.selectLanguage}
                                        type='task'
                                        isActive={this.props.isActive}
                                        data-test-id='employeeInputDropdown'
                                        onChange={(selected) => {
                                            setFieldValue('employee_id', selected&&selected.value)
                                        }}
                                        onClick={(employeeId) => setFieldValue("employee_id", values.employee_id !== employeeId)}
                                    />
                                }
                                {this.props.viewTaskDetails && this.viewEmpData(classes)}
                                {this.finalErrorMessage(errors.employee_id, touched.employee_id, classes)}
                                {this.emailTeam({values,handleChange})}
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body1' className={`${classes.mb_5} ${classes.text_lightGrey} ${classes.mt_15}`}>
                                        {t('Calendar.label.dueDate')}{!this.props.viewTaskDetails &&<span className={`${classes.text_red}`}>*</span>}
                                        </Typography>
                                        {!this.props.viewTaskDetails &&
                                            <CustomDatePicker
                                                selectedDate={values.due_date}
                                                dateChange={(date) =>
                                                    setFieldValue('due_date', date)
                                                }
                                            />
                                        }
                                        {this.finalErrorMessage(errors.due_date, touched.due_date, classes)}
                                        {this.props.viewTaskDetails &&
                                            <Typography variant='body1' className={classes.font_gray}>{this.props.selectedEventData.due_date}</Typography>
                                        }
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={this.props.viewTaskDetails? 12 : 6} className={this.props.viewTaskDetails? classes.mt_24 : classes.mt_0}>
                                        <Typography variant='body1' className={`${classes.mb_5} ${classes.text_lightGrey} ${classes.mt_15}`}>
                                        {t('Calendar.label.urgency')}{!this.props.viewTaskDetails &&<span className={`${classes.text_red}`}>*</span>}
                                        </Typography>
                                        {!this.props.viewTaskDetails &&
                                        <CustomSearchableSelect
                                            onChangeFun={(selected) => {
                                                setFieldValue('urgency', selected && selected.value )
                                            }}
                                            data-test-id='select-urgency'
                                            defaultVal={{
                                                label: names.find((item) =>
                                                    item.value == values.urgency)?.label || values.urgency, value: values.urgency,
                                            }}
                                            menuPlacement='top'
                                            padding='10px'
                                            name="urgency"
                                            options={names}
                                        />}
                                        {this.viewUrgency(classes)}
                                        {this.finalErrorMessage(errors.urgency, touched.urgency, classes)}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            {this.formBtn()}
                        </form>
                    )}
                </Formik>
            </Dialog>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const CalendarSnackbar = withSnackbar(Task)
export default(withCalendarStyles)(CalendarSnackbar);
// Customizable Area End
