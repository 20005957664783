Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'
exports.storageToken="authToken"
exports.appointmentAPiEndPoint ='bx_block_appointment_management/appointments'
exports.taskApiEndPoint='bx_block_request_management/task_allocates'
exports.getEmployeeApiEndPoint='bx_block_appointment_management/appointments/get_employees'
exports.getCustomers="bx_block_request_management/customers_list"
exports.getSites="bx_block_location/site_informations"
exports.jobApiEndPoint='bx_block_request_management/jobs'
exports.createAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentAPiMethod = 'GET'
exports.updateAppointmentAPiMethod = 'PUT'
exports.updateTaskAPiMethod = 'PATCH'
exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'



exports.addAppointmentAPiMethod = "POST";

exports.getAppointmentListAPiMethod = "GET";

exports.format = "DD/MM/YY";
exports.serverFormat = "YY/MM/DD";
exports.webFormat = "dd/MM/yyyy";
exports.timeFormat = "HH:mm A";
exports.minDate = "01/01/20";
exports.maxDate = "01/01/25";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.availableDate = "Available Date";
exports.availableFrom = "Available From";
exports.availableTo = "Available To";
exports.viewAllAppointments = "View all Appointments";
exports.addAppointment = "Add Appointment";
exports.confirm = "Confirm";
exports.cancel = "Cancel";
exports.datePickerPlaceholder = "Select Date";
exports.datePickerPlaceholderFormat = "DD/MM/YY";
exports.timePickerPlaceholder = "Select Time";
exports.getList = "Get List";
exports.slot = "Slot";
// Customizable Area End
