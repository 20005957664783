//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import AES from "crypto-js/aes";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler: any;
  history: any
  location: any
  // props:any

  // Customizable Area End
}


interface S {
  // Customizable Area Start
  formData: {
    email: string;
    textPassword: string;
  };
  isErrorFormData: any;
  showPassword: boolean;
  tabvalue: number;
  tabname: any,
  selectedLanguage: any
  loginLoader:boolean
  checkBox:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<
  Props,
  S,
  SS> {
  // Customizable Area Start
  loginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      formData: {
        email: "",
        textPassword: "",
      },
      isErrorFormData: {},
      showPassword: false,
      tabvalue: 0,
      tabname: "",
      selectedLanguage: localStorage.getItem("lang"),
      loginLoader:false,
      checkBox:"false"
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const pathName = this.props.location.pathname;
    this.initTabChange(pathName)
    const checkBox = localStorage.getItem("terms")
    this.setState({checkBox})

    const email = await getStorageData("loginEmail");
    this.retrieveLoginCreds(email)
    // Customizable Area End
  }

  // Customizable Area Start
  retrieveLoginCreds = (email: any) => {
    if (email) {
      this.setState({ formData: { ...this.state.formData, email } })
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage) );

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.loginApiCallId) {
        if (responseJson && responseJson.message) {
          this.setState({ loginLoader: true });
          localStorage.setItem("otpCount", JSON.stringify(0))
          localStorage.setItem("comingFrom", "Login")
          localStorage.setItem("OtpToken", responseJson.token)
          localStorage.setItem("email", this.state.formData.email)
          localStorage.removeItem("terms")
          localStorage.removeItem("cameFrom")
          removeStorageData("loginEmail")
          setTimeout(this.handleNavigation.bind(this, "OtpPassword"), 500)
        } else if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.setState({ loginLoader: true });
          this.props.openToastHandler(this.props.t('OtpScreen.loginSuccess'), "success",)
          this.saveLoggedInUserData(responseJson);
          localStorage.setItem("account_id", responseJson.data.id);
          localStorage.setItem("isEmployee", JSON.stringify(false))
          localStorage.removeItem("profileDataFetched");
          localStorage.removeItem("otpCount");
          localStorage.removeItem("Resend email")
          localStorage.removeItem("email")
          localStorage.removeItem("comingFrom");
          localStorage.removeItem("OtpToken")
          setTimeout(this.handleNavigation.bind(this, "Dashboard"), 500)
        } else {
          //Check Error Response
          removeStorageData("loginEmail");
          const { otp, failed_login, pin } = responseJson.errors[0]
          this.props.openToastHandler(otp || failed_login || pin, "error")
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start  
   
  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
        const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

        msg.addData(
            getName(MessageEnum.SessionResponseData),
            JSON.stringify(responseJson)
        );
        msg.addData(
            getName(MessageEnum.SessionResponseToken),
            responseJson.meta.token
        );
        this.send(msg);
    }
}
  handleNavigation = (url:string)=>{
    this.props.navigation.navigate(url)
  }
  initTabChange = (pathName:any)=>{
    if (pathName == "/Register") {
      this.setState({ tabvalue: 1 })
    } else {
      this.setState({ tabvalue: 0 })
    }
  }
  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.props.history.push(newValue === 0 ? '/Login' : '/Register')
  }
  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  validateTextPass(value: string, errors: any) {

    const { t }: any = this.props;
    const { atleastOneCapsRegex,
      atleastLowerCaseRegex,
      specialCharRegex,
      digitsRegex } = configJSON.passwordValidators;
    if (!value) {
      //@ts-ignore
      errors.textPassword = t("BoAuthErrors.password.empty");
    }

    else if (!atleastOneCapsRegex.test(value)) {
      errors.textPassword = t("BoAuthErrors.password.validUpperCase");
    }
    else if (!atleastLowerCaseRegex.test(value)) {
      errors.textPassword = t("BoAuthErrors.password.validLowerCase");
    }
    else if (!specialCharRegex.test(value)) {
      errors.textPassword = t("BoAuthErrors.password.validSpecialChar");
    }
    else if (!digitsRegex.test(value)) {
      errors.textPassword = t("BoAuthErrors.password.validNumeric");
    }
    else if ((value).length < 8) {
      errors.textPassword = t("BoAuthErrors.password.passwordLength");
    }
  }

  validateEmail(value: string, errors: any) {
    const { t }: any = this.props

    const emailvalidation = configJSON.emailRegex;
    if (!value) {
      errors.email = t("BoAuthErrors.login.empty");
    } else if (!emailvalidation.test(value)) {
      errors.email = t("BoAuthErrors.login.validEmail");
    }
  }

  encryptApiKey = (apiKey:any, encryptionKey:any) => {
    const encryptedApiKey = AES.encrypt(apiKey, encryptionKey).toString();
    return encryptedApiKey;
  };

  handleLoginChange = (e: any) => {
    const errors: any = {};
    let { name, value } = e.target;

    (name === 'textPassword') && this.validateTextPass(value, errors);
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
      isErrorFormData: errors
    });
   
  }


  storeLoginCreds = async () => {
    await setStorageData("loginEmail", this.state.formData.email)
  }

  handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const lang = localStorage.getItem('lang')
    if(lang===null){
      localStorage.setItem("lang", "en")
    }
    e.preventDefault();
    const errors: any = {};
    let { email, textPassword } = this.state.formData;

    this.validateEmail(email, errors);
    this.validateTextPass(textPassword, errors);

    this.setState({
      isErrorFormData: errors
    }, () => {
      if (Object.keys(this.state.isErrorFormData).length === 0) {
        this.emailLogin();
      }
    });
  }

  handleForgotPassword = () => {
    localStorage.setItem("role","BO")
    this.props.navigation.navigate("PasswordForgot")
  }
  handleForgotPassword = () => {
    localStorage.setItem("role","BO")
    this.props.navigation.navigate("PasswordForgot")
  }
  selectLang = () => {
    const lang = localStorage.getItem('lang')
    if (lang === null) {
      return 'en'
    } else {
      return lang
    }
  }
  emailLogin = async () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.formData.email.trim(),
      password: this.state.formData.textPassword,
      terms:this.state.checkBox ==="true"
    };

    const data = {
      type: "email_account",
      attributes: attrs,
     
    };

    const httpBody = {
      data: data,
    };

     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.loginApiEndPoint}?lang=${this.selectLang()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}
// export default withTranslation()(LoginController)
// Customizable Area End