import React from "react";

// Customizable Area Start
import { Box, List, Typography,Hidden,Tab } from "@material-ui/core";
import { changePassword, logOut, profile } from "./assets";
import { withRouter } from "react-router-dom";
import {StyledTabs} from "./ProfileSubSideBar.web"
import {backIconMobile} from "../../CustomisableUserProfiles/src/assets"
import { withTranslation } from "react-i18next";
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { CONSTANTS  } from "../../../components/src/theme";
// Customizable Area End

import EmployeeProfileController, {
  Props,
} from "./EmployeeProfileController.web";

// Customizable Area Start

export class EmployeeProfileSubSideBar extends EmployeeProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const EmployeeStyledTab = withStyles((theme:Theme)=>({
      root: {
       
        "& .MuiTab-wrapper": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: this.state.lang === "ar" ? "0" : "1rem",
          textTransform: "capitalize" as const,
          lineHeight: "20px",
          gap:"0.8rem",
          fontWeight: CONSTANTS.fontWeight.fontWeight400,
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            marginLeft: "0 !important"
          
          },
        },
        fontFamily: "Rubik",
        fontSize: "1.1667rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.9rem !important",
          width:"33%"
        },
        "& .MuiSvgIcon-root": {
          marginRight: "1rem",
          
        },
        "&:hover": {
          borderRadius: "10px",
          backgroundColor: CONSTANTS.color.darkBlue,
          color: CONSTANTS.color.white,
          "& img": {
            filter: "brightness(0) invert(1)"
          }
        },
        "& .Mui-selected": {
          color: "#ffffff",
        },
      },
    }))(Tab);
    const { classes , width, t}: any = this.props;
    const empsideBarData = [
      {
        id: 1,
        height: "22px",
        title: t("boProfile.profileInformation"),
        icon: profile,
        width: "22px",

      },
      {
        id: 2,
        title: t("boProfile.changePassword"),
       
        width: "23px",
        height: "21px",
        icon: changePassword,
      },
      {
        id: 3,
        title: t("boProfile.logout"),
        icon: logOut,
        width: "21px",
        height: "20px",
      },
    ];
    const isMobile = ['sm','xs'].includes(width);
    const renderContent = (isMob:any) =>{
     
      return (
      <Box>
        <Box>
            <List disablePadding style={{ padding: 1 }}>
              <Box style={webStyle.backIconContainerEmployee}>
                <Hidden smUp>
                  <img width={"12px"} height={"24px"} data-test-id="backIcon" src={backIconMobile} onClick={this.handleBackIconEmployee} alt="" />
                </Hidden>
                <Typography variant="subtitle1"
                  className={this.state.lang === "en" ?
                    classes.empsideBarTitle : classes.empsideBarTitleAr
                  }>
                  {t("boProfile.profileSettings")}
                </Typography>
              </Box>
              <StyledTabs
              data-test-id="handleTabValueChange"
              orientation={isMob ? 'horizontal' : 'vertical'} 
              variant="scrollable"
              value={this.props.profileActive}
              onChange={this.emphandleTabValueChange}
              aria-label="Vertical tabs example"
              scrollButtons={false}
            >
              {empsideBarData.map((item: any) => {
                return (
                  < EmployeeStyledTab
                    icon={
                      <img
                        style={
                          this.props.profileActive === item.id - 1
                            ? {
                                width: item.width,

                              
                                marginBottom: "0px",
                                height: item.height,
                                filter: "brightness(0) invert(1)",
                              }
                            : {
                                marginBottom: "0px",
                                width: item.width,
                                height: item.height,
                              
                              }
                        }
                        src={item.icon}
                        alt=""
                      />
                    }
                    label={item.title}
                    key={item.id}
                   
                  />
                );
              })}
            </StyledTabs>
          </List>
        </Box>
      </Box>
    )}

    return (
      
      <>
        <Box>{renderContent(isMobile)}</Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  backIconContainerEmployee: {
    display: "flex",
    alignItems: "center",
    marginBottom:"2rem",
    gap:"1rem"
  },
};

// @ts-ignore
const loginRouter =withTranslation()(withRouter(EmployeeProfileSubSideBar));
export default loginRouter;
// Customizable Area End
