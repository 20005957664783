import React from "react";

// Customizable Area Start
import {
  Box,
  Container
} from "@material-ui/core";
import {
  withStyles
} from "@material-ui/core/styles";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { StyledRoot } from "./utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
import CreateController, {
  Props,
} from "./CreateController.web";
// Customizable Area End

export class Create extends CreateController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined}  oldProps={undefined} history={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.create')} active={0} active1={false} active2={false} />
        <StyledRoot>
          <Box
            sx={{
              marginTop: "8rem",
            }}
            className={classes.boxContainer}
          >
            <Container maxWidth={false}>
              <h2>Content</h2>
            </Container>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important"
    }
  }
});

const webStyle = {
  
}
const CreateSnackbar = withSnackbar(Create)
export default withTranslation()(withStyles(styles)(CreateSnackbar));
// Customizable Area End