// Customizable Area Start

// @ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import { CONSTANTS } from '../../../components/src/theme';
const withRequestStyles = (wrappedComponent) => {
    return withStyles((theme) =>
    createStyles({
       
        font_lightBrown:{
            color:CONSTANTS.color.lightBrown
        },
        fontWeight_600:{
            fontWeight:CONSTANTS.fontWeight.fontWeight600
        },
        smLayout: {
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        customer: {
            border: " 1px solid rgba(0,0,0,0.1)",
            width: "346px",
            height: "86px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            cursor: "pointer"
        },
        serviceInputCreate: {
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                maxWidth: "100%"
            },
            [theme.breakpoints.up("xl")]: {
                maxWidth: " 690px"
            },

        },
        margin: {
            margin: "4rem 0 4rem",
            [theme.breakpoints.down("xs")]: {
                margin: '0px'
            },
            requestInputCreate: {
                width: "100%"
            },
        },
        margin20:{
            margin: "2rem 0 2rem",
            [theme.breakpoints.down("xs")]: {
                margin: '0px'
            }, 
        },
        text_align:{
            alignItems:"center",
            display:"flex"
        },
        ml_6:{
            marginLeft:"6px",
            marginRight:"6px"
        },
        ml_10:{
            marginLeft:"10px",
            marginRight:"10px"
        },
        lineHeight:{
            lineHeight:'initial'
        },
        flexDirection:{
            [theme.breakpoints.down("xs")]: {
                flexDirection:'column-reverse'
             },
        },
        switchText:{
            color:CONSTANTS.color.darkBlue,
            fontWeight: CONSTANTS.fontWeight.fontWeight500,
        },
        switchText1:{
            color:CONSTANTS.color.lightGrey,
            fontWeight: CONSTANTS.fontWeight.fontWeight500,
        },
        direction:{
            [theme.breakpoints.down("xs")]: {
                direction:'rtl'
             },
        },
        mt_1:{
            [theme.breakpoints.down("xs")]: {
                marginTop:'1rem',
                marginBottom:'20px'
             },
        },
        mt_20:{
            [theme.breakpoints.down("xs")]: {
                marginTop:'-20px'
             },
        },
        ml_16:{
            marginLeft:'16px'
        },
        mr_16:{
            marginRight:"16px"
        },
        p_10:{
            padding:'10px 0px'
        },
        buttonText: {
            fontWeight: CONSTANTS.fontWeight.fontWeight400,
            textAlign: "center" as const,
          },
        requestInputCreate: {
            width: "346px",
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        fieldDisableBg: {
            backgroundColor: "#F2F2F2",
            cursor: "default",
            pointerEvents: "none"
        },
        cancelBtnCreate: {
            width: "200px",
            height: "67px",
            border: "1px solid #999999",
            "&:hover": {
                background: "#ffffff",
            },
            [theme.breakpoints.up("xl")]: {
                width: "200px",
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%",
                height:'48px'
            },
            [theme.breakpoints.between("sm", 'md')]: {
                width: "44%",
            },
        },
        outlinedSaveBtn: {
            width: "219px",
            height: "67px",
            marginRight: "5px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "30px",
                width: "100%",
                marginRight: "0px",

            },
            '&:hover': {
                backgroundColor: "#ffffff",
                color: "#1C18AF",
            }
        },
        selectCustomerButton: {
            width: "219px",
            height: "67px",
            "&:hover": {
                background: "#1c18af",
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        drawerLayout: {
            zIndex: 1,
            height: "100px",
            [theme.breakpoints.down("sm")]: {
                position: 'absolute',
                zIndex: 1,
                marginTop: '5px',
            },
            [theme.breakpoints.between("xs","sm")]: {
                padding: '0px 0px 0px 24px'
            },
            [theme.breakpoints.down("xs")]: {
                padding: '0px 0px 0px 15px'
            }
        },
        mb_25: {
            marginBottom:'25px',
             [theme.breakpoints.down("sm")]: {
                 marginBottom:'10px'
             }
         },
        mb_10:{
            [theme.breakpoints.down("sm")]: {
                marginBottom:'10px'
            }
        },
        backIcon: {
            width: "12px",
            height: "24px",
        },
        backIconContainer:{
            display:"flex",
            alignItems:"center",
            gap:"1rem",
            marginBottom:"10px"
        },
        underline: {
            textDecoration: 'underline',
        },
        mt_2:{
            marginTop:'20px'
        },
        mb_12:{
            marginBottom:'-12px'
        },
        saveAndBtn: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center"
        },
        requestUserDetails: {
            border: "1px solid rgba(0,0,0,0.1)",
            width: "100%",
            height: "320px",
            padding: "1rem 1rem",
            borderRadius: "8px",
            [theme.breakpoints.down("md")]: {
                width: "100%",
                height: "auto !important"
            },
        },
        quoteheading: {
            height: "33px",
            color: "#34313a",
            fontFamily: "Rubik",
            fontSize: "28px",
            fontWeight: 500,
            marginBottom: "0.6rem"
        },
        cusName: {
            color: "#1C18AF",
            fontFamily: "Rubik",
            fontSize: "28px",
            fontWeight: 500
        },
        saveEndText:{
                fontSize: "20px",
                color: "#34313a",
                fontFamily: "Rubik",
                fontWeight: 400
        },
        iconLayout: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight:"0.6rem"
        },
        editBtn: {
            width: "150px",
            height: "67px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "30px",
                width: "100%",        
            },
            '&:hover': {
                backgroundColor: "#ffffff",
                color: "#1C18AF",
            }
        },
        deleteBtn: {
            width: "200px",
            height: "67px",
            "&:hover": {
                background: "#ffffff",
            },
            [theme.breakpoints.up("xl")]: {
                width: "200px",
                height: "67px",
                "&:hover": {
                    background: "#ffffff",
                },
                [theme.breakpoints.up("xl")]: {
                    width: "200px",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "100%"
                }
            },
        },
            pogressBar:{
                marginBottom:"1.5rem"
            },
            drawer: {
                flexShrink: 0,
            },
            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: "2rem",
                justifyContent: 'flex-start',
                color: " #999999",
            },
            requestText: {
                fontFamily: "Rubik",
                color: "#1C18AF",
                fontSize: "18px"
            },
            requestGrid: {
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1.2rem"
            },
            lightText: {
                fontFamily: "Rubik",
                color: "#606060",
                fontSize: "20px",
                fontWeight: 400
            },
            quoteIconLayout:{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
            },
            mt_10:{
                [theme.breakpoints.down("sm")]: {
                   marginTop:'10px'
                }
            },
            mt_15:{
                [theme.breakpoints.down("sm")]: {
                   marginTop:'15px'
                }
            },
            mb_15:{
                [theme.breakpoints.down("sm")]: {
                    marginBottom:'15px'
                 }
            },
       
        hide: {
            display: 'none',
          },
          drawerPaper: {
            maxHeight: "100%",
            height: "740px",
            position: "relative",
            zIndex: 0,
            marginTop:"10px",
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
            borderRadius: "5px",
            border: "1px solid rgba(0,0,0,0.1)",
            [theme.breakpoints.up("xl")]: {
              width: "100%"
            }
          },
          paper: {
            display: "flex",
            padding: "1rem",
            justifyContent: "center",
            marginTop: "15px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "-23px"
              }
          },
          jobPaper: {
            display: "flex",
            padding: "1rem",
            justifyContent: "center",
            marginTop: "-72px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "10px"
            }
          },
          mt_28:{
            marginTop:"-28px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "18px"
            }
          },
          mt_29:{
            marginTop:"-29px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "18px"
            }
          },
          mt_129:{
            marginTop:"-129px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "18px"
            }
          },
          requestDetailsGrid: {
            padding: "1rem",
            marginTop: "15px"
          },
          source:{
            fontFamily: "Rubik",
            fontSize: "22px",
            fontWeight: 400,
            fontStyle: "normal",
            color: "#34313a"
        },
        marginTop_10:{
            [theme.breakpoints.down("sm")]: {
               marginTop:'10px'
            }
        },
        mt_15:{
            [theme.breakpoints.down("sm")]: {
               marginTop:'15px'
            }
        },
        mb_15:{
            [theme.breakpoints.down("sm")]: {
                marginBottom:'15px'
             }
        },
        mr_1:{
            marginRight:'0.7em',
            [theme.breakpoints.down("xs")]: {
                marginRight:'0em'
             }
        },
        ml_1:{
            marginLeft:'0.7em',
            [theme.breakpoints.down("xs")]: {
                marginLeft:'0em'
             }
        }
    })
    )(wrappedComponent);
};

export default withRequestStyles;
// Customizable Area End