import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Avatar,
  withWidth
} from "@material-ui/core";
import { withStyles, } from "@material-ui/core/styles";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { EmployeeProfileSubSideBar } from "./EmployeeProfileSubSideBar.web";
import { StyledRoot } from "./utility.web";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { EmployeeProfileStyledButton } from "./EmployeeProfile.web";
import { userIcon } from "./assets";
import { StyledTextField } from "./ChangePassword.web";
import {  CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import EmployeeProfileController, {
  Props,
} from "./EmployeeProfileController.web";

// Customizable Area Start

// Customizable Area End

export class EmployeeChangePassword extends EmployeeProfileController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start


  // Customizable Area End
  render() {
    // Customizable Area Start
    const { firstName, lastName } = this.state.employeeformDetails;
    const getConditionalStyle = (isError: any) => {
      return isError
        ? { backgroundColor: "#fceef0", borderRadius: "10px" }
        : {};
    };
    const { classes, t }: any = this.props;
    return (
      < >
        <DashboardSideBar
          permissions={undefined} oldProps={undefined}
          {...this.props}
          active={false}
          active1={false}
          active2={false}    
          navMenuItem={this.props.t('dashboardNavbar.profile')}
          />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "5rem",
            }}
            className={classes.boxContainer}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              className={classes.employeerenderFormPassword}
              alignItems="flex-start"
            >
              <Grid xs style={{ paddingTop: "55px" }}>
                <EmployeeProfileSubSideBar
                  {...this.props}
                  profileActive={1}
               
                />
              </Grid>
              <Grid
                className={classes.employeemainCont}
              >
                <Container
                  maxWidth={"lg"}
                  style={{ padding: 0 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ paddingTop: "30px" }}
                    className={classes.pageTitle}
                  >
                    {t("boProfile.changePassword")}
                  </Typography>
                </Container>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: "10px 0px",
                    marginTop: "58px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={this.state.profile_image_employee || userIcon}
                      alt="Profile"
                      className={classes.companyLogo}
                      style={{ width: "64px", height: "64px" }}
                    />
                    <Typography variant="subtitle2"  className={classes.userName}>
                      {firstName} {lastName}
                    </Typography>
                  </Box>

                  <Grid container justifyContent="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.textFieldTitle}
                         variant = "body1"
                        style={{ marginTop: "30px", marginBottom: "15px" }}
                      >
                         {t("boProfile.oldPassword")}
                      </Typography>

                      <>
                        <StyledTextField
                          data-test-id="passwords"
                          autoFocus
                          variant="outlined"
                          fullWidth
                          name="oldPassword"
                          value={this.state.employeepasswordData.oldPassword}
                          onChange={this.employeesetPassword}
                          type={this.employeecheckEnableNew(
                            this.state.enablePasswordFieldOld
                          )}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="showPassword"
                                  aria-label="toggle password visibility"
                                  onClick={
                                    this.employeehandleClickShowPasswordOld
                                  }
                                  edge="end"
                                >
                                  {this.state.enablePasswordFieldOld ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.textFieldTitle}
                         variant = "body1"
                        style={{ marginBottom: "15px" }}
                      >
                               {t("boProfile.newPassword")}
                      </Typography>

                      <StyledTextField
                        variant="outlined"
                        fullWidth
                        style={getConditionalStyle(
                          this.state.isErrorFormData.newPassword ||
                            this.state.isErrorFormData.commonError
                        )}
                        name="newPassword"
                        error={Boolean(
                          this.state.isErrorFormData.newPassword ||
                            this.state.isErrorFormData.commonError
                        )}
                        value={this.state.employeepasswordData.newPassword}
                        onChange={this.employeesetPassword}
                        type={this.employeecheckEnableNew(
                          this.state.enablePasswordFieldNew
                        )}
                        helperText={this.state.isErrorFormData.newPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="showPassword"
                                aria-label="toggle password visibility"
                                onClick={
                                  this.employeehandleClickShowPasswordNew
                                }
                                edge="end"
                              >
                                {this.state.enablePasswordFieldNew ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.textFieldTitle}
                         variant = "body1"
                        style={{ marginBottom: "15px" }}
                      >
                        {t("boProfile.confirmPassword")}
                      </Typography>

                      <>
                        <StyledTextField
                          variant="outlined"
                          fullWidth
                          name="confirmPassword"
                          value={
                            this.state.employeepasswordData.confirmPassword
                          }
                          onChange={this.employeesetPassword}
                          error={Boolean(
                            this.state.isErrorFormData.confirmPassword ||
                              this.state.isErrorFormData.commonError
                          )}
                          type={this.employeecheckEnableNew(
                            this.state.enablePasswordFieldConfirm
                          )}
                          helperText={
                            this.state.isErrorFormData.confirmPassword ||
                            this.state.isErrorFormData.commonError
                          }
                          style={getConditionalStyle(
                            this.state.isErrorFormData.confirmPassword ||
                              this.state.isErrorFormData.commonError
                          )}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="showPassword"
                                  aria-label="toggle password visibility"
                                  onClick={
                                    this.employeehandleClickShowPasswordConfirm
                                  }
                                  edge="end"
                                >
                                  {this.state.enablePasswordFieldConfirm ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {this.state.error && (
                          <Typography variant="body1" className={classes.errorMessage}>
                            {this.state.errorMessage}
                          </Typography>
                        )}
                      </>
                    </Grid>
                  </Grid>
                  {this.state.success && this.state.successMessage && (
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.successMessage}>
                        {this.state.successMessage}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    className={classes.employeerenderSavePassword}
                  >
                    <Grid
                      container
                      className={classes.employeebtnProfilePassword}
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          textAlign: "end",
                          marginTop: "1rem",
                          maxWidth: "100%",
                        }}
                      >
                        <EmployeeProfileStyledButton
                          data-test-id="btnEmailLogin"
                          onClick={this.employeeResetPasswordSubmit}
                          className={classes.updateButton}
                        >
                          <Typography
                              variant="subtitle2"
                            className={classes.updateButtonText}
                          >
                            {t("boProfile.update")}
                          </Typography>
                        </EmployeeProfileStyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  empsideBarTitle: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 0 2rem",
      marginLeft: "-0.2rem",
    },
  },
  empsideBarTitleAr: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 2rem 0 0 !important",
      marginRight: "-0.2rem !important",
    },
  },
  employeemainCont:{
    width: "75%",
    padding: "20px",
    borderLeft: "1px solid #e4e4e4",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  employeerenderSavePassword:{
    width: "100%",
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  employeerenderFormPassword: {
    flexWrap: "nowrap" as const,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap" as const
    },
  },
  employeebtnProfilePassword:{
    display:"flex",
    flexDirection:"column" as const,
    alignItems:"flex-end" as const,
    [theme.breakpoints.down("xs")]: {
      alignItems:"normal",
    },

  },

  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  successMessage: {
    marginTop: "5px",
    color: "#0b9500",
  },
  pageTitle: {
    width: "256px",
    height: "28px",
    color: CONSTANTS.color.lightBrown,
    fontWeight: theme.typography.fontWeightMedium,
  },
  profilePic: {
    width: "64px",
    height: "64px",
    //background:  url(image_Bitmap Copy 2.png)  no-repeat;
  },
  profileName: {
    width: "136px",
    height: "26px",
    fontFamily: "Rubik",
    fontSize: "22px",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  textFieldHeader: {
    width: "114px",
    color: "#999999",
    height: "16px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  userName: {
    height: "26px",
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as "center",
    marginLeft: "20px",
    marginRight:"20px"
  },
  updateButton: {
    width: "200px",
    borderRadius: "10px",
    height: "67px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    textAlign: "end" as const,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "327px",
      maxHeight:"48px",
    },
  },
  updateButtonText: {
    width: "75px",
    height: "32px",
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as "center",
  },
  textFieldTitle: {
    height: "16px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "16px",
  },
  errorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },

});

const webStyle = {};

export default withTranslation()(withStyles(styles)(withWidth()(EmployeeChangePassword)));
// Customizable Area End
