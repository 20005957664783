import React from "react";
import {
    Grid, Typography, Divider
    // Customizable Area Start
    , WithStyles
    // Customizable Area End
} from "@material-ui/core";
import {
    withStyles, createStyles, Theme
} from "@material-ui/core/styles";
// Customizable Area Start
import ClearIcon from '@material-ui/icons/Clear';
import { CONSTANTS } from "../../../components/src/theme";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
interface ClassProps{
    fieldDisableBgColor: string;
    requestUserDetails:string
    titleHeading26:string
    cusName:string
    detailsHeading:string
    detailsText:string
    overFlow:string
}

interface Props {
    converted: string
    clearCustomer: () => void;
    crudStatus: string;
    siteDetails: any;
    details: any
    dailogOpen: any;
    modeType: string
    t?: (key: string) => string;
    classes:ClassProps
    editStatus:boolean

}
// Customizable Area End

export class CustomerDetails extends React.Component<Props>{
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes,t} = this.props;
        const converted = this.props?.converted
        return (
            // Customizable Area Start
            <>
                <div className={
                      !this.props.editStatus && this.props.crudStatus != "create" && !converted ?
                      `${classes.requestUserDetails} ${classes.fieldDisableBgColor}`:
                    classes.requestUserDetails
                    } 
                    data-test-id="selectCustomer"  >
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={11}>
                                <Typography variant="h6" className={classes.titleHeading26}>{this.props.modeType}
                                    <Typography variant="h6"  display="inline" className={classes.cusName} onClick={!converted && this.props.dailogOpen}>&nbsp;{this.props.details?.name}</Typography>
                                </Typography>
                            </Grid>
                            {this.props.crudStatus != "edit" && !converted && <Grid item xs={1}>
                                <ClearIcon
                                    data-test-id={"clearCustomer"}
                                    onClick={this.props.clearCustomer}
                                />

                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={3} >
                        {this.props.siteDetails &&
                            <>
                                <Grid item xs={12} style={{ marginTop: "12px" }}>
                                    <Typography variant="body1" className={classes.detailsHeading}>{t? t('customerDetails.phoneNo'):"Phone Number"}</Typography>
                                    <Typography variant="body1" className={classes.detailsText}>{this.props.details?.phone_number || "-"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={7}>
                                            <Typography variant="body1" className={classes.detailsHeading}>{t? t('customerDetails.address'):"Address"}</Typography>
                                            <Typography variant="body1" className={`${classes.detailsText} ${classes.overFlow}`}>{this.props.siteDetails.site_address}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="body1" className={classes.detailsHeading}>{t? t('customerDetails.state'):"State"}</Typography>
                                            <Typography variant="body1" className={classes.detailsText}>{this.props.siteDetails.state || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={7}>
                                            <Typography variant="body1"  className={classes.detailsHeading}>{t? t('customerDetails.city'):"City"}</Typography>
                                            <Typography variant="body1" className={classes.detailsText}>{this.props.siteDetails.city || "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="body1" className={classes.detailsHeading}>{t?t('customerDetails.zipCode'):"Zip Code"}</Typography>
                                            <Typography variant="body1" className={classes.detailsText}>{this.props.siteDetails.zip_code || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </>
                        }
                    </Grid>
                </div>


            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const CustomerDetailsStyles = (theme: Theme) =>
    createStyles({
        detailsHeading: {
            color: CONSTANTS.color.textLightGrey,
            fontWeight: theme.typography.fontWeightRegular
        },
        detailsText: {
            color: CONSTANTS.color.silverGrey,
            fontWeight: theme.typography.fontWeightMedium
        },
        overFlow: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        cusName:{
            color:CONSTANTS.color.darkBlue,
            fontWeight:CONSTANTS.fontWeight.fontWeight500

        },
        fieldDisableBgColor:{
            backgroundColor: "#F2F2F2",
            cursor: "default",
            pointerEvents: "none"
        },
        requestUserDetails: {
            border: "1px solid rgba(0,0,0,0.1)",
            width: "100%",
            height: "320px",
            padding: "1rem 1rem",
            borderRadius: "8px",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "auto !important",
                maxWidth:"none"
            },
        }
    });
const CommonStyles:any = withCommonStyles(CustomerDetails)
export default withStyles(CustomerDetailsStyles)(CommonStyles);
// Customizable Area End
