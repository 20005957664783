// Customizable Area Start
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { getStorageData} from "../../../framework/src/Utilities";
export const configJSON = require("./config");
const customersErrorText = {
  requiredTypo: "Please enter name",
  addressRequiredTypo: "Address is required",
  lengthTypo: "Name should be less than 23 characters",
  lengthTypoV1:"First Name should be less than 23 characters",
  lengthTypoV2:"Last Name should be less than 23 characters",
  alphabeticTypo: "Please enter in alphabetical characters",
  phoneTypo: "This is not a valid Phone Number",
  emailTypo: "Please enter a valid Email Address",
  numbersTypo: "Please enter in numbers",
  stateLengthTypo:"State should be less than 50 characetrs",
  phoneTypoV1:"This is not a valid Mobile Number",
  alphaNumericTypo:"Please enter in alphanumeric characters"
};
//@ts-ignore
import CryptoJS from "crypto-js";

export const DRAWER_WIDTH = 280;

interface StyledProps {
  languageSelected?: string;
}

export const StyledRoot = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.up('lg')]: {
    marginLeft: (props: StyledProps) => props?.languageSelected === "en" ? `${DRAWER_WIDTH + 1}px` : "0px",
    marginRight: (props: StyledProps) => props?.languageSelected === "ar" ? `${DRAWER_WIDTH + 1}px` : "0px",
  },
}));

export const countryCodes = [
  "+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34", "+36", "+39", "+40",
  "+41", "+43", "+44", "+45", "+46", "+47", "+48", "+49", "+51", "+52", "+53", "+54",
  "+55", "+56", "+57", "+58", "+60", "+61", "+62", "+63", "+64", "+65", "+66", "+81",
  "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", "+95", "+98", "+211", "+212",
  "+213", "+216", "+218", "+220", "+221", "+222", "+223", "+224", "+225", "+226", "+227",
  "+228", "+229", "+230", "+231", "+232", "+233", "+234", "+235", "+236", "+237", "+238",
  "+239", "+240", "+241", "+242", "+243", "+244", "+245", "+248", "+249", "+250", "+251",
  "+252", "+253", "+254", "+255", "+256", "+257", "+260", "+261", "+262", "+263", "+264",
  "+265", "+266", "+267", "+268", "+269", "+290", "+291", "+297", "+298", "+299", "+350",
  "+351", "+352", "+353", "+354", "+355", "+356", "+357", "+358", "+359", "+370", "+371",
  "+372", "+373", "+374", "+375", "+376", "+377", "+378", "+379", "+380", "+381", "+382",
  "+383", "+385", "+386", "+387", "+389", "+420", "+421", "+423", "+500", "+501", "+502",
  "+503", "+504", "+505", "+506", "+507", "+508", "+509", "+590", "+591", "+592", "+593",
  "+594", "+595", "+596", "+597", "+598", "+670", "+672", "+673", "+674", "+675", "+676",
  "+677", "+678", "+679", "+680", "+681", "+682", "+683", "+685", "+686", "+687", "+688",
  "+689", "+690", "+691", "+692", "+800", "+808", "+850", "+852", "+853", "+855", "+856",
  "+870", "+878", "+880", "+881", "+882", "+883", "+886", "+888", "+960", "+961", "+962", 
  "+963", "+964", "+965", "+966", "+967", "+968", "+971", "+972", "+973", "+974", "+975",
  "+976", "+977", "+979", "+992", "+993", "+994", "+995", "+996", "+998"
]

export const checkPermissionDashboard = async (passedCategoryV1: any) => {
  const ciphertextV1 = await getStorageData("permissions");
  return checkPermissionCallDashboard(ciphertextV1,passedCategoryV1)
};
export const checkPermissionCallDashboard = (ciphertextV1:any, passedCategoryV1:any)=>{
  if(ciphertextV1){
    let bytesV1 = CryptoJS.AES.decrypt(ciphertextV1, configJSON.secretKey);
    let getPermissionsV1 = JSON.parse(bytesV1.toString(CryptoJS.enc.Utf8));
   
    const parsedPermissionsv1 = getPermissionsV1;
    if (parsedPermissionsv1 && parsedPermissionsv1.length > 0) {
     
      for (const permissionV1 of parsedPermissionsv1) {
        const { category, action } = permissionV1.attributes;
        if (category === passedCategoryV1) {
         
          return [action, true];
        }
      }
    } else {
      return [undefined, undefined];
    }
  }
}

export const checkLoggedInUserDashboard = async()=>{
  const getUser = await getStorageData("isEmployee")
  const parsedUser= JSON.parse(getUser)
  return parsedUser // true = employee, false = BO
}

export const getAuthTokenDashboard = async ()=>{
  const getToken = await getStorageData("authToken")
  return getToken
}

export const empvalidateFormDataProfileV1 = (values: any,t:(key: string) => string) => {
  const errors: any = {};
  const { firstName, lastName, email, phone} = values;

  if (firstName.length > 23) {
    errors.firstName = t("customers.customersErrorText.lengthTypoV1");
  } else if (firstName && !configJSON.regexAr.spaces.test(firstName)) {
    errors.firstName = t("customers.customersErrorText.alphabeticTypo");
  }

  if (lastName.length > 23) {
    errors.lastName =  t("customers.customersErrorText.lengthTypoV2");
  } else if (lastName && !configJSON.regexAr.spaces.test(lastName)) {
    errors.lastName = t("customers.customersErrorText.alphabeticTypo");
  }

  if (email && !configJSON.regex.email.test(email)) {
    errors.email = t("customers.customersErrorText.emailTypo");
  }
  if (phone && !configJSON.regex.phone.test(phone)) {
    errors.phone = t("customers.customersErrorText.phoneTypo");
  }
  
  empvalidateFormDataProfileV2(values,errors,t)
  return errors;
};

export const empvalidateFormDataProfileV2 = (values: any, errors: any,t:(key: string) => string) => {
  const { city, country, postcode, language } = values;

  if (city && !configJSON.regexAr.spaces.test(city)) {
    errors.city = t("customers.customersErrorText.alphabeticTypo");
  }

  if (country && !configJSON.regexAr.spaces.test(country)) {
    errors.country = t("customers.customersErrorText.alphabeticTypo");
  }
  if (postcode && !configJSON.regex.zipcode.test(postcode)) {
    errors.postcode = t("customers.customersErrorText.alphaNumericTypo");
  }
  if (language && !configJSON.regexAr.spaces.test(language)) {
    errors.language = t("customers.customersErrorText.alphabeticTypo");
  }

  return errors;
};
export const getLang = async () => getStorageData("lang");
// Customizable Area End