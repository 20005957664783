Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Notes";
exports.labelBodyText = "Notes Body";
exports.getNoteEndPoint ="bx_block_notes/notes"
exports.updateNoteEndPoint = "bx_block_notes/notes/"
exports.deleteNoteEndPoint = "bx_block_notes/notes/"
exports.createNoteEndPoint ="bx_block_notes/notes"

exports.btnExampleTitle = "Add Note";
exports.cancelBtn = 'Cancel'

exports.deleteBtn = 'Delete'
exports.updateBtnText = 'Update Note'
exports.btnAdd = "Add Notes";
exports.labelNoNote="Add New Notes"
exports.deleteModalText = 'Are you sure you want to delete ?'
exports.loginAlertMessage = 'Please login to view Notes'



exports.btnExampleTitle = "CLICK ME";
// Customizable Area End