import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from 'i18next';

interface CustomI18nType {
  changeLanguage(language: string): void;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  active: boolean | number;
  t: (key: string) => string;
  homePage: Function;
  oldProps: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  navbar: boolean;
  buttons: boolean;
  language: string;
  languageSelectModal: boolean;
  languageSelected: string|null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NavBarFooterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      navbar: false,
      buttons: true,
      language: "English",
      languageSelectModal: false,
      languageSelected: "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.showButton();
    this.navBarResize();

    const selectedLanguage:string|null = localStorage.getItem("lang");
    this.setState({ language: selectedLanguage === "ar" ? "عربي" : "English" });
    this.setState({ languageSelected: selectedLanguage });
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  showButton = () => {
    if(window.innerWidth <= 960) {
      this.setState({ buttons: false });
    } else {
      this.setState({ buttons: true });
    }
  }

  navBarResize = () => {
    if(window.innerWidth >= 960) {
      this.setState({ navbar: false });
    }
  }

  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleTabValueChange = (event: any, value: number) => {
    if(value === 0) {
      this.handleNavigate("Home");
    }
    else if(value === 1) {
      this.handleNavigate("WhyLamsa");
    }
    else if(value === 2) {
      this.handleNavigate("Pricing");
    }
    else if(value === 3) {
      this.handleNavigate("ContactUs");
    }
  }

  handleMenuClick = () => {
    this.setState({ navbar: !this.state.navbar });
  }

  handleNavigateLogin = () => {
    this.handleNavigate("Login");
    this.handleClickAway();
  }

  handleNavigateSignUp = () => {
    this.handleNavigate("Register");
    this.handleClickAway();
  }

  handleNavigateHome = () => {
    this.handleNavigate("Home");
    this.handleClickAway();
  }

  handleNavigateWhyLamsa = () => {
    this.handleNavigate("WhyLamsa");
    this.handleClickAway();
  }

  handleNavigatePricing = () => {
    this.handleNavigate("Pricing");
    this.handleClickAway();
  }

  handleNavigateContactUs = () => {
    this.handleNavigate("ContactUs");
    this.handleClickAway();
  }

  handleNavigateTerms = () => {
    this.handleNavigate("TermsAndConditions");
  }

  handleNavigatePrivacy = () => {
    this.handleNavigate("PrivacyPolicy");
  }

  handleClickAway = () => {
    this.setState({ navbar: false });
  }

  handleClickOpenlanguageDialog = () => {
    this.setState({ languageSelectModal: !this.state.languageSelectModal });
  }

  handleSelectlanguage = async (language: { value: string; name: string; id: number }) => {
    const path = this.props.navigation.history?.location?.pathname;
    setStorageData("lang", language.value);
    const selectedLanguage = getStorageData("lang");
    const languageDirection = language.value === "ar" ? "rtl" : "ltr";
  
    this.setState({
      language: await selectedLanguage === "ar" ? "عربي" : "English" || language.name,
      languageSelectModal: false,
      navbar: false,
      languageSelected: await selectedLanguage
    }, () => {
      const htmlElement = window.document.querySelector("html");
      if (htmlElement) {
        htmlElement.setAttribute("dir", languageDirection);
      }
      (i18n as CustomI18nType).changeLanguage(language.value);
      this.props.homePage();
      this.props.oldProps.setState({
        emailError: "",
        emailError2: "",
        homeLoading: true,
        whyLamsaLoading: true,
        getContactUsDataLoading: true,
        termsLoading: true,
        privacyLoading: true,
        isErrorFormDetails: {},
        questionList: []
      });
  
      if (path === "/Pricing") {
        window.location.reload();
      }
    });
  }

}
// Customizable Area End