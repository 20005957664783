//@ts-nocheck
import React from "react";
 // Customizable Area Start
import {
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  withStyles,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withRouter } from "react-router-dom";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import {
  Theme
} from "@material-ui/core/styles";
import { CONSTANTS } from "../../../components/src/theme";

import LoginController, { Props } from "./LoginController.web";
import { withSnackbar } from "../../../components/src/toastweb";
import {
  StyledButton,
  StyledTextField,
} from "../../email-account-registration/src/utility.web";
import { withTranslation } from "react-i18next";
//@ts-ignore
 // Customizable Area End

export class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
      // Customizable Area Start
    //@ts-ignore
    const { classes, t } = this.props;
    const { email, textPassword } = this.state.formData;
    return (
      <>
        <form onSubmit={this.handleLoginSubmit}>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textPrimary" className={classes.loginLabelText}>
                {t("BoAuth.emailLabel")}
                <Typography display="inline" variant="caption" color="error">
                  *
                </Typography>
              </Typography>
              <StyledTextField
                className={classes.inputFieldText}
                placeholder={t("BoAuth.emailPlaceHolder")}
                variant="outlined"
                fullWidth
                name="email"
                value={email}
                onChange={this.handleLoginChange}
                error={Boolean(this.state.isErrorFormData.email)}
                helperText={this.state.isErrorFormData.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textPrimary" className={classes.loginLabelText}>
                {t("BoAuth.passwordLabel")}
                <Typography display="inline" variant="caption" color="error">
                  *
                </Typography>
              </Typography>
              <StyledTextField
                className={classes.inputFieldText}
                placeholder={t("BoAuth.passwordPlaceholder")}
                variant="outlined"
                fullWidth
                name="textPassword"
                value={textPassword}
                onChange={this.handleLoginChange}
                error={Boolean(this.state.isErrorFormData.textPassword)}
                type={this.state.showPassword ? "text" : "password"}
                helperText={this.state.isErrorFormData.textPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-test-id="showPassword"
                        aria-label="toggle password visibility"
                        onClick={this.handleShowPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Typography
                variant="body2"
                className={classes.fpass}
                onClick={this.handleForgotPassword}
                data-test-id="forgotPassword"
              >
                {t('BoAuth.forgotPassword')}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end" >
              <StyledButton
                data-test-id="btnEmailLogin"
                type="submit"
                className={classes.loginButton}
              >
                <Typography variant="body2" className={classes.btntext}>
                  {t('BoAuth.login')}
                </Typography>
              </StyledButton>
            </Grid>

          </Grid>
        </form>
        <LoadingBackdrop
          loading={this.state.loginLoader}
        />
      </>
     
    );
       // Customizable Area End
  }
}

// Customizable Area Start

//@ts-ignore
const loginRouter = withRouter(Login);
const loginToaster = withSnackbar(loginRouter);
export const LoginStyles = (theme: Theme) => ({
  inputFieldText: {
    "& .MuiInputBase-root": {
      borderRadius: 8,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.common.black
    },
    "& .MuiInputBase-input":{
      fontSize:"16px"
    }
  },
  btntext: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "none",
    color: "#ffffff",
  },
  loginLabelText: {
    fontWeight: theme.typography.fontWeightRegular,
    paddingTop: "10px",
    paddingBottom: "10px",
    textTransform: "none",
  },
  fpass: {
    borderBottom: `1px solid ${CONSTANTS.color.darkBlue}`,
    margin: "5px 0 30px",
    color: CONSTANTS.color.darkBlue,
    cursor: "pointer",
    textAlign: 'end'
  },
  addCheckBoxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    alignSelf: "flex-start",
  },
  termsHeading: {
    textDecoration: 'underline',
    marginTop: "2px",
    color: "#1C18AF",
    cursor: "pointer",
    lineHeight: "24px",
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important'
    }
  },
  loginButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
})
const LoginComponent = withStyles(LoginStyles)(loginToaster)
export default withTranslation()(LoginComponent);
// Customizable Area End