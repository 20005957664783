// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any
  handleCollectionPaymentSave:any
  invoicePayment:any
  handleChange:any
  onClose:any
  collectedPaymentForm:any
  handleChangePaymentMethodRef:any
  handleCollectionPaymentModal:any
  handleCollectionPaymentClose:any
  handlePay_balence:Function;
  handleCollected: Function
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  collectedPayment:boolean
  dialogOpen:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CollectedPaymentModalController extends BlockComponent<
  any,
  S,
  SS
> {
  // Customizable Area Start
  invoicePaymentApiCallId:string=""
  // Customizable Area End

  constructor(props: any) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state={
        collectedPayment:false,
        dialogOpen:true,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  handleCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  }
  
  

}
// Customizable Area End
