import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  Container,
} from "@material-ui/core";
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {
  likeIcon,
} from "./assets";
import "./Style.css";
import NavBar from "./NavBar.web";
import Footer from "./Footer.web";
import Monthly from "./Monthly.web";
import Yearly from "./Yearly.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import PricingController, {
  Props,
} from "./PricingController.web";

// Customizable Area Start
interface ItemData {
  id: string;
  attributes: {
    title: string;
    description: string;
    offer: string;
    logo: {
      url: string;
    }
  }
}
// Customizable Area End

export class Pricing extends PricingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const { classes }: any = this.props;
    return (
      <>
        <NavBar {...this.props} active={2} homePage={this.pricingPlanMonthlyPage} oldProps={this} />
      
        <Box
          style={webStyle.container}
          className={classes.container}
        >
          <Container maxWidth={"lg"}>
            {this.state.pricingPlan.map((item: ItemData) => {
              return (
                <Grid container spacing={2} style={webStyle.sectionOne} key={item.id}>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography
                      style={webStyle.pricingPlan}
                      className={`${classes.pricingPlan} pricingPlan`}
                      variant="h2"
                    >
                      {item?.attributes?.title}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography
                      style={webStyle.pricingPlanBody}
                      className={classes.pricingPlanBody}
                      variant="body1"
                    >
                      {item?.attributes?.description}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Box style={webStyle.plansContainer} className={classes.plansContainer}>
                      <Button
                        style={
                          this.state.activeTab === "Monthly" ?
                          {...webStyle.buttonContainer, backgroundColor: CONSTANTS.color.thickBlue} :
                          webStyle.buttonContainer
                        }
                        className={classes.buttonContainer}
                        onClick={this.handleClickMonthly}
                        >
                        <Typography
                          style={
                            this.state.activeTab === "Yearly" ?
                            {...webStyle.buttonText, color: CONSTANTS.color.thickBlue} :
                            webStyle.buttonText
                          }
                          className="buttonText"
                          variant="subtitle1"
                        >
                          {t("landingPage.Pricing.monthly")}
                        </Typography>
                      </Button>
                      <Button
                        style={
                          this.state.activeTab === "Yearly" ?
                          {...webStyle.buttonContainer, backgroundColor: CONSTANTS.color.thickBlue} :
                          webStyle.buttonContainer
                        }
                        className={classes.buttonContainer}
                        onClick={this.handleClickYearly}
                      >
                        <Typography
                          style={this.state.activeTab === "Monthly" ? {...webStyle.buttonText, color: CONSTANTS.color.coolDarkGrey} : webStyle.buttonText}
                          className="buttonText"
                          variant="subtitle1"
                        >
                          {t("landingPage.Pricing.yearly")}
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Box style={webStyle.likeRootContainer}>
                      <Box style={this.state.languageSelected === "ar" ? {...webStyle.likeContainer, gap: "1rem"} : webStyle.likeContainer}>
                        <img style={webStyle.likeIcon} src={item?.attributes?.logo?.url} alt="" />
                        <Typography style={webStyle.getText}>
                          {item?.attributes?.offer}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
            {this.state.activeTab === "Monthly" ? (
              <Monthly {...this.props}  route="Pricing" />
            ) : (
              <Yearly {...this.props} route="Pricing" />
            )}
          </Container>
        
        </Box>
        <Footer {...this.props} active={2} homePage={this.pricingPlanMonthlyPage} oldProps={this} />

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 0rem 5rem 0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8rem !important"
    },
  },
  pricingPlan: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    },
  },
  pricingPlanBody: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("xs")]: {
      height: "53px !important",
      minHeight: "0px !important"
    },
  },
  plansContainer: {
    [theme.breakpoints.down("xs")]: {
      height: "53px !important",
    },
  },
});

const webStyle = {
  container: {
    marginTop: "10rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0rem 2rem 5rem 2rem",
  },
  sectionOne: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  pricingPlan: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    textAlign: "center" as const,
  },
  pricingPlanBody:{
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    textAlign: "center" as const,
    maxWidth: "500px",
    width: "100%"
  },
  plansContainer: {
    width: "440px",
    maxWidth: "100%",
    height: "70px",
    borderRadius: "35px",
    background: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(163,177,198,0.25)",
    marginTop: "1rem",
    display: "flex",
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.white,
    borderRadius: "35px",
    minHeight: "70px",
    maxWidth: "220px",
    width: "100%",
  },
  buttonText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textTransform: "none" as const
  },
  likeRootContainer: {
    width: "434px",
    height: "34px",
    borderRadius: "12px",
    backgroundColor: "rgba(28,24,175,0.15)",
    maxWidth: "100%",
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  likeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  likeIcon: {
    width: "20px",
    height: "20px",
  },
  getText: {
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
    marginLeft: "5px",
  },

}
const PricingStyles = withStyles(styles)(withSnackbar(Pricing));
export default withTranslation()(PricingStyles);
// Customizable Area End