//@ts-nocheck
import React from "react";
import {
  TextField,
  withStyles,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  Theme,
} from "@material-ui/core/styles";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
//@ts-ignore
import CryptoJS from "crypto-js";
import { SiteProp } from "./CustomersController.web"
import {ProductItem} from "./CustomerRequestsController.web"

export const ProfileStyledTextField = withStyles((theme: Theme) => (
  {
    root: {
      "& .MuiInputBase-root": {
        color: "#666666",
        borderRadius: "10px",
        fontFamily: "Rubik",
        fontSize: "1.1667rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "1.0625rem !important"
        },

      },
      "& .MuiOutlinedInput-input": {
        padding: "15px 14px",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderWidth: "2px",
      },
      "& .MuiOutlinedInput-root:hover": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white",
      },
      "& .MuiOutlinedInput-root.Mui-error": {
        backgroundColor: "rgb(255,239,239)",
      },

    },
  }
))(TextField);

export const ProfileStyledSelect = withStyles({
  select: {
    fontSize: "18px",
    fontFamily: "Rubik",
    marginLeft: "0.5rem",
  },
  icon: {
    fontSize: "2.3rem",
    top: "auto",
  },
})(Select);

export const ProfileStyledMenuItem = withStyles({
  root: {
    backgroundColor: "#fff",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#41cb41",
    },
    "& .MuiTypography-body1": {

      fontFamily: "Rubik",
    },
  },
})(MenuItem);

export const ProfileStyledButton = withStyles({
  root: {
    backgroundColor: "#1C18AF",
    color: "#ffffff",
    fontSize: "22px",
    fontWeight: 400,
    fontFamily: "Rubik",
    textAlign: "center",
    borderRadius: "8px",
    width: "10rem",
    height: "3rem",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      cursor: "pointer",
    },
  },
})(Button);

export const checkPermission = async (passedCategory: any) => {
  const ciphertext = await getStorageData("permissions");
  return checkPermissionCall(ciphertext, passedCategory);
};
export const checkPermissionCall = (ciphertext, passedCategory) => {
  if (ciphertext) {
    let bytes = CryptoJS.AES.decrypt(ciphertext, configJSON.secretKey);
    let getPermissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const parsedPermissions = getPermissions;
    if (parsedPermissions && parsedPermissions.length > 0) {
      for (const permission of parsedPermissions) {
        const { category, action } = permission.attributes;
        if (category === passedCategory) {
          return [action, true];
        }
      }
    } else {
      return [undefined, undefined];
    }
  }
};
export const checkLoggedInUser = async () => {
  const getUser = await getStorageData("isEmployee");
  const parsedUser = JSON.parse(getUser);
  return parsedUser; // true = employee, false = BO
};

export const getAuthToken = async () => {
  const getToken = await getStorageData("authToken");
  return getToken;
};

//for customers only
export const performAuthorizationCheck = async (passedCategory: any) => {
  const isEmployee = await checkLoggedInUser();
  const isLoggedIn = await getAuthToken();
  const isPermit = await checkPermission(passedCategory);
  return performAuthorizationCheckCall(isEmployee, isLoggedIn, isPermit);
};

export const performAuthorizationCheckCall = (
  isEmployee,
  isLoggedIn,
  isPermit
) => {
  if (isEmployee && isLoggedIn) {
    //EMP
    return (
      isPermit &&
      (!isPermit[1] ||
        isPermit[0] === "None" ||
        isPermit[0] === "View client's details")
    );
  } else {
    //BO
    return false;
  }
};

//for suppliers
export const performSupplierAuthorizationCheck = async (
  passedCategory: any
) => {
  const isEmployee = await checkLoggedInUser();
  const isLoggedIn = await getAuthToken();
  const isPermit = await checkPermission(passedCategory);
  return performSupplierAuthorizationCheckCall(
    isEmployee,
    isLoggedIn,
    isPermit
  );
};

export const performSupplierAuthorizationCheckCall = (
  isEmployee,
  isLoggedIn,
  isPermit
) => {
  if (isEmployee && isLoggedIn) {
    //EMP
    return (
      isPermit &&
      (!isPermit[1] ||
        isPermit[0] === "None" ||
        isPermit[0] === "View supplier's details")
    );
  } else {
    //BO
    return false;
  }
};
//and Request jobs and Qquotes : to check for view permisions
export const handleViewData = async (passed_category: any) => {
  const isEmployee = await checkLoggedInUser();
  const isPermit = await checkPermission(passed_category);
  return handleViewDataCallback(isEmployee, isPermit);
};

export const handleViewDataCallback = (isEmployee: any, isPermit: any) => {
  if (isEmployee) {
    return handleViewDataCallbackV1(isPermit)
  } else {
    return ["VIEW", "CREATE", "EDIT", "DELETE", "COMPLETE"];
  }
};

export const handleViewDataCallbackV1 = (isPermit: any) => {
  if (isPermit && !isPermit[1]) {
    return [];
  } else if (isPermit && isPermit[0] === "None") {
    return ["NONE"];
  } else if (isPermit && isPermit[0] === "View only") {
    return ["VIEW"];
  } else if (isPermit && isPermit[0] === "View, create and edit") {
    return ["VIEW", "CREATE", "EDIT"];
  } else if (isPermit && isPermit[0] === "View, create, edit and delete") {
    return ["VIEW", "CREATE", "EDIT", "DELETE"];
  } else if (isPermit && isPermit[0] === "View and complete the tasks assigned to them") {
    return ["VIEW", "COMPLETE"];
  } else if (isPermit && isPermit[0] === "View, create, edit and delete the tasks that they have created") {
    return ["VIEW", "CREATE", "EDIT", "DELETE"];
  }
  return handleViewDataCallbackV2(isPermit)

}

export const handleViewDataCallbackV2 = (isPermit: any) => {
  if (isPermit && isPermit[0] === "View their own data") {
    return ["VIEW"];
  }
  else if (isPermit && isPermit[0] === "Edit their own data") {
    return ["EDIT", "VIEW"];
  }
}

export const performInvoiceAuthorizationCheck = async (passedCategory: any) => {
  const isEmployee = await checkLoggedInUser();
  const isLoggedIn = await getAuthToken();
  const isPermit = await checkPermission(passedCategory);
  return performInvoiceAuthorizationCheckCall(isEmployee, isLoggedIn, isPermit);
};

export const performInvoiceAuthorizationCheckCall = (
  isEmployee,
  isLoggedIn,
  isPermit
) => {
  if (isEmployee && isLoggedIn) {
    //EMP
    return (
      isPermit &&
      (!isPermit[1] || isPermit[0] === "None" || isPermit[0] === "View only")
    );
  } else {
    //BO
    return false;
  }
};
export const performInvoiceDeleteAuthorizationCheck = async (
  passedCategory: any
) => {
  const isEmployee = await checkLoggedInUser();
  const isLoggedIn = await getAuthToken();
  const isPermit = await checkPermission(passedCategory);

  return performInvoiceDeleteAuthorizationCheckCall(
    isEmployee,
    isLoggedIn,
    isPermit
  );
};
export const performInvoiceDeleteAuthorizationCheckCall = (
  isEmployee,
  isLoggedIn,
  isPermit
) => {
  if (isEmployee && isLoggedIn) {
    //EMP
    return (
      isPermit &&
      (!isPermit[1] ||
        isPermit[0] === "View only" ||
        isPermit[0] === "View, create and edit")
    );
  } else {
    //BO
    return false;
  }
};


export const sitesvalidateFormDataV1 = (values: any, errors: any, t: (key: string) => string) => {
  if (values.state && !configJSON.regexAr.spaces.test(values.state)) {
    errors.state = t("customers.customersErrorText.alphabeticTypo");
  } else if (values.state && values.state.length > 50) {
    errors.state = t("customers.customersErrorText.stateLengthTypo");
  }
  if (values.city && !configJSON.regexAr.spaces.test(values.city)) {
    errors.city = t("customers.customersErrorText.alphabeticTypo");
  }
  if (values.country && !configJSON.regexAr.spaces.test(values.country)) {
    errors.country = t("customers.customersErrorText.alphabeticTypo");
  }
  if (values.zip && !configJSON.regex.zipcode.test(values.zip)) {
    errors.zip = t("customers.customersErrorText.alphaNumericTypo");
  }

  return errors;
};
export const sitesvalidateFormData = (values: any, t: (key: string) => string) => {
  const errors: any = {};
  if (!values.site_name) {
    errors.name = t("customers.customersErrorText.requiredTypo");
  } else if (!configJSON.regexAr.spaces_numbers.test(values.site_name)) {
    errors.name = t("customers.customersErrorText.alphabeticTypo");
  }
  if (!values.site_address) {
    errors.site_address = t("customers.customersErrorText.addressRequiredTypo");
  }
  sitesvalidateFormDataV1(values, errors, t);

  return errors;
};

export const getAlignment = (lang: string) => lang === "en" ? "left" : "right"

export const getLang = async () => getStorageData("lang");

export function convertToArabicNumerals(number) {
  const arabicNumerals = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  return String(number).split('').map(digit => arabicNumerals[digit]).join('');
}


export const filterItems = (item: ProductItem[] | [], filterKey: string) => {
  if (item) {
    const filteredData = item.filter((subItem: ProductItem) => subItem.attributes[filterKey]);
    return filteredData.length > 1 ?
      `${filteredData[0]?.attributes.product_name}..` : filteredData[0]?.attributes.product_name
  } else {
    return []
  }

};

export const hoverItems = (item: ProductItem[] | [], hoverKey: string) => {
  if (item) {
    return item.filter((subItem: ProductItem) => subItem.attributes[hoverKey]);
  } else {
    return []
  }

};


export const handleTooltipOpenSites = (item: SiteProp[] | [], heading: string, event: React.MouseEvent<HTMLButtonElement>) => {

  return {
    anchorEl: item.length > 1 ? event.currentTarget : null,
    anchorId: item.length > 1 ? "list-popover" : "",
    listData: item,
    popoverHeading: heading
  }
};

export const handleTooltipOpenProducts = (item: ProductItem[] | [], product_id: string, popoverHeading: string, event: React.MouseEvent<HTMLButtonElement>) => {
  return {
    anchorEl: hoverItems(item, product_id).length > 1 ? event.currentTarget : null,
    anchorId: hoverItems(item, product_id).length > 1 ? "product-popover" : "",
    listData: hoverItems(item, product_id),
    popoverHeading
  }

};

export const handleTooltipClose = () => {
  return { anchorId: "", anchorEl: null, popoverHeading: "", listData: [] }
}

export const generateStatusMapping = (data, statusOrder) => {
  const statusMapping = {};
  statusOrder.forEach(status => {
    statusMapping[status] = [];
  });

  data.forEach(item => {
    const status = item.attributes.status;
    if (statusMapping.hasOwnProperty(status)) {
      statusMapping[status].push(item);
    }
  });

  return statusMapping
};

export const getStatusTextStyle = (status: string) => {
  if (status === 'pending' || status === "قيد الانتظار" || status === 'upcoming' || status === 'القادمة' || status==="awaiting_payment" || status ==='انتظار الدفع') {
    return {
      color: "#6764d6",
      textTransform: "capitalize" as const
    };
  } else if (status === 'converted_to_job' || status === "converted" || status === 'to_be_invoiced' || status === 'تحويل_إلى_وظيفة' || status==="تحويل" || status === 'مفوترة'  || status==="paid" || status ==='مدفوع') {
    return {
      color: "#74ab39",
      textTransform: "capitalize" as const
    };
  } else if (status === 'quoted' || status === 'completed' || status === 'مقتبس' || status === 'مكتمل'|| status==="bad_debt" ||status==='الديون المعدومة') {
    return {
      color: "#fa6400",
      textTransform: "capitalize" as const
    };
  } else if (status === 'overdue' || status === 'late' || status === 'تأخرت' || status === 'متأخر' || status==="payment_overdue" || status==='التأخر في الدفع') {
    return {
      color: "#e02020",
      textTransform: "capitalize" as const
    };
  } else if (status === 'cancelled' || status === 'ألغيت' || status==="archived" || status==='مؤرشف') {
    return {
      color: "#8C8C8C",
      textTransform: "capitalize" as const
    };
  } else if (status === 'sent' || status === 'المرسلة') {
    return {
      color: "#005719",
      textTransform: "capitalize" as const
    };
  } else if (status === "active") {
    return {
      color: "rgba(0,10,255,0.66)",
      textTransform: "capitalize" as const
    };
  } else if (status === "archived") {
    return {
      color: "#ffbc00",
      textTransform: "capitalize" as const
    };
  } else {
    return {
      color: CONSTANTS.color.darkBlue,
      textTransform: "capitalize" as const
    };
  }
};

export const getStatusTextStyleCustomer = (status: string) => {
  if (status === "active") {
    return {
      color: "rgba(0,10,255,0.66)",
      fontWeight: 500,
      fontSize: "16px",
      textTransform: "capitalize" as const
    };
  } else {
    return {
      color: "#ffbc00",
      fontWeight: 500,
      fontSize: "16px",
      textTransform: "capitalize" as const
    };
  }
}
export const getDividerTextStyleCustomer = (status: string) => {
  if (status === "active") {
    return {
      backgroundColor: "#000aff",
    };
  } else {
    return {
      backgroundColor: "#ffbc00",

    };
  }
}
export const getDividerTextStyle = (status: string) => {
  if (status === 'pending' || status === 'upcoming' || status === "قيد الانتظار" || status === 'القادمة'|| status==="awaiting_payment" || status ==='انتظار الدفع') {
    return {
      backgroundColor: "#6764d6",
    };
  } else if (status === 'converted_to_job' || status === "converted" || status === 'to_be_invoiced' || status === 'تحويل_إلى_وظيفة' || status === 'مفوترة' || status==="paid" || status ==='مدفوع') {
    return {
      backgroundColor: "#74ab39",
    };
  } else if (status === 'quoted' || status === 'completed' || status === 'مقتبس' || status === 'مكتمل' || status==="bad_debt" ||status==='الديون المعدومة') {
    return {
      backgroundColor: "#fa6400",
    };
  } else if (status === 'overdue' || status === 'late' || status === 'تأخرت' || status === 'متأخر'|| status==="payment_overdue" || status==='التأخر في الدفع') {
    return {
      backgroundColor: "#e02020",
    };
  } else if (status === 'cancelled' || status === 'ألغيت'|| status==="archived" || status==='مؤرشف') {
    return {
      backgroundColor: "#8C8C8C",
    };
  } else if (status === 'sent' || status === 'المرسلة') {
    return {
      backgroundColor: "#005719",
    };
  } else if (status === "active") {
    return {
      backgroundColor: "#000aff;",

    };
  } else if (status === "archived") {
    return {
      backgroundColor: "#ffbc00",

    };
  } else {
    return {
      backgroundColor: CONSTANTS.color.darkBlue,
    };
  }
};


