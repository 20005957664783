import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  Theme,
  withStyles,createStyles
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { CONSTANTS } from "./theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
interface Props {
  description: string;
  title: string;
  deleteDialogOpen: boolean;
  handleClickProceedDelete: Function;
  handleCloseDialog: boolean;
  loader: boolean;
}

export const StyledDialog = withStyles((theme: Theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: "1rem",
      width: "100%",
      maxWidth: "634px",
      minHeight: "263px",
      borderRadius: "8px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
      [theme.breakpoints.down("xs")]: {
        minHeight: "242px !important",
        paddingTop: "2.5rem !important",
      }
    },
    "& .MuiDialogTitle-root": {
      [theme.breakpoints.down("xs")]: {
        padding: "8px !important"
      }
    }
  }
}))(Dialog);

export const StyledCloseIcon = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      left: "90% !important",
      top: "14% !important"
    }
  }
}))(CloseIcon);

export const StyledButton = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "176px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    color: CONSTANTS.color.darkBlue,
    marginLeft: "1.5rem",
    textTransform: "none" as const,
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
      border:"none"
    },
      transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0 !important",
      minHeight: "47px !important"
    },
    [theme.breakpoints.down(480)]: {
      maxWidth: "100% !important",
    },
  }
}))(Button);

export const StyledTypography = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important"
    }
  }
}))(Typography);

export const StyledTypographyProceed = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
      fontWeight: `${400} !important`
    }
  }
}))(Typography);

export const StyledDialogTitle = withStyles((theme: any) => ({
  root: {
    "& .MuiTypography-h6": {
      color: CONSTANTS.color.lightBrown,
      fontSize: "30px",
      fontWeight: CONSTANTS.fontWeight.fontWeight500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.6867rem !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px !important",
      },
    },
  }
}))(DialogTitle);

export const StyledDialogContent = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "8px !important",
    },
  }
}))(DialogContent);

export const StyledDialogContentText = withStyles((theme: Theme) => ({
  root: {
    color: CONSTANTS.color.lightBrown,
    fontSize: "24px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4286rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
      marginBottom: "0 !important",
    },
  }
}))(DialogContentText);

export const StyledDialogActions = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      gap: "1rem !important",
    },
  }
}))(DialogActions);
const CancelButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      maxWidth: "176px",
      width: "100%",
      minHeight: "67px",
      borderRadius: "10px",
      color: CONSTANTS.color.lightGrey,
      border: `1px solid ${CONSTANTS.color.lightGrey}`,
      marginLeft: "1.5rem",
      textTransform: "none" as const,
      transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
      '&:hover': {
        backgroundColor: '#999999',
        color: theme.palette.common.white,
        cursor: "pointer"
      },
      [theme.breakpoints.down('xs')]: {
        height: "48px",
        width: '100%'
      },
      [theme.breakpoints.between("sm", 'md')]: {
        width: "44%",
      },
    }
  }))(Button)
// Customizable Area End

export class DeleteModal extends React.Component<any, Props, any> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const { deleteDialogOpen, handleCloseDialog, title, description, handleClickProceedDelete, loader } = this.props;
    return (
      <>
        <StyledDialog
          open={deleteDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <StyledCloseIcon
            style={webStyle.close}
            onClick={handleCloseDialog}
          />
          <StyledDialogTitle id="responsive-dialog-title">
            {title}
          </StyledDialogTitle>
          <StyledDialogContent>
            <StyledDialogContentText>
              {description}
            </StyledDialogContentText>
          </StyledDialogContent>
          <StyledDialogActions>
            <CancelButton
              onClick={handleCloseDialog}
            >
              <StyledTypography
                style={webStyle.buttonCancelText}
                variant="subtitle2"
              >
                {t('settings.cancel')}
              </StyledTypography>
            </CancelButton>
            <StyledButton
              onClick={handleClickProceedDelete}
            >
              <StyledTypographyProceed
                style={webStyle.buttonCancelText}
                variant="subtitle2"
              >
                {t('settings.proceed')} {loader}
              </StyledTypographyProceed>
            </StyledButton>
          </StyledDialogActions>
        </StyledDialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  buttonCancelText: {
    fontSize: "22px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  close: {
    position: "absolute" as const,
    left: "95%",
    top: "12%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer"
  },
}

export default withTranslation()(DeleteModal);
// Customizable Area End