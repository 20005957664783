import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  Hidden
} from "@material-ui/core";
import {
  tickIcon
} from "./assets";
import "./Style.css";
import CompareLamsaFeatures from "./CompareLamsaFeatures.web";
import { CONSTANTS } from "../../../components/src/theme";
import StyleDialog from "../../../components/src/StyledDialog"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "./Swiper.css";
// Customizable Area End

import PricingController, {
  Props,
} from "./PricingController.web";

// Customizable Area Start
interface ButtonType {
  display: string;
  justifyContent: string;
  alignItems: string;
  backgroundColor: string;
  borderRadius: string;
  border: string;
  padding: string;
  maxWidth: string;
  width: string;
  marginTop: string;
}

interface MonthlyData {
  id: string;
  activeIcon: string;
  icon: string;
  title: string;
  planPrice: string;
  currency: string;
  body: string;
  planTerms: { id: number; plan_type: string; discription: string; }[];
  buttonBody: string;
  buttonText: string;
  choosen: boolean
}

interface IData {
  id: number;
  plan_type: string;
  discription: string;
}
// Customizable Area End

class Monthly extends PricingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const buttonStyleMonthly = (item: MonthlyData) => {
      const buttonText = "ابدأ النسخة التجريبية المجانية";
    
      const inactiveStyleMonthly: Record<string, string> = {};
      if (this.props.disabled && item.planPrice === "0") {
        inactiveStyleMonthly.border = `1px solid ${CONSTANTS.color.metallicGrey}`;
        inactiveStyleMonthly.backgroundColor = CONSTANTS.color.metallicGrey;
      }
      const backgroundStyleMonthly: Record<string, string> = {}
      if(this.props.route === "FreeTrial" && this.props.disabled && item.planPrice === "0") {
        backgroundStyleMonthly.backgroundColor = CONSTANTS.color.metallicGrey;
      }
      const activeStyleMonthly = (this.state.activePlan === item.planPrice) ? { backgroundColor: CONSTANTS.color.thickBlue } : {};
      let buttonStyleMonthly: ButtonType = { ...webStyle.buttonsContainer, ...inactiveStyleMonthly, ...activeStyleMonthly, ...backgroundStyleMonthly };
    
      if (this.state.languageSelected === "ar" && item.buttonText === buttonText) {
        buttonStyleMonthly = { ...buttonStyleMonthly, maxWidth: "248px" };
      }
    
      return buttonStyleMonthly;
    }

    const textStyleMonthly = (item: MonthlyData) => {
      const { route } = this.props;
      const { activePlan } = this.state;
  
      const isActiveMonthly = activePlan === item.planPrice;
      const isFreeTrialMonthly = route === "FreeTrial" && item.planPrice === "0";

      const styleMonthly = {
        ...webStyle.buttonsText,
        color: isActiveMonthly ? CONSTANTS.color.white : CONSTANTS.color.thickBlue,
      };

      if (isFreeTrialMonthly) {
        styleMonthly.color = CONSTANTS.color.coolDarkGrey;
      }
      return styleMonthly;
    }

    const monthlyData = (item: MonthlyData) => {
      return (
        <Grid item lg={3} md={4} sm={6} xs={12} key={item.id} className="gridContainer">
          <Box
            data-test-id="handleSelectPlan"
            style={
              this.state.activePlan === item.planPrice ?
              {...webStyle.monthlyPlanContainer, border: `1.5px solid ${CONSTANTS.color.thickBlue}`} :
              webStyle.monthlyPlanContainer
            }
            onClick={() => this.handleSelectPlan(item.planPrice)}
            >
            <img style={webStyle.inactiveCheckIcon} src={this.state.activePlan === item.planPrice ? item.activeIcon : item.icon} alt="" />
            <Box style={webStyle.monthlyPlanInnerContainer}>
              <Typography variant="subtitle1" style={webStyle.tryUsText}>
                {item.title}
              </Typography>
              <Typography variant="h3" style={webStyle.planPriceText}>
                {item.currency} {item.planPrice}
              </Typography>
              <Typography style={webStyle.planPriceBodyText}>
                {item.body}
              </Typography>
              <Box style={webStyle.monthlyTermsContainer}>
                {item.planTerms.map((i: IData) => (
                  <Box style={this.state.languageSelected === "ar" ? {...webStyle.monthlyTickContainer, gap: "0.5rem"} : webStyle.monthlyTickContainer} key={i?.id}>
                    <img style={webStyle.tickIconImg} src={tickIcon} alt="" />
                    <Typography
                      style={webStyle.tickIconText}
                      variant="caption"
                      dangerouslySetInnerHTML={{
                        __html: (`${i?.discription}`)
                      }}
                    ></Typography>
                  </Box>
                ))}
              </Box>
              <Button
                data-test-id="handleButtonStartUsing"
                style={buttonStyleMonthly(item)}
                onClick={() => this.handleButtonStartUsing(item)}
              >
                <Typography
                  style={textStyleMonthly(item)}
                  variant="body1"
                >
                  {item.buttonText}
                </Typography>
              </Button>
              <Typography
                style={
                  (this.props.route === "FreeTrial" && item.planPrice === "0") ?
                  {...webStyle.freeTrialText, color: CONSTANTS.color.coolDarkGrey} :
                  webStyle.freeTrialText
                }
              >
                {item.buttonBody}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );
    }
    return (
      <Grid container style={webStyle.monthlyContainer}>
        
        <Hidden smUp>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {this.state.pricingPlanMonthlyData?.map((item: MonthlyData) => {
              return (
                <SwiperSlide style={webStyle.swiperStyle}>
                  {monthlyData(item)}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Hidden>
        <Hidden xsDown>
          {this.state.pricingPlanMonthlyData?.map((item: MonthlyData) => {
            return (
              <>
                {monthlyData(item)}
              </>
            );
          })}
        </Hidden>

        <CompareLamsaFeatures {...this.props} />
      
        <StyleDialog
          data-test-id="createPlan"
          planModal={this.state.planModal}
          handleClosePlanModal={this.handleClosePlanModal}
          planType={this.state.planType}
          handleClickProceedPlan={this.handleClickProceedPlan}
          languageSelected={this.state.languageSelected}
        />
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  monthlyContainer: {
    marginTop: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  swiperStyle: {
    paddingBottom: "1rem"
  },
  monthlyPlanContainer: {
    minHeight: "457px",
    maxWidth: "288px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 10px #ECECEC",
    padding: "1rem",
    cursor: "pointer",
    marginTop: "1rem",
    filter:"drop-shadow(0px 0px 10px rgba(0,0,0,0.05000000074505806))"
  },
  inactiveCheckIcon: {
    width: "24px",
    height: "24px",
  },
  monthlyPlanInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  tryUsText: {
    color: CONSTANTS.color.oliveGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  planPriceText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    textAlign: "center" as const,
    margin: "5px 0"
  },
  planPriceBodyText: {
    opacity: 0.7,
    color: CONSTANTS.color.oliveGrey,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  monthlyTermsContainer: {
    display: "flex",
    flexDirection: "column" as const,
    marginTop: "0.5rem"
  },
  monthlyTickContainer: {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    marginTop: "1rem"
  },
  tickIconImg: {
    width: "11px",
    height: "8px",
  },
  tickIconText: {
    color: CONSTANTS.color.oliveGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginLeft: "0.75rem"
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.white,
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.thickBlue}`,
    padding: "1rem 0",
    maxWidth: "227px",
    width: "100%",
    marginTop: "1.5rem"
  },
  buttonsText: {
    color: CONSTANTS.color.thickBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textTransform: "none" as const
  },
  freeTrialText: {
    color: CONSTANTS.color.darkBlue,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
    marginTop: "1rem"
  },

}
export default Monthly;
// Customizable Area End