import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { checkLoggedInUser, performSupplierAuthorizationCheck } from "./utility.web";
import { apiCall,debounce } from "../../../components/src/CommonFunction";
export const configJSON = require("./config");

// Customizable Area Start
const mockToggleData = [
  { id: 1, title: "Supplier Name", hidden: true },
  { id: 2, title: "Contact Name", hidden: true },
  { id: 3, title: "Mobile Number", hidden: true },
  { id: 4, title: "Email Address", hidden: true },
  { id: 5, title: "Supplier Of", hidden: true },
]
const mockToggleDataAr = [
  { id: 1, title: "اسم المورد", hidden: true },
  { id: 2, title: "اسم جهة الاتصال", hidden: true },
  { id: 3, title: "رقم الهاتف المحمول", hidden: true },
  { id: 4, title: "عنوان البريد الإلكتروني", hidden: true },
  { id: 5, title: "مورد", hidden: true },
]

interface MockStatusData {
  id: number;
  title: string;
  value: string;
}

interface MockToggleData {
  id: number;
  title: string;
  hidden: boolean;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  statusModal: boolean;
  toggleModal: boolean;
  information: boolean;
  purchases: boolean;
  status: string;
  checkedItems: string[];
  suppliersData: any;
  getSuppliersLoading: boolean;
  sortDirection: string;
  sortColumn: string;
  toggleData: MockToggleData[];
  searchSupplier: string;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SuppliersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllSuppliersApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      statusModal: false,
      toggleModal: false,
      information: false,
      purchases: false,
      status: "all",
      checkedItems: ["all", "active", "archived"],
      suppliersData: {},
      getSuppliersLoading: false,
      sortDirection: "asc",
      sortColumn: "",
      toggleData: mockToggleData,
      searchSupplier: "",
      languageSelected: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    if (!await getStorageData("authToken")) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }

    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });

    if(selectedLanguage === "ar") {
      this.setState({ toggleData: mockToggleDataAr });
    }

    this.getAllSuppliers();
    this.setState({ getSuppliersLoading: true });
    await removeStorageData(configJSON.supplier_id);
    
    const deleteSupplierToaster = await getStorageData(configJSON.deleteSupplierToaster);
    const archiveSupplierToaster = await getStorageData(configJSON.archiveSupplierToaster);
    const UnarchiveSupplierToaster = await getStorageData(configJSON.UnarchiveSupplierToaster);
    await removeStorageData(configJSON.deleteSupplierToaster);
    await removeStorageData(configJSON.archiveSupplierToaster);
    await removeStorageData(configJSON.UnarchiveSupplierToaster);
    const { t } = this.props;
    if(deleteSupplierToaster) {
      this.props.openToastHandler(t('suppliers.supplierDeleted'), "success");
    } else if(archiveSupplierToaster) {
      this.props.openToastHandler(t('suppliers.supplierArchived'), "success");
    } else if(UnarchiveSupplierToaster) {
      this.props.openToastHandler(t('suppliers.supplierUnArchived'), "success");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  tokenExpired = (isEmployee: boolean) => {
    setTimeout(() => {
      localStorage.clear();
      this.props.navigation.history?.push(isEmployee ? "/Employee/Login" : "/Login");
    }, 2000);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllSuppliersApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.showOpenToastHandler();
            this.setState({ suppliersData: [] });
            this.setState({ getSuppliersLoading: false });
          } else { 
            this.setState({ getSuppliersLoading: false });
            this.setState({ suppliersData: responseJson });
          }
        } else if (responseJson?.errors) {
          this.setState({ getSuppliersLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ getSuppliersLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    this.handleSearchSuppliers(message);
    // Customizable Area End
  }

  // Customizable Area Start  
  errorMessageHandler = async (error: { token: string }) => {
    const { token } = error;
    if(token == configJSON.tokenExpired || token == configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  getErrorMessage = (error: { token: string }) => {
    if (error) {
      this.errorMessageHandler(error);
    }
  }
  
  handleSearchSuppliers = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getAllSuppliersApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.showOpenToastHandler();
            this.setState({ suppliersData: [] });
          } else {
            this.setState({ suppliersData: responseJson });
          }
        } else if (responseJson?.errors?.[0]?.message == configJSON.notFoundSupplierLabel) {
          this.setState({ suppliersData: [] });
        } else if (responseJson?.errors) {
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
 
  getAllSuppliers = async () => {
    const { languageSelected } = this.state;
    const apiEndPoint = configJSON.getAllSuppliersAPIEndPoint
    const token = await getStorageData(configJSON.storageToken)
    const header = {
      "token": token
    }; 
    const status = this.state.status.toLowerCase();
    let supplierListUrl 
    if(this.state.searchSupplier && this.state.sortColumn){
      supplierListUrl= `${apiEndPoint}?status=${status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&query=${this.state.searchSupplier}&lang=${languageSelected}`
    } else if(this.state.searchSupplier){
      supplierListUrl= `${apiEndPoint}?status=${status}&query=${this.state.searchSupplier}&lang=${languageSelected}`
    } else if(this.state.sortColumn){
      supplierListUrl=`${apiEndPoint}?status=${status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${languageSelected}`
    } else{
      supplierListUrl= `${apiEndPoint}?status=${status}&lang=${languageSelected}`
    }
    this.setState({getSuppliersLoading:true})
    const getSupplierAPI = apiCall({
      header: header,
      httpBody: '',
      url:supplierListUrl,
      httpMethod:configJSON.getSuppliersAPIMethod,
    });
    this.getAllSuppliersApiCallId = getSupplierAPI.messageId;
    runEngine.sendMessage(getSupplierAPI.id, getSupplierAPI);
  }
  debounceEetAllListSearchEmployees = debounce(this.getAllSuppliers, 700);

  handleChangeSearchSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchSupplier: event.target.value }, () => {
      this.debounceEetAllListSearchEmployees();
    });
  }
  
  handleStatusSupplierModalOpen = () => {
    this.setState({ statusModal: !this.state.statusModal });
    this.setState({ toggleModal: false });
  }

  handleStatusSupplierModalClose = () => {
    this.setState({ statusModal: false });
  }

  handleToggleSupplierModalOpen = () => {
    this.setState({ toggleModal: !this.state.toggleModal });
    this.setState({ statusModal: false });
  }

  handleToggleSupplierModalClose = () => {
    this.setState({ toggleModal: false });
  }

  handleSelectStatus = (item: { value: string; }, suppliersStatusData: MockStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {
    let checkedItems = [...this.state.checkedItems];
    let status = "";

    if (event.target.checked) {
      if (item.value === "all") {
        checkedItems = suppliersStatusData.map((subItem: MockStatusData) => subItem.value);
        status = "all";
      } else {
        checkedItems.push(item.value);
        status = checkedItems.join(",");
      }
    } else {
      if (item.value === "all") {
        checkedItems = [];
      } else {
        checkedItems = checkedItems.filter((subItem: string) => subItem !== item.value && subItem !== "all");
        status = checkedItems.join(",");
      }
    }

    this.setState({
      checkedItems: checkedItems,
      status: status
    }, this.getAllSuppliers);
  }

  handleCreateSupplier = async () => {
    const isAuthorized = await performSupplierAuthorizationCheck("supplier_data"); //true = not authorized , false = authorized
    this.handleCreateSupplierCallback(isAuthorized);
  };

  handleCreateSupplierCallback = (isAuthorized: boolean) => {
    if (!isAuthorized) {
      this.props.navigation.history?.push(configJSON.CreateSuppliers);
    } else {
      this.showOpenToastHandler();
    }
  };

  showOpenToastHandler = () => {
    const { t } = this.props;
    this.props.openToastHandler(t('suppliers.notAuthorized'), configJSON.errorLabel);
  }
 
  handleSortSupplier = (columnKey: string) => {
    let newSortDirection: "asc" | "desc" = "asc";
    if (columnKey === this.state.sortColumn) {
      newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
    }
    this.setState({
      sortDirection: newSortDirection,
      sortColumn: columnKey
    },()=>{
      this.debounceEetAllListSearchEmployees()
    });
  };

  handleToggleColumn = (id: number) => {
    const newToggleData = this.state.toggleData.map((item: MockToggleData) => {
      if (item.id === id) {
        return { ...item, hidden: !item.hidden };
      }
      return item
    })

    this.setState({
      toggleData: newToggleData,
    });
  }

  handleViewSupplierData = async (item: { id: string | number, value: string }) => {
    this.props.navigation.history?.push(configJSON.SuppliersData);
    await setStorageData(configJSON.supplier_id, item?.id);
  }

  handleBackIcon = () => {
    this.props.navigation.history.goBack();
  }

}
// Customizable Area End