
import React,{RefObject} from "react";
import {
     Grid, Typography,
    WithStyles,Button
} from "@material-ui/core";
import { GoogleMap, Marker } from "@react-google-maps/api";

interface CustomerPlace{
    lat:number
    lng:number
}

interface googleMapProps extends WithStyles{
    location:CustomerPlace,
    reference:any
    getCustomerPlace:CustomerPlace
    t: (key: string) => string;
}
export const CustomGoogleMap = (props:googleMapProps) => {
    const onMapLoad = (map: google.maps.Map) => {
        props.reference.current = map;
        const zoomInButton = document.createElement('button');
        zoomInButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
            <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
        </svg>`;
        zoomInButton.style.backgroundColor = 'white';
        zoomInButton.style.marginTop = '10px';
        zoomInButton.style.marginBottom = '10px';
        zoomInButton.style.border = 'none';
        zoomInButton.style.paddingLeft = '5px';
        zoomInButton.style.cursor = 'pointer';
        zoomInButton.style.padding = '6px 3px 0px 5px';
        zoomInButton.style.borderRadius = '4px';
        zoomInButton.title = 'Zoom In';
        zoomInButton.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
        const zoomOutButton = document.createElement('button');
        zoomOutButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
        </svg>`;
        zoomOutButton.style.border = 'none';
        zoomOutButton.style.cursor = 'pointer';
        zoomOutButton.style.backgroundColor = 'white';
        zoomOutButton.style.marginBottom = '0px';
        zoomOutButton.style.padding = '6px 3px 0px 5px';
        zoomOutButton.style.borderRadius = '4px';
        zoomOutButton.style.paddingLeft = '5px';
        zoomOutButton.title = 'Zoom Out';
      
        zoomOutButton.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
      
        const controlDiv = document.createElement('div');
        controlDiv.style.position = 'absolute';
        controlDiv.style.marginRight = '10px';
        controlDiv.style.display = 'flex';
        controlDiv.style.flexDirection = 'column';
        controlDiv.style.zIndex = '1';
        controlDiv.style.marginBottom = '10px';
        controlDiv.style.right = '10px';
        controlDiv.style.justifyContent = 'space-between'; 
        controlDiv.appendChild(zoomInButton);
        controlDiv.appendChild(zoomOutButton);
        const controlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
        map.controls[controlPosition].push(controlDiv);
    }; 
    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={1}>
            <Grid item xs={12} style={{direction:localStorage.getItem('lang')==='ar'?'ltr':'rtl'}}>
                <GoogleMap
                    mapContainerStyle={webStyle.selectedCustomerMapStyles}
                    center={props.location}
                    zoom={5}
                    data-test-id="mapComponent"
                    onLoad={(map) => {
                        onMapLoad(map);
                    }}
                    options={{
                        disableDefaultUI: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl:false
                    }}
                >
                    <div><Button
                        data-test-id="currentlocation"
                        style={webStyle.googleSymbol}
                        onClick={() => {
                            const { lat, lng } = props.getCustomerPlace;
                            const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}&z=15&markers=${lat},${lng}`;
                            window.open(googleMapsUrl, '_blank');
                        }}
                    />
                    </div>
                    {props?.getCustomerPlace && (
                        <Marker position={props.getCustomerPlace} />
                    )}
                </GoogleMap>
            </Grid>
        </Grid>
    )
}

const webStyle = {
    selectedCustomerMapStyles: {
        width: "100%",
        minHeight: "320px",
        borderRadius: "10px",
    },
    locationText: {
        color: '#34313a',
        fontWeight: 500
    },
    googleSymbol: {
        backgroundColor: "transparant",
        border: "none",
        width: "100px",
        height: '30px',
        position: "absolute",
        top: '290px'
    } as const
    
}