Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskAllocator";
exports.labelBodyText = "TaskAllocator Body";

exports.btnExampleTitle = "CLICK ME";

exports.createATaskLabel = "+ Create a Task";
exports.upcomingTasksLabel = "Upcoming Tasks";
exports.todoNameLabel = "Task Name (To do)";
exports.assigneeLabel = "Assignee";
exports.dueDateLabel = "Due date";
exports.urgencyLabel = "Urgency";
exports.actionLabel = "Action";
exports.addUrgencyLabel = "Add Urgency";
exports.addDateLabel = "Add Date";
exports.addAssigneeLabel = "Add Assignee";
exports.todoLabel = "To do";
exports.taskListEmptyLabel = "Your task list is empty";
exports.taskListEmptyDescLabel = "You don’t have any task available click on create a task button to add new task";
exports.completedTasksLabel = "Completed Tasks";
exports.authToken = "authToken";
exports.tokenExpired = "Token has Expired";
exports.invalidToken = "Invalid token";
exports.errorLabel = "error";

exports.getAllEmployesAPiEndPoint = "bx_block_request_management/task_allocates/get_employees";
exports.getEmployee = "account_block/employees"
exports.taskAPiEndPoint = "bx_block_request_management/task_allocates";
exports.addTaskAPiMethod = "POST";
exports.getAPiMethod = "GET";
exports.deleteAPiMethod = "DELETE";
exports.editAPiMethod = "PUT";
exports.patchAPiMethod = "PATCH";
// Customizable Area End