//@ts-nocheck
import React from 'react';
// Customizable Area Start
import { Button, Dialog, Grid,DialogTitle,Avatar,Divider, Typography,DialogContent,DialogActions } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { withSnackbar } from "../../../components/src/toastweb";
import {  StyledTextFieldRequest } from "../../RequestManagement/src/Utility.web";
import EmpSelectInput from './EmpSelectInput.web'
import withCalendarStyles from "../../appointmentmanagement/src/CommonStyle.web";
import { dateMinusOne } from "../../../components/src/CommonFunction";
import TimePicker from "../../../components/src/TimePicker";
import { StyledCheckbox } from './Calendar.web' 
import CustomDatePicker from "../../../components/src/DatePicker";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from 'yup'
export const configJSON = require("./config");
import Select, { components } from 'react-select'
import { CONSTANTS } from "../../../components/src/theme"
// Customizable Area End
// Customizable Area Start
interface Props {
    navigation?: Function;
    id?: string;
    history?: Function
    isOpen: boolean
    onClose: Function
    isOpenNewJob:boolean
}
// Customizable Area End
export class Job extends React.Component {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customSelectStyle(){
        return {
            indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
            control: (styles) => ({
                ...styles, borderRadius: "8px", padding: "7px",
                borderColor: "hsl(0,0%,80%)",
                boxShadow: "none",
                '&:hover': {
                    borderColor: "hsl(0,0%,80%)",
                },
            }),
            valueContainer: (styles) => ({ ...styles, padding: "0px 0px" }),
            singleValue: (styles) => ({ ...styles, color: CONSTANTS.color.lightGrey, fontSize: '19px' }),
            menu: (styles) => ({ ...styles, zIndex: 99999 }),
        }
    };
    finalErrorMessage(error: string, touch: boolean) {
        const { classes } = this.props
        if (error) {
            if (touch) {
                return <div className={`${classes.errorText} ${classes.text_red}`}>
                    {error}
                </div>
            }
        }
    } 
    checkValue = (openNewApp: boolean, value: string | boolean) => {
        if (openNewApp) {
          return value
        } else {
          return ''
        }
      }
      startEnd = (view: string, value1: Function, value2: Date) => {
          if (view) {
            return value1
          } else {
            return value2
          }
      }
      checkbox({ values, classes, handleChange,t }) {
        return <> {!this.props.viewJobDetails &&
            <Grid item xs={12} sm={6}>
                <Grid container alignItems="center"
                    className={`${classes.pl_4} ${classes.mt_10}`}
                >
                    <StyledCheckbox
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        onChange={handleChange}
                        name='all_day'
                        checked={values.all_day}
                    />
                    <Typography variant='body1' className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.pr_9}` : classes.font_gray}>{t('Calendar.label.allDay')}</Typography>
                </Grid>
            </Grid>
        }
        </>
    }
    startDate() {
        const date = moment(this.props.selectedEventData.start_date, 'YYYY-MM-DD')
        return moment.utc(date).toDate()
    }
    endDate() {
        const date = moment(this.props.selectedEventData.end_date, 'YYYY-MM-DD')
        return moment.utc(date).toDate()
    }
    checkObjectIsEmpty(object) {
        if(Object.keys(object).length===0){
          return true
        }else{
          return false
        }
      }
    employeeInitialValue = () => {
        const employeeID = this.props.getEmployeeData.find((item) =>
            item.id === this.props.employeeId
        )
        let employeeId
        let name
        let email
        let image
        if (!this.props.isEmployee) {
            if (this.checkObjectIsEmpty(this.props.selectedEventData)) {
                employeeId = null
                name = ''
                email = ''
                image = ''
            } else {
                employeeId = this.props.selectedEventData.employee.map((item) => {
                    return this.checkValue(!this.props.isOpenNewJob, item.employee_id)
                })
                name = this.props.selectedEventData.employee.map((item) => {
                    return this.checkValue(!this.props.isOpenNewJob, item.employee_name)
                })
                email = this.props.selectedEventData.employee.map((item) => {
                    return this.checkValue(!this.props.isOpenNewJob, item.employee_email)
                })
                image = this.props.selectedEventData.employee_img.map((item) => {
                    return this.checkValue(!this.props.isOpenNewJob, item.employee_image)
                })
            }
        } else {
            employeeId = !this.props.isOpenNewJob ? this.props.selectedEventData.employee[0].employee_id : employeeID?.id
            name = !this.props.isOpenNewJob ? this.props.selectedEventData.employee[0].employee_name : employeeID?.attributes.full_name
            email = !this.props.isOpenNewJob ? this.props.selectedEventData.employee[0].employee_email : employeeID?.attributes.email
            image = !this.props.isOpenNewJob ? this.props.selectedEventData.employee[0].employee_image || '' : employeeID?.attributes.employee_image || ''
        }

        return { employeeId, name, email, image }
    }
    initialValues = () => ({
        start_time : !this.props.isOpenNewJob ? this.props.selectedEventData.start_time : moment(new Date( this.props.appointmentForm.start)).format("HH:mm A").toString(),
        end_time : !this.props.isOpenNewJob ? this.props.selectedEventData.end_time : moment(new Date( this.props.appointmentForm.end)).format("HH:mm A").toString(),
        title : this.checkValue(!this.props.isOpenNewJob, this.props.selectedEventData.title),
        all_day : this.checkValue(!this.props.isOpenNewJob, this.props.selectedEventData.all_day),
        start_date: !this.props.isOpenNewJob ? this.startDate() : this.props.appointmentForm.start,
        end_date: !this.props.isOpenNewJob ? this.endDate() : 
        this.startEnd(this.props.view==='month',dateMinusOne(this.props.appointmentForm.end),this.props.appointmentForm.end),
        employee_id: this.employeeInitialValue().employeeId || '',
        employee_name: this.employeeInitialValue().name,
        employee_email: this.employeeInitialValue().email,
        employee_image: this.employeeInitialValue().image,
        customer_id: this.checkValue(!this.props.isOpenNewJob, this.props.selectedEventData.customer_id),
        site_id: this.checkValue(!this.props.isOpenNewJob, this.props.selectedEventData.site_id)
      })
    formTitle() {
        const { classes,t } = this.props
        return <>
            <DialogTitle>
            {this.props.viewJobDetails &&
                    <Grid container style={{ justifyContent: 'end' }}>
                        <ClearIcon data-test-id='closeModal' className={classes.text_lightBrown} onClick={this.props.onClose} />
                    </Grid>
                }
                <Typography variant='h6' className={`${classes.text_lightBrown} ${classes.paddingTop_15}`}>
                    {this.props.isOpenNewJob && `${t('Calendar.newJob')}`}
                    {this.props.openEditJobModal && `${t('Calendar.editJob')}`}
                    {this.props.viewJobDetails && <span>{t('Calendar.job')} - {this.props.selectedEventData.title}</span>}
                </Typography>
            </DialogTitle>
        </>
    }
    formBtn(){
        const { classes,t } = this.props 
        return <>
            {!this.props.viewJobDetails &&
                <DialogActions className={classes.p_25}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <Button variant="outlined"
                                data-test-id='close-modal'
                                className={this.props.isOpenNewJob ? `${classes.cancelBtn} ${classes.text_border_red} ${classes.text_red}` : `${classes.deleteBtn} ${classes.text_border_gray}`}
                                onClick={this.props.onClose}
                            >
                                <Typography variant='body1'>{t('Calendar.button.cancel')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item sm={5} className={classes.btn_hide_smallScreen}>
                            {!this.props.isOpenNewJob &&
                                <Button variant="outlined"
                                    onClick={this.props.deleteJob}
                                    className={ this.props.selectLanguage === 'ar' ? `${classes.deleteBtn} ${classes.mr_15} ${classes.text_border_red} ${classes.text_red}` : `${classes.deleteBtn} ${classes.marginLeft_25} ${classes.text_border_red} ${classes.text_red}`}
                                >
                                    <Typography variant='body1'>{t('Calendar.button.delete')}</Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid xs={6} item sm={4} style={{ textAlign: 'end' }}>
                            <Button
                                variant="outlined"
                                data-test-id='submit-btn'
                                type='submit'
                                className={classes.saveBtn}
                            >
                                <Typography variant='body1'>{t('Calendar.button.save')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.btn_hide_largeScreen}>
                            {!this.props.isOpenNewJob &&
                                <Button variant="outlined"
                                    onClick={this.props.deleteJob}
                                    className={`${classes.deleteBtn} ${classes.mt_10} ${classes.text_border_red} ${classes.text_red}`}
                                >
                                    <Typography variant='body1'>{t('Calendar.button.delete')}</Typography>
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </DialogActions>
    } 
        </>
    }
    customerInput({ values, PlaceholderText,ClearIndicator,setFieldValue }) {
        const { classes,t } = this.props
        return <>
            <Typography 
            variant='body1'
                style={{ marginTop: "20px" }}
                className={!this.props.viewJobDetails ? `${classes.marginBottom_5} ${classes.text_lightGrey}` : classes.text_lightGrey}>
                {t('Calendar.label.customer')}
                {!this.props.viewJobDetails && <span className={`${classes.text_red}`}>*</span>}
            </Typography>
            {this.props.viewJobDetails &&
                <Typography variant='body1'
                    className={classes.font_gray}>{this.props.getCustomerData.find((item) =>
                        item.id == values.customer_id).attributes.name}
                </Typography>
            }
            {!this.props.viewJobDetails &&
                <Select
                    components={{ PlaceholderText, ClearIndicator }}
                    isClearable
                    styles={this.customSelectStyle()}
                    data-test-id='select-customer'
                    placeholder={<PlaceholderText />}
                    defaultValue={{
                        label: this.props.getCustomerData.find((item) => item.id == values.customer_id)?.attributes.name,
                        value: values.customer_id,
                    }}
                    theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: "#1c18af" } })}
                    options={this.props.getCustomerData.map((item) => {
                        return {
                            value: item.id,
                            label: item.attributes.name
                        }
                    })}
                    onChange={(selected) => {
                        setFieldValue('customer_id', selected && selected.value)
                        setFieldValue('site_id', null)
                        this.props.getSite(selected.value)
                    }}
                />
            }
        </>
    }
    siteInput({values, PlaceholderText, ClearIndicator,setFieldValue }) {
        const { classes,t } = this.props
        return <>
            <Typography variant='body1'
                style={{ marginTop: "20px" }}
                className={!this.props.viewJobDetails ? `${classes.marginBottom_5} ${classes.text_lightGrey}` : classes.text_lightGrey}>
                {t('Calendar.label.site')}
                {!this.props.viewJobDetails && <span className={`${classes.text_red}`}>*</span>}
            </Typography>
            {this.props.viewJobDetails &&
                <Typography variant='body1' className={classes.font_gray}>
                    {this.props.getSiteData.find((item) =>
                        item.id == values.site_id).site_name}
                </Typography>
            }
            {!this.props.viewJobDetails &&
                <Select
                    components={{ PlaceholderText, ClearIndicator }}
                    isClearable
                    data-test-id='select-site'
                    value={{
                        label: values.site_id ? this.props.getSiteData.find((item) =>
                            item.id == values.site_id)?.site_name:null,
                        value: values.site_id,
                    }}
                    placeholder={<PlaceholderText />}
                    theme={(theme) => ({ ...theme, borderRadius: 0, colors: { ...theme.colors, text: 'orangered', primary: "#1c18af" } })}
                    styles={this.customSelectStyle()}
                    options={this.props.getSiteData.map((item) => {
                        return {
                            value: item.id,
                            label: item.site_name
                        }
                    })}
                    onChange={(selected) => {
                        setFieldValue('site_id', selected && selected.value)
                    }}
                />
            }
        </>
    }
    divisibleByThree(number){
        return number % 3 ===0;
    }
    employeeSelectData({ classes }) {
        return <Grid container alignItems="center" className={classes.paddingLeft_18}>
            {this.props.selectedEventData.employee.length>0 && this.props.selectedEventData.employee.map((item, index) => {
                return <> <Grid item xs={12} sm={this.props.selectedEventData.employee.length===1 ? 12 :4}>
                        <Grid container >
                            <Grid item xs={this.props.selectedEventData.employee.length>1 && 3}>
                                <Avatar style={{ marginBottom: '12px', marginTop: '15px' }} src={this.props.selectedEventData.employee_img[index].employee_image || ''} />
                            </Grid>
                            <Grid item xs={this.props.selectedEventData.employee.length>1 && 8} style={{ paddingLeft: '9px', marginTop: "8px" }}>
                                <>
                                    <Typography variant="body1" 
                                    className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.fontWeight_500} ${classes.titleText} ${classes.pr_9}` : `${classes.font_gray} ${classes.fontWeight_500} ${classes.titleText}`}
                                    >
                                        {item.employee_name}
                                    </Typography>
                                    <Typography 
                                    className={this.props.selectLanguage === 'ar' ? `${classes.font_gray} ${classes.font_14} ${classes.titleText} ${classes.pr_9}` : `${classes.font_gray} ${classes.font_14} ${classes.titleText}`}
                                    >
                                        {item.employee_email}
                                    </Typography>
                                </>
                            </Grid>
                            {!this.divisibleByThree(index + 1) && index < this.props.selectedEventData.employee.length - 1 &&
                                <Grid item xs={1} style={{ alignItems: "center", display: "flex" }}>
                                    <Divider className={classes.divider} orientation="vertical" />
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                </>
            })}
        </Grid>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes,t } = this.props
        const validationSchema = Yup.object().shape({
            title: Yup.string().required(`${t('Calendar.ErrorMessage.title')}`),
            customer_id:Yup.string().required(`${t('Calendar.ErrorMessage.CustomerName')}`),
            site_id:Yup.string().nullable(true).required(`${t('Calendar.ErrorMessage.SiteName')}`),
            employee_id: Yup.string().required(`${t('Calendar.ErrorMessage.team')}`),
            start_time: Yup.string().required(`${t('Calendar.ErrorMessage.startTime')}`),
            end_time: Yup.string().required(`${t('Calendar.ErrorMessage.endTime')}`),
            start_date: Yup.date().min(dateMinusOne(new Date()),`${t('Calendar.ErrorMessage.minDate')}`).required(`${t('Calendar.ErrorMessage.startDate')}`),
            end_date: Yup.date().min(dateMinusOne(new Date()),`${t('Calendar.ErrorMessage.minDate')}`).required(`${t('Calendar.ErrorMessage.endDate')}`),
           
        })
        const PlaceholderText = () => {
            return (
                <Grid container>
                    <Grid item style={{ display: "flex" }}><img className={classes.searchIcon1} src={searchIcon} alt="" /></Grid>
                </Grid>
            )
        }
        const ClearIndicator: React.FC<ClearIndicatorProps> = props => {
            return (
                <components.ClearIndicator {...props}>
                    <Grid>{props.getValue()[0].value !== '' ? <ClearIcon style={{ display: "none" }} /> : ''}</Grid>
                </components.ClearIndicator>
            );
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.onClose}
                PaperProps={{
                    style: {
                        width: "500px",
                        borderRadius: '20px',
                    },

                }}>
                {this.formTitle()}
                <Formik
                    initialValues={this.initialValues()}
                    validationSchema={validationSchema}
                    data-test-id="formikJobForm"
                    onSubmit={(values) => {!this.props.isOpenNewJob ?
                        this.props.updateJob(values) : this.props.createJob(values)
                    this.props.onClose()
                }}
                >
                    {({ errors, values, touched, handleSubmit, handleChange, setFieldValue }) => (
                        <form onSubmit={handleSubmit} style={{ overflow: 'auto' }}>
                            <DialogContent style={{ overflow: 'hidden', paddingTop: '0px' }}>
                                <Typography variant='body1' className={!this.props.viewJobDetails ? `${classes.mb_5} ${classes.text_lightGrey}` : classes.text_lightGrey}>
                                {t('Calendar.label.titleLabel')}{!this.props.viewJobDetails && <span className={`${classes.text_red}`}>*</span>}
                                </Typography>
                                {!this.props.viewJobDetails ?
                                    <StyledTextFieldRequest
                                        variant="outlined"
                                        inputProps={{ 'aria-label': 'title' }}
                                        fullWidth
                                        name='title'
                                        onChange={handleChange}
                                        value={values.title}
                                    />
                                    : <Typography variant='body1' className={classes.font_gray}>
                                        <div className={classes.titleText}>
                                            {values.title}
                                        </div>
                                    </Typography>
                                }
                                {this.finalErrorMessage(errors.title, touched.title)}
                                {this.customerInput({ values, PlaceholderText,ClearIndicator,setFieldValue })}
                                {this.finalErrorMessage(errors.customer_id, touched.customer_id, classes)}
                                {this.siteInput({values, PlaceholderText,ClearIndicator,setFieldValue })}
                                {this.finalErrorMessage(errors.site_id, touched.site_id, classes)}
                                <Typography variant='body1' className={`${classes.mb_5} ${classes.text_lightGrey} ${classes.mt_15}`}>{t('Calendar.label.assignTeamMember')}
                                    {!this.props.viewJobDetails && <span className={`${classes.text_red}`}>*</span>}
                                </Typography>
                                {!this.props.viewJobDetails &&
                                <EmpSelectInput
                                    isEmployee={this.props.isEmployee}
                                    getEmployeeData={this.props.getEmployeeData}
                                    selectLanguage={this.props.selectLanguage}
                                    values={values}
                                    defaultValue={values}
                                    type='appointment'
                                    isActive={this.props.isActive}
                                    data-test-id='empDropDown'
                                    onChange={(selected: object) => {
                                        setFieldValue('employee_id', selected && selected.length > 0 && selected.map((item: object) => item.value))
                                    }}
                                    onClick={(employeeId) => setFieldValue("employee_id", values.employee_id.filter((item) => {
                                        return item !== employeeId
                                    }))}
                                />
                                }
                                {this.finalErrorMessage(errors.employee_id, touched.employee_id)}
                                {this.props.viewJobDetails && this.employeeSelectData({classes})}
                                <Typography variant='body1' className={`${classes.mb_5} ${classes.text_lightGrey} ${classes.mt_15}`}>
                                {t('Calendar.label.start')} {!this.props.viewJobDetails && <span className={`${classes.text_red} ${classes.marginLeft_4}`}>*</span>}
                                </Typography>
                                {!this.props.viewJobDetails &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <CustomDatePicker
                                                selectedDate={values.start_date}
                                                dateChange={(date) => {
                                                    setFieldValue('start_date', date)
                                                }
                                                }
                                            />
                                            {this.finalErrorMessage(errors.start_date, touched.start_date)}
                                        </Grid>
                                    <Grid item xs={12} sm={6} className={classes.marginTop_0}>
                                        <TimePicker
                                            name="start_time"
                                            time={values.start_time}
                                            data-test-id='startTime'
                                            onChange={(timeValue: string) =>
                                                setFieldValue('start_time', timeValue)
                                            }
                                            t={t}
                                            languageSelected={this.props.selectLanguage}
                                        />
                                        {this.finalErrorMessage(errors.start_time, touched.start_time)}
                                    </Grid>
                                </Grid>
                               }
                                {this.props.viewJobDetails &&
                                        <Typography variant='body1' className={classes.font_gray}>{moment(values.start_date).format("DD/MM/YYYY").toString()}, {values.start_time}</Typography>
                                    }
                                <Typography variant='body1' className={`${classes.mb_5} ${classes.text_lightGrey} ${classes.mt_15}`}>
                                {t('Calendar.label.end')} {!this.props.viewJobDetails && <span className={`${classes.text_red} ${classes.marginLeft_4}`}>*</span>}
                                </Typography>
                                {!this.props.viewJobDetails &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <CustomDatePicker
                                                selectedDate={values.end_date}
                                                dateChange={(date) =>{
                                                    setFieldValue('end_date', date)}
                                                }    
                                             />
                                            {this.finalErrorMessage(errors.end_date, touched.end_date)}
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.marginTop_0}>
                                            <TimePicker
                                                name="end_time"
                                                data-test-id='endTime'
                                                time={values.end_time}
                                                defaultValue={values.end_time}
                                                onChange={(timeValue: string) =>
                                                    setFieldValue('end_time', timeValue)
                                                }
                                                t={t}
                                                languageSelected={this.props.selectLanguage}
                                            />
                                            {this.finalErrorMessage(errors.end_time, touched.end_time)}
                                        </Grid>
                                    </Grid>
                                }
                                {this.props.viewJobDetails &&
                                    <Typography variant='body1' className={`${classes.font_gray} ${classes.marginBottom_40}`}>{moment(values.end_date).format("DD/MM/YYYY").toString()}, {values.end_time}</Typography>
                                }
                                {this.checkbox({ values, classes, handleChange ,t})}
                            </DialogContent>
                            {this.formBtn()}
                        </form>
                    )}
                </Formik>
            </Dialog>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const CalendarSnackbar = withSnackbar(Job)
export default (withCalendarStyles)(CalendarSnackbar);
// Customizable Area End
