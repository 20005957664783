import React from "react";
import {
    Box,
    Typography, Grid,Tooltip
} from "@material-ui/core";
import { CONSTANTS } from "./theme";

export const Attachments = (props: any) => {
    return (
        <>
            {props?.details && props?.details.attributes?.files && props?.details?.attributes?.files?.length > 0 && props?.details?.attributes?.files.map((file: any) => {
                return (
                    <>
                        <Grid item xs={12} >
                            <Box
                                key={file.name}
                            >
                                <Box
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px 2px 0 5px",
                                    }}
                                >
                                    <Box style={{ marginRight: "9px" }}>
                                        <Tooltip title="download">
                                            <img
                                                style={{ width: "70px", height: "70px" }}
                                                src={
                                                    props?.Icons[file.name.split(".")[1]]
                                                }
                                                alt="upload"
                                                onClick={props.handleDownloadClick.bind(this, file)}
                                            />
                                        </Tooltip>
                                    </Box>
                                    {/* text section */}
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Box>
                                            {" "}
                                            <Typography
                                                gutterBottom
                                                variant="caption"
                                                style={webStyle.uploaFileText}
                                               
                                            >
                                                {file && file.name && file.name.length > 15
                                                    ? file.name
                                                        .split(".")[0]
                                                        .concat(".")
                                                        .concat(file.name.split(".")[1])
                                                        .substring(0, 7) + "..."
                                                    : file.name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                variant="caption"
                                                gutterBottom
                                                style={webStyle.uploaFileText}
                                            >
                                                {file && file.size && Math.floor(file.size / 1024)}kb
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box >
                        </Grid>
                    </>
                );
            })}
        </>
    )

}

const webStyle = {
    uploaFileText: {
        color: CONSTANTS.color.lightGrey,
        fontFamily: "Rubik",
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
        marginBottom: 0,
    }

}