// Customizable Area Start
import { withStyles, TextField, Button,} from "@material-ui/core";

export const StyledTextFieldRequest = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      fontFamily: "Rubik",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "2px"
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",

    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px"
    }
  }
})(TextField);

export const StyledRequestButton = withStyles({
  root: {
    backgroundColor: "#1C18AF",
    width: "230px",
    height: "67px",
    borderRadius: "10px",
    textTransform: "capitalize",
    '&:hover': {
      backgroundColor: "#1C18AF",
      color: "#ffffff",
    }
  }
})(Button)

export const StyledTextFieldQuote = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      fontFamily: "Rubik",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",

    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px"
    },
    '& .MuiOutlinedInput-input': {
      paddingRight:"8px"
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"

    },
    "& .MuiOutlinedInput-adornedStart":{
      paddingLeft: "0px"

    },
    "& .MuiOutlinedInput-inputAdornedStart":{
      paddingLeft: "20px"
    }
  }
})(TextField);
// Customizable Area End