
export const bgImage=require("../assets/bgimage.png")
export const logo=require("../assets/logo.png")


export const lamsaLogo = require("../assets/lamsaLogo.png");
export const emptyImage = require("../assets/emptyImage.png");
export const iIcon = require("../assets/iIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const confirmationImage = require("../assets/confirmationImage.png");
export const radioSelected = require("../assets/radioSelected.png");
export const radioUnSelected = require("../assets/radioUnSelected.png");
export const dropDownIcon = require("../assets/dropDownIcon.png");
export const afterHoverIcon = require("../assets/afterHoverIcon.png");
export const selectedIcon = require("../assets/selectedIcon.png");
export const radioButton = require("../assets/radioButton.png");
export const radioButtonOff = require("../assets/radioButtonOff.png");
export const purchaseOrderIcon = require("../assets/purchaseOrderIcon.png");
export const xlsExtensionIcon = require("../assets/xls-ext.png");
export const searchIcon = require("../assets/searchIcon.png");
export const pdfExtensionIcon = require("../assets/pdf-ext.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const pptExtensionIcon = require("../assets/ppt-ext.png");
export const threeDotsIcon = require("../assets/threeDotsIcon.png");
export const txtExtensionIcon = require("../assets/txt-ext.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const docExtensionIcon = require("../assets/doc-ext.png");
export const pngExtensionIcon = require("../assets/png-ext.png");
export const jpgExtensionIcon = require("../assets/jpg-ext.png");
export const dropDownSelectIcon = require("../assets/dropDownSelectIcon.png");
export const deleteProfileIcon = require("../assets/deleteProfileIcon.png");
export const galleryIcon = require("../assets/galleryIcon.png");
export const dialogBoxIcon = require("../assets/dialogBoxIcon.png");
export const backIcon = require("../assets/backIcon.png");
export const arrowRight = require("../../dashboard/assets/arrowRight.png");
export const arrowLeft = require("../../dashboard/assets/arrowLeft.png");
export const calendarIcon = require("../../dashboard/assets/calendarIcon.png");
export const arrowUp = require("../../dashboard/assets/arrowUp.png");
export const googleMaps = require("../../dashboard/assets/googleMaps.png");
export const backIconMobile = require("../assets/backIconMobile.png");

