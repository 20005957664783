import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    location: any;
    openToastHandler: any;
  t:(key?: string, options?: object) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start

    countryList: any;
    countryCodeList: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class Profilesetupcontroller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countrylistApiCallId: string = "";
    countryCodelistApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            countryList: [],
            countryCodeList: [],
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getCountryList()
        this.getCountryCodeList()
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.countrylistApiCallId) {
                if (responseJson && !responseJson.errors) {
                    let getCountry = responseJson.map((country: any) => {
                        return country.country
                    })
                    this.setState({
                        countryList: getCountry
                    })
                }
            } else if (apiRequestCallId === this.countryCodelistApiCallId) {
                if (responseJson && !responseJson.errors) {
                    this.setState({ countryCodeList: responseJson })
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleKeyDownInput = (e: React.KeyboardEvent<HTMLDivElement>, handleLoginSubmit: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (typeof handleLoginSubmit === 'function') {
                handleLoginSubmit(e);
            }
        }
      }
    
    getCountryList = () => {
        const header: any = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.countrylistApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.countryList}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getCountryCodeList = async() => {
        const header: any = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.countryCodelistApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.countryCodeList}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
}
// Customizable Area End