import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  InputBase,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Divider,
  Popover
} from "@material-ui/core";
import {
  styled,
  withStyles,Theme
} from "@material-ui/core/styles";
import { dropDownIcon } from "../assets/assets";
import { deleteLogoIcon } from "../../Settings/src/assets";
import PriceSectionWeb from "../../QuoteManagement/src/PriceSection.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
export const configJSON = require("./config");
import CustomDatePicker from "../../../components/src/DatePicker";
import { CONSTANTS } from "../../../components/src/theme";
import { StyleGrid } from "./CreateSupplier.web";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withTranslation } from "react-i18next";
import Select, {  OptionsType  } from "react-select";
import Radio, { RadioProps } from '@material-ui/core/Radio';
// Customizable Area End

import PurchasesController, {
  Props,
} from "./PurchasesController.web";

// Customizable Area Start
 interface TaxRateResponse {
  id: number;
  company_information_id: number;
  name: string;
  rate: number;
  description: string;
  created_at: string;
  updated_at: string;
}
const customSelectStyles = {
  control: (styles: object) => ({
    ...styles, border: "none", boxShadow: 'none',
    '&:hover': { borderColor: 'none', boxShadow: 'none' },
  }),
  placeholder: (styles: object) => ({ ...styles, display: 'none' }),
  indicatorSeparator: (styles: object) => ({ ...styles, backgroundColor: "none" }),
};
interface StyledRadioProps extends RadioProps {
  classes: {
    root: string;
    checked: string;
  };
};
const stylesRadio = (theme: Theme) => ({
  root: {
    color: '#1C18AF',
    '&$checked': {
      color: '#1C18AF',
    },
  },
  checked: {},
});
const StyledRadio = withStyles(stylesRadio)((props: StyledRadioProps) => (
  <Radio color="default" {...props} />
));
const StyledTextFieldDiscount = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: CONSTANTS.color.black,
      fontFamily: "Rubik",
    },
    "& .css-1uccc91-singleValue": {
      color: CONSTANTS.color.black,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",

    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",

    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white"
    },
    "& .css-6q0nyr-Svg": {
      height: "25px",
      width: "25px"
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"

    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px"

    },
    '& .MuiOutlinedInput-input': {
      padding: '0px',
      paddingLeft: '8px',
      paddingRight: "8px"
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
      paddingLeft: "20px"
    }
  }
})(TextField);
export const StyledTextFields = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      color: CONSTANTS.color.lightBlack,
    },
    "& .MuiFormHelperText-root": {
      textAlign: "end"
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px"
    },
    "& .MuiTypography-colorTextSecondary": {
      fontWeight: "700",
      color: CONSTANTS.color.lightBlack,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: CONSTANTS.color.lightBlack,
    }
  }
})(TextField);

export const StyledInputBase = withStyles({
  root: {
    "& .MuiInputBase-input": {
      cursor: "pointer",
      color: CONSTANTS.color.lightBlack,
    },
    "& .MuiTypography-colorTextSecondary": {
      color: CONSTANTS.color.lightBlack,
    }
  }
})(InputBase);

export const StyledSubtotalDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: "20.5% !important",
  },
}));

export const StyledTaxDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    top:"auto !important",
    left: "auto !important",
  },
}));

export const StyledDiscountDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: (props) =>  props.languageSelected !== "ar" ? "41.5% !important" : "81.5% !important",
  },
  [theme.breakpoints.down(700)]: {
    top: "64% !important",
    left: "50.5% !important",
  },
}));

export const StyledDepositDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: (props) => props.languageSelected !== "ar" ? "68.5% !important" : "33.5% !important",
  },
  [theme.breakpoints.down(700)]: {
    left: "50.5% !important",
  },
}));

const getConditionalStyle = (isError: any) => {
  return isError && webStyle.errorBackgroundStyle;
};
const getConditionalStyleModal = (isError: any) => {
  return isError && webStyle.errorStyle;
};

const renderErrorMessage = (errorText: string) => {
  return (
    <Typography variant="body2" style={webStyle.errorText}>
      {errorText}
    </Typography>
  );
};

const purchaseFormContainer = (languageSelected: string, classes: ClassNameMap) => {
  return languageSelected === "ar" ? classes?.purchaseFormContainerAr : classes?.purchaseFormContainer
}

const rateContainer = (languageSelected: string, classes: ClassNameMap) => {
  return languageSelected === "ar" ? classes?.rateLabelContainerAr : classes?.rateLabelContainer
}

const transactionDateContainer = (languageSelected: string) => {
  return languageSelected === "ar" ? { ...webStyle.depositContainer, ...webStyle.transactionDateStyle, gap: "2.5rem" } : { ...webStyle.depositContainer, ...webStyle.transactionDateStyle }
}

const requiredDepositModalText = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.modalTextsContent, ...webStyle.modalDepositBackDrop, right: "45%"} : {...webStyle.modalTextsContent, ...webStyle.modalDepositBackDrop}
}

interface MockDeposit {
  id: number;
  value: string;
}
// Customizable Area End

export class CreatePurchase extends PurchasesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t } = this.props;
    const currency = [
      { value: "%", label: '%' },
      { value: this.props.currency, label: this.props.currency },
    ];
    const depositMethodMock = [
      { id: 1, value: t('suppliers.cash') },
      { id: 2, value: t('suppliers.cheque') },
      { id: 3, value: t('suppliers.creditCard') },
      { id: 4, value: t('suppliers.bankTransfer') },
      { id: 5, value: t('suppliers.moneyOrder') },
      { id: 6, value: t('suppliers.other') },
    ]
    const { languageSelected } = this.state;
    const { purchaseDate, price, quantity, itemDescription } = this.state.formData;
    const { method, amount, transactionDate, details } = this.state.depositFormData;
    const { classes }: any = this.props;
    return (
      <>
        <Box className={classes?.purchasesContainer}>
          <Typography style={webStyle.headingText} variant="subtitle1" className={classes?.headingText}>{t('suppliers.purchaseOrder')}</Typography>
          <Box className={purchaseFormContainer(languageSelected, classes)}>
            <Grid item lg={9} md={12} xs={12}>
              <StyleGrid container spacing={10}>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className={classes?.formDataText} color="textPrimary" variant="body1" gutterBottom>
                    {t('suppliers.item')}
                  </Typography>
                  <StyledTextFields
                    style={{ backgroundColor: CONSTANTS.color.offWhiteGrey }}
                    variant="outlined"
                    value={""}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className={classes?.formDataText} variant="body1" color="textPrimary" gutterBottom>
                    {t('suppliers.purchaseDate')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                  </Typography>
                  <CustomDatePicker
                    data-test-id="handleDateChange"
                    selectedDate={purchaseDate}
                    dateChange={this.handleDateChange}
                  />
                  {renderErrorMessage(this.state.isErrorFormData.purchaseDate)}
                </Grid>
              </StyleGrid>
            </Grid>
            <Box className={classes?.gridItems}>
              <Grid item lg={9} md={12} xs={12}>
                <StyleGrid container spacing={10}>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes?.formDataText} color="textPrimary" variant="body1" gutterBottom>
                      {t('suppliers.price')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      data-testid="price"
                      style={getConditionalStyle(this.state.isErrorFormData.price)}
                      fullWidth
                      value={price}
                      variant="outlined"
                      name="price"
                      onChange={this.handleChangeFormData}
                      error={Boolean(this.state.isErrorFormData.price)}
                    />
                    {renderErrorMessage(
                      this.state.isErrorFormData.price
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography
                      className={classes?.formDataText}
                      color="textPrimary" variant="body1"
                      gutterBottom
                    >
                      {t('suppliers.quantity')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(
                        this.state.isErrorFormData.quantity
                      )}
                      inputProps={{ 'data-testid': 'quantity' }}
                      variant="outlined"
                      fullWidth
                      value={quantity}
                      onChange={this.handleChangeFormData}
                      name="quantity"
                      error={Boolean(this.state.isErrorFormData.quantity)}
                    />
                    {renderErrorMessage(
                      this.state.isErrorFormData.quantity
                    )}
                  </Grid>
                </StyleGrid>
              </Grid>
            </Box>
            <Box
              className={classes?.gridItems}>
              <Grid item lg={9} md={12} xs={12}>
                <StyleGrid
                  container
                  spacing={10}
                  >
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography
                      className={classes?.formDataText}
                      variant="body1"
                      color="textPrimary"
                      gutterBottom
                    >
                      {t('suppliers.itemDescription')}
                    </Typography>
                    <StyledTextFields
                      data-test-id="formData"
                      style={getConditionalStyle(this.state.isErrorFormData.itemDescription)}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                      helperText={
                        <div className={classes?.helperText}>
                          {renderErrorMessage(this.state.isErrorFormData.itemDescription)}
                          <span style={itemDescription?.length > 500 ? {color: CONSTANTS.color.red } : {}}>
                            {`${itemDescription.length}/500`}
                          </span>
                        </div>
                      }
                      name="itemDescription"
                      value={itemDescription}
                      onChange={this.handleChangeFormData}
                      error={Boolean(this.state.isErrorFormData.itemDescription || itemDescription.length > 500)}
                    />
                  </Grid>
                </StyleGrid>
              </Grid>
            </Box>
          </Box>

          <Box className={rateContainer(languageSelected, classes)}>
            <Typography style={{...webStyle.headingText, padding: "1rem 0rem 0rem"}} className={classes?.rateLabelMobile} variant="subtitle1">
              {t('suppliers.rate')}
            </Typography>
            <PriceSectionWeb
                data={this.state.formData}
                priceSectionData={this.state.priceSectionData}
                handlePriceSection={this.handlePriceSection}
                emptyPriceSectionData={this.emptyPriceSectionData}
                selectTaxRate={this.selectTaxRate}
                isEditable={true}
                calculateTotal={this.calculateTotal}
                currency={this.state.currency}
                getSubTotal={this.getSubTotal}
                invoicePayment={this.getSubTotal}
                selectCurrency={this.selectCurrency}
                selectedCurrency={this.state.selectCurrency}
                handleCollectionPaymentSave={this.handleCollectionPaymentSave}
                handleChange={this.handleChange}
                onClose={this.handlePaymentModalClose}
                discountDialog={this.state.discountDialog}
                handleDiscountDialogOpen={this.handleDiscountDialogOpen}
                collectedPaymentForm={this.state.collectedPaymentForm}
                handleChangePaymentMethodRef={this.handleChangePaymentMethodRef}
                handleCollectionPaymentModal={this.handleCollectionPaymentModal}
                handleCollectionPaymentClose={this.handleCollectionPaymentClose}
                handleTransactionDate={this.handleTransactionDate}
                handlePay_balence={this.handlePayBalance}
                handleCollected={this.handleCollected}
                  />
          </Box>
        </Box>
        
        <Box style={webStyle.buttons} className={`${classes?.buttons} ${classes?.dialogButtonsContainer}`}>
          <Box className={classes?.buttonsInnerContainer}>
            <Button
              className={`${classes?.buttonStyleMobile}  ${classes.btnBoxCancel} ${classes?.buttonStyleCommon}`}
              onClick={this.handleCancelPurchase}
            >
              <Typography
                style={webStyle.cancelText}
                className={classes?.buttonTextMobile}
                variant="subtitle2"
              >
                {t('suppliers.cancel')}
              </Typography>
            </Button>
          </Box>
          <Button
            className={`${classes?.buttonMobile} ${classes.buttonSaveSuppliers} ${classes?.buttonStyleCommon}`}
            onClick={this.handleSavePurchase}
            data-testid='saveBtn'
          >
            <Typography
              style={webStyle.buttonSaveText}
              className={classes?.buttonTextMobile}
              variant="subtitle2"
            >
              {t('suppliers.save')}
            </Typography>
          </Button>
        </Box>
        <LoadingBackdrop
          loading={this.state.createPurchasesLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({

});

const webStyle = {
  deleteIcon:{
    width: "30px",
    height: "30px",
    marginBottom:"3px",
    cursor:"pointer"
  }, 
  headingText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    padding: "2.5rem 4rem 0rem"
  },
  buttons: {
    padding: "3rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancelText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  }, 
  buttonSaveText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  errorText: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },  
  modalTextsContent: {
    color: CONSTANTS.color.blueViolet,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    cursor: "pointer",
  }, 
   
  modalDepositBackDrop: {
    position: "absolute" as const,
    top: "-3%",
    right: "-21%",
    transform: "translate(-50%, -50%)"
  },
  depositContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative" as const,
  },
  transactionDateStyle: {
    padding: "1px 0px 6px",
    gap: "1rem"
  },
  dropDownIcon: {
    width: "12px",
    height: "7px"
  }, 
  dialogButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  }, 
  redColor: {
    color: CONSTANTS.color.red,
  },
  errorBackgroundStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "10px"
  },
  errorStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    border: `1px solid ${CONSTANTS.color.red}`,
    borderRadius: "5px"
  }
}

export default withTranslation()(withStyles(styles)(CreatePurchase));
// Customizable Area End