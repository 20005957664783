import React from "react";
// Customizable Area Start
import { Grid, Typography,Theme,withStyles } from "@material-ui/core";
import "./CreationProcessStyle.css"
import { CONSTANTS } from "../../../components/src/theme";
import {
  requestsIcon,
  jobsIcon,
  quotesIcon,
  invoicesIcon
} from "./assets";
// Customizable Area Start
interface Props {
  active: number;
  t?: (key: string) => string
  selectLanguage:string
}
// Customizable Area End

export class CreationProcess extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  layoutStyle = (active:boolean) => {
   return  this.props.selectLanguage === 'en' ?
      `layout ${active && "stepActive"}` :
      `layoutAr ${active && "stepActiveAr"}`
  }
  layoutStyle1 = (active:boolean) => {
    return this.props.selectLanguage === 'en' ?
       `layout ${"previousStepActive"}` :
       `layoutAr ${"previousStepActiveAr"}`
   }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }:any = this.props
    const textLayout = this.props.selectLanguage === 'en' ? 'textlayout' : "textlayoutAr"
    const convertType = localStorage.getItem("convertType"); 
    return (
      <>
        <Grid item xs={12}>
          <Grid container id="cont">
            <Grid
              className={convertType === 'RequestTo' ? 
              `${this.layoutStyle(this.props.active === 0)} ${this.layoutStyle1(this.props.active === 0)}`:
                this.layoutStyle(this.props.active === 0)
              }
              item
              xs={3}
              sm={3}
            >
              <div className={textLayout}>
                <img style={this.props.active === 0 || convertType === 'RequestTo' ? webStyle.imagetextWhite : webStyle.imagetextgray } src={requestsIcon} />
                <Typography
                className={`${classes.text} ${classes.fontSize}`}
                  style={
                    this.props.active === 0 || convertType === 'RequestTo' 
                      ? webStyle.textWhite
                      : webStyle.textgrey
                  }
                >
                  {this.props.t ? this.props.t('creationProcess.request') :"Request"}
                </Typography>
              </div>
            </Grid>
            <Grid
             className={convertType === 'QuoteTo' ? 
             `${this.layoutStyle(this.props.active === 1)} ${this.layoutStyle1(this.props.active === 1)}`:
               this.layoutStyle(this.props.active === 1)
             }
              item
              xs={3}
              sm={3}
            >
              <div className={textLayout}>
                <img style={ this.props.active === 1 || convertType === 'QuoteTo' ? webStyle.imagetextWhite : webStyle.imagetextgray } src={quotesIcon} />
                <Typography
                className={`${classes.text} ${classes.fontSize}`}
                style={
                  this.props.active === 1 || convertType === 'QuoteTo' 
                    ? webStyle.textWhite
                    : webStyle.textgrey
                }
                >
                  {this.props.t ? this.props.t('creationProcess.quote') : 'Quote'} 
                </Typography>
              </div>
            </Grid>
            <Grid
              className={this.layoutStyle(this.props.active === 2)}
              item
              xs={3}
              sm={3}
            >
              <div className={textLayout}>
                <img style={ this.props.active === 2 ? webStyle.imagetextWhite : webStyle.imagetextgray } src={jobsIcon} />
                <Typography
                className={`${classes.text} ${classes.fontSize}`}
                  style={
                    this.props.active === 2
                      ? webStyle.textWhite
                      : webStyle.textgrey
                  }
                >
                  {this.props.t ? this.props.t('creationProcess.job') : 'Job'}
                </Typography>
              </div>
            </Grid>
            <Grid
              className={this.layoutStyle(this.props.active === 3)}
              item
              xs={3}
              sm={3}
            >
              <div className={textLayout}>
                <img style={ this.props.active === 3 ? webStyle.imagetextWhite : webStyle.imagetextgray } src={invoicesIcon} />
                <Typography
                 className={`${classes.text} ${classes.fontSize}`}
                  style={
                    this.props.active === 3
                      ? webStyle.textWhite
                      : webStyle.textgrey
                  }
                >
                  {this.props.t ? this.props.t('creationProcess.invoice') : 'Invoice'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
    textgrey: {
        fontWeight: CONSTANTS.fontWeight.fontWeight300,
        fontStyle: "normal",
        color: CONSTANTS.color.lightGrey,
    },
    textWhite: {
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        fontStyle: "normal",
        color: CONSTANTS.color.white,

    },
    imagetextWhite: {
      height: "25px",
      widht: "25px",
      marginBottom: "6px",
      filter: "brightness(0) invert(1)"
    },
    imagetextgray: {
      height: "25px",
      widht: "25px",
      marginBottom: "6px",
    }
}
export const styles = (theme: Theme) => ({
  fontSize:{
    fontSize:"24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px"
    }
  },
  text :{
    zIndex: 99
  }
});
export default withStyles(styles)(CreationProcess);
// Customizable Area End