import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Container
} from "@material-ui/core";
import {
  instagramIcon,
  linkedinIcon,
  xLogo,
  lamsaLogoWhite
} from "./assets";
import "./Footer.css";
import { CONSTANTS } from "../../../components/src/theme";
// Customizable Area End

import NavBarFooterController, {
  Props,
} from "./NavBarFooterController.web";

// Customizable Area Start
// Customizable Area End

export default class Footer extends NavBarFooterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { t } = this.props;
    return (
      <Box
        style={webStyle.container}
        className="container"
      >
        <Container maxWidth={"lg"}>
          <Grid container spacing={2} style={webStyle.gridItem1} className="gridItem1">
            <Grid item lg={6} md={6} xs={9} style={webStyle.gridItemTerms} className="grid-item terms">
              <Typography style={webStyle.termsContainer} className="termsContainer">
                <Typography
                  data-test-id="handleNavigateTerms"
                  style={webStyle.termsConditions}
                  className="typography"
                  variant="body2"
                  onClick={this.handleNavigateTerms}
                >
                  {t("landingPage.Footer.termsAndConditions")}
                </Typography>
                <Typography
                  data-test-id="handleNavigatePrivacy"
                  style={webStyle.privacyPolicy}
                  className="typography"
                  variant="body2"
                  onClick={this.handleNavigatePrivacy}
                >
                  {t("landingPage.Footer.privacyPolicy")}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={3} style={webStyle.gridItemIcons} className="grid-item">
              <Typography style={webStyle.socialMediaIcons} component={"div"}>
                <Typography component={"a"} href="https://www.instagram.com/getlamsa/" target="_blank">
                  <img style={webStyle.instagramIcon} className="instagramIcon" src={instagramIcon} alt="Icon" />
                </Typography>
                <Typography component={"a"} href="https://www.linkedin.com/company/lamsa-io/" target="_blank">
                  <img style={webStyle.linkedinIcon} className="linkedinIcon" src={linkedinIcon} alt="Icon" />
                </Typography>
                <Typography component={"a"} href="https://twitter.com/getlamsa" target="_blank">
                  <img style={webStyle.twitterIcon} className="twitterIcon" src={xLogo} alt="Icon" />
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Typography style={webStyle.hrStyle} className="hr" component={"div"}>
            <Typography style={webStyle.hr} component={"div"}></Typography>
          </Typography>
          <Grid container spacing={2} style={webStyle.gridItem2}>
            <Grid item lg={6} md={6} xs={9} style={webStyle.gridItemTerms} className="grid-item">
              <Typography
                style={webStyle.allRightsText}
                className="typography"
                variant="body2"
              >
                © {t("landingPage.Footer.allRightsReserved")}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={3} style={webStyle.gridItemIcons} className="lamsaLogoContainer">
              <img style={webStyle.lamsaLogoWhite} className="lamsaLogoWhite" src={lamsaLogoWhite} alt="Logo" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    background: CONSTANTS.color.royalBlue,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 2rem",
    zIndex: 99
  },
  gridItem1: {
    marginTop: "1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center" as "center",
  },
  gridItem2: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center" as "center",
  },
  termsContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
  },
  termsConditions: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer"
  },
  privacyPolicy: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer",
  },
  hrStyle: {
    padding: "1.6rem 0"
  },
  hr: {
    height: "1px",
    border: `1px solid ${CONSTANTS.color.white}`,
    backgroundColor: CONSTANTS.color.white
  },
  gridItemTerms: {
    display: "flex",
    justifyContent: "flex-start"
  },
  gridItemIcons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  socialMediaIcons: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "end"
  },
  instagramIcon: {
    height: "20px",
    width: "20px",
    cursor: "pointer"
  },
  linkedinIcon: {
    height: "20px",
    width: "20px",
    margin: "0 2rem",
    cursor: "pointer"
  },
  twitterIcon: {
    height: "16px",
    width: "16px",
    cursor: "pointer"
  },
  allRightsText: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  lamsaLogoWhite: {
    width: "125px",
    height: "40px",
  }
}

// Customizable Area End