import React from 'react';
import { Dialog, DialogTitle, Typography, Divider, Grid, Button, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StyledTextFieldRequest } from '../../blocks/RequestManagement/src/Utility.web';
import { CustomerText } from './CustomizableComponents.web';
import { arrowRight } from '../../blocks/RequestManagement/src/assets';
import {SiteProp} from "../../blocks/CustomisableUserProfiles/src/CustomerRequestsController.web"

enum CSSClass {
    closeButton = "closeButton",
    display = "display",
    createBtn = "createBtn",
    searchLayout = "searchLayout",
    searchLayoutChild = "searchLayoutChild",
    activeText = "activeText",
    activeDivider = "activeDivider",
    paper = "paper",
    siteText = "siteText",
    siteNameLayout = "siteNameLayout",
    customerName = "customerName",
    titleText = "titleText",
}
export interface ComponentClasses {
    [key: string]: CSSClass;
  }
interface SiteModalProp {
    siteModal:boolean,
    handleSiteCloseModal: () => void;
    classes:ComponentClasses,
    t: (key: string) => string;
    handleChangeSearchCustomerSite:Function,
    siteInformationData:SiteProp[],
    siteDetails:SiteProp ,
    selectedSiteCustomer:Function,
    createSite: () => void;
}

const SiteModal = (props: SiteModalProp) => {
    return (
        <Dialog
            maxWidth="lg"
            open={props.siteModal}
            onClose={props.handleSiteCloseModal}
            PaperProps={{ classes: { root: props.classes.paper } }}
        >
            <DialogTitle classes={{ root: props.classes.dialogTitle }}>
                <Typography className={props.classes.titleText}>{props.t ? props.t('siteModal.title') : "Select Or Create Site"}</Typography>
                <IconButton aria-label="close" data-test-id="search_sites" className={props.classes.closeButton}
                    onClick={props.handleSiteCloseModal}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Grid item xs={12} className={props.classes.searchLayout}>
                <Grid container direction='row' justifyContent='center' alignItems='center'
                    className={props.classes.searchLayoutChild}>
                    <Grid item xs={12} md={5}>
                        <StyledTextFieldRequest
                            fullWidth
                            variant='outlined'
                            placeholder={`${props.t ? props.t('siteModal.searchSite') : "Search Sites..."}`}
                            InputProps={{ classes: { input: props.classes['input'] } }}
                            onChange={(event) => props.handleChangeSearchCustomerSite("sites",event)}
                            data-test-id="search_sites"
                        />

                    </Grid>
                    <Grid item xs={12} md={2} container justifyContent='center'>
                        <Typography className={props.classes.orText}>{props.t ? props.t('customerModal.or') : "or"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Button className={props.classes.createBtn} 
                        fullWidth
                        onClick={props.createSite}
                        >
                            {props.t ? props.t('siteModal.createNewSite') : "CREATE NEW SITE"}
                        </Button>
                    </Grid>

                </Grid>

            </Grid>
            <Grid item xs={12} >
                <Grid container >
                    <Grid item xs={12} style={{
                        paddingLeft: "0.7rem",
                        paddingRight: "0.7rem"
                    }}>
                        <Typography className={props.classes.activeText}>{props.t ? props.t('siteModal.current') : "Current"}</Typography>
                        <Divider className={props.classes.activeDivider} />
                    </Grid>

                    <Grid item xs={12}>
                        {props.siteInformationData && props.siteInformationData.length > 0 ? props.siteInformationData.map((_list: any, index: number) => {
                            return (
                                <>
                                    <CustomerText key={_list.id}
                                        selected={props.siteDetails?.site_name === _list.site_name}>
                                        <ListItemText
                                            className={props.classes.display}
                                            data-test-id="selectSite"
                                            onClick={() => { props.selectedSiteCustomer(_list) }}
                                            primary={_list.site_name} />
                                        <ListItemIcon>
                                            <img src={arrowRight} width={14} height={17} />
                                        </ListItemIcon>
                                    </CustomerText>
                                </>
                            )
                        }) :
                            <>
                                <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center' style={{ marginTop: "10%", marginBottom: "10%" }}>
                                    <Typography className={props.classes.customerName}>{props.t ? props.t('siteModal.noSiteText') : "No customer sites Found"}</Typography>
                                </Grid>
                            </>}
                    </Grid>

                </Grid>

            </Grid>

        </Dialog>
    )

}


export default SiteModal;

