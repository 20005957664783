import React from "react";
import {
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { CONSTANTS } from "./theme"
import {
    Theme,
    withStyles
  } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

type Props = {
    t: (key: string) => string;
    planModal: boolean;
    handleClosePlanModal: () => void;
    planType: string;
    handleClickProceedPlan: () => void;
    classes: ClassNameMap;
    languageSelected?: string;
}

const StyledDialog = withStyles({
    root: {
      "& .MuiPaper-root": {
        padding: "1rem",
        width: "100%",
        maxWidth: "752px",
        minHeight: "251px",
        borderRadius: "8px",
        backgroundColor: CONSTANTS.color.white,
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
      }
    }
})(Dialog);

const StyledDialogTitle = withStyles((theme: Theme) => ({
    root: {
       padding:"16px",
      "& .MuiTypography-h6": {
        color: CONSTANTS.color.lightBrown,
        fontSize: "1.8741rem",
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.6867rem !important"
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "22px !important",
         
        },
        
      }
    }
  }))(DialogTitle);

const StyledDialogContentText = withStyles((theme) => ({
    root: {
      color: CONSTANTS.color.lightBrown,
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      fontSize: "1.52rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.4286rem !important"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.25rem !important",
        marginBottom: "0 !important"
      },
    }
}))(DialogContentText);

const StyledDialogContent = withStyles((theme) => ({
    root: {
        [theme.breakpoints.down("xs")]: {
            padding: "8px 14px !important"
        },
    }
}))(DialogContent);

const StyleDialog = (props: Props) => {
    const { t, classes } = props;
    return (
        <StyledDialog
            open={props.planModal}
            onClose={props.handleClosePlanModal}
            aria-labelledby="responsive-dialog-title"
        >
            <CloseIcon
                style={webStyle.closeIcon}
                className={props?.languageSelected === "ar" ? classes.closeIconAr : classes.closeIcon}
                onClick={props.handleClosePlanModal}
            />
            <StyledDialogTitle id="responsive-dialog-title">
                {`${t("landingPage.StyleDialog.youHaveChosen")} ${props.planType} ${t("landingPage.StyleDialog.plan")}`}
            </StyledDialogTitle>
            <StyledDialogContent>
                <StyledDialogContentText>
                    {t("landingPage.StyleDialog.areYouSure")}
                </StyledDialogContentText>
            </StyledDialogContent>
            <DialogActions style={webStyle.dialogActionsContainer}>
                <Button
                    style={{ ...webStyle.buttonContainerCancel, maxWidth: "162px" }}
                    className={classes.buttonContainerMobile}
                    onClick={props.handleClosePlanModal}
                >
                    <Typography
                        style={webStyle.buttonTextCancel}
                        className={classes.buttonTextMobile}
                        variant="subtitle2"
                    >
                        {t("landingPage.StyleDialog.cancel")}
                    </Typography>
                </Button>
                <Button
                    style={{ ...webStyle.buttonContainerCancel, border: `1px solid ${CONSTANTS.color.darkBlue}`, maxWidth: "162px" }}
                    onClick={props.handleClickProceedPlan}
                    className={classes.buttonContainerMobile}
                >
                    <Typography
                        style={{ ...webStyle.buttonTextCancel, color: CONSTANTS.color.darkBlue }}
                        className={classes.buttonTextMobile}
                        variant="subtitle2"
                    >
                        {t("landingPage.StyleDialog.proceed")}
                    </Typography>
                </Button>
            </DialogActions>
        </StyledDialog>
    )

}

export const styles = (theme: Theme) => ({
    buttonContainerMobile: {
        [theme.breakpoints.down("xs")]: {
            minHeight: "48px !important",
            maxWidth: "100% !important",
        },
    },
    buttonTextMobile: {
        [theme.breakpoints.down("xs")]: {
            fontSize: "21px !important"
        },
    },
    closeIcon: {
        [theme.breakpoints.down("xs")]: {
            left: "92% !important"
        },
    },
    closeIconAr: {
        [theme.breakpoints.down("xs")]: {
            left: "0% !important",
            right: "82% !important"
        },
    },
});

const webStyle = {
    buttonContainerCancel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        maxWidth: "200px",
        width: "100%",
        minHeight: "67px",
        borderRadius: "10px",
        border: `1px solid ${CONSTANTS.color.lightGrey}`,
        textTransform: "capitalize" as const,
    },
    buttonTextCancel: {
        color: CONSTANTS.color.lightGrey,
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        textAlign: "center" as const,
    },
    closeIcon: {
        position: "absolute" as const,
        left: "95%",
        top: "12%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer"
    },
    dialogActionsContainer: {
        gap: "1rem"
    }
}
export default withTranslation()(withStyles(styles)(StyleDialog));