// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectReporting";
exports.labelBodyText = "ProjectReporting Body";
exports.nameofproject= "Name of the Project";
exports.projecttype = "Project Type";
exports.manager = "Manager ID";
exports.co_manager = "Co-Manager ID";
exports.assessor ="Assessor";
exports.client = "Client ID"
exports.selectstartdate = "Select Start Date"
exports.selectecenddate = "Select End Date";
exports.uploaddoc ="Upload Documents"
exports.survey = "Survey Type";
exports.projectReporting = "Project Reporting"
exports.enterProjectReporting = "Enter Project Name"
exports.enterProjectType = "Enter Project Type"
exports.projectReporting = "Project Reporting"
exports.managerID = "Manager Id"
exports.coManagerId = "Co Manager Id"
exports.clientID = "Client ID"
exports.buttonSave = "Save"
exports.getProjectReportingCall = "bx_block_projectreporting/projects";
exports.createProjectCall="bx_block_profile/projects";
exports.downloadProjectReportingCsvFile = "bx_block_projectreporting/projects.csv"
exports.getCoManagersDataAPICall="account_block/co_managers";
exports.getManagersDataAPICall="account_block/managers";
exports.getClientDataAPICall="account_block/clients";
exports.getcsvfileAPICall = "bx_block_projectreporting/projects.csv"
exports.btnExampleTitle = "CLICK ME";
exports.getDownloadPdfEndPoint = "bx_block_projectreporting/download_request_reports";
exports.getDownloadpdfJobreportEndPoint = "bx_block_projectreporting/download_job_reports";

exports.getAllEmployesAPiEndPoint = "bx_block_request_management/task_allocates/get_employees";
exports.getAPiMethod = "GET";

exports.authToken = "authToken";
exports.tokenExpired = "Token has Expired";
exports.invalidToken = "Invalid token";
exports.errorLabel = "error";

exports.reportInfoLabel = "Report info";
exports.RequestReport = "RequestReport";
exports.JobReport = "JobReport";
exports.JobFinancialReport = "JobFinancialReport";
exports.TimesheetReport = "TimesheetReport";
exports.requestLabel = "Requests Report";
exports.requestDescLabel = "You will get updated about new jobs including job address, description, contact info, and staff allocation";
exports.todayLabel = "Today";
exports.reportFromLabel = "Report from";
exports.reportToLabel = "Report to";
exports.customerLabel = "Customer";
exports.employeeLabel = "Employee";
exports.downloadLabel = "Download";
exports.downloadFormatLabel = "Download Format";
exports.pdfFileLabel = "PDF File";
exports.csvFileLabel = "CSV File";
exports.jobLabel = "Job Report";
exports.jobDescLabel = "You will get updated about new jobs including job address, description, contact info, and staff allocation";
exports.jobFinancialLabel = "Jobs Financial Report";
exports.jobFinancialDescLabel = "You will get updated about new jobs including job address, description, contact info, and staff allocation";
exports.timesheetLabel = "Timesheet";
exports.timesheetDescLabel = "You will get updated about new jobs including job address, description, contact info, and staff allocation";
exports.reportInfoLabel = "Report Info";
exports.addStaffMemberLabel = "Add Individual staff Member";
exports.allStaffMemberSelectedLabel = "Currently all staff members are selected.";

exports.projectReportingerror= " Please Enter Project Name"
exports.projectTypeerror = "Please Enter Project Type";
exports.managerNameerror ="Please Enter Manager Name"
exports.coManagerNameerror = "Please Enter Co-Manager";
exports.clientNamerror = "Please Enter Client Name";
exports.startdateerror = "Please Enter Start Date";
exports.enddateerror = "Please Enter End Date"
exports.errorsName = "Name already exists"
exports.errorsClientId = "has already been taken."
exports.noRecodFound = "No records found"
exports.downloadcsvfile = "Download to CSV file"
exports.downloadexcelfile = "Download to Excel file"
exports.dateFormate = "DD-MM-YYYY"
exports.dateMode = "date"
exports.maxDate = "01-01-2080"
exports.textConfirm = "Confirm"
exports.textCancle = "Cancel"
exports.mineTextCsv = "text/csv"
exports.titlecsv = "Downloading CSV"
exports.description = "Please wait..."
exports.getCsvAPICall = "bx_block_projectreporting/projects.csv"
exports.mineTextxlsx = "text/xlsx"
exports.titleXlsx = "Downloading Xlsx"
exports.getXlsxAPICall = "bx_block_projectreporting/projects.xlsx"
exports.getCustomerSerachAPiEndPoint = "bx_block_request_management/customers_list"



// Customizable Area End
