import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import {
  styled,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { ReportsSideBar } from "./ReportsSideBar.web";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { ReportsData } from "./ReportsData.web";
import { withTranslation } from "react-i18next";
import { StyledBox } from "../../../blocks/Settings/src/CompanyInformation.web";
// Customizable Area End

import ReportsController, {
  Props,
} from "./ReportsController.web";

// Customizable Area Start
// Customizable Area End

export class RequestReport extends ReportsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.report')} active={false} active1={false} active2={0} />
        <ReportsSideBar {...this.props} active={0} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.requestReportsContainer}
            className={classes.requestReportsContainer}
          >
            <Container maxWidth={false}>
              <Typography style={webStyle.requestHeaderText} className={classes.requestHeaderText} variant="subtitle1">
                {t('reports.requestsReport')}
              </Typography>
              <Typography style={webStyle.requestDescText} className={classes.requestDescText} color="textPrimary">
                {t('reports.reportDesc')}
              </Typography>
              <ReportsData {...this.props} />
            </Container>
          </Box>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  requestReportsContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem 6rem !important",
      marginTop: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.25rem 3rem !important"
    }
  },
  mobileDownloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
    }
  },
  requestHeaderText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    }
  },
  requestDescText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "25px !important"
    }
  },
  boxInnerContainer: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "0.5rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "2rem !important",
    },
  },
  downloadText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    },
  }
});

const webStyle = {
  requestReportsContainer: {
    marginTop: "8.5rem",
    paddingBottom: "6rem",
  },
  requestHeaderText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    color: CONSTANTS.color.lightBrown,
  },
  requestDescText: {
    lineHeight: "20px",
    fontSize: "14px",
    paddingTop: "1rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  }
}

const requestReportStyle = withStyles(styles)(RequestReport);
export default withTranslation()(withSnackbar(requestReportStyle));
// Customizable Area End