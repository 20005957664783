import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  Container,
  TextField,
  MenuItem,
  Checkbox,
  Hidden,
  InputAdornment,
  ClickAwayListener
} from "@material-ui/core";
import {
  withStyles,
  Theme,
  WithStyles
} from "@material-ui/core/styles";
import {
  contactUsImage,
  contactUsMail,
  contactUsMap,
  contactUsPhone,
  contactUsDropDown
} from "./assets";
import "./Style.css";
import NavBar from "./NavBar.web";
import Footer from "./Footer.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { countryCodes } from "../../dashboard/src/utility.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
// Customizable Area End

import ContactUsController, {
  Props,
} from "./ContactUsController.web";

// Customizable Area Start
interface QuestionList {
  id: number;
  name: string;
  value: string;
}

interface ContactUsDataType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    heading: string;
    address: string;
    email: string;
    phone_number: string;
    image: string;
    address_icon: string;
    phone_icon: string;
    email_icon: string;
  }
}

const StyledTextFields = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      color: CONSTANTS.color.midGrey
    },
  }
})(TextField);

export const StyledMenuItem = withStyles({
  root: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: CONSTANTS.color.lightGreen
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  }
})(MenuItem);

export const StyledTextField = withStyles({
  root: {
    padding: "0 0.2rem",
    "& .MuiInputBase-root": {
      height: "55px",
      borderRadius: "10px",
      fontSize: "1.1667rem",
      color: CONSTANTS.color.midGrey
    },
    "& .MuiOutlinedInput-input":{
      cursor: "pointer",
      padding:"15px 14px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor: CONSTANTS.color.thickBlue,
      borderWidth: "2px"
    },
    "& .MuiSvgIcon-root":{
      fontSize: "2.1rem",
      marginTop: "-5px",
    }
  }
})(TextField);

interface StyledAutoCompleteProps extends WithStyles<typeof styles> {
  languageSelected: string;
}

const StyledAutoComplete = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      paddingRight:(props: StyledAutoCompleteProps) => props.languageSelected === "ar" && "45px !important",
      height: "57px",
      borderRadius: "10px",
      fontSize: "1.1667rem",
      color: CONSTANTS.color.midGrey,
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.0625rem !important"
      }
    },
    "& .MuiOutlinedInput-input":{
      cursor: "pointer",
      padding:"15px 12px"
    },
    "& .MuiSvgIcon-root":{
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor: CONSTANTS.color.thickBlue,
      borderWidth: "2px"
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none"
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "90px"
    }
  }
}))(TextField);

const getConditionalStyle = (isError: any) => {
  return isError && webStyle.errorStyle;
};

const addressStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.contactAddress, gap: "1rem"} : webStyle.contactAddress
}

const renderContent = (classes: ClassNameMap, getContactUsData: ContactUsDataType[], languageSelected: string) => {
  return (
    <Box className={classes.titleContainer}>
      <Box>
        <Typography
          style={webStyle.letsTalkText}
          className={classes.letsTalkText}
          variant="h2"
        >
          {getContactUsData?.[0]?.attributes?.title}
        </Typography>
        <Typography
          style={webStyle.body}
          className={classes.body}
          variant="body1"
        >
          {getContactUsData?.[0]?.attributes?.description}
        </Typography>
      </Box>
      <Box className={classes.rightContent}>
        <img
          style={webStyle.contactUsImage}
          className={classes.contactUsImage}
          src={getContactUsData?.[0]?.attributes?.image}
          alt="Image"
        />
      </Box>
      <Hidden smDown>
        {contactUsAddress(classes, getContactUsData, languageSelected)}
      </Hidden>
    </Box>
  );
}

const contactUsAddress = (classes: ClassNameMap, getContactUsData: ContactUsDataType[], languageSelected: string) => {
  return (
    <Box>
      <Typography
        style={webStyle.contactUsText}
        className={classes.contactUsText}
        variant="h3"
      >
        {getContactUsData?.[0]?.attributes?.heading}
      </Typography>
      <Box style={addressStyle(languageSelected)}>
        <img style={webStyle.contactIcons} src={getContactUsData?.[0]?.attributes?.address_icon} alt="" />
        <Typography
          style={webStyle.addressText}
          className={classes.addressText}
          variant="body1"
        >
          {getContactUsData?.[0]?.attributes?.address}
        </Typography>
      </Box>
      <Box style={addressStyle(languageSelected)}>
        <img style={webStyle.contactIcons} src={getContactUsData?.[0]?.attributes?.email_icon} alt="" />
        <Typography
          style={webStyle.addressText}
          className={classes.addressText}
          variant="body1"
        >
          {getContactUsData?.[0]?.attributes?.email}
        </Typography>
      </Box>
      <Box style={addressStyle(languageSelected)}>
        <img style={webStyle.contactIcons} src={getContactUsData?.[0]?.attributes?.phone_icon} alt="" />
        <Typography
          style={webStyle.addressText}
          className={classes.addressText}
          variant="body1"
        >
          {getContactUsData?.[0]?.attributes?.phone_number}
        </Typography>
      </Box>
    </Box>
  );
}

const handleKeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  handleDetailsSubmit: Function,
  message: string
) => {
  if (e.key === "Enter" && !e.shiftKey) {
    e.preventDefault();
    handleDetailsSubmit(e);
    const inputElement = document.activeElement as HTMLInputElement;
    if (inputElement && message.length > 0) {
      inputElement.blur();
    }
  }
}

const countryCodeStyle = (countryCode: string | undefined) => {
  return countryCode ? webStyle.countryCodeError : {}
}

const modalStyle = (name: string, classes: ClassNameMap) => {
  return (name === 'Get help' ? classes.checkBoxContainer : '') + (name === 'Others' ? classes.checkBoxContainerOthers : '')
}
// Customizable Area End

export class ContactUs extends ContactUsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { firstName, lastName, email, phone, message } = this.state.formDetails;
    const { getContactUsData, questionList } = this.state;
    const { t } = this.props;

    const questions = [
      {id: 1, name: t("landingPage.ContactUs.bookADemo"), value: "Book a demo"},
      {id: 2, name: t("landingPage.ContactUs.getHelp"), value: "Get help"},
      {id: 3, name: t("landingPage.ContactUs.applyForAJob"), value: "Apply For a job"},
      {id: 4, name: t("landingPage.ContactUs.others"), value: "Others"}
    ]
    return (
      <>
        <NavBar {...this.props} active={3} homePage={this.getContactUsData} oldProps={this} />
        {!this.state.getContactUsDataLoading ? (
          <>
            <Box
              style={webStyle.container}
              className={classes.container}
            >
              <Container maxWidth={"lg"}>
                <form onSubmit={this.handleDetailsSubmit}>
                  <Grid container spacing={2} style={webStyle.gridContainer}>
                    <Grid item lg={6} md={6} xs={12}>
                      {renderContent(classes, getContactUsData, this.state.languageSelected)}
                    </Grid>
                    <Grid item lg={5} md={6} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12} style={webStyle.gridItems} className={classes.gridItems}>
                          <Typography
                            style={webStyle.contactForm}
                            className={classes.contactForm}
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                          >
                            {t("landingPage.ContactUs.firstName")}<span style={webStyle.redColor}>*</span>
                          </Typography>
                          <StyledTextFields
                            data-test-id="formDetails"
                            style={getConditionalStyle(this.state.isErrorFormDetails.firstName)}
                            fullWidth
                            variant="outlined"
                            placeholder={t("landingPage.ContactUs.firstNamePlaceholder")}
                            name="firstName"
                            value={firstName}
                            onChange={this.handleChangeFormDetails}
                            error={Boolean(this.state.isErrorFormDetails.firstName)}
                          />
                          <Typography
                            style={webStyle.errorMessage}
                            variant="body2"
                          >
                            {this.state.isErrorFormDetails.firstName}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} style={webStyle.gridItems} className={classes.gridItems}>
                          <Typography
                            style={webStyle.contactForm}
                            className={classes.contactForm}
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                          >
                            {t("landingPage.ContactUs.lastName")}<span style={webStyle.redColor}>*</span>
                          </Typography>
                          <StyledTextFields
                            data-test-id="formDetails"
                            style={getConditionalStyle(this.state.isErrorFormDetails.lastName)}
                            fullWidth
                            variant="outlined"
                            placeholder={t("landingPage.ContactUs.lastNamePlaceholder")}
                            name="lastName"
                            value={lastName}
                            onChange={this.handleChangeFormDetails}
                            error={Boolean(this.state.isErrorFormDetails.lastName)}
                          />
                          <Typography
                            style={webStyle.errorMessage}
                            variant="body2"
                          >
                            {this.state.isErrorFormDetails.lastName}
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} style={webStyle.gridItems} className={classes.gridItems}>
                          <Typography
                            style={webStyle.contactForm}
                            className={classes.contactForm}
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                          >
                            {t("landingPage.ContactUs.email")}<span style={webStyle.redColor}>*</span>
                          </Typography>
                          <StyledTextFields
                            data-test-id="formDetails"
                            style={getConditionalStyle(this.state.isErrorFormDetails.email)}
                            fullWidth
                            variant="outlined"
                            placeholder={t("landingPage.ContactUs.emailPlaceholder")}
                            name="email"
                            value={email}
                            onChange={this.handleChangeFormDetails}
                            error={Boolean(this.state.isErrorFormDetails.email)}
                          />
                          <Typography
                            style={webStyle.errorMessage}
                            variant="body2"
                          >
                            {this.state.isErrorFormDetails.email}
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} style={webStyle.gridItems} className={classes.gridItems}>
                          <Typography
                            style={webStyle.contactForm}
                            className={classes.contactForm}
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                          >
                            {t("landingPage.ContactUs.phoneNumber")}<span style={webStyle.redColor}>*</span>
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item md={3} xs={4}>
                              <Autocomplete
                                data-test-id="handleChangeCountryCode"
                                classes={{option: classes.customOption}}
                                options={countryCodes}
                                value={this.state.countryCode}
                                onChange={(e, value) => this.handleChangeCountryCode(e, value as string)}
                                autoHighlight
                                renderInput={(params: any) => (
                                  <StyledAutoComplete
                                    style={countryCodeStyle(this.state.isErrorFormDetails.countryCode)}
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    languageSelected={this.state.languageSelected}
                                  >
                                  </StyledAutoComplete>
                                )}
                              />
                              <Typography
                                style={webStyle.errorMessage}
                                variant="body2"
                              >
                                {this.state.isErrorFormDetails.countryCode}
                              </Typography>
                            </Grid>
                            <Grid item md={9} xs={8}>
                              <StyledTextFields
                                data-test-id="formDetails"
                                style={getConditionalStyle(this.state.isErrorFormDetails.phone)}
                                fullWidth
                                variant="outlined"
                                placeholder={t("landingPage.ContactUs.phoneNumberPlaceholder")}
                                name="phone"
                                value={phone}
                                onChange={this.handleChangeFormDetails}
                                error={Boolean(this.state.isErrorFormDetails.phone)}
                              />
                              <Typography
                                variant="body2"
                                style={webStyle.errorMessage}
                              >
                                {this.state.isErrorFormDetails.phone}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} style={webStyle.gridItems} className={classes.gridItems}>
                          <Typography
                            style={webStyle.contactForm}
                            className={classes.contactForm}
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                          >
                            {t("landingPage.ContactUs.iWouldLikeTo")}
                          </Typography>
                          <StyledTextField
                            fullWidth
                            variant="outlined"
                            defaultValue={t("landingPage.ContactUs.askAQuestion")}
                            value={questionList.length === 0 ? t('landingPage.ContactUs.askAQuestion') : questionList.map((i: QuestionList) => i.name).join(', ')}
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img style={!this.state.toggleQuestionsModal ? webStyle.contactUsDropDown : {...webStyle.contactUsDropDown, transform: "rotate(180deg)"}} src={contactUsDropDown} alt="" />
                                </InputAdornment>
                              ),
                            }}
                            onClick={this.handleQuestionsModalOpen}
                          >
                          </StyledTextField>
                          {this.state.toggleQuestionsModal ? (
                            <ClickAwayListener data-test-id="handleQuestionsModalClose" onClickAway={this.handleQuestionsModalClose}>
                              <Typography>
                                <Box style={webStyle.toggleModalContainer} className={classes.toggleModalContainer}>
                                  <Box style={webStyle.toggleModalInnerContainer} className={classes.toggleModalInnerContainer}>
                                    {questions.map((item) => (
                                      <Box
                                        key={item.id}
                                        style={(item.value === "Others" && this.state.languageSelected === "ar") ? {...webStyle.checkBoxContainer, paddingRight: "2.2rem"} : webStyle.checkBoxContainer}
                                        className={modalStyle(item.value, classes)}
                                      >
                                        <StyledMenuItem
                                          disableRipple
                                          style={webStyle.menuItem}
                                          className={classes.menuItem}
                                          value={item.name}
                                        >
                                          <Typography component={"label"} style={webStyle.labelStyles}>
                                            <Checkbox
                                              data-test-id="handleCheckBoxChange"
                                              checked={this.state.questionList.some((q: QuestionList) => q.value === item.value)}
                                              onChange={(event) => this.handleCheckBoxChange(event, item)}
                                            />
                                            {item.name}
                                          </Typography>
                                        </StyledMenuItem>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              </Typography>
                            </ClickAwayListener>
                          ) : null}
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} style={webStyle.gridItems} className={classes.gridItems}>
                          <Typography
                            style={webStyle.contactForm}
                            className={classes.contactForm}
                            variant="body1"
                            gutterBottom
                            color="textPrimary"
                          >
                            {t("landingPage.ContactUs.message")}<span style={webStyle.redColor}>*</span>
                          </Typography>
                          <StyledTextFields
                            data-test-id="formDetails"
                            style={getConditionalStyle(this.state.isErrorFormDetails.message)}
                            fullWidth
                            multiline
                            minRows={4}
                            variant="outlined"
                            placeholder={t("landingPage.ContactUs.tellUsSomething")}
                            name="message"
                            value={message}
                            onChange={this.handleChangeFormDetails}
                            error={Boolean(this.state.isErrorFormDetails.message)}
                            onKeyDown={(e) => handleKeyDown(e, this.handleDetailsSubmit, message)}
                          />
                          <Typography
                            style={webStyle.errorMessage}
                            variant="body2"
                          >
                            {this.state.isErrorFormDetails.message}
                          </Typography>
                        </Grid>
                        <Button
                          className={classes.buttonContainerSubmit}
                          type="submit"
                        >
                          <Typography
                            style={webStyle.buttonTextSubmit}
                            className={classes.buttonTextSubmit}
                            variant="body2"
                          >
                            {t("landingPage.ContactUs.submit")}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Container>
            </Box>
            <Hidden mdUp>
              <Box style={webStyle.contactAddressStyle}>
                {contactUsAddress(classes, getContactUsData, this.state.languageSelected)}
              </Box>
            </Hidden>
            <Footer {...this.props} active={3} homePage={this.getContactUsData} oldProps={this} />
          </>
        ) : (
          <LoadingBackdrop
            loading={this.state.getContactUsDataLoading}
          />
        )}
        <LoadingBackdrop
          loading={this.state.contactUsLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
      marginTop: "10rem !important",
      padding: "0rem 0.5rem 0rem 0.5rem !important"
    }
  },
  contactUsImage: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important"
    }
  },
  letsTalkText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    },
  },
  body: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
      marginTop: "0rem !important"
    },
  },
  addressText: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important"
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: "24px !important",
      fontSize: "18px !important"
    },
  },
  menuItem: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
  },
  customOption: {
    color: CONSTANTS.color.midGrey
  },
  toggleModalContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "325px !important"
    },
  },
  toggleModalInnerContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 !important"
    },
  },
  checkBoxContainer: {
    marginLeft: '1rem',
    [theme.breakpoints.down("sm")]: {
      gap: "1rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0 !important"
    },
  },
  checkBoxContainerOthers: {
    [theme.breakpoints.down("sm")]: {
      gap: "1rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-0.8rem !important"
    },
  },
  contactUsText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px !important",
      lineHeight: "30px !important",
      marginTop: "0 !important"
    },
  },
  buttonTextSubmit: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  buttonContainerSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.thickBlue,
    borderRadius: "10px",
    minHeight: "56px",
    maxWidth: "166px",
    width: "100%",
    marginTop: "2.5rem",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.5rem !important",
      minHeight: "48px !important",
      maxWidth: "100% !important"
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column-reverse !important"
    },
  },
  contactForm: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0.5rem !important",
    },
  },
  gridItems: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.5rem !important",
    },
  },
});

const webStyle = {
  container: {
    marginTop: "12rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0rem 2rem 4rem 2rem",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  letsTalkText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
  },
  body: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    maxWidth: "500px",
    width: "100%",
    marginTop: "1rem"
  },
  contactUsImage: {
    maxWidth: "384px",
    width: "100%",
    height: "auto",
    marginTop: "2rem"
  },
  contactUsText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "70px",
    marginTop: "3rem"
  },
  contactIcons: {
    height: "20px",
    width: "17px",
  },
  contactAddress: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginTop: "1.5rem"
  },
  addressText: {
    opacity: 0.7,
    color: CONSTANTS.color.coolDarkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "30px",
    maxWidth: "460px",
    width: "100%",
  },
  gridItems: {
    marginTop: '1rem'
  },
  contactForm: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem"
  },
  countryCodeError: {
    border: `0.5px solid ${CONSTANTS.color.red}`,
    backgroundColor: "rgb(252, 238, 240)",
    borderRadius: "12px"
  },
  buttonTextSubmit: {
    color: CONSTANTS.color.white,
    fontFamily: 'Poppins',
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    textTransform: "capitalize" as const,
  },
  contactUsDropDown: {
    width: "14px",
    height: "8px",
    cursor: "pointer"
  },
  modalContainer: {
    padding: "18.5px 34px",
    borderRadius: "12px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "0.5rem"
  },
  modalInnerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    paddingTop: "0.5rem"
  },
  errorMessage: {
    marginTop: "5px",
    color: CONSTANTS.color.red,
  },
  errorStyle: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "10px",
    padding: "0px"
  },
  toggleModalContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "100%",
    maxWidth: "502px",
    minHeight: "125px",
    borderRadius: "12px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
  },
  toggleModalInnerContainer: {
    display: "flex",
    flexWrap: "wrap" as const,
    padding: "1rem",
  },
  menuItem: {
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  labelStyles: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: CONSTANTS.color.midGrey
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
  },
  redColor: {
    color: CONSTANTS.color.red,
  },
  contactAddressStyle: {
    backgroundColor: "rgba(28, 24, 175, 0.05)",
    padding: "2rem 1rem 1.5rem",
    marginTop: "3rem"
  }
}

const contactUsOpenToast = withStyles(styles)(ContactUs);
const ContactUsStyles = withSnackbar(contactUsOpenToast);
export default withTranslation()(ContactUsStyles);
// Customizable Area End