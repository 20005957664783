import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Button,
  Box,
  CircularProgress,
  Backdrop, Hidden
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withRouter } from "react-router-dom";
import {
  mainLogo,
  sidePage,
  sidePage2,
  sidePage1,
  sidePage3,
  firstSideImg,
} from "../../email-account-login/src/assets";
import { languageLogo1, languagelogo2, mobileLogo } from "../../LanguageSupport/src/assets";
import { withSnackbar } from "../../../components/src/toastweb";
import {
  ProfileStyledButton,
  ProfileStyledTextField,
} from "../../CustomisableUserProfiles/src/utility.web";
export const configJSON = require("./config");
import CloseIcon from "@material-ui/icons/Close";
import { completedIcon } from "../../dashboard/src/assets";
import withPermission from "../../../components/src/withPermission.web";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End
import EmployeeLoginController, { Props } from "./EmployeeLoginController.web";

export class EmployeeLogin extends EmployeeLoginController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start


  renderCircularBackdropV1 = () => {
    return this.state.loginLoader ? (
      <Backdrop
        style={{
          zIndex: 1000 + 1,

          justifyContent: "center",
          alignItems: "center",
          color: "#05037a",
        }}
        open={this.state.loginLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : null;
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t }: any = this.props;
    return (
      <>
        <form onSubmit={this.handleEmployeeLoginInformation}>
          {/* new code */}
          {this.state.toaster ? (
            <div className={classes.employeetoasterStyle}>
              <Grid item xs={12} md={12}>
                <Box style={webStyle.employeeLogintoaster}>
                  <CloseIcon
                    style={webStyle.employeeLogincloseIcon}
                    onClick={this.handleCloseToaster}
                  />
                  <img
                    src={completedIcon}
                    style={{
                      width: "28px",
                      height: "28px",
                      marginRight: "5px",
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.employeeLogintoasterText}
                  >
                    {this.state.toasterText}
                  </Typography>
                </Box>
              </Grid>
            </div>
          ) : null}
          <Grid container direction="row" alignItems="center" className={classes.imageLayout}>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.employeeLoginImage} />
            </Hidden>
            <Hidden only={'xs'}>
              <Grid item xs={12} sm={1} lg={2} xl={3} className={classes.secondLayoutImage}>
                <img style={{ width: "80px" }} src={firstSideImg} alt="firstSideImg" />
              </Grid>
            </Hidden>

            <Grid item xs={12} sm={5} md={5} lg={5} xl={5} style={{ padding: '30px' }}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <img src={mainLogo} className={classes.logoImgEmployee} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.userTitleEmp}>
                    {t('BoAuth.loginTitle')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.subTitletextEmp}>
                    {t('BoAuth.subtitle')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.loginLabelText}>
                    {t('BoAuth.emailLabel')}

                    <span className={classes.errortext}>*</span>
                  </Typography>
                  <ProfileStyledTextField
                    variant="outlined"
                    fullWidth
                    placeholder={t('BoAuth.emailPlaceHolder')}
                    name="email"
                    value={this.state.loginFormData.employeeEmail}
                    onChange={this.handleEmployeeLogin}
                    error={Boolean(
                      this.state.isErrorFormData.employeeEmail ||
                      this.state.apiErr.emailErr
                    )}
                    style={{ width: "100%", marginBottom: "5px" }}
                  />
                  <Typography variant="body1" className={classes.errorIcon}>
                    {(this.state.isErrorFormData.employeeEmail ||
                      this.state.apiErr.emailErr) && (

                        <ErrorOutlineIcon color="error" />
                      )}
                    {this.state.isErrorFormData.employeeEmail ||
                      this.state.apiErr.emailMsg}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={webStyle.fieldBottomMargin}>
                  <Typography variant="body2" className={classes.loginLabelText}>
                    {t('BoAuth.passwordLabel')}
                    <span className={classes.errortext}>*</span>
                  </Typography>
                  <ProfileStyledTextField
                    variant="outlined"
                    fullWidth
                    placeholder={t('BoAuth.passwordPlaceholder')}
                    name="textPassword"
                    style={{ width: "100%" }}
                    value={this.state.loginFormData.password}
                    onChange={this.handleEmployeeLogin}
                    error={Boolean(
                      this.state.isErrorFormData.password ||
                      this.state.apiErr.error
                    )}
                    type={this.state.showPassword ? "text" : "password"}
                    //helperText={this.state.isErrorFormData.textPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-test-id="showPassword"
                            aria-label="toggle password visibility"
                            onClick={this.handleShowPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-end"
                    style={{ width: "100%" }}
                  >
                    <Grid item xs={12} style={{ width: "100%", display: "flex" }}>
                      <Typography variant="body1" className={classes.errorIcon}>
                        {(this.state.isErrorFormData.password ||
                          this.state.apiErr.error) && (
                            <ErrorOutlineIcon color="error" />
                          )}
                        {this.state.isErrorFormData.password ||
                          this.state.apiErr.message}
                      </Typography>
                      
                    </Grid>
                    <Grid item xs={12}>
                    <Typography
                        variant="body2"
                        className={classes.fpass}
                        onClick={this.forgotPassword}
                      >
                        {t('BoAuth.forgotPassword')}
                      </Typography>

                    </Grid>
                  </Grid>
                </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "end",
                        width: "100%",
                        marginTop: "30px",
                      }}
                    >
                      <ProfileStyledButton
                        data-test-id="btnEmailLogin"
                        type="submit"
                        disabled={this.state.loginLoader}
                        className={classes.profileLogin}
                      >
                        <Typography variant="body1" className={classes.btntext}>
                          {t('BoAuth.login')}

                        </Typography>
                      </ProfileStyledButton>
                    </Grid>

              </Grid>

            </Grid>
            <Grid item xs={12} sm={1} md={2} lg={2} />
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <Grid item xs={12} sm={1} lg={2} xl={3} className={classes.secondLayoutImage}>
                <img className={classes.secondImage} src={firstSideImg} alt="firstSideImg" />
              </Grid>
            </Hidden>
            <Hidden only={'xs'}>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.employeeLoginImage} />
            </Hidden>
          </Grid>
          {this.renderCircularBackdropV1()}
        </form>
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  errorIcon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    color: theme.palette.error.main,
  },
  userTitleEmp: {
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    paddingTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30

    },

  },
  logoImgEmp: {
    width: "150px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "120px",
    },
  },
  subTitletextEmp: {
    color: CONSTANTS.color.starDust,
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  employeeLoginImage: {
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${mobileLogo})`,
      maxHeight: "86px"
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${sidePage1})`,
    },
    [theme.breakpoints.between("md", "lg")]: {
      backgroundImage: `url(${sidePage3})`,
    },
    backgroundImage: `url(${sidePage1})`,
    height: "100vh",
    backgroundSize: "100% 100%",
    position: "relative",
    [theme.breakpoints.between("sm", "md")]: {
      backgroundImage: `url(${sidePage2})`,
    },
  },
  btntext: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "none",
    color: theme.palette.common.white,
    textAlign: "center",
  },
  errortext: {
    color: theme.palette.error.main,
  },
  loginLabelText: {
    color: CONSTANTS.color.starDust,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  fpass: {
    borderBottom: "1px solid #1C18AF",
    marginTop: "2px",
    color: CONSTANTS.color.darkBlue,
    cursor: "pointer",
    marginLeft: "auto",
  },
  logoImgEmployee: {
    width: "150px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "120px",
    },
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-evenly",
    },
  },

  btntext1: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "none",
    marginRight: "auto",
    color: "#666666"
  },
  buttongrid: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  employeetoasterStyle: {
    zIndex: 99,
    position: "absolute",

    paddingLeft: "3rem",
    transform: "translate(0%, 55%)",
    [theme.breakpoints.between("md", "lg")]: {
      transform: "translate(29%, 50%)",
    },

  },
  employeeLogintoasterText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    paddingRight: "55px",
  },
  secondLayoutImage: {
    display: 'flex',
    alignItems: 'end',
    height: '100%',
  },
  imageLayout: {
    [theme.breakpoints.up("sm")]: {
      height: '100%'
    },
  },
  profileLogin: {
    [theme.breakpoints.down("xs")]: {
      width: '100%'
    },
  },
  secondImage: {
    width: '80px',
    [theme.breakpoints.down("xs")]: {
      width: '47px',
      height: '47px'
    },
  }
});

const webStyle = {
  button: {
    width: "100%",
    height: 64,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonClicked: {
    width: "100%",
    height: 64,
    borderRadius: 10,
    backgroundColor: `${CONSTANTS.color.invisibleBlue}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  employeeLogintoaster: {
    position: "relative" as "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft: "2rem",
    width: "100%",
    maxWidth: "689px",
    minHeight: "80px",
    borderRadius: "8px",
    backgroundColor: "#1c18af",
    marginTop: "5px",
  },
  employeeLogincloseIcon: {
    position: "absolute" as "absolute",
    left: "96%",
    color: " #fff",
    top: "29%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },

  fieldBottomMargin: {
    marginBottom: "5px"
  },

};
//@ts-ignore
const loginRouter = withStyles(styles)(withPermission(EmployeeLogin));
//@ts-ignore
const employeeLoginToast = withRouter(loginRouter);
const loginSnackBar = withSnackbar(employeeLoginToast);
const transalatation = withTranslation()(loginSnackBar)
export default withTranslation()(transalatation);

// Customizable Area End
