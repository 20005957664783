// Customizable Area Start
//@ts-nocheck
import React from 'react'
import { Typography, withStyles, TextField, Button, styled, Checkbox, Menu } from "@material-ui/core";
import { CONSTANTS } from '../../../components/src/theme'
const selectedLanguage =  localStorage.getItem("lang");
export const TypographyStyled = withStyles({
  root: {

  }

})(Typography);

export const StyledTextFieldJob = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      fontFamily: "Rubik",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",

    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px"
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"

    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px"

    },
    "& .MuiOutlinedInput-inputAdornedStart": {
      paddingLeft: "20px"
    }
  }
})(TextField)

export const StyledEmailTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      fontFamily: "Rubik",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",

    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px"
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"

    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px"

    },
    "& .MuiOutlinedInput-inputAdornedStart": {
      paddingLeft: "10px"
    }
  }
})(TextField);

export const StyledRequestButton = withStyles({
  root: {
    backgroundColor: "#1C18AF",
    width: "216px",
    height: "67px",
    borderRadius: "10px",
    textTransform: "capitalize",
    '&:hover': {
      backgroundColor: "#1C18AF",
      color: "#ffffff",
    }
  }
})(Button)

export const StyledDiscountDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: "50% !important",
  },
}));

export const StyledProductsDiv = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('sm')]: {
    left: "39.5% !important",
  },
}));
export const StyledCheckbox = withStyles({
  root: {
    height: "24px",
    width: "24px",
    color: "rgba(0,0,0,0.1)",
    "&.Mui-checked": {
      color: CONSTANTS.color.darkBlue
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem"
    }
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const StyledMenu = withStyles((theme: Theme) =>
    createStyles({
      paper: {
        borderRadius: "5px",
        background: "#ffffff",
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
      },
    }))((props: MenuProps) => (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: "1000px" }}
        {...props}
      />
    ));
export const JobsStyledMenu:any = withStyles({
  paper: {
    borderRadius: "5px",
    background: '#ffffff',
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    marginLeft: selectedLanguage === 'en' ? "-15px" : "",
    marginTop: "-129px",
},
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
// Customizable Area End