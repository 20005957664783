import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Hidden,
  Container,
  Typography,
  InputBase,
  TextField,
  MenuItem,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  ClickAwayListener
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import {
  searchIcon,
  dropDownSelectIcon
} from "../assets/assets";
import {
  backIconMobile
} from "./assets";
import { withSnackbar } from "../../../components/src/toastweb";
import withPermission from "../../../components/src/withPermission.web"
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
// Customizable Area End

import SuppliersController, {
  Props,
} from "./SuppliersController.web";

// Customizable Area Start
export const StyledTextField = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      height: "56px",
      borderRadius: "10px",
      color: CONSTANTS.color.grey,
      fontSize: "1.1667rem",
      fontWeight: CONSTANTS.fontWeight.fontWeight400,
      lineHeight: "16px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important"
      }
    },
   '&.language-ar': {
      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "13px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: CONSTANTS.color.thickBlue,
      borderWidth: "2px"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.1rem",
      marginTop: "-5px",
    }
  }
}))(TextField);

export const StyledTableContainer = withStyles({
  root: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  }
})(TableContainer);

const StyledTableRowData = withStyles({
  root:{
    cursor:"pointer",
    "&:hover": {
      backgroundColor: CONSTANTS.color.blueViolet,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& .MuiInputBase-root .MuiInputBase-input": {
        color: `${CONSTANTS.color.white} !important`,
      },
    },
  }
})(TableRow)

interface MockStatusData {
  id: number;
  title: string;
  value: string;
}

interface Data {
  id: string;
  supplier_name: string;
  contact_name: string;
  mobile_number: string;
  email: string;
  supplier_of: string;
  value: string;
}

interface MockToggleData {
  id: number;
  title: string;
  hidden: boolean;
}

const backIconStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.backIconMobile, transform: "rotate(180deg)"} : webStyle.backIconMobile
}

const tableClassStyle = (languageSelected: string, classes: ClassNameMap) => {
  return languageSelected === "ar" ? `${classes.tableHeadSpan} ${classes?.tableHeadSpanAr} ${classes?.tableHeadSpanArMargin}` : classes.tableHeadSpan
}
// Customizable Area End

export class Suppliers extends SuppliersController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  statusModal = (mockSuppliersStatusData: MockStatusData[], classes: ClassNameMap) => {
    return (
      <ClickAwayListener onClickAway={this.handleStatusSupplierModalClose}>
        <Typography>
          <Box style={webStyle.statusModalContainer}>
            <Box style={webStyle.statusModal}>
              {mockSuppliersStatusData.map((item) => (
                <Box
                  data-test-id="handleSelectStatus"
                  style={{...webStyle.menuItemStyle, ...webStyle.statusModalStyle}}
                  className={classes.menuItemStyle}
                  key={item.id}
                >
                  <Checkbox
                    data-test-id="handleSelectStatus"
                    style={webStyle.checkbox}
                    checked={this.state.checkedItems.some((subItem) => item.value === subItem)}
                    onChange={this.handleSelectStatus.bind(this, item, mockSuppliersStatusData)}
                  />
                  {item.title}
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  toggleModal = (classes: ClassNameMap) => {
    return (
      <ClickAwayListener onClickAway={this.handleToggleSupplierModalClose}>
        <Typography>
          <Box style={this.state.languageSelected === "ar" ? {...webStyle.toggleSupplierModalContainer, padding: "0.5rem"} : webStyle.toggleSupplierModalContainer} className={classes.toggleSupplierModalContainer}>
            <Box style={webStyle.toggleModalStyle}>
              {this.state.toggleData.map((item: MockToggleData) => (
                <Box style={webStyle.toggleIconsContainer} key={item.id}>
                  <StyledCheckbox
                    data-test-id="handleToggleColumn"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={item.hidden}
                    onChange={() => this.handleToggleColumn(item.id)}
                  />
                  <MenuItem
                    style={webStyle.menuItemStyle}
                    className={classes.menuItemStyle}
                    value={item.title}
                    disableRipple
                  >
                    {item.title}
                  </MenuItem>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  tableBodySuppliers = (data: Data[], classes: ClassNameMap) => {
    return (
      <Table style={{ tableLayout: "fixed" }}>
        <TableBody>
          {data?.map((item: Data) => (
            <StyledTableRowData
              style={webStyle.tableBodyRow}
              data-test-id="handleViewSupplierData"
              key={item.id}
              onClick={() => this.handleViewSupplierData(item)}
            >
              {this.state.toggleData[0]?.hidden && (
                <TableCell align="right">
                  <Typography variant="body1" style={webStyle.tableCellBody} className={classes.tableCellBody}>
                    {item?.supplier_name}
                  </Typography>
                </TableCell>)}
                {this.state.toggleData[1]?.hidden && (
                <TableCell align="right">
                  <Typography variant="body1" style={webStyle.tableCellBody} className={classes.tableCellBody}>
                    {item?.contact_name}
                  </Typography>
                </TableCell>)}
                {this.state.toggleData[2]?.hidden && (
                <TableCell align="right">
                  <Typography variant="body1" style={webStyle.tableCellBody} className={classes.tableCellBody}>
                    {item?.mobile_number}
                  </Typography>
                </TableCell>)}
                {this.state.toggleData[3]?.hidden && (
                <TableCell align="right">
                  <Typography variant="body1" style={webStyle.tableCellBody} className={classes.tableCellBody}>
                    {item?.email}
                  </Typography>
                </TableCell>)}
                {this.state.toggleData[4]?.hidden && (
                  <TableCell align="right">
                    <Typography variant="body1" style={webStyle.tableCellBody} className={classes.tableCellBody}>
                      {item?.supplier_of}
                    </Typography>
                  </TableCell>
              )}
            </StyledTableRowData>
          ))}
          {(data?.length === 0) &&
            <Typography
              style={webStyle.noSupplierText}
              variant="subtitle1"
            >
              {this.props.t('suppliers.noDataFound')}
            </Typography>
          }
        </TableBody>
      </Table>
    );
  }

  tableBodyBox = (suppliersData: Data[]) => {
    return suppliersData?.length === 0 ? webStyle.tableStyle : webStyle.tableStyleAuto
  }

  tableRow = (item: { key: string }) => {
    return this.state.sortColumn === item.key ? {...webStyle.tableHeadSpan, backgroundColor: CONSTANTS.color.lightCement} : {...webStyle.tableHeadSpan, ...(this.state.languageSelected === "ar" ? {padding: "0.8rem 0.5rem"} : {})}
  }

  selectedIconStyle = (modal: boolean) => {
    return !modal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;

    const { t } = this.props;
    const mockSuppliersStatusData = [
      { id: 1, title: t('suppliers.all'), value: "all" },
      { id: 2, title: t('suppliers.active'), value: "active" },
      { id: 3, title: t('suppliers.archived'), value: "archived" },
    ]
    
    const mockSuppliersTableRow = [
      { id: 1, key: "supplier_name", title: t('suppliers.supplierName') },
      { id: 2, key: "contact_name", title: t('suppliers.contactName') },
      { id: 3, key: "mobile_number", title: t('suppliers.mobileNumber') },
      { id: 4, key: "email", title: t('suppliers.emailAddress') },
      { id: 5, key: "supplier_of", title: t('suppliers.supplierOf') },
    ]
    const { languageSelected } = this.state;
    const width = `${(100 / this.state.toggleData.filter((item: { hidden: boolean; }) => item.hidden).length)}%`;
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.supplier')} active={false} active1={0} active2={false} />
        <StyledRoot languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.boxSuppliersContainer}
            className={classes.boxSuppliersContainer}
          >
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item lg={7} md={6} xs={12}>
                  <Box style={webStyle.backIconContainer}>
                    <Hidden smUp>
                      <img data-test-id="handleBackIcon" style={backIconStyle(languageSelected)} src={backIconMobile} onClick={this.handleBackIcon} alt="" />
                    </Hidden>
                    <Box style={webStyle.searchContainer}>
                      <Box>
                        <img className={classes.searchIcon} style={webStyle.searchIcon} src={searchIcon} alt="" />
                      </Box>
                      <InputBase
                        data-test-id="handleChangeSearchSupplier"
                        style={webStyle.inputBase}
                        className={classes.inputBase}
                        placeholder={t('suppliers.searchSupplier')}
                        inputProps={{ 'aria-label': 'search' }}
                        value={this.state.searchSupplier}
                        onChange={this.handleChangeSearchSupplier}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={6} xs={5}>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    value={t('suppliers.status')}
                    className={this.state.languageSelected === 'ar' ? 'language-ar' : ''}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={this.selectedIconStyle(this.state.statusModal)} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleStatusSupplierModalOpen}
                  ></StyledTextField>
                  {this.state.statusModal ? (
                    this.statusModal(mockSuppliersStatusData, classes)
                  ) : null}
                </Grid>
                <Grid item lg={3} md={6} xs={7}>
                  <StyledTextField
                    fullWidth
                    defaultValue={t('suppliers.toggleColumns')}
                    className={this.state.languageSelected === 'ar' ? 'language-ar' : ''}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={this.selectedIconStyle(this.state.toggleModal)} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleToggleSupplierModalOpen}
                  >
                  </StyledTextField>
                  {this.state.toggleModal ? (
                    this.toggleModal(classes)
                  ) : null}
                </Grid>
              </Grid>
              <Grid container style={webStyle.suppliersContainer}>
                <Grid item xs={12} style={webStyle.gridItemStyle}>
                  <Grid container style={webStyle.gridItemContainer}>
                    {mockSuppliersTableRow.map((item, index) => (
                      <>
                        {this.state.toggleData[index]?.hidden && (
                          <Grid
                            data-test-id="handleSort"
                            item
                            key={item.id}
                            onClick={() => this.handleSortSupplier(item.key)}
                            style={this.state.sortColumn === item.key ?
                              { backgroundColor: "rgba(232,232,232,0.4)", width: width, minWidth: "185px" } :
                              { width: width, minWidth: "185px" }
                            }
                          >
                            <span
                              style={this.tableRow(item)}
                              className={tableClassStyle(languageSelected, classes)}
                            >
                              <Typography
                                component={"span"}
                                variant="body1"
                                className={classes.titleTableRow}
                              >
                                {item.title}
                              </Typography> <img style={(this.state.sortColumn === item.key) && this.state.sortDirection === 'asc' ? {...webStyle.dropDownSelectIconTable, ...webStyle.transformStyle} : webStyle.dropDownSelectIconTable} src={dropDownSelectIcon} className={classes.dropDownSelectIcon} alt="" />
                            </span>
                          </Grid>
                        )}
                      </>
                    ))}
                  </Grid>
                  <span></span>
                  {this.state.checkedItems.find(item => item === "active") &&
                    <Box>
                      {(this.state.toggleData.some((item: { hidden: boolean }) => item.hidden)) &&
                        <>
                          <Typography style={webStyle.activeText} variant="body1" className={classes.title}>
                            {t('suppliers.active')}
                          </Typography>
                          <span style={webStyle.hr}></span>
                        </>
                      }
                      <Table style={{ minWidth: 710 }}>
                        <Box style={this.tableBodyBox(this.state.suppliersData)}>
                          {this.tableBodySuppliers(this.state.suppliersData.active, classes)}
                        </Box>
                      </Table>
                    </Box>
                  }
                  {this.state.checkedItems.find(item => item === "archived") &&
                    <Box>
                      {(this.state.toggleData.some((item: { hidden: boolean }) => item.hidden)) &&
                        <>
                          <Typography style={{...webStyle.activeText, color: "#ffbc00"}} variant="body1" className={classes.title}>
                            {t('suppliers.archived')}
                          </Typography>
                          <span style={{...webStyle.hr, backgroundColor: "#ffbc00"}}></span>
                        </>
                      }
                      <Table style={{ minWidth: 710 }}>
                        <Box style={this.tableBodyBox(this.state.suppliersData)}>
                          {this.tableBodySuppliers(this.state.suppliersData.archived, classes)}
                        </Box>
                      </Table>
                    </Box>
                  }
                </Grid>
              </Grid>
              <Box style={webStyle.buttonsContainer}>
                <Button
                  onClick={this.handleCreateSupplier}
                  className={`${classes.buttonContainer} ${classes.buttonContainerCreateSupplier}`}
                >
                  <Typography
                    style={webStyle.buttonTextCreateSupplier}
                    variant="subtitle2"
                    className={classes.buttonTextCreateSupplier}
                  >
                    {t('suppliers.createASupplier')}
                  </Typography>
                </Button>
              </Box>
            </Container>
          </Box>
        </StyledRoot>
        <LoadingBackdrop
          loading={this.state.getSuppliersLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxSuppliersContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.25rem 2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8rem !important",
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important"
    }
  },
  dropDownSelectIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    }
  },
  menuItemStyle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  inputBase: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  titleTableRow: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important"
    }
  },
  searchIcon: {
    [theme.breakpoints.down("xs")]: {
      margin: "4px 0.6rem 0px 1.3rem !important",
    }
  },
  toggleSupplierModalContainer: {
    [theme.breakpoints.down("xs")]: {
      right: "23px !important",
    }
  },
  buttonTextCreateSupplier: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  buttonContainerCreateSupplier: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "216px",
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    color: CONSTANTS.color.white,
    backgroundColor: CONSTANTS.color.darkBlue,
    textTransform: "none" as const,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  tableCellBody: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    }
  },
  tableHeadSpanAr: {
    [theme.breakpoints.down("sm")]: {
      gap: "6px !important",
    }
  },
  tableHeadSpanArMargin: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 !important",
    }
  },
  tableHeadSpan: {
    [theme.breakpoints.between(600, 800)]: {
      margin: "0 3px !important",
    },
  },
});

const webStyle = {
  boxSuppliersContainer: {
    marginTop: "9rem",
    paddingBottom: "5rem"
  },
  searchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer"
  },
  inputBase: {
    width: "100%",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    color: CONSTANTS.color.grey
  },
  dropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    transform: "rotate(0deg)"
  },
  transformStyle: {
    transform: 'rotate(180deg)'
  },
  statusModalContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "182px",
    minHeight: "102px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  statusModal: {
    padding: "1rem"
  },
  statusModalStyle: {
    display: "flex",
    gap: "1rem",
    padding: "5px 0",
  },
  menuItemStyle: {
    color: CONSTANTS.color.grey,
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  checkbox: {
    padding: 0,
    color: CONSTANTS.color.darkBlue
  },
  toggleSupplierModalContainer: {
    position: "absolute" as const,
    padding: "1rem",
    zIndex: 9,
    width: "254px",
    minHeight: "194px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  toggleModalStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
  },
  toggleIconsContainer: {
    display: "flex",
    alignItems: "center"
  },
  toggleIcons: {
    width: "24px",
    height: "24px"
  },
  suppliersContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "697px",
    overflow: "scroll"
  },
  gridItemStyle: {
    overflow: 'scroll',
    minWidth: "fit-content"
  },
  gridItemContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap' as const,
    gridAutoFlow: 'column' as const,
  },
  tableContainer: {
    position: "sticky" as const,
    top: 0
  },
  tableCell: {
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    width: "25%",
    maxWidth: "100%"
  },
  tableBodyRow: {
    cursor: "pointer"
  },
  tableCellBody: {
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "start" as const,
    wordBreak: "break-all" as const,
  },
  tableHeadSpan: {
    color: CONSTANTS.color.grey,
    cursor: "pointer",
    padding: "0.8rem",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 5px"
  },
  buttonsContainer: {
    paddingTop: "3rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tableStyle: {
    height: "560px",
    display: "flex",
    alignItems: "center",
    textAlignLast: "center" as const
  },
  tableStyleAuto: {
    height: "312px",
    overflow: "auto"
  },
  activeText: {
    color: "rgba(0,10,255,0.66)",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    padding: "1rem 1rem 5px 1rem",
    fontSize:"16px"
  },
  hr: {
    display: "flex",
    backgroundColor: "#000aff",
    minWidth: "710px",
    minHeight: "1px"
  },
  buttonTextCreateSupplier: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  noSupplierText: {
    color: CONSTANTS.color.darkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
    paddingTop: "8rem"
  },
  backIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  backIconMobile: {
    width: "12px",
    height: "24px",
  },
}

const suppliersStyles = withStyles(styles)(Suppliers);

const suppliersToast = withSnackbar(suppliersStyles);
const suppliersPermission = withPermission(suppliersToast);
export default withTranslation()(suppliersPermission);
// Customizable Area End