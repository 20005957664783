import React from "react";

// Customizable Area Start
import { withRouter } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Avatar,
  Typography,
  InputAdornment,
  TextField,
  Dialog,
  InputBase,
  DialogContent,
  withWidth
} from "@material-ui/core";
import { withStyles ,} from "@material-ui/core/styles";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { ProfileSubSideBar } from "./ProfileSubSideBar.web";
import { StyledRoot, countryCodes } from "./utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
import {
  EmployeeStyledTextField,
  EmployeeStyledMenuItem,
  EmployeeProfileStyledButton,
  EmployeeStyledBoxModal,
} from "./EmployeeProfile.web";
import {
  userIcon,
  profileDropDown,
  successModalImage,
  successModalCross,
} from "./assets";
import {
  editIcon,
  galleryIcon,
  deleteLogoIcon,
  dialogBoxIcon,
} from "../../Settings/src/assets";
import AutocompleteV1 from "@material-ui/lab/Autocomplete";
import { Autocomplete } from "@react-google-maps/api";
import {  CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ProfileController, { Props } from "./ProfileController.web";

// Customizable Area Start
interface TextfieldProps {
  languageSelected: string;
}
export const StyledTextFieldsAutoComplete = withStyles((theme)=>({
  root: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "90px",
    },
    "& .MuiInputBase-root": {
      display: "flex",
      justifyContent: (props: TextfieldProps) => props.languageSelected === "ar" ? "center" : "flex-start",
      height: "55px",
      fontSize: "1.1667rem",
      borderRadius: (props: TextfieldProps) => props.languageSelected !== "ar" ? "7px 0 0 7px" : "0 7px 7px 0",
      color: CONSTANTS.color.midGrey,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important"
      },
    },

    "& .MuiOutlinedInput-input": {
      cursor: "pointer",
      padding: "15px 12px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#05037a",
      borderWidth: "2px",
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none",
    },
  },
}))(TextField);

export const ProfileStyledTextFields = withStyles((theme)=>({
  root: {
    "& .MuiInputBase-root": {
      height: "56px",
      borderRadius: "8px",
      border: "1px solid rgba(0,0,0,0.1)",
      fontFamily: "Rubik",
      fontSize: "1.1667rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "1.0625rem !important"
        },
    },
  },
}))(TextField);

export const ProfileStyledSmallAvatar = withStyles((theme)=>({
  root: {
    "& .MuiAvatar-root": {
      width: "18px",
      height: "18px",
    },
  },
}))(Avatar);

export const renderImageUrl = (item: File) => {
  let objectUrl = "";

  if (item) {
    if (typeof window.URL.createObjectURL === "function") {
      objectUrl = window.URL.createObjectURL(item);
    } else if (typeof window.webkitURL?.createObjectURL === "function") {
      objectUrl = window.webkitURL.createObjectURL(item);
    } else {
      console.error("createObjectURL is not supported in this environment.");
    }

    return objectUrl;
  }
};

// Customizable Area End

// Customizable Area Start
export class Profile extends ProfileController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  renderProfileImage = () => {
    return (
      <>
        {!this.state.companyLogo && !this.state.profile_image && (
          <img
            style={{ borderRadius: "50%", marginTop: "20px" }}
            src={userIcon}
            height="100"
            width="100"
          />
        )}
        {this.state.companyLogo && (
          <img
            style={{ borderRadius: "50%", marginTop: "20px" }}
            src={renderImageUrl(this.state.companyLogo)}
            height="100"
            width="100"
          />
        )}
        {this.state.profile_image && (
          <img
            style={{ borderRadius: "50%", marginTop: "20px" }}
            src={this.state.profile_image}
            height="100"
            width="100"
          />
        )}

        <img
          style={webStyle.editIcon}
          src={editIcon}
          alt=""
          onClick={this.handleClickEditIcon}
        />
      </>
    )
  }
  renderProfileForm = (classes: any) => {
    const{t}=this.props
    const {
      firstName,
      lastName,
      email,
      phone,
      streetAddress,
      city,
      country,
      postcode,
    } = this.state.formDetails;
   
    return (
      <>
        <Grid xs className={classes.mainContV1} style={{ paddingTop: "55px" }}>
          <ProfileSubSideBar
            {...this.props}
            navMenuItem={t("boProfile.profile")}
            profileActive={0}
           
          />
        </Grid>
        <Grid
          className={classes.mainCont}
        >
          {/* <Divider orientation="vertical"/> */}
          <Container maxWidth={"lg"} style={{ padding: 0 }}>
            <Typography
            
              variant="subtitle1"
              className={classes.profileTitle}
            >
             {t("boProfile.profileInformation")}
            </Typography>
          </Container>

          <Grid container direction="column">
            <Grid item xs>
              <Box>
                <Grid item>
                  {this.renderProfileImage()}
                </Grid>
                {this.state.logoChangeModal ? (
                  <EmployeeStyledBoxModal style={this.state.lang === "en" ? webStyle.changeLogoContainer : webStyle.changeLogoContainerAr}>
                    <img
                      style={this.state.lang === "en" ? webStyle.dialogBoxIcon : webStyle.dialogBoxIconAr}
                      src={dialogBoxIcon}
                      className={classes.dialogBoxIconAr}
                      alt=""
                    />
                    <Box
                      style={
                        this.state.isHovered
                          ? {
                              ...webStyle.profilechangeLogoInnerContainer,
                              backgroundColor: "rgba(28,24,175,0.06)",
                            }
                          : webStyle.profilechangeLogoInnerContainer
                      }
                      component="label"
                      onMouseEnter={this.handleMouseEnterChangeLogo}
                      onMouseLeave={this.handleMouseLeaveChangeLogo}
                    >
                      <input
                        data-test-id="handleSelectFiles"
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={this.handleFile}
                      />
                      <img
                        style={webStyle.profilegalleryIcon}
                        src={galleryIcon}
                        alt=""
                      />
                      <Typography
                        style={webStyle.profilechangeLogoText}
                        variant="body1"
                      >
                         {t("boProfile.changeLogo")} 
                      </Typography>
                    </Box>
                    <Box
                      style={
                        this.state.isHovered2
                          ? {
                              ...webStyle.profilechangeLogoInnerContainer,
                              backgroundColor: "rgba(28,24,175,0.06)",
                            }
                          : webStyle.profilechangeLogoInnerContainer
                      }
                      onClick={this.handleDeleteUploadedLogo}
                      onMouseEnter={this.handleMouseEnterDeleteLogo}
                      onMouseLeave={this.handleMouseLeaveDeleteLogo}
                    >
                      <img
                        style={webStyle.profilegalleryIcon}
                        src={deleteLogoIcon}
                        alt=""
                      />
                      <Typography
                        style={webStyle.profileremoveImageText}
                        variant="body1"
                      >
                         {t("boProfile.removeImage")} 
                      </Typography>
                    </Box>
                  </EmployeeStyledBoxModal>
                ) : null}
              </Box>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                {t("landingPage.ContactUs.firstName")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.firstName)}
                  inputProps={{ className: classes.profiletextFielsFonts }}
                />
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.firstName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                  {t("landingPage.ContactUs.lastName")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.lastName)}
                  inputProps={{ className: classes.profiletextFielsFonts }}
                />
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.lastName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                  {t("landingPage.ContactUs.email")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your email address"
                  name="email"
                  value={email}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.email)}
                  inputProps={{ className: classes.profiletextFielsFonts }}
                  type="email"
                />
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.email}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >  {t("landingPage.ContactUs.phoneNumber")}
                </Typography>

                <Box style={webStyle.profilePhoneContainer}>
                  <AutocompleteV1
                    id="country-select-demo"
                    style={{ width: "34%" }}
                  
                    options={countryCodes}
                    value={
                      this.state.countryCode &&  this.state.countryCode !== "" &&
                      this.state.countryCode?.toString().charAt(0) != "+"
                        ? "+" + this.state.countryCode?.toString()
                        : this.state.countryCode?.toString()
                    }
                    onChange={this.handleChangeCountryCode}
                    autoHighlight
                    renderInput={this.renderAutoCompleteInputProfile.bind(this)}
                  />

                  <InputBase
                    data-test-id="formDetails"
                    fullWidth
                    className={
                      this.state.lang === "ar" ?
                      `${classes?.profileInputBase} ${classes?.profileinputBaseAr}` :
                      classes?.profileInputBase
                    }
                    style={webStyle.profileInputBase}
                    name="phone"
                    value={phone}
                    onChange={this.handleChangeFormDetails}
                    error={Boolean(this.state.isErrorFormDetails.phone)}
                  />
                </Box>
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.phone}
                </Typography>
              </Grid>

              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                 {t("boProfile.streetAddress")}
                </Typography>
               

                <Autocomplete
                  onPlaceChanged={this.onPlaceChangedProfile}
                  onLoad={this.onLoadProfile}
                >
                  <ProfileStyledTextFields
                    fullWidth
                    variant="outlined"
                    name="streetAddress"
                    value={streetAddress}
                    onChange={this.handleChangeFormDetails}
                    onKeyPress={this.handleKeyPressProfile}
                    ref={this.inputRef}
                    error={Boolean(this.state.isErrorFormDetails.streetAddress)}
                    inputProps={{ className: classes.profiletextFielsFonts }}
                  />
                </Autocomplete>
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.streetAddress}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                  {t("customers.siteDialog.city")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="city"
                  value={city}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.city)}
                  inputProps={{ className: classes.profiletextFielsFonts }}
                />
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.city}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                  {t("customers.siteDialog.country")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="country"
                  value={country}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.country)}
                  inputProps={{ className: classes.profiletextFielsFonts }}
                />
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.country}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={webStyle.profilegridItems}>
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                 {t("customers.siteDialog.postCode")}
                </Typography>
                <ProfileStyledTextFields
                  data-test-id="formDetails"
                  fullWidth
                  variant="outlined"
                  //placeholder="Enter your last name"
                  name="postcode"
                  value={postcode}
                  onChange={this.handleChangeFormDetails}
                  error={Boolean(this.state.isErrorFormDetails.postcode)}
                  inputProps={{ className: classes.profiletextFielsFonts }}
                />
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.postcode}
                </Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                style={webStyle.profilegridItems}
              >
                <Typography
                  style={webStyle.profilecontactForm}
                  variant = "body1"
                  gutterBottom
                >
                   {t("customers.siteDialog.language")}
                </Typography>
                <EmployeeStyledTextField
                  fullWidth
                  variant="outlined"
                  defaultValue="english"
                  languageSelected={this.state.lang}
                  error={Boolean(this.state.isErrorFormDetails.language)}
                  value={this.state.formDetails.language}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          style={
                            !this.state.toggleLanguageModal
                              ? webStyle.profilecontactUsDropDown
                              : {
                                  ...webStyle.profilecontactUsDropDown,
                                  transform: "rotate(180deg)",
                                }
                          }
                          src={profileDropDown}
                          alt=""
                        />
                      </InputAdornment>
                    ),
                    className: classes.profiletextFielsFonts,
                  }}
                  onClick={this.handleLanguageModalOpen}
                />
                <Typography
                  variant="body2"
                  style={webStyle.profileerrorMessage}
                >
                  {this.state.isErrorFormDetails.language}
                </Typography>
                {this.state.toggleLanguageModal ? (
                  <Typography ref={this.wrapperRef}>
                    <Box style={webStyle.profiletoggleModalContainer}>
                      <Box style={webStyle.profiletoggleModalInnerContainer}>
                        {["English", "Arabic"].map((name,index) => (
                          <Box key={name}>
                            <EmployeeStyledMenuItem
                              disableRipple
                              style={webStyle.profilemenuItem}
                              value={name}
                            >
                              <Typography
                                onClick={this.handleLanguage.bind(this, name)}
                              >
                               {t(`boProfile.language.${index}`)}
                              </Typography>
                            </EmployeeStyledMenuItem>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.renderSave}>
              <Grid
                container
            className={classes.btnProfile}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "end",
                    marginTop: "1rem",
                    maxWidth: "100%",
                  }}
                >
                  <EmployeeProfileStyledButton
                    data-test-id="btnEmailLogin"
                    onClick={this.handleDetailsSubmit}
                    className={classes.profilesaveButton}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.profilesaveButtonText}
                    >
                      {t("boProfile.save")}
                    </Typography>
                  </EmployeeProfileStyledButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  renderAutoCompleteInputProfile = (params: any) => (
    <StyledTextFieldsAutoComplete {...params} languageSelected={this.state.lang} fullWidth variant="outlined" />
  );

  handleLanguage = (name: any) => {
    this.handleLanguageDropdown(name);
  };

  // Customizable Area End
  render() {
    // Customizable Area Start

    const { classes, t }: any = this.props

    return (
      <>
        <DashboardSideBar
          permissions={undefined}
          oldProps={undefined}
          {...this.props}
          navMenuItem={this.props.t('dashboardNavbar.profile')}
          active={false}
          active1={false}
          active2={false}
        />
        <StyledRoot languageSelected={this.state.lang}>
          <Box
            sx={{
              marginTop: "5rem",
            }}
            className={classes.ProfileSidebarboxContainer}
          >
            <Grid
              alignItems="flex-start"
              container
              direction="row"
              justifyContent="space-between"
              className={classes.renderForm}
              
            >
              <Dialog
                open={this.state.profileUpdateSuccessful}
                className={classes.profilepaperRounded}
              >
                <DialogContent
                  className={

                      classes.profileUpdateSuccessfulBox 
                      
                  }

                >
                  <Grid container direction="column">
                    <Grid item  xs={12} className={classes.profileUpdateSuccessfulCross}>
                      <img
                        src={successModalCross}
                        alt="cross_icon"
                        onClick={this.handleSuccessModal}
                        
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.profileUpdateSuccessfulTitle}
                      >
                        {t("boProfile.profileInformation")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.profileImageParent}>
                      <Grid item xs={7} className={classes.profileUpdateSuccessfulMessageParent}>
                        <Typography
                          variant="subtitle1"
                          className={classes.profileUpdateSuccessfulMessage}
                        >
                           {t("boProfile.successMsg")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}   className={
                        this.state.lang === "en" ?
                          classes.profileUpdateSuccessfulSideImageBackground :
                          classes.profileUpdateSuccessfulSideImageBackgroundAr
                      }>


                        <img
                          src={successModalImage}
                          alt="Success_icon"
                          className={
                            this.state.lang === "en" ?
                              classes.profileUpdateSuccessfulSideImage :
                              classes.profileUpdateSuccessfulSideImageAr
                          }
                        />
                      
                    </Grid>
                    </Grid>
                   
                  </Grid>
                </DialogContent>
              </Dialog>

              {this.renderProfileForm(classes)}
            </Grid>
          </Box>
        </StyledRoot>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

// Customizable Area Start
export const styles = (theme: any) => ({
  profileImageParent:{
    display:"flex",
    flexDirection:"row" as const,
    justifyContent:"space-between",
    alignItems:"baseline" as const
    },
  renderForm: {
    flexWrap: "nowrap" as const,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap" as const
    },
  },
  btnProfile:{
    display:"flex",
    flexDirection:"column" as const,
    alignItems:"flex-end" as const,
    [theme.breakpoints.down("xs")]: {
      alignItems:"normal",
    },

  },
  renderSave:{
    maxWidth: "100%",
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  profileBoxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  profilecustomOption: {
    color: CONSTANTS.color.midGrey
  },
  profileInputBase:{
    fontSize: "1.1667rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0625rem !important"
    },
  },
  profileinputBaseAr: {
    borderRadius: "8px 0 0 8px !important",
  },
  mainContV1:{
  
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  mainCont:{
    width: "75%",
    padding: "20px",
    borderLeft: "1px solid #e4e4e4",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  profileTitle: {
    
    height: "28px",
    color: CONSTANTS.color.lightBrown,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: "30px"
  },

  profilecompanyLogo: {
    width: "156px",
    height: "156px",
    marginTop: "35px",
  },
  profileeditBadge: {
    width: "18px",
    height: "18px",
    marginTop: "25%",
    marginLeft: "25%",
  },
  profileeditBadgeOval: {
    width: "40px",
    height: "40px",
    background: "#e9e9ff",
    backgroundBlendMode: "normal",
    borderRadius: "50%",
  },
  profilesaveButton: {
    width: "200px",
    height: "67px",
    borderRadius: "10px",
    background: "#1c18af",
    backgroundBlendMode: "normal",
    textAlign: "end" as const,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  profilesaveButtonText: {
    width: "75px",
    height: "32px",
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center" as const,
    textTransform: "none" as const,
  },
  profiletextFielsFonts: {
    height: "21px",
    color: CONSTANTS.color.midGrey,
    fontWeight:  theme.typography.fontWeightRegular,
  },
  profileUpdateSuccessfulBox: {
    width: "600px",
    height: "260px",
    borderRadius: "20px",
    background: "#eeffe4",
    backgroundBlendMode: "normal",
    boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
    padding:"20px !important",
    [theme.breakpoints.down("xs")]: {
      width: "256px",
    },
  },

  profileUpdateSuccessfulTitle: {
    color: "#0b9500",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "33px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem !important"
    },
  },
  profileUpdateSuccessfulMessageParent:{
    alignSelf:"center"
  },
  profileUpdateSuccessfulMessage: {
    color: "#3e3e3e",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "33px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem !important"
    },
  },
  profileUpdateSuccessfulSideImageBackground: {
    width: "100%",
    height: "150px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 20px 56px rgba(24,55,5,0.07)",
    borderRadius: "50%",
    marginLeft: "20%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      height: "95px",
      marginTop:"0.5rem"
    },
  },
  profileUpdateSuccessfulSideImageBackgroundAr: {
    width: "100%",
    height: "150px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 20px 56px rgba(24,55,5,0.07)",
    borderRadius: "50%",
    marginRight: "20%",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      height: "95px",
      marginTop:"0.5rem"
    },
  },
  profileUpdateSuccessfulSideImage: {
    width: "80px",
    height: "80px",
    marginLeft: "29%",
    marginTop: "20%",
    [theme.breakpoints.down("xs")]: {
      width: "58px",
      height: "58px",
      marginLeft: "19%",
      marginTop: "19%",
    },
  },
  profileUpdateSuccessfulSideImageAr: {
    width: "80px",
    height: "80px",
    marginRight: "29%",
    marginTop: "20%",
    [theme.breakpoints.down("xs")]: {
      width: "58px",
      height: "58px",
      marginRight: "14%",
      marginTop: "19%",
    },
  },
  profileUpdateSuccessfulCross: {
    alignSelf: "flex-end"
  },
  profilepaperRounded: {
    "& .MuiPaper-rounded": {
      borderRadius: "20px",
    },
  },
  ProfileSidebarboxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important",
    },
  },
  ProfileSidebarsideBarTitle: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 0 2rem",
      marginLeft: "-0.2rem",
    },
  },

  ProfileSidebarsideBarTitleAr: {
    color: "rgb(153, 153, 153)",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 2rem 0 0",
      marginRight: "-0.2rem",
    },
  },
  dialogBoxIconAr: {
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-206%, -50%) !important",
    },
    
  },
});

const webStyle = {
  profilePhoneContainer: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #dedede",
    display: "flex",
    minHeight: "56px",
  },
  profilegridItems: {
    marginTop: "1rem",
  },
  profilecontactForm: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
  },
  profilemenuItem: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  profileerrorMessage: {
    marginTop: "5px",
    color:  CONSTANTS.color.red,
  },
  profilecontactUsDropDown: {
    width: "14px",
    height: "8px",
    cursor: "pointer",
  },
  profiletoggleModalContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "85%",
    maxWidth: "470px",
    minHeight: "125px",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
  },
  profileInputBase: {
    fontFamily: "Rubik",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 10px",
    width: "100%",
    border: "0.5px solid lightgrey",
    borderRadius: "0 8px 8px 0",
    color:CONSTANTS.color.midGrey
  },
  profiletoggleModalInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    flexWrap: "wrap" as const,
    padding: "1rem",
  },
  editIcon: {
    cursor: "pointer",
    height: "36px",
    width: "36px",
    transform: "translate(-58%, 0%)",
  },
  changeLogoContainer: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    width: "85%",
    maxWidth: "354px",
    minHeight: "125px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    transform: "translate(20%, 10%)",
    zIndex: 1,
  },
  changeLogoContainerAr: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    width: "85%",
    maxWidth: "354px",
    minHeight: "125px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    transform: "translate(-20%, 10%)",
    zIndex: 1,
  },
  dialogBoxIcon: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "8%",
    transform: "translate(-50%, -50%)",
  },
  dialogBoxIconAr: {
    width: "16px",
    height: "8px",
    position: "absolute" as const,
    top: "-2%",
    left: "80%",
    transform: "translate(-50%, -50%)",
  },
  profilechangeLogoInnerContainer: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "1rem",
  },
  profilegalleryIcon: {
    width: "24px",
    height: "24px",
  },
  profilechangeLogoText: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  profileremoveImageText: {
    color: CONSTANTS.color.crimsonRed,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
};
//@ts-ignore
const ProfileRouter =withSnackbar(withRouter(Profile));
export default withTranslation()(withStyles(styles)(withWidth()(ProfileRouter)));
// Customizable Area End
