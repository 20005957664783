Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.employeeLoginApiEndPoint = "account_block/employee_login";
exports.employeeLoginApiContentType = "application/json";
exports.employeeLoginApiMethodType = "POST";

exports.loginApiEndPoint = "bx_block_login/logins";
exports.resendOtpEndPoint = "bx_block_login/logins/resend_otp"
exports.loginApiMethod = "POST";
// exports.emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
exports.emailRegex = /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
exports.charactersregex = /^[A-Za-z]+$/;
exports.numRegex = /^\d*$/;
exports.passwordValidators = {
  atleastOneCapsRegex: /[A-Z]+/,
  atleastLowerCaseRegex: /[a-z]+/,
  specialCharRegex: /\W+/,
  digitsRegex: /\d+/,
};
exports.passtext = "Kumar@123";
exports.signUpUrl = "/account_block/accounts";
exports.forgotPassword = "/bx_block_forgot_password/otps";
exports.confirmotp = "/bx_block_forgot_password/otp_confirmations";
exports.confirmEmailotp = "/account_block/email_otp_confirmations";
exports.validateEmail="account_block/signup_mesage"
//Employee login error text
exports.customersErrorText = {
  requiredTypo: "Password is required",
  requiredTypoV1: "Email is required",
  requiredTypoV2: "Language is required",
  alphabeticTypo: "Please enter in alphabetical characters",
  emailTypo: "The email address is invalid",
  passTypo:"The password is invalid",
  passValidation:"Password should contain atleast 8 characters, 1 uppercase, 1 small and 1 special character."
};

//regex
exports.regex = {
  email: /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  spaces: /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/,
  passRegex :/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*]).{8,}$/ 
};

exports.employeeApis = {
  loginApiEndPoint: "account_block/employee_login",
  loginApiMethod: "POST",
  contentType: "application/json",
};

exports.employeeLoginTexts = {
  welcomeBack: " Welcome Back,",
  enterCreds: "Please enter your credentials to get start now !",
  emailAddress: "Email Address",
  forgotPass: "Forgot Password?",
  language: {
    choose: "Choose Language",
    optionOne: "English",
    optionTwo: "Arabic",
  },
  login: "Login",
};
exports.secretKey = "eD%TN@3bc%l},F5eplIMCyd9h!Y2"
// Customizable Area End
