Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SaveAsPdf";
exports.labelBodyText = "SaveAsPdf Body";

exports.btnExampleTitle = "CLICK ME";
exports.storageToken="authToken"
exports.downloadLabel="Download"
exports.pdfOrderLabel="Request"
exports.pdfOrderJobLabel="Jobs"

exports.dateLabel="27/03/2023"


// Api end points
exports.getRequestPdfDetailsEndPoint="bx_block_request_management/pdf_details?id="
exports.getJobPdfDetailsEndPoint="bx_block_request_management/job_pdf_details?id="
exports.getQuotePdfDetailsEndPoint="bx_block_quotemanagement/pdf_details?id="
exports.downloadPdfEndPoint="account_block/downlode_pdf?id="
exports.apiCallIDSiteDetails="bx_block_location/site_informations"
exports.invoicePdfendPoint="bx_block_request_management/invoice_pdf_details?id="

// Customizable Area End